import mainReducer from './reducer';
import { dashboardReducer } from '../dashboard/reducer';
import { visibilityReducer } from '../visibility/store/reducer';
import { exploreReducer } from '../visibility/explore/logViewer_v2/store/reducer';
import { insightsReducer } from '../visibility/insights/store/reducer';
import { devicePageReducer } from '../visibility/insights/pages/DeviceDetails/store/reducer';
import { insightsMainReducer } from '../visibility/insights/pages/InsightsMain/store/reducer';
import { linePageReducer } from '../visibility/insights/pages/LineMetricDetails/store/reducer';
import { tablePageReducer } from '../visibility/insights/pages/TableMetricDetails/store/reducer';
import { alertsReducer } from '../visibility/alerts/store/reducer';
import { alertsMainReducer } from '../visibility/alerts/pages/AlertsMain/store/reducer';
import { alertsNotificationsReducer } from '../visibility/alerts/pages/AlertsNotifications/store/reducer';
import { alertDetailsReducer } from '../visibility/alerts/pages/AlertDetails/store/reducer';
import { initAppEpic, fetchMetricsMiscEpic, fetchAlertsMiscEpic, updateActiveAppEpic } from '../visibility/store/epics';
import {
	initInsightsEpic,
	initInsightsTabsStateEpic,
	openDevicePageEpic,
	openInsightsTabEpic,
	switchInsightsTabEpic,
	closeInsightsTabEpic,
	cacheInsightsTabEpic,
	clearInsightsDataCacheEpic,
	updateInsightsActiveTabEpic,
	initInsightsSchemaEpic,
	updateInsightsSchemaEpic,
	resetInsightsSchemaEpic,
	pollInsightsJobEpic,
	fetchAutocompleteEpic
} from '../visibility/insights/store/epics';
import {
	fetchInsightsMainPageContentEpic,
	fetchHealthStatusEpic,
	updateHexGridSettingsEpic,
	fetchHexGridEpic,
	updateQueryPrefEpic,
	fetchInsightsLogsEpic,
	fetchInsightsLogsCountEpic,
	fetchInsightsDetailLogsEpic,
	fetchMoreDetailLogsEpic,
	updateInsightsPageConfigEpic,
	pollInsightsMainPageJobEpic
} from '../visibility/insights/pages/InsightsMain/store/epics';
import {
	fetchDeviceDetailsPageContentEpic,
	fetchDeviceMetricsEpic,
	pollDevicePageJobEpic,
	fetchDeviceAlertsEpic,
	fetchDeviceCommitEventsEpic,
	updateDeviceInfoTimePresetEpic,
	fetchDeviceDetailsEpic,
	fetchClosedDeviceAlertsEpic,
	updateDeviceAlertEpic,
	fetchDeviceAlertCommentsEpic,
	addDeviceAlertCommentEpic,
	updateDeviceAlertCommentEpic,
	removeDeviceAlertCommentEpic,
	fetchDeviceAlertEventsEpic,
	fetchDeviceAlertActivitiesEpic
} from '../visibility/insights/pages/DeviceDetails/store/epics';
import { fetchTablePageContentEpic, fetchTablePageDescEpic, fetchTableChartEpic, pollTablePageJobEpic } from '../visibility/insights/pages/TableMetricDetails/store/epics';
import {
	fetchLinePageContentEpic,
	fetchLinePageDescEpic,
	fetchRelatedMetricsEpic,
	fetchTimelineEpic,
	fetchForecastEpic,
	fetchMovingAverageEpic,
	fetchCommitEventsEpic,
	fetchCapacityEpic,
	updateCheckedRelatedMetricsEpic,
	updateShowCommitEventsEpic,
	updateShowRefLinesEpic,
	updateTimeRangePresetEpic,
	pollLinePageJobEpic
} from '../visibility/insights/pages/LineMetricDetails/store/epics';

import {
	initAlertsEpic,
	initAlertsTabsStateEpic,
	openAlertsTabEpic,
	switchAlertsTabEpic,
	closeAlertsTabEpic,
	cacheAlertsTabEpic,
	clearAlertsDataCacheEpic,
	updateAlertsActiveTabEpic,
	initAlertsSchemaEpic,
	updateAlertsSchemaEpic,
	resetAlertsSchemaEpic
} from '../visibility/alerts/store/epics';

import {
	fetchAlertsMainPageContentEpic,
	fetchAlertsEpic,
	fetchClosedAlertsEpic,
	updateAlertEpic,
	fetchCommentsEpic,
	addCommentEpic,
	updateCommentEpic,
	removeCommentEpic,
	fetchEventsEpic,
	fetchActivitiesEpic,
	fetchDevicesDetailsEpic,
	pollAlertsMainPageJobEpic,
	updateAlertsTimePresetEpic,
	updateAlertsTimeRangeEpic
} from '../visibility/alerts/pages/AlertsMain/store/epics';

import {
	fetchAlertsNotificationsPageContentEpic,
	fetchRulesEpic,
	addRuleEpic,
	updateRuleEpic,
	removeRuleEpic,
	fetchEmailTemplatesEpic,
	addEmailTemplateEpic,
	updateEmailTemplateEpic,
	removeEmailTemplateEpic,
	fetchConfigEpic,
	addConfigEpic,
	fetchSNTemplatesEpic,
	addSNTemplateEpic,
	updateSNTemplateEpic,
	removeSNTemplateEpic,
	fetchSNGroupUsersEpic,
	fetchSNGroupsEpic
} from '../visibility/alerts/pages/AlertsNotifications/store/epics';

import {
	fetchAlertDetailPageContentEpic,
	fetchAlertEpic,
	updateAlertStatusEpic,
	fetchAlertCommentsEpic,
	addAlertCommentEpic,
	updateAlertCommentEpic,
	removeAlertCommentEpic,
	fetchAlertEventsEpic,
	fetchAlertDeviceDetailsEpic,
	pollAlertDetailsPageJobEpic,
	fetchChartsEpic,
	updateAlertTimeRangePresetEpic,
	fetchAlertTimelineEpic,
	fetchAlertForecastEpic,
	fetchAlertMovingAverageEpic,
	fetchAlertCommitEventsEpic,
	fetchAlertCapacityEpic,
	updateShowAlertCommitEventsEpic,
	updateShowAlertRefLinesEpic,
	fetchAlertTableChartEpic,
	fetchAlertActivitiesEpic
} from '../visibility/alerts/pages/AlertDetails/store/epics';

import {
	fetchSchemaEpic,
	updateExploreSchemaEpic,
	fetchLogsEpic,
	fetchLogsCountEpic,
	fetchRelatedLogsEpic,
	pollJobEpic,
	updatePageEpic,
	fetchUserContextEpic,
	initExportEpic,
	checkExportStatusEpic,
	cancelExportEpic,
	fetchExportHistoryEpic,
	fetchExportFileEpic,
	updateLogTypeEpic,
	updateQueryEpic,
	updateTimePresetEpic,
	updateTimeRangeEpic,
	restoreLogviewerEpic
} from '../visibility/explore/logViewer_v2/store/epics';
import {
	isUserLoggedInEpic,
	getLoggedInUserEpic,
	fetchCommitEpic,
	doCommitEpic,
	doCommitAndPushEpic,
	doPushEpic,
	doRevertEpic,
	fetchLicenseInfoEpic,
	fetchTokenInfoEpic,
	fetchDirectoryDomainsEpic,
	fetchAplicationsInfoEpic,
	saveUserPreferenceEpic
} from './actions';
import {
	addConfigItemsEpic,
	editConfigItemsEpic,
	fetchConfigItemsEpic,
	renameConfigItemsEpic,
	deleteConfigItemsEpic,
	cloneConfigItemsEpic,
	getURLFilteringCategoriesEpic,
	getPoliciesAddressesEpic,
	getPoliciesApplicationEpic,
	getPoliciesServicesEpic
} from 'service-lib';

const adelphiStoreInfo = {
	reducers: {
		main: mainReducer,
		dashboard: dashboardReducer,
		visibility: visibilityReducer,
		insights: insightsReducer,
		insightsMain: insightsMainReducer,
		linePage: linePageReducer,
		tablePage: tablePageReducer,
		devicePage: devicePageReducer,
		alerts: alertsReducer,
		alertsMain: alertsMainReducer,
		alertsNotifications: alertsNotificationsReducer,
		alertDetails: alertDetailsReducer,
		explore: exploreReducer
	},
	epics: [
		//App
		initAppEpic,
		fetchMetricsMiscEpic,
		fetchAlertsMiscEpic,
		updateActiveAppEpic,
		//insights
		initInsightsEpic,
		initInsightsTabsStateEpic,
		openDevicePageEpic,
		openInsightsTabEpic,
		switchInsightsTabEpic,
		closeInsightsTabEpic,
		cacheInsightsTabEpic,
		clearInsightsDataCacheEpic,
		updateInsightsActiveTabEpic,
		initInsightsSchemaEpic,
		updateInsightsSchemaEpic,
		resetInsightsSchemaEpic,
		pollInsightsJobEpic,
		fetchAutocompleteEpic,
		// InsightsMainPage
		fetchInsightsMainPageContentEpic,
		fetchHealthStatusEpic,
		updateHexGridSettingsEpic,
		fetchHexGridEpic,
		updateQueryPrefEpic,
		fetchInsightsLogsEpic,
		fetchInsightsLogsCountEpic,
		fetchInsightsDetailLogsEpic,
		fetchMoreDetailLogsEpic,
		updateInsightsPageConfigEpic,
		updateShowCommitEventsEpic,
		updateShowRefLinesEpic,
		updateTimeRangePresetEpic,
		pollInsightsMainPageJobEpic,
		//DeviceDetailsPage
		fetchDeviceDetailsPageContentEpic,
		fetchDeviceMetricsEpic,
		pollDevicePageJobEpic,
		fetchDeviceAlertsEpic,
		fetchDeviceCommitEventsEpic,
		updateDeviceInfoTimePresetEpic,
		fetchDeviceDetailsEpic,
		fetchClosedDeviceAlertsEpic,
		updateDeviceAlertEpic,
		fetchDeviceAlertCommentsEpic,
		addDeviceAlertCommentEpic,
		updateDeviceAlertCommentEpic,
		removeDeviceAlertCommentEpic,
		fetchDeviceAlertEventsEpic,
		fetchDeviceAlertActivitiesEpic,
		// TableMetricDetailsPage
		fetchTablePageContentEpic,
		fetchTablePageDescEpic,
		fetchTableChartEpic,
		pollTablePageJobEpic,
		// LineMetricDetailsPage
		fetchLinePageContentEpic,
		fetchLinePageDescEpic,
		fetchRelatedMetricsEpic,
		fetchTimelineEpic,
		fetchForecastEpic,
		fetchMovingAverageEpic,
		fetchCommitEventsEpic,
		fetchCapacityEpic,
		updateCheckedRelatedMetricsEpic,
		pollLinePageJobEpic,
		// Alerts
		initAlertsEpic,
		initAlertsTabsStateEpic,
		openAlertsTabEpic,
		switchAlertsTabEpic,
		closeAlertsTabEpic,
		cacheAlertsTabEpic,
		clearAlertsDataCacheEpic,
		updateAlertsActiveTabEpic,
		initAlertsSchemaEpic,
		updateAlertsSchemaEpic,
		resetAlertsSchemaEpic,
		// AlertsMain
		fetchAlertsMainPageContentEpic,
		fetchAlertsEpic,
		fetchClosedAlertsEpic,
		updateAlertEpic,
		fetchCommentsEpic,
		addCommentEpic,
		updateCommentEpic,
		removeCommentEpic,
		fetchEventsEpic,
		fetchActivitiesEpic,
		fetchDevicesDetailsEpic,
		pollAlertsMainPageJobEpic,
		updateAlertsTimePresetEpic,
		updateAlertsTimeRangeEpic,
		// AlertDetails
		fetchAlertDetailPageContentEpic,
		fetchAlertEpic,
		updateAlertStatusEpic,
		fetchAlertCommentsEpic,
		addAlertCommentEpic,
		updateAlertCommentEpic,
		removeAlertCommentEpic,
		fetchAlertEventsEpic,
		fetchAlertActivitiesEpic,
		fetchAlertDeviceDetailsEpic,
		pollAlertDetailsPageJobEpic,
		fetchChartsEpic,
		updateAlertTimeRangePresetEpic,
		updateShowAlertCommitEventsEpic,
		updateShowAlertRefLinesEpic,
		fetchAlertTimelineEpic,
		fetchAlertForecastEpic,
		fetchAlertMovingAverageEpic,
		fetchAlertCommitEventsEpic,
		fetchAlertCapacityEpic,
		fetchAlertTableChartEpic,
		// AlertsNotifications
		fetchAlertsNotificationsPageContentEpic,
		fetchRulesEpic,
		addRuleEpic,
		updateRuleEpic,
		removeRuleEpic,
		fetchEmailTemplatesEpic,
		addEmailTemplateEpic,
		updateEmailTemplateEpic,
		removeEmailTemplateEpic,
		fetchConfigEpic,
		addConfigEpic,
		fetchSNTemplatesEpic,
		addSNTemplateEpic,
		updateSNTemplateEpic,
		removeSNTemplateEpic,
		fetchSNGroupUsersEpic,
		fetchSNGroupsEpic,
		// Explore
		fetchSchemaEpic,
		updateExploreSchemaEpic,
		fetchLogsEpic,
		fetchLogsCountEpic,
		fetchRelatedLogsEpic,
		pollJobEpic,
		updatePageEpic,
		fetchUserContextEpic,
		initExportEpic,
		checkExportStatusEpic,
		cancelExportEpic,
		fetchExportHistoryEpic,
		fetchExportFileEpic,
		updateLogTypeEpic,
		updateQueryEpic,
		updateTimePresetEpic,
		updateTimeRangeEpic,
		restoreLogviewerEpic,
		// Common
		isUserLoggedInEpic,
		getLoggedInUserEpic,
		fetchCommitEpic,
		doCommitEpic,
		doCommitAndPushEpic,
		doPushEpic,
		doRevertEpic,
		fetchLicenseInfoEpic,
		fetchTokenInfoEpic,
		fetchDirectoryDomainsEpic,
		fetchAplicationsInfoEpic,
		addConfigItemsEpic,
		editConfigItemsEpic,
		fetchConfigItemsEpic,
		renameConfigItemsEpic,
		deleteConfigItemsEpic,
		cloneConfigItemsEpic,
		getURLFilteringCategoriesEpic,
		getPoliciesAddressesEpic,
		getPoliciesApplicationEpic,
		getPoliciesServicesEpic,
		saveUserPreferenceEpic
	]
};

export { adelphiStoreInfo };
