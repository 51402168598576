import React from 'react'
import { connect } from 'ui-lib'
import _ from 'lodash'

import HexagonChart from './components/HexagonChart.js'
import Setting from './components/Setting'
import { PopoverWidget as Popover } from 'ui-lib'
import PanwLoadingLogo from './resources/panw-logo.svg'
import iconSetting from '../../../../../images/icon-gear.svg'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'

import * as insightsMainActions from '../../store/actions'

import Util from '../../../../utils/Util'
import './HexGrid.scss'

class HexGrid extends React.Component {
	constructor(props) {
		super(props)

		this.svgRef = React.createRef()

		this.state = {
			zoom: 1,
			zoomMulti: 1,
			offset: { x: 0, y: 0 },
			svgWidth: -1,
			svgHeight: -1,
			isResizing: true,
			elements: {},
			data: this.props.hexgrid
		}
		this.state.elements = this.dataToElements(this.props.hexgrid)
	}

	dataToElements(d) {
		if (d === undefined || d === null || d.length === undefined || d.length === 0) {
			return []
		}
		if (d[0] !== undefined && d[0].metric !== undefined) {
			this.metricsMode = true
			return d.map((o, idx) => {
				return {
					id: idx,
					queryInfo: { key: 'metric', value: o.metric },
					attributes: [
						{ key: 'name', value: Util.getMetricDisplayName(this.props.metricsMisc, o.metric) },
						{ key: 'critical', value: o.num_critical_devices },
						{ key: 'warning', value: o.num_warning_devices },
						{ key: 'healthy', value: o.num_healthy_devices },
						{ key: 'severity', value: o.severity },
						{ key: 'nodata', value: false },
						{ key: 'model', value: '' },
						{ key: 'version', value: '' }
					]
				}
			})
		} else {
			this.metricsMode = false
			return d.map((o) => {
				return {
					id: o.serial,
					queryInfo: { key: 'hostname', value: o.hostname },
					attributes: [
						{ key: 'name', value: o.hostname },
						{ key: 'severity', value: o.severity },
						{ key: 'version', value: o.sw_version },
						{ key: 'model', value: o.model },
						{ key: 'ip-address', value: determineWhichIPAddressToShow(o) },
						{ key: 'critical', value: o.num_critical_metrics },
						{ key: 'warning', value: o.num_warning_metrics },
						{ key: 'healthy', value: o.num_healthy_metrics },
						{ key: 'nodata', value: false }
					]
				}
			})
		}

		function determineWhichIPAddressToShow(o) {
			if (o.ip_address !== 'unknown') {
				return o.ip_address
			}
			if (o.ipv6_address !== 'unknown') {
				return o.ipv6_address
			}
			return 'N/A'
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { hexgrid, hexgridSettings, metricsMisc, activeInsightsTab, isNavOpen, lockedElements } = this.props
		if (
			this.state !== nextState ||
			hexgrid !== nextProps.hexgrid ||
			metricsMisc !== nextProps.metricsMisc ||
			activeInsightsTab !== nextProps.activeInsightsTab ||
			hexgridSettings !== nextProps.hexgridSettings ||
			isNavOpen !== nextProps.isNavOpen ||
			!_.isEqual(nextProps.lockedElements, lockedElements)
		) {
			return true
		}
		return false
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isNavOpen !== this.props.isNavOpen) {
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'))
			}, 300)
		}
		if (!_.isEqual(this.state.data, this.props.hexgrid)) {
			this.setState({
				data: this.props.hexgrid,
				elements: this.dataToElements(this.props.hexgrid),
				isResizing: true
			})
			window.clearTimeout(window.timeoutResize)
			window.timeoutResize = window.setTimeout(() => {
				this.setState({
					isResizing: false
				})
				this.updateSVGBounds()
			}, 500)
		}
	}

	updateSVGBounds() {
		if (this.svgRef.current !== null) {
			this.setState({
				svgWidth: this.svgRef.current.clientWidth,
				svgHeight: this.svgRef.current.clientHeight
			})
		}
	}

	resizeHandler = (delay = 500) => {
		window.clearTimeout(window.timeoutResize)
		this.setState({
			isResizing: true
		})
		window.timeoutResize = window.setTimeout(() => {
			this.setState({
				isResizing: false
			})
			this.updateSVGBounds()
		}, delay)
	}

	componentDidMount() {
		window.timeoutResize = null
		window.addEventListener('resize', this.resizeHandler)
		this.resizeHandler()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeHandler)
	}

	render() {
		const { hexgridError, hexgridLoading, hexgrid, resetLockedElements } = this.props
		if (hexgridError) {
			return <div className="info-area">Error fetching hex grid</div>
		}
		if (hexgridLoading) {
			return (
				<div className="info-area">
					<img className="loading-spinner" src={iconLoading} alt={'loading'} />
					Loading Hex Grid
				</div>
			)
		}
		if (hexgrid.length === 0) {
			return <div className="info-area">No Data Found </div>
		}

		if (this.state.isResizing === true) {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						height: '395px',
						textAlign: 'center'
					}}
				>
					<img style={{ height: '128px' }} src={PanwLoadingLogo} alt="loading indicator" />
				</div>
			)
		} else {
			const { hexgridSettings, activeInsightsTab, updateHexGridSettings } = this.props
			const setting = hexgridSettings[activeInsightsTab]
			return (
				<>
					<div className="setting">
						<Popover
							getPopupContainer={(triggerNode) => triggerNode}
							prefixCls="setting-popover"
							content={
								<Setting
									onSettingChange={(newSetting) => {
										updateHexGridSettings({ ...setting, ...newSetting }, activeInsightsTab)
										resetLockedElements()
										this.resizeHandler(0)
									}}
									healthFilter={setting.healthFilter}
									sortBy={setting.sortBy}
									activeInsightsTab={activeInsightsTab}
								/>
							}
							trigger="click"
							placement="bottomRight"
						>
							<div className="hex-tools">
								<img className="gear-icon" src={iconSetting} alt="setting" />
							</div>
						</Popover>
					</div>
					<HexagonChart
						{...this.props}
						elements={this.state.elements}
						metricsMode={this.metricsMode}
						sortBy={setting.sortBy}
						filter={
							setting.healthFilter === true
								? []
								: [
										{ key: 'severity', value: 'critical' },
										{ key: 'severity', value: 'warning' },
										{ key: 'severity', value: 'nodata' }
								  ]
						}
						svgHeight={this.state.svgHeight}
						svgWidth={this.state.svgWidth}
						offset={{ x: this.state.offset.x, y: this.state.offset.y }}
						svgRef={this.svgRef}
						updateSVGBounds={() => {
							this.updateSVGBounds()
						}}
						setParentState={(v) => {
							this.setState(v)
						}}
						resetEverything={() => {
							window.clearTimeout(window.timeoutResize)
							this.setState({
								isResizing: true
							})
							window.timeoutResize = window.setTimeout(() => {
								this.setState({
									isResizing: false
								})
								this.updateSVGBounds()
							}, 32)
						}}
					/>
				</>
			)
		}
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.insights, ...state.insightsMain }
}

const mapDispatchToProps = { ...insightsMainActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(HexGrid)
