const validUserPreferenceAttributes = ['insights', 'explore', 'alerts', 'activeApp'];
export const getValidUserPreference = (userPreference) => {
	let preference = {};
	for (const key of Object.keys(userPreference)) {
		if (_.includes(validUserPreferenceAttributes, key)) {
			preference[key] = userPreference[key];
		}
	}
	return preference;
};
