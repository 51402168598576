import React, { Component } from 'react'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import chevMore from 'ui-lib/src/images/icon-chevron-more.svg'
import chevLess from 'ui-lib/src/images/icon-chevron-less.svg'
import moment from 'moment'

export default class Card extends Component {
	state = { isExpanded: false }

	toggleExpand = () => {
		this.setState((prevState) => {
			return { isExpanded: !prevState.isExpanded }
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.logIndex !== prevProps.logIndex) {
			this.setState({ isExpanded: false })
		}
	}

	render() {
		const { title, groupData, id, isUser, userData, userCtxLoading, userCtxError } = this.props
		const rowsCount = Math.ceil(Object.keys(groupData).length / 3)
		const renderedFields = this.state.isExpanded ? Object.keys(groupData) : Object.keys(groupData).slice(0, 12)
		if (isUser && (userCtxLoading || (!userCtxLoading && userCtxError))) {
			return (
				<div className="expand-card" id={id}>
					<div
						className="expand-card-content"
						style={{
							gridTemplateColumns: '1fr',
							gridTemplateRows: '1fr',
							height: '100px'
						}}
					>
						<div className="expand-card-title">{title}</div>
						{userCtxLoading ? (
							<div className="timeline-spin">
								<div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						) : (
							<div className="expand-card-cell">No User Information Found</div>
						)}
					</div>
				</div>
			)
		}
		return (
			<div className="expand-card" id={id}>
				<div
					className="expand-card-content"
					style={{
						gridTemplateColumns: '1fr '.repeat(3),
						gridTemplateRows: `48px ${'40px '.repeat(this.state.isExpanded ? rowsCount : rowsCount > 4 ? 4 : rowsCount)}`
					}}
				>
					<div className="expand-card-title">{title}</div>
					{isUser ? (
						<div className="expand-card-userbadge">
							<div className="ubadge">{userData['initials']}</div>
							<div className="name">{userData['displayName']}</div>
							<div className="title">{userData['title']}</div>
						</div>
					) : null}
					{renderedFields.map((field, index) => (
						<div key={field} className="expand-card-cell">
							<div className="expand-card-cell-title" data-tip data-for={`title_${_.kebabCase(field)}`}>
								<span>{field.toLowerCase()}</span>
							</div>
							<ReactTooltip place={(index + 1) % 3 === 0 ? 'left' : 'right'} type="dark" effect="solid" id={`title_${_.kebabCase(field)}`}>
								{field.toLowerCase()}
							</ReactTooltip>
							<div className="expand-card-cell-data" data-tip data-for={`data_${_.kebabCase(field)}`}>
								<span>{field === 'time generated' ? moment(groupData[field] / 1000).format('MM/DD/YYYY hh:mm:ss A') : groupData[field]}</span>
							</div>
							<ReactTooltip place={(index + 1) % 3 === 0 ? 'left' : 'right'} type="dark" effect="solid" id={`data_${_.kebabCase(field)}`}>
								{field === 'time generated' ? moment(groupData[field] / 1000).format('MM/DD/YYYY hh:mm:ss A') : groupData[field]}
							</ReactTooltip>
						</div>
					))}
				</div>
				{rowsCount > 4 ? (
					<div className="expand-card-more">
						{this.state.isExpanded ? (
							<>
								<span onClick={this.toggleExpand}>View Less</span> <img src={chevLess} alt="Less" />
							</>
						) : (
							<>
								<span onClick={this.toggleExpand}>View More</span> <img src={chevMore} alt="More" />
							</>
						)}
					</div>
				) : null}
			</div>
		)
	}
}
