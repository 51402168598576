import React, { Component } from 'react'

export default class HostnameCellRender extends Component {
	render() {
		const { data, activeInsightsTab, cacheInsightsTab, openDevicePage } = this.props
		return (
			<span className="hostname-cell">
				<span className={`severity-icon ${this.props.data.severity}`} />
				<span
					className="hostname"
					onClick={() => {
						cacheInsightsTab(activeInsightsTab)
						openDevicePage({ serial: data.serial, hostname: data.hostname })
					}}
				>
					{this.props.data.hostname ? this.props.data.hostname : ''}
				</span>
			</span>
		)
	}
}
