import * as actionTypes from './actionTypes'

export const fetchInsightsMainPageContent = () => ({
	type: actionTypes.FETCH_INSIGHTS_MAIN_PAGE_CONTENT
})

/*----- Poll Job Actions -----*/
export const pollInsightsMainPageJobRequest = (jobId, jobType, jobPage, jobLimit, params) => ({
	type: actionTypes.POLL_INSIGHTS_MAIN_PAGE_JOB_REQUEST,
	jobId,
	jobType,
	jobPage,
	jobLimit,
	params
})

export const pollInsightsMainPageJobFailure = (jobId, error) => ({
	type: actionTypes.POLL_INSIGHTS_MAIN_PAGE_JOB_FAILURE,
	jobId,
	error
})

/*----- Health Status Actions -----*/
export const fetchHealthStatusRequest = () => ({
	type: actionTypes.FETCH_HEALTH_STAT_REQUEST
})

export const fetchHealthStatusSuccess = (healthStatus) => ({
	type: actionTypes.FETCH_HEALTH_STAT_SUCCESS,
	healthStatus
})

export const setHealthStatusRequestId = (jobId) => ({
	type: actionTypes.SET_HEALTH_STAT_REQUEST_ID,
	jobId
})

export const fetchHealthStatusFailure = (error) => ({
	type: actionTypes.FETCH_HEALTH_STAT_FAILURE,
	error
})

export const restoreHealthStatus = (data) => ({
	type: actionTypes.RESTORE_HEALTH_STAT,
	data
})

/*----- Hex Grid Actions -----*/
export const updateHexGridSettings = (setting, activeInsightsTab) => ({
	type: actionTypes.UPDATE_HEXGRID_SETTINGS,
	setting,
	activeInsightsTab
})

export const setHexGridSettings = (setting, activeInsightsTab) => ({
	type: actionTypes.SET_HEXGRID_SETTINGS,
	setting,
	activeInsightsTab
})

export const fetchHexGridRequest = () => ({
	type: actionTypes.FETCH_HEX_GRID_REQUEST
})

export const fetchHexGridSuccess = (hexgrid) => ({
	type: actionTypes.FETCH_HEX_GRID_SUCCESS,
	hexgrid
})

export const setHexGridRequestId = (jobId) => ({
	type: actionTypes.SET_HEX_GRID_REQUEST_ID,
	jobId
})

export const fetchHexGridFailure = (error) => ({
	type: actionTypes.FETCH_HEX_GRID_FAILURE,
	error
})

export const restoreHexGrid = (data) => ({
	type: actionTypes.RESTORE_HEX_GRID,
	data
})

/*----- Insights Logs Actions -----*/
export const fetchInsightsLogsRequest = (jobType, jobLimit, jobPage) => ({
	type: actionTypes.FETCH_INSIGHTS_LOGS_REQUEST,
	jobType,
	jobLimit,
	jobPage
})

export const fetchInsightsLogsSuccess = (logs, jobId) => ({
	type: actionTypes.FETCH_INSIGHTS_LOGS_SUCCESS,
	logs,
	jobId
})

export const setInsightsLogsRequestId = (jobId) => ({
	type: actionTypes.SET_INSIGHTS_LOGS_REQUEST_ID,
	jobId
})

export const fetchInsightsLogsFailure = (error) => ({
	type: actionTypes.FETCH_INSIGHTS_LOGS_FAILURE,
	error
})

export const restoreInsightsLogs = (data) => ({
	type: actionTypes.RESTORE_INSIGHTS_LOGS,
	data
})

/*----- Insights Logs Count Actions -----*/
export const fetchInsightsLogsCountRequest = (jobType) => ({
	type: actionTypes.FETCH_INSIGHTS_LOGS_COUNT_REQUEST,
	jobType
})

export const fetchInsightsLogsCountSuccess = (logsCount, jobId) => ({
	type: actionTypes.FETCH_INSIGHTS_LOGS_COUNT_SUCCESS,
	logsCount,
	jobId
})

export const setInsightsLogsCountRequestId = (jobId) => ({
	type: actionTypes.SET_INSIGHTS_LOGS_COUNT_REQUEST_ID,
	jobId
})

export const fetchInsightsLogsCountFailure = (error) => ({
	type: actionTypes.FETCH_INSIGHTS_LOGS_COUNT_FAILURE,
	error
})

export const restoreInsightsLogsCount = (data) => ({
	type: actionTypes.RESTORE_INSIGHTS_LOGS_COUNT,
	data
})

/*----- Insights Detail Logs Actions -----*/
export const fetchInsightsDetailLogsRequest = (list, jobPage) => ({
	type: actionTypes.FETCH_INSIGHTS_DETAIL_LOGS_REQUEST,
	list,
	jobPage
})

export const fetchInsightsDetailLogsSuccess = (logs, jobId, activeInsightsTab) => ({
	type: actionTypes.FETCH_INSIGHTS_DETAIL_LOGS_SUCCESS,
	logs,
	jobId,
	activeInsightsTab
})

export const setInsightsDetailLogsRequestId = (jobId) => ({
	type: actionTypes.SET_INSIGHTS_DETAIL_LOGS_REQUEST_ID,
	jobId
})

export const fetchInsightsDetailLogsFailure = (error) => ({
	type: actionTypes.FETCH_INSIGHTS_DETAIL_LOGS_FAILURE,
	error
})

/*----- More Detail Logs Actions -----*/
export const fetchMoreDetailLogsRequest = (logIndex) => ({
	type: actionTypes.FETCH_MORE_DETAIL_LOGS_REQUEST,
	logIndex
})

export const fetchMoreDetailLogsSuccess = (details, rowsInJob, logIndex, jobId, currentTabJob) => ({
	type: actionTypes.FETCH_MORE_DETAIL_LOGS_SUCCESS,
	details,
	rowsInJob,
	logIndex,
	jobId,
	currentTabJob
})

export const fetchMoreDetailLogsFailure = (error, logIndex) => ({
	type: actionTypes.FETCH_MORE_DETAIL_LOGS_FAILURE,
	error,
	logIndex
})

/*----- Query Related Actions -----*/
export const updateInsightsQuery = (query) => ({
	type: actionTypes.UPDATE_INSIGHTS_QUERY,
	query
})

export const setInsightsQuery = (query) => ({
	type: actionTypes.SET_INSIGHTS_QUERY,
	query
})

export const updateInsightsTimeRange = () => ({
	type: actionTypes.UPDATE_INSIGHTS_TIME_RANGE
})

export const updateQueryPref = () => ({
	type: actionTypes.UPDATE_QUERY_PREF
})

/*----- Page Actions -----*/
export const updateInsightsPageRequest = (params) => ({
	type: actionTypes.UPDATE_INSIGHTS_PAGE_REQUEST,
	params: params || {}
})

export const updateInsightsPageNumber = (pageNumber) => ({
	type: actionTypes.UPDATE_INSIGHTS_PAGE_NUMBER,
	pageNumber
})

export const updateInsightsPageSize = (pageSize) => ({
	type: actionTypes.UPDATE_INSIGHTS_PAGE_SIZE,
	pageSize
})

export const setInsightsPageSize = (pageSize) => ({
	type: actionTypes.SET_INSIGHTS_PAGE_SIZE,
	pageSize
})

export const updateInsightsPageLimit = (pageLimit) => ({
	type: actionTypes.UPDATE_INSIGHTS_PAGE_LIMIT,
	pageLimit
})

export const resetInsightsPage = () => ({
	type: actionTypes.RESET_INSIGHTS_PAGE
})

export const restoreInsightsPage = (data) => ({
	type: actionTypes.RESTORE_INSIGHTS_PAGE,
	data
})
