import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import { ClearableInputWidget as Input } from 'ui-lib'
import LogQueryCompletion from './LogQueryCompletion'
import { LogSchema, EMPTY_SCHEMA } from './util'
import iconSearch from '../../../../../../images/icon-search.svg'
import iconSearchActivated from '../../../../../../images/icon-search-activated.svg'
import './LogQuery.scss'

export class LogQueryComponent extends Component {
	state = {
		fields: [],
		query: '',
		defaultQuery: '',
		logType: '',
		defaultLogType: '',
		schema: new LogSchema()
	}

	static getDerivedStateFromProps(props, state) {
		const { query, logType, schema } = props
		const update = {}
		if (schema !== state.schema && !state.schema.is(schema)) {
			update.schema = LogSchema.from(schema)
		}
		if (logType !== state.defaultLogType) {
			Object.assign(update, {
				logType,
				defaultLogType: logType
			})
		}
		if (update.schema || update.logType) {
			const schema = update.schema || state.schema
			const logType = update.logType || state.logType
			// Extract fields from schema
			const fields = logType && schema && schema.getFields(logType)
			if (fields && fields !== state.fields) {
				update.fields = fields
			}
		}
		if (query !== state.defaultQuery) {
			Object.assign(update, { query, defaultQuery: query })
		}
		return update
	}

	appendQuery = (queryExp, andor = 'AND', triggerSearch = false) => {
		const oldQuery = this.state.query.trim()
		const query = oldQuery ? `${oldQuery} ${andor} ${queryExp.trim()}` : queryExp.trim()
		// console.error('appendQuery', oldQuery, queryExp, query)
		this.setState({ query }, () => {
			if (triggerSearch) {
				this.triggerOnSearch()
			} else if (this.queryInputRef && this.queryInputRef.onSearch) {
				this.queryInputRef.onSearch()
			}
		})
	}

	onQueryChange = (query) => {
		this.setState({ query }, () => {
			this.props.onQueryChange(query)
		})
	}

	onQueryPressEnter = (query) => {
		const trigger = () => {
			this.props.onPressEnter(query)
			this.triggerOnSearch()
		}
		if (query !== this.state.query) {
			this.setState({ query }, trigger)
		} else {
			trigger()
		}
	}

	triggerOnSearch = () => {
		const { query, logType, fields } = this.state
		const { maxLength } = this.props

		if (logType && (maxLength > 0 ? query.length <= maxLength : 1)) {
			this.props.onSearch(query, { query, logType, fields })
		}
	}

	render() {
		const {
			commonFilters,
			className,
			style,
			// schema,
			disabled,
			addonBefore,
			addonAfter,
			addonButton,
			searchButton,
			useCompletion,
			hasLogType,
			onSearch,
			onPressEnter,
			onQueryChange,
			onLogTypeChange,
			onQueryParsed,
			onValueCompletion,
			logType: _pLT, // ignored
			query: _pQ, // ignored
			timeFormat,
			maxLength,
			supportNot,
			supportParen,
			disabledDate,
			valueSuggestions,
			...props
		} = this.props
		const { fields, logType, defaultLogType, query, defaultQuery } = this.state
		const inputDisabled = disabled || !this.state.logType
		return (
			<div style={style} className={classNames(className, 'log-query-wrapper')}>
				<Input.Group compact className="log-query-input-group">
					<LogQueryCompletion
						{...props}
						commonFilters={commonFilters}
						ref={(comp) => (this.queryInputRef = comp)}
						disabled={inputDisabled}
						logType={logType}
						fields={fields || []}
						value={query}
						timeFormat={timeFormat}
						defaultValue={defaultQuery}
						onQueryChange={this.onQueryChange}
						onQueryPressEnter={this.onQueryPressEnter}
						onQueryParsed={onQueryParsed}
						maxLength={maxLength}
						supportNot={supportNot}
						supportParen={supportParen}
						onValueCompletion={onValueCompletion}
						valueSuggestions={valueSuggestions}
					/>
					<div className="log-query-btn" onClick={this.triggerOnSearch}>
						<img alt="search-icon" src={_.isEmpty(query) ? iconSearch : iconSearchActivated} className="search-icon" text="" />
					</div>
				</Input.Group>
			</div>
		)
	}
}

LogQueryComponent.propTypes = {
	commonFilters: PropTypes.array,
	className: PropTypes.string,
	style: PropTypes.object,
	disabled: PropTypes.bool,
	supportNot: PropTypes.bool,
	supportParen: PropTypes.bool,
	schema: PropTypes.object,
	addonBefore: PropTypes.node,
	addonAfter: PropTypes.node,
	addonButton: PropTypes.node,
	searchButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
	useCompletion: PropTypes.bool,
	hasLogType: PropTypes.bool,
	onSearch: PropTypes.func,
	onPressEnter: PropTypes.func,
	onQueryChange: PropTypes.func,
	onLogTypeChange: PropTypes.func,
	onQueryParsed: PropTypes.func,
	onValueCompletion: PropTypes.func,
	disabledDate: PropTypes.func,
	logType: PropTypes.string,
	query: PropTypes.string,
	maxLength: PropTypes.number,
	timeFormat: PropTypes.string
}

LogQueryComponent.defaultProps = {
	commonFilters: [],
	className: '',
	schema: EMPTY_SCHEMA,
	disabled: false,
	supportNot: true,
	supportParen: true,
	addonBefore: null,
	addonAfter: null,
	addonButton: null,
	searchButton: null,
	useCompletion: true,
	hasLogType: true,
	onSearch: _.noop,
	onPressEnter: _.noop,
	onQueryChange: _.noop,
	onLogTypeChange: _.noop,
	onQueryParsed: _.noop,
	disabledDate: undefined,
	logType: '',
	query: '',
	maxLength: 2048,
	timeFormat: 'YYYY/MM/DD HH:mm:ss'
}
