import * as actionTypes from './actionTypes'

export const fetchAlertsMainPageContent = () => ({
	type: actionTypes.FETCH_ALERTS_MAIN_PAGE_CONTENT
})

/*----- Devices Details Actions -----*/
export const fetchDevicesDetailsRequest = (devices, alertsStatus) => ({
	type: actionTypes.FETCH_DEVICES_DETAILS_REQUEST,
	devices,
	alertsStatus
})

export const fetchDevicesDetailsSuccess = (devicesDetails, alertsStatus) => ({
	type: actionTypes.FETCH_DEVICES_DETAILS_SUCCESS,
	devicesDetails,
	alertsStatus
})

export const setDevicesDetailsRequestId = (jobId) => ({
	type: actionTypes.SET_DEVICES_DETAILS_REQUEST_ID,
	jobId
})

export const fetchDevicesDetailsFailure = (error, alertsStatus) => ({
	type: actionTypes.FETCH_DEVICES_DETAILS_FAILURE,
	error,
	alertsStatus
})

export const restoreDevicesDetails = (data) => ({
	type: actionTypes.RESTORE_DEVICES_DETAILS,
	data
})

/*----- Alerts Actions -----*/
export const fetchAlertsRequest = () => ({
	type: actionTypes.FETCH_ALERTS_REQUEST
})

export const fetchAlertsSuccess = (alerts) => ({
	type: actionTypes.FETCH_ALERTS_SUCCESS,
	alerts
})

export const fetchAlertsFailure = (error) => ({
	type: actionTypes.FETCH_ALERTS_FAILURE,
	error
})

export const updateAlertRequest = (alertId, status, assignee) => ({
	type: actionTypes.UPDATE_ALERT_REQUEST,
	alertId,
	status,
	assignee
})

export const updateAlertSuccess = (alert, alertId) => ({
	type: actionTypes.UPDATE_ALERT_SUCCESS,
	alert,
	alertId
})

export const updateAlertFailure = (error) => ({
	type: actionTypes.UPDATE_ALERT_FAILURE,
	error
})

export const restoreAlerts = (data) => ({
	type: actionTypes.RESTORE_ALERTS,
	data
})

export const fetchClosedAlertsRequest = () => ({
	type: actionTypes.FETCH_CLOSED_ALERTS_REQUEST
})

export const fetchClosedAlertsSuccess = (alerts) => ({
	type: actionTypes.FETCH_CLOSED_ALERTS_SUCCESS,
	alerts
})

export const fetchClosedAlertsFailure = (error) => ({
	type: actionTypes.FETCH_CLOSED_ALERTS_FAILURE,
	error
})

export const restoreClosedAlerts = (data) => ({
	type: actionTypes.RESTORE_CLOSED_ALERTS,
	data
})

/*----- Events Actions -----*/
export const fetchEventsRequest = (alertId) => ({
	type: actionTypes.FETCH_EVENTS_REQUEST,
	alertId
})

export const fetchEventsSuccess = (events, alertId) => ({
	type: actionTypes.FETCH_EVENTS_SUCCESS,
	events,
	alertId
})

export const fetchEventsFailure = (error) => ({
	type: actionTypes.FETCH_EVENTS_FAILURE,
	error
})

/*----- Activities Actions -----*/
export const fetchActivitiesRequest = (subject, object_id, alertId) => ({
	type: actionTypes.FETCH_ACTIVITIES_REQUEST,
	subject,
	object_id,
	alertId
})

export const fetchActivitiesSuccess = (activities, alertId) => ({
	type: actionTypes.FETCH_ACTIVITIES_SUCCESS,
	activities,
	alertId
})

export const fetchActivitiesFailure = (error) => ({
	type: actionTypes.FETCH_ACTIVITIES_FAILURE,
	error
})

/*----- Comments Actions -----*/
export const fetchCommentsRequest = (alertId) => ({
	type: actionTypes.FETCH_COMMENTS_REQUEST,
	alertId
})

export const fetchCommentsSuccess = (comments, alertId) => ({
	type: actionTypes.FETCH_COMMENTS_SUCCESS,
	comments,
	alertId
})

export const fetchCommentsFailure = (error) => ({
	type: actionTypes.FETCH_COMMENTS_FAILURE,
	error
})

export const addCommentRequest = (comment, alertId) => ({
	type: actionTypes.ADD_COMMENT_REQUEST,
	comment,
	alertId
})

export const addCommentSuccess = (comment, alertId) => ({
	type: actionTypes.ADD_COMMENT_SUCCESS,
	comment,
	alertId
})

export const addCommentFailure = (error) => ({
	type: actionTypes.ADD_COMMENT_FAILURE,
	error
})

export const updateCommentRequest = (comment, alertId, commentId) => ({
	type: actionTypes.UPDATE_COMMENT_REQUEST,
	comment,
	alertId,
	commentId
})

export const updateCommentSuccess = (comment, alertId, commentId) => ({
	type: actionTypes.UPDATE_COMMENT_SUCCESS,
	comment,
	alertId,
	commentId
})

export const updateCommentFailure = (error) => ({
	type: actionTypes.UPDATE_COMMENT_FAILURE,
	error
})

export const removeCommentRequest = (commentId, alertId) => ({
	type: actionTypes.REMOVE_COMMENT_REQUEST,
	commentId,
	alertId
})

export const removeCommentSuccess = (commentId, alertId) => ({
	type: actionTypes.REMOVE_COMMENT_SUCCESS,
	commentId,
	alertId
})

export const removeCommentFailure = (error) => ({
	type: actionTypes.REMOVE_COMMENT_FAILURE,
	error
})

/*---------------Time Range Actions-------------------*/
export const updateAlertsTimeRange = (timeRange) => ({
	type: actionTypes.UPDATE_ALERTS_TIME_RANGE,
	alertsTimeRange: timeRange
})

export const updateAlertsTimePreset = (timePreset) => ({
	type: actionTypes.UPDATE_ALERTS_TIME_PRESET,
	alertsTimePreset: timePreset
})

export const setAlertsTimeRange = (timeRange) => ({
	type: actionTypes.SET_ALERTS_TIME_RANGE,
	alertsTimeRange: timeRange
})

export const setAlertsTimePreset = (timePreset) => ({
	type: actionTypes.SET_ALERTS_TIME_PRESET,
	alertsTimePreset: timePreset
})

/*----- Poll Job Actions -----*/
export const pollAlertsMainPageJobRequest = (jobId, jobType, jobPage, jobLimit, params) => ({
	type: actionTypes.POLL_ALERTS_MAIN_PAGE_JOB_REQUEST,
	jobId,
	jobType,
	jobPage,
	jobLimit,
	params
})

export const pollAlertsMainPageJobFailure = (jobId, error) => ({
	type: actionTypes.POLL_ALERTS_MAIN_PAGE_JOB_FAILURE,
	jobId,
	error
})
