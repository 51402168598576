import React, { Component } from 'react'
import iconArrowRight from '../../images/icon-arrow-right.svg'
import './DetailsPageHeader.scss'

export default class DetailsPageHeader extends Component {
	render() {
		const { pageDesc, pageTitle, parentPageName, currentPageName, onParentLinkClicked } = this.props
		return (
			<div className="page-header">
				<div className="nav">
					<div className="parent" onClick={onParentLinkClicked}>
						{parentPageName}
					</div>
					<img src={iconArrowRight} alt="arrow-right" />
					<div className="current">{currentPageName}</div>
				</div>
				<div className="title">{pageTitle}</div>
				<div className="desc">
					{Object.entries(pageDesc).map((attr, index) => (
						<div key={index}>
							<div className="key">{attr[0]}</div>
							<div className="value">{attr[1]}</div>
						</div>
					))}
				</div>
			</div>
		)
	}
}
