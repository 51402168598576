import React from 'react'
import iconCritical from '../../../images/icon-critical.svg'
import iconWarning from '../../../images/icon-warning.svg'
import iconHealty from '../../../images/icon-healthy.svg'
import iconArrowDown from '../../../images/icon-arrow-down.svg'

const iconMap = { critical: iconCritical, warning: iconWarning, healthy: iconHealty }

export default class SeverityCellRenderer extends React.Component {
	state = {
		isExpanded: false
	}
	static getDerivedStateFromProps(nextProps) {
		return { isExpanded: nextProps.node.expanded }
	}
	render() {
		const { data } = this.props
		let cellContent
		if (data.row_desc === 'show_closed') {
			cellContent = (
				<div className="severity-cell">
					<img className="severity-icon" src={iconMap['healthy']} alt={'healthy_icon'} />
				</div>
			)
		} else {
			cellContent = (
				<div
					className="severity-cell"
					onClick={() => {
						if (this.state.isExpanded) {
							this.props.onCloseDetails(data.alert_id)
						} else {
							this.props.onOpenDetails(data.alert_id)
						}
						this.setState({ isExpanded: !this.state.isExpanded })
						this.props.node.setExpanded(!this.state.isExpanded)
					}}
				>
					<img className={`arrow-icon ${this.state.isExpanded ? 'up' : 'down'}`} src={iconArrowDown} alt="expand-icon" />
					<img className="severity-icon" src={iconMap[data.severity]} alt={data.severity} />
				</div>
			)
		}
		return cellContent
	}
}
