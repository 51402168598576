import * as THREE from '../../../../../../vendor/three.module.js'
import React from 'react'
import PropTypes from 'prop-types'
import { extent as d3_extent } from 'd3-array'

import DefaultDefs from './DefaultDefs.js'

import layoutElements from '../helpers/layoutElements.js'
import calculateZoom from '../helpers/calculateZoom.js'
import gridSpaceToScreenSpace from '../helpers/gridSpaceToScreenSpace.js'
import renderExpandedElement from './renderExpandedElement.js'

import hexagonSRC from '../resources/flat-hexagon.json.js'
import geometryIconNoData from '../resources/iconNodata.json.js'
import geometryIconCritical from '../resources/iconCritical.json.js'
import geometryIconHealthy from '../resources/iconHealthy.json.js'
import geometryIconWarning from '../resources/iconWarning.json.js'

import tupleSearch from '../util/tupleSearch.js'

class HexagonResources3D extends React.Component {
	static propTypes = {
		activeElements: PropTypes.array,
		lockedElements: PropTypes.array,
		dragAndZoom: PropTypes.bool,
		gridHeight: PropTypes.number,
		graphicAttribute: PropTypes.string,
		expandedKeys: PropTypes.array,
		sortBy: PropTypes.string,
		height: PropTypes.string,
		highlight: PropTypes.array,
		onMouseOver: PropTypes.func,
		onMouseOut: PropTypes.func,
		onMouseClick: PropTypes.func,
		elementSize: PropTypes.number,
		defs: PropTypes.node,
		elements: PropTypes.array,
		assets: PropTypes.array
	}

	static defaultProps = {
		activeElements: [],
		lockedElements: [],
		dragAndZoom: true,
		gridHeight: null,
		graphicAttribute: 'severity',
		expandedKeys: [
			{ label: 'HOST NAME', key: 'name' },
			{ label: 'IP ADDRESS', key: 'ip-address' },
			{ label: 'MODEL', key: 'model' }
		],
		expandedKeysMetrics: [{ label: 'METRIC', key: 'name' }],
		sortBy: 'none',
		filter: [],
		height: '400px',
		onMouseOver: (element) => {},
		onMouseOut: (element) => {},
		onClick: (element) => {},
		defaultZoom: 1,
		elementSize: 55,
		defs: <DefaultDefs />,
		elements: [],
		assets: [
			{
				key: 'critical',
				value: {
					expanded: { img: '#expanded-hexagon-red', offset: { x: 0, y: 0 }, scale: 1 }
				}
			},
			{
				key: 'warning',
				value: {
					expanded: { img: '#expanded-hexagon-yellow', offset: { x: 0, y: 0 }, scale: 1 }
				}
			},
			{
				key: 'nodata',
				value: {
					expanded: { img: '#expanded-hexagon-blue', offset: { x: 0, y: 0 }, scale: 1 }
				}
			},
			{
				key: 'healthy',
				value: {
					expanded: { img: '#expanded-hexagon-green', offset: { x: 0, y: 0 }, scale: 1 }
				}
			}
		]
	}

	onSearch = (query) => {
		this.props.setInsightsQuery(query)
		this.props.resetInsightsPage()
		this.props.fetchInsightsLogsRequest()
		this.props.fetchInsightsLogsCountRequest()
	}

	constructor(props) {
		super(props)

		this.layoutElements = layoutElements.bind(this)
		this.calculateZoom = calculateZoom.bind(this)
		this.gridSpaceToScreenSpace = gridSpaceToScreenSpace.bind(this)
		this.renderExpandedElement = renderExpandedElement.bind(this)

		this.divRef = React.createRef()

		this.state = {
			svgHeight: this.props.svgHeight,
			svgWidth: this.props.svgWidth,
			zoom: 1,
			targetZoom: 0,
			zoomLayer: {},
			renderer: {}
		}
		this.state.elements = this.layoutElements()
		this.state.targetZoom = this.calculateZoom(this.state.elements)

		this.frameID = null
		this.animationPaused = false

		this.memo = {
			sortBy: this.props.sortBy,
			booted: false,
			filter: JSON.parse(JSON.stringify(this.props.filter))
		}
	}

	initialize3DEnvironment() {
		let camera, scene, renderer
		let raycaster, mouse
		let hexagonMesh, iconCriticalMesh, iconWarningMesh, iconHealthyMesh, iconNoDataMesh
		const intersect = []

		this.state.elements = this.layoutElements()

		const init = () => {
			this.state.materials = {
				blue: { lowOpacity: {}, map: {}, color: 0xffffff },
				red: { lowOpacity: {}, map: {}, color: 0xd42020 }, // red target - d42020
				green: { lowOpacity: {}, map: {}, color: 0x00b52d }, // green target - 00b52d
				yellow: { lowOpacity: {}, map: {}, color: 0xffd92c }, // yellow target - 0xffd92c
				white: { lowOpacity: {}, map: {}, color: 0xffffff }
			}

			const loadMaterials = Object.keys(this.state.materials).map((name) => {
				const material = this.state.materials[name]
				return (done) => {
					material.map = new THREE.MeshPhongMaterial({
						color: material.color,
						transparent: false,
						// shininess: 100,
						reflectivity: 0.3
						// side: THREE.DoubleSide
					})
					material.lowOpacity = new THREE.MeshPhongMaterial({
						color: material.color,
						transparent: true,
						opacity: 0.5
						// side: THREE.DoubleSide
					})
					done()
				}
			})

			const fns = loadMaterials.concat([
				(done) => {
					var loader = new THREE.ObjectLoader()
					// load a resource
					hexagonMesh = loader.parse(hexagonSRC())
					iconCriticalMesh = loader.parse(geometryIconCritical())
					iconWarningMesh = loader.parse(geometryIconWarning())
					iconHealthyMesh = loader.parse(geometryIconHealthy())
					iconNoDataMesh = loader.parse(geometryIconNoData())
					done()
				},
				(done) => {
					const zoom = this.calculateZoom(this.layoutElements(this.filterElements()))
					this.state.zoom = zoom
					this.setState({ zoom })

					var frustumSize = this.props.svgHeight * 1.67
					var aspect = this.props.svgWidth / this.props.svgHeight
					camera = new THREE.OrthographicCamera((frustumSize * aspect) / -2, (frustumSize * aspect) / 2, frustumSize / 2, frustumSize / -2, 1, 2000)
					// camera = new THREE.PerspectiveCamera( 45, this.props.svgWidth / this.props.svgHeight, 1, 1000 );

					this.state.camera = camera
					window.camera = camera
					this.state.camera.targetPosition = new THREE.Vector3(0, 0, 0)
					this.state.camera.targetPosition.z = 100
					this.state.camera.position.z = 100

					raycaster = new THREE.Raycaster()
					mouse = new THREE.Vector2(0, 0)

					this.clock = new THREE.Clock()
					this.clock.start()

					scene = new THREE.Scene()
					window.scene = scene
					camera.lookAt(scene.position)

					this.state.zoomLayer = new THREE.Object3D()
					window.zoomLayer = this.state.zoomLayer
					this.state.zoomLayer.position.set(0, 0, -500)
					this.state.zoomLayer.targetScale = new THREE.Vector3(zoom, zoom, zoom)
					this.state.zoomLayer.scale.set(zoom * 0.5, zoom * 0.5, zoom * 0.5)
					scene.add(this.state.zoomLayer)

					var geometry = new THREE.PlaneBufferGeometry(5000, 5000, 32)
					var material = new THREE.MeshPhongMaterial({ color: 0xffff00, side: THREE.DoubleSide })
					var plane = new THREE.Mesh(geometry, material)
					plane.position.z = -600
					plane.receiveShadow = true
					// scene.add( plane );

					const lightPositions = [
						// { x: -200, y: 200, z: 200, intensity: 1 },
						{ x: 0, y: 200, z: 0, intensity: 0.9 }
						// { x: 200, y: 100, z: 100, intensity: 1 },
					].map((p) => {
						const directionalLight = new THREE.DirectionalLight(0xffffff, p.intensity)
						directionalLight.position.set(p.x, p.y, p.z)
						directionalLight.castShadow = true
						directionalLight.shadow.mapSize.width = 2048
						directionalLight.shadow.mapSize.height = 2048
						directionalLight.shadow.camera.near = 1
						directionalLight.shadow.camera.far = 3000
						const d = this.props.svgWidth
						directionalLight.shadow.camera.left = -d
						directionalLight.shadow.camera.right = d
						directionalLight.shadow.camera.top = d
						directionalLight.shadow.camera.bottom = -d
						directionalLight.lookAt(this.state.zoomLayer.position)
						directionalLight.target = this.state.zoomLayer
						scene.add(directionalLight)
						window.light = directionalLight
						// var shadowHelper = new THREE.CameraHelper( directionalLight.shadow.camera )
						// scene.add( shadowHelper )
					})

					renderer = new THREE.WebGLRenderer({ antialias: true })
					this.state.renderer = renderer
					renderer.setClearColor(0xffffff, 1)
					renderer.setSize(this.props.svgWidth, this.props.svgHeight)
					renderer.setPixelRatio(window.devicePixelRatio)
					renderer.shadowMap.enabled = true
					renderer.shadowMap.type = THREE.PCFSoftShadowMap
					renderer.toneMapping = THREE.LinearToneMapping
					window.renderer = renderer
					window.THREE = THREE

					// add to scene
					this.divRef.current.appendChild(renderer.domElement)
					this.divRef.current.addEventListener('mousemove', onDocumentMouseMove, false)
					this.divRef.current.addEventListener('click', onDocumentClick, false)

					const mapLUT = {
						critical: 'red',
						nodata: 'blue',
						warning: 'yellow',
						healthy: 'green'
					}

					// const _scale = 27
					this.state.elements
						.sort((a, b) => {
							return b.targetP.y - a.targetP.y
						})
						.map((element, idx) => {
							const severity = tupleSearch(element.data.attributes, 'severity').value
							const __s = 53
							const object3D = new THREE.Object3D()
							object3D.position.set(0, 0, 0)
							object3D.scale.set(__s, __s, __s)
							object3D.rotation.set(element.rotation.x, element.rotation.y, element.rotation.z)

							const _x = new THREE.Mesh(hexagonMesh.geometry.clone(), this.state.materials[mapLUT[severity]].map)
							_x.elementID = element.id
							_x.castShadow = true
							_x.receiveShadow = true
							// _x.scale.set(1.025,1.025,1.025)
							object3D.add(_x)
							object3D.elementID = element.id

							const iconMeshLUT = {
								critical: iconCriticalMesh,
								healthy: iconHealthyMesh,
								warning: iconWarningMesh,
								nodata: iconNoDataMesh,
								undefined: iconNoDataMesh
							}

							const _icon = new THREE.Mesh(iconMeshLUT[severity].geometry.clone(), this.state.materials['white'].map)
							_icon.position.y = -0.1
							_icon.rotation.set(Math.PI, -Math.PI * 0.5, 0)
							window.icon = _icon
							object3D.add(_icon)

							intersect.push(_x)
							element.object3D = object3D
							this.state.zoomLayer.add(object3D)
						})
					done()
				},
				(done) => {
					animate()
					done()
				}
			])

			let fnToRun = -1
			function done() {
				fnToRun += 1
				if (fns[fnToRun] === undefined) {
					return
				}
				fns[fnToRun](done)
			}
			done()
		}

		const onDocumentClick = () => {
			if (this.props.activeElements.length === 0) {
				return
			}
			const _id = this.props.activeElements[0]
			const o = this.props.elements.filter((o) => {
				return o.id === _id
			})[0]
			if (this.props.lockedElements.indexOf(o.id) === -1) {
				this.props.lockedElements.push(o.id)
			} else {
				const newArray = this.props.lockedElements.filter((_id) => {
					return o.id !== _id
				})
				while (this.props.lockedElements.length > 0) {
					this.props.lockedElements.pop()
				}
				newArray.map((o) => {
					this.props.lockedElements.push(o)
				})
			}
			const mapLUT = {
				critical: 'red',
				nodata: 'blue',
				warning: 'yellow',
				healthy: 'green'
			}
			this.state.elements.forEach((element) => {
				const severity = tupleSearch(element.data.attributes, 'severity').value
				if (this.props.lockedElements.length > 0 && this.props.lockedElements.indexOf(element.id) >= 0) {
					element.object3D.children[0].material = this.state.materials[mapLUT[severity]].map
				}
			})
			let query = ''
			this.props.lockedElements.forEach((lockedEl) => {
				const item = this.state.elements.find((el) => el.id === lockedEl)
				query += `${query === '' ? '' : ' OR '}${item.data.queryInfo.key} = '${item.data.queryInfo.value}'`
			})
			this.onSearch(query.trim())
			this.forceUpdate()
			this.props.onClick(o.data)
			if (this.animationPaused === true) {
				this.animationPaused = false
				requestAnimationFrame(this.animate)
			}
		}

		const onDocumentMouseMove = (event) => {
			const mapLUT = {
				critical: 'red',
				nodata: 'blue',
				warning: 'yellow',
				healthy: 'green'
			}

			event.preventDefault()
			let doChange = false
			mouse.x = (event.layerX / this.props.svgWidth) * 2 - 1
			mouse.y = -(event.layerY / this.props.svgHeight) * 2 + 1
			camera.updateMatrixWorld()
			raycaster.setFromCamera(mouse, camera)
			var intersects = raycaster.intersectObjects(intersect, true)
			let INTERSECT
			const oldActiveElements = JSON.stringify(this.props.activeElements)
			while (this.props.activeElements.length > 0) {
				this.props.activeElements.pop()
			}
			if (intersects.length > 0) {
				for (let i = 0; i < 1; i++) {
					INTERSECT = intersects[i]
					this.props.activeElements.push(INTERSECT.object.elementID)
				}
			}

			this.state.elements.forEach((element) => {
				const severity = tupleSearch(element.data.attributes, 'severity').value
				element.object3D.children[0].material = this.state.materials[mapLUT[severity]].map
				if (this.props.lockedElements.length > 0 && this.props.lockedElements.indexOf(element.id) === -1) {
					element.object3D.children[0].material = this.state.materials[mapLUT[severity]].lowOpacity
				} else if (this.props.lockedElements.length > 0 && this.props.lockedElements.indexOf(element.id) >= 0) {
					element.object3D.children[0].material = this.state.materials[mapLUT[severity]].map
				} else if (this.props.activeElements.length > 0 && this.props.activeElements.indexOf(element.id) === -1) {
					element.object3D.children[0].material = this.state.materials[mapLUT[severity]].lowOpacity
				}

				if (this.props.activeElements.length > 0 && this.props.activeElements.indexOf(element.id) >= 0) {
					element.object3D.children[0].material = this.state.materials[mapLUT[severity]].map
				}
			})
			if (oldActiveElements !== JSON.stringify(this.props.activeElements)) {
				this.forceUpdate()
			}
			if (this.animationPaused === true) {
				this.animationPaused = false
				requestAnimationFrame(this.animate)
			}
		}

		const animate = () => {
			if (!this.state) return
			const delta = Math.min(this.clock.getDelta() * 120, 8)
			const tickSegments = 18

			if (this.props.svgWidth === -1) {
				return
			}

			let didAny = false

			const mapLUT = {
				critical: 'red',
				nodata: 'blue',
				warning: 'yellow',
				healthy: 'green'
			}
			this.state.elements.forEach((e) => {
				if (e.doChange === false) {
					return
				}

				const severity = tupleSearch(e.data.attributes, 'severity').value
				e.object3D.children[0].material = this.state.materials[mapLUT[severity]].map
				if (this.props.lockedElements.length > 0 && this.props.lockedElements.indexOf(e.id) === -1) {
					e.object3D.children[0].material = this.state.materials[mapLUT[severity]].lowOpacity
				} else if (this.props.lockedElements.length > 0 && this.props.lockedElements.indexOf(e.id) >= 0) {
					e.object3D.children[0].material = this.state.materials[mapLUT[severity]].map
				} else if (this.props.activeElements.length > 0 && this.props.activeElements.indexOf(e.id) === -1) {
					e.object3D.children[0].material = this.state.materials[mapLUT[severity]].lowOpacity
				}

				if (this.props.activeElements.length > 0 && this.props.activeElements.indexOf(e.id) >= 0) {
					e.object3D.children[0].material = this.state.materials[mapLUT[severity]].map
				}
				e.p.x += delta * ((e.targetP.x - e.p.x) / tickSegments)
				e.p.y += delta * ((e.targetP.y - e.p.y) / tickSegments)
				e.p.z += delta * ((e.targetP.z - e.p.z) / tickSegments)

				e.object3D.rotation.x += delta * ((e.targetRotation.x - e.object3D.rotation.x) / tickSegments)
				e.object3D.rotation.y += delta * ((e.targetRotation.y - e.object3D.rotation.y) / tickSegments)
				e.object3D.rotation.z += delta * ((e.targetRotation.z - e.object3D.rotation.z) / tickSegments)

				if (Math.abs(e.p.x - e.targetP.x) > 0.001 || Math.abs(e.object3D.rotation.x - e.targetRotation.x) > 0.001) {
					didAny = true
				}

				e.object3D.position.set(e.p.x, -e.p.y, e.p.z)
			})
			this.animationPaused = !didAny
			if (this.state.zoomLayer.scale.x !== this.state.zoomLayer.targetScale.x) {
				if (Math.abs(this.state.zoomLayer.targetScale.x - this.state.zoomLayer.scale.x) < 0.0001) {
					this.state.zoomLayer.scale.x = this.state.zoomLayer.targetScale.x
					this.state.zoomLayer.scale.y = this.state.zoomLayer.targetScale.y
					this.state.zoomLayer.scale.z = this.state.zoomLayer.targetScale.z
				} else {
					this.state.zoomLayer.scale.x += delta * ((this.state.zoomLayer.targetScale.x - this.state.zoomLayer.scale.x) / tickSegments)
					this.state.zoomLayer.scale.y += delta * ((this.state.zoomLayer.targetScale.y - this.state.zoomLayer.scale.y) / tickSegments)
					this.state.zoomLayer.scale.z += delta * ((this.state.zoomLayer.targetScale.z - this.state.zoomLayer.scale.z) / tickSegments)
				}
			}

			camera.lookAt(this.state.zoomLayer.position)
			camera.updateMatrixWorld()

			if (this.animationPaused === false) {
				this.frameID = requestAnimationFrame(animate)
			}

			return renderer.render(scene, camera)
		}

		this.animate = animate

		init()
	}

	componentDidUpdate(prevProps) {
		if (!_.isEqual(prevProps.lockedElements, this.props.lockedElements)) {
			this.animate()
		}
		if (this.props.activeElements.length > 0) {
			document.body.style.cursor = 'pointer'
		} else {
			document.body.style.cursor = 'default'
		}
		if (prevProps.isNavOpen !== this.props.isNavOpen) {
			this.divRef.current.dispatchEvent(new Event('mousemove'))
		}
		// catch the very first render event
		if (this.props.svgHeight !== -1 && this.props.svgWidth !== -1 && this.props.elements.length !== 0 && this.memo.booted === false) {
			// set the flag that only allows the routine to run once
			this.memo.booted = true
			// boot up the entire app
			this.initialize3DEnvironment()
		}

		// have the filters been updated?
		// (if yes, hide the removed elements and move the elements to their new position)
		if (JSON.stringify(this.props.filter) !== JSON.stringify(this.memo.filter)) {
			// filters changed
			const newElements = this.layoutElements()
			if (newElements.length === 0) {
				this.state.elements.map((o) => {
					o.targetP.y += 400
					o.object3D.rotation.set(0, 0, -Math.PI * 2 * Math.random())
				})
			} else {
				this.state.elements.forEach((e) => {
					e.object3D.scale.set(0, 0, 0)
					e.active = false
				})
				let bailedOut = false
				newElements.forEach((e, i) => {
					if (bailedOut) {
						return
					}
					try {
						const otherElement = this.state.elements.filter((o) => {
							return o.id === e.id
						})[0]
						otherElement.x = e.x
						otherElement.y = e.y
						otherElement.z = e.y * -0.3
						otherElement.targetP.x = e.targetP.x
						otherElement.targetP.y = e.targetP.y
						otherElement.targetP.z = e.targetP.z
						otherElement.scale = 0.1
						otherElement.object3D.rotation.set(0, 0, -Math.PI * 2 * Math.random())
						otherElement.doChange = true
						otherElement.active = true
						otherElement.object3D.scale.set(53, 53, 53)
					} catch (e) {
						bailedOut = true
						this.props.resetEverything()
					}
				})
				this.state.zoom = this.calculateZoom(newElements)
			}
			this.state.elements.labels = newElements.labels
			this.memo.filter = JSON.parse(JSON.stringify(this.props.filter))
			// const yRange = 100
			this.state.zoomLayer.targetScale.set(this.state.zoom, this.state.zoom, this.state.zoom)
			this.forceUpdate()
			if (this.animationPaused === true) {
				this.animationPaused = false
				this.clock = new THREE.Clock()
				requestAnimationFrame(this.animate)
			}
		}

		// if there is a mismatch with the height and width of the parent component
		// change the size of the renderer and adjust the camera settings
		if (this.props.svgHeight !== this.props.svgHeight || this.props.svgWidth !== this.props.svgWidth) {
			const rect = this.props.svgRef.current.getBoundingClientRect()
			let actualWidth = Math.max(500, window.innerWidth - rect.x)
			if (this.state.renderer.setSize) {
				const frustumSize = 500
				const aspect = actualWidth / this.props.svgHeight
				this.state.camera.left = (-frustumSize * aspect) / 2
				this.state.camera.right = (frustumSize * aspect) / 2
				this.state.camera.top = frustumSize / 2
				this.state.camera.bottom = -frustumSize / 2
				this.state.camera.updateProjectionMatrix()
				this.state.renderer.setSize(actualWidth, this.props.svgHeight)
			}
			this.setState({
				svgWidth: this.props.svgWidth,
				svgHeight: this.props.svgHeight
			})
			if (this.animationPaused === true) {
				this.animationPaused = false
				requestAnimationFrame(this.animate)
			}
		}

		// did the sortBy prop change?
		// (yes, find new positions for the elements and move them there)
		if (this.memo.sortBy !== this.props.sortBy) {
			let bailedOut = false
			const newElements = this.layoutElements()
			newElements.forEach((e, i) => {
				if (bailedOut) {
					return
				}
				const otherElement = this.state.elements.filter((o) => {
					return o.id === e.id
				})[0]
				try {
					otherElement.targetP.x = e.targetP.x
					otherElement.targetP.y = e.targetP.y
					otherElement.targetP.z = e.targetP.z
					otherElement.scale = 0.1
					otherElement.object3D.rotation.set(0, 0, -Math.PI * 2 * Math.random())
					otherElement.doChange = true
				} catch (error) {
					bailedOut = true
					this.props.resetEverything()
				}
			})

			this.state.elements.labels = newElements.labels
			this.memo.sortBy = this.props.sortBy

			this.setState({
				elements: this.state.elements
			})
			if (this.animationPaused === true) {
				this.animationPaused = false
				requestAnimationFrame(this.animate)
			}
		}
	}

	componentDidMount() {
		// console.log('threejs component component did mount!')
	}

	componentWillUnmount() {
		// console.log('threejs component will unmount!')
		cancelAnimationFrame(this.frameID)
		this.state = null
	}

	render() {
		const selectedLayer1 = (
			<g>
				{this.state.elements.map((o, i) => {
					if (this.props.activeElements.indexOf(o.id) !== -1) {
						return this.renderExpandedElement(o, i)
					} else {
						return
					}
				})}
			</g>
		)
		const selectedLayer2 = (
			<g>
				{this.state.elements.map((o, i) => {
					if (this.props.lockedElements.length === 0) {
						return
					}
					if (this.props.lockedElements.indexOf(o.id) !== -1) {
						return
					}
					if (this.props.activeElements.indexOf(o.id) === -1) {
						return
					}
					return this.renderExpandedElement(o, i)
				})}
			</g>
		)

		const containerStyle = {
			position: 'relative',
			width: '100%',
			overflowX: 'none'
		}

		const overlayStyle = {
			position: 'absolute',
			top: 0,
			left: 0,
			userSelect: 'none',
			pointerEvents: 'none',
			width: '100%',
			height: '100%'
		}

		const len = Object.keys(this.state.elements.labels).length
		const elementYRange = d3_extent(
			this.state.elements.filter((o) => {
				return o.active
			}),
			(d) => {
				return d.y
			}
		)
		const gridLabels = Object.keys(this.state.elements.labels).map((n, i) => {
			const o = this.state.elements.labels[n]
			const y = elementYRange[0] - 2
			const p = this.gridSpaceToScreenSpace({ x: o.x, y: y })
			const p2 = this.gridSpaceToScreenSpace({ x: o.width - (i === len - 1 ? 0 : 2), y: y })
			const key = [this.memo.filter.length === 0 ? 'noFilter' : this.memo.filter[0].key + this.memo.filter[0].value, this.props.sortBy, 'group-labels', n, i].join('')
			return (
				<g key={key} transform={['translate(', p.x + p2.x / 2, p.y, ')'].join(' ')}>
					<g>
						<text
							transform={['scale(', 2 * (1 / this.state.zoom), ')'].join(' ')}
							dy="0.33em"
							textAnchor="middle"
							fontSize="10px"
							letterSpacing=".5px"
							fill="#7F868C"
							fontWeight="700"
							style={{ textTransform: 'uppercase' }}
						>
							{n}
						</text>
					</g>
				</g>
			)
		})

		if (this.props.svgHeight === -1) {
			// the component is not initialized, return nothing
			return (
				<div
					ref={this.props.svgRef}
					style={{
						height: this.props.height
					}}
				>
					<svg style={overlayStyle}></svg>
				</div>
			)
		}

		return (
			<div style={containerStyle}>
				<div ref={this.divRef} style={{ height: this.props.height }} />
				<svg ref={this.props.svgRef} style={overlayStyle}>
					<defs>{this.props.defs}</defs>
					<g transform={['translate(', this.props.svgWidth * 0.5, this.props.svgHeight * 0.5, ')'].join(' ')}>
						<g transform={['scale(', this.state.zoom / 1.67, ')'].join(' ')}>
							{gridLabels}
							{selectedLayer1}
							{selectedLayer2}
						</g>
					</g>
				</svg>
			</div>
		)
	}
}

export default HexagonResources3D
