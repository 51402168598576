import React, { Component } from 'react'
import { connect } from 'ui-lib'
import detailsSchema from '../../../../visibility/util/schema/insights/details-presentation.json'
import relatedMetricsSchema from '../../../../visibility/util/schema/insights/related-metrics-presentation.json'
import SeverityCellRenderer from '../../components/CellRenderers/SeverityCellRenderer'
import RelatedMetricSeverityCellRenderer from '../../components/CellRenderers/RelatedMetricSeverityCellRenderer'
import LastUpdateHeaderCellRenderer from '../../components/CellRenderers/LastUpdatedHeaderCellRenderer/LastUpdateHeaderCellRenderer'
import LastUpdatedCellRenderer from '../../components/CellRenderers/LastUpdatedCellRenderer'
import Util from '../../utils/Util'
import Constants from '../../utils/Constants.json'
import iconService from '../../../images/icon-service.svg'
import iconHelp from '../../../images/icon-help.svg'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'
import SyncChart from './components/SyncChart/SyncChart'
import DetailsPageHeader from '../../../components/DetailsPageHeader/DetailsPageHeader'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import './LineMetricDetails.scss'

import * as actions from './store/actions'
import * as insightsActions from '../../store/actions'

class LineMetricDetails extends Component {
	componentDidMount() {
		const chart = document.getElementsByClassName('line-metric-details')[0]
		if (chart) chart.scrollIntoView()
		this.props.fetchLineMetricDetailsPageContent()
	}

	componentDidUpdate(prevProps) {
		if (_.get(prevProps, `insightsTabs.custom_tabs[${prevProps.activeInsightsTab}].id`) !== _.get(this.props, `insightsTabs.custom_tabs[${this.props.activeInsightsTab}].id`)) {
			const chart = document.getElementsByClassName('line-metric-details')[0]
			if (chart) chart.scrollIntoView()
			this.props.fetchLineMetricDetailsPageContent()
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api
		this.gridColumnApi = params.columnApi
	}

	createMiscHTML = (snippet) => ({ __html: snippet })

	render() {
		const { linePageDescLoading, linePageDescError } = this.props

		if (linePageDescError) {
			return (
				<div className="info-area">
					<div>The requested tab cannot be loaded</div>
				</div>
			)
		} else if (linePageDescLoading) {
			return (
				<div className="info-area">
					<img className="loading-spinner" src={iconLoading} alt={'loading'} />
					Loading Data
				</div>
			)
		} else {
			const {
				relatedMetrics,
				relatedMetricsError,
				relatedMetricsLoading,
				metricsMisc,
				checkedRelatedMetrics,
				updateCheckedRelatedMetrics,
				linePageDesc,
				activeInsightsTab,
				switchInsightsTab
			} = this.props
			const misc = metricsMisc[Util.getGenericMetricName(linePageDesc.metric)] ? metricsMisc[Util.getGenericMetricName(linePageDesc.metric)] : {}

			const colDefConverter = (schema) => {
				return schema.fields.map((field) => {
					const col = {
						headerName: field.displayName,
						field: field.name,
						colId: field.name,
						flex: null,
						width: field.defaultWidth,
						suppressMenu: true,
						valueFormatter: (params) => {
							if (['true', 'false', 'yes', 'no'].includes(params.value)) {
								return _.capitalize(params.value)
							} else {
								return params.value
							}
						}
					}
					if (col.field === 'metric') {
						col.flex = 1
						col.valueFormatter = (params) => {
							return Util.getMetricDisplayName(metricsMisc, params.value)
						}
					}
					if (col.field === 'metric_value_pct') {
						col.cellRenderer = 'severityCellRenderer'
					}
					if (col.field === 'timegenerated' || col.field === 'update_date') {
						col.cellRenderer = 'LastUpdatedCellRenderer'
						col.headerComponent = 'LastUpdateHeaderCellRenderer'
					}
					return col
				})
			}
			const metricInfoCols = colDefConverter(detailsSchema)
			const chartInfoCols = colDefConverter(relatedMetricsSchema)
			chartInfoCols.forEach((col) => {
				if (col.field === 'timegenerated') {
					col.cellRenderer = 'LastUpdatedCellRenderer'
					col.headerComponent = 'LastUpdateHeaderCellRenderer'
					col.cellClass = 'timestamp'
				}
				if (col.field === 'severity') {
					col.cellRenderer = 'relatedMetricSeverityCellRenderer'
					col.cellRendererParams = {
						state: { ...checkedRelatedMetrics },
						toggleCheck: (metric, checkStatus) => {
							updateCheckedRelatedMetrics({ ...checkedRelatedMetrics, [metric]: checkStatus })
							setTimeout(() => {
								this.gridApi.redrawRows()
							}, 0)
						}
					}
				}

				if (col.field === 'metric_value_pct') {
					col.cellRenderer = null
					col.valueFormatter = (params) => {
						const data = params.data
						return data.metric in Constants.TABLE_CHART_INFO_MAP
							? ''
							: data.metric_value_pct === null || isNaN(data.metric_value_pct)
							? data.metric_value_raw === null || isNaN(data.metric_value_raw)
								? ''
								: Util.getReadableNumber(data.metric_value_raw, 0)
							: `${Math.round(data.metric_value_pct)}%`
					}
				}
			})

			let relatedMetricsHeight = 56
			let showScroll = false
			if (relatedMetrics) {
				relatedMetricsHeight = relatedMetrics.length * 28
				// if (relatedMetrics.length > 5) {
				// 	relatedMetricsHeight = 5 * 28
				// 	showScroll = true
				// } else if (relatedMetrics.length > 0) {
				// 	relatedMetricsHeight = relatedMetrics.length * 28
				// }
			}

			if (!metricsMisc[Util.getGenericMetricName(linePageDesc.metric)]) {
				return <div class="info-area">Invalid Metric Name</div>
			}
			return (
				<div className="line-metric-details">
					<DetailsPageHeader
						pageDesc={{
							'Serial Number:': linePageDesc.serial,
							'Model:': linePageDesc.model,
							'SW Version:': linePageDesc.sw_version,
							'IP Address:':
								!_.isString(linePageDesc.ip_address) && !_.isString(linePageDesc.ipv6_address)
									? 'N/A'
									: linePageDesc.ip_address !== 'unknown'
									? linePageDesc.ip_address
									: linePageDesc.ipv6_address
						}}
						pageTitle={`${Util.getMetricDisplayName(metricsMisc, linePageDesc.metric)} - ${linePageDesc.hostname}`}
						parentPageName={'Insights'}
						currentPageName={'Metric Details'}
						onParentLinkClicked={() => switchInsightsTab(activeInsightsTab, Constants.FIXED_TABS[0])}
					/>
					<div className="metric-details">
						<div className={'master-detail-grid ag-theme-balham'} style={{ height: '100%' }}>
							<AgGridReact
								frameworkComponents={{ severityCellRenderer: SeverityCellRenderer, LastUpdateHeaderCellRenderer, LastUpdatedCellRenderer }}
								columnDefs={metricInfoCols}
								rowData={[linePageDesc]}
								suppressCsvExport={true}
								suppressExcelExport={true}
								suppressDragLeaveHidesColumns={true}
							/>
						</div>
					</div>
					<div className="metric-desc">
						<div className="desc">
							<div className="title">metric description</div>
							<div className="content" dangerouslySetInnerHTML={this.createMiscHTML(misc.description)} />
						</div>
						<div className="impact">
							<div className="title">impact</div>
							<div className="content" dangerouslySetInnerHTML={this.createMiscHTML(misc.impacted_features)} />
						</div>
						{'related_metrics' in misc && misc.related_metrics.trim() === '' ? null : (
							<div className="related-metrics">
								<div className="title">Related Metrics</div>
								<div className={`content ${showScroll ? ' show-scroll' : ''}`} style={{ height: relatedMetricsHeight }}>
									{relatedMetricsError ? (
										<div className="info-area">Error Fetching Line Chart Data</div>
									) : relatedMetricsLoading ? (
										<div className="info-area">
											<img className="loading-spinner" src={iconLoading} alt={'loading'} />
											Loading Data
										</div>
									) : relatedMetrics && relatedMetrics.length === 0 ? (
										<div className="info-area">No Data Available</div>
									) : (
										<div className={'master-detail-grid ag-theme-balham'} style={{ height: '100%' }}>
											<AgGridReact
												headerHeight={0}
												frameworkComponents={{
													severityCellRenderer: SeverityCellRenderer,
													relatedMetricSeverityCellRenderer: RelatedMetricSeverityCellRenderer,
													LastUpdateHeaderCellRenderer,
													LastUpdatedCellRenderer
												}}
												columnDefs={chartInfoCols}
												rowData={relatedMetrics}
												getRowClass={(params) => {
													const checkedRelatedMetrics = this.props.checkedRelatedMetrics
													const checkedRowsCount = Object.keys(checkedRelatedMetrics).filter((metric) => checkedRelatedMetrics[metric]).length
													if (checkedRowsCount == 2 && !checkedRelatedMetrics[params.node.data.metric]) {
														return 'gray-out-metric'
													}
												}}
												onGridReady={this.onGridReady}
												suppressCsvExport={true}
												suppressExcelExport={true}
												suppressDragLeaveHidesColumns={true}
											/>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
					<div className="chart-info">
						<div className="chart-graph">
							<div className="sync-chart">
								<SyncChart />
							</div>
						</div>
						<div className="chart-misc">
							<div className="item">
								<div className="title">
									<img className="misc-icon" src={iconService} alt="misc" />
									Recommendations
								</div>
								<div className="content" dangerouslySetInnerHTML={this.createMiscHTML(misc.recommendations)} />
							</div>
							<div className="item">
								<div className="title">
									<img className="misc-icon" src={iconHelp} alt="misc" />
									Related Help Articles
								</div>
								<div className="content" dangerouslySetInnerHTML={this.createMiscHTML(misc.related_help)} />
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

const mapStateToProps = (state) => {
	return { ...state.linePage, ...state.insights, ...state.visibility }
}

const mapDispatchToProps = { ...actions, ...insightsActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(LineMetricDetails)
