import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import Anchor from './NavigatableViewer'
import classnames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import errorIcon from 'ui-lib/src/images/icon-error.svg'

export default class RelatedLogsDrawer extends Component {
	state = {
		activeTab: '1',
		selectedLogIndex: -1
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.relatedLogsLoading === true && this.props.relatedLogsLoading === false && !this.props.relatedLogsError) {
			this.setState({ selectedLogIndex: this.props.relatedLogs.findIndex((log) => _.isEqual(log, this.props.selectedRow)) })
		}
		if (prevProps.relatedLogsLoading === false && this.props.relatedLogsLoading === true) {
			this.setState({ selectedLogIndex: -1 })
		}
	}
	render() {
		const { schema, relatedLogs, relatedLogsLoading, relatedLogsError, selectedRow, logType, userContext, userCtxLoading, userCtxError } = this.props
		const { selectedLogIndex } = this.state
		const [vendor, type] = logType.split('.')
		// Remove table row index
		delete selectedRow.__table_row_index
		// Selected Row is the row you clicked. Selected Log is the log within related logs you clicked.
		let selectedRowIndex = -1
		let selectedLogData
		let selectedLogType
		if (relatedLogsLoading || selectedLogIndex === -1) {
			selectedLogType = selectedRow.log_type.value.toLowerCase()
			selectedLogData = selectedRow
		} else {
			selectedLogType = relatedLogs[selectedLogIndex].log_type.value.toLowerCase()
			selectedLogData = relatedLogs[selectedLogIndex]
		}
		if (relatedLogsLoading === false && relatedLogs) {
			selectedRowIndex = relatedLogs.findIndex((log) => _.isEqual(log, selectedRow))
		}
		let groupsInfo = schema[vendor].content[selectedLogType].groupFieldsOrder
		let fieldsInfo = schema[vendor].content[selectedLogType].fields

		return (
			<div className="log-detail-container">
				<div className="log-detail-timeline">
					{relatedLogsError ? (
						<div className="timeline-err">
							<img src={errorIcon} alt="Error" />
							<div>Error Fetching Related Logs</div>
						</div>
					) : relatedLogsLoading ? (
						<div className="timeline-spin">
							<div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					) : (
						<ul className="timeline-list">
							{relatedLogs.map((log, index) => {
								let date = null
								let dateStamp = null
								if (index === 0) {
									date = moment(log.time_generated / 1000).format('DD-MM-YYYY')
								} else {
									const prevLogDate = moment(relatedLogs[index - 1].time_generated / 1000).format('DD-MM-YYYY')
									const currentLogDate = moment(log.time_generated / 1000).format('DD-MM-YYYY')
									if (prevLogDate !== currentLogDate) {
										date = currentLogDate
									}
								}
								if (date) {
									dateStamp = (
										<li key={date} className="timeline-item">
											<div className="timeline-timestamp">
												<span className="timestamp-date">{date}</span>
											</div>
										</li>
									)
								}
								return (
									<React.Fragment key={`timestamp_${index}`}>
										{dateStamp}
										<li
											key={index}
											className={`timeline-item ${index === selectedRowIndex ? 'selected-row' : ''} ${index === this.state.selectedLogIndex ? 'selected-log' : ''}`}
											onClick={() => this.setState({ selectedLogIndex: index })}
										>
											<div className="timeline-timestamp">
												<span className="timestamp-logtype">{log.log_type.value.toUpperCase()}</span>
												<span className="timestamp-time">{moment(log.time_generated / 1000).format('HH:mm:ss')}</span>
											</div>
										</li>
									</React.Fragment>
								)
							})}
						</ul>
					)}
				</div>
				<div className="log-detail-tabs">
					<Nav tabs>
						<NavItem>
							<NavLink
								className={classnames({ active: this.state.activeTab === '1' })}
								onClick={() => {
									this.setState({ activeTab: '1' })
								}}
							>
								Details
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({ active: this.state.activeTab === '2' })}
								onClick={() => {
									this.setState({ activeTab: '2' })
								}}
							>
								Context
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={this.state.activeTab}>
						<TabPane tabId="1">
							<Anchor groupsInfo={groupsInfo} fieldsInfo={fieldsInfo} logData={selectedLogData} logIndex={selectedLogIndex} />
						</TabPane>
						<TabPane tabId="2">
							<Anchor
								isUser={true}
								groupsInfo={{ 'User Detail': ['userPrincipalName', 'location', 'number', 'department', 'manager', 'position', 'lastLogon'] }}
								fieldsInfo={[
									{ name: 'userPrincipalName', displayName: 'EMAIL' },
									{ name: 'department', displayName: 'DEPARTMENT' },
									{ name: 'location', displayName: 'LOCATION' },
									{ name: 'number', displayName: 'NUMBER' },
									{ name: 'manager', displayName: 'MANAGER' },
									{ name: 'position', displayName: 'POSITION DETAILS' },
									{ name: 'lastLogon', displayName: 'LAST LOGIN TIME' }
								]}
								logData={userContext}
								userCtxLoading={userCtxLoading}
								userCtxError={userCtxError}
							/>
						</TabPane>
					</TabContent>
				</div>
			</div>
		)
	}
}
