import React, { Component } from 'react'
import { connect, doLogout } from 'ui-lib'
import _ from 'lodash'
import '@pan/cortex-app-bar'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import NavGroup from './components/NavGroup/NavGroup'
import RefreshTab from './components/RefreshTab/RefreshTab'
import HelpTab from './components/HelpTab/HelpTab'

import Constants from './insights/utils/Constants.json'
import Util from './insights/utils/Util'
import visUtil from './util/Util'

import * as visibilityActions from './store/actions'
import * as insightsActions from './insights/store/actions'
import * as alertsActions from './alerts/store/actions'
import * as mainActions from '../main/actions'

import appLogoExpand from './images/app-log-expand.svg'
import appLogoCollapse from './images/app-log-collapse.svg'
import InsightsIcon from './images/icon-insights.svg'
import AlertsIcon from './images/icon-alerts.svg'
import LogsIcon from './images/icon-logs.svg'
import NetworkIcon from './images/icon-network.svg'
import ActiveInsightsIcon from './images/icon-insights-active.svg'
import ActiveAlertsIcon from './images/icon-alerts-active.svg'
import ActiveLogsIcon from './images/icon-logs-active.svg'
import ActiveNetworkIcon from './images/icon-network-active.svg'
import DimmedInsightsIcon from './images/icon-insights-dimmed.svg'
import DimmedAlertsIcon from './images/icon-alerts-dimmed.svg'
import DimmedLogsIcon from './images/icon-logs-dimmed.svg'
import DimmedNetworkIcon from './images/icon-network-dimmed.svg'
import iconClose from 'ui-lib/src/images/icon-close-notification.svg'

import './Visibility.scss'
import routes from './routes'

class Visibility extends Component {
	constructor(props) {
		super(props)
		this.state = {
			appBarInitialized: false,
			appBarLoaded: false,
			appSwitcherLoaded: false
		}
	}

	componentDidMount() {
		this.props.initApp()
	}

	componentDidUpdate(prevProps) {
		this.renderAppBar()
	}

	handleCollapsed(isCollapsed) {
		this.props.updateNavOpenState(!isCollapsed)
		this.appBar.appBarLogo = !isCollapsed ? (this.appBar.appBarLogo = appLogoExpand) : appLogoCollapse
	}

	renderAppBar = () => {
		if (this.appBar) {
			if (this.state.appBarInitialized === false) {
				this.setState({ appBarInitialized: true })
				const { loggedInUser } = this.props
				const { pendo } = window
				let { currentSupportAccountId, email, profile } = loggedInUser || {}
				let { accesstoken, pingcert, notonorafter, firstname, lastname, supportaccountids } = profile || {}
				let account = _.find(JSON.parse(supportaccountids), {
					accountid: parseInt(currentSupportAccountId || 0)
				})
				let companyName = account && account.name ? account.name : 'Palo Alto Networks'
				let userName = firstname + ' ' + lastname
				let userData = {
					userName,
					email,
					companyName
				}
				let rbac = { user: 'data' }

				pendo.initialize({
					visitor: {
						id: email,
						email,
						displayName: userName
					},
					account: {
						id: currentSupportAccountId
					}
				})
				this.appBar._isVertical = true
				this.appBar.collapsed = this.handleCollapsed.bind(this)
				this.appBar.accesstoken = accesstoken
				this.appBar.pingcert = pingcert
				this.appBar.rbac = rbac
				this.appBar.showAppSwitcher = true
				this.appBar.showUserMenu = true
				this.appBar.notonorafter = notonorafter
				this.appBar.supportedAccounts = JSON.parse(supportaccountids)
				this.appBar.userData = userData
				this.appBar.appBarLogo = appLogoExpand
				this.appBar.selectedAccount = JSON.parse(currentSupportAccountId)
				this.appBar._baseURL = `${window.__admin_cluster_url}/api`
				this.appBar.addEventListener('cortex-sign-out', () => {
					doLogout()
				})
			}
		}
	}

	render() {
		let {
			metricsMisc,
			metricsMiscError,
			alertsMisc,
			alertsMiscError,
			notifications,
			removeNotification,
			insightsTabs,
			activeApp,
			finishedInsightsInitTasks,
			activeInsightsTab,
			switchInsightsTab,
			closeInsightsTab,
			clearInsightsDataCache,
			updateInsightsActiveTab,
			isNavOpen,
			updateActiveApp,
			alertsTabs,
			activeAlertsTab,
			switchAlertsTab,
			closeAlertsTab,
			clearAlertsDataCache,
			updateAlertsActiveTab
		} = this.props

		if (metricsMiscError || alertsMiscError) {
			throw 'Error Initializing App'
		}

		if (activeInsightsTab === null || finishedInsightsInitTasks.count !== Constants.INIT_TASKS || metricsMisc === null || alertsMisc === null) {
			return <div></div>
		}

		let lastRefresh, staleStatus
		if (activeApp !== 'insights' || activeInsightsTab !== 'insights.devicedetails') {
			lastRefresh = visUtil.getLastRefreshOfActiveTab(this.props)
			staleStatus = visUtil.getStaleStatusOfActiveTab(this.props)
		}

		const navGroupInfo = [
			{
				appName: 'insights',
				icons: { normal: InsightsIcon, active: ActiveInsightsIcon, dimmed: DimmedInsightsIcon },
				subItems: [
					{
						id: Constants.FIXED_TABS[0],
						name: 'Insights',
						active: Constants.FIXED_TABS.includes(activeInsightsTab) && activeApp === 'insights'
					},
					...insightsTabs.custom_tabs.map((tab, index) => {
						return {
							id: index,
							name: `${tab.desc.hostname} - ${Util.getMetricDisplayName(metricsMisc, tab.desc.metric)}`,
							active: activeInsightsTab === index
						}
					})
				],
				onItemClick: (id) => {
					if (activeApp === 'insights') {
						switchInsightsTab(activeInsightsTab, id)
					} else {
						updateInsightsActiveTab(id)
					}
					updateActiveApp('insights')
				},
				onItemClose: (id) => closeInsightsTab(id),
				componentPath: '/insights'
			},
			{
				appName: 'alerts',
				icons: { normal: AlertsIcon, active: ActiveAlertsIcon, dimmed: DimmedAlertsIcon },
				subItems: [
					{
						id: 'alerts.main',
						name: 'Alerts',
						active: (activeAlertsTab === 'alerts.main' || activeAlertsTab === 'alerts.notifications') && activeApp === 'alerts'
					},
					...alertsTabs.custom_tabs.map((tab, index) => {
						return {
							id: index,
							name: `${tab.desc.alertname} - ${tab.desc.hostname}`,
							active: activeAlertsTab === index
						}
					})
				],
				onItemClick: (id) => {
					if (activeApp === 'alerts') {
						switchAlertsTab(activeAlertsTab, id)
					} else {
						updateAlertsActiveTab(id)
					}
					updateActiveApp('alerts')
				},
				onItemClose: (id) => closeAlertsTab(id),
				componentPath: '/alerts'
			},
			{
				appName: 'logs',
				icons: { normal: LogsIcon, active: ActiveLogsIcon, dimmed: DimmedLogsIcon },
				subItems: [
					{
						id: 'logs',
						name: 'Logs',
						active: activeApp === 'logs'
					}
				],
				onItemClick: () => updateActiveApp('logs'),
				onItemClose: _.noop,
				componentPath: '/logs'
			},
			{
				appName: 'network',
				icons: { normal: NetworkIcon, active: ActiveNetworkIcon, dimmed: DimmedNetworkIcon },
				subItems: [
					{
						id: 'network',
						name: 'Visibility',
						active: activeApp === 'network'
					}
				],
				onItemClick: () => updateActiveApp('network'),
				onItemClose: _.noop,
				componentPath: '/visibility'
			}
		]

		return (
			<Router>
				<div id="strata-visibility" className={isNavOpen ? 'left-nav-open' : 'left-nav-close'}>
					<cortex-app-bar id="app-bar" ref={(el) => (this.appBar = el)} _isVertical={true}>
						<div className="visibility-nav">
							<NavGroup navGroupInfo={navGroupInfo} isNavOpen={isNavOpen} activeApp={activeApp} />
							<RefreshTab
								isNavOpen={isNavOpen}
								staleStatus={staleStatus}
								lastRefresh={lastRefresh}
								onRefresh={() => {
									clearInsightsDataCache()
									clearAlertsDataCache()
								}}
								activeApp={activeApp}
								activeInsightsTab={activeInsightsTab}
							/>
							<HelpTab isNavOpen={isNavOpen} />
						</div>
					</cortex-app-bar>

					<div className="visibility-body">
						{notifications.length > 0 ? (
							<div className={`notification ${_.last(notifications).type}`}>
								<span className="notification-message">{_.last(notifications).message}</span>
								<img className="notification-close-icon" src={iconClose} alt="close" onClick={() => removeNotification()} />
							</div>
						) : null}
						<Switch>
							{routes.map(({ path, component }, key) => (
								<Route exact path={path} component={component} key={key} />
							))}
							<Route path="/">
								<Redirect to={`/${activeApp}`} />
							</Route>
						</Switch>
					</div>
				</div>
			</Router>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.insights, ...state.alerts, loggedInUser: state.main.loggedInUser, tenant: state.main.tenant }
}

const mapDispatchToProps = { ...visibilityActions, ...insightsActions, ...alertsActions, ...mainActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(Visibility)
