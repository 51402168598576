import { connect } from 'ui-lib'
import { Select, Input, Space } from 'antd'
import React from 'react'

import TemplatePicker from './TemplatePicker'

import iconSpinner from '../../../../../../images/icon-spinner.svg'
import iconHealthy from '../../../../../../images/icon-healthy.svg'
import iconCritical from '../../../../../../images/icon-critical.svg'
import iconChecked from '../../../../../../images/icon-checked.svg'
import iconUnchecked from '../../../../../../images/icon-unchecked.svg'
import './ServiceNowConfig.scss'

import * as actions from '../../../store/actions'

class Config extends React.Component {
	state = {
		isTemplatePickerOpen: false,
		configMode: true,
		serviceNowUrl: '',
		clientId: '',
		clientPassword: '',
		serviceNowUsername: '',
		serviceNowPassword: '',
		serviceNowVer: '1',
		inputErrors: {}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleOutsideClick, false)
		const { snConfig, snConfigError, snConfigLoading } = this.props
		if (!snConfigLoading && !_.isEmpty(snConfig) && _.isEmpty(snConfigError)) {
			this.setState({
				configMode: false
				// serviceNowUrl: snConfig.instance_name,
				// clientId: snConfig.client_id,
				// clientPassword: snConfig.client_password,
				// serviceNowUsername: snConfig.user,
				// serviceNowPassword: snConfig.password,
				// serviceNowVer: snConfig.api_version
			})
		}
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick, false)
	}

	componentDidUpdate(prevProps) {
		const { snConfig, snConfigError, snConfigLoading } = this.props
		if (prevProps.ruleState.serviceNowChecked === false && this.props.ruleState.serviceNowChecked === true && !snConfigLoading && !_.isEmpty(snConfig) && _.isEmpty(snConfigError)) {
			this.setState({
				configMode: false
			})
		}

		if (prevProps.ruleState.serviceNowChecked === true && this.props.ruleState.serviceNowChecked === false) {
			this.props.removeConfigError()
		}
	}

	handleOutsideClick = (e) => {
		if (e.target.closest('.servicenow-template-select') === null) {
			this.setState({
				isTemplatePickerOpen: false
			})
		}
	}
	render() {
		const { ruleState, setRuleState, snConfig, snConfigLoading, snConfigError } = this.props
		const { isTemplatePickerOpen, configMode, serviceNowUrl, clientId, clientPassword, serviceNowUsername, serviceNowPassword, serviceNowVer, inputErrors } = this.state
		return (
			<div className="servicenow-config">
				<div className="type">
					<img
						className="icon-check"
						onClick={() => setRuleState({ serviceNowChecked: !ruleState.serviceNowChecked })}
						src={ruleState.serviceNowChecked ? iconChecked : iconUnchecked}
						alt="icon"
					/>
					<span className="title">Service Now</span>
				</div>
				{ruleState.serviceNowChecked ? (
					configMode ? (
						<div className="account-setup">
							<div className="input-item">
								<div className="title">ServiceNow URL</div>
								<div className="input">
									<Input
										allowClear
										maxLength={256}
										value={serviceNowUrl}
										onChange={(e) => {
											this.setState({ serviceNowUrl: e.target.value })
										}}
									/>
									{inputErrors.serviceNowUrl ? <div className="input-error">{`* ${inputErrors.serviceNowUrl}`}</div> : null}
								</div>
							</div>
							<div className="input-item">
								<div className="title">Client ID</div>
								<div className="input">
									<Input
										allowClear
										maxLength={256}
										value={clientId}
										onChange={(e) => {
											this.setState({ clientId: e.target.value })
										}}
									/>
									{inputErrors.clientId ? <div className="input-error">{`* ${inputErrors.clientId}`}</div> : null}
								</div>
							</div>
							<div className="input-item">
								<div className="title">Client Password</div>
								<div className="input">
									<Input.Password
										maxLength={256}
										value={clientPassword}
										onChange={(e) => {
											this.setState({ clientPassword: e.target.value })
										}}
									/>
									{inputErrors.clientPassword ? <div className="input-error">{`* ${inputErrors.clientPassword}`}</div> : null}
								</div>
							</div>
							<div className="input-item">
								<div className="title">ServiceNow User Name</div>
								<div className="input">
									<Input
										allowClear
										maxLength={256}
										value={serviceNowUsername}
										onChange={(e) => {
											this.setState({ serviceNowUsername: e.target.value })
										}}
									/>
									{inputErrors.serviceNowUsername ? <div className="input-error">{`* ${inputErrors.serviceNowUsername}`}</div> : null}
								</div>
							</div>
							<div className="input-item">
								<div className="title">Service Now Password</div>
								<div className="input">
									<Input.Password
										maxLength={256}
										value={serviceNowPassword}
										onChange={(e) => {
											this.setState({ serviceNowPassword: e.target.value })
										}}
									/>
									{inputErrors.serviceNowPassword ? <div className="input-error">{`* ${inputErrors.serviceNowPassword}`}</div> : null}
								</div>
							</div>
							<div className="input-item">
								<div className="title">Service Now API Version</div>
								<div className="input">
									<Input
										allowClear
										defaultValue={1}
										maxLength={256}
										value={serviceNowVer}
										onChange={(e) => {
											this.setState({ serviceNowVer: e.target.value })
										}}
									/>
									{inputErrors.serviceNowVer ? <div className="input-error">{`* ${inputErrors.serviceNowVer}`}</div> : null}
								</div>
							</div>
							<div className="btns">
								<span className="test-result">
									{snConfigError ? (
										<>
											<img src={iconCritical} alt="icon" className="icon-failed" />
											<span className="text">Test Failed</span>
										</>
									) : snConfigLoading ? (
										<>
											<img src={iconSpinner} alt="icon" className="loading-spinner" />
											<span className="text">Testing connection...</span>
										</>
									) : _.isEmpty(snConfig) ? null : (
										<>
											<img src={iconHealthy} alt="icon" className="icon-pass" />
											<span className="text">Connection successful!</span>
										</>
									)}
								</span>
								<span
									className="test-btn"
									onClick={() => {
										this.props.addConfigRequest({
											instance_name: serviceNowUrl,
											client_id: clientId,
											client_password: clientPassword,
											user: serviceNowUsername,
											password: serviceNowPassword,
											api_version: serviceNowVer
											// instance_name: 'https://dev93167.service-now.com',
											// client_id: 'ffcd04a7b32020109640a52795cb7590',
											// client_password: 'P@l0Alt0nw',
											// user: 'rest.user',
											// password: 'passpass',
											// api_version: '1'
										})
									}}
								>
									Test
								</span>
								<span
									className="cancel-btn"
									onClick={() => {
										this.setState({
											isTemplatePickerOpen: false,
											configMode: true,
											serviceNowUrl: '',
											clientId: '',
											clientPassword: '',
											serviceNowUsername: '',
											serviceNowPassword: '',
											serviceNowVer: '1',
											inputErrors: {}
										})
									}}
								>
									Cancel
								</span>
								<span
									className={`next-btn ${!snConfigLoading && !_.isEmpty(snConfig) && _.isEmpty(snConfigError) ? '' : 'disabled'}`}
									onClick={() => {
										if (!snConfigLoading && !_.isEmpty(snConfig) && _.isEmpty(snConfigError)) this.setState({ configMode: false })
									}}
								>
									Next
								</span>
							</div>
						</div>
					) : (
						<TemplatePicker ruleState={ruleState} setRuleState={setRuleState} />
					)
				) : null}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alertsNotifications }
}

const mapDispatchToProps = { ...actions }

export default connect(mapStateToProps, mapDispatchToProps, null)(Config)
