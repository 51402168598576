export default function () {
	return {
		metadata: { version: 4.5, type: 'Object', generator: 'Object3D.toJSON' },
		geometries: [
			{
				uuid: 'DD282596-C240-4B2A-AD86-F5EB73BA2EFB',
				type: 'BufferGeometry',
				data: {
					attributes: {
						position: {
							itemSize: 3,
							type: 'Float32Array',
							array: [
								-0.016653887927532196,
								0,
								-0.11077626049518585,
								0.01398354023694992,
								0,
								-0.11077626049518585,
								-0.0013352138921618462,
								0,
								-0.11180660873651505,
								-0.03134617581963539,
								0,
								-0.10777489095926285,
								0.0286758653819561,
								0,
								-0.10777489095926285,
								-0.04527757316827774,
								0,
								-0.10293694585561752,
								0.04260728508234024,
								0,
								-0.10293694585561752,
								-0.05831357464194298,
								0,
								-0.09639692306518555,
								-0.05831357464194298,
								0,
								-0.09639692306518555,
								-0.0013352138921618462,
								0,
								-0.09769818931818008,
								0.04260728508234024,
								0,
								-0.10293694585561752,
								-0.0013352138921618462,
								0,
								-0.09769818931818008,
								0.05564329773187637,
								0,
								-0.09639692306518555,
								-0.014739041216671467,
								0,
								-0.0967966690659523,
								0.01206863671541214,
								0,
								-0.09679664671421051,
								-0.027594786137342453,
								0,
								-0.0941704511642456,
								0.02492440491914749,
								0,
								-0.09417042881250381,
								-0.07031967490911484,
								0,
								-0.0882892906665802,
								0.06764938682317734,
								0,
								-0.0882892906665802,
								-0.0397847518324852,
								0,
								-0.0899372398853302,
								0.03711438179016113,
								0,
								-0.08993721753358841,
								0.06764938682317734,
								0,
								-0.0882892906665802,
								0.02492440491914749,
								0,
								-0.09417042881250381,
								-0.05119125172495842,
								0,
								-0.0842147096991539,
								0.048520881682634354,
								0,
								-0.08421468734741211,
								0.03711438179016113,
								0,
								-0.08993721753358841,
								-0.08116135746240616,
								0,
								-0.07874854654073715,
								0.048520881682634354,
								0,
								-0.08421468734741211,
								0.07849105447530746,
								0,
								-0.07874853163957596,
								-0.06169658899307251,
								0,
								-0.07712052017450333,
								0.05902621150016785,
								0,
								-0.07712051272392273,
								0.07849105447530746,
								0,
								-0.07874853163957596,
								-0.09070412814617157,
								0,
								-0.06790915131568909,
								-0.06169658899307251,
								0,
								-0.07712052017450333,
								-0.08116135746240616,
								0,
								-0.07874854654073715,
								0.08803380280733109,
								0,
								-0.0679091364145279,
								-0.09070412814617157,
								0,
								-0.06790915131568909,
								-0.07118306308984756,
								0,
								-0.06877235323190689,
								0.0685126781463623,
								0,
								-0.0687723457813263,
								-0.07953298836946487,
								0,
								-0.05928788706660271,
								0.07686258852481842,
								0,
								-0.05928787216544151,
								-0.09881346672773361,
								0,
								-0.05590559169650078,
								0.07686258852481842,
								0,
								-0.05928787216544151,
								0.09614310413599014,
								0,
								-0.05590558052062988,
								0.08803380280733109,
								0,
								-0.0679091364145279,
								-0.08662866055965424,
								0,
								-0.0487847663462162,
								0.08395823836326599,
								0,
								-0.048784755170345306,
								-0.10535486787557602,
								0,
								-0.0428723469376564,
								0.08395823836326599,
								0,
								-0.048784755170345306,
								0.10268448293209076,
								0,
								-0.04287233576178551,
								0.09614310413599014,
								0,
								-0.05590558052062988,
								-0.09235239773988724,
								0,
								-0.037380676716566086,
								0.0896819606423378,
								0,
								-0.03738066554069519,
								0.10268448293209076,
								0,
								-0.04287233576178551,
								-0.11019383370876312,
								0,
								-0.028943894430994987,
								0.0896819606423378,
								0,
								-0.03738066554069519,
								0.10752342641353607,
								0,
								-0.028943883255124092,
								0.053304772824048996,
								0,
								-0.041149262338876724,
								0.05690830200910568,
								0,
								-0.04115017130970955,
								0.0551065094769001,
								0,
								-0.041264500468969345,
								0.05868637189269066,
								0,
								-0.040806256234645844,
								0.05152687802910805,
								0,
								-0.04080446437001228,
								0.06041693314909935,
								0,
								-0.04023275896906853,
								0.049796607345342636,
								0,
								-0.04023010656237602,
								0.06207619979977608,
								0,
								-0.039429668337106705,
								0.04813774675130844,
								0,
								-0.03942620009183884,
								0.06364039331674576,
								0,
								-0.03839698061347008,
								0.046574078500270844,
								0,
								-0.038392744958400726,
								0.046574078500270844,
								0,
								-0.038392744958400726,
								0.06508572399616241,
								0,
								-0.03713470324873924,
								0.06364039331674576,
								0,
								-0.03839698061347008,
								0.04512938857078552,
								0,
								-0.03712976723909378,
								-0.09658649563789368,
								0,
								-0.025193283334374428,
								0.09391605854034424,
								0,
								-0.025193272158503532,
								0.04512938857078552,
								0,
								-0.03712976723909378,
								0.06634921580553055,
								0,
								-0.03569025546312332,
								0.06508572399616241,
								0,
								-0.03713470324873924,
								0.04393814504146576,
								0,
								-0.03593817353248596,
								0.04064470902085304,
								0,
								-0.032643772661685944,
								0.04064470902085304,
								0,
								-0.032643772661685944,
								0.0673830658197403,
								0,
								-0.0341268926858902,
								0.06634921580553055,
								0,
								-0.03569025546312332,
								0.06818725168704987,
								0,
								-0.032468367367982864,
								0.0673830658197403,
								0,
								-0.0341268926858902,
								0.03566952794790268,
								0,
								-0.027667121961712837,
								0.06818725168704987,
								0,
								-0.032468367367982864,
								0.06876179575920105,
								0,
								-0.03073847107589245,
								0.06910669803619385,
								0,
								-0.02896095998585224,
								0.03566952794790268,
								0,
								-0.027667121961712837,
								0.06922195851802826,
								0,
								-0.027159618213772774,
								0.06910669803619385,
								0,
								-0.02896095998585224,
								-0.11319585889577866,
								0,
								-0.01425471343100071,
								0.09391605854034424,
								0,
								-0.025193272158503532,
								0.11052542924880981,
								0,
								-0.01425468921661377,
								0.10752342641353607,
								0,
								-0.028943883255124092,
								0.029433030635118484,
								0,
								-0.021428784355521202,
								0.06910757720470428,
								0,
								-0.02535822056233883,
								0.06876357644796371,
								0,
								-0.023580534383654594,
								-0.09921327233314514,
								0,
								-0.012340251356363297,
								0.0965428277850151,
								0,
								-0.012340240180492401,
								0.11052542924880981,
								0,
								-0.01425468921661377,
								0.06818994879722595,
								0,
								-0.021850334480404854,
								0.029433030635118484,
								0,
								-0.021428784355521202,
								0.06738670915365219,
								0,
								-0.020191404968500137,
								0.06818994879722595,
								0,
								-0.021850334480404854,
								0.02235565334558487,
								0,
								-0.014349324628710747,
								0.06738670915365219,
								0,
								-0.020191404968500137,
								0.06635384261608124,
								0,
								-0.018627505749464035,
								0.06509137898683548,
								0,
								-0.017182419076561928,
								0.06370379030704498,
								0,
								-0.01579442247748375,
								0.059867534786462784,
								0,
								-0.011957094073295593,
								0.014857831411063671,
								0,
								-0.006849302444607019,
								-0.11422640830278397,
								0,
								0.0010607466101646423,
								-0.09921327233314514,
								0,
								-0.012340251356363297,
								-0.11319585889577866,
								0,
								-0.01425471343100071,
								0.11155598610639572,
								0,
								0.0010607466101646423,
								-0.11422640830278397,
								0,
								0.0010607466101646423,
								-0.10011500865221024,
								0,
								0.0010607466101646423,
								0.09744458645582199,
								0,
								0.0010607466101646423,
								0.05407233163714409,
								0,
								-0.006160274613648653,
								0.007360009476542473,
								0,
								0.0006507202051579952,
								0.046807922422885895,
								0,
								0.0011061718687415123,
								0.00028263311833143234,
								0,
								0.007730180397629738,
								-0.11319584399461746,
								0,
								0.01637624204158783,
								-0.09921327978372574,
								0,
								0.014461779966950417,
								-0.04543581232428551,
								0,
								0.001175532117486,
								-0.04183231666684151,
								0,
								0.001175532117486,
								-0.04363406449556351,
								0,
								0.0010607466101646423,
								0.0965428501367569,
								0,
								0.014461768791079521,
								0.11052538454532623,
								0,
								0.016376247629523277,
								0.0385640412569046,
								0,
								0.009352358058094978,
								-0.047213781625032425,
								0,
								0.0015198769979178905,
								-0.04005434364080429,
								0,
								0.0015198886394500732,
								-0.048944197595119476,
								0,
								0.0020937928929924965,
								-0.03832393139600754,
								0,
								0.002093804534524679,
								-0.050603270530700684,
								0,
								0.002897279802709818,
								-0.03666485473513603,
								0,
								0.0028972914442420006,
								-0.05216723680496216,
								0,
								0.003930326085537672,
								-0.03510088846087456,
								0,
								0.003930337727069855,
								-0.05361230671405792,
								0,
								0.005192955024540424,
								-0.03510088846087456,
								0,
								0.003930337727069855,
								-0.05216723680496216,
								0,
								0.003930326085537672,
								-0.05361230671405792,
								0,
								0.005192955024540424,
								-0.03365582227706909,
								0,
								0.005192955024540424,
								-0.05487522482872009,
								0,
								0.006637750659137964,
								-0.01541373785585165,
								0,
								0.023431207984685898,
								-0.05590852349996567,
								0,
								0.008201406337320805,
								-0.005953875370323658,
								0,
								0.013968516141176224,
								-0.05671219527721405,
								0,
								0.009860150516033173,
								-0.005953875370323658,
								0,
								0.013968516141176224,
								0.029830437153577805,
								0,
								0.01808842085301876,
								0.0385640412569046,
								0,
								0.009352358058094978,
								-0.057286251336336136,
								0,
								0.011590209789574146,
								-0.05763068422675133,
								0,
								0.013367801904678345,
								-0.057745493948459625,
								0,
								0.015169166028499603,
								-0.010929059237241745,
								0,
								0.01894516684114933,
								0.029830437153577805,
								0,
								0.01808842085301876,
								0.09391608089208603,
								0,
								0.027314817532896996,
								-0.09658651053905487,
								0,
								0.027314823120832443,
								-0.05763068422675133,
								0,
								0.01697053574025631,
								-0.11019383370876312,
								0,
								0.03106546960771084,
								0.10752338171005249,
								0,
								0.03106546960771084,
								-0.057286251336336136,
								0,
								0.01874813437461853,
								0.021096831187605858,
								0,
								0.026824481785297394,
								-0.05671219527721405,
								0,
								0.02047818899154663,
								-0.014222495257854462,
								0,
								0.022239569574594498,
								-0.05590852349996567,
								0,
								0.022136926651000977,
								-0.05487522482872009,
								0,
								0.02370058186352253,
								-0.05361230671405792,
								0,
								0.025145377963781357,
								-0.053057000041007996,
								0,
								0.025700567290186882,
								-0.05152172967791557,
								0,
								0.027235504239797592,
								-0.05152172967791557,
								0,
								0.027235504239797592,
								0.01285296119749546,
								0,
								0.035070668905973434,
								0.021096831187605858,
								0,
								0.026824481785297394,
								-0.04920249432325363,
								0,
								0.029554234817624092,
								0.01285296119749546,
								0,
								0.035070668905973434,
								0.08968198299407959,
								0,
								0.03950221836566925,
								-0.09235241264104843,
								0,
								0.03950222581624985,
								-0.04629528149962425,
								0,
								0.03246081620454788,
								-0.10535486787557602,
								0,
								0.044993944466114044,
								0.10268443822860718,
								0,
								0.044993944466114044,
								-0.04299608990550041,
								0,
								0.03575929254293442,
								-0.04629528149962425,
								0,
								0.03246081620454788,
								-0.04299608990550041,
								0,
								0.03575929254293442,
								0.005588552448898554,
								0,
								0.04233711212873459,
								-0.03950090706348419,
								0,
								0.03925371915102005,
								-0.03600572049617767,
								0,
								0.04274814575910568,
								0.08395826816558838,
								0,
								0.050906311720609665,
								-0.08662867546081543,
								0,
								0.05090631544589996,
								-0.000206648837774992,
								0,
								0.04813393950462341,
								-0.03270652890205383,
								0,
								0.04604662209749222,
								-0.09881346672773361,
								0,
								0.05802719667553902,
								0.09614305943250656,
								0,
								0.05802719667553902,
								-0.029799319803714752,
								0,
								0.04895320534706116,
								-0.00404290622100234,
								0,
								0.05197127163410187,
								-0.027480080723762512,
								0,
								0.051271937787532806,
								0.07686259597539902,
								0,
								0.06140942499041557,
								-0.09881346672773361,
								0,
								0.05802719667553902,
								-0.07953299582004547,
								0,
								0.06140942871570587,
								-0.08662867546081543,
								0,
								0.05090631544589996,
								-0.025944814085960388,
								0,
								0.05280687287449837,
								-0.00404290622100234,
								0,
								0.05197127163410187,
								-0.027480080723762512,
								0,
								0.051271937787532806,
								-0.005430483724921942,
								0,
								0.053359244018793106,
								-0.02538950741291046,
								0,
								0.05336206406354904,
								-0.005430483724921942,
								0,
								0.053359244018793106,
								-0.025944814085960388,
								0,
								0.05280687287449837,
								-0.0068755121901631355,
								0,
								0.054622478783130646,
								-0.023944174870848656,
								0,
								0.05462491139769554,
								-0.00843952875584364,
								0,
								0.05565609037876129,
								-0.022379925474524498,
								0,
								0.05565809831023216,
								-0.022379925474524498,
								0,
								0.05565809831023216,
								-0.010098726488649845,
								0,
								0.05646007880568504,
								-0.00843952875584364,
								0,
								0.05565609037876129,
								-0.020720547065138817,
								0,
								0.05646162107586861,
								-0.010098726488649845,
								0,
								0.05646007880568504,
								-0.011829310096800327,
								0,
								0.057034436613321304,
								-0.018989823758602142,
								0,
								0.05703548341989517,
								-0.011829310096800327,
								0,
								0.057034436613321304,
								-0.020720547065138817,
								0,
								0.05646162107586861,
								-0.018989823758602142,
								0,
								0.05703548341989517,
								-0.013607507571578026,
								0,
								0.057379160076379776,
								-0.0172115508466959,
								0,
								0.05737968906760216,
								-0.01540951244533062,
								0,
								0.05749424919486046,
								-0.09070412069559097,
								0,
								0.07003075629472733,
								0.07686259597539902,
								0,
								0.06140942499041557,
								0.0880337581038475,
								0,
								0.07003075629472733,
								0.09614305943250656,
								0,
								0.05802719667553902,
								0.0685126855969429,
								0,
								0.07089389115571976,
								0.0880337581038475,
								0,
								0.07003075629472733,
								-0.09070412069559097,
								0,
								0.07003075629472733,
								-0.07118307054042816,
								0,
								0.07089389860630035,
								-0.07953299582004547,
								0,
								0.06140942871570587,
								-0.08116135001182556,
								0,
								0.0808701366186142,
								0.07849101722240448,
								0,
								0.0808701366186142,
								0.05902622267603874,
								0,
								0.079242043197155,
								-0.08116135001182556,
								0,
								0.0808701366186142,
								-0.06169659271836281,
								0,
								0.0792420506477356,
								-0.07118307054042816,
								0,
								0.07089389860630035,
								0.04852089285850525,
								0,
								0.08633620291948318,
								-0.05119125545024872,
								0,
								0.08633621782064438,
								-0.06169659271836281,
								0,
								0.0792420506477356,
								-0.07031966745853424,
								0,
								0.09041086584329605,
								-0.05119125545024872,
								0,
								0.08633621782064438,
								0.04852089285850525,
								0,
								0.08633620291948318,
								0.06764934957027435,
								0,
								0.09041087329387665,
								0.07849101722240448,
								0,
								0.0808701366186142,
								0.03711439296603203,
								0,
								0.09205872565507889,
								0.06764934957027435,
								0,
								0.09041087329387665,
								-0.07031966745853424,
								0,
								0.09041086584329605,
								-0.0397847555577755,
								0,
								0.09205873310565948,
								-0.05831357091665268,
								0,
								0.09851846098899841,
								0.05564326420426369,
								0,
								0.09851846843957901,
								0.024924416095018387,
								0,
								0.0962919145822525,
								-0.027594786137342453,
								0,
								0.0962919294834137,
								0.012068659998476505,
								0,
								0.0989181250333786,
								0.05564326420426369,
								0,
								0.09851846843957901,
								0.024924416095018387,
								0,
								0.0962919145822525,
								-0.014739041216671467,
								0,
								0.0989181324839592,
								-0.04527756944298744,
								0,
								0.1050584688782692,
								-0.014739041216671467,
								0,
								0.0989181324839592,
								-0.05831357091665268,
								0,
								0.09851846098899841,
								0.012068659998476505,
								0,
								0.0989181250333786,
								0.04260726273059845,
								0,
								0.1050584688782692,
								-0.0013352138921618462,
								0,
								0.09981968253850937,
								-0.04527756944298744,
								0,
								0.1050584688782692,
								0.04260726273059845,
								0,
								0.1050584688782692,
								-0.0013352138921618462,
								0,
								0.09981968253850937,
								-0.031346168369054794,
								0,
								0.10989638417959213,
								0.02867584116756916,
								0,
								0.10989639908075333,
								-0.0166538804769516,
								0,
								0.11289776116609573,
								0.01398351788520813,
								0,
								0.11289776116609573,
								-0.0013352138921618462,
								0,
								0.11392810195684433
							],
							normalized: false
						},
						normal: {
							itemSize: 3,
							type: 'Float32Array',
							array: [
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0
							],
							normalized: false
						}
					},
					index: {
						type: 'Uint16Array',
						array: [
							0,
							1,
							2,
							3,
							1,
							0,
							3,
							4,
							1,
							5,
							4,
							3,
							5,
							6,
							4,
							7,
							6,
							5,
							8,
							9,
							10,
							11,
							12,
							6,
							7,
							13,
							11,
							14,
							12,
							11,
							7,
							15,
							13,
							16,
							12,
							14,
							17,
							15,
							7,
							16,
							18,
							12,
							17,
							19,
							15,
							20,
							21,
							22,
							17,
							23,
							19,
							24,
							18,
							25,
							26,
							23,
							17,
							27,
							28,
							21,
							26,
							29,
							23,
							30,
							31,
							24,
							32,
							33,
							34,
							30,
							35,
							31,
							36,
							37,
							29,
							38,
							35,
							30,
							36,
							39,
							37,
							40,
							35,
							38,
							41,
							39,
							36,
							42,
							43,
							44,
							41,
							45,
							39,
							46,
							43,
							42,
							47,
							45,
							41,
							48,
							49,
							50,
							47,
							51,
							45,
							52,
							53,
							46,
							54,
							51,
							47,
							55,
							56,
							49,
							57,
							58,
							59,
							57,
							60,
							58,
							61,
							60,
							57,
							61,
							62,
							60,
							63,
							62,
							61,
							63,
							64,
							62,
							65,
							64,
							63,
							65,
							66,
							64,
							67,
							66,
							65,
							68,
							69,
							70,
							71,
							69,
							68,
							54,
							72,
							51,
							73,
							56,
							55,
							74,
							75,
							76,
							77,
							75,
							74,
							78,
							75,
							77,
							79,
							80,
							81,
							78,
							82,
							83,
							84,
							85,
							79,
							84,
							86,
							85,
							84,
							87,
							86,
							88,
							89,
							90,
							91,
							72,
							54,
							92,
							93,
							94,
							95,
							89,
							88,
							95,
							96,
							89,
							95,
							97,
							96,
							91,
							98,
							72,
							99,
							100,
							73,
							95,
							101,
							97,
							102,
							103,
							104,
							105,
							106,
							95,
							105,
							107,
							106,
							105,
							108,
							107,
							105,
							109,
							108,
							105,
							110,
							109,
							111,
							110,
							105,
							112,
							113,
							114,
							99,
							115,
							100,
							116,
							117,
							98,
							118,
							115,
							99,
							111,
							119,
							110,
							120,
							119,
							111,
							120,
							121,
							119,
							122,
							121,
							120,
							123,
							117,
							116,
							123,
							124,
							117,
							125,
							126,
							127,
							128,
							115,
							118,
							128,
							129,
							115,
							122,
							130,
							121,
							131,
							126,
							125,
							131,
							132,
							126,
							133,
							132,
							131,
							133,
							134,
							132,
							135,
							134,
							133,
							135,
							136,
							134,
							137,
							136,
							135,
							137,
							138,
							136,
							139,
							140,
							141,
							142,
							143,
							138,
							144,
							143,
							142,
							144,
							145,
							143,
							146,
							145,
							144,
							147,
							130,
							122,
							148,
							145,
							146,
							149,
							150,
							151,
							152,
							145,
							148,
							153,
							145,
							152,
							154,
							145,
							153,
							155,
							156,
							147,
							157,
							129,
							128,
							123,
							158,
							124,
							159,
							145,
							154,
							160,
							158,
							123,
							157,
							161,
							129,
							162,
							145,
							159,
							155,
							163,
							156,
							164,
							145,
							162,
							165,
							163,
							155,
							166,
							145,
							164,
							167,
							145,
							166,
							145,
							163,
							165,
							167,
							163,
							145,
							168,
							163,
							167,
							169,
							163,
							168,
							170,
							163,
							169,
							171,
							172,
							173,
							174,
							175,
							170,
							176,
							161,
							157,
							160,
							177,
							158,
							178,
							175,
							174,
							179,
							177,
							160,
							176,
							180,
							161,
							181,
							172,
							182,
							183,
							184,
							175,
							185,
							184,
							183,
							186,
							184,
							185,
							187,
							180,
							176,
							179,
							188,
							177,
							186,
							189,
							184,
							190,
							189,
							186,
							191,
							188,
							179,
							187,
							192,
							180,
							193,
							189,
							190,
							193,
							194,
							189,
							195,
							194,
							193,
							196,
							192,
							187,
							197,
							198,
							199,
							200,
							201,
							202,
							200,
							203,
							201,
							204,
							205,
							206,
							204,
							207,
							205,
							208,
							207,
							204,
							208,
							209,
							207,
							210,
							209,
							208,
							211,
							212,
							213,
							214,
							215,
							210,
							214,
							216,
							215,
							217,
							218,
							219,
							220,
							221,
							216,
							222,
							221,
							220,
							222,
							223,
							221,
							224,
							198,
							197,
							225,
							226,
							227,
							228,
							229,
							196,
							230,
							231,
							232,
							233,
							231,
							230,
							228,
							234,
							229,
							235,
							234,
							228,
							236,
							237,
							238,
							239,
							234,
							235,
							233,
							240,
							241,
							242,
							243,
							236,
							244,
							245,
							246,
							247,
							248,
							239,
							249,
							250,
							240,
							251,
							250,
							249,
							247,
							252,
							248,
							253,
							252,
							247,
							251,
							254,
							250,
							255,
							256,
							257,
							251,
							258,
							254,
							259,
							260,
							261,
							262,
							263,
							252,
							264,
							263,
							262,
							265,
							264,
							258,
							259,
							266,
							267,
							268,
							263,
							265,
							268,
							269,
							263,
							270,
							269,
							268,
							270,
							271,
							269,
							272,
							271,
							270
						]
					},
					boundingSphere: { center: [-0.0013352110981941223, 0, 0.0010607466101646423], radius: 0.1596354043100692 }
				}
			}
		],
		materials: [
			{
				uuid: 'A5C4744B-B67F-49F6-9431-4A17C6BF8502',
				type: 'MeshStandardMaterial',
				color: 16777215,
				roughness: 1,
				metalness: 1,
				emissive: 0,
				depthFunc: 3,
				depthTest: true,
				depthWrite: true,
				stencilWrite: false,
				stencilWriteMask: 255,
				stencilFunc: 519,
				stencilRef: 0,
				stencilFuncMask: 255,
				stencilFail: 7680,
				stencilZFail: 7680,
				stencilZPass: 7680
			}
		],
		object: {
			uuid: '370CAF55-374E-4830-982D-068985A04A13',
			type: 'Mesh',
			name: 'Curve002',
			userData: { name: 'Curve.002' },
			layers: 1,
			matrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
			geometry: 'DD282596-C240-4B2A-AD86-F5EB73BA2EFB',
			material: 'A5C4744B-B67F-49F6-9431-4A17C6BF8502'
		}
	}
}
