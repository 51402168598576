import React, { Component } from 'react'
import { connect } from 'ui-lib'
import moment from 'moment'
import _ from 'lodash'
import detailsSchema from '../../../util/schema/insights/details-presentation.json'
import SeverityCellRenderer from '../../components/CellRenderers/SeverityCellRenderer'
import HighlightedCellRenderer from '../../components/CellRenderers/HighlightedCellRenderer'
import ArrayCellRenderer from '../../components/CellRenderers/ArrayCellRenderer'
import LinkCellRenderer from '../../components/CellRenderers/LinkCellRenderer'
import LastUpdateHeaderCellRenderer from '../../components/CellRenderers/LastUpdatedHeaderCellRenderer/LastUpdateHeaderCellRenderer'
import LastUpdatedCellRenderer from '../../components/CellRenderers/LastUpdatedCellRenderer'
import Constants from '../../utils/Constants.json'
import Util from '../../utils/Util'
import iconService from '../../../images/icon-service.svg'
import iconHelp from '../../../images/icon-help.svg'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'
import DetailsPageHeader from '../../../components/DetailsPageHeader/DetailsPageHeader'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import './TableMetricDetails.scss'

import * as actions from './store/actions'
import * as insightsActions from '../../store/actions'

class TableMetricDetails extends Component {
	componentDidMount () {
		const chart = document.getElementsByClassName('table-metric-details')[0]
		if (chart) chart.scrollIntoView()
		this.props.fetchTableMetricDetailsPageContent()
	}

	componentDidUpdate (prevProps) {
		if (_.get(prevProps, `insightsTabs.custom_tabs[${prevProps.activeInsightsTab}].id`) !== _.get(this.props, `insightsTabs.custom_tabs[${this.props.activeInsightsTab}].id`)) {
			const chart = document.getElementsByClassName('table-metric-details')[0]
			if (chart) chart.scrollIntoView()
			this.props.fetchTableMetricDetailsPageContent()
		}
	}

	createMiscHTML = (snippet) => ({ __html: snippet })

	render () {
		const { tablePageDescLoading, tablePageDescError, activeInsightsTab, switchInsightsTab } = this.props

		if (tablePageDescError) {
			return (
				<div className='info-area'>
					<div>The requested tab cannot be loaded</div>
				</div>
			)
		} else if (tablePageDescLoading) {
			return (
				<div className='info-area'>
					<img className='loading-spinner' src={iconLoading} alt={'loading'} />
					Loading Data
				</div>
			)
		} else {
			const { metricsMisc, tablePageDesc, tableChartData, tableChartDataError, tableChartDataLoading } = this.props
			const chartInfoMap = Constants.TABLE_CHART_INFO_MAP
			const fields = detailsSchema.fields
			const misc = metricsMisc[Util.getGenericMetricName(tablePageDesc.metric)] ? metricsMisc[Util.getGenericMetricName(tablePageDesc.metric)] : {}
			const metricInfoCols = fields.map((field) => {
				const col = {
					headerName: field.displayName,
					field: field.name,
					colId: field.name,
					flex: null,
					width: field.defaultWidth,
					minWidth: field.minWidth ? field.minWidth : 100,
					suppressMenu: true,
					valueFormatter: (params) => {
						if (['true', 'false', 'yes', 'no'].includes(params.value)) {
							return _.capitalize(params.value)
						} else {
							return params.value
						}
					}
				}

				if (col.field === 'metric') {
					col.flex = 1
					col.valueFormatter = (params) => {
						return Util.getMetricDisplayName(metricsMisc, params.value)
					}
				}
				if (col.field === 'metric_value_pct') {
					col.cellRenderer = 'severityCellRenderer'
				}

				if (col.field === 'timegenerated') {
					col.headerComponent = 'LastUpdateHeaderCellRenderer'
					col.cellRenderer = 'LastUpdatedCellRenderer'
				}
				return col
			})

			const colDefConverter = (field) => {
				const col = {
					headerName: field.displayName,
					field: field.name,
					colId: field.name,
					flex: field.flex ? field.flex : null,
					width: field.defaultWidth,
					minWidth: field.minWidth ? field.minWidth : 100,
					suppressMenu: true,
					resizable: true,
					valueFormatter: (params) => {
						if (['true', 'false', 'yes', 'no'].includes(params.value)) {
							return _.capitalize(params.value)
						} else {
							return params.value
						}
					}
				}

				if (field.highlight) {
					col.cellRenderer = 'highlightedCellRenderer'
					col.cellRendererParams = { field: field.name, metric: tablePageDesc.metric }
				}

				// Value Formatters
				if (col.field === 'timegenerated' || col.field === 'date' || col.field === 'updated') {
					col.headerComponent = 'LastUpdateHeaderCellRenderer'
					col.cellRenderer = 'LastUpdatedCellRenderer'
				}

				if (col.field === 'content_name') {
					col.valueFormatter = () => {
						return misc.title
					}
				}

				if (col.field === 'id') {
					col.cellRenderer = 'linkCellRenderer'
					col.cellRendererParams = { metric: tablePageDesc.metric }
				}

				if (col.field === 'update_date' || col.field === 'installed_release_date' || col.field === 'latest_release_date') {
					col.valueFormatter = (params) => {
						return moment
							.utc(params.value, ['YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss'])
							.local()
							.format('DD MMM YYYY [at] HH:mm:ss')
					}
				}

				return col
			}
			const chartInfoCols = chartInfoMap[tablePageDesc.metric].colDefs.map((field) => colDefConverter(field))
			let supplementalChartInfoCols = null
			if (_.has(chartInfoMap[tablePageDesc.metric], 'supplementalColDefs')) {
				supplementalChartInfoCols = chartInfoMap[tablePageDesc.metric].supplementalColDefs.map((field) => colDefConverter(field))
			}

			return (
				<div className='table-metric-details'>
					<DetailsPageHeader
						pageDesc={{
							'Serial Number:': tablePageDesc.serial,
							'Model:': tablePageDesc.model,
							'SW Version:': tablePageDesc.sw_version,
							'IP Address:':
								!_.isString(tablePageDesc.ip_address) && !_.isString(tablePageDesc.ipv6_address)
									? 'N/A'
									: tablePageDesc.ip_address !== 'unknown'
									? tablePageDesc.ip_address
									: tablePageDesc.ipv6_address
						}}
						pageTitle={`${Util.getMetricDisplayName(metricsMisc, tablePageDesc.metric)} - ${tablePageDesc.hostname}`}
						parentPageName={'Insights'}
						currentPageName={'Metric Details'}
						onParentLinkClicked={() => switchInsightsTab(activeInsightsTab, Constants.FIXED_TABS[0])}
					/>
					<div className='metric-details'>
						<div className={'master-detail-grid ag-theme-balham'} style={{ height: '100%' }}>
							<AgGridReact
								frameworkComponents={{ severityCellRenderer: SeverityCellRenderer, LastUpdateHeaderCellRenderer, LastUpdatedCellRenderer }}
								columnDefs={metricInfoCols}
								rowData={[tablePageDesc]}
								suppressCsvExport={true}
								suppressExcelExport={true}
								suppressDragLeaveHidesColumns={true}
							/>
						</div>
					</div>
					<div className='metric-desc'>
						<div className='desc'>
							<div className='title'>metric description</div>
							<div className='content' dangerouslySetInnerHTML={this.createMiscHTML(misc.description)} />
						</div>
						<div className='impact'>
							<div className='title'>impact</div>
							<div className='content' dangerouslySetInnerHTML={this.createMiscHTML(misc.impacted_features)} />
						</div>
					</div>

					<div className='chart-info'>
						<div
							className={`chart-tables ${tableChartDataLoading || tableChartDataError ? 'show-border' : ''}`}
							style={{ height: !tableChartDataLoading && !tableChartDataError ? '100%' : 56 }}
						>
							{tableChartDataError ? (
								<div className='info-area'>Error Fetching Table Chart Data</div>
							) : tableChartDataLoading ? (
								<div className='info-area'>
									<img className='loading-spinner' src={iconLoading} alt={'loading'} />
									Loading Data
								</div>
							) : (
								<>
									<div className='chart-title'>{`${Util.getMetricDisplayName(metricsMisc, tablePageDesc.metric)} Details`}</div>
									{(tablePageDesc.metric.startsWith('environmentals') && !('timegenerated' in _.get(tableChartData, 'mainData[0]', {}))) ||
									(tableChartData.mainData && tableChartData.mainData.length === 0) ? null : (
										<div className={'master-detail-grid ag-theme-balham main'} style={{ height: (tableChartData.mainData.length + 1) * 28 }}>
											<AgGridReact
												frameworkComponents={{
													highlightedCellRenderer: HighlightedCellRenderer,
													arrayCellRenderer: ArrayCellRenderer,
													linkCellRenderer: LinkCellRenderer,
													LastUpdateHeaderCellRenderer,
													LastUpdatedCellRenderer
												}}
												columnDefs={chartInfoCols}
												rowData={tableChartData.mainData}
												suppressCsvExport={true}
												suppressExcelExport={true}
												suppressDragLeaveHidesColumns={true}
											/>
										</div>
									)}

									{tableChartData.supplementalData && tableChartData.supplementalData.length > 0 ? (
										<div className={'master-detail-grid ag-theme-balham supplemental'} style={{ height: (tableChartData.supplementalData.length + 1) * 28 }}>
											<AgGridReact
												frameworkComponents={{
													highlightedCellRenderer: HighlightedCellRenderer,
													arrayCellRenderer: ArrayCellRenderer,
													LastUpdateHeaderCellRenderer,
													LastUpdatedCellRenderer
												}}
												columnDefs={supplementalChartInfoCols}
												rowData={tableChartData.supplementalData}
												suppressCsvExport={true}
												suppressExcelExport={true}
												suppressDragLeaveHidesColumns={true}
											/>
										</div>
									) : null}
								</>
							)}
						</div>
						<div className='chart-misc'>
							<div className='item'>
								<div className='title'>
									<img className='misc-icon' src={iconService} alt='misc' />
									Recommendations
								</div>
								<div className='content' dangerouslySetInnerHTML={this.createMiscHTML(misc.recommendations)} />
							</div>
							<div className='item'>
								<div className='title'>
									<img className='misc-icon' src={iconHelp} alt='misc' />
									Related Help Articles
								</div>
								<div className='content' dangerouslySetInnerHTML={this.createMiscHTML(misc.related_help)} />
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

const mapStateToProps = (state) => {
	return { ...state.tablePage, ...state.insights, ...state.visibility }
}

const mapDispatchToProps = { ...actions, ...insightsActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(TableMetricDetails)
