import React from 'react'
import deleteIcon from '../../../images/icon-delete.svg'

export default class CellRenderer extends React.Component {
	render() {
		const { data } = this.props
		if (data.deleting) {
			return <div>Deleting...</div>
		} else {
			return (
				<div className="delete-cell">
					<img onClick={() => this.props.onRemove(this.props.data)} src={deleteIcon} alt="delete icon" className="delete-icon" />
				</div>
			)
		}
	}
}
