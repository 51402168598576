import * as actionTypes from './actionTypes'

export const fetchDeviceDetailsPageContent = () => ({
	type: actionTypes.FETCH_DEVICE_PAGE_CONTENT
})

/*----- Poll Job Actions -----*/
export const pollDevicePageJobRequest = (jobId, jobType, jobPage, jobLimit, params) => ({
	type: actionTypes.POLL_DEVICE_PAGE_JOB_REQUEST,
	jobId,
	jobType,
	jobPage,
	jobLimit,
	params
})

export const pollDevicePageJobFailure = (jobId, error) => ({
	type: actionTypes.POLL_DEVICE_PAGE_JOB_FAILURE,
	jobId,
	error
})

/*----------- Device Metrics Actions --------------*/
export const fetchDeviceMetricsRequest = (deviceId) => ({
	type: actionTypes.FETCH_DEVICE_METRICS_REQUEST,
	deviceId
})

export const setDeviceMetricsRequestId = (jobId) => ({
	type: actionTypes.SET_DEVICE_METRICS_REQUEST_ID,
	jobId
})

export const fetchDeviceMetricsSuccess = (deviceMetrics) => ({
	type: actionTypes.FETCH_DEVICE_METRICS_SUCCESS,
	deviceMetrics
})

export const fetchDeviceMetricsFailure = (error) => ({
	type: actionTypes.FETCH_DEVICE_METRICS_FAILURE,
	error
})

export const updateDeviceMetricsQuery = (query) => ({
	type: actionTypes.UPDATE_DEVICE_METRICS_QUERY,
	query
})

export const setDevicePageDesc = (pageDesc) => ({
	type: actionTypes.SET_DEVICE_PAGE_DESC,
	pageDesc
})

/*---------- Device Alerts Actions -------------*/
export const fetchDeviceAlertsRequest = (deviceId) => ({
	type: actionTypes.FETCH_DEVICE_ALERTS_REQUEST,
	deviceId
})

export const fetchDeviceAlertsSuccess = (deviceAlerts) => ({
	type: actionTypes.FETCH_DEVICE_ALERTS_SUCCESS,
	deviceAlerts
})

export const fetchDeviceAlertsFailure = (error) => ({
	type: actionTypes.FETCH_DEVICE_ALERTS_FAILURE,
	error
})

export const updateDeviceAlertRequest = (alertId, status, assignee) => ({
	type: actionTypes.UPDATE_DEVICE_ALERT_REQUEST,
	alertId,
	status,
	assignee
})

export const updateDeviceAlertSuccess = (alert, alertId) => ({
	type: actionTypes.UPDATE_DEVICE_ALERT_SUCCESS,
	alert,
	alertId
})

export const updateDeviceAlertFailure = (error) => ({
	type: actionTypes.UPDATE_DEVICE_ALERT_FAILURE,
	error
})

export const fetchClosedDeviceAlertsRequest = () => ({
	type: actionTypes.FETCH_CLOSED_DEVICE_ALERTS_REQUEST
})

export const fetchClosedDeviceAlertsSuccess = (alerts) => ({
	type: actionTypes.FETCH_CLOSED_DEVICE_ALERTS_SUCCESS,
	alerts
})

export const fetchClosedDeviceAlertsFailure = (error) => ({
	type: actionTypes.FETCH_CLOSED_DEVICE_ALERTS_FAILURE,
	error
})

/*----- Events Actions -----*/
export const fetchDeviceAlertEventsRequest = (alertId) => ({
	type: actionTypes.FETCH_DEVICE_ALERT_EVENTS_REQUEST,
	alertId
})

export const fetchDeviceAlertEventsSuccess = (events, alertId) => ({
	type: actionTypes.FETCH_DEVICE_ALERT_EVENTS_SUCCESS,
	events,
	alertId
})

export const fetchDeviceAlertEventsFailure = (error) => ({
	type: actionTypes.FETCH_DEVICE_ALERT_EVENTS_FAILURE,
	error
})

/*----- Activities Actions -----*/
export const fetchDeviceAlertActivitiesRequest = (subject, object_id, alertId) => ({
	type: actionTypes.FETCH_DEVICE_ALERT_ACTIVITIES_REQUEST,
	subject,
	object_id,
	alertId
})

export const fetchDeviceAlertActivitiesSuccess = (activities, alertId) => ({
	type: actionTypes.FETCH_DEVICE_ALERT_ACTIVITIES_SUCCESS,
	activities,
	alertId
})

export const fetchDeviceAlertActivitiesFailure = (error) => ({
	type: actionTypes.FETCH_DEVICE_ALERT_ACTIVITIES_FAILURE,
	error
})

/*----- Comments Actions -----*/
export const fetchDeviceAlertCommentsRequest = (alertId) => ({
	type: actionTypes.FETCH_DEVICE_ALERT_COMMENTS_REQUEST,
	alertId
})

export const fetchDeviceAlertCommentsSuccess = (comments, alertId) => ({
	type: actionTypes.FETCH_DEVICE_ALERT_COMMENTS_SUCCESS,
	comments,
	alertId
})

export const fetchDeviceAlertCommentsFailure = (error) => ({
	type: actionTypes.FETCH_DEVICE_ALERT_COMMENTS_FAILURE,
	error
})

export const addDeviceAlertCommentRequest = (comment, alertId) => ({
	type: actionTypes.ADD_DEVICE_ALERT_COMMENT_REQUEST,
	comment,
	alertId
})

export const addDeviceAlertCommentSuccess = (comment, alertId) => ({
	type: actionTypes.ADD_DEVICE_ALERT_COMMENT_SUCCESS,
	comment,
	alertId
})

export const addDeviceAlertCommentFailure = (error) => ({
	type: actionTypes.ADD_DEVICE_ALERT_COMMENT_FAILURE,
	error
})

export const updateDeviceAlertCommentRequest = (comment, alertId, commentId) => ({
	type: actionTypes.UPDATE_DEVICE_ALERT_COMMENT_REQUEST,
	comment,
	alertId,
	commentId
})

export const updateDeviceAlertCommentSuccess = (comment, alertId, commentId) => ({
	type: actionTypes.UPDATE_DEVICE_ALERT_COMMENT_SUCCESS,
	comment,
	alertId,
	commentId
})

export const updateDeviceAlertCommentFailure = (error) => ({
	type: actionTypes.UPDATE_DEVICE_ALERT_COMMENT_FAILURE,
	error
})

export const removeDeviceAlertCommentRequest = (commentId, alertId) => ({
	type: actionTypes.REMOVE_DEVICE_ALERT_COMMENT_REQUEST,
	commentId,
	alertId
})

export const removeDeviceAlertCommentSuccess = (commentId, alertId) => ({
	type: actionTypes.REMOVE_DEVICE_ALERT_COMMENT_SUCCESS,
	commentId,
	alertId
})

export const removeDeviceAlertCommentFailure = (error) => ({
	type: actionTypes.REMOVE_DEVICE_ALERT_COMMENT_FAILURE,
	error
})

/*---------- Device Commit Events Actions -------------*/
export const fetchDeviceCommitEventsRequest = (deviceId) => ({
	type: actionTypes.FETCH_DEVICE_COMMIT_EVENTS_REQUEST,
	deviceId
})

export const setDeviceCommitEventsRequestId = (jobId) => ({
	type: actionTypes.SET_DEVICE_COMMIT_EVENTS_REQUEST_ID,
	jobId
})

export const fetchDeviceCommitEventsSuccess = (deviceCommitEvents) => ({
	type: actionTypes.FETCH_DEVICE_COMMIT_EVENTS_SUCCESS,
	deviceCommitEvents
})

export const fetchDeviceCommitEventsFailure = (error) => ({
	type: actionTypes.FETCH_DEVICE_COMMIT_EVENTS_FAILURE,
	error
})

/*---------- Alert Timeline Actions -------------*/
export const updateDeviceInfoTimePreset = (deviceInfoTimePreset) => ({
	type: actionTypes.UPDATE_DEVICE_INFO_TIME_PRESET,
	deviceInfoTimePreset
})

export const updateDeviceInfoTimeRange = (deviceInfoStartTime, deviceInfoEndTime) => ({
	type: actionTypes.UPDATE_DEVICE_INFO_TIME_RANGE,
	deviceInfoStartTime,
	deviceInfoEndTime
})

/*---------- Device Details Actions -------------*/
export const fetchDeviceDetailsRequest = (deviceId) => ({
	type: actionTypes.FETCH_DEVICE_DETAILS_REQUEST,
	deviceId
})

export const fetchDeviceDetailsSuccess = (deviceDetails) => ({
	type: actionTypes.FETCH_DEVICE_DETAILS_SUCCESS,
	deviceDetails
})

export const fetchDeviceDetailsFailure = (error) => ({
	type: actionTypes.FETCH_DEVICE_DETAILS_FAILURE,
	error
})
