import React, { Component } from 'react'
import { connect } from 'ui-lib'
import { Select } from 'antd'
import moment from 'moment'
import Timeline from './Timeline/Timeline'
import AlertList from './AlertList/AlertList'

import * as actions from '../../store/actions'

import iconCritical from '../../../../../images/icon-critical.svg'
import iconWarning from '../../../../../images/icon-warning.svg'
import iconHealty from '../../../../../images/icon-healthy.svg'
import iconList from '../../../../../images/icon-view-list.svg'
import iconListActive from '../../../../../images/icon-view-list-active.svg'
import iconChart from '../../../../../images/icon-view-chart.svg'
import iconChartActive from '../../../../../images/icon-view-chart-active.svg'
import iconArrowDown from '../../../../../images/icon-arrow-down-blue.svg'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'

import 'antd/lib/select/style/css'
import './AlertTimeline.scss'

const { Option } = Select

const iconMap = { critical: iconCritical, warning: iconWarning, healthy: iconHealty }

class AlertTimeline extends Component {
	state = {
		viewType: 'chart'
	}
	render() {
		const {
			deviceInfoTimePreset,
			deviceInfoStartTime,
			deviceInfoEndTime,
			updateDeviceInfoTimePreset,
			deviceAlerts,
			deviceAlertsLoading,
			deviceAlertsError,
			deviceCommitEventsLoading,
			deviceCommitEventsError
		} = this.props
		if (deviceAlertsLoading || deviceCommitEventsLoading) {
			return (
				<div className="alert-timeline">
					<div className="title">
						<span className="summary">
							<span className="item">Alert Timeline</span>
						</span>
					</div>
					<div className="content">
						<div className="info-area">
							<img className="loading-spinner" src={iconLoading} alt={'loading'} />
							Loading Data
						</div>
					</div>
				</div>
			)
		}

		if (deviceAlertsError || deviceCommitEventsError) {
			return (
				<div className="alert-timeline">
					<div className="title">
						<span className="summary">
							<span className="item">Alert Timeline</span>
						</span>
					</div>
					<div className="content">
						<div className="info-area">Error Loading Data</div>
					</div>
				</div>
			)
		}

		let alertsStat
		alertsStat = { critical: 0, warning: 0 }
		if (!_.isEmpty(deviceAlerts)) {
			deviceAlerts.forEach((alert) => {
				if (alert.severity === 'critical') alertsStat.critical += 1
				if (alert.severity === 'warning') alertsStat.warning += 1
			})
		}

		return (
			<div className="alert-timeline">
				<div className="title">
					<span className="summary">
						<span className="item">Alert Timeline</span>
						{!_.isEmpty(alertsStat)
							? _.keys(alertsStat).map((k) => (
									<span className="item" key={k}>
										<img className={`icon-${k}`} src={iconMap[k]} alt={`icon-${k}`} />
										<span className="count">{alertsStat[k]}</span>
										<span className="status">{_.startCase(k)}</span>
									</span>
							  ))
							: null}
					</span>
					<span className="time-range-picker">
						<Select
							suffixIcon={<img src={iconArrowDown} alt="expand-icon" />}
							prefixCls={'time-range-select ant-select'}
							showArrow
							value={deviceInfoTimePreset}
							size={'small'}
							style={{ width: 130, height: 24 }}
							onSelect={(value) => updateDeviceInfoTimePreset(value)}
							getPopupContainer={(triggerNode) => triggerNode}
						>
							<Option value={'last_7_days'}>Last 7 days</Option>
							<Option value={'last_30_days'}>Last 30 days</Option>
							<Option value={'last_90_days'}>Last 90 days</Option>
						</Select>
					</span>
					<img className={`list-view`} src={this.state.viewType === 'list' ? iconListActive : iconList} alt={`icon-list`} onClick={() => this.setState({ viewType: 'list' })} />
					<img className={`chart-view`} src={this.state.viewType === 'chart' ? iconChartActive : iconChart} alt={`icon-chart`} onClick={() => this.setState({ viewType: 'chart' })} />
				</div>
				<div className="content">
					{this.state.viewType === 'chart' ? (
						<>
							<Timeline />
							<div className="start-time">
								<div className="date">{moment(deviceInfoStartTime).format('MMM DD, YYYY')}</div>
								<div className="time">{moment(deviceInfoStartTime).format('HH:mm:ss A')}</div>
							</div>
							<div className="end-time">
								<div className="date">{moment(deviceInfoEndTime).format('MMM DD, YYYY')}</div>
								<div className="time">{moment(deviceInfoEndTime).format('HH:mm:ss A')}</div>
							</div>
						</>
					) : (
						<AlertList />
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.devicePage }
}

const mapDispatchToProps = { ...actions }

export default connect(mapStateToProps, mapDispatchToProps, null)(AlertTimeline)
