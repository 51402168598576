import * as actionTypes from './actionTypes'
import Util from '../../../utils/Util'
import Constants from '../../../utils/Constants.json'
import pageInfo from '../../pageUtil'

const ACTION_HANDLERS = {
	[actionTypes.OPEN_INSIGHTS_TAB]: (state, { pageDesc, ignoreExistingDetails }) => {
		if (!(pageDesc.metric in Constants.TABLE_CHART_INFO_MAP) && !ignoreExistingDetails) {
			return {
				..._.cloneDeep(initialState),
				linePageDesc: pageDesc,
				linePageDescRequestId: null,
				linePageDescLoading: false,
				linePageDescError: null
			}
		} else {
			return { ...state }
		}
	},
	[actionTypes.RESET_STORE]: (state, { pageType }) => {
		if (pageType === pageInfo.LineMetricDetails.pageType) {
			return {
				..._.cloneDeep(initialState)
			}
		} else {
			return { ...state }
		}
	},
	/*----- PollJob Reducers -----*/
	[actionTypes.POLL_LINE_PAGE_JOB_REQUEST]: (state, { jobId }) => {
		return {
			...state,
			linePageJobId: jobId,
			linePageJobError: null
		}
	},
	[actionTypes.POLL_LINE_PAGE_JOB_FAILURE]: (state, { jobId, error }) => {
		return {
			...state,
			linePageJobId: jobId,
			linePageJobError: error
		}
	},
	/*----- Table Page Desc Reducers -----*/
	[actionTypes.FETCH_LINE_PAGE_DESC_REQUEST]: (state) => {
		return {
			...state,
			linePageDesc: null,
			linePageDescLoading: true,
			linePageDescError: null,
			linePageDescRequestId: null
		}
	},
	[actionTypes.FETCH_LINE_PAGE_DESC_SUCCESS]: (state, { pageDesc }) => {
		return {
			...state,
			linePageDesc: pageDesc,
			linePageDescLoading: false,
			linePageDescError: null,
			linePageDescRequestId: null
		}
	},
	[actionTypes.SET_LINE_PAGE_DESC_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			linePageDescRequestId: jobId
		}
	},
	[actionTypes.FETCH_LINE_PAGE_DESC_FAILURE]: (state, { error }) => {
		return {
			...state,
			linePageDesc: null,
			linePageDescLoading: false,
			linePageDescError: error,
			linePageDescRequestId: null
		}
	},
	[actionTypes.RESTORE_LINE_PAGE_DESC]: (state, { data }) => {
		return {
			...state,
			linePageDescLoading: false,
			linePageDescError: null,
			linePageDescRequestId: null,
			...data
		}
	},
	/*----- Line Chart Reducers -----*/
	[actionTypes.FETCH_RELATED_METRICS_REQUEST]: (state) => {
		return {
			...state,
			relatedMetrics: null,
			relatedMetricsLoading: true,
			relatedMetricsError: null,
			relatedMetricsRequestId: null
		}
	},
	[actionTypes.SET_RELATED_METRICS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			relatedMetricsRequestId: jobId
		}
	},
	[actionTypes.FETCH_RELATED_METRICS_SUCCESS]: (state, { content, currentTabJob }) => {
		if (currentTabJob && currentTabJob !== state.relatedMetricsRequestId) {
			return { ...state }
		} else {
			return {
				...state,
				relatedMetrics: content,
				relatedMetricsLoading: false,
				relatedMetricsError: null,
				relatedMetricsRequestId: null
			}
		}
	},
	[actionTypes.FETCH_RELATED_METRICS_FAILURE]: (state, { error }) => {
		return {
			...state,
			relatedMetrics: null,
			relatedMetricsLoading: false,
			relatedMetricsError: error,
			relatedMetricsRequestId: null
		}
	},
	[actionTypes.RESTORE_RELATED_METRICS]: (state, { data }) => {
		return {
			...state,
			relatedMetricsLoading: false,
			relatedMetricsError: false,
			relatedMetricsRequestId: null,
			...data
		}
	},
	[actionTypes.FETCH_CAPACITY_REQUEST]: (state, { metricList }) => {
		return {
			...state,
			capacity: metricList,
			capacityLoading: true,
			capacityError: false
		}
	},
	[actionTypes.FETCH_CAPACITY_SUCCESS]: (state, { data }) => {
		const capacity = {}
		Object.keys(state.capacity).forEach((metric) => {
			capacity[metric] = data.find((item) => item.name === state.capacity[metric])
		})
		return {
			...state,
			capacity,
			capacityLoading: false,
			capacityError: false
		}
	},
	[actionTypes.FETCH_CAPACITY_FAILURE]: (state, { error }) => {
		return {
			...state,
			capacity: null,
			capacityLoading: false,
			capacityError: error
		}
	},
	[actionTypes.RESTORE_CAPACITY]: (state, { data }) => {
		return {
			...state,
			capacityLoading: false,
			capacityError: false,
			...data
		}
	},
	[actionTypes.FETCH_TIMELINE_REQUEST]: (state, { device, metrics }) => {
		const timeline = {}
		metrics.forEach((metric) => (timeline[metric] = null))
		return {
			...state,
			timeline,
			timelineLoading: true,
			timelineError: false,
			timelineRequestId: []
		}
	},
	[actionTypes.FETCH_FORECAST_REQUEST]: (state, { device, metrics }) => {
		const forecast = {}
		metrics.forEach((metric) => (forecast[metric] = null))
		return {
			...state,
			forecast,
			forecastLoading: true,
			forecastError: false,
			forecastRequestId: []
		}
	},
	[actionTypes.FETCH_FORECAST_SUCCESS]: (state, { forecast, trend }) => {
		return {
			...state,
			forecast,
			trend,
			forecastLoading: false,
			forecastError: false
		}
	},
	[actionTypes.FETCH_FORECAST_FAILURE]: (state, { error }) => {
		return {
			...state,
			forecastLoading: false,
			forecastError: error
		}
	},
	[actionTypes.RESTORE_FORECAST]: (state, { data }) => {
		return {
			...state,
			forecastLoading: false,
			forecastError: false,
			...data
		}
	},
	[actionTypes.SET_TIMELINE_REQUEST_ID]: (state, { ids }) => {
		return {
			...state,
			timelineRequestId: ids
		}
	},
	[actionTypes.FETCH_TIMELINE_SUCCESS]: (state, { data, metric, jobId }) => {
		const timeline = { ...state.timeline }
		let timelineRequestId = state.timelineRequestId
		if (!timelineRequestId.includes(jobId)) {
			return { ...state }
		}
		timeline[metric] = data
		let timelineLoading = false
		Object.keys(state.timeline).forEach((metric) => {
			if (!timeline[metric]) timelineLoading = true
		})
		if (!timelineLoading) {
			timelineRequestId = []
		}
		return {
			...state,
			timeline,
			timelineLoading,
			timelineRequestId
		}
	},
	[actionTypes.FETCH_TIMELINE_FAILURE]: (state, { error }) => {
		return {
			...state,
			timelineError: error
		}
	},
	[actionTypes.RESTORE_TIMELINE]: (state, { data }) => {
		return {
			...state,
			timelineLoading: false,
			timelineError: null,
			timelineRequestId: [],
			...data
		}
	},
	[actionTypes.FETCH_MOV_AVG_REQUEST]: (state, { device, metrics }) => {
		const movingAverage = {}
		metrics.forEach((metric) => (movingAverage[metric] = null))
		return {
			...state,
			movingAverage,
			movingAverageLoading: true,
			movingAverageError: false,
			movingAverageRequestId: []
		}
	},
	[actionTypes.SET_MOV_AVG_REQUEST_ID]: (state, { ids }) => {
		return {
			...state,
			movingAverageRequestId: ids
		}
	},
	[actionTypes.FETCH_MOV_AVG_SUCCESS]: (state, { data, metric, jobId }) => {
		if (_.isEqual(data, {})) {
			return {
				...state,
				movingAverage: data,
				movingAverageLoading: false,
				movingAverageRequestId: null
			}
		}
		const movingAverage = { ...state.movingAverage }
		let movingAverageRequestId = state.movingAverageRequestId
		if (!movingAverageRequestId.includes(jobId)) {
			return { ...state }
		}
		movingAverage[metric] = data
		let movingAverageLoading = false
		Object.keys(state.movingAverage).forEach((metric) => {
			if (!movingAverage[metric]) movingAverageLoading = true
		})
		if (!movingAverageLoading) {
			movingAverageRequestId = []
		}
		return {
			...state,
			movingAverage,
			movingAverageLoading,
			movingAverageRequestId
		}
	},
	[actionTypes.FETCH_MOV_AVG_FAILURE]: (state, { error }) => {
		return {
			...state,
			movingAverageError: error
		}
	},
	[actionTypes.RESTORE_MOV_AVG]: (state, { data }) => {
		return {
			...state,
			movingAverageLoading: false,
			movingAverageError: false,
			movingAverageRequestId: [],
			...data
		}
	},
	[actionTypes.FETCH_COMMIT_EVENTS_REQUEST]: (state, {}) => {
		return {
			...state,
			commitEvents: null,
			commitEventsLoading: true,
			commitEventsError: false,
			commitEventsRequestId: null
		}
	},
	[actionTypes.SET_COMMIT_EVENTS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			commitEventsRequestId: jobId
		}
	},
	[actionTypes.FETCH_COMMIT_EVENTS_SUCCESS]: (state, { data }) => {
		return {
			...state,
			commitEvents: data,
			commitEventsLoading: false,
			commitEventsError: false
		}
	},
	[actionTypes.FETCH_COMMIT_EVENTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			commitEvents: null,
			commitEventsLoading: false,
			commitEventsError: error,
			commitEventsRequestId: null
		}
	},
	[actionTypes.RESTORE_COMMIT_EVENTS]: (state, { data }) => {
		return {
			...state,
			commitEventsLoading: false,
			commitEventsError: null,
			commitEventsRequestId: null,
			...data
		}
	},
	[actionTypes.UPDATE_TIMELINE_TIME_RANGE]: (state, { preset }) => {
		const timelineTimeRange = Util.getTimeRange(preset)
		return {
			...state,
			timelineStartTime: timelineTimeRange[0],
			timelineEndTime: timelineTimeRange[1]
		}
	},
	/*----- Update Line Chart Settings Actions -----*/
	[actionTypes.SET_CHECKED_RELATED_METRICS]: (state, { checkedRelatedMetrics }) => {
		return {
			...state,
			checkedRelatedMetrics
		}
	},
	[actionTypes.SET_SHOW_COMMIT_EVENTS]: (state, { showCommitEvents }) => {
		return {
			...state,
			showCommitEvents
		}
	},
	[actionTypes.SET_SHOW_REF_LINES]: (state, { showRefLines }) => {
		return {
			...state,
			showRefLines
		}
	},
	[actionTypes.SET_TIME_RANGE_PRESET]: (state, { timeRangePreset }) => {
		return {
			...state,
			timeRangePreset
		}
	}
}

const initialState = {
	linePageId: null,
	linePageDesc: null,
	linePageDescRequestId: null,
	linePageDescLoading: true,
	linePageDescError: null,
	linePageJobId: null,
	linePageJobError: null,
	relatedMetrics: null,
	relatedMetricsLoading: true,
	relatedMetricsError: null,
	relatedMetricsRequestId: null,
	timelineStartTime: null,
	timelineEndTime: null,
	timeline: {},
	timelineLoading: true,
	timelineError: false,
	timelineRequestId: [],
	movingAverage: {},
	movingAverageLoading: false,
	movingAverageError: false,
	movingAverageRequestId: [],
	commitEvents: null,
	commitEventsLoading: true,
	commitEventsError: false,
	commitEventsRequestId: null,
	capacity: null,
	capacityLoading: true,
	capacityError: false,
	checkedRelatedMetrics: {},
	showCommitEvents: false,
	showRefLines: true,
	timeRangePreset: Constants.TIME_RANGE_PRESETS.DefaultPreset
}

export const linePageReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
