import React from 'react'
import _ from 'lodash'

export default class AlertNameCellRenderer extends React.Component {
	render() {
		const { data } = this.props
		let cellContent
		if (data.row_desc === 'show_closed') {
			cellContent = (
				<div
					className="alert-name-cell"
					onClick={() => {
						if (!this.props.closedAlertsLoading) {
							this.props.fetchClosedAlerts()
						}
					}}
				>
					{this.props.closedAlertsLoading ? 'Loading' : 'Show Closed Alerts'}
				</div>
			)
		} else {
			cellContent = (
				<div
					className="alert-name-cell"
					onClick={() => {
						this.props.openAlertsTab({ ...data, hostname: _.get(this.props.devicesDetails, `${data.appliance_id}.hostname`, 'N/A') })
					}}
				>
					{_.startCase(data.alert_name)}
				</div>
			)
		}
		return cellContent
	}
}
