import React from "react";

const Divider = ({ className, rootPrefixCls, style }) => (
	<li className={`${className} ${rootPrefixCls}-item-divider`} style={style} />
);

Divider.defaultProps = {
	// To fix keyboard UX.
	disabled: true,
	className: "",
	style: {},
};

export default Divider;
