import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import NavSubItem from './NavSubItem'
import './NavItem.scss'

export default class NavItem extends Component {
	state = { sideMenuOpen: false }
	static getDerivedStateFromProps(nextProps, prevState) {
		if ((nextProps.isNavOpen && prevState.sideMenuOpen) || nextProps.subMenuOwner !== nextProps.appName) {
			return { sideMenuOpen: false }
		}
	}
	render() {
		const { isNavOpen, activeApp, appName, subItems, onItemClick, onItemClose, componentPath, icons, subMenuOwner, setSubMenuOwner } = this.props

		return (
			<div className={`nav-item ${appName} ${isNavOpen ? 'nav-open' : 'nav-close'}`}>
				{isNavOpen ? null : subItems.length > 1 ? (
					<div
						className={`nav-item-icon ${activeApp === appName ? 'active' : ''} ${this.state.sideMenuOpen ? 'side-menu-open' : ''}`}
						onClick={() => {
							this.setState((prevState) => ({
								sideMenuOpen: !prevState.sideMenuOpen
							}))
							if (subMenuOwner !== appName) {
								setSubMenuOwner(appName)
							} else {
								setSubMenuOwner(null)
							}
						}}
					>
						<img alt="nav-item-icon" src={this.state.sideMenuOpen ? icons.dimmed : activeApp === appName ? icons.active : icons.normal} />
					</div>
				) : (
					<Link to={componentPath}>
						<div
							className={`nav-item-icon ${activeApp === appName ? 'active' : ''}`}
							onClick={() => {
								onItemClick(subItems[0].id)
								setSubMenuOwner(null)
							}}
						>
							<img alt="nav-item-icon" src={activeApp === appName ? icons.active : icons.normal} />
						</div>
					</Link>
				)}
				{(isNavOpen && subItems.length > 0) || (this.state.sideMenuOpen && subItems.length > 1) ? (
					<div className={`sub-items ${this.state.sideMenuOpen ? 'side-menu' : ''}`}>
						{subItems.map((item, index) => {
							return (
								<NavSubItem
									key={index}
									activeApp={activeApp}
									appName={appName}
									sideMenuOpen={this.state.sideMenuOpen}
									onItemClick={onItemClick}
									onItemClose={onItemClose}
									index={index}
									itemInfo={item}
									componentPath={componentPath}
									icons={icons}
									setSubMenuOwner={setSubMenuOwner}
								/>
							)
						})}
					</div>
				) : null}
			</div>
		)
	}
}
