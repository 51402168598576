import React from 'react'
import { connect } from 'ui-lib'
import _ from 'lodash'

import DetailsPageHeader from '../../../components/DetailsPageHeader/DetailsPageHeader'
import AlertTimeline from './components/AlertTimeline/AlertTimeline'
import MetricsTable from './components/MetricsTable/MetricsTable'
import DeviceGraph from './components/DeviceGraph'
import TopAlertTypes from './components/TopAlertTypes/TopAlertTypes'
import Constants from '../../utils/Constants.json'

import * as actions from './store/actions'
import * as insightsActions from '../../store/actions'

import iconLoading from 'ui-lib/src/images/icon-loading.svg'
import './DeviceDetails.scss'

const system_modes = {
	panorama: 'Panorama',
	logger: 'Logger',
	'management-only': 'Management',
	legacy: 'Legacy',
	fw: 'Firewall'
}

class DeviceDetails extends React.Component {
	componentDidMount() {
		this.props.fetchDeviceDetailsPageContent()
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.devicePageDesc &&
			(_.get(prevProps, 'devicePageDesc.device') !== _.get(this.props, 'devicePageDesc.device') ||
				(!this.props.deviceDetails && !this.props.deviceDetailsLoading && !this.props.deviceDetailsError))
		) {
			this.props.fetchDeviceDetailsPageContent()
		}
	}

	render() {
		const { deviceInfoTimePreset, deviceDetails, devicePageDesc, insightsTabs, switchInsightsTab, activeInsightsTab } = this.props

		if (_.isEmpty(devicePageDesc) || _.isEmpty(deviceDetails)) {
			return (
				<div className="device-details">
					<div className="info-area">
						<img className="loading-spinner" src={iconLoading} alt={'loading'} />
						Loading Data
					</div>
				</div>
			)
		}

		return (
			<div className="device-details">
				<DetailsPageHeader
					pageDesc={{
						'Serial Number:': deviceDetails.device_id,
						'Model:': deviceDetails.model,
						'SW Version:': deviceDetails.sw_version,
						'Public IP Address:': deviceDetails.public_ip || 'none',
						'Management IP Address:': deviceDetails.ip,
						'Mode:': system_modes[deviceDetails.system_mode] || deviceDetails.system_mode
					}}
					pageTitle={`${_.get(insightsTabs, 'fixed_tabs[2].desc.hostname', 'N/A')}`}
					parentPageName={'Insights'}
					currentPageName={'Device Details'}
					onParentLinkClicked={() => switchInsightsTab(activeInsightsTab, Constants.FIXED_TABS[0])}
				/>
				<div className="overview">
					<div className="title">Overview</div>
					<div className="content">
						<DeviceGraph device={deviceDetails} />
					</div>
				</div>
				<AlertTimeline />
				<TopAlertTypes />
				<MetricsTable />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.insights, ...state.devicePage }
}

const mapDispatchToProps = { ...actions, ...insightsActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(DeviceDetails)
