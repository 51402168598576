import React, { Component } from 'react'

export default class LinkCellRenderer extends Component {
	render () {
    let { value, metric } = this.props
		return metric === 'security_advisory' ? (
			<span
				className='link'
				onClick={() => {
					window.open(`https://security.paloaltonetworks.com/${value}`, '_blank')
				}}
			>
				{value}
			</span>
		) : (
			<span>{value}</span>
		)
	}
}
