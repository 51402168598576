import * as actionTypes from './actionTypes'
import Constants from '../utils/Constants.json'

import moment from 'moment'

const ACTION_HANDLERS = {
	[actionTypes.INIT_INSIGHTS]: (state) => {
		return {
			...state
		}
	},
	/*----- Tabs State Reducers -----*/
	[actionTypes.UPDATE_INSIGHTS_TABS_STATE]: (state, { insightsTabs, statesToUpdate }) => {
		return {
			...state,
			insightsTabs,
			...statesToUpdate
		}
	},
	[actionTypes.INSIGHTS_INIT_TASK_COMPLETE]: (state, { task }) => {
		// if (task === 'autocomplete' && state.valueSuggestionsLoading) {
		// 	return { ...state }
		// }
		return {
			...state,
			finishedInsightsInitTasks: {
				count: state.finishedInsightsInitTasks.count + 1,
				tasks: [...state.finishedInsightsInitTasks.tasks, task]
			}
		}
	},
	/*----- Active Tab Reducers -----*/
	[actionTypes.UPDATE_INSIGHTS_ACTIVE_TAB]: (state, { index }) => {
		return {
			...state,
			activeInsightsTab: index
		}
	},
	/*----- PollJob Reducers -----*/
	[actionTypes.POLL_INSIGHTS_JOB_REQUEST]: (state, { jobId }) => {
		return {
			...state,
			insightsJobId: jobId,
			insightsJobError: null
		}
	},
	[actionTypes.POLL_INSIGHTS_JOB_FAILURE]: (state, { jobId, error }) => {
		return {
			...state,
			insightsJobId: jobId,
			insightsJobError: error
		}
	},
	/*----- Schema Reducers -----*/
	[actionTypes.SET_INSIGHTS_SCHEMA]: (state, { schema }) => {
		return {
			...state,
			insightsSchema: schema
		}
	},
	/*----- Autocomplete Reducers -----*/
	[actionTypes.FETCH_AUTOCOMPLETE_REQUEST]: (state) => {
		return {
			...state,
			valueSuggestionsLoading: true,
			valueSuggestionsError: null
		}
	},
	[actionTypes.FETCH_AUTOCOMPLETE_SUCCESS]: (state, { suggestions, field, rowsInJob }) => {
		const newSuggestions = []
		suggestions.forEach((suggestion) => {
			if (suggestion[field]) newSuggestions.push(suggestion[field])
		})
		const valueSuggestions = { ...state.valueSuggestions }
		if (newSuggestions.length > 0) {
			valueSuggestions[field].suggestions = valueSuggestions[field].suggestions.concat(newSuggestions)
			valueSuggestions[field].jobPage = valueSuggestions[field].jobPage + 1
		}
		if (valueSuggestions[field].suggestions.length === rowsInJob) {
			valueSuggestions[field].nomore = true
		}
		let valueSuggestionsLoading = false
		// if (
		// 	Object.keys(valueSuggestions).some((field) => {
		// 		return valueSuggestions[field].jobPage === -1
		// 	})
		// ) {
		// 	valueSuggestionsLoading = true
		// }
		return {
			...state,
			valueSuggestions,
			valueSuggestionsLoading,
			valueSuggestionsError: null
		}
	},
	[actionTypes.FETCH_AUTOCOMPLETE_FAILURE]: (state, { error }) => {
		return {
			...state,
			valueSuggestionsLoading: false,
			valueSuggestionsError: error
		}
	},
	/*----- Refresh Reducers -----*/
	[actionTypes.UPDATE_INSIGHTS_LAST_REFRESH]: (state, { index }) => {
		if (_.isString(index)) {
			const fixed_tabs = [...state.insightsTabs.fixed_tabs]
			let tabIndex = Constants.FIXED_TABS.indexOf(index)
			fixed_tabs[tabIndex] = { ...fixed_tabs[tabIndex] }
			fixed_tabs[tabIndex].lastRefresh = moment()
			fixed_tabs[tabIndex].isDataStale = false
			return {
				...state,
				insightsTabs: { ...state.insightsTabs, fixed_tabs }
			}
		} else {
			const custom_tabs = [...state.insightsTabs.custom_tabs]
			custom_tabs[index] = { ...custom_tabs[index] }
			custom_tabs[index].lastRefresh = moment()
			custom_tabs[index].isDataStale = false
			return {
				...state,
				insightsTabs: { ...state.insightsTabs, custom_tabs }
			}
		}
	},
	[actionTypes.UPDATE_INSIGHTS_STALE_STATUS]: (state, { index, isDataStale }) => {
		if (_.isString(index)) {
			const fixed_tabs = [...state.insightsTabs.fixed_tabs]
			let tabIndex = Constants.FIXED_TABS.indexOf(index)
			fixed_tabs[tabIndex] = { ...fixed_tabs[tabIndex] }
			fixed_tabs[tabIndex].isDataStale = isDataStale
			return {
				...state,
				insightsTabs: { ...state.insightsTabs, fixed_tabs }
			}
		} else {
			const custom_tabs = [...state.insightsTabs.custom_tabs]
			custom_tabs[index] = { ...custom_tabs[index] }
			custom_tabs[index].isDataStale = isDataStale
			return {
				...state,
				insightsTabs: { ...state.insightsTabs, custom_tabs }
			}
		}
	},
	[actionTypes.SET_INSIGHTS_LAST_UPDATE]: (state, { data }) => {
		return {
			...state,
			insightsLogsLoading: true,
			insightsLastUpdate: data
		}
	}
}

const initialState = {
	activeInsightsTab: null,
	insightsTabs: null,
	finishedInsightsInitTasks: { count: 0, tasks: [] },
	insightsSchema: null,
	insightsJobId: null,
	insightsJobError: null,
	valueSuggestions: Constants.DEFAULT_VALUE_SUGGESTIONS,
	valueSuggestionsLoading: true,
	valueSuggestionsError: null,
	insightsLastUpdate: true
}

export const insightsReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
