import _ from 'lodash'
import Constants from '../utils/Constants.json'

const pageUtil = {
	InsightsMain: {
		pageType: 'InsightsMain',
		storeKey: 'insightsMain',
		cacheUtil: (store, activeInsightsTab) => {
			const pageCache = { state: null, data: null }
			_.set(pageCache, 'state.hexgridSetting', _.cloneDeep(store.hexgridSettings[activeInsightsTab]))
			_.set(pageCache, 'state.pageSize', store.insightsPageSize)
			_.set(pageCache, 'state.query', store.insightsQuery)

			// Do not cache data if there's unsettled requests on page
			if (
				!(
					store.healthStatusLoading ||
					store.healthStatusError ||
					store.hexgridLoading ||
					store.hexgridError ||
					store.insightsLogsLoading ||
					store.insightsLogsError ||
					store.insightsLogsCountLoading ||
					store.insightsLogsCountError ||
					store.insightsDetailLogsLoading ||
					store.insightsDetailLogsError
				)
			) {
				store.insightsLogs.forEach((log) => {
					log.details_loading = false
				})
				pageCache.data = {
					healthStatus: store.healthStatus,
					hexgrid: store.hexgrid,
					insightsLogs: store.insightsLogs,
					insightsLogsRequestId: store.insightsLogsRequestId,
					insightsLogsCount: store.insightsLogsCount,
					insightsPageLimit: store.insightsPageLimit,
					insightsPageNumber: store.insightsPageNumber
				}
			}
			return pageCache
		},
		pageDefaultSettings: { hexgridSetting: { sortBy: 'model', healthFilter: false }, pageSize: 10, query: '' }
	},
	LineMetricDetails: {
		pageType: 'LineMetricDetails',
		storeKey: 'linePage',
		cacheUtil: (store, activeInsightsTab) => {
			const pageCache = { state: null, data: null }
			_.set(pageCache, 'state.timeRange', store.timeRangePreset)
			_.set(pageCache, 'state.checkState', store.checkedRelatedMetrics)
			_.set(pageCache, 'state.showCommitEvents', store.showCommitEvents)
			_.set(pageCache, 'state.showRefLines', store.showRefLines)
			// _.set(pageCache, 'state.showForecast', store.showForecast)

			if (
				!(
					store.linePageDescLoading ||
					store.linePageDescError ||
					store.relatedMetricsLoading ||
					store.relatedMetricsError ||
					store.timelineLoading ||
					store.timelineError ||
					store.movingAverageLoading ||
					store.movingAverageError ||
					store.commitEventsLoading ||
					store.commitEventsError ||
					store.capacityLoading ||
					store.capacityError ||
					store.forecastError
				)
			) {
				pageCache.data = {
					linePageDesc: store.linePageDesc,
					relatedMetrics: store.relatedMetrics,
					timeline: store.timeline,
					movingAverage: store.movingAverage,
					commitEvents: store.commitEvents,
					capacity: store.capacity,
					forecast: store.forecast
				}
			}
			return pageCache
		},
		pageDefaultSettings: { checkState: {}, showCommitEvents: false, showRefLines: true, timeRange: Constants.TIME_RANGE_PRESETS.DefaultPreset }
	},
	TableMetricDetails: {
		pageType: 'TableMetricDetails',
		storeKey: 'tablePage',
		cacheUtil: (store, activeInsightsTab) => {
			const pageCache = { data: null }
			if (!(store.tablePageDescLoading || store.tablePageDescError || store.tableChartDataLoading || store.tableChartDataError)) {
				pageCache.data = {
					tablePageDesc: store.tablePageDesc,
					tableChartData: store.tableChartData
				}
			}
			return pageCache
		},
		pageDefaultSettings: {}
	},
	DeviceDetails: {
		pageType: 'DeviceDetails',
		storeKey: 'devicePage',
		cacheUtil: (store, activeInsightsTab) => {
			const pageCache = { state: null, data: null }
			return pageCache
		}
	}
}

export default pageUtil
