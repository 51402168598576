import * as actionTypes from './actionTypes'

export const fetchAlertsNotificationsPageContent = () => ({
	type: actionTypes.FETCH_ALERTS_NOTIFICATIONS_PAGE_CONTENT
})

/*----- Notifications Actions -----*/
export const fetchRulesRequest = () => ({
	type: actionTypes.FETCH_RULES_REQUEST
})

export const fetchRulesSuccess = (rules) => ({
	type: actionTypes.FETCH_RULES_SUCCESS,
	rules
})

export const fetchRulesFailure = (error) => ({
	type: actionTypes.FETCH_RULES_FAILURE,
	error
})

export const restoreRules = (data) => ({
	type: actionTypes.RESTORE_RULES,
	data
})

export const addRuleRequest = (ruleDef) => ({
	type: actionTypes.ADD_RULE_REQUEST,
	ruleDef
})

export const addRuleSuccess = (rule) => ({
	type: actionTypes.ADD_RULE_SUCCESS,
	rule
})

export const addRuleFailure = (error) => ({
	type: actionTypes.ADD_RULE_FAILURE,
	error
})

export const updateRuleRequest = (ruleId, ruleDef) => ({
	type: actionTypes.UPDATE_RULE_REQUEST,
	ruleId,
	ruleDef
})

export const updateRuleSuccess = (rule) => ({
	type: actionTypes.UPDATE_RULE_SUCCESS,
	rule
})

export const updateRuleFailure = (error) => ({
	type: actionTypes.UPDATE_RULE_FAILURE,
	error
})

export const removeRuleRequest = (ruleId) => ({
	type: actionTypes.REMOVE_RULE_REQUEST,
	ruleId
})

export const removeRuleSuccess = (rule) => ({
	type: actionTypes.REMOVE_RULE_SUCCESS,
	rule
})

export const removeRuleFailure = (error) => ({
	type: actionTypes.REMOVE_RULE_FAILURE,
	error
})

/*----------- Email Template actions ---------------*/
export const fetchEmailTemplatesRequest = () => ({
	type: actionTypes.FETCH_EMAIL_TEMPLATES_REQUEST
})

export const fetchEmailTemplatesSuccess = (templates) => ({
	type: actionTypes.FETCH_EMAIL_TEMPLATES_SUCCESS,
	templates
})

export const fetchEmailTemplatesFailure = (error) => ({
	type: actionTypes.FETCH_EMAIL_TEMPLATES_FAILURE,
	error
})

export const addEmailTemplateRequest = (template) => ({
	type: actionTypes.ADD_EMAIL_TEMPLATE_REQUEST,
	template
})

export const addEmailTemplateSuccess = () => ({
	type: actionTypes.ADD_EMAIL_TEMPLATE_SUCCESS
})

export const addEmailTemplateFailure = (error) => ({
	type: actionTypes.ADD_EMAIL_TEMPLATE_FAILURE,
	error
})

export const removeEmailTemplateRequest = (templateId) => ({
	type: actionTypes.REMOVE_EMAIL_TEMPLATE_REQUEST,
	templateId
})

export const removeEmailTemplateSuccess = () => ({
	type: actionTypes.REMOVE_EMAIL_TEMPLATE_SUCCESS
})

export const removeEmailTemplateFailure = (error) => ({
	type: actionTypes.REMOVE_EMAIL_TEMPLATE_FAILURE,
	error
})

export const updateEmailTemplateRequest = (templateId, template) => ({
	type: actionTypes.UPDATE_EMAIL_TEMPLATE_REQUEST,
	template,
	templateId
})

export const udpateEmailTemplateSuccess = () => ({
	type: actionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS
})

export const udpateEmailTemplateFailure = (error) => ({
	type: actionTypes.UPDATE_EMAIL_TEMPLATE_FAILURE,
	error
})

/*------------- Service Now Config ------------*/
export const fetchConfigRequest = () => ({
	type: actionTypes.FETCH_CONFIG_REQUEST
})

export const fetchConfigSuccess = (config) => ({
	type: actionTypes.FETCH_CONFIG_SUCCESS,
	config
})

export const fetchConfigFailure = (error) => ({
	type: actionTypes.FETCH_CONFIG_FAILURE,
	error
})

export const addConfigRequest = (config) => ({
	type: actionTypes.ADD_CONFIG_REQUEST,
	config
})

export const addConfigSuccess = (config) => ({
	type: actionTypes.ADD_CONFIG_SUCCESS,
	config
})

export const addConfigFailure = (error) => ({
	type: actionTypes.ADD_CONFIG_FAILURE,
	error
})

export const removeConfigError = () => ({
	type: actionTypes.REMOVE_CONFIG_ERROR
})

/*----------- Service Now Template actions ---------------*/
export const fetchSNTemplatesRequest = () => ({
	type: actionTypes.FETCH_SN_TEMPLATES_REQUEST
})

export const fetchSNTemplatesSuccess = (templates) => ({
	type: actionTypes.FETCH_SN_TEMPLATES_SUCCESS,
	templates
})

export const fetchSNTemplatesFailure = (error) => ({
	type: actionTypes.FETCH_SN_TEMPLATES_FAILURE,
	error
})

export const addSNTemplateRequest = (template) => ({
	type: actionTypes.ADD_SN_TEMPLATE_REQUEST,
	template
})

export const addSNTemplateSuccess = () => ({
	type: actionTypes.ADD_SN_TEMPLATE_SUCCESS
})

export const addSNTemplateFailure = (error) => ({
	type: actionTypes.ADD_SN_TEMPLATE_FAILURE,
	error
})

export const removeSNTemplateRequest = (templateId) => ({
	type: actionTypes.REMOVE_SN_TEMPLATE_REQUEST,
	templateId
})

export const removeSNTemplateSuccess = () => ({
	type: actionTypes.REMOVE_SN_TEMPLATE_SUCCESS
})

export const removeSNTemplateFailure = (error) => ({
	type: actionTypes.REMOVE_SN_TEMPLATE_FAILURE,
	error
})

export const updateSNTemplateRequest = (templateId, template) => ({
	type: actionTypes.UPDATE_SN_TEMPLATE_REQUEST,
	template,
	templateId
})

export const udpateSNTemplateSuccess = () => ({
	type: actionTypes.UPDATE_SN_TEMPLATE_SUCCESS
})

export const udpateSNTemplateFailure = (error) => ({
	type: actionTypes.UPDATE_SN_TEMPLATE_FAILURE,
	error
})

export const fetchSNGroupsRequest = () => ({
	type: actionTypes.FETCH_SN_GROUPS_REQUEST
})

export const fetchSNGroupsSuccess = (groups) => ({
	type: actionTypes.FETCH_SN_GROUPS_SUCCESS,
	groups
})

export const fetchSNGroupsFailure = () => ({
	type: actionTypes.FETCH_SN_GROUPS_FAILURE
})

export const fetchSNGroupUsersRequest = (groupId) => ({
	type: actionTypes.FETCH_SN_GROUPUSERS_REQUEST,
	groupId
})

export const fetchSNGroupUsersSuccess = (groupUsers) => ({
	type: actionTypes.FETCH_SN_GROUPUSERS_SUCCESS,
	groupUsers
})

export const fetchSNGroupUsersFailure = (error) => ({
	type: actionTypes.FETCH_SN_GROUPUSERS_FAILURE,
	error
})
