import React, { Component, Fragment } from 'react'
// import { Popover } from 'antd'
import { PopoverWidget as Popover } from 'ui-lib'
import { connect } from 'ui-lib'

import * as insightsActions from '../../../store/actions'
import * as alertsActions from '../../../../alerts/store/actions'

import './LastUpdateHeaderCellRenderer.scss'

class LastUpdateHeaderCellRenderer extends Component {
	setUpdatedTime = (value) => {
		if (this.props.activeApp === 'insights') this.props.setInsightsLastUpdate(value === 'relative')
		if (this.props.activeApp === 'alerts') this.props.setAlertsLastUpdate(value === 'relative')
	}

	render () {
		const cont = (
			<div style={{ cursor: 'pointer' }}>
				<ul className='time-list'>
					<li>
						<input
							onChange={(value) => {
								this.setUpdatedTime('absolute')
							}}
							className=''
							type='radio'
							name='lastupdate'
							value='absolute'
							checked={this.props[`${this.props.activeApp}LastUpdate`] === false}
						/>

						<label for='absolute'>Absolute Time</label>
					</li>
					<li>
						<input
							onChange={(value) => {
								this.setUpdatedTime('relative')
							}}
							type='radio'
							name='lastupdate'
							value='relative'
							checked={this.props[`${this.props.activeApp}LastUpdate`] === true}
						/>
						<label for='relative'>Relative Time</label>
					</li>
				</ul>
			</div>
		)

		return (
			<Fragment>
				<Popover prefixCls='cell-popover' arrowPointAtCenter={true} placement='bottom' content={cont} trigger='click' align={{ offset: [-2, 0] }}>
					<span className='icon-clock'>
						Last Update
						<span className='show-icon'></span>
					</span>
				</Popover>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.insights, ...state.alerts }
}

const mapDispatchToProps = { ...insightsActions, ...alertsActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(LastUpdateHeaderCellRenderer)
