import React from 'react'
import { connect } from 'ui-lib'
import { withRouter } from 'react-router-dom'
import * as alertsMainActions from '../../store/actions'
import * as alertsActions from '../../../../store/actions'
import * as insightsActions from '../../../../../insights/store/actions'
import * as visibilityActions from '../../../../../store/actions'

import MasterDetailGridWidget from './MasterDetailGridWidget/MasterDetailGridWidget'
import DetailCellRenderer from '../../../../components/CellRenderers/DetailCellRenderer/DetailCellRenderer'
import SeverityCellRenderer from '../../../../components/CellRenderers/SeverityCellRenderer'
import HostnameCellRenderer from '../../../../components/CellRenderers/HostnameCellRenderer'
import StatusCellRenderer from '../../../../components/CellRenderers/StatusCellRenderer/StatusCellRenderer'
import AlertNameCellRenderer from '../../../../components/CellRenderers/AlertNameCellRenderer'
import CommentCellRenderer from '../../../../components/CellRenderers/CommentCellRenderer/CommentCellRenderer'
import LastUpdateHeaderCellRenderer from '../../../../../insights/components/CellRenderers/LastUpdatedHeaderCellRenderer/LastUpdateHeaderCellRenderer'

import './AlertsTable.scss'

import iconLoading from 'ui-lib/src/images/icon-loading.svg'
class AlertsTable extends React.Component {
	state = {
		editingCommentInfo: null,
		resetSchema: false,
		defaultColDef: { flex: 1 },
		frameworkComponents: {
			DetailCellRenderer,
			SeverityCellRenderer,
			StatusCellRenderer,
			AlertNameCellRenderer,
			CommentCellRenderer,
			LastUpdateHeaderCellRenderer,
			HostnameCellRenderer
		}
	}

	componentDidUpdate(prevProps) {
		if (this.gridApi && this.gridColumnApi && prevProps.alertsSchema !== this.props.alertsSchema) {
			this.gridColumnApi.resetColumnState()
			this.gridApi.sizeColumnsToFit()
		}

		if (
			prevProps.comments !== this.props.comments ||
			prevProps.events !== this.props.events ||
			prevProps.activities !== this.props.activities ||
			prevProps.closedAlertsLoading !== this.props.closedAlertsLoading
		) {
			if (this.gridApi) this.gridApi.redrawRows()
		}
	}

	exportData = (fileType, fileName) => {
		if (fileType === 'csv') {
			this.gridApi.exportDataAsCsv({
				fileName,
				shouldRowBeSkipped: (params) => {
					if (params.node.data.row_desc === 'show_closed') return true
				}
			})
		}
		if (fileType === 'xml') {
			this.gridApi.exportDataAsExcel({
				fileName,
				exportMode: 'xml',
				shouldRowBeSkipped: (params) => {
					if (params.node.data.row_desc === 'show_closed') return true
				}
			})
		}
	}

	onOpenDetails = (alertId) => {
		this.props.fetchCommentsRequest(alertId)
		this.props.fetchEventsRequest(alertId)
		this.props.fetchActivitiesRequest(this.props.loggedInUser.email, alertId, alertId)
	}

	onCloseDetails = () => {
		// console.log('Close Details')
	}

	onGridReady = (params) => {
		this.gridApi = params.api
		this.gridColumnApi = params.columnApi
		this.gridApi.sizeColumnsToFit()
		this.props.setExportClick(this.exportData)
	}

	onGridResized = () => {
		if (this.gridApi) {
			this.gridApi.sizeColumnsToFit()
			this.gridApi.resetRowHeights()
		}
	}

	getMainMenuItems = (params) => {
		const itemsToExclue = ['autoSizeThis', 'autoSizeAll', 'resetColumns']
		const menu = params.defaultItems.filter((item) => !itemsToExclue.includes(item))
		menu.push({
			name: 'Reset Columns',
			action: () => {
				this.props.resetAlertsSchema(this.props.activeAlertsTab.split('.')[1])
			}
		})
		return menu
	}

	getRowHeight = (params) => {
		if (params.node && params.node.detail) {
			const detailCell = document.getElementById(params.data.alert_id)
			if (detailCell) return detailCell.clientHeight + 1
			return 0
		}
		return 28
	}

	getRowStyle = (params) => {
		if (parseInt(params.node.id) % 2 === 0) {
			return { background: 'white' }
		} else {
			return { background: '#f4f5f5' }
		}
	}

	render() {
		const {
			closedAlertsLoading,
			closedAlerts,
			activeAlertsTab,
			openAlertsTab,
			alertsSchema,
			alertsMisc,
			metricsMisc,
			alerts,
			comments,
			events,
			activities,
			alertsLoading,
			alertsError,
			devicesDetails,
			devicesDetailsLoading,
			devicesDetailsError,
			updateActiveApp,
			openInsightsTab
		} = this.props

		if (alertsError || devicesDetailsError) {
			return <div className="info-area">Error Fetching Alerts</div>
		}

		if (alertsLoading || devicesDetailsLoading) {
			return (
				<div className="info-area">
					<img className="loading-spinner" src={iconLoading} alt={'loading'} />
					Loading Data
				</div>
			)
		}

		if (_.isEmpty(alerts)) {
			return <div className="info-area">No Data Found</div>
		}

		const [vendor, type] = activeAlertsTab.split('.')
		const fields = alertsSchema[vendor].content[type].fields //.filter((field) => field.name !== 'severity')

		const columns = _.map(fields, (fieldObj, i) => {
			const col = {
				lockPosition: true,
				lockVisible: true,
				flex: null,
				headerName: fieldObj.displayName,
				field: fieldObj.name,
				colId: fieldObj.name,
				pinned: fieldObj.pinned,
				resizable: false,
				hide: fieldObj.hidden,
				filterable: fieldObj.filterable,
				width: fieldObj.defaultWidth,
				minWidth: 80,
				suppressSizeToFit: true,
				valueFormatter: (params) => {
					if (['true', 'false', 'yes', 'no'].includes(params.value)) {
						return _.capitalize(params.value)
					} else {
						return params.value
					}
				}
			}

			if (col.field === 'hostname') {
				col.cellRenderer = 'HostnameCellRenderer'
				col.cellRendererParams = {
					devicesDetails: this.props.devicesDetails,
					openDevicePage: this.props.openDevicePage,
					updateActiveApp: this.props.updateActiveApp,
					redirectToAppRoot: () => {
						this.props.history.push('/')
					}
				}
			}

			if (col.field === 'severity') {
				col.cellRenderer = 'SeverityCellRenderer'
				col.cellRendererParams = {
					editingCommentInfo: this.state.editingCommentInfo,
					onOpenDetails: this.onOpenDetails,
					onCloseDetails: this.onCloseDetails
				}
			}

			if (col.field === 'status') {
				col.cellRenderer = 'StatusCellRenderer'
				col.cellRendererParams = {
					assignee: this.props.loggedInUser.email,
					updateAlert: this.props.updateAlertRequest
				}
			}

			if (col.field === 'alert_name') {
				col.flex = 1
				col.resizable = true
				col.suppressSizeToFit = false
				col.cellRenderer = 'AlertNameCellRenderer'
				col.cellRendererParams = {
					openAlertsTab: this.props.openAlertsTab,
					devicesDetails,
					closedAlertsLoading: this.props.closedAlertsLoading,
					fetchClosedAlerts: this.props.fetchClosedAlertsRequest
				}
			}

			if (col.field === 'time') {
				col.cellRenderer = 'CommentCellRenderer'
				col.headerComponent = 'LastUpdateHeaderCellRenderer'
				col.cellRendererParams = {
					cancelEditing: (node) => {
						if (this.state.editingCommentInfo) {
							this.setState({ editingCommentInfo: null }, () => {
								if (this.gridApi) this.gridApi.redrawRows({ rowNodes: [node] })
							})
						}
					},
					editingCommentInfo: this.state.editingCommentInfo,
					addComment: this.props.addCommentRequest,
					updateComment: this.props.updateCommentRequest
				}
				col.lockPinned = true
			}

			return col
		})

		let rowData
		if (!closedAlertsLoading && _.isArray(closedAlerts)) {
			rowData = [...alerts, ...closedAlerts]
		} else {
			rowData = [...alerts, { row_desc: 'show_closed' }]
		}

		return (
			<MasterDetailGridWidget
				logType={activeAlertsTab}
				enableSorting={false}
				columnDefs={columns}
				defaultColDef={this.state.defaultColDef}
				getMainMenuItems={this.getMainMenuItems}
				detailCellRendererParams={{
					redirectToAppRoot: () => {
						this.props.history.push('/')
					},
					updateActiveApp,
					openInsightsTab,
					devicesDetails,
					alertsMisc,
					metricsMisc,
					comments,
					events,
					activities,
					removeComment: this.props.removeCommentRequest,
					editingCommentInfo: this.state.editingCommentInfo,
					setEditingCommentInfo: (info, node) => {
						this.setState({ editingCommentInfo: info }, () => {
							if (this.gridApi) this.gridApi.redrawRows({ rowNodes: [node] })
						})
					}
				}}
				detailCellRenderer={'DetailCellRenderer'}
				frameworkComponents={this.state.frameworkComponents}
				rowData={rowData}
				onGridReady={this.onGridReady}
				onGridSizeChanged={this.onGridResized}
				onModelUpdated={this.onModelUpdated}
				onRowClicked={this.onRowClicked}
				onCellClicked={this.onCellClicked}
				onColumnPinned={() => this.gridApi.redrawRows()}
				getRowHeight={this.getRowHeight}
				getRowStyle={this.getRowStyle}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alerts, ...state.alertsMain, ...state.visibility, loggedInUser: state.main.loggedInUser }
}

const mapDispatchToProps = { ...alertsActions, ...alertsMainActions, ...insightsActions, ...visibilityActions }

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null)(AlertsTable))
