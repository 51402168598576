import React, { Component } from 'react'
import iconList from 'ui-lib/src/images/icon-list.svg'
import { PopoverWidget as Popover } from 'ui-lib'

export default class ArrayCellRenderer extends Component {
	render() {
		// If less than two options, show it directly
		if (this.props.value.length < 1) {
			return <div />
		} else if (this.props.value.length === 1) {
			return <div>{this.props.value[0]}</div>
		}

		// More than one options, show dropdown
		const content = (
			<>
				{this.props.value.map((item) => (
					<div>{item}</div>
				))}
			</>
		)

		return (
			<Popover prefixCls="cell-popover" content={content} trigger="hover" placement="bottom">
				<img className="list-btn" src={iconList} alt="List" />
				<span>{this.props.value[0]}</span>
			</Popover>
		)
	}
}
