import React, { Component } from 'react'
import { PopoverWidget as Popover } from 'ui-lib'
import Util from '../../utils/Util'

export default class MetricCellRender extends Component {
	createMiscHTML = (snippet) => ({ __html: snippet })
	state = {
		popoverVisible: false
	}
	togglePopover = () => {
		this.setState((prev) => ({ popoverVisible: !prev.popoverVisible }))
	}
	render() {
		const { data, metricsMisc } = this.props
		let displayName = Util.getMetricDisplayName(metricsMisc, data.metric)
		const misc = metricsMisc[Util.getGenericMetricName(data.metric)] ? metricsMisc[Util.getGenericMetricName(data.metric)] : {}
		return (
			<span>
				<Popover
					transitionName={null}
					mouseLeaveDelay={0}
					mouseEnterDelay={0}
					prefixCls={`metric-desc-popover${this.state.popoverVisible ? '' : '-hidden'}`}
					content={<div className="content" dangerouslySetInnerHTML={this.createMiscHTML(misc.description)} />}
					trigger="hover"
					placement="bottomLeft"
				>
					<span onMouseLeave={this.togglePopover} onMouseEnter={this.togglePopover}>
						{displayName}
					</span>
				</Popover>
			</span>
		)
	}
}
