export default function () {
	return {
		metadata: { version: 4.5, type: 'Object', generator: 'Object3D.toJSON' },
		geometries: [
			{
				uuid: '5508698F-7B13-4076-92B0-F7D08FF91961',
				type: 'BufferGeometry',
				data: {
					attributes: {
						position: {
							itemSize: 3,
							type: 'Float32Array',
							array: [
								-0.015318451449275017,
								0,
								-0.11130818724632263,
								0.015320174396038055,
								0,
								-0.11130816489458084,
								-6.05359673500061e-7,
								0,
								-0.11233887821435928,
								-0.030010199174284935,
								0,
								-0.10830587893724442,
								0.030013861134648323,
								0,
								-0.10830587893724442,
								-0.043941300362348557,
								0,
								-0.10346665978431702,
								0.04394601657986641,
								0,
								-0.10346665978431702,
								-0.05697721615433693,
								0,
								-0.09692516922950745,
								-0.05697721615433693,
								0,
								-0.09692516922950745,
								-6.05359673500061e-7,
								0,
								-0.097285196185112,
								0.04394601657986641,
								0,
								-0.10346665978431702,
								-6.05359673500061e-7,
								0,
								-0.097285196185112,
								0.05698224902153015,
								0,
								-0.09692516922950745,
								-0.013258657418191433,
								0,
								-0.09639063477516174,
								0.013257458806037903,
								0,
								-0.09639065712690353,
								-0.06898339092731476,
								0,
								-0.0888160690665245,
								-0.013258657418191433,
								0,
								-0.09639063477516174,
								0.06898815184831619,
								0,
								-0.0888160690665245,
								0.025979336351156235,
								0,
								-0.09378533065319061,
								-0.02598053589463234,
								0,
								-0.09378530830144882,
								0.038047485053539276,
								0,
								-0.08958662301301956,
								-0.06898339092731476,
								0,
								-0.0888160690665245,
								-0.03804868459701538,
								0,
								-0.08958660066127777,
								-0.02598053589463234,
								0,
								-0.09378530830144882,
								0.04934435710310936,
								0,
								-0.08391198515892029,
								-0.049345556646585464,
								0,
								-0.0839119628071785,
								-0.0798252746462822,
								0,
								-0.07927402853965759,
								0.07982932776212692,
								0,
								-0.07927402853965759,
								0.05975239723920822,
								0,
								-0.07687880843877792,
								-0.05975360423326492,
								0,
								-0.07687880098819733,
								-0.0893683135509491,
								0,
								-0.0684337243437767,
								0.05975239723920822,
								0,
								-0.07687880843877792,
								0.08937136828899384,
								0,
								-0.0684337243437767,
								0.07982932776212692,
								0,
								-0.07927402853965759,
								0.06915407627820969,
								0,
								-0.06860454380512238,
								0.08937136828899384,
								0,
								-0.0684337243437767,
								-0.06915528327226639,
								0,
								-0.06860452890396118,
								-0.0019146478734910488,
								0,
								-0.06988482922315598,
								0.0019148457795381546,
								0,
								-0.06988484412431717,
								9.313225746154785e-8,
								0,
								-0.0700136199593544,
								0.0037512974813580513,
								0,
								-0.06950969249010086,
								-0.0037510995753109455,
								0,
								-0.06950968503952026,
								0.005492637865245342,
								0,
								-0.06890498846769333,
								-0.0054924399591982365,
								0,
								-0.06890497356653214,
								0.007122056558728218,
								0,
								-0.06808753311634064,
								-0.007121858652681112,
								0,
								-0.06808751821517944,
								0.07743185013532639,
								0,
								-0.059206604957580566,
								-0.07743304967880249,
								0,
								-0.05920659005641937,
								-0.0974779799580574,
								0,
								-0.05642981827259064,
								0.09747987985610962,
								0,
								-0.05642981827259064,
								0.00862274318933487,
								0,
								-0.06707413494586945,
								-0.008622545748949051,
								0,
								-0.06707412749528885,
								0.009977887384593487,
								0,
								-0.06588160246610641,
								-0.009977689012885094,
								0,
								-0.06588158756494522,
								-0.009977689012885094,
								0,
								-0.06588158756494522,
								0.011170666664838791,
								0,
								-0.0645267441868782,
								0.009977887384593487,
								0,
								-0.06588160246610641,
								-0.011170469224452972,
								0,
								-0.06452673673629761,
								0.011170666664838791,
								0,
								-0.0645267441868782,
								0.012184283696115017,
								0,
								-0.06302637606859207,
								-0.012184085324406624,
								0,
								-0.06302636116743088,
								0.013001915067434311,
								0,
								-0.06139729171991348,
								-0.013001717627048492,
								0,
								-0.061397284269332886,
								0.013606751337647438,
								0,
								-0.05965631455183029,
								-0.013606552965939045,
								0,
								-0.0596562996506691,
								0.013981981202960014,
								0,
								-0.05782024562358856,
								-0.013981782831251621,
								0,
								-0.057820238173007965,
								0.08446816354990005,
								0,
								-0.04880240187048912,
								-0.08446934819221497,
								0,
								-0.048802390694618225,
								-0.013981782831251621,
								0,
								-0.057820238173007965,
								0.01411079429090023,
								0,
								-0.05590590089559555,
								0.013981981202960014,
								0,
								-0.05782024562358856,
								-0.014110596850514412,
								0,
								-0.05590590089559555,
								0.01411079429090023,
								0,
								-0.05590590089559555,
								-0.10401970893144608,
								0,
								-0.04339698329567909,
								0.08446816354990005,
								0,
								-0.04880240187048912,
								0.10402046889066696,
								0,
								-0.04339698329567909,
								0.09747987985610962,
								0,
								-0.05642981827259064,
								-0.014110596850514412,
								0,
								-0.054517894983291626,
								0.01411079429090023,
								0,
								-0.05451791733503342,
								0.01411079429090023,
								0,
								-0.050680484622716904,
								-0.014110596850514412,
								0,
								-0.05068046227097511,
								0.01411079429090023,
								0,
								-0.044883500784635544,
								-0.014110596850514412,
								0,
								-0.04488347843289375,
								0.09014546126127243,
								0,
								-0.03750937059521675,
								0.10402046889066696,
								0,
								-0.04339698329567909,
								-0.09014665335416794,
								0,
								-0.037509359419345856,
								0.01411079429090023,
								0,
								-0.03761686757206917,
								-0.014110596850514412,
								0,
								-0.03761684522032738,
								-0.10885895788669586,
								0,
								-0.029469871893525124,
								0.10885871201753616,
								0,
								-0.02946988306939602,
								0.01411079429090023,
								0,
								-0.029370462521910667,
								-0.014110596850514412,
								0,
								-0.029370440170168877,
								0.09434621036052704,
								0,
								-0.025444937869906425,
								0.10885871201753616,
								0,
								-0.02946988306939602,
								0.09014546126127243,
								0,
								-0.03750937059521675,
								-0.10885895788669586,
								0,
								-0.029469871893525124,
								-0.09434740245342255,
								0,
								-0.02544492669403553,
								-0.09014665335416794,
								0,
								-0.037509359419345856,
								0.09434621036052704,
								0,
								-0.025444937869906425,
								0.11186022311449051,
								0,
								-0.014783170074224472,
								-0.11186117678880692,
								0,
								-0.014783157035708427,
								-0.09434740245342255,
								0,
								-0.02544492669403553,
								0.01411079429090023,
								0,
								-0.02063416875898838,
								-0.014110596850514412,
								0,
								-0.02063414640724659,
								0.09695287048816681,
								0,
								-0.012726517394185066,
								-0.09695404767990112,
								0,
								-0.01272650621831417,
								0.01411079429090023,
								0,
								-0.011897874996066093,
								-0.014110596850514412,
								0,
								-0.011897850781679153,
								0.11289059370756149,
								0,
								0.000528472475707531,
								-0.1128918007016182,
								0,
								0.000528472475707531,
								0.0978478491306305,
								0,
								0.000528472475707531,
								-0.0978490561246872,
								0,
								0.000528472475707531,
								0.01411079429090023,
								0,
								-0.003651465754956007,
								-0.014110596850514412,
								0,
								-0.0036514434032142162,
								0.01411079429090023,
								0,
								-0.003651465754956007,
								-0.014110596850514412,
								0,
								-0.011897850781679153,
								-0.014110596850514412,
								0,
								-0.0036514434032142162,
								0.01411079429090023,
								0,
								0.003615166526287794,
								-0.014110596850514412,
								0,
								0.0036151898093521595,
								-0.11186116933822632,
								0,
								0.015840113162994385,
								-0.09695404767990112,
								0,
								0.013783451169729233,
								0.09695284813642502,
								0,
								0.013783451169729233,
								0.1118602454662323,
								0,
								0.015840113162994385,
								0.01411079429090023,
								0,
								0.009412148967385292,
								-0.014110596850514412,
								0,
								0.009412172250449657,
								0.01411079429090023,
								0,
								0.013249581679701805,
								-0.014110596850514412,
								0,
								0.013249605894088745,
								0.01411079429090023,
								0,
								0.014637589454650879,
								-0.014110596850514412,
								0,
								0.014637589454650879,
								-0.11186116933822632,
								0,
								0.015840113162994385,
								-0.09434740245342255,
								0,
								0.02650187723338604,
								-0.09695404767990112,
								0,
								0.013783451169729233,
								0.09434618800878525,
								0,
								0.026501871645450592,
								-0.013981782831251621,
								0,
								0.01655193604528904,
								-0.013981782831251621,
								0,
								0.01655193604528904,
								0.013981981202960014,
								0,
								0.016551941633224487,
								0.01411079429090023,
								0,
								0.014637589454650879,
								-0.10885894298553467,
								0,
								0.030526820570230484,
								-0.09434740245342255,
								0,
								0.02650187723338604,
								0.09434618800878525,
								0,
								0.026501871645450592,
								0.10885873436927795,
								0,
								0.03052682615816593,
								0.1118602454662323,
								0,
								0.015840113162994385,
								-0.013606552965939045,
								0,
								0.018387997522950172,
								0.013981981202960014,
								0,
								0.016551941633224487,
								0.013606751337647438,
								0,
								0.01838800311088562,
								-0.013001717627048492,
								0,
								0.020128970965743065,
								0.013001915067434311,
								0,
								0.020128976553678513,
								-0.012184085324406624,
								0,
								0.021758046001195908,
								0.012184283696115017,
								0,
								0.021758051589131355,
								-0.011170469224452972,
								0,
								0.02325841784477234,
								0.011170666664838791,
								0,
								0.023258423432707787,
								-0.009977689012885094,
								0,
								0.0246132705360651,
								-0.009977689012885094,
								0,
								0.0246132705360651,
								0.009977887384593487,
								0,
								0.0246132779866457,
								0.011170666664838791,
								0,
								0.023258423432707787,
								-0.008622545748949051,
								0,
								0.025805801153182983,
								0.009977887384593487,
								0,
								0.0246132779866457,
								0.00862274318933487,
								0,
								0.02580580674111843,
								-0.007121858652681112,
								0,
								0.026819201186299324,
								-0.007121858652681112,
								0,
								0.026819201186299324,
								0.007122056558728218,
								0,
								0.02681920863687992,
								0.00862274318933487,
								0,
								0.02580580674111843,
								0.09014543890953064,
								0,
								0.03856630623340607,
								-0.10885894298553467,
								0,
								0.030526820570230484,
								-0.09014665335416794,
								0,
								0.03856631740927696,
								-0.0054924399591982365,
								0,
								0.027636658400297165,
								0.007122056558728218,
								0,
								0.02681920863687992,
								0.005492637865245342,
								0,
								0.027636663988232613,
								-0.0037510995753109455,
								0,
								0.028241366147994995,
								0.0037512974813580513,
								0,
								0.028241371735930443,
								-0.0019146478734910488,
								0,
								0.028616519644856453,
								0.0019148457795381546,
								0,
								0.02861652709543705,
								9.313225746154785e-8,
								0,
								0.028745310381054878,
								-0.10401970148086548,
								0,
								0.044453930109739304,
								-0.09014665335416794,
								0,
								0.03856631740927696,
								0.09014543890953064,
								0,
								0.03856630623340607,
								0.10402049124240875,
								0,
								0.0444539375603199,
								0.10885873436927795,
								0,
								0.03052682615816593,
								0.08446814119815826,
								0,
								0.049859337508678436,
								0.10402049124240875,
								0,
								0.0444539375603199,
								-0.10401970148086548,
								0,
								0.044453930109739304,
								-0.08446935564279556,
								0,
								0.04985934868454933,
								-0.001914601307362318,
								0,
								0.04298260807991028,
								0.0019133789464831352,
								0,
								0.04298260435461998,
								-6.05359673500061e-7,
								0,
								0.04285373166203499,
								0.003749364987015724,
								0,
								0.043357979506254196,
								-0.003750587347894907,
								0,
								0.043357983231544495,
								0.005490484181791544,
								0,
								0.043963007628917694,
								-0.005491706542670727,
								0,
								0.04396301135420799,
								0.007119891233742237,
								0,
								0.04478083550930023,
								-0.00712111359462142,
								0,
								0.04478084295988083,
								0.007119891233742237,
								0,
								0.04478083550930023,
								-0.005491706542670727,
								0,
								0.04396301135420799,
								-0.0974779725074768,
								0,
								0.057486772537231445,
								-0.08446935564279556,
								0,
								0.04985934868454933,
								0.08446814119815826,
								0,
								0.049859337508678436,
								0.09747990220785141,
								0,
								0.057486776262521744,
								0.00862072966992855,
								0,
								0.045794617384672165,
								-0.008621951565146446,
								0,
								0.04579462110996246,
								0.00862072966992855,
								0,
								0.045794617384672165,
								-0.00712111359462142,
								0,
								0.04478084295988083,
								0.009976141154766083,
								0,
								0.04698749631643295,
								-0.009977363049983978,
								0,
								0.04698750004172325,
								-0.009977363049983978,
								0,
								0.04698750004172325,
								0.011169269680976868,
								0,
								0.04834262281656265,
								0.009976141154766083,
								0,
								0.04698749631643295,
								-0.011170492507517338,
								0,
								0.04834262654185295,
								-0.011170492507517338,
								0,
								0.04834262654185295,
								0.01218327134847641,
								0,
								0.04984314367175102,
								0.011169269680976868,
								0,
								0.04834262281656265,
								-0.012184493243694305,
								0,
								0.04984315112233162,
								0.01218327134847641,
								0,
								0.04984314367175102,
								-0.012184493243694305,
								0,
								0.04984315112233162,
								0.013001274317502975,
								0,
								0.05147220939397812,
								-0.013002497144043446,
								0,
								0.05147221311926842,
								0.07743183523416519,
								0,
								0.060263536870479584,
								-0.07743304967880249,
								0,
								0.06026355177164078,
								-0.013002497144043446,
								0,
								0.05147221311926842,
								0.013606436550617218,
								0,
								0.0532129667699337,
								0.013001274317502975,
								0,
								0.05147220939397812,
								-0.013607658445835114,
								0,
								0.0532129742205143,
								0.013606436550617218,
								0,
								0.0532129667699337,
								0.013981888070702553,
								0,
								0.055048562586307526,
								-0.013983109965920448,
								0,
								0.05504857003688812,
								0.01411079429090023,
								0,
								0.05696215108036995,
								-0.01411200501024723,
								0,
								0.05696215108036995,
								0.01411079429090023,
								0,
								0.05696215108036995,
								-0.013983109965920448,
								0,
								0.05504857003688812,
								-0.013983098790049553,
								0,
								0.05887573957443237,
								-0.013983098790049553,
								0,
								0.05887573957443237,
								0.013981899246573448,
								0,
								0.058875735849142075,
								-0.0893683135509491,
								0,
								0.06949067860841751,
								0.08937139064073563,
								0,
								0.06949067860841751,
								0.013606447726488113,
								0,
								0.0607113316655159,
								0.013981899246573448,
								0,
								0.058875735849142075,
								-0.013607647269964218,
								0,
								0.0607113353908062,
								0.013606447726488113,
								0,
								0.0607113316655159,
								0.06915406882762909,
								0,
								0.06966148316860199,
								-0.0893683135509491,
								0,
								0.06949067860841751,
								-0.06915529072284698,
								0,
								0.06966149061918259,
								-0.07743304967880249,
								0,
								0.06026355177164078,
								-0.013607647269964218,
								0,
								0.0607113353908062,
								0.013001286424696445,
								0,
								0.06245208904147148,
								-0.013002485036849976,
								0,
								0.06245209276676178,
								-0.013002485036849976,
								0,
								0.06245209276676178,
								0.012183282524347305,
								0,
								0.06408114731311798,
								0.013001286424696445,
								0,
								0.06245208904147148,
								-0.01218448206782341,
								0,
								0.06408115476369858,
								-0.01218448206782341,
								0,
								0.06408115476369858,
								0.011169281788170338,
								0,
								0.06558167189359665,
								0.012183282524347305,
								0,
								0.06408114731311798,
								-0.011170480400323868,
								0,
								0.06558167934417725,
								0.011169281788170338,
								0,
								0.06558167189359665,
								0.009976152330636978,
								0,
								0.06693679839372635,
								-0.009977351874113083,
								0,
								0.06693679839372635,
								0.008620740845799446,
								0,
								0.06812967360019684,
								-0.00862194038927555,
								0,
								0.06812968850135803,
								0.00711990287527442,
								0,
								0.06914345920085907,
								-0.007121101953089237,
								0,
								0.06914346665143967,
								0.005490495823323727,
								0,
								0.0699612945318222,
								-0.005491694901138544,
								0,
								0.0699612945318222,
								-0.0798252671957016,
								0,
								0.0803309753537178,
								-0.06915529072284698,
								0,
								0.06966149061918259,
								0.07982935011386871,
								0,
								0.0803309828042984,
								0.059752386063337326,
								0,
								0.07793575525283813,
								0.07982935011386871,
								0,
								0.0803309828042984,
								0.06915406882762909,
								0,
								0.06966148316860199,
								-0.05975361168384552,
								0,
								0.07793576270341873,
								-0.005491694901138544,
								0,
								0.0699612945318222,
								0.003749376628547907,
								0,
								0.0705663189291954,
								0.005490495823323727,
								0,
								0.0699612945318222,
								-0.0037505757063627243,
								0,
								0.070566326379776,
								0.003749376628547907,
								0,
								0.0705663189291954,
								0.001913390588015318,
								0,
								0.07094170153141022,
								-0.0019145896658301353,
								0,
								0.07094170153141022,
								-6.05359673500061e-7,
								0,
								0.07107057422399521,
								0.04934433475136757,
								0,
								0.0849689170718193,
								0.059752386063337326,
								0,
								0.07793575525283813,
								-0.04934556037187576,
								0,
								0.0849689319729805,
								-0.06898338347673416,
								0,
								0.08987301588058472,
								0.06898817420005798,
								0,
								0.08987301588058472,
								0.038047462701797485,
								0,
								0.09064356982707977,
								-0.06898338347673416,
								0,
								0.08987301588058472,
								-0.038048695772886276,
								0,
								0.09064356982707977,
								-0.04934556037187576,
								0,
								0.0849689319729805,
								-0.05697720870375633,
								0,
								0.09798211604356766,
								-0.038048695772886276,
								0,
								0.09064356982707977,
								0.05698227137327194,
								0,
								0.09798212349414825,
								0.025979313999414444,
								0,
								0.09484226256608963,
								-0.025980547070503235,
								0,
								0.09484227746725082,
								0.013257434591650963,
								0,
								0.09744759649038315,
								-0.013258669525384903,
								0,
								0.09744760394096375,
								-6.05359673500061e-7,
								0,
								0.09834214299917221,
								-0.043941300362348557,
								0,
								0.10452360659837723,
								0.0439460389316082,
								0,
								0.10452362149953842,
								-0.030010199174284935,
								0,
								0.10936284065246582,
								0.030013883486390114,
								0,
								0.10936284065246582,
								-0.015318451449275017,
								0,
								0.11236511915922165,
								0.030013883486390114,
								0,
								0.10936284065246582,
								-0.030010199174284935,
								0,
								0.10936284065246582,
								0.015320198610424995,
								0,
								0.11236513406038284,
								-6.05359673500061e-7,
								0,
								0.11339583247900009,
								0.015320198610424995,
								0,
								0.11236513406038284,
								-0.015318451449275017,
								0,
								0.11236511915922165
							],
							normalized: false
						},
						normal: {
							itemSize: 3,
							type: 'Float32Array',
							array: [
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0
							],
							normalized: false
						}
					},
					index: {
						type: 'Uint16Array',
						array: [
							0,
							1,
							2,
							3,
							1,
							0,
							3,
							4,
							1,
							5,
							4,
							3,
							5,
							6,
							4,
							7,
							6,
							5,
							8,
							9,
							10,
							11,
							12,
							6,
							7,
							13,
							11,
							14,
							12,
							11,
							15,
							16,
							8,
							14,
							17,
							12,
							18,
							17,
							14,
							15,
							19,
							16,
							20,
							17,
							18,
							21,
							22,
							23,
							24,
							17,
							20,
							21,
							25,
							22,
							26,
							25,
							21,
							24,
							27,
							17,
							28,
							27,
							24,
							26,
							29,
							25,
							30,
							29,
							26,
							31,
							32,
							33,
							34,
							35,
							28,
							30,
							36,
							29,
							37,
							38,
							39,
							37,
							40,
							38,
							41,
							40,
							37,
							41,
							42,
							40,
							43,
							42,
							41,
							43,
							44,
							42,
							45,
							44,
							43,
							46,
							35,
							34,
							30,
							47,
							36,
							48,
							47,
							30,
							46,
							49,
							35,
							45,
							50,
							44,
							51,
							50,
							45,
							51,
							52,
							50,
							53,
							52,
							51,
							54,
							55,
							56,
							57,
							58,
							53,
							57,
							59,
							58,
							60,
							59,
							57,
							60,
							61,
							59,
							62,
							61,
							60,
							62,
							63,
							61,
							64,
							63,
							62,
							64,
							65,
							63,
							66,
							65,
							64,
							67,
							49,
							46,
							48,
							68,
							47,
							69,
							70,
							71,
							72,
							73,
							66,
							74,
							68,
							48,
							75,
							76,
							77,
							78,
							73,
							72,
							78,
							79,
							73,
							78,
							80,
							79,
							81,
							80,
							78,
							81,
							82,
							80,
							83,
							82,
							81,
							84,
							85,
							67,
							74,
							86,
							68,
							83,
							87,
							82,
							88,
							87,
							83,
							89,
							86,
							74,
							84,
							90,
							85,
							88,
							91,
							87,
							92,
							91,
							88,
							93,
							94,
							95,
							96,
							97,
							98,
							99,
							100,
							90,
							101,
							102,
							89,
							92,
							103,
							91,
							104,
							103,
							92,
							105,
							100,
							99,
							101,
							106,
							102,
							104,
							107,
							103,
							108,
							107,
							104,
							105,
							109,
							100,
							110,
							106,
							101,
							111,
							109,
							105,
							110,
							112,
							106,
							108,
							113,
							107,
							114,
							115,
							116,
							117,
							118,
							113,
							119,
							118,
							117,
							120,
							112,
							110,
							120,
							121,
							112,
							122,
							109,
							111,
							122,
							123,
							109,
							119,
							124,
							118,
							125,
							124,
							119,
							125,
							126,
							124,
							127,
							126,
							125,
							127,
							128,
							126,
							129,
							128,
							127,
							130,
							131,
							132,
							133,
							123,
							122,
							134,
							128,
							129,
							135,
							136,
							137,
							138,
							139,
							120,
							140,
							141,
							142,
							143,
							144,
							134,
							143,
							145,
							144,
							146,
							145,
							143,
							146,
							147,
							145,
							148,
							147,
							146,
							148,
							149,
							147,
							150,
							149,
							148,
							150,
							151,
							149,
							152,
							151,
							150,
							153,
							154,
							155,
							156,
							157,
							152,
							156,
							158,
							157,
							159,
							158,
							156,
							160,
							161,
							162,
							163,
							141,
							140,
							164,
							165,
							131,
							166,
							167,
							159,
							166,
							168,
							167,
							169,
							168,
							166,
							169,
							170,
							168,
							171,
							170,
							169,
							171,
							172,
							170,
							173,
							172,
							171,
							174,
							175,
							138,
							176,
							177,
							178,
							179,
							180,
							163,
							181,
							182,
							165,
							183,
							184,
							185,
							183,
							186,
							184,
							187,
							186,
							183,
							187,
							188,
							186,
							189,
							188,
							187,
							189,
							190,
							188,
							191,
							192,
							193,
							194,
							195,
							174,
							196,
							197,
							177,
							191,
							198,
							192,
							199,
							200,
							201,
							199,
							202,
							200,
							203,
							202,
							199,
							204,
							205,
							206,
							207,
							205,
							204,
							208,
							209,
							210,
							211,
							212,
							207,
							213,
							214,
							209,
							215,
							214,
							213,
							216,
							197,
							196,
							194,
							217,
							195,
							218,
							219,
							220,
							221,
							222,
							215,
							221,
							223,
							222,
							224,
							223,
							221,
							224,
							225,
							223,
							226,
							227,
							228,
							229,
							227,
							226,
							230,
							231,
							225,
							232,
							217,
							194,
							216,
							233,
							197,
							229,
							234,
							235,
							236,
							237,
							230,
							238,
							233,
							216,
							239,
							240,
							241,
							242,
							243,
							234,
							244,
							243,
							242,
							245,
							246,
							247,
							248,
							246,
							245,
							249,
							250,
							251,
							252,
							253,
							248,
							252,
							254,
							253,
							255,
							254,
							252,
							255,
							256,
							254,
							257,
							256,
							255,
							257,
							258,
							256,
							259,
							258,
							257,
							259,
							260,
							258,
							261,
							260,
							259,
							262,
							263,
							232,
							238,
							264,
							233,
							265,
							266,
							267,
							262,
							268,
							263,
							269,
							270,
							271,
							272,
							273,
							261,
							272,
							274,
							273,
							275,
							274,
							272,
							275,
							276,
							274,
							277,
							264,
							278,
							262,
							279,
							268,
							280,
							279,
							262,
							277,
							281,
							264,
							282,
							281,
							277,
							283,
							284,
							285,
							286,
							287,
							280,
							282,
							288,
							281,
							289,
							288,
							282,
							286,
							290,
							287,
							291,
							288,
							289,
							286,
							292,
							290,
							293,
							288,
							291,
							286,
							293,
							292,
							294,
							293,
							286,
							293,
							295,
							288,
							294,
							295,
							293,
							296,
							295,
							294,
							296,
							297,
							295,
							298,
							299,
							300,
							298,
							301,
							299,
							302,
							303,
							304
						]
					},
					boundingSphere: { center: [-6.034970283508301e-7, 0, 0.000528477132320404], radius: 0.1596354043100692 }
				}
			}
		],
		materials: [
			{
				uuid: '8768A335-C295-4A46-A397-91C10B989481',
				type: 'MeshStandardMaterial',
				color: 16777215,
				roughness: 1,
				metalness: 1,
				emissive: 0,
				depthFunc: 3,
				depthTest: true,
				depthWrite: true,
				stencilWrite: false,
				stencilWriteMask: 255,
				stencilFunc: 519,
				stencilRef: 0,
				stencilFuncMask: 255,
				stencilFail: 7680,
				stencilZFail: 7680,
				stencilZPass: 7680
			}
		],
		object: {
			uuid: '529F4FE2-3DBF-49DE-830F-DCDE02CF4F81',
			type: 'Mesh',
			name: 'Curve003',
			userData: { name: 'Curve.003' },
			layers: 1,
			matrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
			geometry: '5508698F-7B13-4076-92B0-F7D08FF91961',
			material: '8768A335-C295-4A46-A397-91C10B989481'
		}
	}
}
