import tupleSearch from './tupleSearch.js'

export default function filterElements() {
  const self = this
  // console.log('filter elements', self.props.elements)
  return self.props.elements.filter(element=>{
    return matchCheck(element, self.props.filter)
  })
}

function matchCheck(element, filters) {
  let match = true
  filters.forEach(f=>{
    const nFiltersWithThisKey = filters.filter(_o=>{ return _o.key === f.key }).length
    // does it fail to match?
    if(nFiltersWithThisKey > 1) {      
      let matchAny = false 
      filters.filter(_o=>{ return _o.key === f.key }).forEach(f=>{
        if(tupleSearch(element.attributes, f.key).value === f.value) {
          matchAny = true
        }
      })
      if(matchAny === false) {
        match = false
      }
    } else {
      if(tupleSearch(element.attributes, f.key).value !== f.value) {
        match = false
      }        
    }
  })
  return match
}