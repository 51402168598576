import React, { Component } from 'react'
import { connect } from 'ui-lib'
import InnerMetricCellRenderer from '../../../../../components/CellRenderers/InnerMetricCellRenderer'
import LastUpdatedCellRenderer from '../../../../../components/CellRenderers/LastUpdatedCellRenderer'
import Constants from '../../../../../utils/Constants.json'
import Util from '../../../../../utils/Util'
import { AgGridReact } from 'ag-grid-react'

import * as actions from '../../../store/actions'
import * as insightsActions from '../../../../../store/actions'

import iconLoading from 'ui-lib/src/images/icon-loading.svg'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import './DeviceMetricsTable.scss'

class DeviceMetricsTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			frameworkComponents: { innerMetricCellRenderer: InnerMetricCellRenderer, LastUpdatedCellRenderer },
			colDefs: [
				{
					headerName: 'Severity',
					field: 'metric_value_pct',
					width: 120,
					suppressSizeToFit: true,
					cellRenderer: (params) =>
						`<span class="inner-severity-cell"><span class="severity-icon ${params.data.severity}"></span>${
							params.data.metric in Constants.TABLE_CHART_INFO_MAP
								? ''
								: params.data.metric_value_pct === null || isNaN(params.data.metric_value_pct)
								? params.data.metric_value_raw === null || isNaN(params.data.metric_value_raw)
									? ''
									: Util.getReadableNumber(params.data.metric_value_raw, 0)
								: `${Math.round(params.data.metric_value_pct)}%`
						}</span>`
				},
				{
					headerName: 'Metric',
					field: 'metric',
					flex: 1,
					cellRenderer: 'innerMetricCellRenderer',
					cellRendererParams: { metricsMisc: props.metricsMisc }
				},
				{
					headerName: 'Last Update',
					field: 'timegenerated',
					width: 168,
					suppressSizeToFit: true,
					cellRenderer: 'LastUpdatedCellRenderer'
				}
			],
			defaultColDef: {
				resizable: false,
				suppressMenu: true
			}
		}
	}

	onCellClicked = (node) => {
		if (node.column.colDef.field === 'metric') {
			this.props.openInsightsTab(node.data, true)
		}
	}

	render() {
		const { deviceMetrics: data, deviceMetricsLoading, deviceMetricsError } = this.props

		if (deviceMetricsError) {
			return (
				<div className="device-metrics-table">
					<div className="info-area">Error Loading Data</div>
				</div>
			)
		}

		if (deviceMetricsLoading) {
			return (
				<div className="device-metrics-table">
					<div className="info-area">
						<img className="loading-spinner" src={iconLoading} alt={'loading'} />
						Loading Data
					</div>
				</div>
			)
		}
		return (
			<div className="device-metrics-table">
				{data && data.length === 0 ? null : (
					<div className={'ag-theme-balham'} style={{ height: '100%' }}>
						<AgGridReact
							scrollbarWidth={0}
							suppressMaxRenderedRowRestriction={true}
							frameworkComponents={this.state.frameworkComponents}
							columnDefs={this.state.colDefs}
							defaultColDef={this.state.defaultColDef}
							rowData={data}
							onCellClicked={this.onCellClicked}
							getRowClass={(params) => {
								if (params.node.rowIndex % 2 === 0) {
									return 'detail-even-row'
								} else {
									return 'detail-odd-row'
								}
							}}
							suppressCsvExport={true}
							suppressExcelExport={true}
							suppressDragLeaveHidesColumns={true}
						/>
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.insights, ...state.devicePage }
}

const mapDispatchToProps = { ...actions, ...insightsActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(DeviceMetricsTable)
