module.exports = {
  displayName: 'Network',
  content: {
    decryption: require('./decryption.json'),
    decryption_presentation: require('./decryption-presentation.json'),
    traffic: require('./traffic.json'),
    traffic_presentation: require('./traffic-presentation.json'),
    threat: require('./threat.json'),
    threat_presentation: require('./threat-presentation.json'),
    auth: require('./auth.json'),
    auth_presentation: require('./auth-presentation.json'),
    url: require('./url.json'),
    url_presentation: require('./url-presentation.json'),
    file_data: require('./file_data.json'),
    file_data_presentation: require('./file_data-presentation.json'),
    userid: require('./userid.json'),
    userid_presentation: require('./userid-presentation.json'),
    iptag: require('./iptag.json'),
    iptag_presentation: require('./iptag-presentation.json'),
    hipmatch: require('./hipmatch.json'),
    hipmatch_presentation: require('./hipmatch-presentation.json'),
    tunnel: require('./tunnel.json'),
    tunnel_presentation: require('./tunnel-presentation.json'),
    sctp: require('./sctp.json'),
    sctp_presentation: require('./sctp-presentation.json')
  }
}
