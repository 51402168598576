import React, { Component } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { connect } from 'ui-lib'
import { updateAlertsSchema } from '../../../../../store/actions'
import visibilityUtil from '../../../../../../util/Util'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-enterprise'

class MasterDetailGridWidget extends Component {
	constructor(props) {
		super(props)
		this.state = {
			movingColumn: null
		}

		this.setPreference = this.setPreference.bind(this)
	}

	setPreference = (presentation) => {
		this.props.dispatch(updateAlertsSchema(presentation))
	}

	sizeColumnsToFit(params) {
		params.api.sizeColumnsToFit()
	}

	onColumnPinned = (params) => {
		this.props.onColumnPinned()
		params.columns.forEach((column) => {
			const { colDef, pinned } = column
			// console.log("Pin change", column);
			let presentation = {}
			_.set(presentation, `${this.props.logType}.fields[0].name`, colDef.field)
			_.set(presentation, `${this.props.logType}.fields[0].pinned`, pinned)
			this.setPreference(presentation)
		})
	}

	onColumnVisible = (params) => {
		params.columns.forEach((column) => {
			// console.log("Visible change", column);
			const { visible, colDef } = column
			let presentation = {}
			_.set(presentation, `${this.props.logType}.fields[0].name`, colDef.field)
			_.set(presentation, `${this.props.logType}.fields[0].hidden`, !visible)
			this.setPreference(presentation)
		})
		this.sizeColumnsToFit(params)
	}

	onColumnMoved = (params) => {
		// This is for reset
		if (!this.state.movingColumn) {
			const columns = visibilityUtil.normalizeFields(params.api.columnController.gridColumns)
			let presentation = {}
			for (let i = 1; i < columns.length; i++) {
				_.set(presentation, `${this.props.logType}.field.name`, columns[i].colDef.field)
				_.set(presentation, `${this.props.logType}.moveAfter.name`, columns[i - 1].colDef.field)
				this.setPreference(presentation)
			}
		}
	}

	onDragStarted = (params) => {
		// console.log("drag start");
		const columns = visibilityUtil.normalizeFields(params.api.columnController.gridColumns)
		const movingColumn = columns.find((col) => col.moving)
		if (movingColumn) {
			this.setState({ movingColumn })
		}
	}

	onDragStopped = (params) => {
		// console.log("drag stop");
		let { movingColumn } = this.state
		if (movingColumn) {
			const columns = visibilityUtil.normalizeFields(params.api.columnController.gridColumns)
			const movColStopIndex = columns.findIndex((col) => col.colDef.field === movingColumn.colDef.field)

			let presentation = {}
			_.set(presentation, `${this.props.logType}.field.name`, movingColumn.colDef.field)
			// If column is moved to the beginning of all, use moveBefore, otherwise use moveAfter
			if (movColStopIndex === 1) {
				_.set(presentation, `${this.props.logType}.moveBefore.name`, columns[movColStopIndex + 1].colDef.field)
			} else {
				_.set(presentation, `${this.props.logType}.moveAfter.name`, columns[movColStopIndex - 1].colDef.field)
			}
			this.setPreference(presentation)
			this.setState({ movingColumn: null })
		}
	}

	render() {
		return (
			<div className={'master-detail-grid ag-theme-balham ' + (this.props.className || '')} style={{ height: '100%' }}>
				<AgGridReact
					{...this.props}
					masterDetail={true}
					onColumnResized={this.onColumnResized}
					onColumnPinned={this.onColumnPinned}
					onDragStarted={this.onDragStarted}
					onDragStopped={this.onDragStopped}
					onColumnVisible={this.onColumnVisible}
					onColumnMoved={this.onColumnMoved}
					suppressDragLeaveHidesColumns={true}
					suppressPropertyNamesCheck={true}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(mapStateToProps)(MasterDetailGridWidget)
