import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import memoize from 'micro-memoize'
import LogQueryInput from './LogQueryInput'
import { getPopupContainer } from './util'
import { isStringTypes, isIntTypes, TYPE_OP_MAPPING, quotes, QueryExpression } from './util'
import { LogSchema } from './util'
import Util from '../../../../../utils/Util'
import { RecycleCompleteWidget as AutoComplete, PopoverWidget as Popover } from 'ui-lib'
import './LogQueryCompletion.scss'
import iconFail from '../../../../../../images/icon-query-fail.svg'
import iconPass from '../../../../../../images/icon-query-pass.svg'
import iconClearQuery from '../../../../../../images/icon-clear-query.svg'

const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'

export default class LogQueryCompletion extends Component {
	state = {
		dataSource: [],
		errors: [],
		warnings: [],
		selectFirstOption: false
	}

	componentDidUpdate(prevProps) {
		let refreshSearch = false
		if (prevProps.fields !== this.props.fields || prevProps.valueSuggestions != this.props.valueSuggestions) {
			// fields changed
			// clear cache
			this._lastSearchKey = undefined
			this.parseQuery.cache.keys.length = 0
			this.parseQuery.cache.values.length = 0
			// re-parse
			refreshSearch = true
		}
		if (prevProps.value !== this.props.value) {
			// query changed
			refreshSearch = true
		}

		if (refreshSearch) {
			this.onValueCompletion.cache.keys.length = 0
			this.onValueCompletion.cache.values.length = 0
			this.onSearch()
		}
	}

	componentWillUnmount() {
		this._lastSearchKey = undefined
		this.parseQuery.cache.keys.length = 0
		this.parseQuery.cache.values.length = 0
		// this.parseQuery.cache = undefined // this will throw execption
		this.parseQuery = undefined
		this.onValueCompletion.cache.keys.length = 0
		this.onValueCompletion.cache.values.length = 0
		this.onValueCompletion = undefined
	}

	onSelect = (value, opt) => {
		if (!this.inputRef) {
			return
		}
		const cursor = +_.get(opt, 'props.data-cursor', -1)
		const { input } = this.inputRef
		this.setState({ value }, () => {
			if (cursor >= 0) {
				input.selectionStart = cursor
				input.selectionEnd = cursor
			}
			if (input.selectionStart >= value.length) {
				this.handleSearch(value, input.selectionStart).then(this.openSuggestion, _.noop)
			}
		})
		// console.info('on select', value, cursor)
	}
	renderOptions = (options, valueOptionsFor = null, current) => {
		let Options = []
		if (_.isEmpty(options)) {
			Options.push({ itemKey: '-', itemValue: '-', itemContent: <>(no suggestion)</>, itemDisabled: true })
		} else {
			// Keep severity fields order
			if (valueOptionsFor !== 'severity') options.sort((a, b) => (a.displayName > b.displayName || a.name === '!=' ? 1 : -1))
			Options = options.map((option) => {
				if (option && option.name != null) {
					const { name, displayName, value = name, cursor = -1, disabled, sliced } = option
					const splitName = name.toLowerCase().includes(sliced) ? name.split(new RegExp(`(${sliced})`, 'i')) : null
					const splitDisplayName = displayName.toLowerCase().includes(sliced) ? displayName.split(new RegExp(`(${sliced})`, 'i')) : null

					if (['=', '!='].includes(name)) {
						return {
							itemKey: `${name}`,
							itemValue: `${value}`,
							itemContent: (
								<>
									<span className="log-query-option-value">
										{!splitName ? name : <>{splitName.map((slice, i) => (slice.toLowerCase() === sliced ? <strong key={i}>{slice}</strong> : slice))}</>}
									</span>
									{displayName && (
										<span className="log-query-option-desc">
											{!splitDisplayName ? displayName : <>{splitDisplayName.map((slice, i) => (slice.toLowerCase() === sliced ? <strong key={i}>{slice}</strong> : slice))}</>}
										</span>
									)}
								</>
							),
							itemDisabled: false,
							itemCursor: cursor
						}
					}
					return {
						itemKey: `${name}`,
						itemValue: `${value}`,
						itemContent: (
							<>
								{displayName && (
									<span className="log-query-option-value">
										{!splitDisplayName ? displayName : <>{splitDisplayName.map((slice, i) => (slice.toLowerCase() === sliced ? <strong key={i}>{slice}</strong> : slice))}</>}
									</span>
								)}
								<span className="log-query-option-desc">
									{!splitName ? name : <>{splitName.map((slice, i) => (slice.toLowerCase() === sliced ? <strong key={i}>{slice}</strong> : slice))}</>}
								</span>
							</>
						),
						itemDisabled: false,
						itemCursor: cursor
					}
				}
				return <div key={option}>{option}</div>
			})
		}
		// TODO: Logic needs to change according to initial limit.
		if ((!current || (current && current.kind === 'value')) && valueOptionsFor && this.props.valueSuggestions[valueOptionsFor] && !this.props.valueSuggestions[valueOptionsFor].nomore) {
			Options.push({
				itemKey: 'more',
				itemValue: 'more',
				itemContent: (
					<>
						<div
							className="more-options"
							onClick={(e) => {
								e.stopPropagation()
								// Fetch data and set data
								this.setState({
									dataSource: [
										{
											itemKey: '-',
											itemValue: '-',
											itemContent: (
												<>
													<div className="more-options">Loading ...</div>
												</>
											),
											itemDisabled: true
										}
									]
								})
								if (this.props.loadMore) this.props.loadMore(valueOptionsFor)
							}}
						>
							More ...
						</div>
					</>
				)
			})
		}
		return Options
	}
	// per instance, clear cache when fields change
	parseQuery = memoize(
		(query) =>
			QueryExpression.parse(
				query,
				this.props.fields,
				{
					supportNot: this.props.supportNot,
					supportParen: this.props.supportParen
				},
				this.props.commonFilters
			),
		{ maxSize: 30, isEqual: (a, b) => a === b }
	)
	// per instance, clear cache when fields or value change
	onValueCompletion = memoize(
		(arg) => {
			// let promise = _.attempt(() => this.props.onValueCompletion(arg))
			let suggestions = _.get(this.props.valueSuggestions[arg.field.name], 'suggestions', [])
			if (arg.field.name === 'metric') {
				suggestions = suggestions.map((i) => ({ name: i, displayName: Util.getMetricDisplayName(this.props.metricsMisc, i) }))
			}
			let promise = new Promise((res) => setTimeout(() => res(suggestions ? suggestions : []), 0))
			if (promise == null || _.isError(promise)) {
				return Promise.reject(promise || new Error('skip'))
			}
			if (promise && !Array.isArray(promise) && _.isFunction(promise.then)) {
				promise = promise.then(
					(result) => {
						if (promise == null) {
							throw new Error('no result')
						}
						if (result && Array.isArray(result) && arg.field && (arg.field.enum || isStringTypes(arg.field.type))) {
							const quoted = "'"
							result = result.map((r) => (_.isString(r) ? { name: r, quoted } : { quoted, ...r }))
							return result
						}
						return result
					},
					(err) => {
						console.error('value completion exception', err)
						throw err // will unset the cache
					}
				)
			}
			return promise
		},
		{ maxSize: 3, isPromise: true, isEqual: (a, b) => a === b, transformKey: ([a]) => `${(a.field && a.field.name) || ''}\n${a.op || ''}` }
	)
	handleSearch = async (value, cursor) => {
		let dataSource = []
		const END = '(end)'
		const key = `${cursor || 0}|${value || ''}`.trim()
		const { logType, disabled, maxLength, supportNot } = this.props
		if (value.length > maxLength) {
			return new Promise((resolve) => {
				this.setState({ dataSource, errors: [new Error('query is too long')] }, resolve)
			})
		}
		//! hardcode time format for tms here TODO: need to remove after we switch
		const timeFormat = logType.startsWith('tms') ? ISO_FORMAT : this.props.timeFormat
		// prevent duplicated call due to throttle
		if (disabled || this._lastSearchKey === key) {
			return
		}
		this._lastSearchKey = key

		const fields = _.filter(this.props.fields || [], (f) => f.filterable)
		if (!fields.length) {
			return new Promise((r) =>
				this.setState(
					{
						dataSource: this.renderOptions(),
						errors: value.trim() ? ['no schema fields'] : []
					},
					r
				)
			)
		}
		const fieldsWithNot = supportNot ? [...fields, 'NOT'] : [...fields]

		const queryExp = this.parseQuery(value)
		const EMPTY_STR = { name: "''", displayName: '(string)', moveCursor: 1 }
		const genEmptyTimestamp = (format = timeFormat) => ({ name: "''", displayName: `(${format.replace(/[[\]]/g, '')})`, moveCursor: 1 })
		if (!queryExp.isEmpty()) {
			const idx = queryExp.tokens.findIndex((t) => cursor >= t.from && cursor <= t.to)
			let current = queryExp.tokens[idx]
			if (current && current.kind === 'paren') {
				if (current.to === cursor && current.value === '(') {
					current = queryExp.tokens[idx + 1]
				} else {
					current = undefined
				}
			}
			const lastToken = _.last(queryExp.tokens)
			const getOperators = (tokenOrExp) => {
				return _.get(tokenOrExp, 'field.operators', TYPE_OP_MAPPING[''])
			}
			const filterSuggestions = (suggestions, sortLimit = 0) => {
				if (!suggestions || cursor <= current.from || (current.kind === 'value' && current.value === '')) {
					return suggestions
				}
				// let matched = current.kind === 'value' ? cursor - current.from + 1 : cursor - current.from
				let matched = cursor - current.from
				if (!(matched > 0) || !current.value || current.value.slice(0, matched) === "''") {
					return suggestions
				}
				if (current.quoted || /^['"`]/.test(current.value)) {
					matched -= 1
				}
				if (matched <= 0) {
					return suggestions
				}
				const sliced = current.value.slice(0, matched).toLowerCase()

				const filterFn = (f) => {
					return (f.name || f).toLowerCase().includes(sliced) || (f.displayName && f.displayName.toLowerCase().includes(sliced))
				}
				let matchedFn
				if (matched) {
					matchedFn = (s) => ({ ...(s.name ? s : { name: s }), matched, sliced })
				} else {
					matchedFn = (s) => s
				}
				if (!sortLimit || suggestions.length > sortLimit) {
					const result = suggestions.filter(filterFn).map(matchedFn)
					return result
				}
				const { true: matchedGroup, false: unmatchedGroup = [] } = _.groupBy(suggestions, filterFn)
				return matchedGroup ? [...matchedGroup.map(matchedFn), ...unmatchedGroup] : suggestions
			}
			const showLoading = () =>
				new Promise((r) =>
					this.setState(
						{
							dataSource: [{ itemKey: '-', itemValue: '-', itemContent: <></>, itemDisabled: true }]
						},
						r
					)
				)
			const isPartOfAndOr = () => !current.kind && current.value && 'AN\x00O'.includes(current.value.toUpperCase())
			const formatTimestamp = (ts, format = timeFormat) => {
				let date = moment.isMoment(ts) ? ts : moment(ts, format)
				if (!date.isValid()) {
					// try parse
					date = moment(new Date(ts))
					if (!date.isValid()) {
						return String(ts)
					}
				}
				if (/\[(?:Z|\+00:?00)\]$/.test(format)) {
					if (_.isString(ts) && /\.\d{4,9}[+Z]/.test(ts)) {
						return ts // keep original string value, so the details won't lost
					}
					date = date.utc()
				}
				return date.format(format)
			}

			let suggestions = []
			let valueOptionsFor = null
			if (current) {
				valueOptionsFor = current.field && current.field.name
				// console.log('found', current)
				// dataSource.unshift(`${current.kind}: ${current.value}`)
				if (current.kind === 'key') {
					suggestions = filterSuggestions(fieldsWithNot)
				} else if (current.kind === 'op') {
					const operators = getOperators(current)
					suggestions = filterSuggestions(operators, 3)
				} else if (current.kind === 'not' && supportNot) {
					suggestions = ['NOT']
				} else if (current.kind === 'value') {
					const field = current.field
					if (field.filterable) {
						let result = this.onValueCompletion({ field, op: current.exp.op }).catch(_.noop)
						if (result != null) {
							if (result && !Array.isArray(result) && result.then) {
								await showLoading()
								result = await result
							}
							if (result && Array.isArray(result)) {
								suggestions = filterSuggestions(result)
								suggestions._done = true
							}
						}
					}
					const enums = LogSchema.getFieldEnum(field)
					const { type } = field || {}
					if (!suggestions._done) {
						// Following logic should be avoided for now, since we only have string type
						if (enums) {
							suggestions = filterSuggestions(enums, 3)
						} else if (isStringTypes(type)) {
							if (current.exp.op.toUpperCase() === 'LIKE') {
								suggestions = [{ name: quotes(current.value), displayName: '(string)' }]
								// disable since ip cannot support arbitrary *
								// if (!current.value.includes('%')) {
								//   suggestions.push({ name: quotes(`${current.value}%`), displayName: '(string)' })
								// }
							} else {
								suggestions = [{ name: quotes(current.value), displayName: '(string)' }]
							}
							if (current.value) {
								suggestions.push(EMPTY_STR)
							}
						} else if (isIntTypes(type)) {
							let int = +current.value || 0
							if (type.startsWith('u') && int < 0) {
								int = 0
							}
							suggestions = [{ name: String(int), displayName: `(${type})` }]
						} else if (type === 'boolean') {
							suggestions = filterSuggestions(
								[
									{ name: 'true', displayName: `(${type})` },
									{ name: 'false', displayName: `(${type})` }
								],
								2
							)
						} else if (type === 'timestamp') {
							const timestamp = current.value >= 0 ? +current.value * 1000 : (current.value || '').trim()
							const now = moment()
							if (!timestamp) {
								suggestions.push({ name: quotes(formatTimestamp(now, field.format)), displayName: type })
							}
							const date = moment(new Date(timestamp))
							if (+date > 0 && +date !== +now) {
								suggestions.unshift({ name: quotes(formatTimestamp(timestamp, field.format)), displayName: type })
								suggestions.push(genEmptyTimestamp(field.format))
							} else {
								suggestions.unshift(genEmptyTimestamp(field.format))
							}
						} else {
							const name = current.quoted ? quotes(current.value) : current.value || ' '
							suggestions = [{ name, displayName: `(${type})`, disabled: true }]
						}
					}
				} else if (current.kind === 'andor' || isPartOfAndOr()) {
					// could be part of and/or
					suggestions = filterSuggestions(['AND', 'OR'], 2)
				}
				const appendSpace = lastToken === current ? ' ' : ''
				suggestions = suggestions.map((s) => {
					let name = (_.isString(s) ? s : String(s.name)).trim()
					let matched = s.matched
					let sliced = s.sliced
					if (s.quoted) {
						name = quotes(name)
						matched += 1
					}
					const pre = (value.slice(0, current.from) + name).trim()
					const full = `${pre} ${value.slice(current.to).trim()}`
					const newValue = s.disabled ? '\n' : `${full.trim()}${appendSpace}`
					const cursor = s.moveCursor > 0 ? current.from + s.moveCursor : s.moveCursor < 0 ? current.to - s.moveCursor : appendSpace ? newValue.length : pre.length
					let displayName = s.displayName ? s.displayName : (_.isString(s) ? s : String(s.name)).trim()
					if (['=', '!='].includes(displayName)) {
						displayName = displayName === '=' ? 'Equals' : 'Does not equal'
					}
					return {
						displayName,
						name,
						matched,
						sliced,
						cursor,
						value: newValue,
						disabled: s.disabled
					}
				})
			} else if (cursor >= _.get(lastToken, 'to', value.length)) {
				if (lastToken.value === '(') {
					suggestions = fieldsWithNot
				} else {
					let q = queryExp.tree
					while (q && q.args) {
						if (q.op === END) {
							suggestions = getOperators(q)
							break
						}
						const last = _.last(q.args)
						if (last == null) {
							suggestions = lastToken.kind === 'not' ? fields : fieldsWithNot
							break
						} else if (last === END) {
							const field = q.field
							if (field.filterable) {
								valueOptionsFor = field.name
								let result = this.onValueCompletion({ field, op: q.op /*, suggestions */ }).catch(_.noop)
								if (result != null) {
									if (result && !Array.isArray(result) && result.then) {
										await showLoading()
										result = await result
									}
									if (result && Array.isArray(result)) {
										suggestions = result
										result._done = true
									}
								}
							}
							const enums = LogSchema.getFieldEnum(field)
							const { type } = field || {}
							if (suggestions._done) {
								// skip
							} else if (enums) {
								suggestions = enums
							} else if (isStringTypes(type)) {
								if (q.op.toUpperCase() === 'LIKE') {
									suggestions = [
										// disable for now, since ip does not support more than 1 star
										// { name: '\'%%\'', displayName: '(string)', moveCursor: 2 },
										{ name: "'%'", displayName: '(string)', moveCursor: 1 },
										EMPTY_STR
									]
								} else {
									suggestions = [EMPTY_STR]
								}
							} else if (isIntTypes(type)) {
								suggestions = [{ name: '0', displayName: `(${type})` }]
							} else if (type === 'timestamp') {
								suggestions = [genEmptyTimestamp(field.format), { name: quotes(formatTimestamp(moment(), field.format)), displayName: type }]
							} else if (type === 'boolean') {
								suggestions = [
									{ name: 'true', displayName: `(${type})` },
									{ name: 'false', displayName: `(${type})` }
								]
							} else {
								suggestions = [{ name: ' ', displayName: `(${type})`, disabled: true }]
							}
							break
						} else {
							q = last
						}
					}
					if (!q || !q.args) {
						suggestions = ['AND', 'OR'] // append: and/or
					}
				}
				suggestions = suggestions.map((s) => {
					let name = (_.isString(s) ? s : String(s.name)).trim()
					let matched = s.matched
					let sliced = s.sliced
					if (s.quoted) {
						name = quotes(name)
						matched += 1
					}
					const newValue = s.disabled ? '\n' : `${value.trim()} ${name} `
					const cursor = s.moveCursor > 0 ? value.length + s.moveCursor : s.moveCursor < 0 ? newValue.length - s.moveCursor - 1 : -1
					let displayName = s.displayName ? s.displayName : (_.isString(s) ? s : String(s.name)).trim()
					if (['=', '!='].includes(displayName)) {
						displayName = displayName === '=' ? 'Equals' : 'Does not equal'
					}
					return {
						displayName,
						name,
						value: newValue,
						matched,
						sliced,
						disabled: s.disabled,
						cursor
					}
				})
			}
			dataSource = suggestions ? this.renderOptions(suggestions, valueOptionsFor, current) : []
			// else if (!cursor || cursor < _.get(tokens, '0.from', 1)) {
			//   dataSource.push('key: (prepend key)')
			// }
			// else {
			//   console.log('space')
			// }
			// dataSource = _.uniq(dataSource)
		} else {
			// dataSource.push('key: (new key)')
			// console.error(fields)
			const suggestions = fields.map((f) => ({ ...f, value: `${f.name} ` }))
			dataSource = this.renderOptions(supportNot ? [...suggestions, 'NOT'] : suggestions)
		}
		// console.info(value, cursor, dataSource)

		return new Promise((resolve, reject) => {
			try {
				this.setState({ dataSource, errors: queryExp.errors, warnings: queryExp.warnings }, resolve)
				this.props.onQueryParsed(value, queryExp)
			} catch (e) {
				reject(e)
			}
		})
	}
	refreshSearch = () => {
		if (this.inputRef) {
			const { input } = this.inputRef
			this.handleSearch(input.value, input.selectionStart).catch(_.noop)
		}
	}
	onSearch = _.throttle(this.refreshSearch, 500, { tail: true, leading: true })

	onPressTab = _.debounce(() => {
		if (this.inputRef) {
			this.setState({ selectFirstOption: true }, () => {
				this.setState(null, () => {
					// wait for next cycle
					this.inputRef.pressEnter()
					this.setState({ selectFirstOption: false })
				})
			})
		}
	}, 200) // need a little delay, or the previous first option will be selected

	handleCursorMayChange = () => {
		if (this.inputRef) {
			const { input } = this.inputRef
			const last = +input.dataset.selectionStart
			if (last !== +input.selectionStart) {
				// console.info('cursor move', last, input.selectionStart)
				input.dataset.selectionStart = input.selectionStart
				this.onSearch()
			}
		}
	}

	openSuggestion = () => {
		if (!this.props.disabled) {
			_.invoke(this.autoCompleteRef, 'select.rcSelect.openIfHasChildren')
		}
	}

	saveRefAndOverride = (ref) => {
		this.autoCompleteRef = ref
		this.rcSelect = undefined
		const rcSelect = ref && ref.select && ref.select.rcSelect
		if (rcSelect && !rcSelect._patched) {
			this.rcSelect = rcSelect
			const { onInputKeyDown } = rcSelect
			// override press enter to trigger search if no active suggestion
			if (onInputKeyDown) {
				rcSelect.onInputKeyDown = (e) => {
					if (e.key === 'Enter' && !this.isOpenAndHasActiveSuggestion()) {
						e.preventDefault()
						rcSelect.setOpenState(false)
						return this.props.onQueryPressEnter(this.getValue(), e)
					}
					return onInputKeyDown(e)
				}
			}
			rcSelect._patched = true
		}
	}

	onClear = (e) => {
		const query = this.inputRef.getValue()
		if (query) {
			this.props.onQueryChange('', e)
			this.props.onQueryPressEnter('', e)
			this.handleSearch('', 0)
		}
	}

	isOpen() {
		const { rcSelect } = this
		return Boolean(rcSelect && rcSelect.getRealOpenState(rcSelect.state))
	}

	isOpenAndHasActiveSuggestion() {
		const { rcSelect } = this
		if (rcSelect && rcSelect.getRealOpenState(rcSelect.state) && rcSelect.selectTriggerRef) {
			const menu = rcSelect.selectTriggerRef.getInnerMenu()
			const state = menu && menu.store && menu.store.getState()
			const hasActiveItem =
				state &&
				_.some(state.activeKey, (v, k) => {
					if (k !== 'undefined') {
						return Boolean(v)
					}
					return false
				})
			return Boolean(hasActiveItem)
		}
		return false
	}

	focus() {
		if (this.autoCompleteRef) {
			this.autoCompleteRef.focus()
		}
	}
	blur() {
		if (this.autoCompleteRef) {
			this.autoCompleteRef.blur()
		}
	}

	getValue() {
		return this.inputRef && this.inputRef.getValue().trim()
	}

	renderError(errors) {
		const content = (
			<Popover prefixCls="filter-popover" content={errors[0].message} trigger="hover" placement="topLeft" align={{ offset: [-8, -4] }}>
				<img className="log-query-icon" alt="close" src={iconFail} />
			</Popover>
		)
		return errors && errors.length > 0 && content
	}

	renderWarnings(warnings) {
		const content = (
			<Popover prefixCls="filter-popover" content={errors[0].message} trigger="hover" placement="topLeft" align={{ offset: [-8, -4] }}>
				<img className="log-query-icon" alt="warning" src={iconFail} />
			</Popover>
		)
		return warnings && warnings.length > 0 && content
	}

	render() {
		const { className, fields, onQueryChange, onQueryPressEnter, value, maxLength, ...props } = this.props
		const { errors, warnings, dataSource, selectFirstOption } = this.state
		const statusIcon =
			!value || !value.trim() ? (
				<i className={'log-query-icon panicon'}></i>
			) : _.isEmpty(errors) ? (
				_.isEmpty(warnings) ? (
					<img className="log-query-icon" alt="check" src={iconPass} />
				) : (
					this.renderWarnings(warnings)
				)
			) : (
				this.renderError(errors)
			)
		return (
			<div className="log-query-completion-wrapper">
				<AutoComplete
					ref={this.saveRefAndOverride}
					menuHeight={300}
					menuItemHeight={30}
					className={classNames(className, 'log-query-completion', !value && 'log-query-empty')}
					dropdownClassName="log-query-completion-dropdown"
					dropdownMatchSelectWidth={false}
					dataSource={dataSource}
					placeholder="Query"
					value={value}
					allowClear={false}
					defaultOpen={false}
					defaultActiveFirstOption={selectFirstOption}
					optionLabelProp="value"
					onSelect={this.onSelect}
					onSearch={this.onSearch}
					onFocus={this.openSuggestion}
					onChange={(query) => {
						onQueryChange(query)
					}}
					getPopupContainer={getPopupContainer}
					{...props}
				>
					<LogQueryInput
						ref={(ref) => (this.inputRef = ref)}
						prefix={statusIcon}
						onClick={this.handleCursorMayChange}
						onKeyUp={this.handleCursorMayChange}
						onFocus={this.handleCursorMayChange}
						onPressTab={this.onPressTab}
						onQueryPressEnter={onQueryPressEnter}
						maxLength={maxLength + 100}
					/>
				</AutoComplete>
				<div className="log-query-btn-wrap">
					<img src={iconClearQuery} className="log-query-btn-clear" onClick={this.onClear} />
				</div>
			</div>
		)
	}

	static getFieldsFromSchema(schema, logType) {
		return LogSchema.from(schema).getFields(logType)
	}
}

LogQueryCompletion.propTypes = {
	commonFilters: PropTypes.array,
	fields: PropTypes.array.isRequired,
	className: PropTypes.string,
	logType: PropTypes.string,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	supportNot: PropTypes.bool,
	supportParen: PropTypes.bool,
	maxLength: PropTypes.number,
	timeFormat: PropTypes.string,
	onQueryChange: PropTypes.func,
	onQueryPressEnter: PropTypes.func,
	onQueryParsed: PropTypes.func,
	onValueCompletion: PropTypes.func
}
LogQueryCompletion.defaultProps = {
	commonFilters: [],
	timeFormat: 'MM/DD/YYYY HH:mm:ss',
	maxLength: 2048,
	supportNot: true,
	supportParen: true,
	onQueryChange: _.noop,
	onQueryPressEnter: _.noop,
	onQueryParsed: _.noop,
	onValueCompletion: _.noop
}
