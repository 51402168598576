import React from 'react'
import { Dropdown } from 'antd'
import Overlay from './DropdownOverlay'

import iconArrowDown from '../../../../images/icon-arrow-down.svg'
import './StatusCellRenderer.scss'

const items = {
	open: 'Open',
	acknowledged: 'Acknowledged',
	actionTaken: 'Action Taken'
}

export default class StatusCellRenderer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isDropdownOpen: false,
			status: props.data.status
		}
	}

	onVisibleChange = (visible) => {
		if (!visible) this.setState({ isDropdownOpen: false })
	}

	render() {
		const { data, popupContainer } = this.props
		if (data.row_desc === 'show_closed') return <div />
		if (data.status === 'closed') return <div className="status-cell">Closed</div>
		const getContainer = popupContainer === 'body' ? () => document.body : (triggerNode) => triggerNode.closest(`[class$=cols-container]`)
		return (
			<div className="status-cell">
				<Dropdown
					onVisibleChange={this.onVisibleChange}
					prefixCls="status-dropdown ant-dropdown"
					getPopupContainer={getContainer}
					overlay={
						<Overlay
							setStatus={(status) => {
								this.props.updateAlert(this.props.data.alert_id, status, this.props.assignee)
								this.setState({ status })
							}}
							activeItem={this.state.status}
							hideDropdown={() => this.setState({ isDropdownOpen: false })}
						/>
					}
					visible={this.state.isDropdownOpen}
					trigger="click"
				>
					<div
						onClick={() => {
							this.setState({ isDropdownOpen: !this.state.isDropdownOpen })
						}}
					>
						<span className="status">{items[this.state.status]}</span>
						<img className={`arrow-icon ${this.state.isDropdownOpen ? 'up' : 'down'}`} src={iconArrowDown} alt="expand-icon" />
					</div>
				</Dropdown>
			</div>
		)
	}
}
