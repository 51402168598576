export const INIT_ALERTS = 'INIT_ALERTS'

export const RESET_STORE = 'RESET_STORE'

export const INIT_ALERTS_TABS_STATE = 'INIT_ALERTS_TABS_STATE'
export const UPDATE_ALERTS_TABS_STATE = 'UPDATE_ALERTS_TABS_STATE'
export const ALERTS_INIT_TASK_COMPLETE = 'ALERTS_INIT_TASK_COMPLETE'

export const INIT_ALERTS_SCHEMA = 'INIT_ALERTS_SCHEMA'
export const UPDATE_ALERTS_SCHEMA = 'UPDATE_ALERTS_SCHEMA'
export const SET_ALERTS_SCHEMA = 'SET_ALERTS_SCHEMA'
export const RESET_ALERTS_SCHEMA = 'RESET_ALERTS_SCHEMA'

export const POLL_ALERTS_JOB_REQUEST = 'POLL_ALERTS_JOB_REQUEST'
export const POLL_ALERTS_JOB_FAILURE = 'POLL_ALERTS_JOB_FAILURE'

export const OPEN_ALERTS_TAB = 'OPEN_ALERTS_TAB'
export const SWITCH_ALERTS_TAB = 'SWITCH_ALERTS_TAB'
export const CLOSE_ALERTS_TAB = 'CLOSE_ALERTS_TAB'
export const CACHE_ALERTS_TAB = 'CACHE_ALERTS_TAB'
export const CLEAR_ALERTS_DATA_CACHE = 'CLEAR_ALERTS_DATA_CACHE'
export const REFRESH_ALERTS_TAB = 'REFRESH_ALERTS_TAB'
export const UPDATE_ALERTS_ACTIVE_TAB = 'UPDATE_ALERTS_ACTIVE_TAB'

export const UPDATE_ALERTS_LAST_REFRESH = 'UPDATE_ALERTS_LAST_REFRESH'
export const UPDATE_ALERTS_STALE_STATUS = 'UPDATE_ALERTS_STALE_STATUS'

export const SET_ALERTS_LAST_UPDATE = 'SET_ALERTS_LAST_UPDATE'