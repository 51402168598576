import * as PropTypes from "prop-types";
import { Component } from "react";

export default class Option extends Component {
	static propTypes = {
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	};

	static isSelectOption = true;
}
