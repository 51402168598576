import React from 'react'
import { DrawerWidget as Drawer } from 'ui-lib'
import DetailsDrawer from './DetailsDrawer'
import RelatedLogsDrawer from './RelatedLogsDrawer'
import Constants from '../../utils/Constants.json'
import './LogDetailPanel.scss'
export default class LogDetailPanel extends React.Component {
	render() {
		const { onCloseLogDetailPanel, visible, selectedRow, logType } = this.props
		const [vendor, type] = logType.split('.')

		// Only show details in drawer if it's not one of relate logs types
		let drawerContent
		// render empty div placeholder if no row selected
		if (!selectedRow) {
			drawerContent = <div />
		} else if (Constants.RELATED_LOGS_TYPES.includes(type)) {
			drawerContent = <RelatedLogsDrawer {...this.props} />
		} else {
			drawerContent = <DetailsDrawer {...this.props} />
		}

		return (
			<Drawer className="detail-panel" getContainer={'body'} title="Log Details" mask={true} placement="right" closable={true} onClose={onCloseLogDetailPanel} visible={visible} width={'850'}>
				{drawerContent}
			</Drawer>
		)
	}
}
