import React from 'react'
import moment from 'moment'
import { Route } from 'react-router-dom'

import iconCritical from '../../../../images/icon-critical.svg'
import iconWarning from '../../../../images/icon-warning.svg'
import iconHealty from '../../../../images/icon-healthy.svg'
import iconComment from '../../../../images/icon-comment.svg'
import iconModify from '../../../../images/icon-modify.svg'
import iconDelete from '../../../../images/icon-delete.svg'
import iconAuthor from '../../../../images/icon-author.svg'
import iconAlert from '../../../../images/icon-alert.svg'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'

import './DetailCellRenderer.scss'
import Util from '../../../utils/Util'

const iconMap = {
	critical: iconCritical,
	warning: iconWarning,
	healthy: iconHealty,
	alert_assignee_changed: iconAuthor,
	alert_comment_added: iconAuthor,
	alert_comment_deleted: iconAuthor,
	alert_comment_updated: iconAuthor,
	alert_created: iconAlert,
	alert_event_added: iconAlert,
	alert_severity_changed: iconAlert,
	alert_status_changed: iconAuthor,
	alert_email_sent: iconAlert,
	alert_servicenow_ticket_created: iconAuthor,
	alert_servicenow_ticket_updated: iconAuthor
}

export default class DetailCellRenderer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			rowHeight: -1
		}
		this.detailCellRef = React.createRef()
	}

	componentDidMount() {
		this.setState({ rowHeight: this.props.node.rowHeight })
	}

	componentDidUpdate(prevProps) {
		const detailCell = this.detailCellRef.current
		if (detailCell && detailCell.clientHeight !== this.state.rowHeight) {
			this.props.api.resetRowHeights()
		}
	}
	renderActivity = (activity) => {
		const payload = JSON.parse(activity.payload)
		const activityDetail = {
			alert_email_sent: 'Email sent',
			alert_servicenow_ticket_created: 'Service Now Ticket Created',
			alert_servicenow_ticket_updated: 'Service Now Ticket Updated',
			alert_assignee_changed: `Assignee Changed to ${_.capitalize(payload.new_assignee)}`,
			alert_comment_added: 'Comment Added',
			alert_comment_deleted: 'Comment Deleted',
			alert_comment_updated: 'Comment Updated',
			alert_created: 'Alert Created',
			alert_event_added: `Alert Event Added`,
			alert_severity_changed: `Alert Severity Changed From ${_.capitalize(payload.old_severity)} to ${_.capitalize(payload.new_severity)}`,
			alert_status_changed: `Alert Status Changed From ${_.capitalize(
				payload.old_status && payload.old_status.toLowerCase() === 'actiontaken' ? 'Action Taken' : payload.old_status
			)} to ${_.capitalize(payload.new_status && payload.new_status.toLowerCase() === 'actiontaken' ? 'Action Taken' : payload.new_status)}`
		}
		return (
			<>
				<img src={iconMap[activity.activity_type]} alt="activity-icon" className="prefix-icon" />
				<span className="time">
					{moment
						.utc(parseInt(activity.time) * 1000)
						.local()
						.format('DD MMM YYYY [at] HH:mm:ss')}
				</span>
				<span className="text">{activityDetail[activity.activity_type]}</span>
			</>
		)
	}
	renderComment = (comment) => {
		return (
			<>
				<img src={iconComment} alt="comment-icon" className="prefix-icon" />
				<span className="time">
					{moment
						.utc(parseInt(comment.time) * 1000)
						.local()
						.format('DD MMM YYYY [at] HH:mm:ss')}
				</span>
				<span className="text">
					{`${comment.comment} ${comment.edited ? '(edited)' : ''}`}
					<img
						onClick={() => {
							this.props.setEditingCommentInfo({ alert_id: this.props.data.alert_id, ...comment }, this.props.node.parent)
						}}
						src={iconModify}
						alt="modify-icon"
						className="modify-icon"
					/>
					<img
						onClick={() => {
							this.props.removeComment(comment.comment_id, this.props.data.alert_id)
						}}
						src={iconDelete}
						alt="delete-icon"
						className="delete-icon"
					/>
				</span>
			</>
		)
	}
	renderEvent = (event) => {
		const metric = _.get(event, 'evidences[0].key', '')
		return (
			<>
				<img src={iconMap[event.severity]} alt="severity-icon" className="prefix-icon" />
				<span className="time">
					{moment
						.utc(parseInt(event.time) * 1000)
						.local()
						.format('DD MMM YYYY [at] HH:mm:ss')}
				</span>
				<span
					className="text metric"
					onClick={() => {
						if (
							_.isEmpty(_.get(this.props, 'data.appliance_id')) ||
							_.isEmpty(_.get(this.props, `devicesDetails[${this.props.data.appliance_id}].hostname`)) ||
							_.isEmpty(_.get(event, 'evidences[0].key'))
						) {
							return
						}

						this.props.updateActiveApp('insights')
						this.props.openInsightsTab(
							{
								hostname: this.props.devicesDetails[this.props.data.appliance_id].hostname,
								serial: this.props.data.appliance_id,
								metric
							},
							true
						)
						this.props.redirectToAppRoot()
					}}
				>
					{event.severity !== 'healthy'
						? `${Util.getMetricDisplayName(this.props.metricsMisc, metric)} hit a ${event.severity} threshold`
						: `${Util.getMetricDisplayName(this.props.metricsMisc, metric)} clear`}
				</span>
			</>
		)
	}
	createMiscHTML = (snippet) => ({ __html: snippet })
	render() {
		const { data, alertsMisc, comments, events, activities } = this.props
		const metadata = {
			description: alertsMisc[data.alert_type_id].description,
			impacted_features: alertsMisc[data.alert_type_id].alert_impact
		}

		let eventsContent
		let loading = false
		if (
			comments === null ||
			events === null ||
			activities === null ||
			_.get(comments, `${data.alert_id}.isLoading`) ||
			_.get(events, `${data.alert_id}.isLoading`) ||
			_.get(activities, `${data.alert_id}.isLoading`)
		) {
			loading = true
			eventsContent = (
				<div className="info-area">
					<img className="loading-spinner" src={iconLoading} alt={'loading'} />
					Loading Events
				</div>
			)
		} else {
			const formattedActivities = _.get(activities, `${data.alert_id}.data`, []).map((activity) => ({ type: 'activity', ...activity }))
			const formattedComments = _.get(comments, `${data.alert_id}.data`, []).map((comment) => ({ type: 'comment', ...comment }))
			const formattedEvents = _.get(events, `${data.alert_id}.data`, []).map((event) => ({ type: 'event', ...event }))

			const contributingEvents = [
				...formattedComments,
				...formattedEvents,
				...formattedActivities.filter((activity) => !['alert_created', 'alert_event_added', 'alert_comment_added'].includes(activity.activity_type))
			]

			contributingEvents.sort((a, b) => b.time - a.time)
			eventsContent = contributingEvents.map((item, index) => {
				return (
					<div className="event-row" key={index}>
						{item.type === 'event' ? this.renderEvent(item) : item.type === 'comment' ? this.renderComment(item) : item.type === 'activity' ? this.renderActivity(item) : null}
					</div>
				)
			})
		}
		return (
			<div className="alert-detail-cell" id={data.alert_id} ref={this.detailCellRef}>
				<div className="alert-desc">
					<div className="desc">
						<div className="title">alert description</div>
						<div className="content" dangerouslySetInnerHTML={this.createMiscHTML(metadata.description)} />
					</div>
					<div className="impact">
						<div className="title">impact</div>
						<div className="content" dangerouslySetInnerHTML={this.createMiscHTML(metadata.impacted_features)} />
					</div>
					<div className="events">
						<div className="title">Contributing Events</div>
						<div className={`content ${loading ? 'loading' : ''}`}>{eventsContent}</div>
					</div>
				</div>
			</div>
		)
	}
}
