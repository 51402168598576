import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of, concat } from 'rxjs'
import { getHealthStatus, getHexGridLogs, getLogs, getLogsCount, getDetailLogs, getMoreDetails, pollInsightsJob } from '../../../store/services'

import Constants from '../../../utils/Constants.json'
import Util from '../../../utils/Util'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import * as insightsActions from '../../../store/actions'
import * as mainActions from '../../../../../main/actions'

import pageInfo from '../../pageUtil'

export const fetchInsightsMainPageContentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_INSIGHTS_MAIN_PAGE_CONTENT, actionTypes.REFRESH_INSIGHTS_TAB),
		switchMap((action) => {
			if (action.type === actionTypes.REFRESH_INSIGHTS_TAB && action.pageType !== pageInfo.InsightsMain.pageType) {
				return of()
			}
			const { insightsTabs, activeInsightsTab } = store.value.insights
			const currentTab = insightsTabs.fixed_tabs[Constants.FIXED_TABS.indexOf(activeInsightsTab)]
			const { state: cachedState, data: cachedData } = currentTab
			// 1. Restore State
			const stateActionList = []
			if (cachedState) {
				const { hexgridSetting, query, pageSize } = cachedState
				stateActionList.push(actions.setHexGridSettings(hexgridSetting, activeInsightsTab), actions.setInsightsQuery(query), actions.setInsightsPageSize(pageSize))
			}
			// 2. Restore Data
			const dataActionList = []
			if (cachedData) {
				const { healthStatus, hexgrid, insightsLogs, insightsLogsRequestId, insightsLogsCount, insightsPageLimit, insightsPageNumber } = cachedData
				dataActionList.push(
					actions.restoreHealthStatus({ healthStatus }),
					actions.restoreHexGrid({ hexgrid }),
					actions.restoreInsightsLogs({ insightsLogs, insightsLogsRequestId }),
					actions.restoreInsightsLogsCount({ insightsLogsCount }),
					actions.restoreInsightsPage({ insightsPageNumber, insightsPageLimit })
				)
				return concat(of(actions.updateInsightsTimeRange(), ...stateActionList), of(...dataActionList))
			} else {
				dataActionList.push(actions.fetchHealthStatusRequest(), actions.fetchHexGridRequest(), actions.fetchInsightsLogsRequest(), actions.fetchInsightsLogsCountRequest())
				return concat(of(insightsActions.updateInsightsLastRefresh(activeInsightsTab)), of(actions.updateInsightsTimeRange(), ...stateActionList), of(...dataActionList))
			}
		})
	)
}

export const fetchHealthStatusEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_HEALTH_STAT_REQUEST),
		switchMap(() => {
			return getHealthStatus(store.value).pipe(
				mergeMap(({ response }) => {
					return of(actions.setHealthStatusRequestId(response.jobId), actions.pollInsightsMainPageJobRequest(response.jobId, 'health', 0))
				}),
				catchError((error) => {
					return of(actions.fetchHealthStatusFailure(error))
				})
			)
		})
	)
}

export const updateHexGridSettingsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_HEXGRID_SETTINGS),
		mergeMap((action) => {
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const hexgridSettingPref = _.get(userPref, `insights.fixed_tabs[${Constants.FIXED_TABS.indexOf(action.activeInsightsTab)}].state.hexgridSetting`)
			if (_.isEqual(action.setting, hexgridSettingPref)) {
				return of()
			} else {
				_.set(userPref, `insights.fixed_tabs[${Constants.FIXED_TABS.indexOf(action.activeInsightsTab)}].state.hexgridSetting`, action.setting)
				return of(
					actions.setHexGridSettings(action.setting, action.activeInsightsTab),
					mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME),
					mainActions.setUserPreference(userPref)
				)
			}
		})
	)
}

export const fetchHexGridEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_HEX_GRID_REQUEST),
		switchMap(() => {
			return getHexGridLogs(store.value).pipe(
				mergeMap(({ response }) => {
					return of(actions.setHexGridRequestId(response.jobId), actions.pollInsightsMainPageJobRequest(response.jobId, 'hexgrid', 0))
				}),
				catchError((error) => of(actions.fetchHexGridFailure(error)))
			)
		})
	)
}

export const updateQueryPrefEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_QUERY_PREF),
		mergeMap((action) => {
			const { activeInsightsTab } = store.value.insights
			const { insightsQuery, insightsPageSize } = store.value.insightsMain
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const queryPref = _.get(userPref, `insights.fixed_tabs[${Constants.FIXED_TABS.indexOf(activeInsightsTab)}].state.query`)
			const pageSizePref = _.get(userPref, `insights.fixed_tabs[${Constants.FIXED_TABS.indexOf(activeInsightsTab)}].state.pageSize`)
			let updatePref = false
			if (insightsQuery !== queryPref) {
				updatePref = true
			}

			if (insightsPageSize !== pageSizePref) {
				updatePref = true
			}

			if (updatePref) {
				_.set(userPref, `insights.fixed_tabs[${Constants.FIXED_TABS.indexOf(activeInsightsTab)}].state.query`, insightsQuery)
				_.set(userPref, `insights.fixed_tabs[${Constants.FIXED_TABS.indexOf(activeInsightsTab)}].state.pageSize`, insightsPageSize)
				return of(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			} else {
				return of()
			}
		})
	)
}

export const fetchInsightsLogsEpic = (action$, store) => {
	/**Two cases this epic will be used
	 * 1. From component directly -- jobType is not set
	 * 2. From updateInsightsPageEpic when pageLimit is exceeded -- jobType is set to update_page
	 */
	return action$.pipe(
		ofType(actionTypes.FETCH_INSIGHTS_LOGS_REQUEST),
		// If handling of prev req is in process inside, drop it
		switchMap((action) => {
			if (!action.jobType) {
				action.jobType = 'insights_logs'
				action.jobPage = 0
				action.jobLimit = Constants.DEFAULT_PAGE_LIMIT
			}
			return concat(
				of(actions.updateQueryPref()),
				getLogs(store.value, action.jobLimit).pipe(
					mergeMap(({ response }) => {
						if (action.jobType === 'update_page') {
							// If it's update_page job type, also pass jobLimit for setting limit when polling is done.
							return of(actions.setInsightsLogsRequestId(response.jobId), actions.pollInsightsMainPageJobRequest(response.jobId, action.jobType, action.jobPage, action.jobLimit))
						} else {
							// If it's insights_logs job type, it must be page 0 request. Reset page limit for later polling.
							return of(
								actions.updateInsightsPageLimit(action.jobLimit),
								actions.setInsightsLogsRequestId(response.jobId),
								actions.pollInsightsMainPageJobRequest(response.jobId, action.jobType, action.jobPage)
							)
						}
					}),
					catchError((error) => {
						return of(actions.fetchInsightsLogsFailure(error))
					})
				)
			)
		})
	)
}

export const fetchInsightsLogsCountEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_INSIGHTS_LOGS_COUNT_REQUEST),
		switchMap(() => {
			return getLogsCount(store.value).pipe(
				mergeMap(({ response }) => {
					return of(actions.setInsightsLogsCountRequestId(response.jobId), actions.pollInsightsMainPageJobRequest(response.jobId, 'insights_logs_count', 0))
				}),
				catchError((error) => of(actions.fetchInsightsLogsCountFailure(error)))
			)
		})
	)
}

export const fetchInsightsDetailLogsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_INSIGHTS_DETAIL_LOGS_REQUEST),
		// If handling of prev req is in process inside, drop it
		switchMap((action) => {
			if (action.list.length === 0) {
				return of(actions.fetchInsightsDetailLogsSuccess([], null, store.value.insights.activeInsightsTab))
			}
			const currentTabJob = store.value.insightsMain.insightsLogsRequestId
			return getDetailLogs(store.value, action.list).pipe(
				mergeMap(({ response }) => {
					if (store.value.insightsMain.insightsLogsRequestId !== currentTabJob) {
						return of(actions.pollInsightsMainPageJobFailure(response.jobId, 'detail_logs', 'Cancelled'))
					}
					return of(actions.setInsightsDetailLogsRequestId(response.jobId), actions.pollInsightsMainPageJobRequest(response.jobId, 'detail_logs', 0))
				}),
				catchError((error) => {
					return of(actions.fetchInsightsDetailLogsFailure(error))
				})
			)
		})
	)
}

export const fetchMoreDetailLogsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_MORE_DETAIL_LOGS_REQUEST),
		// If handling of prev req is in process inside, drop it
		mergeMap((action) => {
			const log = store.value.insightsMain.insightsLogs[action.logIndex]
			let device = ''
			let metric = ''
			if (store.value.insights.activeInsightsTab === Constants.FIXED_TABS[0]) device = log.serial
			if (store.value.insights.activeInsightsTab === Constants.FIXED_TABS[1]) metric = log.metric
			const jobPage = log.details_page + 1
			if (log.details_jobId) {
				return of(
					actions.pollInsightsMainPageJobRequest(log.details_jobId, 'more_detail_logs', jobPage, null, {
						logIndex: action.logIndex,
						currentTabJob: store.value.insightsMain.insightsLogsRequestId
					})
				)
			}
			const currentTabJob = store.value.insightsMain.insightsLogsRequestId
			return getMoreDetails(store.value, device, metric).pipe(
				mergeMap(({ response }) => {
					if (currentTabJob !== store.value.insightsMain.insightsLogsRequestId) {
						return of(actions.fetchMoreDetailLogsFailure(error, action.logIndex))
					}
					return of(
						actions.pollInsightsMainPageJobRequest(response.jobId, 'more_detail_logs', jobPage, null, {
							logIndex: action.logIndex,
							currentTabJob: store.value.insightsMain.insightsLogsRequestId
						})
					)
				}),
				catchError((error) => {
					return of(actions.fetchMoreDetailLogsFailure(error, action.logIndex))
				})
			)
		})
	)
}

export const updateInsightsPageConfigEpic = (action$, store) => {
	/**
	 * 1. For page size change: update store pageSize, reset store pageNumber to 0, do a fresh fetchInsightsLogsRequest.
	 * 2. For page number change: update store pageNumber, check pageLimit and fetch logs accordingly
	 */
	return action$.pipe(
		ofType(actionTypes.UPDATE_INSIGHTS_PAGE_REQUEST),
		mergeMap((action) => {
			if (action.params.pageSize) {
				// Page Size Change
				return of(actions.setInsightsPageSize(action.params.pageSize), actions.updateInsightsPageNumber(0), actions.fetchInsightsLogsRequest())
			} else {
				// Page Number Change
				let withinLimit = (action.params.pageNumber + 1) * store.value.insightsMain.insightsPageSize <= store.value.insightsMain.insightsPageLimit
				if (withinLimit) {
					// 1. within page limit, return POLL_JOB_REQUEST
					return of(
						actions.updateInsightsPageNumber(action.params.pageNumber),
						actions.pollInsightsMainPageJobRequest(store.value.insightsMain.insightsLogsRequestId, 'update_page', action.params.pageNumber, store.value.insightsMain.insightsPageLimit)
					)
				} else {
					// 2. exceed page limit, return FETCH_LOGS_REQUEST
					// Do not update pageLimit here. Set pageLimit only after receive logs. Otherwise rapid updateInsightsPageRequests will fail. It will poll job with a smaller pageLimit
					// e.g. 500 per job, 100 per page. Request page 6. If job is not done, request page 7, it will poll job since it's withilimit. Polling might fail cause prev job is not done.
					// If limit is set after logs received, immediate page 7 request will be considered exceeding limit, hence fetching logs with a limit not polling.
					const jobLimit = Math.ceil(((action.params.pageNumber + 1) * store.value.insightsMain.insightsPageSize) / Constants.DEFAULT_PAGE_LIMIT) * Constants.DEFAULT_PAGE_LIMIT
					return of(actions.updateInsightsPageNumber(action.params.pageNumber), actions.fetchInsightsLogsRequest('update_page', jobLimit, action.params.pageNumber))
				}
			}
		})
	)
}

/*--------- Poll Job ----------*/
export const pollInsightsMainPageJobEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.POLL_INSIGHTS_MAIN_PAGE_JOB_REQUEST),
		mergeMap((action) => {
			return pollInsightsJob(store.value.insights, action.jobId, action.jobPage).pipe(
				mergeMap(({ response }) => {
					// For logs job, if job expired or pageNumber expired, quit processing
					// For health job, if job expired, quit processing
					if (
						(action.jobType === 'health' && response.jobId !== store.value.insightsMain.healthStatusRequestId) ||
						(action.jobType === 'hexgrid' && response.jobId !== store.value.insightsMain.hexgridRequestId) ||
						(action.jobType === 'insights_logs' && (response.jobId !== store.value.insightsMain.insightsLogsRequestId || action.jobPage !== store.value.insightsMain.insightsPageNumber)) ||
						(action.jobType === 'insights_logs_count' && response.jobId !== store.value.insightsMain.insightsLogsCountRequestId) ||
						(action.jobType === 'detail_logs' && response.jobId !== store.value.insightsMain.insightsDetailLogsRequestId)
					) {
						return of(actions.pollInsightsMainPageJobFailure(response.jobId, action.jobType, 'Cancelled'))
					}
					// If it's still running and job is valid, keep polling
					else if (response.state === 'RUNNING') {
						return of(actions.pollInsightsMainPageJobRequest(response.jobId, action.jobType, action.jobPage, action.jobLimit, action.params))
					}
					// If it's done, handle response accroding to action type
					else {
						// Insights Main
						if (action.jobType === 'health') {
							return of(actions.fetchHealthStatusSuccess(response.page.result.data[0]))
						} else if (action.jobType === 'hexgrid') {
							return of(actions.fetchHexGridSuccess(response.page.result.data))
						} else if (action.jobType === 'insights_logs') {
							const { logs, unHealthyDevices } = Util.initInsightsLogs(response.page.result.data, store.value.insights.activeInsightsTab)
							return concat(of(actions.fetchInsightsLogsSuccess(logs, response.jobId)), of(actions.fetchInsightsDetailLogsRequest(unHealthyDevices, action.jobPage)))
						} else if (action.jobType === 'insights_logs_count') {
							return of(actions.fetchInsightsLogsCountSuccess(response.page.result.data[0].count, response.jobId))
						} else if (action.jobType === 'detail_logs') {
							return of(actions.fetchInsightsDetailLogsSuccess(response.page.result.data, response.jobId, store.value.insights.activeInsightsTab))
						} else if (action.jobType === 'more_detail_logs') {
							return of(actions.fetchMoreDetailLogsSuccess(response.page.result.data, response.rowsInJob, action.params.logIndex, response.jobId, action.params.currentTabJob))
						} else if (action.jobType === 'update_page') {
							const { logs, unHealthyDevices } = Util.initInsightsLogs(response.page.result.data, store.value.insights.activeInsightsTab)
							return of(
								actions.updateInsightsPageLimit(action.jobLimit),
								actions.fetchInsightsLogsSuccess(logs, response.jobId),
								actions.fetchInsightsDetailLogsRequest(unHealthyDevices, action.jobPage)
							)
						}
					}
				}),
				catchError((error) => {
					if (action.jobType === 'health') {
						return of(actions.fetchHealthStatusFailure(error))
					} else if (action.jobType === 'hexgrid') {
						return of(actions.fetchHexGridFailure(error))
					} else if (action.jobType === 'insights_logs' || action.jobType === 'update_page') {
						return of(actions.fetchInsightsLogsFailure(error))
					} else if (action.jobType === 'insights_logs_count') {
						return of(actions.fetchInsightsLogsCountFailure(error))
					} else if (action.jobType === 'detail_logs') {
						return of(actions.fetchInsightsDetailLogsFailure(error))
					} else if (action.jobType === 'more_detail_logs') {
						return of(actions.fetchMoreDetailLogsFailure(error, action.params.logIndex))
					}
					{
						return of(actions.pollInsightsMainPageJobFailure(action.jobId, action.jobType, error))
					}
				})
			)
		})
	)
}
