import * as actionTypes from './actionTypes'

export const fetchLineMetricDetailsPageContent = () => ({
	type: actionTypes.FETCH_LINE_PAGE_CONTENT
})

/*----- Poll Job Actions -----*/
export const pollLinePageJobRequest = (jobId, jobType, jobPage, jobLimit, params) => ({
	type: actionTypes.POLL_LINE_PAGE_JOB_REQUEST,
	jobId,
	jobType,
	jobPage,
	jobLimit,
	params
})

export const pollLinePageJobFailure = (jobId, error) => ({
	type: actionTypes.POLL_LINE_PAGE_JOB_FAILURE,
	jobId,
	error
})

/*----- Line Page Desc Actions -----*/
export const fetchLinePageDescRequest = (device, metric, hostname) => ({
	type: actionTypes.FETCH_LINE_PAGE_DESC_REQUEST,
	device,
	metric,
	hostname
})

export const setLinePageDescRequestId = (jobId) => ({
	type: actionTypes.SET_LINE_PAGE_DESC_REQUEST_ID,
	jobId
})

export const fetchLinePageDescSuccess = (pageDesc) => ({
	type: actionTypes.FETCH_LINE_PAGE_DESC_SUCCESS,
	pageDesc
})

export const fetchLinePageDescFailure = (error) => ({
	type: actionTypes.FETCH_LINE_PAGE_DESC_FAILURE,
	error
})

export const restoreLinePageDesc = (data) => ({
	type: actionTypes.RESTORE_LINE_PAGE_DESC,
	data
})

/*----- Related Metrics Actions -----*/
export const fetchRelatedMetricsRequest = (node) => ({
	type: actionTypes.FETCH_RELATED_METRICS_REQUEST,
	node
})

export const fetchRelatedMetricsSuccess = (content, currentTabJob) => ({
	type: actionTypes.FETCH_RELATED_METRICS_SUCCESS,
	content,
	currentTabJob
})

export const setRelatedMetricsRequestId = (jobId) => ({
	type: actionTypes.SET_RELATED_METRICS_REQUEST_ID,
	jobId
})

export const fetchRelatedMetricsFailure = (error) => ({
	type: actionTypes.FETCH_RELATED_METRICS_FAILURE,
	error
})

export const restoreRelatedMetrics = (data) => ({
	type: actionTypes.RESTORE_RELATED_METRICS,
	data
})

/*----- Capacity Actions -----*/
export const fetchCapacityRequest = (node, metricList) => ({
	type: actionTypes.FETCH_CAPACITY_REQUEST,
	node,
	metricList
})

export const fetchCapacitySuccess = (data) => ({
	type: actionTypes.FETCH_CAPACITY_SUCCESS,
	data
})

export const fetchCapacityFailure = (error) => ({
	type: actionTypes.FETCH_CAPACITY_FAILURE,
	error
})

export const restoreCapacity = (data) => ({
	type: actionTypes.RESTORE_CAPACITY,
	data
})

/*----- Mov Avg Actions -----*/
export const fetchMovingAverageRequest = (device, metrics) => ({
	type: actionTypes.FETCH_MOV_AVG_REQUEST,
	device,
	metrics
})

export const fetchMovingAverageSuccess = (data, metric, jobId) => ({
	type: actionTypes.FETCH_MOV_AVG_SUCCESS,
	data,
	metric,
	jobId
})

export const setMovingAverageRequestId = (ids) => ({
	type: actionTypes.SET_MOV_AVG_REQUEST_ID,
	ids
})

export const fetchMovingAverageFailure = (error) => ({
	type: actionTypes.FETCH_MOV_AVG_FAILURE,
	error
})

export const restoreMovingAverage = (data) => ({
	type: actionTypes.RESTORE_MOV_AVG,
	data
})

export const restoreForecast = (data) => ({
	type: actionTypes.RESTORE_FORECAST,
	data
})

/*----- Timeline Actions -----*/
export const updateTimelineTimeRange = (preset) => ({
	type: actionTypes.UPDATE_TIMELINE_TIME_RANGE,
	preset
})

export const fetchTimelineRequest = (device, metrics) => ({
	type: actionTypes.FETCH_TIMELINE_REQUEST,
	device,
	metrics
})


export const fetchForecastRequest = (device, metrics) => ({
	type: actionTypes.FETCH_FORECAST_REQUEST,
	device,
	metrics
})

export const fetchForecastSuccess = (forecast, trend, device, metric) => ({
	type: actionTypes.FETCH_FORECAST_SUCCESS,
	forecast,
	trend,
	device,
	metric
})

export const fetchForecastFailure = (error) => ({
	type: actionTypes.FETCH_FORECAST_FAILURE,
	error
})

export const fetchTimelineSuccess = (data, metric, jobId) => ({
	type: actionTypes.FETCH_TIMELINE_SUCCESS,
	data,
	metric,
	jobId
})

export const setTimelineRequestId = (ids) => ({
	type: actionTypes.SET_TIMELINE_REQUEST_ID,
	ids
})

export const fetchTimelineFailure = (error) => ({
	type: actionTypes.FETCH_TIMELINE_FAILURE,
	error
})

export const restoreTimeline = (data) => ({
	type: actionTypes.RESTORE_TIMELINE,
	data
})

/*----- Commit Events Actions -----*/
export const fetchCommitEventsRequest = (device) => ({
	type: actionTypes.FETCH_COMMIT_EVENTS_REQUEST,
	device
})

export const fetchCommitEventsSuccess = (data) => ({
	type: actionTypes.FETCH_COMMIT_EVENTS_SUCCESS,
	data
})

export const setCommitEventsRequestId = (jobId) => ({
	type: actionTypes.SET_COMMIT_EVENTS_REQUEST_ID,
	jobId
})

export const fetchCommitEventsFailure = (error) => ({
	type: actionTypes.FETCH_COMMIT_EVENTS_FAILURE,
	error
})

export const restoreCommitEvents = (data) => ({
	type: actionTypes.RESTORE_COMMIT_EVENTS,
	data
})

/*----- Update Line Chart Settings Actions -----*/
export const updateCheckedRelatedMetrics = (checkedRelatedMetrics) => ({
	type: actionTypes.UPDATE_CHECKED_RELATED_METRICS,
	checkedRelatedMetrics
})

export const updateShowCommitEvents = (showCommitEvents) => ({
	type: actionTypes.UPDATE_SHOW_COMMIT_EVENTS,
	showCommitEvents
})

export const updateShowRefLines = (showRefLines) => ({
	type: actionTypes.UPDATE_SHOW_REF_LINES,
	showRefLines
})

export const updateTimeRangePreset = (timeRangePreset) => ({
	type: actionTypes.UPDATE_TIME_RANGE_PRESET,
	timeRangePreset
})

export const setCheckedRelatedMetrics = (checkedRelatedMetrics) => ({
	type: actionTypes.SET_CHECKED_RELATED_METRICS,
	checkedRelatedMetrics
})

export const setShowCommitEvents = (showCommitEvents) => ({
	type: actionTypes.SET_SHOW_COMMIT_EVENTS,
	showCommitEvents
})

export const setShowRefLines = (showRefLines) => ({
	type: actionTypes.SET_SHOW_REF_LINES,
	showRefLines
})

export const setTimeRangePreset = (timeRangePreset) => ({
	type: actionTypes.SET_TIME_RANGE_PRESET,
	timeRangePreset
})
