import React, { Component } from 'react'
import InnerMetricCellRenderer from './InnerMetricCellRenderer'
import LastUpdatedCellRenderer from './LastUpdatedCellRenderer'
import Constants from '../../utils/Constants.json'
import Util from '../../utils/Util'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

export default class DetailCellRenderer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			frameworkComponents: { innerMetricCellRenderer: InnerMetricCellRenderer, LastUpdatedCellRenderer },
			colDefs: [
				{
					headerName: 'Severity',
					field: 'metric_value_pct',
					width: 120,
					suppressSizeToFit: true,
					cellRenderer: (params) =>
						`<span class="inner-severity-cell"><span class="severity-icon ${params.data.severity}"></span>${
							params.data.metric in Constants.TABLE_CHART_INFO_MAP
								? ''
								: params.data.metric_value_pct === null || isNaN(params.data.metric_value_pct)
								? params.data.metric_value_raw === null || isNaN(params.data.metric_value_raw)
									? ''
									: Util.getReadableNumber(params.data.metric_value_raw, 0)
								: `${Math.round(params.data.metric_value_pct)}%`
						}</span>`
				},
				props.logType === Constants.FIXED_TABS[0]
					? {
							headerName: 'Metric',
							field: 'metric',
							flex: 1,
							cellRenderer: 'innerMetricCellRenderer',
							cellRendererParams: { metricsMisc: props.metricsMisc }
					  }
					: { headerName: 'Host Name', field: 'hostname', flex: 1 },
				{
					headerName: 'Last Update',
					field: 'timegenerated',
					width: 168,
					suppressSizeToFit: true,
					cellRenderer: 'LastUpdatedCellRenderer'
				}
			],
			defaultColDef: {
				resizable: false,
				suppressMenu: true
			},
			rowData: props.data.details_data,
			rowIndex: props.rowIndex,
			masterGridApi: props.api
		}
	}

	onGridReady = (params) => {
		let detailGridId = this.createDetailGridId()
		let gridInfo = {
			id: detailGridId,
			api: params.api,
			columnApi: params.columnApi
		}
		this.state.masterGridApi.addDetailGridInfo(detailGridId, gridInfo)
	}

	componentWillUnmount = () => {
		let detailGridId = this.createDetailGridId()
		this.state.masterGridApi.removeDetailGridInfo(detailGridId)
	}

	createDetailGridId = () => {
		return 'detail_' + this.state.rowIndex
	}

	onCellClicked = (node) => {
		if (node.column.colDef.field === 'metric' || node.column.colDef.field === 'hostname') {
			this.props.openInsightsTab(node.data, true)
		}
	}

	showMoreDetails = (rowIndex, offset) => {
		this.props.fetchMoreDetailLogsRequest((rowIndex - 1) / 2)
		this.props.setOffset(offset)
	}

	render() {
		const data = this.state.rowData
		let nextDetailIcon = 'healthy'
		if (data.length > 0) {
			let num_critical, num_warning
			if (this.props.logType === Constants.FIXED_TABS[0]) {
				const { num_critical_metrics, num_warning_metrics } = this.props.data
				num_critical = num_critical_metrics
				num_warning = num_warning_metrics
			} else {
				const { num_critical_devices, num_warning_devices } = this.props.data
				num_critical = num_critical_devices
				num_warning = num_warning_devices
			}
			if (data.length < num_critical) {
				nextDetailIcon = 'critical'
			} else if (data.length >= num_critical && data.length < num_critical + num_warning) {
				nextDetailIcon = 'warning'
			}
		}
		return (
			<div className={`inner-grid ${this.props.data.details_nomore ? 'inner-grid-nomore' : ''}`}>
				{data && data.length === 0 ? null : (
					<div className="detail-grid" style={{ height: `${(this.state.rowData.length + 1) * 28}px` }}>
						<div className={'master-detail-grid ag-theme-balham'} style={{ height: '100%' }}>
							<AgGridReact
								scrollbarWidth={0}
								suppressMaxRenderedRowRestriction={true}
								frameworkComponents={this.state.frameworkComponents}
								columnDefs={this.state.colDefs}
								defaultColDef={this.state.defaultColDef}
								rowData={this.state.rowData}
								onCellClicked={this.onCellClicked}
								onGridReady={this.onGridReady}
								getRowClass={(params) => {
									if (params.node.rowIndex % 2 === 0) {
										return 'detail-even-row'
									} else {
										return 'detail-odd-row'
									}
								}}
								suppressCsvExport={true}
								suppressExcelExport={true}
								suppressDragLeaveHidesColumns={true}
							/>
						</div>
					</div>
				)}
				{this.props.data.details_nomore ? null : this.props.data.details_loading ? (
					<div className="more-details">Loading ...</div>
				) : (
					<div
						onClick={(e) => {
							const offset =
								document.getElementsByClassName('ag-header')[0].getBoundingClientRect().y - document.getElementsByClassName('ag-center-cols-clipper')[0].getBoundingClientRect().y + 28
							this.showMoreDetails(this.state.rowIndex, offset)
						}}
						className="more-details"
					>
						<span className={`next-detail-icon ${nextDetailIcon}`} />
						{this.props.logType === Constants.FIXED_TABS[0] ? 'Show More Metrics' : 'Show More Devices'}
					</div>
				)}
				<div className="detail-separator" />
			</div>
		)
	}
}
