import OptGroup from "./OptGroup";
import Option from "./Option";
import SelectPropTypes from "./PropTypes";
import Select from "./Select";
import RecycleSelect from "./RecycleSelect";

Select.Option = Option;
Select.OptGroup = OptGroup;
export { Option, OptGroup, SelectPropTypes, RecycleSelect };
export default Select;
