import { Vector3 } from '../../../../../../vendor/three.module.js'

import tupleSearch from '../util/tupleSearch.js'
import gridSpaceToScreenSpace from './gridSpaceToScreenSpace.js'
import filterElements from '../util/filterElements.js'

export default function layoutElements () {

  this.filterElements = filterElements.bind(this)
  this.gridSpaceToScreenSpace = gridSpaceToScreenSpace.bind(this)

  const filteredElements = this.filterElements()
  const nElements = filteredElements.length
  let nElementsHeight = 1

  if(this.props.gridHeight !== undefined && this.props.gridHeight !== null) {
    nElementsHeight = this.props.gridHeight
  } else {
    while(
      ((nElements/(nElementsHeight+1)) / (1+nElementsHeight)) >
      (this.props.svgWidth/this.props.svgHeight)
    ) {
      nElementsHeight += 1
    }
    nElementsHeight = Math.max(1, nElementsHeight)
  }


  const models = filteredElements.map(o=>{
    return tupleSearch(o.attributes, 'model').value
  }).filter(onlyUnique)

  const versions = filteredElements.map(o=>{
    return tupleSearch(o.attributes, 'version').value
  }).filter(onlyUnique)

  const severities = filteredElements.map(o=>{
    return tupleSearch(o.attributes, 'severity').value
  }).filter(onlyUnique)

  const sortByGroups = {
    severity: severities,
    model: models,
    version: versions
  }

  let elements = []
  const elementGroupLabels = {}

  const severityLUT = {
    'critical': 5,
    'warning': 4,
    'nodata': 3,
    'healthy': 1
  }

  if(this.props.sortBy === 'none') {
    filteredElements.forEach((_g,n)=>{
      const id = _g.id
      const data = _g
      const name = data.name

      let x = (Math.floor(n / nElementsHeight))
      let y = (n - (x * nElementsHeight))

      y = y * 2
      if(x % 2 === 1){
        y += 1
      }
      const p = this.gridSpaceToScreenSpace({x,y})
      p.z = 0
      elements.push({ x, y, id, p, data, name })
    })
  } else {
    const elementGroups = Object.keys(sortByGroups).map(group => {
      const o = sortByGroups[group].map((g)=>{
        const _o = filteredElements.filter(o=>{
            return tupleSearch(o.attributes, group).value === g
          }).sort((a,b)=>{
            return severityLUT[tupleSearch(b.attributes, 'severity').value] - severityLUT[tupleSearch(a.attributes, 'severity').value]
          }).map((_g,n)=>{
            const id = _g.id
            const data = _g
            const name = data.name

            let x = (Math.floor(n / nElementsHeight))
            let y = (n - (x * nElementsHeight))

            y = y * 2
            if(x % 2 === 1){
              y += 1
            }

            const p = this.gridSpaceToScreenSpace({x,y})
            p.z = 0
            return { x, y, id, p, data, name }
          })
        return _o
      })
      o.group = group
      return o
    })

    let xOffset = 0

    elementGroups.filter(o=>{return o.group === this.props.sortBy})
      .forEach(g=>{
        g.forEach(_elements=>{
          _elements.forEach(element => {
            element.x += xOffset
            element.p = this.gridSpaceToScreenSpace({ x: element.x, y: element.y })
            elements.push(element)
            if(elementGroupLabels[tupleSearch(element.data.attributes, g.group).value] === undefined){
              elementGroupLabels[tupleSearch(element.data.attributes, g.group).value] = { x: xOffset, y: 0 }
            }
          })
          if(elements.length > 0) {
            xOffset = elements[elements.length-1].x + 2
          }
        })
      })
  }

  // normalize elements around (0,0)
  let gridMaxX = 0
  let gridMaxY = 0
  elements.forEach(e=>{
    if(e.x > gridMaxX) { gridMaxX = e.x }
    if(e.y > gridMaxY) { gridMaxY = e.y }
  })

  this.gridWidth = gridMaxX
  this.gridHeight = gridMaxY

  elements.forEach(e=>{
    e.x -= Math.ceil(gridMaxX / 2)
    e.y -= Math.ceil(gridMaxY / 2)
    e.p = this.gridSpaceToScreenSpace({ x: e.x, y: e.y })
    e.p.z = 0
  })

  // convert to 3d
  elements = elements.map((e,i)=>{
    e.targetP = { x: e.p.x, y: e.p.y, z: -.1*e.p.y }
    e.scale = .01
    e.targetScale = 1
    e.doChange = true
    e.rotation = new Vector3(
      (-2)-(5*Math.PI*Math.random())-Math.PI,
      (-Math.PI*0.5),
      0
    )
    e.targetRotation = new Vector3(
      -Math.PI*0.5,
      -Math.PI*0.5,
      0
    )
    e.opacity = 1
    e.active = true
    return e
  })
  ////////

  Object.values(elementGroupLabels).forEach((n,i)=>{
    if(Object.values(elementGroupLabels)[i+1] !== undefined) {
      n.width = Object.values(elementGroupLabels)[i+1].x - n.x
    } else {
      n.width = gridMaxX - n.x
    }
    n.x -= Math.ceil(gridMaxX / 2)
  })

  elements.labels = elementGroupLabels
  // console.log('layout elements', elements)
  return elements
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
