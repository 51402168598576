import _ from 'lodash'
import moment from 'moment'
import Constants from './Constants.json'
import pageInfo from '../pages/pageUtil'
import { queryConverter } from '../pages/InsightsMain/components/FilterBuilder'

const encode = (s) => {
	try {
		return window.btoa(s)
	} catch (e) {
		return null
	}
}

const decode = (s) => {
	try {
		return window.atob(s)
	} catch (e) {
		return null
	}
}

const Util = {
	getTenantInfoParams(store) {
		return `instance_id=${store.visibility.instanceId}&csp_id=${store.visibility.cspId}&tenant_id=${store.visibility.tenantId}`
	},
	// Humanize info
	getReadableBytes(bytes, decimal_places) {
		if (decimal_places === undefined) {
			decimal_places = 0
		}
		if (bytes === 0 || bytes === undefined) {
			return '0B'
		}
		const i = Math.floor(Math.log(bytes) / Math.log(1024))
		const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		if (i > 2) {
			decimal_places = 2
		}
		const readableByte = Number((bytes / Math.pow(1024, i)).toFixed(2) * 1).toFixed(decimal_places)
		const readableMetric = sizes[i]
		return `${readableByte} ${readableMetric}`
	},
	getStyledRisk(risk) {
		return ['<span class="ag-risk ag-risk-', risk, '">', risk, '</span>'].join('')
	},
	getStyledSeverity(severity) {
		const severity_to_risk_number_lut = {
			Critical: 5,
			High: 4,
			Medium: 3,
			Informational: 2,
			Low: 1
		}
		return ['<span class="ag-risk ag-risk-', severity_to_risk_number_lut[severity], '">', severity, '</span>'].join('')
	},
	getReadableNumber(num, decimal_places) {
		if (decimal_places === undefined) {
			decimal_places = 0
		}
		const neg = num < 0
		const units = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
		if (neg) {
			num = -num
		}
		if (num < 1) {
			return (neg ? '-' : '') + num
		}
		const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
		// num = Math.round(Number((num / Math.pow(1000, exponent)).toFixed(2)))
		// if (exponent >= 1) {
		// 	decimal_places = 2
		// }
		num = Number((num / Math.pow(1000, exponent)).toFixed(decimal_places))
		const unit = units[exponent]
		return `${(neg ? '-' : '') + num}${unit}`
	},

	getRelativeTime(time) {
		return moment(time / 1000).fromNow()
	},
	getTransformedTime(time) {
		//default format for panw
		let date = ''
		if (!isNaN(+time)) {
			date = moment(time / 1000).format('DD MMM YYYY [at] HH:mm:ss')
		} else {
			date = moment(time).format('DD MMM YYYY [at] HH:mm:ss')
		}
		return date
	},
	escapeHtml(string) {
		const entityMap = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			"'": '&#39;',
			'/': '&#x2F;',
			'`': '&#x60;',
			'=': '&#x3D;'
		}
		return String(string).replace(/[&<>"'`=\/]/g, (s) => {
			return entityMap[s]
		})
	},
	convertQueryTimeStamp(query, fields) {
		const queryExp = queryConverter.queryParseConverter(query, fields, queryConverter.escapeStringValue)
		const convertedQuery = queryExp.forEachToken((token) => {
			if (token.kind && token.field) {
				if (token.kind === 'value' && token.field.type === 'timestamp') {
					const timestamp = new Date(token.value)
					if (timestamp instanceof Date && !isNaN(timestamp)) {
						token.value = timestamp.toISOString()
					} else {
						throw new Error('Invalid Date')
					}
				}
			}
		})
		return convertedQuery.toString()
	},
	initInsightsLogs(data, logType) {
		const unHealthyDevices = []
		const logs = data.map((log) => {
			log.details_data = []
			log.details_page = -1
			log.details_jobId = null
			log.details_loading = false
			log.details_nomore = false
			if (log.severity !== 'healthy') {
				if (logType === Constants.FIXED_TABS[0]) unHealthyDevices.push(log.serial)
				if (logType === Constants.FIXED_TABS[1]) unHealthyDevices.push(log.metric)
				log.details_loading = true
			}
			return log
		})
		return { logs, unHealthyDevices }
	},
	getTimeRange(preset) {
		const currentTime = moment()
		const endTime = currentTime.valueOf()
		let startTime = endTime
		const presetInfo = Constants.TIME_RANGE_PRESETS[preset]
		if (presetInfo) startTime = currentTime.subtract(presetInfo.range[0], presetInfo.range[1]).valueOf()
		return [startTime, endTime]
	},
	getGenericMetricName(metric) {
		let result = metric
		Object.keys(Constants.GENERIC_METRIC_PATTERNS).forEach((key) => {
			let re = new RegExp(Constants.GENERIC_METRIC_PATTERNS[key])
			if (re.test(metric)) {
				// console.log(metric, pattern[1])
				result = key
			}
		})
		return result
	},
	getMetricDisplayName(metricsMisc, metric) {
		if (!metric) {
			return ''
		}
		const genericMetricName = Util.getGenericMetricName(metric)
		let displayName = ''
		let suffix = ''
		if (genericMetricName in metricsMisc) {
			if (genericMetricName in Constants.GENERIC_METRIC_PATTERNS) {
				let re = new RegExp(Constants.GENERIC_METRIC_PATTERNS[genericMetricName])
				suffix = metric.match(re)[1]
			}
			displayName = suffix && suffix !== 'unset' ? `${metricsMisc[genericMetricName].title} (${suffix})` : metricsMisc[genericMetricName].title
		} else {
			displayName = 'Metric Not Found'
		}
		return displayName
	},
	getTabState(tabs, index) {
		const fixedTabIndex = Constants.FIXED_TABS.indexOf(index)
		if (fixedTabIndex >= 0) {
			return tabs.fixed_tabs[fixedTabIndex]
		} else {
			return tabs.custom_tabs[index]
		}
	},
	setTabState(tabs, index, statesToUpdate) {
		const fixedTabIndex = Constants.FIXED_TABS.indexOf(index)
		const updatedTabs = _.cloneDeep(tabs)
		if (fixedTabIndex >= 0) {
			updatedTabs.fixed_tabs[fixedTabIndex] = { ...updatedTabs.fixed_tabs[fixedTabIndex], ...statesToUpdate }
		} else {
			updatedTabs.custom_tabs[index] = { ...updatedTabs.custom_tabs[index], ...statesToUpdate }
		}
		return updatedTabs
	},
	constructTabFromUrl() {
		const url = new URL(window.location.href)
		const urlParams = url.searchParams
		const type = urlParams.get('type')
		const device = urlParams.get('device')
		const hostname = urlParams.get('hostname')
		const metric = urlParams.get('metric')
		let tab = null
		if (device && hostname && metric) {
			const decodedDevice = decode(device)
			const decodedHostname = decodedDevice === null ? 'Invalid' : decode(hostname)

			let pageType
			if (_.isString(metric)) {
				if (metric in Constants.TABLE_CHART_INFO_MAP) {
					pageType = pageInfo.TableMetricDetails.pageType
				} else {
					pageType = pageInfo.LineMetricDetails.pageType
				}
			}
			tab = {
				pageType,
				desc: { device: decodedDevice, hostname: decodedHostname, metric },
				state: {
					..._.cloneDeep(pageInfo.LineMetricDetails.pageDefaultSettings)
				}
			}
		}
		if (Constants.FIXED_TABS.includes(`insights.${type}`)) {
			tab = { id: `insights.${type}` }
			if (type === Constants.FIXED_TABS[2].split('.')[1]) {
				if (device && hostname) {
					const decodedDevice = decode(device)
					const decodedHostname = decodedDevice === null ? 'Invalid' : decode(hostname)
					tab.desc = { device: decodedDevice, hostname: decodedHostname }
				} else {
					tab = null
				}
			}
		}
		return tab
	},
	encode,
	decode
}

export default Util
