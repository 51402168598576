import React from 'react'
import arrowDown from '../../../../../images/icon-arrow-down.svg'

import './Card.scss'

export default class Card extends React.Component {
	state = {
		isOpen: false
	}
	static getDerivedStateFromProps(nextProps) {
		if (!nextProps.addRuleDrawerVisible) return { isOpen: false }
	}
	render () {
		const { icon, title, content, prefixCls } = this.props
		return (
			<div className={`rule-config-card ${this.state.isOpen ? 'open' : ''} ${prefixCls || ''}`}>
				<div className='title' onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
					<img src={icon} alt='icon' className='card-icon' />
					<span className='card-title'>{title}</span>
					<img src={arrowDown} alt='icon open' className='open-icon' />
				</div>
				{this.state.isOpen ? <div className='content'>{content}</div> : null}
			</div>
		)
	}
}
