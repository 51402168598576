import React from 'react';
import { connect } from 'ui-lib';
import { Drawer } from 'antd';
import * as actions from '../../store/actions';
import * as insightsActions from '../../../../store/actions';

import './DeviceGraph.scss';

import {
    calculatePositions,
    getConnectedFirewalls,
    getDevice,
    getEdges,
    getLogging,
    getManagedFirewalls,
    getManagedLogConnectors,
    getManagingPanorama,
    getServices,
} from './utils';
import { Node } from './components/Node';
import { DevicesDrawerContent } from './components/DevicesDrawerContent';
import { LoggingDrawerContent } from './components/LoggingDrawerContent';
import { ServicesDrawerContent } from './components/ServicesDrawerContent';

/**
 * Questions
 * - How do we know which Panorama is HA Active/Passive?
 * - How to detect cloud vs on-prem logging? PANW_LOG_RECEPTOR_SRV
 * - Will denormalized device data have circular references? no
 *   - Will the 'managed_firewalls' reference a full device?no
 * - Are all the types shown? Any future types coming?
 * - Does JSON show CDL cloud-based logging?
 *
 * Answers
 * - Leave out HA for January scope (https://code.pan.run/device-insights/di-docs/-/merge_requests/24)
 * - No logging for Jan
 */

export class DeviceGraph extends React.Component {
    divRef = React.createRef();

    constructor(props) {
        super(props);
        const { device } = props;
        this.state = {
            device_id: device?.device_id,
            device: device,
            drawer: false,
            drawerTitle: null,
            drawerContent: null,
        };
    }

    render = () => {
        return <svg className={'device-graph'} ref={this.divRef}>
            <pattern
                id="pattern"
                patternUnits="userSpaceOnUse"
                width="12px"
                height="12px" viewBox="0 0 12 12">
                <rect x="6" y="6" width="1" height="1" fill="#ddd" id="square"/>
            </pattern>
            <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern)"/>
            {this.renderNodes()}
            <Drawer
                className={'device-graph-drawer'}
                placement="right"
                width={500}
                closable={true}
                onClose={this.closeDrawer}
                visible={this.state.drawer}
                title={this.state.drawerTitle}
                children={this.state.drawerContent}
            />
        </svg>;
    };

    renderNodes = () => {
        const { device, center } = this;
        // Create Node Data
        const deviceParams = getDevice(device);
        const logging = getLogging(device, this.onClickLogging);
        const services = getServices(device, this.onClickServices);
        const panorama = getManagingPanorama(device, this.onClickDevice);
        const managedFirewalls = getManagedFirewalls(device, this.onClickManagedFirewalls);
        const managedLogConnectors = getManagedLogConnectors(device, this.onClickManagedLogCollectors);
        const connectedFirewalls = getConnectedFirewalls(device, this.onClickConnectedFirewalls);

        // Calculate Node Positions
        calculatePositions(center, [
            deviceParams, logging, services, panorama, managedFirewalls, managedLogConnectors, connectedFirewalls
        ]);

        // Create the connections.
        const edges = getEdges(deviceParams, [
            logging, services, panorama, managedFirewalls, managedLogConnectors, connectedFirewalls
        ]);

        return <>
            {edges}
            <Node {...deviceParams}/>
            <Node {...logging}/>
            <Node {...services}/>
            <Node {...panorama}/>
            <Node {...managedFirewalls}/>
            <Node {...managedLogConnectors}/>
            <Node {...connectedFirewalls}/>
        </>;
    };

    get dimensions() {
        return {
            width: this.divRef.current?.clientWidth || -10000,
            height: this.divRef.current?.clientHeight || -10000,
        };
    }

    get center() {
        const dimensions = this.dimensions;
        return {
            x: dimensions.width / 2,
            y: dimensions.height / 2,
        };
    }

    get device() {
        return this.state.device;
    }

    onClickDevice = (device) => {
        this.closeDrawer();
        const { device_id: serial, hostname } = device;
        this.props.openDevicePage({ serial, hostname });
    };
    onClickLogging = () => {
        this.openDrawer({
            title: 'Logging',
            content: <LoggingDrawerContent
                device={this.device}
                onClickDevice={this.onClickDevice}
            />,
        });
    };
    onClickServices = () => {
        this.openDrawer({ title: 'Services', content: <ServicesDrawerContent device={this.device}/> });
    };
    onClickManagedFirewalls = () => {
        const { device } = this;
        const { managed_firewalls } = device;
        this.openDrawer({
            title: 'Firewalls',
            content: <DevicesDrawerContent
                titlePrefix={'Firewalls managed by '}
                device={device}
                devices={managed_firewalls}
                onClickDevice={this.onClickDevice}
            />,
        });
    };
    onClickManagedLogCollectors = () => {
        const { device } = this;
        const { managed_log_collectors } = device;
        this.openDrawer({
            title: 'Log Connectors',
            content: <DevicesDrawerContent
                titlePrefix={'Log Connectors managed by '}
                device={device}
                devices={managed_log_collectors}
                onClickDevice={this.onClickDevice}
            />,
        });
    };
    onClickConnectedFirewalls = () => {
        const { device } = this;
        const { connected_firewalls } = device;
        this.openDrawer({
            title: 'Firewalls',
            content: <DevicesDrawerContent
                titlePrefix={'Firewalls connected to '}
                device={device}
                devices={connected_firewalls}
                onClickDevice={this.onClickDevice}
            />,
        });
    };
    openDrawer = ({ title, content }) => this.setState({ drawer: true, drawerTitle: title, drawerContent: content });
    closeDrawer = () => this.setState({ drawer: false });
    resize = () => this.forceUpdate();

    componentDidMount = () => {
        window.addEventListener('resize', this.resize);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }
}

const mapStateToProps = (state) => {
    return { ...state.visibility, ...state.insights, ...state.devicePage };
};

const mapDispatchToProps = { ...actions, ...insightsActions };

export default connect(mapStateToProps, mapDispatchToProps, null)(DeviceGraph);

