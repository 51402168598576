import _ from 'lodash'
import Constants from '../utils/Constants.json'
import { TimeRangeSelectComponent } from 'ui-lib'
const getRange = TimeRangeSelectComponent.getRange

const pageUtil = {
	AlertsMain: {
		pageType: 'AlertsMain',
		storeKey: 'alertsMain',
		cacheUtil: (store, activeAlertsTab) => {
			const pageCache = { state: null, data: null }
			_.set(pageCache, 'state.alertsTimePreset', store.alertsTimePreset)
			_.set(pageCache, 'state.alertsTimeRange', _.cloneDeep(store.alertsTimeRange))

			// Do not cache data if there's unsettled requests on page
			if (!(store.closedAlertsLoading || store.closedAlertsError || store.devicesDetailsLoading || store.devicesDetailsError || store.alertsLoading || store.alertsError)) {
				pageCache.data = {
					closedAlerts: store.closedAlerts,
					devicesDetails: store.devicesDetails,
					alerts: store.alerts
				}
			}
			return pageCache
		},
		pageDefaultSettings: { alertsTimePreset: Constants.DEFAULT_ALERTS_TIME_PRESET, alertsTimeRange: getRange(Constants.DEFAULT_ALERTS_TIME_PRESET).timeval() }
	},
	AlertsNotifications: {
		pageType: 'AlertsNotifications',
		storeKey: 'alertsNotifications',
		cacheUtil: (store, activeAlertsTab) => {
			const pageCache = { state: null, data: null }
			if (!store.rulesLoading) {
				pageCache.data = {
					rules: store.rules
				}
			}
			return pageCache
		},
		pageDefaultSettings: {}
	},
	AlertDetails: {
		pageType: 'AlertDetails',
		storeKey: 'alertDetails',
		cacheUtil: (store, activeAlertsTab) => {
			const pageCache = { data: null }
			_.set(pageCache, 'state.timeRange', store.alertTimeRangePreset)
			_.set(pageCache, 'state.showCommitEvents', store.showAlertCommitEvents)
			_.set(pageCache, 'state.showRefLines', store.showAlertRefLines)
			// _.set(pageCache, 'state.showForecast', store.showAlertForecast)
			const alertInfo = _.get(store, 'alert[0]')
			if (!alertInfo || !alertInfo.metrics) return pageCache
			if (alertInfo.metrics[0] in Constants.TABLE_CHART_INFO_MAP) {
				const cacheData = store.alertTableChartsData
				let isLoading = false

				if (!_.isEmpty(cacheData)) {
					alertInfo.metrics.forEach((metric) => {
						if (_.get(cacheData[metric], 'isLoading')) {
							isLoading = true
						}
					})
				} else {
					isLoading = true
				}
				if (store.alertLoading || store.alertError || store.deviceDetailsLoading || store.deviceDetailsError) {
					isLoading = true
				}
				if (!isLoading) {
					pageCache.data = {
						alert: store.alert,
						deviceDetails: store.deviceDetails,
						alertTableChartsData: store.alertTableChartsData
					}
				}
			} else {
				if (
					!(
						store.alertLoading ||
						store.alertError ||
						store.deviceDetailsLoading ||
						store.deviceDetailsError ||
						store.alertTimelineLoading ||
						store.alertTimelineError ||
						store.alertMovingAverageLoading ||
						store.alertMovingAverageError ||
						store.alertCommitEventsLoading ||
						store.alertCommitEventsError ||
						store.alertCapacityLoading ||
						store.alertCapacityError
					)
				) {
					pageCache.data = {
						alert: store.alert,
						deviceDetails: store.deviceDetails,
						alertTimeline: store.alertTimeline,
						alertMovingAverage: store.alertMovingAverage,
						alertCommitEvents: store.alertCommitEvents,
						alertCapacity: store.alertCapacity
					}
				}
			}

			return pageCache
		},
		pageDefaultSettings: { showCommitEvents: false, showRefLines: true, timeRange: Constants.TIME_RANGE_PRESETS.DefaultPreset }
	}
}

export default pageUtil
