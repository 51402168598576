import React from 'react'
import { connect } from 'ui-lib'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import * as actions from '../../store/actions'
import * as alertsActions from '../../../../store/actions'
import * as insightsActions from '../../../../../insights/store/actions'
import * as visibilityActions from '../../../../../store/actions'

import MasterDetailGridWidget from './MasterDetailGridWidget/MasterDetailGridWidget'
import DetailCellRenderer from '../../../../components/CellRenderers/DetailCellRenderer/DetailCellRenderer'
import SeverityCellRenderer from '../../../../components/CellRenderers/SeverityCellRenderer'
import StatusCellRenderer from '../../../../components/CellRenderers/StatusCellRenderer/StatusCellRenderer'
import CommentCellRenderer from '../../../../components/CellRenderers/CommentCellRenderer/CommentCellRenderer'
import LastUpdateHeaderCellRenderer from '../../../../../insights/components/CellRenderers/LastUpdatedHeaderCellRenderer/LastUpdateHeaderCellRenderer'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-enterprise'

import './AlertTable.scss'
class AlertsTable extends React.Component {
	state = {
		tableHeight: 57,
		editingCommentInfo: null,
		resetSchema: false,
		defaultColDef: { flex: 1 },
		frameworkComponents: {
			DetailCellRenderer,
			SeverityCellRenderer,
			StatusCellRenderer,
			CommentCellRenderer,
			LastUpdateHeaderCellRenderer
		}
	}

	componentDidUpdate(prevProps) {
		if (this.gridApi && this.gridColumnApi && prevProps.alertsSchema !== this.props.alertsSchema) {
			this.gridColumnApi.resetColumnState()
			this.gridApi.sizeColumnsToFit()
		}

		if (prevProps.alertComments !== this.props.alertComments || prevProps.alertEvents !== this.props.alertEvents || prevProps.alertActivities !== this.props.alertActivities) {
			if (this.gridApi) this.gridApi.redrawRows()
		}
	}

	onOpenDetails = (alertId) => {
		this.props.fetchAlertCommentsRequest(alertId)
		this.props.fetchAlertEventsRequest(alertId)
		this.props.fetchAlertActivitiesRequest(this.props.loggedInUser.email, alertId, alertId)
	}

	onCloseDetails = () => {}

	onGridReady = (params) => {
		this.gridApi = params.api
		this.gridColumnApi = params.columnApi
		this.gridApi.sizeColumnsToFit()
	}

	onGridResized = () => {
		if (this.gridApi) {
			this.gridApi.sizeColumnsToFit()
			this.gridApi.resetRowHeights()
			this.resetTableHeight(this.gridApi)
		}
	}

	onModelUpdated = (params) => {
		this.resetTableHeight(params.api)
	}

	getMainMenuItems = (params) => {
		const itemsToExclue = ['autoSizeThis', 'autoSizeAll', 'resetColumns']
		const menu = params.defaultItems.filter((item) => !itemsToExclue.includes(item))
		menu.push({
			name: 'Reset Columns',
			action: () => {
				this.props.resetAlertsSchema('alerts.main'.split('.')[1])
			}
		})
		return menu
	}

	getRowHeight = (params) => {
		if (params.node && params.node.detail) {
			const detailCell = document.getElementById(params.data.alert_id)
			if (detailCell) return detailCell.clientHeight + 1
			return 0
		}
		return 38
	}

	getRowStyle = (params) => {
		if (parseInt(params.node.id) % 2 === 0) {
			return { background: 'white' }
		} else {
			return { background: '#f4f5f5' }
		}
	}

	resetTableHeight = (api) => {
		const rowNode = api.getRowNode(0)
		if (_.get(rowNode, 'expanded')) {
			const alertDesc = document.querySelector('.alert-desc')
			if (alertDesc) {
				if (alertDesc.clientHeight !== this.state.tableHeight) {
					this.setState({ tableHeight: alertDesc.clientHeight + 59 })
					this.props.setTableHeight(alertDesc.clientHeight + 59)
				}
			}
		} else {
			if (this.state.tableHeight !== 67) {
				this.setState({ tableHeight: 67 })
				this.props.setTableHeight(67)
			}
		}
	}

	render() {
		const {
			updateActiveApp,
			openInsightsTab,
			alertsSchema,
			alertsMisc,
			metricsMisc,
			alert,
			alertComments,
			alertEvents,
			alertActivities,
			alertLoading,
			alertError,
			deviceDetails,
			deviceDetailsLoading,
			deviceDetailsError
		} = this.props

		if (alertError || deviceDetailsError) {
			return <div className="info-area">Error Fetching Alerts</div>
		}

		if (alertLoading || deviceDetailsLoading) {
			return (
				<div className="info-area">
					<img className="loading-spinner" src={iconLoading} alt={'loading'} />
					Loading Data
				</div>
			)
		}

		const [vendor, type] = 'alerts.main'.split('.')
		const fields = alertsSchema[vendor].content[type].fields //.filter((field) => field.name !== 'severity')

		const columns = _.map(fields, (fieldObj, i) => {
			const col = {
				lockPosition: true,
				lockVisible: true,
				flex: null,
				headerName: fieldObj.displayName,
				field: fieldObj.name,
				colId: fieldObj.name,
				pinned: fieldObj.pinned,
				resizable: false,
				hide: fieldObj.hidden,
				filterable: fieldObj.filterable,
				width: fieldObj.defaultWidth,
				minWidth: 80,
				suppressSizeToFit: true,
				valueFormatter: (params) => {
					if (['true', 'false', 'yes', 'no'].includes(params.value)) {
						return _.capitalize(params.value)
					} else {
						return params.value
					}
				}
			}

			if (col.field === 'hostname') {
				col.valueFormatter = (params) => {
					return _.get(deviceDetails, `${params.data.appliance_id}.hostname`, 'N/A')
				}
			}

			if (col.field === 'severity') {
				col.cellRenderer = 'SeverityCellRenderer'
				col.cellRendererParams = {
					editingCommentInfo: this.state.editingCommentInfo,
					onOpenDetails: this.onOpenDetails,
					onCloseDetails: this.onCloseDetails
				}
			}

			if (col.field === 'status') {
				col.cellRenderer = 'StatusCellRenderer'
				col.cellRendererParams = {
					assignee: this.props.loggedInUser.email,
					updateAlert: this.props.updateAlertStatusRequest,
					popupContainer: 'body'
				}
			}

			if (col.field === 'alert_name') {
				col.flex = 1
				col.suppressSizeToFit = false
			}

			if (col.field === 'time') {
				col.cellRenderer = 'CommentCellRenderer'
				col.headerComponent = 'LastUpdateHeaderCellRenderer'
				col.cellRendererParams = {
					cancelEditing: (node) => {
						if (this.state.editingCommentInfo) {
							this.setState({ editingCommentInfo: null }, () => {
								if (this.gridApi) this.gridApi.redrawRows({ rowNodes: [node] })
							})
						}
					},
					editingCommentInfo: this.state.editingCommentInfo,
					addComment: this.props.addAlertCommentRequest,
					updateComment: this.props.updateAlertCommentRequest,
					activeApp: this.props.activeApp,
					alertsLastUpdate: this.props.alertsLastUpdate,
					popupContainer: 'body'
				}
				col.lockPinned = true
			}

			return col
		})
		return (
			<div className="alert-table" style={{ height: `${this.state.tableHeight}px` }}>
				<MasterDetailGridWidget
					enableSorting={false}
					columnDefs={columns}
					defaultColDef={this.state.defaultColDef}
					getMainMenuItems={this.getMainMenuItems}
					detailCellRendererParams={{
						redirectToAppRoot: () => {
							this.props.history.push('/')
						},
						updateActiveApp,
						openInsightsTab,
						devicesDetails: deviceDetails,
						alertsMisc,
						metricsMisc,
						comments: alertComments,
						events: alertEvents,
						activities: alertActivities,
						removeComment: this.props.removeAlertCommentRequest,
						editingCommentInfo: this.state.editingCommentInfo,
						setEditingCommentInfo: (info, node) => {
							this.setState({ editingCommentInfo: info }, () => {
								if (this.gridApi) this.gridApi.redrawRows({ rowNodes: [node] })
							})
						}
					}}
					detailCellRenderer={'DetailCellRenderer'}
					frameworkComponents={this.state.frameworkComponents}
					rowData={alert}
					onGridReady={this.onGridReady}
					onGridSizeChanged={this.onGridResized}
					onModelUpdated={this.onModelUpdated}
					onRowClicked={this.onRowClicked}
					onCellClicked={this.onCellClicked}
					onColumnPinned={() => this.gridApi.redrawRows()}
					getRowHeight={this.getRowHeight}
					getRowStyle={this.getRowStyle}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alertDetails, ...state.alerts, ...state.visibility, loggedInUser: state.main.loggedInUser }
}

const mapDispatchToProps = { ...alertsActions, ...actions, ...insightsActions, ...visibilityActions }

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null)(AlertsTable))
