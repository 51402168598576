import React, { Component } from 'react'
import { PopoverWidget as Popover } from 'ui-lib'
import Util from '../../utils/Util'

export default class MetricCellRender extends Component {
	state = {
		popoverVisible: false
	}

	togglePopover = () => {
		this.setState((prev) => ({ popoverVisible: !prev.popoverVisible }))
	}
	render() {
		const { data, metricsMisc } = this.props
		let displayName = Util.getMetricDisplayName(metricsMisc, data.metric)
		return (
			<span className="metric-cell">
				<Popover
					transitionName={null}
					mouseLeaveDelay={0}
					mouseEnterDelay={0}
					prefixCls={`severity-popover${this.state.popoverVisible ? '' : '-hidden'}`}
					content={'Add to filter'}
					placement="bottomLeft"
				>
					<span onMouseLeave={this.togglePopover} onMouseEnter={this.togglePopover} className={`severity-icon ${this.props.data.severity}`} />
				</Popover>
				{displayName}
			</span>
		)
	}
}
