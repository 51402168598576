export const OPEN_INSIGHTS_TAB = 'OPEN_INSIGHTS_TAB'
export const REFRESH_INSIGHTS_TAB = 'REFRESH_INSIGHTS_TAB'

export const RESET_STORE = 'RESET_STORE'

export const FETCH_LINE_PAGE_CONTENT = 'FETCH_LINE_PAGE_CONTENT'

export const POLL_LINE_PAGE_JOB_REQUEST = 'POLL_LINE_PAGE_JOB_REQUEST'
export const POLL_LINE_PAGE_JOB_FAILURE = 'POLL_LINE_PAGE_JOB_FAILURE'

export const FETCH_LINE_PAGE_DESC_REQUEST = 'FETCH_LINE_PAGE_DESC_REQUEST'
export const FETCH_LINE_PAGE_DESC_SUCCESS = 'FETCH_LINE_PAGE_DESC_SUCCESS'
export const SET_LINE_PAGE_DESC_REQUEST_ID = 'SET_LINE_PAGE_DESC_REQUEST_ID'
export const FETCH_LINE_PAGE_DESC_FAILURE = 'FETCH_LINE_PAGE_DESC_FAILURE'
export const RESTORE_LINE_PAGE_DESC = 'RESTORE_LINE_PAGE_DESC'

export const FETCH_RELATED_METRICS_REQUEST = 'FETCH_RELATED_METRICS_REQUEST'
export const FETCH_RELATED_METRICS_SUCCESS = 'FETCH_RELATED_METRICS_SUCCESS'
export const SET_RELATED_METRICS_REQUEST_ID = 'SET_RELATED_METRICS_REQUEST_ID'
export const FETCH_RELATED_METRICS_FAILURE = 'FETCH_RELATED_METRICS_FAILURE'
export const RESTORE_RELATED_METRICS = 'RESTORE_RELATED_METRICS'

export const FETCH_CAPACITY_REQUEST = 'FETCH_CAPACITY_REQUEST'
export const FETCH_CAPACITY_SUCCESS = 'FETCH_CAPACITY_SUCCESS'
export const FETCH_CAPACITY_FAILURE = 'FETCH_CAPACITY_FAILURE'
export const RESTORE_CAPACITY = 'RESTORE_CAPACITY'

export const FETCH_MOV_AVG_REQUEST = 'FETCH_MOV_AVG_REQUEST'
export const FETCH_MOV_AVG_SUCCESS = 'FETCH_MOV_AVG_SUCCESS'
export const SET_MOV_AVG_REQUEST_ID = 'SET_MOV_AVG_REQUEST_ID'
export const FETCH_MOV_AVG_FAILURE = 'FETCH_MOV_AVG_FAILURE'
export const RESTORE_MOV_AVG = 'RESTORE_MOV_AVG'

export const FETCH_TIMELINE_REQUEST = 'FETCH_TIMELINE_REQUEST'
export const FETCH_TIMELINE_SUCCESS = 'FETCH_TIMELINE_SUCCESS'
export const SET_TIMELINE_REQUEST_ID = 'SET_TIMELINE_REQUEST_ID'
export const FETCH_TIMELINE_FAILURE = 'FETCH_TIMELINE_FAILURE'
export const RESTORE_TIMELINE = 'RESTORE_TIMELINE'

export const FETCH_FORECAST_REQUEST = 'FETCH_FORECAST_REQUEST'
export const FETCH_FORECAST_FAILURE = 'FETCH_FORECAST_FAILURE'
export const FETCH_FORECAST_SUCCESS = 'FETCH_FORECAST_SUCCESS'
export const RESTORE_FORECAST = 'RESTORE_FORECAST'

export const FETCH_COMMIT_EVENTS_REQUEST = 'FETCH_COMMIT_EVENTS_REQUEST'
export const FETCH_COMMIT_EVENTS_SUCCESS = 'FETCH_COMMIT_EVENTS_SUCCESS'
export const SET_COMMIT_EVENTS_REQUEST_ID = 'SET_COMMIT_EVENTS_REQUEST_ID'
export const FETCH_COMMIT_EVENTS_FAILURE = 'FETCH_COMMIT_EVENTS_FAILURE'
export const RESTORE_COMMIT_EVENTS = 'RESTORE_COMMIT_EVENTS'

export const UPDATE_TIMELINE_TIME_RANGE = 'UPDATE_TIMELINE_TIME_RANGE'
export const UPDATE_CHECKED_RELATED_METRICS = 'UPDATE_CHECKED_RELATED_METRICS'
export const UPDATE_SHOW_COMMIT_EVENTS = ' UPDATE_SHOW_COMMIT_EVENTS'
export const UPDATE_SHOW_REF_LINES = 'UPDATE_SHOW_REF_LINES'
export const UPDATE_TIME_RANGE_PRESET = 'UPDATE_TIME_RANGE_PRESET'

export const SET_CHECKED_RELATED_METRICS = 'SET_CHECKED_RELATED_METRICS'
export const SET_SHOW_COMMIT_EVENTS = ' SET_SHOW_COMMIT_EVENTS'
export const SET_SHOW_REF_LINES = 'SET_SHOW_REF_LINES'
export const SET_TIME_RANGE_PRESET = 'SET_TIME_RANGE_PRESET'
