import React, { Component } from 'react'
import iconCritical from '../../../images/icon-critical.svg'
import iconWarning from '../../../images/icon-warning.svg'
import iconHealty from '../../../images/icon-healthy.svg'
import iconCheckboxTrue from 'ui-lib/src/images/icon-checkbox-true.svg'
import iconCheckboxFalse from 'ui-lib/src/images/icon-checkbox-false.svg'
import Constants from '../../utils/Constants.json'
import Util from '../../utils/Util'

const iconMap = { critical: iconCritical, warning: iconWarning, healthy: iconHealty }

export default class RelatedMetricSeverityCellRender extends Component {
	render() {
		const { state: checkState, data } = this.props
		const checked = checkState[data.metric]
		const checkedRowsCount = Object.keys(checkState).filter((metric) => checkState[metric]).length
		const onClick = checkedRowsCount === 2 && !checked ? null : () => this.props.toggleCheck(data.metric, !checked)
		return (
			<span className="inner-severity-cell">
				<img className="checkbox-icon" src={checked ? iconCheckboxTrue : iconCheckboxFalse} alt={'checkbox'} onClick={onClick} />
				<img className="severity-icon" src={iconMap[this.props.data.severity]} alt={this.props.data.severity} />
			</span>
		)
	}
}
