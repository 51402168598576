import React from 'react'
import _ from 'lodash'
import { connect } from 'ui-lib'
import { Drawer, Input } from 'antd'
import Card from '../Card/Card'
import ConditionBuilder from './ConditionBuilder/ConditionBuilder'
import EmailConfig from './EmailConfig/EmailConfig'
import ServiceNowConfig from './ServiceNowConfig/ServiceNowConfig'

import iconOne from '../../../../../images/icon-one.svg'
import iconTwo from '../../../../../images/icon-two.svg'
import iconThree from '../../../../../images/icon-three.svg'

import * as actions from '../../store/actions'

import './AddRuleDrawer.scss'
import 'antd/lib/drawer/style/css'

class AddRuleDrawer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			inputError: null,
			incomplete: true,
			name: '',
			description: '',
			conditions: [],
			emailChecked: false,
			emailTemplateId: null,
			emailTemplateName: '',
			emailFreq: 1,
			serviceNowChecked: false,
			serviceNowTemplateId: ''
		}
	}

	getDerivedStateFromEditingRule = (prevProps) => {
		if ((_.isEmpty(prevProps) || prevProps.isEditMode === false) && this.props.isEditMode === true) {
			const { editingRule } = this.props
			const rule = {
				name: editingRule.name,
				description: editingRule.description,
				conditions: editingRule.condition || [],
				emailChecked: !_.isEmpty(editingRule.email_template_id) || false,
				emailTemplateId: editingRule.email_template_id || null,
				emailFreq: editingRule.email_frequency || 1,
				serviceNowChecked: !_.isEmpty(editingRule.servicenow_template_id) || false,
				serviceNowTemplateId: editingRule.servicenow_template_id || ''
			}
			this.setState({
				incomplete: false,
				ruleId: rule.rule_id,
				name: rule.name,
				description: rule.description,
				conditions: rule.conditions
					? rule.conditions.split('&&').map((condition) => {
							const tokens = condition.split(/([!=]=)/)
							return { key: tokens[0].trim(), op: tokens[1].trim(), value: tokens[2].replace(/['"]+/g, '').trim() }
					  })
					: [],
				emailChecked: !_.isEmpty(rule.emailTemplateId),
				emailTemplateId: rule.emailTemplateId,
				emailFreq: rule.emailFreq,
				serviceNowChecked: !_.isEmpty(rule.serviceNowTemplateId),
				serviceNowTemplateId: rule.serviceNowTemplateId
			})
		}
	}

	componentDidMount() {
		this.getDerivedStateFromEditingRule()
	}

	componentDidUpdate(prevProps) {
		this.getDerivedStateFromEditingRule(prevProps)
	}

	onClose = () => {
		this.props.closeDrawer()
		this.props.removeConfigError()
		this.setState({
			inputError: null,
			incomplete: true,
			name: '',
			description: '',
			conditions: [],
			emailChecked: false,
			emailTemplateId: null,
			emailFreq: 1,
			serviceNowChecked: false,
			serviceNowTemplateId: null
		})
	}

	onSave = () => {
		if (!this.state.incomplete) {
			const ruleDef = {
				name: this.state.name,
				description: this.state.description,
				condition: this.state.conditions.map((condition) => `${condition.key} ${condition.op} '${condition.value}'`).join(' && '),
				email_template_id: this.state.emailTemplateId,
				email_frequency: this.state.emailFreq,
				servicenow_template_id: this.state.serviceNowTemplateId,
				pause_start_time: 0,
				pause_end_time: 0
			}

			if (this.props.isEditMode) {
				this.props.updateRuleRequest(this.props.editingRule.rule_id, ruleDef)
				// console.log(this.props)
			} else {
				this.props.addRuleRequest(ruleDef)
			}
			this.onClose()
		}
	}

	isComplete = (state) => {
		let isValid = true
		// 1. Rule will need name, description and at least one condition
		if (_.isEmpty(state.name)) isValid = false
		if (_.isEmpty(state.description)) isValid = false
		if (_.isEmpty(state.conditions)) isValid = false
		// 2. At least one of email and service now is checked
		if (!state.emailChecked && !state.serviceNowChecked) isValid = false
		if (state.emailChecked && _.isEmpty(state.emailTemplateId)) isValid = false
		if (state.serviceNowChecked && _.isEmpty(state.serviceNowTemplateId)) isValid = false

		return isValid
	}

	setRuleState = (newState) => {
		const updatedState = { ...this.state, ...newState }
		if (this.isComplete(updatedState)) {
			this.setState({ ...updatedState, incomplete: false })
		} else {
			this.setState({ ...updatedState, incomplete: true })
		}
	}

	render() {
		const { addRuleDrawerVisible, emailTemplates } = this.props
		return (
			<Drawer
				prefixCls="notifications-drawer ant-drawer"
				getContainer={() => document.getElementById('alerts-app')}
				title={`${this.props.isEditMode ? 'Edit Notification Rule' : 'Add Notification Rule'}`}
				placement="right"
				closable={false}
				width={500}
				onClose={this.onClose}
				visible={addRuleDrawerVisible}
			>
				<Card
					addRuleDrawerVisible={addRuleDrawerVisible}
					icon={iconOne}
					title="Name and Description"
					content={
						<>
							<div className="input-item">
								<div className="title">Name</div>
								<div className="input">
									<Input
										allowClear
										maxLength={256}
										value={this.state.name || this.props.name}
										onChange={(e) => {
											this.setRuleState({ name: e.target.value })
										}}
									/>
									{this.state.inputError ? <div className="input-error">{`* ${this.state.inputError}`}</div> : null}
								</div>
							</div>
							<div className="input-item">
								<div className="title">Description</div>
								<div className="input">
									<Input.TextArea
										row={4}
										autoSize
										value={this.state.description || this.props.description}
										onChange={(e) => {
											this.setState({ description: e.target.value }, () => {
												this.setRuleState({ description: this.state.description })
											})
										}}
									/>
								</div>
							</div>
						</>
					}
				/>
				<Card
					addRuleDrawerVisible={addRuleDrawerVisible}
					icon={iconTwo}
					title="Rule Conditions"
					content={
						<>
							<div className="input-item">
								<div className="title">Send notification if...</div>
								<div className="input">
									<ConditionBuilder isEditMode={this.props.isEditMode} ruleState={this.state} setRuleState={this.setRuleState} />
								</div>
							</div>
						</>
					}
				/>
				<Card
					addRuleDrawerVisible={addRuleDrawerVisible}
					icon={iconThree}
					prefixCls="recipient-config"
					title="Notification Type and Recipients"
					content={
						<>
							<EmailConfig emailTemplates={emailTemplates} ruleState={this.state} isEditMode={this.props.isEditMode} setRuleState={this.setRuleState} />
							<ServiceNowConfig ruleState={this.state} isEditMode={this.props.isEditMode} setRuleState={this.setRuleState} />
						</>
					}
				/>
				<div className="btns">
					<span className="cancel-btn" onClick={this.onClose}>
						Cancel
					</span>
					<span className={`save-btn ${this.state.incomplete ? 'incomplete' : ''}`} onClick={this.onSave}>
						{this.props.isEditMode ? 'Update Rule' : 'Save Rule'}
					</span>
				</div>
			</Drawer>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alertsNotifications }
}

const mapDispatchToProps = { ...actions }

export default connect(mapStateToProps, mapDispatchToProps, null)(AddRuleDrawer)
