import React from 'react'
import { Input } from 'antd'
import _ from 'lodash'

export default class DropdownOverlay extends React.Component {
	constructor(props) {
		super(props)
		this.node = React.createRef()
		this.state = {
			comment: ''
		}
	}
	componentDidMount() {
		if (_.get(this.props, 'editingCommentInfo.comment')) {
			this.setState({ comment: this.props.editingCommentInfo.comment })
		}
	}

	render() {
		const { editingMode, updateComment, addComment, alertId, commentId } = this.props
		return (
			<div ref={this.node} className="comment-dropdown-overlay" onClick={(e) => e.stopPropagation()}>
				<div className="header">{editingMode ? 'Edit Comment' : 'Add Comment'}</div>
				<div className="body">
					<Input
						allowClear
						value={this.state.comment}
						onChange={(e) => {
							this.setState({ comment: e.target.value })
						}}
					/>
				</div>

				<div className="footer">
					<span
						className="cancel-btn"
						onClick={() => {
							this.props.hideDropdown()
						}}
					>
						Cancel
					</span>
					<span
						onClick={() => {
							if (editingMode) {
								updateComment(this.state.comment, alertId, commentId)
							} else {
								addComment(this.state.comment, alertId)
							}
							this.props.hideDropdown()
						}}
						className="save-btn"
					>
						{editingMode ? 'Save Changes' : 'Save'}
					</span>
				</div>
			</div>
		)
	}
}
