import React, { Component } from 'react'
import { connect } from 'ui-lib'
import { withRouter } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import visibilityUtil from '../../../../../../util/Util'

import * as actions from '../../../store/actions'
import * as insightsActions from '../../../../../store/actions'
import * as alertsAction from '../../../../../../alerts/store/actions'
import * as visibilityActions from '../../../../../../store/actions'

import DetailCellRenderer from '../../../../../../alerts/components/CellRenderers/DetailCellRenderer/DetailCellRenderer'
import SeverityCellRenderer from '../../../../../../alerts/components/CellRenderers/SeverityCellRenderer'
import HostnameCellRenderer from '../../../../../../alerts/components/CellRenderers/HostnameCellRenderer'
import StatusCellRenderer from '../../../../../../alerts/components/CellRenderers/StatusCellRenderer/StatusCellRenderer'
import AlertNameCellRenderer from '../../../../../../alerts/components/CellRenderers/AlertNameCellRenderer'
import CommentCellRenderer from '../../../../../../alerts/components/CellRenderers/CommentCellRenderer/CommentCellRenderer'
import LastUpdateHeaderCellRenderer from '../../../../../../insights/components/CellRenderers/LastUpdatedHeaderCellRenderer/LastUpdateHeaderCellRenderer'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import './AlertList.scss'

import iconLoading from 'ui-lib/src/images/icon-loading.svg'

class AlertList extends Component {
	state = {
		editingCommentInfo: null,
		defaultColDef: { flex: 1 },
		schema: visibilityUtil.loadSchema('alerts'),
		frameworkComponents: {
			DetailCellRenderer,
			SeverityCellRenderer,
			StatusCellRenderer,
			AlertNameCellRenderer,
			CommentCellRenderer,
			LastUpdateHeaderCellRenderer,
			HostnameCellRenderer
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.deviceAlertsComments !== this.props.deviceAlertsComments ||
			prevProps.deviceAlertsEvents !== this.props.deviceAlertsEvents ||
			prevProps.deviceAlertsActivities !== this.props.deviceAlertsActivities
		) {
			if (this.gridApi) this.gridApi.redrawRows()
		}
	}

	onOpenDetails = (alertId) => {
		this.props.fetchDeviceAlertCommentsRequest(alertId)
		this.props.fetchDeviceAlertEventsRequest(alertId)
		this.props.fetchDeviceAlertActivitiesRequest(this.props.loggedInUser.email, alertId, alertId)
	}

	onGridReady = (params) => {
		this.gridApi = params.api
		this.gridColumnApi = params.columnApi
		this.gridApi.sizeColumnsToFit()
	}

	onGridResized = () => {
		if (this.gridApi) {
			this.gridApi.sizeColumnsToFit()
			this.gridApi.resetRowHeights()
		}
	}

	getMainMenuItems = (params) => {
		const itemsToExclue = ['autoSizeThis', 'autoSizeAll']
		const menu = params.defaultItems.filter((item) => !itemsToExclue.includes(item))
		return menu
	}

	getRowHeight = (params) => {
		if (params.node && params.node.detail) {
			const detailCell = document.getElementById(params.data.alert_id)
			if (detailCell) return detailCell.clientHeight + 1
			return 0
		}
		return 28
	}

	getRowStyle = (params) => {
		if (parseInt(params.node.id) % 2 === 0) {
			return { background: 'white' }
		} else {
			return { background: '#f4f5f5' }
		}
	}

	render() {
		const {
			loggedInUser,
			deviceAlerts,
			devicePageDesc,
			updateActiveApp,
			openInsightsTab,
			alertsMisc,
			metricsMisc,
			deviceAlertsComments,
			deviceAlertsEvents,
			deviceAlertsActivities,
			updateDeviceAlertRequest,
			addDeviceAlertCommentRequest,
			updateDeviceAlertCommentRequest,
			removeDeviceAlertCommentRequest
		} = this.props
		const fields = _.get(this.state, 'schema.alerts.content.main.fields', []).filter((field) => field.name !== 'hostname')
		const columns = _.map(fields, (fieldObj, i) => {
			const col = {
				lockPosition: true,
				lockVisible: true,
				flex: null,
				headerName: fieldObj.displayName,
				field: fieldObj.name,
				colId: fieldObj.name,
				pinned: fieldObj.pinned,
				resizable: false,
				hide: fieldObj.hidden,
				filterable: fieldObj.filterable,
				width: fieldObj.defaultWidth,
				minWidth: 80,
				suppressSizeToFit: true,
				valueFormatter: (params) => {
					if (['true', 'false', 'yes', 'no'].includes(params.value)) {
						return _.capitalize(params.value)
					} else {
						return params.value
					}
				}
			}

			if (col.field === 'severity') {
				col.cellRenderer = 'SeverityCellRenderer'
				col.cellRendererParams = {
					editingCommentInfo: this.state.editingCommentInfo,
					onOpenDetails: this.onOpenDetails,
					onCloseDetails: _.noop
				}
			}

			if (col.field === 'status') {
				col.cellRenderer = 'StatusCellRenderer'
				col.cellRendererParams = {
					assignee: loggedInUser.email,
					updateAlert: updateDeviceAlertRequest
				}
			}

			if (col.field === 'alert_name') {
				col.flex = 1
				col.resizable = true
				col.suppressSizeToFit = false
				col.cellRenderer = 'AlertNameCellRenderer'
				col.cellRendererParams = {
					openAlertsTab: (pageDesc) => {
						this.props.updateActiveApp('alerts')
						this.props.openAlertsTab(pageDesc)
						this.props.history.push('/')
					},
					devicesDetails: { [devicePageDesc.device]: { hostname: devicePageDesc.hostname } },
					closedAlertsLoading: false,
					fetchClosedAlerts: _.noop
				}
			}

			if (col.field === 'time') {
				col.cellRenderer = 'CommentCellRenderer'
				col.headerComponent = 'LastUpdateHeaderCellRenderer'
				col.cellRendererParams = {
					cancelEditing: (node) => {
						if (this.state.editingCommentInfo) {
							this.setState({ editingCommentInfo: null }, () => {
								if (this.gridApi) this.gridApi.redrawRows({ rowNodes: [node] })
							})
						}
					},
					editingCommentInfo: this.state.editingCommentInfo,
					addComment: addDeviceAlertCommentRequest,
					updateComment: updateDeviceAlertCommentRequest
				}
				col.lockPinned = true
			}

			return col
		})
		return (
			<div className="alert-list">
				{_.isEmpty(deviceAlerts) ? (
					<div className="info-area">No Data</div>
				) : (
					<div className={'master-detail-grid ag-theme-balham '} style={{ height: '100%' }}>
						<AgGridReact
							masterDetail={true}
							suppressDragLeaveHidesColumns={true}
							suppressPropertyNamesCheck={true}
							enableSorting={false}
							columnDefs={columns}
							defaultColDef={this.state.defaultColDef}
							getMainMenuItems={this.getMainMenuItems}
							detailCellRendererParams={{
								redirectToAppRoot: () => {
									this.props.history.push('/')
								},
								updateActiveApp,
								openInsightsTab,
								devicesDetails: { [devicePageDesc.device]: { hostname: devicePageDesc.hostname } },
								alertsMisc,
								metricsMisc,
								comments: deviceAlertsComments,
								events: deviceAlertsEvents,
								activities: deviceAlertsActivities,
								removeComment: removeDeviceAlertCommentRequest,
								editingCommentInfo: this.state.editingCommentInfo,
								setEditingCommentInfo: (info, node) => {
									this.setState({ editingCommentInfo: info }, () => {
										if (this.gridApi) this.gridApi.redrawRows({ rowNodes: [node] })
									})
								}
							}}
							detailCellRenderer={'DetailCellRenderer'}
							frameworkComponents={this.state.frameworkComponents}
							rowData={deviceAlerts}
							onGridReady={this.onGridReady}
							onGridSizeChanged={this.onGridResized}
							onModelUpdated={this.onModelUpdated}
							onRowClicked={this.onRowClicked}
							onCellClicked={this.onCellClicked}
							onColumnPinned={() => this.gridApi.redrawRows()}
							getRowHeight={this.getRowHeight}
							getRowStyle={this.getRowStyle}
						/>
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.devicePage, loggedInUser: state.main.loggedInUser }
}

const mapDispatchToProps = { ...actions, ...insightsActions, ...alertsAction, ...visibilityActions }

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null)(AlertList))
