import React, { Component } from 'react'
import iconList from 'ui-lib/src/images/icon-list.svg'
import { PopoverWidget as Popover } from 'ui-lib'
import util from '../../../utils/Util'

export default class ArrayTypeCell extends Component {
	constructor(props) {
		super(props)
		this._ref = React.createRef()
		this.state = {
			items: []
		}
	}

	componentDidMount() {
		const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data
		const value = util.computeQueryBuilderAccessor(data, this.props.colDef.fieldObj, true) || ''
		const arr = value.split('|')
		this.setState({ items: [...arr] })
	}

	render() {
		// If less than two options, show it directly
		if (this.state.items.length < 1) {
			return <div />
		} else if (this.state.items.length === 1) {
			return <div>{this.state.items[0]}</div>
		}

		// More than one options, show dropdown
		const content = (
			<>
				{this.state.items.map((item) => (
					<div>{item}</div>
				))}
			</>
		)

		return (
			<Popover prefixCls="cell-popover" content={content} trigger="hover" placement="bottom">
				<img className="list-btn" src={iconList} alt="List" />
				<span>{this.state.items[0]}</span>
			</Popover>
		)
	}
}
