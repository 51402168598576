import React from 'react'
import enableIcon from '../../../../images/icon-enable.svg'
import disableIcon from '../../../../images/icon-disable.svg'

import './EnableCellRenderer.scss'

export default class CellRenderer extends React.Component {
	render() {
		const { data, onDisable, onEnable } = this.props
		if (data.statusUpdating) {
			return <div>Updating...</div>
		} else if (data.pause_start_time > 0 || data.pause_end_time > 0) {
			return (
				<div className="disable-cell">
					<img onClick={() => onEnable(data)} src={disableIcon} alt="disable icon" className="disable-icon" /> Disabled
				</div>
			)
		} else {
			return (
				<div className="enable-cell">
					<img onClick={() => onDisable(data)} src={enableIcon} alt="enable icon" className="enable-icon" /> Enabled
				</div>
			)
		}
	}
}
