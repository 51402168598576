import React, { Component } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { connect } from 'ui-lib'
import { updateAlertsSchema } from '../../../../../store/actions'
import visibilityUtil from '../../../../../../util/Util'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-enterprise'

class MasterDetailGridWidget extends Component {
	render() {
		return (
			<div className={'master-detail-grid ag-theme-balham ' + (this.props.className || '')} style={{ height: '100%' }}>
				<AgGridReact {...this.props} masterDetail={true} scrollbarWidth={0} suppressDragLeaveHidesColumns={true} suppressPropertyNamesCheck={true} />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(mapStateToProps)(MasterDetailGridWidget)
