import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of, forkJoin, concat } from 'rxjs'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import Util from '../utils/Util'
import Constants from '../utils/Constants.json'
import pageInfo from '../pages/pageUtil'
import visibilityUtil from '../../util/Util'

import * as actionTypes from './actionTypes'
import * as services from './services'
import * as actions from './actions'

// This is only for preference
import * as visibilityActions from '../../store/actions'
import * as mainActions from '../../../main/actions'

/*--------- Insights Initialization ----------*/
export const initInsightsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.INIT_INSIGHTS),
		switchMap(() => {
			// Check if it's back from explore
			if (store.value.insights.finishedInsightsInitTasks.count === 0) {
				// Reset Pref
				const userPref = _.get(store.value.main.loggedInUser, 'preference.insights') ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
				_.set(userPref, 'insights.activeTab', 'insights.devices')
				_.set(userPref, 'insights.fixed_tabs', [
					{ id: Constants.FIXED_TABS[0], state: null, pageType: pageInfo.InsightsMain.pageType },
					{ id: Constants.FIXED_TABS[1], state: null, pageType: pageInfo.InsightsMain.pageType }
				])
				_.set(userPref, 'insights.custom_tabs', [])
				// Dispatch actions in the following order
				return concat(
					// of(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref)),
					of(actions.initInsightsSchema(), actions.fetchAutocompleteRequest(), actions.initInsightsTabsState())
				)
			} else {
				return of()
			}
		})
	)
}

export const initInsightsTabsStateEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.INIT_INSIGHTS_TABS_STATE),
		switchMap(() => {
			let activeInsightsTab, fixed_tabs, custom_tabs
			let updatePref = false
			const userPref = _.get(store.value.main.loggedInUser, 'preference') ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}

			// 1. Check if user pref exists

			if (_.get(userPref, 'insights')) {
				// 1.1 Restore Tabs State
				activeInsightsTab = _.get(userPref, 'insights.activeTab')
				fixed_tabs = _.get(userPref, 'insights.fixed_tabs')
				custom_tabs = _.get(userPref, 'insights.custom_tabs')

				if (!fixed_tabs || fixed_tabs.length < 2 || fixed_tabs.some((tab) => tab === null)) {
					fixed_tabs = [
						{ id: Constants.FIXED_TABS[0], state: null, pageType: pageInfo.InsightsMain.pageType },
						{ id: Constants.FIXED_TABS[1], state: null, pageType: pageInfo.InsightsMain.pageType }
					]
					updatePref = true
				}

				if (!custom_tabs) {
					custom_tabs = []
					_.set(userPref, 'insights.custom_tabs', custom_tabs)
					updatePref = true
				}

				if (_.isString(activeInsightsTab)) {
					if (!Constants.FIXED_TABS.includes(activeInsightsTab)) {
						activeInsightsTab = Constants.FIXED_TABS[0]
					}
				} else if (_.isInteger(activeInsightsTab)) {
					if (activeInsightsTab < 0 || activeInsightsTab > custom_tabs.length - 1) {
						activeInsightsTab = Constants.FIXED_TABS[0]
					}
				} else {
					activeInsightsTab = Constants.FIXED_TABS[0]
				}

				if (activeInsightsTab !== _.get(userPref, 'insights.activeTab')) {
					_.set(userPref, 'insights.activeTab', activeInsightsTab)
					updatePref = true
				}
			} else {
				// 1.2 Init Tabs State
				updatePref = true
				activeInsightsTab = Constants.FIXED_TABS[0]
				fixed_tabs = [
					{ id: Constants.FIXED_TABS[0], state: null, pageType: pageInfo.InsightsMain.pageType },
					{ id: Constants.FIXED_TABS[1], state: null, pageType: pageInfo.InsightsMain.pageType }
				]
				custom_tabs = []
			}

			// 2. Check if url carrys tab info
			const tabFromUrl = Util.constructTabFromUrl()
			if (tabFromUrl) {
				updatePref = true
				const id = _.get(tabFromUrl, 'id')
				if (id && Constants.FIXED_TABS.includes(id)) {
					activeInsightsTab = tabFromUrl.id
					if (id === Constants.FIXED_TABS[2]) {
						fixed_tabs[2] = { id: Constants.FIXED_TABS[2], desc: { device: tabFromUrl.desc.device, hostname: tabFromUrl.desc.hostname } }
					}
				} else {
					// Open Tab From URL
					/***
					 * 1. If activeInsightsTab info in pref matches info from URL, restore pref
					 * 2. If not, do a search, switch to the first one found.
					 * 3. If not found, create a new tab.
					 * 4. If tab from url is the sixth tab, still open it but add warning notification.
					 */
					const prefTabIds = _.get(userPref, `insights.custom_tabs[${activeInsightsTab}].desc`) || _.get(userPref, `insights.custom_tabs[${activeInsightsTab}].ids`)
					if (!_.isEqual(tabFromUrl.desc, prefTabIds)) {
						const tabMatchURL = custom_tabs.findIndex((tab) => _.isEqual(tab.desc, tabFromUrl.desc))
						if (tabMatchURL >= 0) {
							activeInsightsTab = tabMatchURL
						} else {
							activeInsightsTab = custom_tabs.length
							custom_tabs.push(tabFromUrl)
						}
					}
				}
			}

			// Reconstruct Pref Object
			fixed_tabs.forEach((tab) => {
				if (tab) {
					delete tab.lastRefresh
					if (!_.get(tab, 'pageType')) {
						tab.pageType = pageInfo.InsightsMain.pageType
					}
				}
			})
			custom_tabs.forEach((tab) => {
				delete tab.lastRefresh
				if (!_.get(tab, 'pageType')) {
					const metric = _.get(tab, 'ids.metric')
					if (_.isString(metric)) {
						if (metric in Constants.TABLE_CHART_INFO_MAP) {
							tab.pageType = pageInfo.TableMetricDetails.pageType
						} else {
							tab.pageType = pageInfo.LineMetricDetails.pageType
						}
					}
				}
				if (_.get(tab, 'ids')) {
					tab.desc = _.cloneDeep(tab.ids)
					delete tab.ids
				}
			})

			let actionList = []
			if (updatePref) {
				_.set(userPref, 'insights.activeTab', activeInsightsTab)
				_.set(userPref, 'insights.fixed_tabs', fixed_tabs)
				_.set(userPref, 'insights.custom_tabs', custom_tabs)
				actionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}

			const tabs = { fixed_tabs: _.cloneDeep(fixed_tabs), custom_tabs: _.cloneDeep(custom_tabs) }
			tabs.fixed_tabs.forEach((tab) => {
				if (tab !== null) {
					tab.lastRefresh = null
				}
			})
			tabs.custom_tabs.forEach((tab) => {
				tab.lastRefresh = null
				tab.id = uuid()
			})

			actionList.push(actions.updateInsightsTabsState(tabs))

			if (custom_tabs.length > 5) {
				actionList.push(
					visibilityActions.addNotification({
						type: 'warning',
						message: 'Maximum tab limit reached (5). Please close unused tabs.'
					})
				)
			}
			// return concat(of(actionList))
			return concat(of(...actionList), of(actions.insightsInitTaskComplete('init_tabs')), of(actions.updateInsightsActiveTab(activeInsightsTab)))
		})
	)
}

/*--------- Tab Operations ----------*/
export const openDevicePageEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.OPEN_INSIGHTS_DEVICE_DETAILS),
		switchMap((action) => {
			let tabs = store.value.insights.insightsTabs
			const tab = {
				pageType: 'DeviceDetails',
				desc: { device: action.pageDesc.serial, hostname: action.pageDesc.hostname },
				id: 'insights.devicedetails'
			}
			tabs.fixed_tabs[2] = tab

			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const fixed_tabs_pref = _.get(userPref, 'insights.fixed_tabs', [])
			fixed_tabs_pref[2] = _.omit(_.cloneDeep(tab), 'id')
			_.set(userPref, `insights.fixed_tabs`, fixed_tabs_pref)
			return concat(
				of(actions.resetStore('DeviceDetails')),
				of(actions.updateInsightsTabsState(tabs), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME)),
				of(actions.updateInsightsActiveTab('insights.devicedetails'))
			)
		})
	)
}

export const openInsightsTabEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.OPEN_INSIGHTS_TAB),
		switchMap((action) => {
			let tabs = store.value.insights.insightsTabs
			if (tabs.custom_tabs.length >= 5) {
				return of(
					visibilityActions.addNotification({
						type: 'warning',
						message: 'Maximum tab limit reached (5). Please close unused tabs.'
					})
				)
			}
			const pageType = action.pageDesc.metric in Constants.TABLE_CHART_INFO_MAP ? 'TableMetricDetails' : 'LineMetricDetails'
			const tab = {
				pageType,
				desc: { device: action.pageDesc.serial, metric: action.pageDesc.metric, hostname: action.pageDesc.hostname },
				state: { ..._.cloneDeep(pageInfo[pageType].pageDefaultSettings) },
				id: uuid()
			}

			// 1. Cache current tab
			const currentTabState = Util.getTabState(tabs, store.value.insights.activeInsightsTab)
			const { storeKey, cacheUtil } = pageInfo[currentTabState.pageType]
			const pageCache = cacheUtil(store.value[storeKey], store.value.insights.activeInsightsTab)
			tabs = Util.setTabState(tabs, store.value.insights.activeInsightsTab, pageCache)
			// 2. Change activeInsightsTab to the newly added tab
			const activeInsightsTab = tabs.custom_tabs.length
			// 3. Append a new tab to the end of tabs.custom_tabs
			tabs.custom_tabs.push(tab)
			// 4. update pref object
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const custom_tabs_pref = _.get(userPref, 'insights.custom_tabs')
			const tabPref = _.omit(_.cloneDeep(tab), 'id')
			tabPref.desc = {
				device: _.get(tabPref.desc, 'device'),
				metric: _.get(tabPref.desc, 'metric'),
				hostname: _.get(tabPref.desc, 'hostname')
			}
			custom_tabs_pref.push(tabPref)
			_.set(userPref, `insights.custom_tabs`, custom_tabs_pref)

			return concat(
				of(actions.updateInsightsTabsState(tabs), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME)),
				of(actions.updateInsightsActiveTab(activeInsightsTab))
			)
		})
	)
}

export const switchInsightsTabEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.SWITCH_INSIGHTS_TAB),
		mergeMap((action) => {
			const { from, to } = action
			if (from === to) return of()
			let { insightsTabs } = store.value.insights
			const fromTabState = Util.getTabState(insightsTabs, from)
			// cache action.from tab
			const { storeKey, cacheUtil } = pageInfo[fromTabState.pageType]
			const pageCache = cacheUtil(store.value[storeKey], from)
			insightsTabs = Util.setTabState(insightsTabs, from, pageCache)
			return concat(of(actions.resetStore(fromTabState.pageType)), of(actions.updateInsightsTabsState(insightsTabs)), of(actions.updateInsightsActiveTab(to)))
		})
	)
}

export const closeInsightsTabEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.CLOSE_INSIGHTS_TAB),
		mergeMap((action) => {
			// 1. Remove cache
			let { insightsTabs, activeInsightsTab } = store.value.insights
			insightsTabs = { ...insightsTabs }
			const custom_tabs = [...insightsTabs.custom_tabs]
			const pageType = custom_tabs[action.index].pageType
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			let custom_tabs_pref = _.get(userPref, 'insights.custom_tabs')
			_.pullAt(custom_tabs, action.index)
			_.pullAt(custom_tabs_pref, action.index)
			_.set(userPref, 'insights.custom_tabs', custom_tabs_pref)
			insightsTabs.custom_tabs = custom_tabs
			// 2. Calculate next tab, switch to next tab
			let nextTab
			if (Constants.FIXED_TABS.includes(activeInsightsTab)) {
				nextTab = activeInsightsTab
			} else {
				if (custom_tabs.length === 0) {
					// 1. closed tab is the only tab
					nextTab = Constants.FIXED_TABS[0]
				} else if (action.index < activeInsightsTab) {
					// 2. closed tab is prior to active tab
					nextTab = activeInsightsTab - 1
				} else {
					// 3. closed tab is after active tab
					nextTab = activeInsightsTab
					if (custom_tabs.length === action.index && activeInsightsTab === action.index) {
						// 3.1 closed tab is the last tab, and it's the active tab
						nextTab = activeInsightsTab - 1
					}
				}
			}
			// If tab to close is not current tab, do not reset
			if (activeInsightsTab !== action.index) {
				return concat(
					of(actions.updateInsightsTabsState(insightsTabs), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME)),
					of(actions.updateInsightsActiveTab(nextTab))
				)
			} else {
				return concat(
					of(actions.resetStore(pageType)),
					of(actions.updateInsightsTabsState(insightsTabs), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME)),
					of(actions.updateInsightsActiveTab(nextTab))
				)
			}
		})
	)
}

export const cacheInsightsTabEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.CACHE_INSIGHTS_TAB),
		mergeMap((action) => {
			let { insightsTabs } = store.value.insights
			const tabState = Util.getTabState(insightsTabs, action.index)
			// cache action.index tab
			const { storeKey, cacheUtil } = pageInfo[tabState.pageType]
			const pageCache = cacheUtil(store.value[storeKey], action.index)
			insightsTabs = Util.setTabState(insightsTabs, action.index, pageCache)
			return of(actions.updateInsightsTabsState(insightsTabs))
		})
	)
}

export const clearInsightsDataCacheEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.CLEAR_INSIGHTS_DATA_CACHE),
		mergeMap((action) => {
			let tabs = _.cloneDeep(store.value.insights.insightsTabs)
			const currentTab = store.value.insights.activeInsightsTab
			const currentTabState = Util.getTabState(tabs, currentTab)
			// cache current tab
			const { storeKey, cacheUtil } = pageInfo[currentTabState.pageType]
			const pageCache = cacheUtil(store.value[storeKey], currentTab)
			tabs = Util.setTabState(tabs, currentTab, pageCache)
			tabs.fixed_tabs.forEach((tab) => {
				if (tab !== null) {
					tab.lastRefresh = null
					tab.isDataStale = null
					tab.data = null
				}
			})
			tabs.custom_tabs.forEach((tab) => {
				tab.lastRefresh = null
				tab.isDataStale = null
				tab.data = null
			})
			let pageType
			if (Constants.FIXED_TABS.includes(store.value.insights.activeInsightsTab)) {
				pageType = pageInfo.InsightsMain.pageType
			} else {
				pageType = tabs.custom_tabs[store.value.insights.activeInsightsTab].pageType
			}
			if (store.value.visibility.activeApp !== 'insights') {
				return concat(of(actions.updateInsightsTabsState(tabs)), of(actions.resetStore(pageType)))
			}
			return concat(of(actions.updateInsightsTabsState(tabs)), of(actions.resetStore(pageType)), of(actions.refreshInsightsTab(pageType)))
		})
	)
}

export const updateInsightsActiveTabEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_INSIGHTS_ACTIVE_TAB),
		mergeMap((action) => {
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const activeInsightsTabPref = _.get(userPref, 'insights.activeTab')
			if (activeInsightsTabPref === action.index) {
				return of()
			} else {
				_.set(userPref, 'insights.activeTab', action.index)
				return of(mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME))
			}
		})
	)
}

/*--------- Schema Operations ----------*/
export const initInsightsSchemaEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.INIT_INSIGHTS_SCHEMA),
		switchMap(() => {
			let schema = _.omit(visibilityUtil.loadSchema('insights'), ['version', 'super'])
			// Merge with Pref
			const schemaPref = _.get(store.value.main.loggedInUser.preference, 'insights.schema')
			schema = visibilityUtil.mergeSchemaWithPref(schema, schemaPref)
			return concat(of(actions.setInsightsSchema(schema)), of(actions.insightsInitTaskComplete('schema')))
		})
	)
}

export const updateInsightsSchemaEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_INSIGHTS_SCHEMA),
		mergeMap((action) => {
			const { preference } = action
			let { insightsSchema: schema } = store.value.insights
			let schemaPref = _.get(store.value.main.loggedInUser.preference, 'insights.schema')
			let [updatedSchema, updatedSchemaPref] = visibilityUtil.updateSchemaOnChange(schema, schemaPref, preference)
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			_.set(userPref, 'insights.schema', updatedSchemaPref)
			return of(actions.setInsightsSchema(updatedSchema), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME))
		})
	)
}

export const resetInsightsSchemaEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.RESET_INSIGHTS_SCHEMA),
		mergeMap((action) => {
			let { insightsSchema: updatedSchema } = store.value.insights
			let schema = _.omit(visibilityUtil.loadSchema('insights'), ['version', 'super'])
			// Remove Pref
			_.set(updatedSchema, `insights.content.${action.schemaType}`, _.get(schema, `insights.content.${action.schemaType}`))
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			_.set(userPref, `insights.schema.insights.${action.schemaType}.fields`, _.get(schema, `insights.content.${action.schemaType}.fields`))
			return of(actions.setInsightsSchema({ ...updatedSchema }), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME))
		})
	)
}

/*--------- Fetch Autocomplete ----------*/
export const fetchAutocompleteEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_AUTOCOMPLETE_REQUEST),
		switchMap((action) => {
			if (action.field) {
				const suggestionsContext = store.value.insights.valueSuggestions[action.field]
				return of(actions.pollInsightsJobRequest(suggestionsContext.jobId, 'autocomplete', suggestionsContext.jobPage + 1, null, { field: action.field }))
			}
			return services.getAutocomplete(store.value).pipe(
				mergeMap(({ response }) => {
					const jobList = []
					// If field is not set, initialize valueSuggestions
					Object.keys(response).map((field) => {
						if (response[field].jobId) {
							store.value.insights.valueSuggestions[field] = { suggestions: [], jobId: response[field].jobId, jobPage: -1, nomore: false }
							jobList.push(field)
						}
					})

					return jobList.map((field) => actions.pollInsightsJobRequest(response[field].jobId, 'autocomplete', store.value.insights.valueSuggestions[field].jobPage + 1, null, { field }))
				}),
				catchError((error) => of(actions.fetchAutocompleteFailure(error)))
			)
		})
	)
}

/*--------- Poll Job ----------*/
export const pollInsightsJobEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.POLL_INSIGHTS_JOB_REQUEST),
		mergeMap((action) => {
			const pageNumber = action.jobPage
			return services.pollInsightsJob(store.value, action.jobId, pageNumber).pipe(
				mergeMap(({ response }) => {
					if (response.state === 'RUNNING') {
						return of(actions.pollInsightsJobRequest(response.jobId, action.jobType, action.jobPage, action.jobLimit, action.params))
					}
					// If it's done, handle response accroding to action type
					else {
						if (action.jobType === 'autocomplete') {
							return concat(of(actions.fetchAutocompleteSuccess(response.page.result.data, action.params.field, response.rowsInJob))) //, of(actions.insightsInitTaskComplete('autocomplete')))
						}
					}
				}),
				catchError((error) => {
					if (action.jobType === 'autocomplete') {
						return of(actions.fetchAutocompleteFailure(error))
					}
				})
			)
		})
	)
}
