import React, { Component } from 'react'
import LogTable from './LogTable/LogTable'
import LogDetailPanel from './LogDetail/LogDetailPanel'
import LogExportPanel from './LogExport/LogExportPanel'
import './LogViewerBody.scss'

class LogViewerBody extends Component {
	render() {
		return (
			<>
				<LogTable onOpenLogDetailPanel={this.props.onOpenLogDetailPanel} {...this.props} />
				<LogDetailPanel visible={this.props.detailVisible} {...this.props} />
				<LogExportPanel {...this.props} />
			</>
		)
	}
}

export default LogViewerBody
