import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of, concat } from 'rxjs'
import _ from 'lodash'

import Constants from '../../../utils/Constants.json'
import Util from '../../../utils/Util'

import * as services from '../../../store/services'
import * as actionTypes from './actionTypes'
import * as actions from './actions'
import * as alertsActions from '../../../store/actions'
import * as mainActions from '../../../../../main/actions'

import pageInfo from '../../pageUtil'

export const fetchAlertsNotificationsPageContentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERTS_NOTIFICATIONS_PAGE_CONTENT, actionTypes.REFRESH_ALERTS_TAB),
		switchMap((action) => {
			if (action.type === actionTypes.REFRESH_ALERTS_TAB && action.pageType !== pageInfo.AlertsNotifications.pageType) {
				return of()
			}
			const { alertsTabs, activeAlertsTab } = store.value.alerts
			const currentTab = alertsTabs.fixed_tabs[Constants.FIXED_TABS.indexOf(activeAlertsTab)]
			const { state: cachedState, data: cachedData } = currentTab
			// 1. Restore State
			const stateActionList = []
			if (!_.isEmpty(cachedState)) {
				// No state cache for alerts notifications for now
			}

			// 2. Restore Data
			const dataActionList = [actions.fetchEmailTemplatesRequest(), actions.fetchConfigRequest()]
			if (!_.isEmpty(cachedData)) {
				const { rules } = cachedData
				dataActionList.push(actions.restoreRules({ rules }))
				return concat(of(alertsActions.resetStore('AlertsNotifications')), of(...stateActionList), of(...dataActionList))
			} else {
				dataActionList.push(actions.fetchRulesRequest())
				return concat(of(alertsActions.resetStore('AlertsNotifications')), of(alertsActions.updateAlertsLastRefresh(activeAlertsTab)), of(...stateActionList), of(...dataActionList))
			}
		})
	)
}

export const fetchRulesEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_RULES_REQUEST),
		switchMap((action) => {
			return services.getRules(store.value).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response)) {
							// 1. Response is empty
							return concat(of(actions.fetchRulesSuccess([])))
						} else {
							// 2. Response is not empty}
							return concat(of(actions.fetchRulesSuccess(response.response)))
						}
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.fetchRulesFailure(error))
				})
			)
		})
	)
}

export const addRuleEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.ADD_RULE_REQUEST),
		switchMap((action) => {
			return services.addRule(store.value, action.ruleDef).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return of(actions.fetchRulesRequest())
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.addRuleFailure(error))
				})
			)
		})
	)
}

export const updateRuleEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_RULE_REQUEST),
		mergeMap((action) => {
			return services.updateRule(store.value, action.ruleId, action.ruleDef).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return of(actions.updateRuleSuccess(response.response))
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.updateRuleFailure(error))
				})
			)
		})
	)
}

export const removeRuleEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.REMOVE_RULE_REQUEST),
		mergeMap((action) => {
			return services.removeRule(store.value, action.ruleId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return of(actions.removeRuleSuccess(response.response))
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.removeRuleFailure(error))
				})
			)
		})
	)
}

/*--------------- Email Template ---------------*/

export const fetchEmailTemplatesEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_EMAIL_TEMPLATES_REQUEST),
		switchMap((action) => {
			return services.getEmailTemplates(store.value).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response)) {
							// 1. Response is empty
							return of(actions.fetchEmailTemplatesSuccess([]))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchEmailTemplatesSuccess(response.response))
						}
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.fetchEmailTemplatesFailure(error))
				})
			)
		})
	)
}

export const addEmailTemplateEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.ADD_EMAIL_TEMPLATE_REQUEST),
		switchMap((action) => {
			return services.addEmailTemplate(store.value, action.template).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return of(actions.fetchEmailTemplatesRequest())
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.addEmailTemplateFailure(error))
				})
			)
		})
	)
}

export const updateEmailTemplateEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_EMAIL_TEMPLATE_REQUEST),
		switchMap((action) => {
			return services.updateEmailTemplate(store.value, action.templateId, action.template).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return of(actions.fetchEmailTemplatesRequest())
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.udpateEmailTemplateFailure(error))
				})
			)
		})
	)
}

export const removeEmailTemplateEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.REMOVE_EMAIL_TEMPLATE_REQUEST),
		switchMap((action) => {
			return services.removeEmailTemplate(store.value, action.templateId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return of(actions.fetchEmailTemplatesRequest())
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.udpateEmailTemplateFailure(error))
				})
			)
		})
	)
}

/*------------- Config ---------------*/
export const fetchConfigEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_CONFIG_REQUEST),
		switchMap((action) => {
			return services.getConfig(store.value).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return concat(of(actions.fetchConfigSuccess(response.response)), of(actions.fetchSNTemplatesRequest()))
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.fetchConfigFailure(error))
				})
			)
		})
	)
}

export const addConfigEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.ADD_CONFIG_REQUEST),
		switchMap((action) => {
			return services.addConfig(store.value, action.config).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return of(actions.addConfigSuccess(response.response))
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.addConfigFailure(error))
				})
			)
		})
	)
}

/*--------------- Service Now Template ---------------*/

export const fetchSNTemplatesEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_SN_TEMPLATES_REQUEST),
		switchMap((action) => {
			return services.getSNTemplates(store.value).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response)) {
							// 1. Response is empty
							return of(actions.fetchSNTemplatesSuccess([]))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchSNTemplatesSuccess(response.response))
						}
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.fetchSNTemplatesFailure(error))
				})
			)
		})
	)
}

export const addSNTemplateEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.ADD_SN_TEMPLATE_REQUEST),
		switchMap((action) => {
			return services.addSNTemplate(store.value, action.template).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return of(actions.fetchSNTemplatesRequest())
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.addSNTemplateFailure(error))
				})
			)
		})
	)
}

export const updateSNTemplateEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_SN_TEMPLATE_REQUEST),
		switchMap((action) => {
			return services.updateSNTemplate(store.value, action.templateId, action.template).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return of(actions.fetchSNTemplatesRequest())
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.udpateSNTemplateFailure(error))
				})
			)
		})
	)
}

export const removeSNTemplateEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.REMOVE_SN_TEMPLATE_REQUEST),
		switchMap((action) => {
			return services.removeSNTemplate(store.value, action.templateId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return of(actions.fetchSNTemplatesRequest())
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.udpateSNTemplateFailure(error))
				})
			)
		})
	)
}

export const fetchSNGroupsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_SN_GROUPS_REQUEST),
		switchMap((action) => {
			return services.getSNGroups(store.value).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response.result)) {
							// 1. Response is empty
							return of(actions.fetchSNGroupsSuccess([]))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchSNGroupsSuccess(response.response.result))
						}
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.fetchSNGroupsFailure(error))
				})
			)
		})
	)
}

export const fetchSNGroupUsersEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_SN_GROUPUSERS_REQUEST),
		switchMap((action) => {
			return services.getSNGroupUsers(store.value, action.groupId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response.result)) {
							// 1. Response is empty
							return of(actions.fetchSNGroupUsersSuccess([]))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchSNGroupUsersSuccess(response.response.result))
						}
					} else {
						throw 'Error'
					}
				}),
				catchError((error) => {
					return of(actions.fetchSNGroupUsersFailure(error))
				})
			)
		})
	)
}
