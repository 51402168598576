import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'ui-lib'

import AlertsMain from './pages/AlertsMain/AlertsMain'
import AlertDetails from './pages/AlertDetails/AlertDetails'
import AlertsNotifications from './pages/AlertsNotifications/AlertsNotifications'

import * as actions from './store/actions'
import * as visibilityActions from '../store/actions'

import Constants from './utils/Constants.json'
import Util from './utils/Util'
import visUtil from '../util/Util'

import AlertsNav from './components/AlertsNav/AlertsNav'
import './Alerts.scss'

class Alerts extends Component {
	constructor(props) {
		super(props)
		this.state = {
			staleStatusTimeout: null
		}

		this.updateURL()
	}

	componentDidMount() {
		this.props.updateActiveApp('alerts')
		window.addEventListener('popstate', this.backURL)
		this.updateURL()
	}

	componentDidUpdate(prevProps) {
		// Track if tab data is stale
		const prevLastRefresh = visUtil.getLastRefreshOfActiveTab(prevProps)
		const currentLastRefresh = visUtil.getLastRefreshOfActiveTab(this.props)
		if (currentLastRefresh && prevLastRefresh !== currentLastRefresh) {
			this.staleTabTracker()
		}
		this.updateURL()
	}

	componentWillUnmount() {
		if (this.state.staleStatusTimeout) {
			clearTimeout(this.state.staleStatusTimeout)
		}
		this.props.cacheAlertsTab(this.props.activeAlertsTab) // Cache current running tab before switch to different app
		window.removeEventListener('popstate', this.backURL)
	}

	staleTabTracker = () => {
		if (this.state.staleStatusTimeout) {
			clearTimeout(this.state.staleStatusTimeout)
		}
		// let timeout = 5 * 1000
		let timeout = Constants.TAB_TIMEOUT * 60 * 60 * 1000
		if (this.props.alertsTabs) {
			const lastRefresh = visUtil.getLastRefreshOfActiveTab(this.props)
			// timeout = lastRefresh.clone().add(5, 's').diff(moment(), 'milliseconds')
			timeout = moment(lastRefresh).clone().add(Constants.TAB_TIMEOUT, 'h').diff(moment(), 'milliseconds')
		}
		if (timeout > 0) {
			this.setState({
				staleStatusTimeout: setTimeout(() => {
					this.props.updateAlertsStaleStatus(this.props.activeAlertsTab, true)
				}, timeout)
			})
		} else {
			this.props.updateAlertsStaleStatus(this.props.activeAlertsTab, true)
		}
	}

	backURL = () => {
		history.pushState(null, null, `/alerts?type=main`)
	}

	updateURL = () => {
		if (this.props.activeAlertsTab === null) return
		let pathname = ''
		const { activeAlertsTab, alertsTabs } = this.props
		if (activeAlertsTab !== null) {
			if (activeAlertsTab === Constants.FIXED_TABS[0]) {
				pathname = `type=main`
			} else if (activeAlertsTab === Constants.FIXED_TABS[1]) {
				pathname = `type=notifications`
			} else {
				const { custom_tabs } = this.props.alertsTabs
				const activeTabInfo = custom_tabs[activeAlertsTab]
				pathname = `type=details&alertname=${Util.encode(activeTabInfo.desc.alertname)}&alertid=${Util.encode(activeTabInfo.desc.alertid)}&hostname=${Util.encode(activeTabInfo.desc.hostname)}`
			}
		}

		history.pushState(null, null, `/alerts/?${pathname}`)
	}
	render() {
		const { activeAlertsTab, alertsTabs, switchAlertsTab } = this.props
		let pageContent
		if (Constants.FIXED_TABS[0] === activeAlertsTab) {
			pageContent = <AlertsMain />
		} else if (Constants.FIXED_TABS[1] === activeAlertsTab) {
			pageContent = <AlertsNotifications />
		} else {
			pageContent = <AlertDetails />
		}
		return (
			<div id="alerts-app">
				{Constants.FIXED_TABS.includes(activeAlertsTab) ? (
					<>
						<div className="alerts-header">
							<div className="title">Alerts</div>
						</div>
						<AlertsNav
							activeAlertsTab={activeAlertsTab}
							items={[
								{ name: 'Alert List', index: Constants.FIXED_TABS[0], onItemClicked: () => switchAlertsTab(activeAlertsTab, Constants.FIXED_TABS[0]) },
								{ name: 'Notification Rules', index: Constants.FIXED_TABS[1], onItemClicked: () => switchAlertsTab(activeAlertsTab, Constants.FIXED_TABS[1]) }
							]}
						/>
						<div />
					</>
				) : null}
				{pageContent}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alerts, ...state.visibility }
}

const mapDispatchToProps = { ...actions, ...visibilityActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(Alerts)
