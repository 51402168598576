import React from 'react'

import tupleSearch from '../util/tupleSearch.js'
import flattenArray from '../util/flattenArray.js'

import critical_device from '../resources/critical-device.svg'
import warning_device from '../resources/warning-device.svg'
import healthy_device from '../resources/healthy-device.svg'

import { scaleLinear } from 'd3-scale'

export default function renderExpandedElement(o, i) {
	this.flattenArray = flattenArray.bind(this)
	const offsetShiftScaleX = scaleLinear().domain([0.69, 2.65]).range([48, 56])
	const offsetShiftScaleY = scaleLinear().domain([0.69, 2.65]).range([64, 42])
	const offsets = [offsetShiftScaleX(this.state.zoom), offsetShiftScaleY(this.state.zoom)]

	let offsetTranslate = [0, 0]
	if (o.object3D === undefined || o.object3D.scale.x === 0) {
		return
	}
	if (o.targetP.x > 0) {
		if (o.targetP.y > 0) {
			// is [+,+] needs [-,-]
			offsetTranslate[0] = -(offsets[0] + 12 * this.state.zoom)
			offsetTranslate[1] = -(offsets[1] + 12 * this.state.zoom)
		} else {
			// is [+,-] needs [-,+]
			offsetTranslate[0] = -(offsets[0] + 12 * this.state.zoom)
			offsetTranslate[1] = +(offsets[1] + 12 * this.state.zoom)
		}
	} else {
		// x <= 0
		if (o.targetP.y > 0) {
			// is [-,+] needs [+,-]
			offsetTranslate[0] = +(offsets[0] + 12 * this.state.zoom)
			offsetTranslate[1] = -(offsets[1] + 12 * this.state.zoom)
		} else {
			// is [-,-] needs [+,+]
			offsetTranslate[0] = +(offsets[0] + 12 * this.state.zoom)
			offsetTranslate[1] = +(offsets[1] + 12 * this.state.zoom)
		}
	}

	return (
		<g
			onClick={() => {
				if (this.props.lockedElements.indexOf(o.id) === -1) {
					this.props.lockedElements.push(o.id)
				} else {
					const newArray = this.props.lockedElements.filter((_id) => {
						return o.id !== _id
					})
					while (this.props.lockedElements.length > 0) {
						this.props.lockedElements.pop()
					}
					newArray.forEach((o) => {
						this.props.lockedElements.push(o)
					})
				}
				this.forceUpdate()
				this.props.onClick(o.data)
			}}
			onMouseOut={() => {
				this.props.onMouseOut(o.data)
			}}
			key={'expandedElement' + i}
			transform={['translate(', o.targetP.x, o.targetP.y, ')'].join(' ')}
			style={{ cursor: 'pointer' }}
		>
			<g className="expander-grow">
				<g transform={['scale(', 1.67 / this.state.zoom, ')'].join(' ')}>
					<g transform={['translate(', offsetTranslate.join(','), ')'].join(' ')}>
						{/* hexagon outline and shape */}
						<use transform="translate(-90.5 -79)" xlinkHref={this.flattenArray(this.props.assets)[tupleSearch(o.data.attributes, 'severity').value].expanded.img} />
						{/* top label */}
						<text letterSpacing="0.2px" fontSize="9px" fill="#333333" fontWeight="bold" transform="translate(-19 -60)">
							{this.props.metricsMode === true ? 'DEVICES' : 'METRICS'}
						</text>
						{/* status icon list */}
						<g transform="translate(-44 0)">
							{[
								{ href: critical_device, attribute: 'critical', xOffset: 0, yOffset: -54 },
								{ href: warning_device, attribute: 'warning', xOffset: 36, yOffset: -54 },
								{ href: healthy_device, attribute: 'healthy', xOffset: 72, yOffset: -54 }
							].map((_o, idx) => {
								return (
									<g key={'key_' + idx + o.id} transform={'translate(' + _o.xOffset + ' ' + _o.yOffset + ')'}>
										<image transform="translate(0 0)" href={_o.href} />
										<text x="8" y="25" textAnchor="middle" letterSpacing="0.1px" fontSize="11px" fill="#24292D" fontWeight="bold" dy="0.3333em">
											{tupleSearch(o.data.attributes, _o.attribute).value}
										</text>
									</g>
								)
							})}
						</g>
						{/* objects list */}
						<g fill="#ffffff" transform={'translate(-13 27)'}>
							{(() => {
								if (this.props.metricsMode === true) {
									// metric
									return (
										<g transform="translate(-45 -40)">
											<foreignObject
												width="120"
												height="77"
												style={{
													backgroundColor: 'rgba(0,0,0,0)',
													background: 'rgba(0,0,0,0)'
												}}
											>
												<div
													style={{
														wordBreak: 'break-word',
														fontSize: '10px',
														color: '#24292D',
														backgroundColor: 'rgba(0,0,0,0)',
														background: 'rgba(0,0,0,0)'
													}}
												>
													<div
														style={{
															textAlign: 'center',
															letterSpacing: '0.2px',
															fontSize: '10px',
															color: '#333333',
															lineHeight: '1.8em',
															fontWeight: 'bold'
														}}
													>
														METRIC
													</div>
													<div
														style={{
															textAlign: 'center',
															lineHeight: '1em',
															fontSize: '10px',
															wordBreak: 'break-word',
															color: '#333333'
														}}
													>
														{tupleSearch(o.data.attributes, 'name').value}
													</div>
												</div>
											</foreignObject>
										</g>
									)
								} else {
									// device
									return this.props.expandedKeys.map((ek, idx) => {
										return (
											<g key={'badge-element-' + idx} transform={['translate(-45', (idx - 1) * 38 * 0.7, ')'].join(' ')}>
												<foreignObject
													width="120"
													height="20"
													x="0"
													y="-12"
													style={{
														backgroundColor: 'rgba(0,0,0,0)',
														background: 'rgba(0,0,0,0)'
													}}
												>
													<div
														style={{
															textAlign: 'center',
															wordBreak: 'break-word',
															fontSize: '9px',
															fontWeight: 'bold',
															color: '#333333',
															lineHeight: '1em',
															backgroundColor: 'rgba(0,0,0,0)',
															background: 'rgba(0,0,0,0)'
														}}
													>
														{ek.label}
													</div>
												</foreignObject>
												<foreignObject
													width="120"
													height="20"
													style={{
														backgroundColor: 'rgba(0,0,0,0)',
														background: 'rgba(0,0,0,0)'
													}}
												>
													<div
														style={{
															textAlign: 'center',
															wordBreak: 'break-word',
															fontSize: '9px',
															color: '#333333',
															lineHeight: '1em',
															backgroundColor: 'rgba(0,0,0,0)',
															background: 'rgba(0,0,0,0)'
														}}
													>
														{tupleSearch(o.data.attributes, ek.key).value}
													</div>
												</foreignObject>
											</g>
										)
									})
								}
							})()}
						</g>
					</g>
				</g>
			</g>
		</g>
	)
}
