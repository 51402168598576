import React from 'react'

export default class DropdownOverlay extends React.Component {
	constructor(props) {
		super(props)
		this.node = React.createRef()
	}

	render() {
		const { activeItem } = this.props
		const items = [
			{ key: 'Open', value: 'open' },
			{ key: 'Acknowledged', value: 'acknowledged' },
			{ key: 'Action Taken', value: 'actionTaken' }
		]
		return (
			<div ref={this.node} className="status-dropdown-overlay" onClick={(e) => e.stopPropagation()}>
				{items.map((item, index) => (
					<div
						onClick={() => {
							this.props.setStatus(item.value)
							this.props.hideDropdown()
						}}
						className={`item ${activeItem === item.value ? 'active' : ''}`}
					>
						{item.key}
					</div>
				))}
			</div>
		)
	}
}
