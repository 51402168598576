import React, { Component } from "react";
import classNames from "classnames";
import _ from "lodash";
import Dropdown from "./components/Dropdown";
import RcMenu, { Item, Divider } from "rc-menu";
import { getRanges, getRange } from "./utils/rangePresets";
import { DatePickerWidget as DatePicker } from "../../widgets/DatePickerWidget/DatePickerWidget";
import "./TimeRangeSelectComponent.scss";
import moment from "moment";

export class TimeRangeSelectComponent extends Component {
	state = {
		open: undefined,
		dropdown: undefined,
		active: false,
		timeRange: null,
		fromSetState: false,
	};

	static getDerivedStateFromProps(props, state) {
		if (state.fromSetState) {
			return { fromSetState: false };
		} else {
			return { timeRange: props.value };
		}
	}

	getCurrentInputRef() {
		const result = this.props.preset === "custom" ? this.rangePickerRef : this.readonlyInputRef;
		return result;
	}
	focus() {
		const ref = this.getCurrentInputRef();
		if (ref) {
			ref.focus();
		}
	}
	blur() {
		const ref = this.getCurrentInputRef();
		if (ref) {
			ref.blur();
		}
	}

	onSelect = (key, rangeDef) => {
		const isCustom = key === "custom";
		const reset = () => {
			this.setState({ fromSetState: true, open: undefined, active: isCustom });
		};
		if (isCustom) {
			this.props.onPresetChange(key, () => {
				this.setState({ fromSetState: true, open: true, dropdown: false }, reset);
			});
		} else {
			const { timeval } = getRange(key, rangeDef);
			this.props.onPresetChange(key, reset);
			this.props.onChange(timeval(), key, timeval);
		}
	};

	onPickerOk = () => {
		this.props.onChange(this.state.timeRange, this.props.preset, () => value);
		this.setState({ fromSetState: true, open: false, dropdown: false }, () => {
			this.setState({ fromSetState: true, open: undefined, dropdown: undefined, active: false });
		});
	};
	onPickerOpenChange = (open) => {
		this.setState({ fromSetState: true, dropdown: open ? false : undefined, active: open });
	};
	onPickerValueChange = (value) => {
		this.setState({ fromSetState: true, timeRange: value });
	};

	onVisibleChange = () => {
		this.setState((prevState) => ({ active: !prevState.active }));
	};

	renderSelection({ getContainer, rangeDef }) {
		let { preset, format = "YYYY/MM/DD HH:mm:ss", defaultValue, disabled, disabledDate } = this.props;
		disabledDate = disabledDate || ((date) => date > moment());
		preset = preset || _.get(getRanges(rangeDef)[0], "key");
		let value = this.state.timeRange || [moment(), moment()];
		value = [moment(value[0]), moment(value[1])];
		const { open } = this.state;
		const isCustom = preset === "custom";
		const timePickerOpt =
			(format.endsWith(" A") && {
				use12Hours: true,
				format: "h:mm:ss A",
			}) ||
			(format.endsWith(" a") && {
				use12Hours: true,
				format: "h:mm:ss a",
			}) ||
			{};
		const getDisplay = () => {
			if (!value) {
				return "";
			}
			if (isCustom) {
				return value.map((t) => t.format(format)).join(" ~ ");
			}
			const { display = "" } = preset ? getRange(preset, rangeDef) : {};
			return display;
		};
		const className = classNames("pan-calendar-picker-input", "pan-input", `time-picker-preset-${preset}`, { "pan-input-disabled": disabled });
		return (
			<span className={className}>
				<i className="time-picker-calendar-icon"></i>
				<input ref={(ref) => (this.readonlyInputRef = ref)} readOnly disabled={disabled} className="pan-calendar-picker-input" placeholder="Please select" tabIndex={-1} value={getDisplay()} />
				<i className="pan-select-arrow"></i>
				{isCustom && (
					<DatePicker.RangePicker
						{...(open !== undefined && { open })}
						defaultValue={value}
						// value={value}
						onChange={this.onPickerValueChange}
						onOk={this.onPickerOk}
						onOpenChange={this.onPickerOpenChange}
						ref={(ref) => (this.rangePickerRef = ref)}
						className="date-range-picker this-is-for-search"
						dropdownClassName="date-range-picker-popup"
						showTime={timePickerOpt}
						format={format}
						disabledDate={disabledDate}
						getCalendarContainer={getContainer}
					/>
				)}
			</span>
		);
	}

	render() {
		const { rangeDef, className, preset, value, ...props } = this.props;
		const { dropdown, active } = this.state;
		const getContainer = (el) => {
			const result = (el.closest && el.closest(".time-range-picker")) || document.body;
			return result;
		};
		const menu = (
			<RcMenu className="time-range-picker-menu" selectedKeys={[preset]} onClick={(e) => this.onSelect(e.key, rangeDef)}>
				{getRanges(rangeDef).map(({ key, display }) => (
					<Item key={key} disabled={key === preset}>
						{display}
					</Item>
				))}
				<Divider />
				<Item key="custom">Custom</Item>
			</RcMenu>
		);
		if (dropdown != null) {
			// only work if force re-render
			props.visible = dropdown;
		}
		return (
			<span className="pan-time-range-picker">
				<Dropdown
					{...props}
					key={preset === "custom" ? "range-picker" : "dropdown"} // force re-render
					className={classNames(className, "time-range-picker", { "time-range-picker-active": active })}
					trigger={["click"]}
					overlay={menu}
					onVisibleChange={this.onVisibleChange}
					getPopupContainer={_.identity} // only work if force re-render
				>
					{this.renderSelection({ getContainer, rangeDef })}
				</Dropdown>
			</span>
		);
	}
}

TimeRangeSelectComponent.getRange = getRange;
TimeRangeSelectComponent.getRanges = getRanges;
