import scrollIntoView from "dom-scroll-into-view";
import * as PropTypes from "prop-types";
import raf from "raf";
import Menu from "./Menu";
import toArray from "rc-util/lib/Children/toArray";
import * as React from "react";
import { findDOMNode } from "react-dom";
import { getSelectKeys, preventDefaultEvent, saveRef } from "./util";

export default class DropdownMenu extends React.Component {
	static displayName = "DropdownMenu";
	static propTypes = {
		ariaId: PropTypes.string,
		defaultActiveFirstOption: PropTypes.bool,
		value: PropTypes.any,
		dropdownMenuStyle: PropTypes.object,
		multiple: PropTypes.bool,
		onPopupFocus: PropTypes.func,
		onPopupScroll: PropTypes.func,
		onMenuDeSelect: PropTypes.func,
		onMenuSelect: PropTypes.func,
		prefixCls: PropTypes.string,
		menuItems: PropTypes.any,
		inputValue: PropTypes.string,
		visible: PropTypes.bool,
		firstActiveValue: PropTypes.string,
		menuItemSelectedIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	};
	rafInstance = null;
	lastInputValue;
	saveMenuRef;
	menuRef;
	lastVisible = false;
	firstActiveItem;
	constructor(props) {
		super(props);
		this.lastInputValue = props.inputValue;
		this.saveMenuRef = saveRef(this, "menuRef");
	}

	componentDidMount() {
		this.scrollActiveItemToView();
		this.lastVisible = this.props.visible;
	}

	shouldComponentUpdate(nextProps) {
		if (!nextProps.visible) {
			this.lastVisible = false;
		}
		// freeze when hide
		return (
			(this.props.visible && !nextProps.visible) ||
			nextProps.visible ||
			nextProps.inputValue !== this.props.inputValue
		);
	}

	componentDidUpdate(prevProps) {
		const props = this.props;
		if (!prevProps.visible && props.visible) {
			this.scrollActiveItemToView();
		}
		this.lastVisible = props.visible;
		this.lastInputValue = props.inputValue;
	}

	componentWillUnmount() {
		if (this.rafInstance) {
			raf.cancel(this.rafInstance);
		}
	}

	scrollActiveItemToView = () => {
		// scroll into view
		const itemComponent = findDOMNode(this.firstActiveItem);
		const { visible, firstActiveValue } = this.props;
		const value = this.props.value;
		if (!itemComponent || !visible) {
			return;
		}
		const scrollIntoViewOpts = {
			onlyScrollIfNeeded: true,
		};
		if ((!value || value.length === 0) && firstActiveValue) {
			scrollIntoViewOpts.alignWithTop = true;
		}

		// Delay to scroll since current frame item position is not ready when pre view is by filter
		// https://github.com/ant-design/ant-design/issues/11268#issuecomment-406634462
		this.rafInstance = raf(() => {
			scrollIntoView(
				itemComponent,
				findDOMNode(this.menuRef),
				scrollIntoViewOpts
			);
		});
	};

	renderMenu = () => {
		const {
			menuItems,
			menuItemSelectedIcon,
			defaultActiveFirstOption,
			prefixCls,
			multiple,
			onMenuSelect,
			inputValue,
			backfillValue,
			onMenuDeselect,
			visible,
			menuItemHeight,
			menuHeight,
			optionList,
		} = this.props;
		const firstActiveValue = this.props.firstActiveValue;

		if (menuItems && menuItems.length) {
			const menuProps = {};
			if (multiple) {
				menuProps.onDeselect = onMenuDeselect;
				menuProps.onSelect = onMenuSelect;
			} else {
				menuProps.onClick = onMenuSelect;
			}
			const value = this.props.value;
			const selectedKeys = getSelectKeys(menuItems, value);
			const activeKeyProps = {};

			let defaultActiveFirst = defaultActiveFirstOption;
			let clonedMenuItems = menuItems;
			if (selectedKeys.length || firstActiveValue) {
				if (visible && !this.lastVisible) {
					activeKeyProps.activeKey = selectedKeys[0] || firstActiveValue;
				} else if (!visible) {
					// Do not trigger auto active since we already have selectedKeys
					if (selectedKeys[0]) {
						defaultActiveFirst = false;
					}
					activeKeyProps.activeKey = undefined;
				}
				let foundFirst = false;
				// set firstActiveItem via cloning menus
				// for scroll into view
				const clone = (item) => {
					const key = item.key;
					if (
						(!foundFirst && selectedKeys.indexOf(key) !== -1) ||
						(!foundFirst &&
							!selectedKeys.length &&
							firstActiveValue.indexOf(item.key) !== -1)
					) {
						foundFirst = true;
						return React.cloneElement(item, {
							ref: (ref) => {
								this.firstActiveItem = ref;
							},
						});
					}
					return item;
				};

				clonedMenuItems = menuItems.map((item) => {
					if (item.type.isMenuItemGroup) {
						const children = toArray(item.props.children).map(clone);
						return React.cloneElement(item, {}, children);
					}
					return clone(item);
				});
			} else {
				// Clear firstActiveItem when dropdown menu items was empty
				// Avoid `Unable to find node on an unmounted component`
				// https://github.com/ant-design/ant-design/issues/10774
				this.firstActiveItem = null;
			}

			// clear activeKey when inputValue change
			const lastValue = value && value[value.length - 1];
			if (
				inputValue !== this.lastInputValue &&
				(!lastValue || lastValue !== backfillValue)
			) {
				activeKeyProps.activeKey = "";
			}

			return (
				<Menu
					ref={this.saveMenuRef}
					style={{
						...this.props.dropdownMenuStyle,
						height: optionList
							? optionList.length * menuItemHeight
							: menuHeight,
					}}
					defaultActiveFirst={defaultActiveFirst}
					role='listbox'
					itemIcon={multiple ? menuItemSelectedIcon : null}
					{...activeKeyProps}
					multiple={multiple}
					{...menuProps}
					selectedKeys={selectedKeys}
					prefixCls={`${prefixCls}-menu`}
				>
					{clonedMenuItems}
				</Menu>
			);
		}
		return null;
	};

	render() {
		const { menuItemHeight, menuHeight, optionList } = this.props;
		const renderMenu = this.renderMenu();
		let height = 0;
		if (optionList) {
			if (optionList.length * menuItemHeight < menuHeight) {
				height = optionList.length * menuItemHeight;
			} else {
				height = menuHeight;
			}
		}
		return renderMenu ? (
			<div
				style={{
					overflow: "auto",
					transform: "translateZ(0)",
					height,
				}}
				id={this.props.ariaId}
				onFocus={this.props.onPopupFocus}
				onMouseDown={preventDefaultEvent}
				onScroll={this.props.onPopupScroll}
				className={`${this.props.prefixCls}-menu-viewport`}
			>
				{renderMenu}
			</div>
		) : null;
	}
}
