import React from 'react'
import _ from 'lodash'
import { Select } from 'antd'
import iconArrowDown from '../../../../../../images/icon-arrow-down.svg'
import iconRemove from '../../../../../../images/icon-delete-dimmed.svg'
import 'antd/lib/select/style/css'

const { Option } = Select

const conditionsInfo = [
	{
		key: 'severity',
		displayName: 'Severity',
		ops: [
			{ value: '==', displayName: 'Equals' },
			{ value: '!=', displayName: 'Does Not Equal' }
		],
		values: [
			{ value: 'critical', displayName: 'Critical' },
			{ value: 'warning', displayName: 'Warning' }
		]
	},
	{
		key: 'category',
		displayName: 'Category',
		ops: [{ value: '==', displayName: 'Equals' }],
		values: [
			{ value: 'Hardware', displayName: 'Hardware' },
			{ value: 'Config limits', displayName: 'Config limits' },
			{ value: 'Resource limits', displayName: 'Resource limits' },
			{ value: 'Dynamic content', displayName: 'Dynamic content' },
			{ value: 'PAN-OS & Subscriptions', displayName: 'PAN-OS & Subscriptions' }
		]
	}
]

export default class Condition extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			conditionIndex: conditionsInfo.findIndex((condition) => condition.key === props.k)
		}
	}
	static getDerivedStateFromProps(nextProps) {
		return { conditionIndex: conditionsInfo.findIndex((condition) => condition.key === nextProps.k) }
	}
	render() {
		const { k, op, v, onChange, onRemove } = this.props
		return (
			<div className="condition">
				<Select
					suffixIcon={<img src={iconArrowDown} alt="expand-icon" />}
					prefixCls={'condition-select ant-select'}
					showArrow
					value={k}
					style={{ width: 75, height: 32 }}
					onChange={(value) => {
						const index = conditionsInfo.findIndex((condition) => condition.key === value)
						onChange({ key: value, op: conditionsInfo[index].ops[0].value, value: conditionsInfo[index].values[0].value })
					}}
				>
					{conditionsInfo.map((info) => (
						<Option value={info.key}>{info.displayName}</Option>
					))}
				</Select>
				<Select
					suffixIcon={<img src={iconArrowDown} alt="expand-icon" />}
					prefixCls={'condition-select ant-select'}
					showArrow
					value={op}
					style={{ width: 105, height: 32 }}
					onChange={(value) => {
						onChange({ op: value })
					}}
				>
					{conditionsInfo[this.state.conditionIndex] && conditionsInfo[this.state.conditionIndex].ops.map((info) => <Option value={info.value}>{info.displayName}</Option>)}
				</Select>
				<Select
					suffixIcon={<img src={iconArrowDown} alt="expand-icon" />}
					prefixCls={'condition-select ant-select'}
					showArrow
					value={v}
					style={{ width: 160, height: 32 }}
					onChange={(value) => {
						onChange({ value })
					}}
				>
					{conditionsInfo[this.state.conditionIndex] && conditionsInfo[this.state.conditionIndex].values.map((info) => <Option value={info.value}>{info.displayName}</Option>)}
				</Select>
				{this.props.isLast ? null : <span className="operator">and...</span>}
				<img className="icon-remove" onClick={onRemove} src={iconRemove} alt="remove-icon" />
			</div>
		)
	}
}
