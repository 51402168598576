import React, { Component } from 'react'
import './LogViewer.scss'
import ExploreHelper from '../helper/ExploreHelper'
import { connect } from 'ui-lib'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'
import LogViewerHeader from './LogViewerHeader'
import LogViewerBody from './LogViewerBody'
import * as actions from './store/actions'
import * as visibilityActions from '../../store/actions'
import Constants from '../utils/Constants.json'

class LogViewer extends Component {
	state = {
		detailVisible: false,
		exportConfigVisible: false,
		exportHistoryVisible: false,
		selectedRow: null,
		extractTenantUserObj: null
	}

	onOpenLogDetailPanel = (node) => {
		if (Constants.RELATED_LOGS_TYPES.includes(node.log_type.value.toLowerCase())) {
			const { session_id, log_source_id, from_zone, to_zone, source_ip, dest_ip, source_port, dest_port, time_generated, source_user } = node
			const params = {
				session_id,
				log_source_id,
				from_zone,
				to_zone,
				source_ip,
				dest_ip,
				source_port,
				dest_port,
				time_generated
			}
			this.props.fetchRelatedLogsRequest({ ...params })
			this.props.fetchUserContext(source_user)
		}
		this.setState({ detailVisible: true, selectedRow: node })
	}

	onCloseLogDetailPanel = () => {
		this.setState({ detailVisible: false, selectedRow: null })
	}

	onOpenExportConfig = () => {
		this.setState({ exportConfigVisible: true })
	}

	onOpenExportHistory = () => {
		this.setState({ exportHistoryVisible: true })
	}

	onCloseExportConfig = () => {
		this.setState({ exportConfigVisible: false })
	}

	onCloseExportHistory = () => {
		this.setState({ exportHistoryVisible: false })
	}

	componentDidMount() {
		let { tenant, loggedInUser } = this.props
		const extractTenantUserObj = ExploreHelper.extractTenantUser(tenant, loggedInUser)
		const { account, email, lstenant, lsregion, dstenant, dsregion } = extractTenantUserObj

		this.exploreProps = {
			viewContext: {
				query: '',
				component: 'logs',
				logtype: 'panw.traffic'
			},
			appContext: {
				baseURL: window.__admin_cluster_url,
				appID: 'adelphi',
				user: {
					account,
					email,
					//temporary hardcoding TODO: Remove when testing is complete @Wen Jiang & @Harsimranjit Singh
					lstenant,
					lsregion,
					dstenant,
					dsregion
				}
			}
		}
		this.setState({ extractTenantUserObj })
		this.props.updateActiveApp('logs')
		this.props.updateTenantId(this.exploreProps.appContext.user.lstenant)
		this.props.fetchSchemaRequest()
		this.props.restoreLogviewer()
		// Set exportJobId to -1 to differentiate inital call
		this.props.setExportRequestId(-1)
		this.props.fetchExportHistoryRequest()
	}

	render() {
		return (
			<div id="explore-logviewer">
				{this.props.schemaLoading || !this.props.isRestoreComplete ? (
					<div className={'info-area'}>
						<img className="loading-spinner" src={iconLoading} alt={'loading'} />
						Loading Schema
					</div>
				) : this.props.schemaError ? (
					<div className={'info-area'}>Schema Error</div>
				) : (
					<>
						<div className={'log-viewer-header'}>
							<LogViewerHeader
								{...this.props}
								logType={this.props.logType}
								onOpenExportConfig={this.onOpenExportConfig}
								onOpenExportHistory={this.onOpenExportHistory}
								onCloseLogDetailPanel={this.onCloseLogDetailPanel}
								extractTenantUserObj={this.state.extractTenantUserObj}
							/>
						</div>
						<div className={'log-viewer-body'}>
							<LogViewerBody
								{...this.props}
								onOpenLogDetailPanel={this.onOpenLogDetailPanel}
								onCloseLogDetailPanel={this.onCloseLogDetailPanel}
								detailVisible={this.state.detailVisible}
								selectedRow={this.state.selectedRow}
								exportConfigVisible={this.state.exportConfigVisible}
								onCloseExportConfig={this.onCloseExportConfig}
								exportHistoryVisible={this.state.exportHistoryVisible}
								onCloseExportHistory={this.onCloseExportHistory}
							/>
						</div>
					</>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({ ...state.explore, ...state.main })

const mapDispatchToProps = { ...actions, ...visibilityActions }

export default connect(mapStateToProps, mapDispatchToProps)(LogViewer)
