import { getLoginServiceURL } from "../services/CommonServices";

export const getParam = (arg) => {
  const params = new URLSearchParams(window.location.search);
  if (params.has(arg)) {
    return params.get(arg);
  }
  return void 0;
};

export const saveSession = ({ sub, token, exp }) => {
  sessionStorage.setItem('currentTenantId', sub);
  localStorage.setItem(`token-${sub}`, token);
  localStorage.setItem(`token-exp-${sub}`, exp);
  sessionStorage.setItem(`token-exp-${sub}`, exp);
  setSessionAlert(exp);
  startSessionMonitor();
}

export const getSession = (sub = "") => {
  sub = sub || sessionStorage.getItem('currentTenantId');
  const token = localStorage.getItem(`token-${sub}`);
  return token;
}

const FIVE_MINS = 5 * 60 * 1000;

let timer;
let logouttimer;
let inabout = 5;
export const setSessionAlert = (expAt) => {
  const expiringIn = (expAt * 1000 - Date.now());
  let timeout = expiringIn - FIVE_MINS;
  if (timeout < 0) {
    timeout = 0;
    inabout = Math.floor(expiringIn / 60000);
  }
  clearTimeout(timer);
  timer = setTimeout(() => {
    clearTimeout(logouttimer);
    logouttimer = setTimeout(() => {
      window.location.hash = 'trylogin';
    }, inabout * 60000)
    alert(`Your session will expire in about ${inabout} mins and you will be logged out. Please save your work before you are logged out.`);
  }, timeout);
}

let sessionTimer;
export const startSessionMonitor = () => {
  clearInterval(sessionTimer);
  sessionTimer = setInterval(() => {
    const sub = sessionStorage.getItem('currentTenantId');
    if (localStorage.getItem(`token-exp-${sub}`) > sessionStorage.getItem(`token-exp-${sub}`)) {
      // another window did a SAML Login - & now has updated token;
      // update the session timeout & alert. so user is not wrongly shown the session timeout alert.
      sessionStorage.setItem(`token-exp-${sub}`, localStorage.getItem(`token-exp-${sub}`));
      setSessionAlert(localStorage.getItem(`token-exp-${sub}`));
    }
  }, 2500);
}

/**
 * Try getting tenantId from URL, or else from session
 * If none found, then we must ask user to establist a session
 * by providing a tenantId or launch us from Hub.
 */
export const doLogin = () => {
    let loginUrl = getLoginServiceURL("api/sso/v1/login")["SERVICE_URL"];
    const urlParams = new URLSearchParams(window.location.search);
    let tenantId = urlParams.get("tenantId");
    if (tenantId) {
      loginUrl += `?tenantId=${tenantId}`;
    } else {
      tenantId = sessionStorage.getItem('currentTenantId');
      if (tenantId) {
        loginUrl += `?tenantId=${tenantId}`;
      }
    }
    if (!tenantId) {
      // should never reach here but in case doLogin is called out of conext.
      throw new Error('No tenantId found!');
    } else {
      window.location = loginUrl;
    }
}

export const doLogout = () => {
  const sub = sessionStorage.getItem('currentTenantId');
  if (sub) {
    localStorage.removeItem(`token-${sub}`);
    localStorage.removeItem(`token-exp-${sub}`);
  }
  sessionStorage.clear();
  const logoutUrl = getLoginServiceURL("api/sso/v1/logout")["SERVICE_URL"];
  window.location = logoutUrl;
}