import React from 'react'
import { connect } from 'ui-lib'
import { LogQueryComponent as LogQuery } from './components/FilterBuilder'
import InsightsTable from './components/InsightsTable/InsightsTable'
import HexGrid from './components/HexGrid/HexGrid'
import InsightsNav from './components/InsightsNav/InsightsNav'
import visibilityUtil from '../../../util/Util'
import Constants from '../../utils/Constants.json'

import * as mainActions from '../../../../main/actions'
import * as actions from './store/actions'
import * as insightsActions from '../../store/actions'

import iconCritical from '../../../images/icon-critical.svg'
import iconWarning from '../../../images/icon-warning.svg'
import iconHealty from '../../../images/icon-healthy.svg'
import iconFilter from '../../../images/icon-filter.svg'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'

import './InsightsMain.scss'

class InsightsMain extends React.Component {
	state = {
		filterBuilderSchema: visibilityUtil.loadSchema('insights', false),
		gridOffset: 0,
		pinnedTopRow: -1,
		lockedHexGrids: []
	}

	componentDidMount() {
		this.props.fetchInsightsMainPageContent()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.activeInsightsTab !== this.props.activeInsightsTab) {
			this.resetLockedHexGrids()

			const hexgrid = document.getElementsByClassName('hex-grid')[0]
			if (hexgrid) hexgrid.scrollIntoView()
			this.removeSticky()
			this.clearOffset()

			this.props.fetchInsightsMainPageContent()
		}
	}

	componentWillUnmount() {
		this.removeSticky()
		this.clearOffset()
	}

	setOffset = (offset) => this.setState({ gridOffset: offset })
	clearOffset = () => this.setState({ gridOffset: 0 })

	setSticky = (rowId) => {
		this.setState({ pinnedTopRow: rowId })
	}

	removeSticky = () => {
		const stickyElement = document.getElementsByClassName('sticky')[0]
		if (stickyElement) {
			stickyElement.remove()
		}

		this.setState({
			pinnedTopRow: -1
		})
	}

	resetLockedHexGrids = () => {
		if (!_.isEqual(this.state.lockedHexGrids, [])) {
			this.setState({ lockedHexGrids: [] })
		}
	}

	onSearch = () => {
		this.removeSticky()
		this.clearOffset()
		this.props.resetInsightsPage()
		this.props.fetchInsightsLogsRequest()
		this.props.fetchInsightsLogsCountRequest()
	}

	render() {
		let {
			activeInsightsTab,
			metricsMisc,
			valueSuggestions,
			healthStatus,
			healthStatusLoading,
			healthStatusError,
			insightsQuery,
			fetchAutocompleteRequest,
			setInsightsQuery: updateQuery,
			switchInsightsTab
		} = this.props

		const { filterBuilderSchema } = this.state

		let query = ''
		const iconMap = { critical: iconCritical, warning: iconWarning, healthy: iconHealty }
		const isFixedTab = _.isString(activeInsightsTab)
		if (isFixedTab) {
			query = insightsQuery
		}

		return (
			<div className="insights-main">
				<div className="insights-header">Insights</div>
				<InsightsNav
					activeInsightsTab={activeInsightsTab}
					items={[
						{ name: 'Devices', index: Constants.FIXED_TABS[0], onItemClicked: () => switchInsightsTab(activeInsightsTab, Constants.FIXED_TABS[0]) },
						{ name: 'Metrics', index: Constants.FIXED_TABS[1], onItemClicked: () => switchInsightsTab(activeInsightsTab, Constants.FIXED_TABS[1]) }
					]}
				/>
				<div className="health-stat">
					{healthStatusError ? (
						<div className="info-area">Error fetching health status</div>
					) : healthStatusLoading ? (
						<div className="info-area">
							<img className="loading-spinner" src={iconLoading} alt={'loading'} />
							Loading Health Status
						</div>
					) : Object.keys(healthStatus).length === 0 ? (
						<div className="info-area">No Data Found</div>
					) : (
						Object.keys(healthStatus).map((key) => (
							<div className={`stat-box stat-${key}`} key={key}>
								<span className="stat-value">{healthStatus[key]}</span>
								<img className="stat-icon" src={iconMap[key]} alt={key} />
								<span className="stat-category">{_.capitalize(key.replace('_', ' '))}</span>
							</div>
						))
					)}
				</div>
				<div className="grid">
					<div className="hex-grid">
						<HexGrid
							lockedElements={this.state.lockedHexGrids}
							resetLockedElements={() => {
								if (!_.isEqual(this.state.lockedHexGrids, [])) {
									this.props.setInsightsQuery('')
									this.props.resetInsightsPage()
									this.props.fetchInsightsLogsRequest()
									this.props.fetchInsightsLogsCountRequest()
									this.resetLockedHexGrids()
								}
							}}
						/>
					</div>
					<div className="table-grid">
						<div className="filter-and-config">
							<div className="filter-builder">
								<img src={iconFilter} alt="filter-icon" className="filter-icon" />
								<LogQuery
									resetLockedHexGrids={this.resetLockedHexGrids}
									metricsMisc={metricsMisc}
									timeFormat="MM/DD/YYYY HH:mm:ss"
									supportNot={false}
									schema={filterBuilderSchema}
									logType={activeInsightsTab}
									query={query}
									searchButton={true}
									onSearch={this.onSearch}
									onQueryChange={updateQuery}
									placeholder="Filter by severity, model, software version, etc."
									hasLogType={true}
									valueSuggestions={valueSuggestions}
									loadMore={(field) => {
										fetchAutocompleteRequest(field)
									}}
								/>
							</div>
						</div>
						<div className="log-table">
							<InsightsTable
								pinnedTopRow={this.state.pinnedTopRow}
								setSticky={this.setSticky}
								removeSticky={this.removeSticky}
								offset={this.state.gridOffset}
								setOffset={this.setOffset}
								clearOffset={this.clearOffset}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.insights, ...state.insightsMain }
}

const mapDispatchToProps = { ...actions, ...insightsActions, ...mainActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(InsightsMain)
