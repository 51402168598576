import React, { Component } from "react";
import "./App.css";
import Main from "../containers/main";
import { ErrorBoundary } from "ui-lib";

export default class App extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<ErrorBoundary>
				<Main />
			</ErrorBoundary>
		);
	}
}
