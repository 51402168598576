import moment from 'moment'

import * as actionTypes from './actionTypes'
import Constants from '../../../utils/Constants.json'
import pageInfo from '../../pageUtil'

const ACTION_HANDLERS = {
	/*----- Reset Main Page Reducers -----*/
	[actionTypes.RESET_STORE]: (state, { pageType }) => {
		if (pageType === pageInfo.InsightsMain.pageType) {
			return {
				..._.cloneDeep(initialState)
			}
		} else {
			return { ...state }
		}
	},
	/*----- PollJob Reducers -----*/
	[actionTypes.POLL_INSIGHTS_MAIN_PAGE_JOB_REQUEST]: (state, { jobId }) => {
		return {
			...state,
			insightsMainPageJobId: jobId,
			insightsMainPageJobError: null
		}
	},
	[actionTypes.POLL_INSIGHTS_MAIN_PAGE_JOB_FAILURE]: (state, { jobId, error }) => {
		return {
			...state,
			insightsMainPageJobId: jobId,
			insightsMainPageJobError: error
		}
	},
	/*----- Health Status Reducers -----*/
	[actionTypes.FETCH_HEALTH_STAT_REQUEST]: (state) => {
		return {
			...state,
			healthStatus: null,
			healthStatusLoading: true,
			healthStatusError: null
		}
	},
	[actionTypes.FETCH_HEALTH_STAT_SUCCESS]: (state, { healthStatus }) => {
		return {
			...state,
			healthStatus,
			healthStatusLoading: false,
			healthStatusError: null,
			healthStatusRequestId: null
		}
	},
	[actionTypes.SET_HEALTH_STAT_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			healthStatusRequestId: jobId
		}
	},
	[actionTypes.FETCH_HEALTH_STAT_FAILURE]: (state, { error }) => {
		return {
			...state,
			healthStatus: null,
			healthStatusLoading: false,
			healthStatusError: error
		}
	},
	[actionTypes.RESTORE_HEALTH_STAT]: (state, { data }) => {
		return {
			...state,
			healthStatusLoading: false,
			healthStatusError: null,
			healthStatusRequestId: null,
			...data
		}
	},
	/*----- Hex Grid Reducers -----*/
	[actionTypes.SET_HEXGRID_SETTINGS]: (state, { setting, activeInsightsTab }) => {
		if (_.isEmpty(setting)) return { ...state }
		const hexgridSettings = { ...state.hexgridSettings, [activeInsightsTab]: setting }
		return {
			...state,
			hexgridSettings
		}
	},
	[actionTypes.FETCH_HEX_GRID_REQUEST]: (state) => {
		return {
			...state,
			hexgrid: null,
			hexgridLoading: true,
			hexgridError: null,
			hexgridRequestId: null
		}
	},
	[actionTypes.FETCH_HEX_GRID_SUCCESS]: (state, { hexgrid }) => {
		return {
			...state,
			hexgrid,
			hexgridLoading: false,
			hexgridError: null,
			hexgridRequestId: null
		}
	},
	[actionTypes.SET_HEX_GRID_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			hexgridRequestId: jobId
		}
	},
	[actionTypes.FETCH_HEX_GRID_FAILURE]: (state, { error }) => {
		return {
			...state,
			hexgrid: null,
			hexgridLoading: false,
			hexgridError: error,
			hexgridRequestId: null
		}
	},
	[actionTypes.RESTORE_HEX_GRID]: (state, { data }) => {
		return {
			...state,
			hexgridLoading: false,
			hexgridError: null,
			hexgridRequestId: null,
			...data
		}
	},
	/*----- Logs Reducers -----*/
	[actionTypes.FETCH_INSIGHTS_LOGS_REQUEST]: (state) => {
		// console.log('------- loading logs')
		return {
			...state,
			insightsLogs: null,
			insightsLogsLoading: true,
			insightsLogsError: null,
			insightsLogsRequestid: null,
			insightsDetailLogsRequestid: null
		}
	},
	[actionTypes.SET_INSIGHTS_LOGS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			insightsLogsRequestId: jobId
		}
	},
	[actionTypes.FETCH_INSIGHTS_LOGS_SUCCESS]: (state, { logs, jobId }) => {
		// console.log('------- loading logs done')
		return {
			...state,
			insightsLogs: logs,
			insightsLogsLoading: false,
			insightsLogsError: null,
			insightsLogsRequestId: jobId
		}
	},
	[actionTypes.FETCH_INSIGHTS_LOGS_FAILURE]: (state, { error }) => {
		return {
			...state,
			insightsLogs: null,
			insightsLogsLoading: false,
			insightsLogsError: error,
			insightsLogsRequestid: null
		}
	},
	[actionTypes.RESTORE_INSIGHTS_LOGS]: (state, { data }) => {
		return {
			...state,
			insightsLogsLoading: false,
			insightsLogsError: null,
			insightsLogsRequestId: null,
			insightsDetailLogsRequestId: null,
			insightsDetailLogsLoading: false,
			insightsDetailLogsError: null,
			...data
		}
	},
	/*----- Logs Count Reducers -----*/
	[actionTypes.FETCH_INSIGHTS_LOGS_COUNT_REQUEST]: (state) => {
		// console.log('------- loading count')
		return {
			...state,
			insightsLogsCount: null,
			insightsLogsCountLoading: true,
			insightsLogsCountError: null,
			insightsLogsCountRequestid: null
		}
	},
	[actionTypes.SET_INSIGHTS_LOGS_COUNT_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			insightsLogsCountRequestId: jobId
		}
	},
	[actionTypes.FETCH_INSIGHTS_LOGS_COUNT_SUCCESS]: (state, { logsCount, jobId }) => {
		// console.log('------- loading count done')
		return {
			...state,
			insightsLogsCount: logsCount,
			insightsLogsCountLoading: false,
			insightsLogsCountError: null,
			insightsLogsCountRequestId: jobId
		}
	},
	[actionTypes.FETCH_INSIGHTS_LOGS_COUNT_FAILURE]: (state, { error }) => {
		return {
			...state,
			insightsLogsCount: null,
			insightsLogsCountLoading: false,
			insightsLogsCountError: error,
			insightsLogsCountRequestId: null
		}
	},
	[actionTypes.RESTORE_INSIGHTS_LOGS_COUNT]: (state, { data }) => {
		return {
			...state,
			insightsLogsCountLoading: false,
			insightsLogsCountError: null,
			insightsLogsCountRequestId: null,
			...data
		}
	},
	/*----- Detail Logs Reducers -----*/
	[actionTypes.FETCH_INSIGHTS_DETAIL_LOGS_REQUEST]: (state) => {
		// console.log('------- loading details')
		return {
			...state,
			insightsDetailLogsLoading: true,
			insightsDetailLogsError: null,
			insightsDetailLogsRequestId: null
		}
	},
	[actionTypes.SET_INSIGHTS_DETAIL_LOGS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			insightsDetailLogsRequestId: jobId
		}
	},
	[actionTypes.FETCH_INSIGHTS_DETAIL_LOGS_SUCCESS]: (state, { logs, jobId, activeInsightsTab }) => {
		if (jobId !== state.insightsDetailLogsRequestId || !state.insightsLogs) {
			return { ...state }
		}
		const classifier = activeInsightsTab === Constants.FIXED_TABS[0] ? 'serial' : 'metric'
		const logsByClassifier = {}
		logs.forEach((log) => {
			if (log[classifier] in logsByClassifier) {
				logsByClassifier[log[classifier]].push(log)
			} else {
				logsByClassifier[log[classifier]] = [log]
			}
		})
		const insightsLogs = [...state.insightsLogs]
		insightsLogs.forEach((log) => {
			if (log[classifier] in logsByClassifier) {
				log.details_data = logsByClassifier[log[classifier]]
				log.details_loading = false
				log.details_nomore = false
				if (log.num_critical_devices + log.num_healthy_devices + log.num_warning_devices <= log.details_data.length) {
					log.details_nomore = true
				}
			}
		})

		return {
			...state,
			insightsLogs,
			insightsDetailLogsLoading: false,
			insightsDetailLogsError: null,
			insightsDetailLogsRequestId: jobId
		}
	},
	[actionTypes.FETCH_INSIGHTS_DETAIL_LOGS_FAILURE]: (state, { error }) => {
		return {
			...state,
			insightsDetailLogsLoading: false,
			insightsDetailLogsError: error,
			insightsDetailLogsRequestid: null
		}
	},
	/*----- More Detail Logs Reducers -----*/
	[actionTypes.FETCH_MORE_DETAIL_LOGS_REQUEST]: (state, { logIndex }) => {
		if (!state.insightsLogs) {
			return { ...state }
		}
		// console.log('------- loading more details')
		const insightsLogs = [...state.insightsLogs]
		const log = insightsLogs[logIndex]
		log.details_loading = true
		return {
			...state,
			insightsLogs
		}
	},
	[actionTypes.FETCH_MORE_DETAIL_LOGS_SUCCESS]: (state, { details, rowsInJob, logIndex, jobId, currentTabJob }) => {
		if ((currentTabJob && currentTabJob !== state.insightsLogsRequestId) || _.isEmpty(state.insightsLogs)) {
			// console.log('Drop it')
			return { ...state }
		} else {
			// console.log('------- loading more details done')
			const insightsLogs = [...state.insightsLogs]
			const log = insightsLogs[logIndex]
			if (log.details_page === -1) {
				log.details_data = details
			} else {
				log.details_data = log.details_data.concat(details)
			}
			if (log.details_data.length === rowsInJob) {
				log.details_nomore = true
			} else {
				log.details_page = log.details_page + 1
			}

			log.details_loading = false
			log.details_jobId = jobId
			return {
				...state,
				insightsLogs
			}
		}
	},
	[actionTypes.FETCH_MORE_DETAIL_LOGS_FAILURE]: (state, { error, logIndex }) => {
		if (!state.insightsLogs) {
			return { ...state }
		}
		const insightsLogs = [...state.insightsLogs]
		const log = insightsLogs[logIndex]
		log.details_loading = false
		return {
			...state,
			insightsLogs
		}
	},
	/*----- Query Related Reducers -----*/
	[actionTypes.SET_INSIGHTS_QUERY]: (state, { query }) => {
		return {
			...state,
			insightsQuery: query
		}
	},
	[actionTypes.UPDATE_INSIGHTS_TIME_RANGE]: (state) => {
		const currentTime = moment()
		return {
			...state,
			insightsEndTime: currentTime.valueOf(),
			insightsStartTime: currentTime.subtract(Constants.DEFAULT_TIME_RANGE, 'd').valueOf()
		}
	},
	/*----- Page Related Reducers -----*/
	[actionTypes.UPDATE_INSIGHTS_PAGE_LIMIT]: (state, { pageLimit }) => {
		return {
			...state,
			insightsPageLimit: pageLimit
		}
	},
	[actionTypes.UPDATE_INSIGHTS_PAGE_NUMBER]: (state, { pageNumber }) => {
		return {
			...state,
			insightsLogsLoading: true,
			insightsPageNumber: pageNumber
		}
	},
	[actionTypes.SET_INSIGHTS_PAGE_SIZE]: (state, { pageSize }) => {
		return {
			...state,
			insightsLogsLoading: true,
			insightsPageSize: pageSize
		}
	},
	[actionTypes.RESET_INSIGHTS_PAGE]: (state) => {
		return {
			...state,
			insightsPageLimit: Constants.DEFAULT_PAGE_LIMIT,
			insightsPageNumber: 0
		}
	},
	[actionTypes.RESTORE_INSIGHTS_PAGE]: (state, { data }) => {
		return {
			...state,
			insightsPageLimit: Constants.DEFAULT_PAGE_LIMIT,
			insightsPageNumber: 0,
			...data
		}
	}
}

const initialState = {
	insightsMainPageJobId: null,
	insightsMainPageJobError: null,
	healthStatus: null,
	healthStatusLoading: true,
	healthStatusError: null,
	healthStatusRequestId: null,
	hexgridSettings: { 'insights.devices': { sortBy: 'model', healthFilter: false }, 'insights.metrics': { healthFilter: false } },
	hexgrid: null,
	hexgridLoading: true,
	hexgridError: null,
	hexgridRequestId: null,
	insightsLogs: null,
	insightsLogsLoading: true,
	insightsLogsError: null,
	insightsLogsRequestid: null,
	insightsLogsCount: null,
	insightsLogsCountLoading: true,
	insightsLogsCountError: null,
	insightsLogsCountRequestid: null,
	insightsDetailLogsLoading: true,
	insightsDetailLogsError: null,
	insightsDetailLogsRequestid: null,
	insightsSortField: 'severity_value',
	insightsSortOrder: 'DESC',
	insightsPageSize: Constants.DEFAULT_PAGE_SIZE,
	insightsPageLimit: Constants.DEFAULT_PAGE_LIMIT,
	insightsPageNumber: 0,
	insightsQuery: '',
	insightsEndTime: null,
	insightsStartTime: null
}

export const insightsMainReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
