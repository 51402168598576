import warning from "rc-util/lib/warning";

export function getMotion({
  prefixCls,
  motion,
  openAnimation,
  openTransitionName
}) {
  if (motion) {
    return motion;
  }

  if (typeof openAnimation === "object" && openAnimation) {
    warning(
      false,
      "Object type of `openAnimation` is removed. Please use `motion` instead."
    );
  } else if (typeof openAnimation === "string") {
    return {
      motionName: `${prefixCls}-open-${openAnimation}`
    };
  }

  if (openTransitionName) {
    return {
      motionName: openTransitionName
    };
  }

  return null;
}
