import React from 'react'
import moment from 'moment'
import { connect } from 'ui-lib'
import MasterDetailGridWidget from './MasterDetailGridWidget/MasterDetailGridWidget'
import RuleNameCellRenderer from '../../../../components/CellRenderers/RuleNameCellRenderer'
import DeleteCellRenderer from '../../../../components/CellRenderers/DeleteCellRenderer'
import EnableCellRenderer from '../../../../components/CellRenderers/EnableCellRenderer/EnableCellRenderer'

import Util from '../../../../utils/Util'

import * as alertsActions from '../../../../store/actions'
import * as actions from '../../store/actions'
import './NotificationsTable.scss'

import iconLoading from 'ui-lib/src/images/icon-loading.svg'

class NotificationsTable extends React.Component {
	state = {
		resetSchema: false,
		defaultColDef: { flex: 1 },
		frameworkComponents: { RuleNameCellRenderer, DeleteCellRenderer, EnableCellRenderer }
	}

	componentDidUpdate(prevProps) {
		if (this.gridApi && this.gridColumnApi && prevProps.alertsSchema !== this.props.alertsSchema) {
			this.gridColumnApi.resetColumnState()
			this.gridApi.sizeColumnsToFit()
		}

		if (prevProps.rules !== this.props.rules) {
			if (this.gridApi) this.gridApi.redrawRows()
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api
		this.gridColumnApi = params.columnApi
		this.gridApi.sizeColumnsToFit()
	}

	onGridResized = () => {
		if (this.gridApi) {
			this.gridApi.sizeColumnsToFit()
			this.gridApi.resetRowHeights()
		}
	}

	getMainMenuItems = (params) => {
		const itemsToExclue = ['autoSizeThis', 'autoSizeAll', 'resetColumns']
		const menu = params.defaultItems.filter((item) => !itemsToExclue.includes(item))
		menu.push({
			name: 'Reset Columns',
			action: () => {
				this.props.resetAlertsSchema(this.props.activeAlertsTab.split('.')[1])
			}
		})
		return menu
	}

	getRowHeight = () => {
		return 28
	}

	getRowStyle = (params) => {
		if (parseInt(params.node.id) % 2 === 0) {
			return { background: 'white' }
		} else {
			return { background: '#f4f5f5' }
		}
	}

	render() {
		const { activeAlertsTab, alertsSchema, rules, rulesLoading, rulesError } = this.props

		if (rulesError) {
			return <div className="info-area">Error Fetching Notifications</div>
		}

		if (rulesLoading) {
			return (
				<div className="info-area">
					<img className="loading-spinner" src={iconLoading} alt={'loading'} />
					Loading Data
				</div>
			)
		}

		if (_.isEmpty(rules)) {
			return <div className="info-area">No Data Found</div>
		}

		const [vendor, type] = activeAlertsTab.split('.')
		const fields = alertsSchema[vendor].content[type].fields //.filter((field) => field.name !== 'severity')

		const columns = _.map(fields, (fieldObj, i) => {
			const col = {
				lockPosition: true,
				lockVisible: true,
				flex: null,
				headerName: fieldObj.displayName,
				field: fieldObj.name,
				colId: fieldObj.name,
				pinned: fieldObj.pinned,
				resizable: true,
				hide: fieldObj.hidden,
				filterable: fieldObj.filterable,
				width: fieldObj.defaultWidth,
				minWidth: 80,
				suppressSizeToFit: true,
				valueFormatter: (params) => {
					if (['true', 'false', 'yes', 'no'].includes(params.value)) {
						return _.capitalize(params.value)
					} else {
						return params.value
					}
				},
				cellStyle: (params) => {
					if (params.data.pause_start_time > 0 || params.data.pause_end_time > 0) {
						//mark police cells as red
						return { color: '#B8B8B8' }
					}
				}
			}

			if (col.field === 'description') {
				col.flex = 1
			}

			if (col.field === 'name') {
				col.cellRenderer = 'RuleNameCellRenderer'
				col.cellRendererParams = {
					setEditingRule: (rule) => {
						this.props.setEditingRule(rule)
					}
				}
			}

			if (col.field === 'action') {
				col.cellRenderer = 'DeleteCellRenderer'
				col.cellRendererParams = {
					onRemove: (data) => this.props.removeRuleRequest(data.rule_id)
				}
			}

			if (col.field === 'status') {
				col.cellRenderer = 'EnableCellRenderer'
				col.cellRendererParams = {
					onEnable: (data) => {
						const ruleDef = {
							name: data.name,
							description: data.description,
							condition: data.condition,
							email_template_id: data.email_template_id,
							email_frequency: data.email_frequency,
							servicenow_template_id: data.servicenow_template_id,
							pause_start_time: 0,
							pause_end_time: 0
						}
						this.props.updateRuleRequest(data.rule_id, ruleDef)
					},
					onDisable: (data) => {
						const ruleDef = {
							name: data.name,
							description: data.description,
							condition: data.condition,
							email_template_id: data.email_template_id,
							email_frequency: data.email_frequency,
							servicenow_template_id: data.servicenow_template_id,
							pause_start_time: moment().unix(),
							pause_end_time: moment().add(100, 'y').unix()
						}
						this.props.updateRuleRequest(data.rule_id, ruleDef)
					}
				}
			}

			if (col.field === 'time' || col.field === 'last_hit_time') {
				col.valueFormatter = (params) => {
					if (params.value === 0) return 'Never'
					return params.value ? Util.getTransformedTime(params.value * 1000000) : ''
				}
			}

			return col
		})

		return (
			<MasterDetailGridWidget
				logType={activeAlertsTab}
				enableSorting={false}
				columnDefs={columns}
				defaultColDef={this.state.defaultColDef}
				getMainMenuItems={this.getMainMenuItems}
				frameworkComponents={this.state.frameworkComponents}
				rowData={rules}
				onGridReady={this.onGridReady}
				onGridSizeChanged={this.onGridResized}
				onColumnPinned={() => this.gridApi.redrawRows()}
				getRowHeight={this.getRowHeight}
				getRowStyle={this.getRowStyle}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alerts, ...state.alertsNotifications }
}

const mapDispatchToProps = { ...alertsActions, ...actions }

export default connect(mapStateToProps, mapDispatchToProps, null)(NotificationsTable)
