import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'ui-lib'

import NotificationsTable from './components/NotificationsTable/NotificationsTable'
import AddRuleDrawer from './components/AddRuleDrawer/AddRuleDrawer'
import addIcon from '../../../images/icon-plus.svg'

import * as alertsActions from '../../store/actions'
import * as actions from './store/actions'

import './AlertsNotifications.scss'

class AlertsNotifications extends Component {
	state = {
		isEditMode: false,
		editingRule: null,
		addRuleDrawerVisible: false
	}

	componentDidMount() {
		this.props.fetchAlertsNotificationsPageContent()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.activeAlertsTab !== this.props.activeAlertsTab) {
			//Scroll to Top
			//Fetch content
			this.props.fetchAlertsNotificationsPageContent()
		}
	}

	render() {
		const { rules, rulesLoading, rulesError } = this.props
		const totalRulesCount = _.get(rules, 'length', 0)
		const enabledRulesCount =
			totalRulesCount === 0
				? 0
				: rules.filter((rule) => {
						const currentTime = moment().unix()
						return rule.pause_start_time > currentTime || rule.pause_end_time < currentTime || (rule.pause_start_time <= 0 && rule.pause_end_time <= 0)
				  }).length
		return (
			<div className="alerts-notifications">
				<div className="notifications-summary">
					{rulesLoading || rulesError ? null : (
						<div className="notifications-stat">
							<div className="total-count">{`${totalRulesCount} Total Notification Rules`}</div>
							<div className="enabled-count">{`${enabledRulesCount} Enabled`}</div>
						</div>
					)}
					<div
						className="add-rule-btn"
						onClick={() => {
							this.setState({ editingRule: null, isEditMode: false, addRuleDrawerVisible: true })
						}}
					>
						<img src={addIcon} alt="add icon" className="add-icon" />
						<span className="text">Add Notification Rule</span>
					</div>
					<AddRuleDrawer
						editingRule={this.state.editingRule}
						isEditMode={this.state.isEditMode}
						addRuleDrawerVisible={this.state.addRuleDrawerVisible}
						closeDrawer={() => this.setState({ addRuleDrawerVisible: false, isEditMode: false })}
					/>
				</div>
				<div className="notifications-table">
					<NotificationsTable
						setEditingRule={(rule) => {
							this.setState({ isEditMode: true, editingRule: rule, addRuleDrawerVisible: true })
						}}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alerts, ...state.alertsNotifications }
}

const mapDispatchToProps = { ...alertsActions, ...actions }

export default connect(mapStateToProps, mapDispatchToProps, null)(AlertsNotifications)
