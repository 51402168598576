import React, { Component } from 'react'
import { connect } from 'ui-lib'
import './AccViewer.scss'
import ExploreHelper from '../helper/ExploreHelper'
import * as visibilityActions from '../../store/actions'

class AccViewer extends Component {
	constructor(props) {
		super(props)
		this.state = { loaded: false }
	}

	componentDidMount() {
		this.setState({ loaded: true })
		this.props.updateActiveApp('network')
	}

	shouldComponentUpdate(nextProps, nextState) {
		let doRender = true
		if (nextState.loaded) {
			doRender = false
		}
		return doRender
	}

	render() {
		const { tenant, loggedInUser } = this.props
		const extractTenantUserObj = ExploreHelper.extractTenantUser(tenant, loggedInUser)
		const { account, email, lstenant, lsregion, dstenant, dsregion } = extractTenantUserObj

		this.exploreProps = {
			viewContext: {
				query: '',
				component: 'acc'
			},
			appContext: {
				baseURL: window.__admin_cluster_url,
				appID: 'adelphi',
				user: {
					account,
					email,
					lstenant,
					lsregion,
					dstenant,
					dsregion
				}
			}
		}
		const ExploreAppWidget = window.explore.ExploreAppWidget
		return (
			<div id="explore-accviewer">
				<ExploreAppWidget {...this.exploreProps} />
			</div>
		)
	}
}

const mapStateToProps = (state) => ({ ...state.explore, ...state.main })

const mapDispatchToProps = { ...visibilityActions }

export default connect(mapStateToProps, mapDispatchToProps)(AccViewer)
