export const FETCH_ALERTS_NOTIFICATIONS_PAGE_CONTENT = 'FETCH_ALERTS_NOTIFICATIONS_PAGE_CONTENT'
export const REFRESH_ALERTS_TAB = 'REFRESH_ALERTS_TAB'
export const RESET_STORE = 'RESET_STORE'

export const FETCH_RULES_REQUEST = 'FETCH_RULES_REQUEST'
export const FETCH_RULES_SUCCESS = 'FETCH_RULES_SUCCESS'
export const FETCH_RULES_FAILURE = 'FETCH_RULES_FAILURE'
export const RESTORE_RULES = 'RESTORE_RULES'

export const ADD_RULE_REQUEST = 'ADD_RULE_REQUEST'
export const ADD_RULE_SUCCESS = 'ADD_RULE_SUCCESS'
export const ADD_RULE_FAILURE = 'ADD_RULE_FAILURE'

export const UPDATE_RULE_REQUEST = 'UPDATE_RULE_REQUEST'
export const UPDATE_RULE_SUCCESS = 'UPDATE_RULE_SUCCESS'
export const UPDATE_RULE_FAILURE = 'UPDATE_RULE_FAILURE'

export const REMOVE_RULE_REQUEST = 'REMOVE_RULE_REQUEST'
export const REMOVE_RULE_SUCCESS = 'REMOVE_RULE_SUCCESS'
export const REMOVE_RULE_FAILURE = 'REMOVE_RULE_FAILURE'

export const FETCH_EMAIL_TEMPLATES_REQUEST = 'FETCH_EMAIL_TEMPLATES_REQUEST'
export const FETCH_EMAIL_TEMPLATES_SUCCESS = 'FETCH_EMAIL_TEMPLATES_SUCCESS'
export const FETCH_EMAIL_TEMPLATES_FAILURE = 'FETCH_EMAIL_TEMPLATES_FAILURE'

export const ADD_EMAIL_TEMPLATE_REQUEST = 'ADD_EMAIL_TEMPLATE_REQUEST'
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'ADD_EMAIL_TEMPLATE_SUCCESS'
export const ADD_EMAIL_TEMPLATE_FAILURE = 'ADD_EMAIL_TEMPLATE_FAILURE'

export const REMOVE_EMAIL_TEMPLATE_REQUEST = 'REMOVE_EMAIL_TEMPLATE_REQUEST'
export const REMOVE_EMAIL_TEMPLATE_SUCCESS = 'REMOVE_EMAIL_TEMPLATE_SUCCESS'
export const REMOVE_EMAIL_TEMPLATE_FAILURE = 'REMOVE_EMAIL_TEMPLATE_FAILURE'

export const UPDATE_EMAIL_TEMPLATE_REQUEST = 'UPDATE_EMAIL_TEMPLATE_REQUEST'
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS'
export const UPDATE_EMAIL_TEMPLATE_FAILURE = 'UPDATE_EMAIL_TEMPLATE_FAILURE'

export const FETCH_CONFIG_REQUEST = 'FETCH_CONFIG_REQUEST'
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS'
export const FETCH_CONFIG_FAILURE = 'FETCH_CONFIG_FAILURE'

export const ADD_CONFIG_REQUEST = 'ADD_CONFIG_REQUEST'
export const ADD_CONFIG_SUCCESS = 'ADD_CONFIG_SUCCESS'
export const ADD_CONFIG_FAILURE = 'ADD_CONFIG_FAILURE'
export const REMOVE_CONFIG_ERROR = 'REMOVE_CONFIG_ERROR'

export const FETCH_SN_TEMPLATES_REQUEST = 'FETCH_SN_TEMPLATES_REQUEST'
export const FETCH_SN_TEMPLATES_SUCCESS = 'FETCH_SN_TEMPLATES_SUCCESS'
export const FETCH_SN_TEMPLATES_FAILURE = 'FETCH_SN_TEMPLATES_FAILURE'

export const ADD_SN_TEMPLATE_REQUEST = 'ADD_SN_TEMPLATE_REQUEST'
export const ADD_SN_TEMPLATE_SUCCESS = 'ADD_SN_TEMPLATE_SUCCESS'
export const ADD_SN_TEMPLATE_FAILURE = 'ADD_SN_TEMPLATE_FAILURE'

export const REMOVE_SN_TEMPLATE_REQUEST = 'REMOVE_SN_TEMPLATE_REQUEST'
export const REMOVE_SN_TEMPLATE_SUCCESS = 'REMOVE_SN_TEMPLATE_SUCCESS'
export const REMOVE_SN_TEMPLATE_FAILURE = 'REMOVE_SN_TEMPLATE_FAILURE'

export const UPDATE_SN_TEMPLATE_REQUEST = 'UPDATE_SN_TEMPLATE_REQUEST'
export const UPDATE_SN_TEMPLATE_SUCCESS = 'UPDATE_SN_TEMPLATE_SUCCESS'
export const UPDATE_SN_TEMPLATE_FAILURE = 'UPDATE_SN_TEMPLATE_FAILURE'

export const FETCH_SN_GROUPS_REQUEST = 'FETCH_SN_GROUPS_REQUEST'
export const FETCH_SN_GROUPS_SUCCESS = 'FETCH_SN_GROUPS_SUCCESS'
export const FETCH_SN_GROUPS_FAILURE = 'FETCH_SN_GROUPS_FAILURE'

export const FETCH_SN_GROUPUSERS_REQUEST = 'FETCH_SN_GROUPUSERS_REQUEST'
export const FETCH_SN_GROUPUSERS_SUCCESS = 'FETCH_SN_GROUPUSERS_SUCCESS'
export const FETCH_SN_GROUPUSERS_FAILURE = 'FETCH_SN_GROUPUSERS_FAILURE'
