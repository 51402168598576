import React, { Component } from 'react'
import iconCheckboxTrue from 'ui-lib/src/images/icon-checkbox-true.svg'
import iconCheckboxFalse from 'ui-lib/src/images/icon-checkbox-false.svg'
import iconRadioOn from 'ui-lib/src/images/icon-radio-on.svg'
import iconRadioOff from 'ui-lib/src/images/icon-radio-off.svg'
import Constants from '../../../../../utils/Constants.json'

export default class Setting extends Component {
	render() {
		const { sortBy, healthFilter, onSettingChange, activeInsightsTab } = this.props

		return (
			<>
				{activeInsightsTab === Constants.FIXED_TABS[0] ? (
					<div className="option-sort">
						<div className="option-title">Group By</div>
						<div className="option">
							<img className="radio-icon" src={sortBy === 'model' ? iconRadioOn : iconRadioOff} alt={'radio'} onClick={() => onSettingChange({ sortBy: 'model' })} />
							Model
						</div>
						<div className="option">
							<img className="radio-icon" src={sortBy === 'version' ? iconRadioOn : iconRadioOff} alt={'radio'} onClick={() => onSettingChange({ sortBy: 'version' })} />
							Software Version
						</div>
					</div>
				) : null}
				<div className="option-health">
					<div className="option-title">Show</div>
					<div className="option">
						<img
							className="checkbox-icon"
							src={healthFilter === true ? iconCheckboxTrue : iconCheckboxFalse}
							alt={'checkbox'}
							onClick={() => onSettingChange({ healthFilter: !healthFilter })}
						/>
						{activeInsightsTab === Constants.FIXED_TABS[0] ? 'Healthy Devices' : 'Healthy Metrics'}
					</div>
				</div>
			</>
		)
	}
}
