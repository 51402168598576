export default function gridSpaceToScreenSpace (tuple) {
  const o = {
    x: tuple.x * 45,
    y: tuple.y * 45
  }
  const multX = 1
  const multY = 0.58 * multX
  o.x *= multX * .89
  o.y *= multY * .87
  return o
}