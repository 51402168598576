import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Popover } from 'antd'
import closeIcon from '../../images/icon-tab-close.svg'

export default class NavSubItem extends Component {
	render() {
		const { activeApp, appName, componentPath, onItemClick, onItemClose, index, itemInfo, icons, sideMenuOpen, setSubMenuOwner } = this.props
		const itemContent = (
			<div
				className={`sub-item ${activeApp === appName && itemInfo.active ? 'active' : ''}`}
				onClick={() => {
					onItemClick(itemInfo.id)
					setSubMenuOwner(null)
				}}
			>
				<Link to={componentPath}>
					{icons && index === 0 ? (
						<div className="sub-item-icon">
							<img alt="sub-item-icon" src={activeApp === appName ? icons.active : icons.normal} />
						</div>
					) : null}
					<div className="sub-item-name">{itemInfo.name}</div>
				</Link>
				{index !== 0 ? (
					<div className="sub-item-close">
						<img
							src={closeIcon}
							alt="sub-item-close"
							onClick={(e) => {
								e.stopPropagation()
								onItemClose(itemInfo.id)
							}}
						/>
					</div>
				) : null}
			</div>
		)

		return index !== 0 ? (
			<Popover prefixCls="nav-popover pan-popover" arrowPointAtCenter={true} placement="right" content={itemInfo.name} trigger="hover" align={{ offset: [-2, 0] }}>
				{itemContent}
			</Popover>
		) : (
			itemContent
		)
	}
}
