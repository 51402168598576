import { ajax as Ajax } from 'rxjs/ajax'
import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import moment from 'moment'
import { getAuthHeaders, getCustomServiceURL, getLoginServiceURL, MOCK_SERVICES } from 'ui-lib'
import { getStoreState } from 'ui-lib'

import Constants from '../utils/Constants.json'
import Util from '../utils/Util'

const relogin = () => {
	let loginUrl = getLoginServiceURL('api/sso/v1/login')['SERVICE_URL']
	//tenant from redux store
	let store = getStoreState()
	let tenantId = _.get(store, 'main.tenant.id', null)
	if (tenantId) {
		loginUrl += `?tenantId=${tenantId}`
	}
	window.location = loginUrl
}

const ajax = (obj) => {
	return Ajax(obj).pipe(
		map((r) => {
			// on redirection response is null & req url != resp url
			if (r.xhr.status === 403) {
				return relogin()
			}
			return r
		}),
		catchError((e) => {
			if (e.xhr.status === 403) {
				return relogin()
			} else {
				return throwError(e)
			}
		})
	)
}

export function pollAlertsJob(currentJobId, pageNumber) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/polljob')
	SERVICE_URL = `${SERVICE_URL}?jobId=${currentJobId}&pageNumber=${pageNumber}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders()
	})
}

/*------------- Alerts APIs -------------*/
export function getAlerts(store, status) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/alert/alerts')
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}&size=10000&sort=Time%3ADESC%7CSeverity%3ADESC&since=${moment(store.alertsMain.alertsTimeRange[0]).unix()}&until=${moment(
		store.alertsMain.alertsTimeRange[1]
	).unix()}&include_status=${status}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function getAlert(store, alertId) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/alert/alerts/${alertId}`)
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function updateAlert(store, alertId, status, assignee) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/alert/alerts/${alertId}`)
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			status,
			assignee
		}
	})
}

/*------------- Comments APIs -------------*/
export function getComments(store, alertId) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/alert/alerts/${alertId}/comments`)
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function addComment(store, alertId, comment) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/alert/alerts/${alertId}/comments`)
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			comment: comment
		}
	})
}

export function updateComment(store, alertId, commentId, comment) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/alert/alerts/${alertId}/comments/${commentId}`)
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			comment
		}
	})
}

export function removeComment(store, alertId, commentId) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/alert/alerts/${alertId}/comments/${commentId}`)
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

/*------------- Events APIs -------------*/
export function getEvents(store, alertId) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/alert/alerts/${alertId}/events`)
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

/*------------- Activities APIs -------------*/
export function getActivities(store, subject, object_id) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/activity/activities`)
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}&subject=${encodeURIComponent('system@paloaltonetworks.com')}&object_id=${object_id}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

/*------------- Devices Details APIs -------------*/
export function getDevicesDetails(store, devices) {
	const query = devices
		.map((device) => {
			return `serial = '${device}'`
		})
		.join(' OR ')
	const body = {
		limit: 1000,
		endTime: moment().valueOf(),
		startTime: moment(store.alertsMain.alertsTimeRange[0]).subtract(30, 'days').valueOf(),
		defaultPageSize: 1000,
		sortField: 'severity_value',
		sortOrder: 'DESC',
		query,
		logtenant: store.visibility.tenantId,
		timeRangeField: 'timegenerated'
	}

	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/devices/aggregated')

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

/*-------------- Charts Related APIs -----------------*/

export function getTimeline(store, device, metric) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/timeline')

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			defaultPageSize: 10000,
			limit: 10000,
			device,
			metric,
			endTime: store.alertDetails.alertTimelineEndTime,
			startTime: store.alertDetails.alertTimelineStartTime,
			logtenant: store.visibility.tenantId,
			query: '',
			sortField: 'timegenerated',
			sortOrder: 'ASC'
		}
	})
}

export function getMovingAverage(store, device, metric) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/mavg')

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			avg_window: '1d',
			defaultPageSize: 10000,
			limit: 10000,
			device,
			metric,
			endTime: store.alertDetails.alertTimelineEndTime,
			startTime: moment(store.alertDetails.alertTimelineStartTime).subtract(7, 'd').valueOf(),
			logtenant: store.visibility.tenantId,
			query: '',
			sortField: 'timegenerated',
			sortOrder: 'ASC'
		}
	})
}

export function getCommitEvents(store, device) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/commit')

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			defaultPageSize: 10000,
			limit: 10000,
			endTime: store.alertDetails.alertTimelineEndTime,
			startTime: store.alertDetails.alertTimelineStartTime,
			logtenant: store.visibility.tenantId,
			device,
			sortField: 'timegenerated',
			sortOrder: 'ASC'
		}
	})
}

export function getForecast(store, device, metric) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/forecast/device/${device}/metric/${metric}/prediction`)
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders()
	})
}

export function getCapacity(store, deviceInfo, metricList) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/capacity')
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			metrics: metricList,
			model: deviceInfo.capacity_model ? deviceInfo.capacity_model : deviceInfo.model,
			version: deviceInfo.sw_version.match(/^\d+.\d+/g)[0],
			logtenant: store.visibility.tenantId,
			limit: 10000
		}
	})
}

/*--------------- Table Charts ------------------*/
export function getChartInfoJob(store, jobApi, node) {
	let { SERVICE_URL } = getCustomServiceURL(`api/visibility/v1/insights${jobApi}`)

	const currentTime = moment()
	const body = {
		device: node.serial,
		endTime: currentTime.valueOf(),
		startTime: currentTime.subtract(Constants.DEFAULT_TIME_RANGE, 'd').valueOf(),
		logtenant: store.visibility.tenantId,
		timeRangeField: 'timegenerated'
	}

	if (jobApi === '/cve') {
		body.version = `${node.sw_version.match(/^\d+.\d+.\d+/g)[0]}`
		body.sortField = 'update_date'
		body.sortOrder = 'DESC'
	}

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function getChartInfo(store, api, node) {
	let { SERVICE_URL } = getCustomServiceURL(`api/visibility/v1/insights${api}`)
	const body = { logtenant: store.visibility.tenantId }
	if (api === '/eol') {
		body.product = 'PAN-OS and Panorama'
		body.version = node.sw_version.match(/^\d+.\d+/g)[0]
		body.limit = 100
	}
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

/*------------- Notifications Rules APIs --------------*/
export function getRules(store) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/rules')
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function addRule(store, ruleDef) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/rule')
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	const body = ruleDef
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function updateRule(store, ruleId, ruleDef) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/rule')
	SERVICE_URL = `${SERVICE_URL}/${ruleId}?${Util.getTenantInfoParams(store)}`
	const body = ruleDef
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function removeRule(store, ruleId) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/rule')
	SERVICE_URL = `${SERVICE_URL}/${ruleId}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}
/*------------- Email Template APIs --------------*/
export function getEmailTemplates(store) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/email/templates')
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function addEmailTemplate(store, template) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/email/template')
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	const body = template
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function updateEmailTemplate(store, templateId, template) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/email/template')
	SERVICE_URL = `${SERVICE_URL}/${templateId}?${Util.getTenantInfoParams(store)}`
	const body = template
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function removeEmailTemplate(store, templateId) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/email/template')
	SERVICE_URL = `${SERVICE_URL}/${templateId}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

/*----------- Service Now Apis -------------*/
export function getConfig(store) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/servicenow/configuration')
	SERVICE_URL = `${SERVICE_URL}?csp_id=${store.visibility.cspId}&tenant_id=${store.visibility.tenantId}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function addConfig(store, config) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/servicenow/configuration')
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	const body = config
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function getSNTemplates(store) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/servicenow/templates')
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function addSNTemplate(store, template) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/servicenow/template')
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	const body = template
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function updateSNTemplate(store, templateId, template) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/servicenow/template')
	SERVICE_URL = `${SERVICE_URL}/${templateId}?${Util.getTenantInfoParams(store)}`
	const body = template
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function removeSNTemplate(store, templateId) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/servicenow/template')
	SERVICE_URL = `${SERVICE_URL}/${templateId}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function getSNGroups(store) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/servicenow/groups')
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function getSNGroupUsers(store, groupId) {
	let { SERVICE_URL } = getCustomServiceURL('api/di/notification/servicenow/groups')
	SERVICE_URL = `${SERVICE_URL}/${groupId}/users?${Util.getTenantInfoParams(store)}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}
