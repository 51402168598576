import React from 'react'
import { DrawerWidget as Drawer } from 'ui-lib'
import ExportConfig from './LogExportConfig'
import ExportHistory from './LogExportHistory'

export default class LogExportPanel extends React.Component {
	render() {
		let { exportConfigVisible, exportHistoryVisible, onCloseExportConfig, onCloseExportHistory } = this.props
		let drawerContent, title, onClose, width
		if (this.props.exportConfigVisible) {
			title = 'Export Configuration'
			drawerContent = <ExportConfig {...this.props} />
			onClose = onCloseExportConfig
			width = 500
		}

		if (this.props.exportHistoryVisible) {
			title = 'Export History'
			drawerContent = <ExportHistory {...this.props} />
			onClose = onCloseExportHistory
			width = 800
		}
		return (
			<Drawer
				className="export-panel"
				getContainer={'body'}
				title={title}
				mask={true}
				placement="right"
				closable={true}
				onClose={onClose}
				visible={exportConfigVisible || exportHistoryVisible}
				width={width}
			>
				{drawerContent}
			</Drawer>
		)
	}
}
