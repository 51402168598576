import { findLast } from 'lodash'

const NUM = ['=', '!=', '>', '<', '>=', '<=']
const DEFAULT = ['=', '!=']

const STRING_TYPES = new Set(['string', 'pan_prefix_string', 'enum'])
const INT_TYPES = new Set(['int', 'uint16', 'uint32', 'uint64'])

export const isStringTypes = (type) => STRING_TYPES.has(type)
export const isIntTypes = (type) => INT_TYPES.has(type)
export const TYPE_OP_MAPPING = {
	int: NUM,
	uint16: NUM,
	uint32: NUM,
	uint64: NUM,
	timestamp: NUM,
	string: ['=', '!='],
	pan_prefix_string: ['=', '!='], // 'in', 'notin'
	enum: DEFAULT,
	'': DEFAULT,
}

// internal use only
const quoted = (str) => `'${str.replace ? str.replace(/'/g, "''") : str}'`
export const quotes = (str) => {
	if (!str.includes("'")) {
		return `'${str}'`
	} else if (!str.includes('"')) {
		return `"${str}"`
	} else if (!str.includes('`')) {
		return `\`${str}\``
	}
	return quoted(str)
}

// initally ported from panos htdocs/js/pan/base/Evaluation.js
// modified save tokens with kind and support not and ==/!=

export const tokenize = (text) => {
	let c // The current character.
	let from // The index of the start of the token.
	let i = 0 // The index of the current character.
	let q // The quote character.
	let str // The string value.

	const result = [] // An array to hold the results.

	// Make a token object.
	const make = (type, value) => ({
		type,
		value,
		from,
		to: i,
	})
	const makeErr = (msg, type, value) => {
		const bad = { ...make(type, value), quoted: q }
		result.push(bad)
		throw Object.assign(new SyntaxError(msg), bad, { tokens: result })
	}
	const isNum = (c) => c >= '0' && c <= '9'

	// Begin tokenization. If the source string is empty, return nothing.

	if (!text) {
		return
	}

	// Loop through text, one character at a time.

	c = text.charAt(i)
	while (c) {
		from = i

		// Ignore whitespace.
		if (c <= ' ') {
			i += 1
			c = text.charAt(i)
			// name.
		} else if ((c >= '0' && c <= '9') || (c >= 'a' && c <= 'z') || (c >= 'A' && c <= 'Z') || (c === '-' && isNum(text.charAt(i + 1)))) {
			str = c
			i += 1
			for (;;) {
				c = text.charAt(i)
				if (
					(c >= 'a' && c <= 'z') ||
					(c >= 'A' && c <= 'Z') ||
					(c >= '0' && c <= '9') ||
					c === '_' ||
					c === '-' ||
					c === '.' ||
					c === '~' ||
					c === '`' ||
					c === '!' ||
					c === '@' ||
					c === '#' ||
					c === '$' ||
					c === '%' ||
					c === '^' ||
					c === '&' ||
					c === '*' ||
					c === '=' ||
					c === '+' ||
					c === '{' ||
					c === '}' ||
					c === '[' ||
					c === ']' ||
					c === '|' ||
					c === ';' ||
					c === ':' ||
					c === '<' ||
					c === '>' ||
					c === ',' ||
					c === '?' ||
					c === '/' ||
					c === "'" ||
					c === '"'
				) {
					// allow ' and " direclty inside string
					str += c
					i += 1
				} else {
					break
				}
			}
			result.push(make('string', str))
			//result.push(make('name', str));

			// string
		} else if (c === "'" || c === '"' || c === '`') {
			str = ''
			q = c
			i += 1
			for (;;) {
				c = text.charAt(i)
				// allow Control character in string, some code removed
				if (c === '') {
					// in case i >= length
					throw makeErr('Unterminated string', 'string', str)
				}

				// Look for the closing quote.
				if (c === q) {
					// only support escapted quotes
					if (text.charAt(i + 1) === q) {
						c = q
						i += 1
					} else {
						break
					}
				}

				// Look for escapement.
				// log query string is different from javascript string - it does not support backslash escapes
				// (code removed)

				str += c
				i += 1
			}
			i += 1
			result.push({ ...make('string', str), quoted: q })
			c = text.charAt(i)

			// single-character operator
		} else {
			// add support for == != <> >= <=
			i += 1
			let op = c
			c = text.charAt(i)
			if ((op === '=' || op === '!' || op === '>' || op === '<') && c === '=') {
				i += 1
				op += c
				c = text.charAt(i)
				// if (c === '=') { // === and !==
				//   i += 1
				//   op += c
				//   c = text.charAt(i)
				// }
			} else if (op === '<' && c === '>') {
				// <>
				i += 1
				op += c
				c = text.charAt(i)
			}
			result.push(make('operator', op))
		}
	}
	return result
}

export const parseQuery = (() => {
	let token
	let tokens
	let token_nr
	let errors = []

	const peek_token = () => {
		if (token_nr >= tokens.length) {
			return { value: '(end)', id: '(end)', type: '(end)' }
		}
		token = tokens[token_nr]
		return token
	}

	const next_token = () => {
		token = peek_token()
		token_nr += 1
		return token
	}

	const assert_token = (condition, msg, warn) => {
		if (!condition) {
			if (!warn) {
				throw new SyntaxError(msg)
			}
			errors.push(new SyntaxError(msg))
		}
	}

	const expression = (left) => {
		const t = peek_token()
		const upper = t.value.toUpperCase()
		if (t.type === '(end)') {
			return left
		}
		switch (upper) {
			case '(':
				t.kind = 'paren'
				return expression(parenthesized_expression())
			case ')':
				t.kind = 'paren'
				return left
			case 'NOT':
			case '!':
				return expression(compound_op())
			case 'AND':
			case 'OR':
			case '&&':
			case '||':
				assert_token(left, 'expression left is missing')
				return expression(compound_op(left))
			default:
				assert_token(!left, 'do not expect expression to the left')
				return expression(simple_op())
		}
	}

	// expr and expr
	const compound_op = (left) => {
		const t = next_token()
		const o = {}
		o.op = t.value.toUpperCase()
		const right = expression()
		if (!left) {
			// added support not
			t.kind = 'not' // or in
			t.exp = right
			o.args = [right]
			return o
		} else {
			t.kind = 'andor'
		}
		assert_token(right, 'expression right is missing', true)
		if (right && right.op === o.op) {
			// combine multiple and or
			right.args.unshift(left)
			return right
		}
		o.args = [left, right]
		return o
	}

	// a op b
	const simple_op = () => {
		const o = {}
		const tokens = [next_token(), next_token(), next_token()]
		o.args = [tokens[0].value, tokens[2].value]
		o.op = tokens[1].value
		// added
		const kinds = ['key', 'op', 'value']
		tokens.forEach((t, i) => {
			t.kind = kinds[i]
			t.exp = o // cross ref
		})
		return o
	}

	const parenthesized_expression = () => {
		next_token() // open
		const e = expression()
		const close = next_token()
		assert_token(close.value === ')', 'expecting close parenthesis', true)
		return e
	}

	return (source) => {
		tokens = Array.isArray(source) ? source : tokenize(source)
		token_nr = 0
		errors = []
		return { tree: expression(), tokens, errors }
	}
})()

const END = '(end)'
const EMPTY_MAP = new Map()

export class QueryExpression {
	constructor(exp, fields, { typeOpMapping = TYPE_OP_MAPPING, supportNot = true, supportParen = true } = {}, commonFilters) {
		Object.assign(this, { typeOpMapping, supportNot, supportParen })
		this.parse(exp, fields, commonFilters)
	}

	static parse(exp, fields, options, commonFilters) {
		return new QueryExpression(exp, fields, options, commonFilters)
	}

	static isStringTypes = isStringTypes
	static isIntTypes = isIntTypes

	cacheFieldMap(fields) {
		if (!fields || !Array.isArray(fields) || !fields.length) {
			return EMPTY_MAP
		}
		if (fields.cachedMap) {
			return fields.cachedMap
		}
		fields.cachedMap = new Map(fields.map((f) => [f.name, f]))
		return fields.cachedMap
	}

	parse(exp = this.query, fields = this.fields, commonFilters) {
		this.query = ''
		this.tree = null
		this.tokens = []
		this.errors = []
		this.warnings = []
		this.fieldMap = this.cacheFieldMap(fields)
		if (!exp || (exp.trim && !exp.trim())) {
			return this
		}
		if (!this.supportParen && /[()]/.test(exp)) {
			this.errors = [new Error('Parentheses are not supported')]
			return this
		}

		this.query = exp
		try {
			this.tokens = tokenize(exp)
		} catch (e) {
			this.errors = [e]
			if (e.tokens) {
				this.tokens = e.tokens
			} else {
				this.tree = null
				this.validate()
				return this
			}
		}
		try {
			const { errors, tree } = parseQuery(this.tokens)
			if (!tree) {
				this.tree = null
				errors.push(new Error('incomplete expression'))
				this.errors.push(...errors)
				return this
			}
			if (tree.op === END || tree.args.some((a) => a === END) || findLast(this.tokens, (t) => t.exp && (t.exp.op === END || t.exp.args.some((a) => a === END)))) {
				errors.push(new SyntaxError('expression is incomplete'))
			}
			this.tree = tree || null
			this.errors.push(...errors)
		} catch (err) {
			this.tree = null
			this.errors.push(err)
		}

		this.validate()
		if (this.errors.length === 0 && commonFilters && commonFilters.length !== 0) {
			this.tokens.forEach((token) => {
				if (token.kind === 'key' && !commonFilters.includes(token.value)) {
					this.warnings.push('This operation may take some time. Please remove some filters for a faster response')
				}
			})
		}
		return this
	}

	validate() {
		// associate field and validate
		const { fieldMap, typeOpMapping, tokens, errors, supportNot, supportParen } = this
		tokens.forEach((token) => {
			switch (token.kind) {
				case 'key':
					if (fieldMap.has(token.value)) {
						const field = fieldMap.get(token.value)
						token.field = {
							...field,
							operators: field.operators || typeOpMapping[field.type || ''] || typeOpMapping[''],
						}
						if (token.exp) {
							token.exp.field = token.field
						}
						if (field.index === false) {
							errors.push(new Error(`field ${token.value} is not allowed`))
						}
					} else {
						errors.push(new Error(`unsupported field name ${token.value}`))
					}
					break
				case 'op':
					if (token.exp.field) {
						token.field = token.exp.field
						if (token.field.operators && !token.field.operators.includes(token.value.toUpperCase())) {
							errors.push(new Error(`unsupported operator ${token.value} for field ${token.field.name}`))
						}
					}
					break
				case 'value':
					if (token.exp.field) {
						token.field = token.exp.field
						if (token.field && token.field.type) {
							if (token.field.enum) {
								const { keySet = new Set(Object.keys(Object.assign({}, ...token.field.enum))) } = token.field.enum
								if (!token.field.enum.keySet) {
									// cache
									token.field.enum.keySet = keySet
								}
								if (!keySet.has(token.value)) {
									errors.push(new TypeError(`${token.field.name} does not support enum '${token.value}'`))
								}
								if (token.quoted !== "'" && (isNaN(+token.value) || token.quoted)) {
									errors.push(new TypeError(`enum string ${token.value} is not quoted with '`))
								}
							} else if (token.field.type === 'timestamp') {
								const value = token.value.trim()
								if (!value || isNaN(new Date(value >= 0 ? +value * 1000 : value).getDate())) {
									errors.push(new TypeError(`bad timestamp ${value}`))
								}
							} else if (token.field.type === 'boolean') {
								const value = token.value.trim().toLowerCase()
								if (value !== 'true' && value !== 'false') {
									errors.push(new TypeError(`bad boolean value ${value}`))
								} else if (token.quoted) {
									errors.push(new TypeError(`quoted ${token.field.type} value`))
								}
							} else if (STRING_TYPES.has(token.field.type)) {
								if (!token.quoted && /['"`]$/.test(token.value)) {
									errors.push(new TypeError(`string value ${token.value} is not quoted but ends with a quote`))
								}
							} else if (INT_TYPES.has(token.field.type)) {
								const int = +token.value
								if (!Number.isInteger(int) || (token.field.type.startsWith('uint') && !(int >= 0))) {
									errors.push(new TypeError(`invalid ${token.field.type} value: ${token.value}`))
								} else if (token.quoted) {
									errors.push(new TypeError(`quoted ${token.field.type} value`))
								}
							}
						}
					}
					break
				case 'not':
					if (!supportNot) {
						errors.push(new Error('NOT is not supported'))
					} else if (tokens.length < 3) {
						errors.push(new Error('incomplete expression'))
					}
					break
				case 'andor':
					break
				case 'paren':
					if (!supportParen) {
						errors.push(new Error('Parentheses are not supported'))
					}
					break
				default:
					errors.push(new Error(`unrecognized expression part ${token.value}`))
			}
		})
		return !this.errors.length
	}

	forEachToken(fn) {
		if (this.tokens && this.tokens.length) {
			this.tokens.forEach(fn)
		}
		return this
	}

	isEmpty() {
		return !this.query || !this.tokens.length
	}

	isValid() {
		return Boolean(this.tree) && this.errors.length === 0
	}

	toString() {
		return this.tokens
			.map((t) => {
				if (t.kind === 'key' && t.quoted === '`') {
					return `\`${t.value}\``
				}
				return t.quoted || (t.kind === 'value' && t.field && STRING_TYPES.has(t.field.type)) ? quoted(t.value) : t.value
			})
			.join(' ')
	}

	toObject() {
		return { ...this }
	}
}

export default QueryExpression
