export const INIT_INSIGHTS = 'INIT_INSIGHTS'

export const RESET_STORE = 'RESET_STORE'

export const INIT_INSIGHTS_TABS_STATE = 'INIT_INSIGHTS_TABS_STATE'
export const UPDATE_INSIGHTS_TABS_STATE = 'UPDATE_INSIGHTS_TABS_STATE'
export const INSIGHTS_INIT_TASK_COMPLETE = 'INSIGHTS_INIT_TASK_COMPLETE'

export const INIT_INSIGHTS_SCHEMA = 'INIT_INSIGHTS_SCHEMA'
export const UPDATE_INSIGHTS_SCHEMA = 'UPDATE_INSIGHTS_SCHEMA'
export const SET_INSIGHTS_SCHEMA = 'SET_INSIGHTS_SCHEMA'
export const RESET_INSIGHTS_SCHEMA = 'RESET_INSIGHTS_SCHEMA'

export const POLL_INSIGHTS_JOB_REQUEST = 'POLL_INSIGHTS_JOB_REQUEST'
export const POLL_INSIGHTS_JOB_FAILURE = 'POLL_INSIGHTS_JOB_FAILURE'

export const FETCH_AUTOCOMPLETE_REQUEST = 'FETCH_AUTOCOMPLETE_REQUEST'
export const FETCH_AUTOCOMPLETE_SUCCESS = 'FETCH_AUTOCOMPLETE_SUCCESS'
export const SET_AUTOCOMPLETE_REQUEST_ID = 'SET_AUTOCOMPLETE_REQUEST_ID'
export const FETCH_AUTOCOMPLETE_FAILURE = 'FETCH_AUTOCOMPLETE_FAILURE'

export const OPEN_INSIGHTS_DEVICE_DETAILS = 'OPEN_INSIGHTS_DEVICE_DETAILS'
export const OPEN_INSIGHTS_TAB = 'OPEN_INSIGHTS_TAB'
export const SWITCH_INSIGHTS_TAB = 'SWITCH_INSIGHTS_TAB'
export const CLOSE_INSIGHTS_TAB = 'CLOSE_INSIGHTS_TAB'
export const CACHE_INSIGHTS_TAB = 'CACHE_INSIGHTS_TAB'
export const CLEAR_INSIGHTS_DATA_CACHE = 'CLEAR_INSIGHTS_DATA_CACHE'
export const REFRESH_INSIGHTS_TAB = 'REFRESH_INSIGHTS_TAB'
export const UPDATE_INSIGHTS_ACTIVE_TAB = 'UPDATE_INSIGHTS_ACTIVE_TAB'

export const UPDATE_INSIGHTS_LAST_REFRESH = 'UPDATE_INSIGHTS_LAST_REFRESH'
export const UPDATE_INSIGHTS_STALE_STATUS = 'UPDATE_INSIGHTS_STALE_STATUS'

export const SET_INSIGHTS_LAST_UPDATE = 'SET_INSIGHTS_LAST_UPDATE'
