import { Card } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import svgVSys from '../../../../../../images/icon-vsys.svg';
import React from 'react';

import 'antd/lib/card/style/css';
import 'antd/lib/input/style/css';

import './LogCollectorGroupList.scss';

function last(string, n) {
    return string.substring(string.length - n, n);
}

export function LogCollectorGroupList({ title, groups, onClickDevice }) {
    if (!groups) return null;
    return (<div className={'log-collector-group-list'}>
        <Card size="small" title={title}>
            <ul>
                {groups.map((group, index) => (
                    <li key={index}>
                        <FolderOutlined/>
                        Collector Group {last('0' + (index + 1), 2)} - Retention: {group.mininum_retention_period} Days
                        <ul>
                            {group.lc_records.map((device, index) => (
                                <li key={index}
                                    onClick={() => onClickDevice(device)}>
                                    <img src={svgVSys} alt={'vsys'}/>
                                    {device.hostname}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </Card>
    </div>);
}