import Constants from '../../../utils/Constants.json'
import * as actionTypes from './actionTypes'

import { TimeRangeSelectComponent } from 'ui-lib'

const getRange = TimeRangeSelectComponent.getRange

const ACTION_HANDLERS = {
	[actionTypes.RESET_STORE]: (state, { pageType }) => {
		if (pageType === 'AlertsMain') {
			return {
				..._.cloneDeep(initialState),
				alertsTimePreset: state.alertsTimePreset,
				alertsTimeRange: state.alertsTimeRange
			}
		} else {
			return { ...state }
		}
	},
	/*--------------- Devices Details ---------------*/
	[actionTypes.FETCH_DEVICES_DETAILS_REQUEST]: (state, { alertsStatus }) => {
		if (alertsStatus === 'open') {
			return {
				...state,
				devicesDetails: null,
				devicesDetailsRequestId: null,
				devicesDetailsLoading: true,
				devicesDetailsError: null
			}
		} else {
			return {
				...state
			}
		}
	},
	[actionTypes.SET_DEVICES_DETAILS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			devicesDetailsRequestId: jobId
		}
	},
	[actionTypes.FETCH_DEVICES_DETAILS_SUCCESS]: (state, { devicesDetails, alertsStatus }) => {
		const formattedData = {}
		devicesDetails.forEach((device) => {
			formattedData[device.serial] = device
		})
		if (alertsStatus === 'open') {
			return {
				...state,
				devicesDetails: formattedData,
				devicesDetailsRequestId: null,
				devicesDetailsLoading: false,
				devicesDetailsError: null
			}
		} else {
			return {
				...state,
				devicesDetails: { ...state.devicesDetails, ...formattedData },
				closedAlertsLoading: false
			}
		}
	},
	[actionTypes.FETCH_DEVICES_DETAILS_FAILURE]: (state, { error, alertsStatus }) => {
		if (alertsStatus === 'open') {
			return {
				...state,
				devicesDetails: null,
				devicesDetailsRequestId: null,
				devicesDetailsLoading: false,
				devicesDetailsError: error
			}
		} else {
			return {
				closedAlertsError: error
			}
		}
	},
	[actionTypes.RESTORE_DEVICES_DETAILS]: (state, { data }) => {
		return {
			...state,
			devicesDetailsLoading: false,
			devicesDetailsError: null,
			...data
		}
	},
	/*--------------- Alerts ---------------*/
	[actionTypes.FETCH_ALERTS_REQUEST]: (state, {}) => {
		return {
			...state,
			alerts: null,
			alertsLoading: true,
			alertsError: null,
			closedAlerts: null,
			closedAlertsLoading: false,
			closedAlertsError: null
		}
	},
	[actionTypes.FETCH_ALERTS_SUCCESS]: (state, { alerts }) => {
		return {
			...state,
			alerts,
			alertsLoading: false,
			alertsError: null
		}
	},
	[actionTypes.FETCH_ALERTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			alerts: null,
			alertsLoading: false,
			alertsError: error
		}
	},
	[actionTypes.UPDATE_ALERT_SUCCESS]: (state, { alert, alertId }) => {
		const updatedAlerts = [...state.alerts]
		const index = updatedAlerts.findIndex((alert) => alert.alert_id === alertId)
		if (index > -1) updatedAlerts[index] = alert
		return {
			...state,
			alerts: updatedAlerts,
			alertsLoading: false,
			alertsError: null
		}
	},
	[actionTypes.RESTORE_ALERTS]: (state, { data }) => {
		return {
			...state,
			alertsLoading: false,
			alertsError: null,
			...data
		}
	},
	[actionTypes.FETCH_CLOSED_ALERTS_REQUEST]: (state, {}) => {
		return {
			...state,
			closedAlertsLoading: true,
			closedAlertsError: null
		}
	},
	[actionTypes.FETCH_CLOSED_ALERTS_SUCCESS]: (state, { alerts }) => {
		return {
			...state,
			closedAlerts: alerts
		}
	},
	[actionTypes.FETCH_CLOSED_ALERTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			closedAlertsLoading: false,
			closedAlertsError: error
		}
	},
	[actionTypes.RESTORE_CLOSED_ALERTS]: (state, { data }) => {
		return {
			...state,
			closedAlertsLoading: false,
			closedAlertsError: null,
			...data
		}
	},
	/*--------------- Comments ---------------*/
	[actionTypes.FETCH_COMMENTS_REQUEST]: (state, { alertId }) => {
		const updatedComments = { ...state.comments }
		updatedComments[alertId] = { isLoading: true }
		return {
			...state,
			comments: updatedComments,
			commentsLoading: false,
			commentsError: null
		}
	},
	[actionTypes.FETCH_COMMENTS_SUCCESS]: (state, { comments, alertId }) => {
		const updatedComments = { ...state.comments }
		updatedComments[alertId] = { isLoading: false, data: comments }
		return {
			...state,
			comments: updatedComments,
			commentsLoading: false,
			commentsError: null
		}
	},
	[actionTypes.FETCH_COMMENTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			commentsLoading: false,
			commentsError: error
		}
	},
	[actionTypes.ADD_COMMENT_REQUEST]: (state, { comment, alertId }) => {
		const updatedComments = { ...state.comments }
		if (_.get(updatedComments, alertId)) {
			updatedComments[alertId].isLoading = true
		} else {
			updatedComments[alertId] = { isLoading: true, data: [] }
		}
		return {
			...state,
			comments: updatedComments,
			commentsLoading: false,
			commentsError: null
		}
	},
	[actionTypes.ADD_COMMENT_SUCCESS]: (state, { comment, alertId }) => {
		const updatedComments = { ...state.comments }
		updatedComments[alertId].isLoading = false
		updatedComments[alertId].data.push(comment)
		return {
			...state,
			comments: updatedComments,
			commentsLoading: false,
			commentsError: null
		}
	},
	[actionTypes.ADD_COMMENT_FAILURE]: (state, { error }) => {
		return {
			...state,
			commentsLoading: false,
			commentsError: error
		}
	},
	[actionTypes.REMOVE_COMMENT_REQUEST]: (state, { commentId, alertId }) => {
		const updatedComments = { ...state.comments }
		updatedComments[alertId].isLoading = true

		return {
			...state,
			comments: updatedComments,
			commentsLoading: false,
			commentsError: null
		}
	},
	[actionTypes.REMOVE_COMMENT_SUCCESS]: (state, { commentId, alertId }) => {
		const updatedComments = { ...state.comments }
		updatedComments[alertId].isLoading = false
		_.remove(updatedComments[alertId].data, (comment) => comment.comment_id === commentId)
		return {
			...state,
			comments: updatedComments,
			commentsLoading: false,
			commentsError: null
		}
	},
	[actionTypes.REMOVE_COMMENT_FAILURE]: (state, { error }) => {
		return {
			...state,
			commentsLoading: false,
			commentsError: error
		}
	},
	[actionTypes.UPDATE_COMMENT_REQUEST]: (state, { alertId }) => {
		const updatedComments = { ...state.comments }
		updatedComments[alertId].isLoading = true

		return {
			...state,
			comments: updatedComments,
			commentsLoading: false,
			commentsError: null
		}
	},
	[actionTypes.UPDATE_COMMENT_SUCCESS]: (state, { comment, alertId, commentId }) => {
		const updatedComments = { ...state.comments }
		updatedComments[alertId].isLoading = false
		_.remove(updatedComments[alertId].data, (comment) => comment.comment_id === commentId)
		updatedComments[alertId].data.push(comment)
		return {
			...state,
			comments: updatedComments,
			commentsLoading: false,
			commentsError: null
		}
	},
	[actionTypes.UPDATE_COMMENT_FAILURE]: (state, { error }) => {
		return {
			...state,
			commentsLoading: false,
			commentsError: error
		}
	},
	/*--------------- Events ---------------*/
	[actionTypes.FETCH_EVENTS_REQUEST]: (state, { alertId }) => {
		const updatedEvents = { ...state.events }
		updatedEvents[alertId] = { isLoading: true }
		return {
			...state,
			events: updatedEvents,
			eventsLoading: false,
			eventsError: null
		}
	},
	[actionTypes.FETCH_EVENTS_SUCCESS]: (state, { events, alertId }) => {
		const updatedEvents = { ...state.events }
		updatedEvents[alertId] = { isLoading: false, data: events }
		return {
			...state,
			events: updatedEvents,
			eventsLoading: false,
			eventsError: null
		}
	},
	[actionTypes.FETCH_EVENTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			eventsLoading: false,
			eventsError: error
		}
	},
	/*--------------- Activities ---------------*/
	[actionTypes.FETCH_ACTIVITIES_REQUEST]: (state, { alertId }) => {
		const updatedActivities = { ...state.activities }
		updatedActivities[alertId] = { isLoading: true }
		return {
			...state,
			activities: updatedActivities,
			activitiesLoading: false,
			activitiesError: null
		}
	},
	[actionTypes.FETCH_ACTIVITIES_SUCCESS]: (state, { activities, alertId }) => {
		const updatedActivities = { ...state.activities }
		updatedActivities[alertId] = { isLoading: false, data: activities }
		return {
			...state,
			activities: updatedActivities,
			activitiesLoading: false,
			activitiesError: null
		}
	},
	[actionTypes.FETCH_ACTIVITIES_FAILURE]: (state, { error }) => {
		return {
			...state,
			activities: [],
			activitiesLoading: false,
			activitiesError: error
		}
	},
	/*--------------- Time Range ---------------*/
	[actionTypes.SET_ALERTS_TIME_RANGE]: (state, { alertsTimeRange }) => {
		return {
			...state,
			alertsTimeRange
		}
	},
	[actionTypes.SET_ALERTS_TIME_PRESET]: (state, { alertsTimePreset }) => {
		return {
			...state,
			alertsTimePreset
		}
	}
}

const initialState = {
	closedAlerts: null,
	closedAlertsLoading: false,
	closedAlertsError: null,
	devicesDetails: null,
	devicesDetailsLoading: true,
	devicesDetailsError: null,
	alerts: null,
	alertsLoading: true,
	alertsError: null,
	events: null,
	eventsLoading: false,
	eventsError: null,
	activities: null,
	activitiesLoading: true,
	activitiesError: null,
	comments: null,
	commentsLoading: false,
	commentsError: null,
	alertsTimePreset: Constants.DEFAULT_ALERTS_TIME_PRESET,
	alertsTimeRange: getRange(Constants.DEFAULT_ALERTS_TIME_PRESET).timeval()
}

export const alertsMainReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
