import { AdminroleBuilder } from "./AdminroleBuilder/AdminroleBuilder";
import { CheckboxBuilder } from "./CheckboxBuilder/CheckboxBuilder";
import { CompletionBuilder } from "./CompletionBuilder/CompletionBuilder";
import { CollapseContainerBuilder } from "./CollapseContainerBuilder/CollapseContainerBuilder";
import { ContainerBuilder } from "./ContainerBuilder/ContainerBuilder";
import { DatefieldBuilder } from "./Datefieldbuilder/DatefieldBuilder";
import { EditorGridBuilder } from "./EditorGridBuilder/EditorGridBuilder";
import { FieldSetBuilder } from "./FieldSetBuilder/FieldSetBuilder";
import { HorizontalSeparatorBuilder } from "./HorizontalSeparatorBuilder/HorizontalSeparatorBuilder";
import { InputGroupBuilder } from "./InputGroupBuilder/InputGroupBuilder";
import { ListGroupBuilder } from "./ListGroupBuilder/ListGroupBuilder";
import { NumberfieldBuilder } from "./NumberfieldBuilder";
import { PasswordBuilder } from "./PasswordBuilder/PasswordBuilder";
import { RadioCardBuilder } from "./RadioCardBuilder/RadioCardBuilder";
import { TagBuilder } from "./TagBuilder";
import { TabBuilder } from "./TabBuilder/TabBuilder";
import { SequenceCheckboxBuilder } from "./SequenceCheckboxBuilder/SequenceCheckboxBuilder";
import { TextfieldBuilder } from "./TextfieldBuilder";
import { TextareaBuilder } from "./TextareaBuilder";
import { RegionMapBuilder } from "./RegionMapBuilder/RegionMapBuilder";
import { RowBuilder } from "./RowBuilder/RowBuilder";
import { FileReaderBuilder } from "./FileReaderBuilder";
import { FakeSchemaContainerBuilder } from "./FakeSchemaContainerBuilder";
import { ViewerDisplayBuilder } from "./ViewerDisplayBuilder";
import { ViewerDisplayActionBuilder } from "./ViewerDisplayActionBuilder";
import { AutoSuggestBuilder } from "./AutoSuggestBuilder";
import { MapPageBuilder } from "./MapPageBuilder";
import { LabelBuilder } from "./LabelBuilder";

export {
	AdminroleBuilder,
	CheckboxBuilder,
	CompletionBuilder,
	CollapseContainerBuilder,
	ContainerBuilder,
	DatefieldBuilder,
	EditorGridBuilder,
	FieldSetBuilder,
	HorizontalSeparatorBuilder,
	InputGroupBuilder,
	ListGroupBuilder,
	NumberfieldBuilder,
	PasswordBuilder,
	RadioCardBuilder,
	TagBuilder,
	TabBuilder,
	TextfieldBuilder,
	RowBuilder,
	FakeSchemaContainerBuilder,
	RegionMapBuilder,
	AutoSuggestBuilder,
	MapPageBuilder,
	ViewerDisplayActionBuilder,
};

const builderMap = {
	AdminroleBuilder: AdminroleBuilder,
	CheckboxBuilder: CheckboxBuilder,
	CompletionBuilder: CompletionBuilder,
	CollapseContainerBuilder: CollapseContainerBuilder,
	ContainerBuilder: ContainerBuilder,
	DatefieldBuilder: DatefieldBuilder,
	EditorGridBuilder: EditorGridBuilder,
	FieldSetBuilder: FieldSetBuilder,
	HorizontalSeparatorBuilder: HorizontalSeparatorBuilder,
	InputGroupBuilder: InputGroupBuilder,
	ListGroupBuilder: ListGroupBuilder,
	NumberfieldBuilder: NumberfieldBuilder,
	PasswordBuilder: PasswordBuilder,
	RadioCardBuilder: RadioCardBuilder,
	TabBuilder: TabBuilder,
	TagBuilder: TagBuilder,
	SequenceCheckboxBuilder: SequenceCheckboxBuilder,
	TextfieldBuilder: TextfieldBuilder,
	TextareaBuilder: TextareaBuilder,
	RowBuilder: RowBuilder,
	FileReaderBuilder: FileReaderBuilder,
	FakeSchemaContainerBuilder: FakeSchemaContainerBuilder,
	RegionMapBuilder: RegionMapBuilder,
	ViewerDisplayBuilder: ViewerDisplayBuilder,
	ViewerDisplayActionBuilder: ViewerDisplayActionBuilder,
	AutoSuggestBuilder: AutoSuggestBuilder,
	MapPageBuilder: MapPageBuilder,
	LabelBuilder: LabelBuilder,
};
export default builderMap;
