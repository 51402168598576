import * as actionTypes from './actionTypes'

export const fetchTableMetricDetailsPageContent = () => ({
	type: actionTypes.FETCH_TABLE_PAGE_CONTENT
})

/*----- Poll Job Actions -----*/
export const pollTablePageJobRequest = (jobId, jobType, jobPage, jobLimit, params) => ({
	type: actionTypes.POLL_TABLE_PAGE_JOB_REQUEST,
	jobId,
	jobType,
	jobPage,
	jobLimit,
	params
})

export const pollTablePageJobFailure = (jobId, error) => ({
	type: actionTypes.POLL_TABLE_PAGE_JOB_FAILURE,
	jobId,
	error
})

/*----- Table Page Desc Actions -----*/
export const fetchTablePageDescRequest = (device, metric, hostname) => ({
	type: actionTypes.FETCH_TABLE_PAGE_DESC_REQUEST,
	device,
	metric,
	hostname
})

export const setTablePageDescRequestId = (jobId) => ({
	type: actionTypes.SET_TABLE_PAGE_DESC_REQUEST_ID,
	jobId
})

export const fetchTablePageDescSuccess = (pageDesc) => ({
	type: actionTypes.FETCH_TABLE_PAGE_DESC_SUCCESS,
	pageDesc
})

export const fetchTablePageDescFailure = (error) => ({
	type: actionTypes.FETCH_TABLE_PAGE_DESC_FAILURE,
	error
})

export const restoreTablePageDesc = (data) => ({
	type: actionTypes.RESTORE_TABLE_PAGE_DESC,
	data
})

/*----- Table Chart Actions -----*/
export const fetchTableChartRequest = (node) => ({
	type: actionTypes.FETCH_TABLE_CHART_REQUEST,
	node
})

export const fetchTableChartSuccess = (jobApiResponse, apiResponse, metric, currentTabJob) => ({
	type: actionTypes.FETCH_TABLE_CHART_SUCCESS,
	jobApiResponse,
	apiResponse,
	metric,
	currentTabJob
})

export const setTableChartRequestId = (jobId) => ({
	type: actionTypes.SET_TABLE_CHART_REQUEST_ID,
	jobId
})

export const fetchTableChartFailure = (error) => ({
	type: actionTypes.FETCH_TABLE_CHART_FAILURE,
	error
})

export const restoreTableChart = (data) => ({
	type: actionTypes.RESTORE_TABLE_CHART,
	data
})
