import React, { Component } from 'react'
import NavItem from '../NavItem/NavItem'

export default class NavGroup extends Component {
	constructor(props) {
		super(props)
		this.node = React.createRef()
	}
	state = {
		subMenuOwner: null
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleOutsideClick, false)
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick, false)
	}

	handleOutsideClick = (e) => {
		if (this.node && !this.node.current.contains(e.target)) {
			this.setState({ subMenuOwner: null })
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isNavOpen !== this.props.isNavOpen) {
			this.setState({ subMenuOwner: null })
		}
	}

	render() {
		const { navGroupInfo, activeApp, isNavOpen } = this.props
		return (
			<div className="nav-group" ref={this.node}>
				{navGroupInfo.map((navItem, index) => {
					return (
						<NavItem
							key={index}
							isNavOpen={isNavOpen}
							activeApp={activeApp}
							appName={navItem.appName}
							icons={navItem.icons}
							subItems={navItem.subItems}
							onItemClick={navItem.onItemClick}
							onItemClose={navItem.onItemClose}
							componentPath={navItem.componentPath}
							subMenuOwner={this.state.subMenuOwner}
							setSubMenuOwner={(subMenuOwner) => {
								this.setState({ subMenuOwner })
							}}
						/>
					)
				})}
			</div>
		)
	}
}
