export default function () {
	return {
		metadata: { version: 4.5, type: 'Object', generator: 'Object3D.toJSON' },
		geometries: [
			{
				uuid: '2A10A16C-AE77-4B4C-A2CF-D7D1FB4692E3',
				type: 'BufferGeometry',
				data: {
					attributes: {
						position: {
							itemSize: 3,
							type: 'Float32Array',
							array: [
								-0.0012128790840506554,
								0,
								-0.12534591555595398,
								0.00426429882645607,
								0,
								-0.12499846518039703,
								0.0015218332409858704,
								0,
								-0.12535244226455688,
								-0.0039060076232999563,
								0,
								-0.12499020993709564,
								-0.0039060076232999563,
								0,
								-0.12499020993709564,
								0.006980687379837036,
								0,
								-0.12427268922328949,
								0.00426429882645607,
								0,
								-0.12499846518039703,
								-0.006523733958601952,
								0,
								-0.12429657578468323,
								0.006980687379837036,
								0,
								-0.12427268922328949,
								-0.009032228030264378,
								0,
								-0.12327638268470764,
								-0.006523733958601952,
								0,
								-0.12429657578468323,
								0.009637180715799332,
								0,
								-0.12316378951072693,
								-0.011397670954465866,
								0,
								-0.12194091081619263,
								0.009637180715799332,
								0,
								-0.12316378951072693,
								-0.009032228030264378,
								0,
								-0.12327638268470764,
								0.010647106915712357,
								0,
								-0.12262555956840515,
								0.011622946709394455,
								0,
								-0.12203581631183624,
								0.012562941759824753,
								0,
								-0.12139630317687988,
								-0.01358623243868351,
								0,
								-0.12030148506164551,
								0.013465370051562786,
								0,
								-0.12070874869823456,
								0.01432847324758768,
								0,
								-0.11997489631175995,
								-0.015564094297587872,
								0,
								-0.11836941540241241,
								0.01432847324758768,
								0,
								-0.11997489631175995,
								-0.01358623243868351,
								0,
								-0.12030148506164551,
								-0.015564094297587872,
								0,
								-0.11836941540241241,
								0.015150517225265503,
								0,
								-0.11919647455215454,
								0.01592976599931717,
								0,
								-0.11837521195411682,
								0.01666446402668953,
								0,
								-0.11751286685466766,
								-0.01729743182659149,
								0,
								-0.11615598201751709,
								0.017352884635329247,
								0,
								-0.11661118268966675,
								0.017993275076150894,
								0,
								-0.11567187309265137,
								-0.018752412870526314,
								0,
								-0.11367250978946686,
								0.01858389563858509,
								0,
								-0.11469671130180359,
								0.019123002886772156,
								0,
								-0.1136874109506607,
								0.020926358178257942,
								0,
								-0.11007991433143616,
								-0.11022577434778214,
								0,
								0.06967242062091827,
								-0.005639827810227871,
								0,
								-0.10820293426513672,
								-0.005639827810227871,
								0,
								-0.10820293426513672,
								-0.0050620464608073235,
								0,
								-0.1089407205581665,
								-0.018752412870526314,
								0,
								-0.11367250978946686,
								-0.0050620464608073235,
								0,
								-0.1089407205581665,
								-0.004402763210237026,
								0,
								-0.1095847338438034,
								-0.003673235187307,
								0,
								-0.11013120412826538,
								-0.0028847544454038143,
								0,
								-0.11057636141777039,
								-0.0020485897548496723,
								0,
								-0.11091643571853638,
								-0.0011760103516280651,
								0,
								-0.11114762723445892,
								-0.00027829682221636176,
								0,
								-0.11126621067523956,
								0.0006332817138172686,
								0,
								-0.11126837134361267,
								0.001547444611787796,
								0,
								-0.11115038394927979,
								0.002452922984957695,
								0,
								-0.11090844869613647,
								0.020926358178257942,
								0,
								-0.11007991433143616,
								0.001547444611787796,
								0,
								-0.11115038394927979,
								0.0033384247217327356,
								0,
								-0.1105387955904007,
								0.002452922984957695,
								0,
								-0.11090844869613647,
								0.003675062907859683,
								0,
								-0.11035940051078796,
								0.004000338725745678,
								0,
								-0.11016282439231873,
								0.00431367103010416,
								0,
								-0.10994966328144073,
								0.02591213583946228,
								0,
								-0.10010634362697601,
								0.00461447611451149,
								0,
								-0.10972049832344055,
								0.004902173765003681,
								0,
								-0.10947588086128235,
								0.00517619214951992,
								0,
								-0.10921642184257507,
								0.005435938015580177,
								0,
								-0.1089426577091217,
								0.005680840462446213,
								0,
								-0.10865519940853119,
								0.005910318344831467,
								0,
								-0.10835464298725128,
								0.006123777478933334,
								0,
								-0.10804155468940735,
								-0.006124825216829777,
								0,
								-0.1073751151561737,
								0.006320647895336151,
								0,
								-0.10771650075912476,
								0.006500357761979103,
								0,
								-0.10738006234169006,
								0.008303724229335785,
								0,
								-0.10377258062362671,
								-0.11022577434778214,
								0,
								0.06967242062091827,
								-0.00792465265840292,
								0,
								-0.10376764833927155,
								-0.006124825216829777,
								0,
								-0.1073751151561737,
								0.013289513066411018,
								0,
								-0.09379896521568298,
								-0.01290062814950943,
								0,
								-0.09379398822784424,
								-0.00792465265840292,
								0,
								-0.10376764833927155,
								0.03344384580850601,
								0,
								-0.08503983914852142,
								0.020821236073970795,
								0,
								-0.0787324458360672,
								-0.020417535677552223,
								0,
								-0.07872739434242249,
								0.04288501292467117,
								0,
								-0.06615367531776428,
								0.030262401327490807,
								0,
								-0.059846218675374985,
								-0.02984013967216015,
								0,
								-0.0598411001265049,
								0.030262401327490807,
								0,
								-0.059846218675374985,
								0.053599145263433456,
								0,
								-0.044721078127622604,
								0.04288501292467117,
								0,
								-0.06615367531776428,
								0.04097653552889824,
								0,
								-0.038413554430007935,
								0.053599145263433456,
								0,
								-0.044721078127622604,
								-0.04053319990634918,
								0,
								-0.03840833902359009,
								-0.0015873988159000874,
								0,
								-0.05477585271000862,
								0.0022420715540647507,
								0,
								-0.05477585271000862,
								0.00032731896499171853,
								0,
								-0.054904643446207047,
								-0.003423827001824975,
								0,
								-0.054400693625211716,
								-0.003423827001824975,
								0,
								-0.054400693625211716,
								0.004078523255884647,
								0,
								-0.054400693625211716,
								0.0022420715540647507,
								0,
								-0.05477585271000862,
								-0.005165155977010727,
								0,
								-0.05379597470164299,
								-0.005165155977010727,
								0,
								-0.05379597470164299,
								0.005819863639771938,
								0,
								-0.05379597470164299,
								0.004078523255884647,
								0,
								-0.054400693625211716,
								-0.006794574670493603,
								0,
								-0.052978504449129105,
								-0.006794574670493603,
								0,
								-0.052978504449129105,
								0.007449282333254814,
								0,
								-0.052978504449129105,
								0.005819863639771938,
								0,
								-0.05379597470164299,
								-0.008295261301100254,
								0,
								-0.051965098828077316,
								0.007449282333254814,
								0,
								-0.052978504449129105,
								0.008949968963861465,
								0,
								-0.051965098828077316,
								-0.009650405496358871,
								0,
								-0.05077255144715309,
								0.010305113159120083,
								0,
								-0.05077255144715309,
								-0.010843196883797646,
								0,
								-0.049417685717344284,
								0.011497893370687962,
								0,
								-0.049417685717344284,
								-0.011856825090944767,
								0,
								-0.04791731759905815,
								0.012511509470641613,
								0,
								-0.04791731759905815,
								-0.012674467638134956,
								0,
								-0.046288248151540756,
								0.013329140841960907,
								0,
								-0.046288248151540756,
								-0.013279315084218979,
								0,
								-0.04454727843403816,
								-0.013279315084218979,
								0,
								-0.04454727843403816,
								0.013933977112174034,
								0,
								-0.04454727843403816,
								0.013329140841960907,
								0,
								-0.046288248151540756,
								0.06494975835084915,
								0,
								-0.022015243768692017,
								-0.013654557056725025,
								0,
								-0.04271123558282852,
								0.013933977112174034,
								0,
								-0.04454727843403816,
								0.01430920697748661,
								0,
								-0.04271123558282852,
								-0.013783370144665241,
								0,
								-0.040796924382448196,
								-0.013783370144665241,
								0,
								-0.040796924382448196,
								0.014438020065426826,
								0,
								-0.040796924382448196,
								0.01430920697748661,
								0,
								-0.04271123558282852,
								-0.013783370144665241,
								0,
								-0.03968650847673416,
								0.014438020065426826,
								0,
								-0.040796924382448196,
								0.014438020065426826,
								0,
								-0.03968651965260506,
								0.014438020065426826,
								0,
								-0.036616530269384384,
								-0.013783370144665241,
								0,
								-0.03661651909351349,
								0.052327144891023636,
								0,
								-0.015707653015851974,
								-0.05186149477958679,
								0,
								-0.01570235565304756,
								0.014438020065426826,
								0,
								-0.03197889402508736,
								-0.013783370144665241,
								0,
								-0.03197888284921646,
								0.014438020065426826,
								0,
								-0.026165518909692764,
								-0.013783370144665241,
								0,
								-0.026165496557950974,
								0.014438020065426826,
								0,
								-0.0195683091878891,
								-0.013783370144665241,
								0,
								-0.01956828683614731,
								0.052327144891023636,
								0,
								-0.015707653015851974,
								0.07630036771297455,
								0,
								0.0006905868649482727,
								0.06494975835084915,
								0,
								-0.022015243768692017,
								0.014438020065426826,
								0,
								-0.012579187750816345,
								-0.013783370144665241,
								0,
								-0.012579165399074554,
								0.06367775797843933,
								0,
								0.006998241879045963,
								0.07630036771297455,
								0,
								0.0006905868649482727,
								-0.0631897896528244,
								0,
								0.007003632374107838,
								0.014438020065426826,
								0,
								-0.005590066313743591,
								-0.013783370144665241,
								0,
								-0.0055900439620018005,
								0.014438020065426826,
								0,
								0.0010071434080600739,
								-0.013783370144665241,
								0,
								0.0010071657598018646,
								0.08701449632644653,
								0,
								0.022123195230960846,
								0.014438020065426826,
								0,
								0.006820527836680412,
								-0.013783370144665241,
								0,
								0.00682054553180933,
								-0.013783370144665241,
								0,
								0.00682054553180933,
								0.014438020065426826,
								0,
								0.011458171531558037,
								0.014438020065426826,
								0,
								0.006820527836680412,
								-0.013783370144665241,
								0,
								0.011458182707428932,
								0.014438020065426826,
								0,
								0.011458171531558037,
								0.07439188659191132,
								0,
								0.028430908918380737,
								-0.07388285547494888,
								0,
								0.028436385095119476,
								-0.013783370144665241,
								0,
								0.011458182707428932,
								0.014438020065426826,
								0,
								0.014528159983456135,
								-0.013783370144665241,
								0,
								0.01452817115932703,
								-0.013783370144665241,
								0,
								0.01452817115932703,
								0.014438020065426826,
								0,
								0.015638582408428192,
								0.014438020065426826,
								0,
								0.014528159983456135,
								-0.013783370144665241,
								0,
								0.015638582408428192,
								-0.013654557056725025,
								0,
								0.017552735283970833,
								0.01430920697748661,
								0,
								0.017552729696035385,
								0.013933977112174034,
								0,
								0.01938863843679428,
								-0.013279315084218979,
								0,
								0.019388645887374878,
								0.013329140841960907,
								0,
								0.021129490807652473,
								-0.012674467638134956,
								0,
								0.02112949639558792,
								0.012511509470641613,
								0,
								0.022758472710847855,
								-0.011856825090944767,
								0,
								0.022758478298783302,
								0.012511509470641613,
								0,
								0.022758472710847855,
								-0.012674467638134956,
								0,
								0.02112949639558792,
								0.07439188659191132,
								0,
								0.028430908918380737,
								0.0964556634426117,
								0,
								0.04100935906171799,
								0.08701449632644653,
								0,
								0.022123195230960846,
								-0.011856825090944767,
								0,
								0.022758478298783302,
								0.011497893370687962,
								0,
								0.024258775636553764,
								-0.010843196883797646,
								0,
								0.024258781224489212,
								-0.010843196883797646,
								0,
								0.024258781224489212,
								0.010305113159120083,
								0,
								0.025613587349653244,
								0.011497893370687962,
								0,
								0.024258775636553764,
								-0.009650405496358871,
								0,
								0.02561359293758869,
								0.010305113159120083,
								0,
								0.025613587349653244,
								0.008949968963861465,
								0,
								0.026806093752384186,
								-0.008295261301100254,
								0,
								0.026806099340319633,
								0.007449282333254814,
								0,
								0.027819477021694183,
								-0.006794574670493603,
								0,
								0.02781948447227478,
								0.005819863639771938,
								0,
								0.028636934235692024,
								-0.005165155977010727,
								0,
								0.028636939823627472,
								0.08383305370807648,
								0,
								0.04731711745262146,
								0.0964556634426117,
								0,
								0.04100935906171799,
								-0.0833054631948471,
								0,
								0.047322675585746765,
								0.004078523255884647,
								0,
								0.029241647571325302,
								-0.003423827001824975,
								0,
								0.02924165315926075,
								0.004078523255884647,
								0,
								0.029241647571325302,
								-0.005165155977010727,
								0,
								0.028636939823627472,
								-0.003423827001824975,
								0,
								0.02924165315926075,
								0.0022420715540647507,
								0,
								0.02961680293083191,
								-0.0015873988159000874,
								0,
								0.029616806656122208,
								0.00032731896499171853,
								0,
								0.029745591804385185,
								0.08383305370807648,
								0,
								0.04731711745262146,
								0.10398738086223602,
								0,
								0.05607584863901138,
								-0.001587375532835722,
								0,
								0.043982889503240585,
								0.0022406047210097313,
								0,
								0.04398288577795029,
								0.0003266204730607569,
								0,
								0.043854013085365295,
								0.00407659076154232,
								0,
								0.0443582609295845,
								-0.0034233613405376673,
								0,
								0.0443582646548748,
								0.005817710421979427,
								0,
								0.0449632853269577,
								-0.005164480768144131,
								0,
								0.0449632927775383,
								-0.005164480768144131,
								0,
								0.0449632927775383,
								0.007447117008268833,
								0,
								0.04578111693263054,
								0.005817710421979427,
								0,
								0.0449632853269577,
								-0.0067938873544335365,
								0,
								0.04578112065792084,
								0.007447117008268833,
								0,
								0.04578111693263054,
								0.008947955444455147,
								0,
								0.04679489508271217,
								-0.00829472579061985,
								0,
								0.04679489880800247,
								0.010303366929292679,
								0,
								0.04798777401447296,
								-0.009650137275457382,
								0,
								0.04798778146505356,
								0.0913647785782814,
								0,
								0.06238364055752754,
								0.10398738086223602,
								0,
								0.05607584863901138,
								-0.09082236140966415,
								0,
								0.06238926202058792,
								0.011496496386826038,
								0,
								0.04934290051460266,
								-0.010843266732990742,
								0,
								0.04934290796518326,
								0.011496496386826038,
								0,
								0.04934290051460266,
								-0.009650137275457382,
								0,
								0.04798778146505356,
								-0.010843266732990742,
								0,
								0.04934290796518326,
								0.012510497123003006,
								0,
								0.05084342509508133,
								-0.01185726746916771,
								0,
								0.05084342882037163,
								0.013328501023352146,
								0,
								0.052472490817308426,
								-0.01267527136951685,
								0,
								0.052472494542598724,
								-0.01267527136951685,
								0,
								0.052472494542598724,
								0.013933662325143814,
								0,
								0.05421324819326401,
								0.013328501023352146,
								0,
								0.052472490817308426,
								-0.013280432671308517,
								0,
								0.05421324819326401,
								0.013933662325143814,
								0,
								0.05421324819326401,
								0.014309113845229149,
								0,
								0.05604884400963783,
								-0.013655884191393852,
								0,
								0.05604884400963783,
								0.014438020065426826,
								0,
								0.057962432503700256,
								-0.013784779235720634,
								0,
								0.057962432503700256,
								0.014438020065426826,
								0,
								0.057962432503700256,
								-0.013655884191393852,
								0,
								0.05604884400963783,
								0.10897316783666611,
								0,
								0.06604944169521332,
								-0.013655872084200382,
								0,
								0.05987602099776268,
								-0.013655872084200382,
								0,
								0.05987602099776268,
								0.014309125021100044,
								0,
								0.05987601727247238,
								0.013933674432337284,
								0,
								0.06171161308884621,
								0.014309125021100044,
								0,
								0.05987601727247238,
								-0.013280421495437622,
								0,
								0.061711616814136505,
								0.013933674432337284,
								0,
								0.06171161308884621,
								0.013328512199223042,
								0,
								0.06345236301422119,
								-0.01267525926232338,
								0,
								0.06345237046480179,
								0.09635056555271149,
								0,
								0.07235725224018097,
								-0.09579835087060928,
								0,
								0.07236292958259583,
								-0.09082236140966415,
								0,
								0.06238926202058792,
								0.012510508298873901,
								0,
								0.06508143246173859,
								-0.01185725536197424,
								0,
								0.06508143246173859,
								0.012510508298873901,
								0,
								0.06508143246173859,
								-0.01267525926232338,
								0,
								0.06345237046480179,
								-0.01185725536197424,
								0,
								0.06508143246173859,
								0.011496507562696934,
								0,
								0.06658194959163666,
								-0.010843254625797272,
								0,
								0.06658195704221725,
								0.11077653616666794,
								0,
								0.06965690851211548,
								0.010303379036486149,
								0,
								0.06793708354234695,
								-0.009650126099586487,
								0,
								0.06793708354234695,
								0.008947966620326042,
								0,
								0.06912995874881744,
								-0.00829471368342638,
								0,
								0.06912996619939804,
								0.007447129115462303,
								0,
								0.07014374434947968,
								-0.006793876178562641,
								0,
								0.07014374434947968,
								0.11188790947198868,
								0,
								0.07231217622756958,
								-0.1105770468711853,
								0,
								0.07041184604167938,
								-0.09579835087060928,
								0,
								0.07236292958259583,
								0.005817721597850323,
								0,
								0.07096157968044281,
								-0.005164468660950661,
								0,
								0.07096157968044281,
								-0.11089887470006943,
								0,
								0.07116295397281647,
								-0.1105770468711853,
								0,
								0.07041184604167938,
								0.00407660286873579,
								0,
								0.07156659662723541,
								-0.0034233496990054846,
								0,
								0.0715666115283966,
								-0.1111910417675972,
								0,
								0.0719248354434967,
								-0.11089887470006943,
								0,
								0.07116295397281647,
								0.002240616362541914,
								0,
								0.07194197922945023,
								-0.0015873638913035393,
								0,
								0.07194197922945023,
								-0.1114533543586731,
								0,
								0.07269659638404846,
								0.0003266204730607569,
								0,
								0.07207085192203522,
								0.11261608451604843,
								0,
								0.07502757012844086,
								0.09815390408039093,
								0,
								0.0759647786617279,
								-0.09759816527366638,
								0,
								0.07597041130065918,
								-0.11168556660413742,
								0,
								0.07347732782363892,
								-0.11188749969005585,
								0,
								0.07426612824201584,
								-0.11205894500017166,
								0,
								0.0750621110200882,
								0.09815390408039093,
								0,
								0.0759647786617279,
								0.11297236382961273,
								0,
								0.07776926457881927,
								0.11261608451604843,
								0,
								0.07502757012844086,
								-0.11219967901706696,
								0,
								0.07586434483528137,
								-0.11230950802564621,
								0,
								0.07667197287082672,
								0.09852445125579834,
								0,
								0.07684975862503052,
								0.11297236382961273,
								0,
								0.07776926457881927,
								-0.11230950802564621,
								0,
								0.07667197287082672,
								-0.09771525114774704,
								0,
								0.07621688395738602,
								-0.09759816527366638,
								0,
								0.07597041130065918,
								-0.09782251715660095,
								0,
								0.07646722346544266,
								-0.09771525114774704,
								0,
								0.07621688395738602,
								-0.09791988134384155,
								0,
								0.07672114670276642,
								-0.09782251715660095,
								0,
								0.07646722346544266,
								-0.11238821595907211,
								0,
								0.07748404145240784,
								-0.09791988134384155,
								0,
								0.07672114670276642,
								-0.11238821595907211,
								0,
								0.07748404145240784,
								-0.09800731390714645,
								0,
								0.07697833329439163,
								0.0987672507762909,
								0,
								0.07775481045246124,
								-0.0980847179889679,
								0,
								0.07723850011825562,
								-0.09800731390714645,
								0,
								0.07697833329439163,
								-0.0981520265340805,
								0,
								0.07750135660171509,
								-0.11243559420108795,
								0,
								0.07829969376325607,
								-0.11243559420108795,
								0,
								0.07829969376325607,
								-0.09820917248725891,
								0,
								0.07776658236980438,
								-0.0981520265340805,
								0,
								0.07750135660171509,
								0.09888603538274765,
								0,
								0.07866863906383514,
								-0.09825608879327774,
								0,
								0.07803390920162201,
								0.11296802759170532,
								0,
								0.080503448843956,
								-0.09829269349575043,
								0,
								0.07830299437046051,
								-0.09825608879327774,
								0,
								0.07803390920162201,
								-0.11245142668485641,
								0,
								0.07911800593137741,
								-0.09831893444061279,
								0,
								0.07857358455657959,
								-0.09833472967147827,
								0,
								0.07884535193443298,
								0.09888461977243423,
								0,
								0.07957998663187027,
								-0.0983400046825409,
								0,
								0.07911800593137741,
								-0.11225820332765579,
								0,
								0.08198986947536469,
								-0.09827559441328049,
								0,
								0.08007539808750153,
								0.0987667366862297,
								0,
								0.08047758042812347,
								0.11296802759170532,
								0,
								0.080503448843956,
								0.09888461977243423,
								0,
								0.07957998663187027,
								-0.09808796644210815,
								0,
								0.08099358528852463,
								0.0985361635684967,
								0,
								0.08135014772415161,
								0.0987667366862297,
								0,
								0.08047758042812347,
								0.11261438578367233,
								0,
								0.08319631218910217,
								-0.11225820332765579,
								0,
								0.08198986947536469,
								-0.09778553247451782,
								0,
								0.08186417818069458,
								-0.09808796644210815,
								0,
								0.08099358528852463,
								0.09819667041301727,
								0,
								0.08218640089035034,
								0.11261438578367233,
								0,
								0.08319631218910217,
								0.0985361635684967,
								0,
								0.08135014772415161,
								-0.09737668186426163,
								0,
								0.08267876505851746,
								-0.09778553247451782,
								0,
								0.08186417818069458,
								-0.1116953194141388,
								0,
								0.08474422991275787,
								0.09775201231241226,
								0,
								0.0829751044511795,
								0.09819667041301727,
								0,
								0.08218640089035034,
								-0.09686984866857529,
								0,
								0.08342896401882172,
								0.0972059816122055,
								0,
								0.08370494842529297,
								0.0972059816122055,
								0,
								0.08370494842529297,
								0.11192271113395691,
								0,
								0.08581402897834778,
								-0.09627341479063034,
								0,
								0.08410638570785522,
								0.09656232595443726,
								0,
								0.08436468243598938,
								0.11192271113395691,
								0,
								0.08581402897834778,
								-0.09559580683708191,
								0,
								0.08470264077186584,
								0.09582480788230896,
								0,
								0.0849430114030838,
								-0.09484542906284332,
								0,
								0.08520929515361786,
								-0.1107880175113678,
								0,
								0.08735588192939758,
								-0.09484542906284332,
								0,
								0.08520929515361786,
								-0.1116953194141388,
								0,
								0.08474422991275787,
								0.09499718993902206,
								0,
								0.08542869985103607,
								-0.1107880175113678,
								0,
								0.08735588192939758,
								-0.09403068572282791,
								0,
								0.08561797440052032,
								0.09475032240152359,
								0,
								0.08554616570472717,
								0.09449955821037292,
								0,
								0.08565376698970795,
								-0.09315997362136841,
								0,
								0.0859202891588211,
								0.09424521774053574,
								0,
								0.08575141429901123,
								0.0939875915646553,
								0,
								0.08583904802799225,
								0.09424521774053574,
								0,
								0.08575141429901123,
								0.0939875915646553,
								0,
								0.08583904802799225,
								0.11090431362390518,
								0,
								0.08832280337810516,
								0.09372696280479431,
								0,
								0.08591660857200623,
								0.09346362948417664,
								0,
								0.08598403632640839,
								-0.09224171191453934,
								0,
								0.08610783517360687,
								0.09319787472486496,
								0,
								0.08604127168655396,
								0.09293000400066376,
								0,
								0.08608824014663696,
								0.0926603153347969,
								0,
								0.08612488210201263,
								-0.09128430485725403,
								0,
								0.0861722081899643,
								-0.09224171191453934,
								0,
								0.08610783517360687,
								0.09238911420106888,
								0,
								0.0861511379480362,
								0.09211667627096176,
								0,
								0.08616693317890167,
								0.09184329211711884,
								0,
								0.0861722081899643,
								-0.08768107742071152,
								0,
								0.0861722081899643,
								-0.09128430485725403,
								0,
								0.0861722081899643,
								-0.07771927863359451,
								0,
								0.0861722081899643,
								-0.06267059594392776,
								0,
								0.0861722081899643,
								-0.0438067652285099,
								0,
								0.0861722081899643,
								-0.02239948883652687,
								0,
								0.0861722081899643,
								0.0002795071923173964,
								0,
								0.0861722081899643,
								0.022958504036068916,
								0,
								0.0861722081899643,
								0.0002795071923173964,
								0,
								0.0861722081899643,
								0.0443657785654068,
								0,
								0.0861722081899643,
								0.06322961300611496,
								0,
								0.0861722081899643,
								0.0443657785654068,
								0,
								0.0861722081899643,
								0.07827829569578171,
								0,
								0.0861722081899643,
								0.08824009448289871,
								0,
								0.0861722081899643,
								-0.1095614954829216,
								0,
								0.08979964256286621,
								0.11090431362390518,
								0,
								0.08832280337810516,
								-0.1095614954829216,
								0,
								0.08979964256286621,
								0.1095704734325409,
								0,
								0.0906887799501419,
								-0.10804099589586258,
								0,
								0.09205029904842377,
								0.10793246328830719,
								0,
								0.09287819266319275,
								-0.10625173151493073,
								0,
								0.09408262372016907,
								0.10600157827138901,
								0,
								0.09485717117786407,
								-0.10421890765428543,
								0,
								0.0958714485168457,
								0.10378912836313248,
								0,
								0.09659194946289062,
								-0.10196775943040848,
								0,
								0.09739154577255249,
								0.10130637884140015,
								0,
								0.09804867208003998,
								-0.09952351450920105,
								0,
								0.09861771762371063,
								0.10056547075510025,
								0,
								0.09840111434459686,
								0.09981293976306915,
								0,
								0.09872396290302277,
								-0.09691137075424194,
								0,
								0.09952475130558014,
								0.09981293976306915,
								0,
								0.09872396290302277,
								-0.09952351450920105,
								0,
								0.09861771762371063,
								-0.09691137075424194,
								0,
								0.09952475130558014,
								0.09904969483613968,
								0,
								0.09901702404022217,
								0.09827663004398346,
								0,
								0.09928008913993835,
								0.09749460220336914,
								0,
								0.09951294958591461,
								0.09670449048280716,
								0,
								0.09971541166305542,
								-0.09415655583143234,
								0,
								0.10008746385574341,
								0.09590718895196915,
								0,
								0.09988728165626526,
								0.09510354697704315,
								0,
								0.10002835094928741,
								0.0942944586277008,
								0,
								0.10013842582702637,
								-0.09128430485725403,
								0,
								0.10028062760829926,
								0.09348081052303314,
								0,
								0.1002172976732254,
								0.09266345947980881,
								0,
								0.100264772772789,
								0.09184329211711884,
								0,
								0.10028062760829926,
								-0.08768109977245331,
								0,
								0.10028062760829926,
								0.09184329211711884,
								0,
								0.10028062760829926,
								-0.09128430485725403,
								0,
								0.10028062760829926,
								-0.0777192935347557,
								0,
								0.10028062760829926,
								-0.06267061829566956,
								0,
								0.10028062760829926,
								-0.0438067764043808,
								0,
								0.10028062760829926,
								-0.022399501875042915,
								0,
								0.10028062760829926,
								0.0002794955507852137,
								0,
								0.10028062760829926,
								0.02295849286019802,
								0,
								0.10028062760829926,
								0.044365767389535904,
								0,
								0.10028062760829926,
								0.06322960555553436,
								0,
								0.10028062760829926,
								0.07827828824520111,
								0,
								0.10028062760829926,
								0.08824009448289871,
								0,
								0.10028062760829926
							],
							normalized: false
						},
						normal: {
							itemSize: 3,
							type: 'Float32Array',
							array: [
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0,
								0
							],
							normalized: false
						}
					},
					index: {
						type: 'Uint16Array',
						array: [
							0,
							1,
							2,
							3,
							1,
							0,
							4,
							5,
							6,
							7,
							8,
							3,
							9,
							5,
							10,
							9,
							11,
							5,
							12,
							13,
							14,
							12,
							15,
							13,
							12,
							16,
							15,
							12,
							17,
							16,
							18,
							17,
							12,
							18,
							19,
							17,
							18,
							20,
							19,
							21,
							22,
							23,
							24,
							25,
							20,
							24,
							26,
							25,
							24,
							27,
							26,
							28,
							27,
							24,
							28,
							29,
							27,
							28,
							30,
							29,
							31,
							30,
							28,
							31,
							32,
							30,
							31,
							33,
							32,
							31,
							34,
							33,
							35,
							36,
							31,
							37,
							38,
							39,
							40,
							41,
							31,
							41,
							42,
							31,
							42,
							43,
							31,
							43,
							44,
							31,
							44,
							45,
							31,
							45,
							46,
							31,
							46,
							47,
							31,
							47,
							34,
							31,
							48,
							34,
							47,
							49,
							50,
							51,
							52,
							34,
							53,
							54,
							34,
							52,
							55,
							34,
							54,
							56,
							34,
							55,
							56,
							57,
							34,
							58,
							57,
							56,
							59,
							57,
							58,
							60,
							57,
							59,
							61,
							57,
							60,
							62,
							57,
							61,
							63,
							57,
							62,
							64,
							57,
							63,
							35,
							65,
							36,
							66,
							57,
							64,
							67,
							57,
							66,
							68,
							57,
							67,
							69,
							70,
							71,
							72,
							57,
							68,
							35,
							73,
							74,
							72,
							75,
							57,
							76,
							75,
							72,
							35,
							77,
							73,
							76,
							78,
							75,
							79,
							78,
							76,
							35,
							80,
							77,
							81,
							82,
							83,
							84,
							85,
							79,
							35,
							86,
							80,
							87,
							88,
							89,
							90,
							88,
							87,
							91,
							92,
							93,
							94,
							92,
							91,
							95,
							96,
							97,
							98,
							96,
							95,
							99,
							100,
							101,
							102,
							103,
							98,
							102,
							104,
							103,
							105,
							104,
							102,
							105,
							106,
							104,
							107,
							106,
							105,
							107,
							108,
							106,
							109,
							108,
							107,
							109,
							110,
							108,
							111,
							110,
							109,
							111,
							112,
							110,
							113,
							112,
							111,
							114,
							115,
							116,
							84,
							117,
							85,
							118,
							119,
							113,
							118,
							120,
							119,
							121,
							120,
							118,
							122,
							123,
							124,
							125,
							126,
							121,
							125,
							127,
							126,
							125,
							128,
							127,
							129,
							128,
							125,
							130,
							117,
							84,
							35,
							131,
							86,
							129,
							132,
							128,
							133,
							132,
							129,
							133,
							134,
							132,
							135,
							134,
							133,
							135,
							136,
							134,
							137,
							136,
							135,
							138,
							139,
							140,
							137,
							141,
							136,
							142,
							141,
							137,
							143,
							144,
							130,
							35,
							145,
							131,
							142,
							146,
							141,
							147,
							146,
							142,
							147,
							148,
							146,
							149,
							148,
							147,
							143,
							150,
							144,
							149,
							151,
							148,
							152,
							151,
							149,
							153,
							154,
							155,
							156,
							157,
							152,
							158,
							150,
							143,
							35,
							159,
							145,
							160,
							161,
							154,
							162,
							161,
							160,
							163,
							164,
							165,
							166,
							164,
							163,
							167,
							164,
							166,
							167,
							168,
							164,
							167,
							169,
							168,
							170,
							169,
							167,
							170,
							171,
							169,
							172,
							171,
							170,
							172,
							173,
							171,
							174,
							175,
							176,
							177,
							178,
							179,
							180,
							181,
							173,
							182,
							181,
							180,
							183,
							184,
							185,
							186,
							187,
							182,
							186,
							188,
							187,
							189,
							188,
							186,
							189,
							190,
							188,
							191,
							190,
							189,
							191,
							192,
							190,
							193,
							192,
							191,
							194,
							195,
							158,
							35,
							196,
							159,
							193,
							197,
							192,
							198,
							199,
							200,
							201,
							202,
							197,
							203,
							202,
							201,
							203,
							204,
							202,
							205,
							206,
							178,
							207,
							208,
							209,
							207,
							210,
							208,
							211,
							210,
							207,
							211,
							212,
							210,
							213,
							212,
							211,
							214,
							215,
							216,
							217,
							218,
							213,
							217,
							219,
							218,
							220,
							219,
							217,
							220,
							221,
							219,
							222,
							221,
							220,
							223,
							224,
							194,
							35,
							225,
							196,
							222,
							226,
							221,
							227,
							228,
							229,
							230,
							231,
							226,
							232,
							231,
							230,
							232,
							233,
							231,
							234,
							233,
							232,
							235,
							236,
							237,
							238,
							239,
							234,
							238,
							240,
							239,
							241,
							240,
							238,
							241,
							242,
							240,
							243,
							244,
							245,
							223,
							246,
							224,
							247,
							244,
							243,
							248,
							249,
							242,
							247,
							250,
							251,
							252,
							253,
							248,
							252,
							254,
							253,
							255,
							254,
							252,
							256,
							246,
							223,
							69,
							257,
							258,
							255,
							259,
							254,
							260,
							261,
							262,
							263,
							264,
							259,
							265,
							264,
							263,
							256,
							266,
							246,
							265,
							267,
							264,
							268,
							267,
							265,
							268,
							269,
							267,
							270,
							269,
							268,
							270,
							271,
							269,
							272,
							271,
							270,
							256,
							273,
							266,
							274,
							275,
							35,
							272,
							276,
							271,
							277,
							276,
							272,
							278,
							257,
							279,
							277,
							280,
							276,
							281,
							280,
							277,
							282,
							275,
							283,
							281,
							284,
							280,
							285,
							284,
							281,
							286,
							275,
							282,
							285,
							287,
							284,
							256,
							288,
							273,
							289,
							288,
							256,
							286,
							290,
							275,
							291,
							290,
							286,
							292,
							290,
							291,
							293,
							290,
							292,
							294,
							295,
							296,
							297,
							290,
							293,
							298,
							290,
							297,
							299,
							300,
							289,
							301,
							302,
							303,
							298,
							304,
							305,
							301,
							306,
							307,
							308,
							309,
							298,
							310,
							311,
							306,
							312,
							300,
							299,
							308,
							313,
							314,
							308,
							315,
							313,
							316,
							315,
							308,
							317,
							318,
							319,
							320,
							300,
							312,
							317,
							321,
							318,
							320,
							322,
							300,
							316,
							323,
							324,
							325,
							323,
							316,
							325,
							326,
							323,
							325,
							327,
							326,
							328,
							322,
							320,
							325,
							329,
							327,
							330,
							329,
							325,
							330,
							331,
							329,
							332,
							333,
							334,
							330,
							335,
							331,
							336,
							322,
							337,
							336,
							338,
							322,
							339,
							340,
							341,
							342,
							343,
							344,
							330,
							345,
							346,
							347,
							345,
							330,
							348,
							338,
							349,
							347,
							350,
							345,
							351,
							338,
							348,
							352,
							353,
							343,
							347,
							354,
							350,
							355,
							356,
							351,
							347,
							357,
							354,
							358,
							356,
							355,
							347,
							359,
							357,
							360,
							361,
							362,
							363,
							356,
							358,
							364,
							365,
							359,
							366,
							356,
							363,
							367,
							356,
							366,
							364,
							368,
							365,
							369,
							356,
							367,
							370,
							353,
							371,
							372,
							373,
							356,
							374,
							373,
							372,
							375,
							373,
							374,
							364,
							376,
							368,
							377,
							373,
							375,
							378,
							373,
							377,
							379,
							373,
							378,
							360,
							380,
							381,
							382,
							373,
							379,
							383,
							373,
							382,
							384,
							373,
							383,
							364,
							385,
							386,
							364,
							387,
							385,
							364,
							388,
							387,
							364,
							389,
							388,
							364,
							390,
							389,
							364,
							391,
							390,
							360,
							392,
							393,
							360,
							394,
							392,
							364,
							395,
							396,
							364,
							397,
							395,
							364,
							398,
							397,
							364,
							384,
							398,
							364,
							373,
							384,
							399,
							400,
							360,
							401,
							402,
							373,
							403,
							402,
							401,
							403,
							404,
							402,
							405,
							404,
							403,
							405,
							406,
							404,
							407,
							406,
							405,
							407,
							408,
							406,
							409,
							408,
							407,
							409,
							410,
							408,
							411,
							410,
							409,
							411,
							412,
							410,
							411,
							413,
							412,
							414,
							415,
							416,
							417,
							418,
							413,
							417,
							419,
							418,
							417,
							420,
							419,
							417,
							421,
							420,
							422,
							421,
							417,
							422,
							423,
							421,
							422,
							424,
							423,
							422,
							425,
							424,
							426,
							425,
							422,
							426,
							427,
							425,
							426,
							428,
							427,
							426,
							429,
							428,
							430,
							431,
							432,
							433,
							431,
							430,
							434,
							431,
							433,
							435,
							431,
							434,
							436,
							431,
							435,
							437,
							431,
							436,
							438,
							431,
							437,
							439,
							431,
							438,
							440,
							431,
							439,
							441,
							431,
							440,
							442,
							431,
							441
						]
					},
					boundingSphere: { center: [0.00026046857237815857, 0, -0.012535907328128815], radius: 0.15947269950540158 }
				}
			}
		],
		materials: [
			{
				uuid: '596FE5BC-288B-4971-86E0-A98E10BE93F7',
				type: 'MeshStandardMaterial',
				color: 16777215,
				roughness: 1,
				metalness: 1,
				emissive: 0,
				depthFunc: 3,
				depthTest: true,
				depthWrite: true,
				stencilWrite: false,
				stencilWriteMask: 255,
				stencilFunc: 519,
				stencilRef: 0,
				stencilFuncMask: 255,
				stencilFail: 7680,
				stencilZFail: 7680,
				stencilZPass: 7680
			}
		],
		object: {
			uuid: 'A85A5348-07A1-4DBF-BAFC-12AA77C9DEA9',
			type: 'Mesh',
			name: 'Curve',
			userData: { name: 'Curve' },
			layers: 1,
			matrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
			geometry: '2A10A16C-AE77-4B4C-A2CF-D7D1FB4692E3',
			material: '596FE5BC-288B-4971-86E0-A98E10BE93F7'
		}
	}
}
