import { connect } from 'ui-lib'
import { Select, Radio, Input } from 'antd'
import React from 'react'
import iconChecked from '../../../../../../images/icon-checked.svg'
import iconUnchecked from '../../../../../../images/icon-unchecked.svg'
import iconArrowDown from '../../../../../../images/icon-arrow-down.svg'
import iconAdd from '../../../../../../images/icon-add-group.svg'
import iconModify from '../../../../../../images/icon-modify-dimmed.svg'
import iconRemove from '../../../../../../images/icon-delete-dimmed.svg'
import 'antd/lib/select/style/css'

import * as actions from '../../../store/actions'
import { template } from 'lodash'

const { Option } = Select

class Config extends React.Component {
	state = {
		createMode: false,
		isTemplatePickerOpen: false,
		templateName: '',
		assignGroup: null,
		assignee: null,
		selectedGroup: '',
		selectedUser: '',
		selectedTemplate: null,
		editingTemplate: null,
		inputErrors: {}
	}

	static getDerivedStateFromProps(nextProps) {
		if (!nextProps.ruleState.serviceNowChecked) {
			return {
				createMode: false,
				isTemplatePickerOpen: false,
				templateName: '',
				assignGroup: null,
				assignee: null,
				selectedGroup: '',
				selectedUser: '',
				selectedTemplate: null,
				editingTemplate: null,
				inputErrors: {}
			}
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleOutsideClick, false)
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick, false)
	}

	handleOutsideClick = (e) => {
		if (e.target.closest('.servicenow-template-select') === null && e.target.closest('.assignment-select') === null) {
			this.setState({
				createMode: false,
				isTemplatePickerOpen: false,
				editingTemplate: null,
				inputErrors: {}
			})
		}
	}

	onSelect = (value, option) => {
		if (option.id === 'addNewTemplate') {
			this.setState({
				createMode: true,
				isTemplatePickerOpen: true,
				templateName: '',
				assignGroup: null,
				assignee: null,
				selectedGroup: '',
				selectedUser: '',
				selectedTemplate: null,
				editingTemplate: null,
				inputErrors: {}
			})
		} else if (value === 'loading') {
			this.setState({ templateName: '' })
		} else if (option.id === 'editNewTemplate') {
			return
		} else {
			this.select.blur()
			this.props.setRuleState({ serviceNowTemplateId: value })
			this.setState({
				selectedTemplate: option.name,
				isTemplatePickerOpen: false,
				templateName: '',
				assignGroup: null,
				assignee: null,
				selectedGroup: '',
				selectedUser: '',
				editingTemplate: null,
				inputErrors: {}
			})
		}
	}

	onOpenTemplatePicker = () => {
		this.setState({ isTemplatePickerOpen: true })
		this.props.fetchSNTemplatesRequest()
	}

	onOpenGroupPicker = () => {
		this.props.fetchSNGroupsRequest()
	}

	onOpenGroupUserPicker = () => {
		this.props.fetchSNGroupUsersRequest(this.state.assignGroup)
	}

	render() {
		const {
			rules,
			rulesLoading,
			ruleState,
			setRuleState,
			snTemplates,
			snTemplatesLoading,
			snTemplatesError,
			snGroups,
			snGroupsLoading,
			snGroupsError,
			snGroupUsers,
			snGroupUsersLoading,
			snGroupUsersError
		} = this.props
		const selSNTemplate = snTemplates && snTemplates.filter((i) => i.template_id === ruleState.serviceNowTemplateId)
		const snTemplate = selSNTemplate && selSNTemplate[0] ? selSNTemplate[0].name : this.state.selectedTemplate
		return (
			<div className="template-picker">
				<Select
					ref={(select) => (this.select = select)}
					value={snTemplate}
					suffixIcon={<img src={iconArrowDown} alt="expand-icon" />}
					prefixCls={`${this.state.createMode ? 'create-mode' : ''} servicenow-template-select  ant-select`}
					open={this.state.isTemplatePickerOpen}
					showArrow
					style={{ width: '100%' }}
					placeholder="Please select a template"
					onSelect={this.onSelect}
					onFocus={() => {
						if (!this.state.isTemplatePickerOpen) this.onOpenTemplatePicker()
					}}
				>
					{this.state.createMode ? (
						<Option value="New ServiceNow Template" id="editNewTemplate">
							<div className="create-group">
								<div className="title">{this.state.editingTemplate ? 'Edit ServiceNow Template' : 'New ServiceNow Template'}</div>
								<div className="input-item">
									<div className="title">ServiceNow Template Name</div>
									<div className="input">
										<Input
											allowClear
											maxLength={256}
											value={this.state.templateName}
											onFocus={(e) => {
												e.stopPropagation()
												this.setState({ isTemplatePickerOpen: true, createMode: true })
											}}
											onChange={(e) => {
												this.setState({ templateName: e.target.value })
											}}
										/>
										{this.state.inputErrors[0] ? <div className="input-error">{`* ${this.state.inputError[0]}`}</div> : null}
									</div>
								</div>
								<div className="input-item">
									<div className="title">Assignment Group</div>
									<div className="input">
										<Select
											showArrow
											suffixIcon={<img src={iconArrowDown} alt="expand-icon" />}
											prefixCls="assignment-select  ant-select"
											value={this.state.selectedGroup}
											style={{ width: '100%' }}
											placeholder="Please select a group"
											onSelect={(value, option) => {
												this.setState({ assignGroup: value, selectedGroup: option.name, assignee: '', selectedUser: '' })
											}}
											onFocus={(e) => {
												e.stopPropagation()
												this.onOpenGroupPicker()
											}}
										>
											{snGroupsError ? (
												<Option disabled value="loading">
													Error loading groups
												</Option>
											) : snGroupsLoading ? (
												<Option disabled value="loading">
													Loading
												</Option>
											) : _.isEmpty(snGroups) ? (
												<Option disabled value="loading">
													No data
												</Option>
											) : (
												snGroups.map((group) => (
													<Option name={group.name} value={group.sys_id}>
														{group.name}
													</Option>
												))
											)}
										</Select>
										{this.state.inputErrors[1] ? <div className="input-error">{`* ${this.state.inputError[1]}`}</div> : null}
									</div>
								</div>
								<div className="input-item">
									<div className="title">Assignee</div>
									<div className="input">
										<Select
											showArrow
											suffixIcon={<img src={iconArrowDown} alt="expand-icon" />}
											prefixCls="assignment-select  ant-select"
											value={this.state.selectedUser}
											style={{ width: '100%' }}
											placeholder="Please select an assignee"
											onSelect={(value, option) => {
												this.setState({ assignee: value, selectedUser: option.name })
											}}
											onFocus={(e) => {
												e.stopPropagation()
												this.onOpenGroupUserPicker()
											}}
										>
											{snGroupUsersError ? (
												<Option disabled value="loading">
													Error loading group users
												</Option>
											) : snGroupUsersLoading ? (
												<Option disabled value="loading">
													Loading
												</Option>
											) : _.isEmpty(snGroupUsers) ? (
												<Option disabled value="loading">
													No data
												</Option>
											) : (
												snGroupUsers.map((user) => (
													<Option name={user.user.display_value} value={user.user.value}>
														{user.user.display_value}
													</Option>
												))
											)}
										</Select>
										{this.state.inputErrors[1] ? <div className="input-error">{`* ${this.state.inputError[1]}`}</div> : null}
									</div>
								</div>

								<div className="btns">
									<span
										className="cancel-btn"
										onClick={() => {
											this.select.blur()
											this.setState({
												createMode: false,
												isTemplatePickerOpen: false,
												templateName: '',
												assignGroup: null,
												assignee: null,
												selectedGroup: '',
												selectedUser: '',
												selectedTemplate: null,
												editingTemplate: null,
												inputErrors: {}
											})
										}}
									>
										Cancel
									</span>
									<span
										className={`next-btn ${_.isEmpty(this.state.templateName) || _.isEmpty(this.state.selectedUser) || _.isEmpty(this.state.selectedGroup) ? 'disabled' : ''}`}
										onClick={() => {
											if (_.isEmpty(this.state.templateName) || _.isEmpty(this.state.selectedGroup) || _.isEmpty(this.state.selectedUser)) {
												return
											}
											if (this.state.editingTemplate) {
												this.props.updateSNTemplateRequest(this.state.editingTemplate.template_id, {
													name: this.state.templateName,
													assignee: this.state.selectedUser,
													assignment_group: this.state.selectedGroup
												})
												this.setState({ createMode: false, isTemplatePickerOpen: true })
											} else {
												this.props.addSNTemplateRequest({
													name: this.state.templateName,
													assignee: this.state.selectedUser,
													assignment_group: this.state.selectedGroup
												})
												this.setState({ createMode: false, isTemplatePickerOpen: true })
											}
										}}
									>
										Next
									</span>
								</div>
							</div>
						</Option>
					) : (
						<>
							<Option value="New Email Group" id="addNewTemplate">
								<img src={iconAdd} alt="" className="icon-add-group" />
								<span className="text">Create a new ServiceNow template</span>
							</Option>
							{snTemplatesError ? (
								<Option disabled value="loading">
									Error loading tempaltes
								</Option>
							) : snTemplatesLoading ? (
								<Option disabled value="loading">
									Loading
								</Option>
							) : _.isEmpty(snTemplates) ? (
								<Option disabled value="loading">
									No data
								</Option>
							) : (
								snTemplates.map((template) => {
									const allowRemove = (rules && rules.findIndex((rule) => rule.servicenow_template_id === template.template_id) < 0) || (_.isEmpty(rules) && !rulesLoading)
									return (
										<Option name={template.name} value={template.template_id}>
											{template.name}
											<img
												src={iconModify}
												onClick={(e) => {
													e.stopPropagation()
													this.setState({
														createMode: true,
														isTemplatePickerOpen: true,
														templateName: template.name,
														assignGroup: null,
														assignee: null,
														selectedGroup: template.assignment_group,
														selectedUser: template.assignee,
														selectedTemplate: null,
														editingTemplate: template,
														inputErrors: {}
													})
												}}
												alt=""
												className="icon-modify"
											/>
											{allowRemove ? (
												<img
													src={iconRemove}
													onClick={(e) => {
														e.stopPropagation()
														this.props.removeSNTemplateRequest(template.template_id)
														this.setState({ isTemplatePickerOpen: true })
													}}
													alt=""
													className="icon-remove"
												/>
											) : null}
										</Option>
									)
								})
							)}
						</>
					)}
				</Select>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alertsNotifications }
}

const mapDispatchToProps = { ...actions }

export default connect(mapStateToProps, mapDispatchToProps, null)(Config)
