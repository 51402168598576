import React, { Component } from 'react'
import ExploreGridWidget from './ExploreGridWidget/ExploreGridWidget'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'
import { quotes } from '../LogQuery'
import ArrayTypeCell from './CellRenderers/ArrayTypeCell'
import util from '../../utils/Util'
import Constants from '../../utils/Constants'
import _ from 'lodash'

class LogTable extends Component {
	constructor(props) {
		super(props)
		this.autoSizeCols = []
	}

	componentDidMount() {
		this.props.fetchLogsCountRequest()
		this.props.fetchLogsRequest()
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.logs !== this.props.logs || nextProps.logsLoading !== this.props.logsLoading || nextProps.logsError !== this.props.logsError) {
			return true
		}
		return false
	}

	onGridReady = (params) => {
		this.gridApi = params.api
		this.gridColumnApi = params.columnApi
		this.gridColumnApi.autoSizeColumns(this.autoSizeCols)
	}

	onCellClicked = ({ colDef, value, data, event }) => {
		if (typeof value !== 'undefined' && value !== null && !util.isArrayTypeField(colDef.fieldObj) && !event.target.className.includes('user-i-icon')) {
			const fieldName = colDef.fieldObj.name
			let fieldValue = util.computeQueryBuilderAccessor(data, colDef.fieldObj, true)
			const fieldType = colDef.fieldObj.type
			const fieldOperators = colDef.fieldObj.operators
			if (fieldType === 'string' || fieldType === 'enum' || fieldType === 'pan_prefix_string' || fieldType === 'timestamp') {
				if (fieldType === 'timestamp') {
					fieldValue = util.getTransformedTime(fieldValue)
				}
				fieldValue = quotes(fieldValue)
			}
			let updatedQuery
			const operator = fieldOperators && fieldOperators.length ? fieldOperators[0] : '='
			if (this.props.query) {
				updatedQuery = `${this.props.query} AND ${fieldName} ${operator} ${fieldValue}`
			} else {
				updatedQuery = `${fieldName} ${operator} ${fieldValue}`
			}
			this.props.updateQuery(updatedQuery)
		}
	}

	onSortChanged = () => {
		const { sortOrder, pageNumber } = this.props
		const updatedOrder = sortOrder === 'DESC' ? 'ASC' : 'DESC'
		this.props.updateSortOrder(updatedOrder)
		this.props.updatePageRequest({ pageNumber, sortOrderChanged: true })
	}

	cellRenderer = (gObj) => {
		// LUT for setting functions to make values more human readable
		// each colDef.field value needs to be added to an allow list
		const valueFormatterLut = {
			bytes_total: 'getReadableBytes',
			count_of_repeats: 'getReadableNumber',
			url_idx: 'getReadableNumber',
			'vendor_severity.value': 'getStyledSeverity',
			risk_of_app: 'getStyledRisk',
			severity: 'getStyledSeverity'
		}
		let v = util.computeAccessor(gObj.data, gObj.colDef.fieldObj, true)
		// if there is an entry for this column, run the util function for it
		if (valueFormatterLut[gObj.colDef.field] !== undefined && v !== undefined) {
			v = util[valueFormatterLut[gObj.colDef.field]](v)
		}
		if (v !== undefined && typeof v === 'string') {
			v = v.slice(0, 512)
		}
		return v !== undefined ? v : ''
	}

	render() {
		const { sortOrder, logs, logsLoading, logsError, logType, schema, onOpenLogDetailPanel } = this.props
		if (logsLoading) {
			return (
				<div className={'info-area'}>
					<img className="loading-spinner" src={iconLoading} alt={'loading'} />
					Loading Data
				</div>
			)
		}

		if (logsError) {
			return <div className={'info-area'}>Fetching logs failed</div>
		}

		let gridData = logs
		if (logs.length === 0) {
			return (
				<div className={'info-area'}>
					<h5 className="logviewer-nodata">There are no results that match your query.</h5>
					<h6 className="logviewer-nodatasub">Please check your entry and try again</h6>
				</div>
			)
		}
		const [vendor, type] = logType.split('.')
		let selectedSchema = []
		if (vendor && type) {
			selectedSchema = _.get(schema, `${vendor}.content.${type}`)
			if (!selectedSchema) {
				return <div />
			}
		}
		const fields = selectedSchema.fields

		const defaultColDef = {
			sortable: true,
			suppressMultiSort: true,
			sortingOrder: ['desc', 'asc'],
			comparator: function () {
				// invalidate local sort
				return 0
			}
		}

		const columns = _.map(fields, (fieldObj, i) => {
			if (!fieldObj.width) {
				this.autoSizeCols.push(fieldObj.name)
			}
			let colOrder = ''
			if (fieldObj.name === selectedSchema.defaultSort) {
				colOrder = sortOrder === 'ASC' ? 'asc' : 'desc'
			}
			const col = {
				sortable: fieldObj.sortable,
				headerName: fieldObj.displayName.toLowerCase(),
				field: fieldObj.name,
				fieldObj,
				sort: colOrder,
				filter: false,
				pinned: fieldObj.pinned,
				resizable: true,
				autoHeight: true,
				hide: fieldObj.hidden,
				cellRenderer: this.cellRenderer
			}
			if (util.isArrayTypeField(fieldObj)) {
				col.cellStyle = { cursor: 'default' }
			}
			if (fieldObj.width > 0) {
				col.width = fieldObj.width
			}
			if (Constants.USER_POPOVERS.includes(fieldObj.name)) {
				col.cellRenderer = null
				// col.cellRendererFramework = UserCell
			}
			if (util.isArrayTypeField(fieldObj)) {
				col.cellRenderer = null
				col.cellRendererFramework = ArrayTypeCell
			}
			return col
		})
		if (columns) {
			columns.unshift({
				headerName: 'Details',
				autoHeight: true,
				field: 'details',
				cellRenderer: function () {
					return '<div class="log-details-icon"/>'
				},
				onCellClicked: (cell) => {
					cell.data.__table_row_index = cell.rowIndex
					onOpenLogDetailPanel(cell.data)
				},
				resizable: false,
				sortable: false,
				filter: false,
				width: 100,
				suppressMovable: true,
				pinned: true,
				lockPosition: true,
				lockPinned: true,
				lockVisible: true
			})
		}
		return (
			<ExploreGridWidget
				columns={columns}
				gridData={gridData}
				columnsResizeFn={this.onGridReady}
				rowSelection="single"
				checkboxSelection={false}
				showGridToolBar={false}
				showPaginationTop={false}
				agGridProps={{
					logType,
					schema: schema[vendor],
					defaultColDef,
					icons: { 'custom-stats': '<span class="ag-icon ag-icon-custom-stats"></span>' },
					getRowHeight: () => 26,
					getMainMenuItems: (e) => {
						return util.getMainMenuItems(e)
					},
					groupDefaultExpanded: false,
					onCellClicked: this.onCellClicked,
					onSortChanged: this.onSortChanged,
					onFirstDataRendered: false,
					sizeColumnsToFit: () => {}
				}}
			/>
		)
	}
}

export default LogTable
