import Menu from "./Menu";
import SubMenu from "./SubMenu";
import MenuItem from "./MenuItem";
import MenuItemGroup from "./MenuItemGroup";
import Divider from "./Divider";

export {
	SubMenu,
	MenuItem as Item,
	MenuItem,
	MenuItemGroup,
	MenuItemGroup as ItemGroup,
	Divider,
};

export default Menu;
