import React, { Component } from 'react'
import { connect, PopoverWidget as Popover } from 'ui-lib'
import _ from 'lodash'
import moment from 'moment'
import * as actions from './store/actions'
import * as visibilityActions from '../store/actions'
import Constants from './utils/Constants.json'
import Util from './utils/Util'
import visUtil from '../util/Util'

import InsightsMain from './pages/InsightsMain'
import DeviceDetails from './pages/DeviceDetails/DeviceDetails'
import TableMetricDetails from './pages/TableMetricDetails'
import LineMetricDetails from './pages/LineMetricDetails'

import './Insights.scss'

class Insights extends Component {
	constructor(props) {
		super(props)
		this.state = {
			staleStatusTimeout: null
		}

		this.updateURL()
	}

	componentDidMount() {
		this.props.updateActiveApp('insights')
		window.addEventListener('popstate', this.backURL)
		this.updateURL()
	}

	componentDidUpdate(prevProps) {
		// Track if tab data is stale
		const prevLastRefresh = visUtil.getLastRefreshOfActiveTab(prevProps)
		const currentLastRefresh = visUtil.getLastRefreshOfActiveTab(this.props)
		if (currentLastRefresh && prevLastRefresh !== currentLastRefresh) {
			this.staleTabTracker()
		}
		this.updateURL()
	}

	componentWillUnmount() {
		if (this.state.staleStatusTimeout) {
			clearTimeout(this.state.staleStatusTimeout)
		}
		this.props.cacheInsightsTab(this.props.activeInsightsTab) // Cache current running tab before switch to different app
		window.removeEventListener('popstate', this.backURL)
	}

	staleTabTracker = () => {
		if (this.state.staleStatusTimeout) {
			clearTimeout(this.state.staleStatusTimeout)
		}
		// let timeout = 5 * 1000
		let timeout = Constants.TAB_TIMEOUT * 60 * 60 * 1000
		if (this.props.insightsTabs) {
			const lastRefresh = visUtil.getLastRefreshOfActiveTab(this.props)
			// timeout = lastRefresh.clone().add(5, 's').diff(moment(), 'milliseconds')
			timeout = moment(lastRefresh).clone().add(Constants.TAB_TIMEOUT, 'h').diff(moment(), 'milliseconds')
		}
		if (timeout > 0) {
			this.setState({
				staleStatusTimeout: setTimeout(() => {
					this.props.updateInsightsStaleStatus(this.props.activeInsightsTab, true)
				}, timeout)
			})
		} else {
			this.props.updateInsightsStaleStatus(this.props.activeInsightsTab, true)
		}
	}

	backURL = () => {
		history.pushState(null, null, `/insights?type=devices`)
	}

	updateURL = () => {
		if (this.props.activeInsightsTab === null) return
		let pathname = ''
		const { activeInsightsTab } = this.props
		if (activeInsightsTab !== null) {
			if (_.isString(activeInsightsTab)) {
				pathname = `type=${activeInsightsTab.split('.')[1]}`
				if (activeInsightsTab === Constants.FIXED_TABS[2]) {
					const { fixed_tabs } = this.props.insightsTabs
					pathname = `type=${activeInsightsTab.split('.')[1]}&device=${Util.encode(fixed_tabs[2].desc.device)}&hostname=${Util.encode(fixed_tabs[2].desc.hostname)}`
				}
			} else {
				const { custom_tabs } = this.props.insightsTabs
				pathname = `type=details&device=${Util.encode(custom_tabs[activeInsightsTab].desc.device)}&hostname=${Util.encode(custom_tabs[activeInsightsTab].desc.hostname)}&metric=${
					custom_tabs[activeInsightsTab].desc.metric
				}`
			}
		}

		history.pushState(null, null, `/insights/?${pathname}`)
	}

	render() {
		let { insightsTabs, activeInsightsTab } = this.props

		let pageContent
		if (Constants.FIXED_TABS.includes(activeInsightsTab)) {
			if (activeInsightsTab === 'insights.devicedetails') {
				pageContent = <DeviceDetails />
			} else {
				pageContent = <InsightsMain />
			}
		} else if (insightsTabs.custom_tabs[activeInsightsTab].desc.metric in Constants.TABLE_CHART_INFO_MAP) {
			pageContent = (
				<div className="working-tab-body">
					<TableMetricDetails />
				</div>
			)
		} else {
			pageContent = (
				<div className="working-tab-body">
					<LineMetricDetails />
				</div>
			)
		}

		return <div id="insights-app">{pageContent}</div>
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.insights, userPreference: state.main.loggedInUser.preference }
}

const mapDispatchToProps = { ...actions, ...visibilityActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(Insights)
