export default function () {
	return {
		metadata: { version: 4.5, type: 'Object', generator: 'Object3D.toJSON' },
		geometries: [
			{
				uuid: 'AEBFB99A-4CC7-487A-AC20-BCB66E062FFB',
				type: 'BufferGeometry',
				data: {
					attributes: {
						position: {
							itemSize: 3,
							type: 'Float32Array',
							array: [
								0.4330126941204071,
								0,
								0.25577351450920105,
								0.4280126988887787,
								0,
								0.24711324274539948,
								-9.07879360496966e-17,
								0,
								0.49422648549079895,
								0.004999999888241291,
								0,
								0.5028867721557617,
								0.004999999888241291,
								0,
								0.5028867721557617,
								-9.07879360496966e-17,
								0,
								0.49422648549079895,
								-0.004999999888241291,
								0,
								0.5028867721557617,
								0.004999999888241291,
								0,
								-0.5028867721557617,
								-0.004999999888241291,
								0,
								-0.5028867721557617,
								3.026264424699138e-17,
								0,
								-0.49422648549079895,
								0.4330126941204071,
								0,
								-0.25577351450920105,
								0.004999999888241291,
								0,
								-0.5028867721557617,
								3.026264424699138e-17,
								0,
								-0.49422648549079895,
								0.4280126988887787,
								0,
								-0.24711324274539948,
								-0.004999999888241291,
								0,
								-0.5028867721557617,
								-0.4330126941204071,
								0,
								-0.25577351450920105,
								-0.4280126988887787,
								0,
								-0.24711324274539948,
								-0.4280126988887787,
								0,
								-0.24711324274539948,
								-0.4330126941204071,
								0,
								-0.25577351450920105,
								-0.4380126893520355,
								0,
								-0.24711324274539948,
								-0.4280126988887787,
								0,
								0.24711324274539948,
								-0.4380126893520355,
								0,
								0.24711324274539948,
								-0.4330126941204071,
								0,
								0.25577351450920105,
								-0.4280126988887787,
								0,
								0.24711324274539948,
								-0.4330126941204071,
								0,
								0.25577351450920105,
								-0.004999999888241291,
								0,
								0.5028867721557617,
								0.4380126893520355,
								0,
								-0.24711324274539948,
								0.4330126941204071,
								0,
								-0.25577351450920105,
								0.4280126988887787,
								0,
								-0.24711324274539948,
								0.4380126893520355,
								0,
								0.24711324274539948,
								0.4280126988887787,
								0,
								0.24711324274539948,
								0.4330126941204071,
								0,
								0.25577351450920105,
								-9.07879360496966e-17,
								-0.03999999910593033,
								0.49422648549079895,
								0.4280126988887787,
								-0.03999999910593033,
								0.24711324274539948,
								0.4330126941204071,
								-0.03999999910593033,
								0.25577351450920105,
								0.004999999888241291,
								-0.03999999910593033,
								0.5028867721557617,
								0.004999999888241291,
								-0.03999999910593033,
								0.5028867721557617,
								-0.004999999888241291,
								-0.03999999910593033,
								0.5028867721557617,
								-9.07879360496966e-17,
								-0.03999999910593033,
								0.49422648549079895,
								0.004999999888241291,
								-0.03999999910593033,
								-0.5028867721557617,
								3.026264424699138e-17,
								-0.03999999910593033,
								-0.49422648549079895,
								-0.004999999888241291,
								-0.03999999910593033,
								-0.5028867721557617,
								3.026264424699138e-17,
								-0.03999999910593033,
								-0.49422648549079895,
								0.004999999888241291,
								-0.03999999910593033,
								-0.5028867721557617,
								0.4330126941204071,
								-0.03999999910593033,
								-0.25577351450920105,
								0.4280126988887787,
								-0.03999999910593033,
								-0.24711324274539948,
								-0.4330126941204071,
								-0.03999999910593033,
								-0.25577351450920105,
								-0.004999999888241291,
								-0.03999999910593033,
								-0.5028867721557617,
								-0.4280126988887787,
								-0.03999999910593033,
								-0.24711324274539948,
								-0.4280126988887787,
								-0.03999999910593033,
								-0.24711324274539948,
								-0.4380126893520355,
								-0.03999999910593033,
								-0.24711324274539948,
								-0.4330126941204071,
								-0.03999999910593033,
								-0.25577351450920105,
								-0.4380126893520355,
								-0.03999999910593033,
								0.24711324274539948,
								-0.4280126988887787,
								-0.03999999910593033,
								0.24711324274539948,
								-0.4330126941204071,
								-0.03999999910593033,
								0.25577351450920105,
								-0.4330126941204071,
								-0.03999999910593033,
								0.25577351450920105,
								-0.4280126988887787,
								-0.03999999910593033,
								0.24711324274539948,
								-0.004999999888241291,
								-0.03999999910593033,
								0.5028867721557617,
								0.4380126893520355,
								-0.03999999910593033,
								-0.24711324274539948,
								0.4280126988887787,
								-0.03999999910593033,
								-0.24711324274539948,
								0.4330126941204071,
								-0.03999999910593033,
								-0.25577351450920105,
								0.4280126988887787,
								-0.03999999910593033,
								0.24711324274539948,
								0.4380126893520355,
								-0.03999999910593033,
								0.24711324274539948,
								0.4330126941204071,
								-0.03999999910593033,
								0.25577351450920105,
								-0.4280126988887787,
								-0.03999999910593033,
								-0.24711324274539948,
								3.026264424699138e-17,
								-0.03999999910593033,
								-0.49422648549079895,
								0.4280126988887787,
								-0.03999999910593033,
								-0.24711324274539948,
								-0.4280126988887787,
								-0.03999999910593033,
								0.24711324274539948,
								0.4280126988887787,
								-0.03999999910593033,
								0.24711324274539948,
								-9.07879360496966e-17,
								-0.03999999910593033,
								0.49422648549079895,
								0.004999999888241291,
								0,
								0.5028867721557617,
								0.004999999888241291,
								-0.03999999910593033,
								0.5028867721557617,
								0.4330126941204071,
								-0.03999999910593033,
								0.25577351450920105,
								0.4330126941204071,
								0,
								0.25577351450920105,
								-0.004999999888241291,
								0,
								0.5028867721557617,
								-0.004999999888241291,
								-0.03999999910593033,
								0.5028867721557617,
								0.004999999888241291,
								-0.03999999910593033,
								0.5028867721557617,
								0.004999999888241291,
								0,
								0.5028867721557617,
								0.004999999888241291,
								0,
								-0.5028867721557617,
								0.004999999888241291,
								-0.03999999910593033,
								-0.5028867721557617,
								-0.004999999888241291,
								-0.03999999910593033,
								-0.5028867721557617,
								-0.004999999888241291,
								0,
								-0.5028867721557617,
								0.4330126941204071,
								0,
								-0.25577351450920105,
								0.4330126941204071,
								-0.03999999910593033,
								-0.25577351450920105,
								0.004999999888241291,
								-0.03999999910593033,
								-0.5028867721557617,
								0.004999999888241291,
								0,
								-0.5028867721557617,
								-0.004999999888241291,
								0,
								-0.5028867721557617,
								-0.004999999888241291,
								-0.03999999910593033,
								-0.5028867721557617,
								-0.4330126941204071,
								-0.03999999910593033,
								-0.25577351450920105,
								-0.4330126941204071,
								0,
								-0.25577351450920105,
								-0.4330126941204071,
								0,
								-0.25577351450920105,
								-0.4330126941204071,
								-0.03999999910593033,
								-0.25577351450920105,
								-0.4380126893520355,
								-0.03999999910593033,
								-0.24711324274539948,
								-0.4380126893520355,
								0,
								-0.24711324274539948,
								-0.4380126893520355,
								0,
								-0.24711324274539948,
								-0.4380126893520355,
								-0.03999999910593033,
								-0.24711324274539948,
								-0.4380126893520355,
								-0.03999999910593033,
								0.24711324274539948,
								-0.4380126893520355,
								0,
								0.24711324274539948,
								-0.4380126893520355,
								0,
								0.24711324274539948,
								-0.4380126893520355,
								-0.03999999910593033,
								0.24711324274539948,
								-0.4330126941204071,
								-0.03999999910593033,
								0.25577351450920105,
								-0.4330126941204071,
								0,
								0.25577351450920105,
								-0.4330126941204071,
								0,
								0.25577351450920105,
								-0.4330126941204071,
								-0.03999999910593033,
								0.25577351450920105,
								-0.004999999888241291,
								-0.03999999910593033,
								0.5028867721557617,
								-0.004999999888241291,
								0,
								0.5028867721557617,
								0.4380126893520355,
								0,
								-0.24711324274539948,
								0.4380126893520355,
								-0.03999999910593033,
								-0.24711324274539948,
								0.4330126941204071,
								-0.03999999910593033,
								-0.25577351450920105,
								0.4330126941204071,
								0,
								-0.25577351450920105,
								0.4380126893520355,
								0,
								0.24711324274539948,
								0.4380126893520355,
								-0.03999999910593033,
								0.24711324274539948,
								0.4380126893520355,
								-0.03999999910593033,
								-0.24711324274539948,
								0.4380126893520355,
								0,
								-0.24711324274539948,
								0.4330126941204071,
								0,
								0.25577351450920105,
								0.4330126941204071,
								-0.03999999910593033,
								0.25577351450920105,
								0.4380126893520355,
								-0.03999999910593033,
								0.24711324274539948,
								0.4380126893520355,
								0,
								0.24711324274539948
							],
							normalized: false
						},
						normal: {
							itemSize: 3,
							type: 'Float32Array',
							array: [
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								0.9999999403953552,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-0.9999999403953552,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								2.9351221453310927e-9,
								-0.9999999403953552,
								0,
								2.9351221453310927e-9,
								-0.9999999403953552,
								0,
								2.9351221453310927e-9,
								-0.9999999403953552,
								0,
								2.9351221453310927e-9,
								-0.9999999403953552,
								0,
								2.9351221453310927e-9,
								-0.9999999403953552,
								0,
								2.9351221453310927e-9,
								-0.9999999403953552,
								0,
								0.5,
								0,
								0.866025447845459,
								0.5,
								0,
								0.866025447845459,
								0.5,
								0,
								0.866025447845459,
								0.5,
								0,
								0.866025447845459,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0.5,
								0,
								-0.866025447845459,
								0.5,
								0,
								-0.866025447845459,
								0.5,
								0,
								-0.866025447845459,
								0.5,
								0,
								-0.866025447845459,
								-0.5,
								0,
								-0.866025447845459,
								-0.5,
								0,
								-0.866025447845459,
								-0.5,
								0,
								-0.866025447845459,
								-0.5,
								0,
								-0.866025447845459,
								-0.8660261034965515,
								0,
								-0.49999886751174927,
								-0.8660261034965515,
								0,
								-0.49999886751174927,
								-0.8660261034965515,
								0,
								-0.49999886751174927,
								-0.8660261034965515,
								0,
								-0.49999886751174927,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-1,
								0,
								0,
								-0.8660261034965515,
								0,
								0.49999886751174927,
								-0.8660261034965515,
								0,
								0.49999886751174927,
								-0.8660261034965515,
								0,
								0.49999886751174927,
								-0.8660261034965515,
								0,
								0.49999886751174927,
								-0.5,
								0,
								0.866025447845459,
								-0.5,
								0,
								0.866025447845459,
								-0.5,
								0,
								0.866025447845459,
								-0.5,
								0,
								0.866025447845459,
								0.8660261034965515,
								0,
								-0.49999886751174927,
								0.8660261034965515,
								0,
								-0.49999886751174927,
								0.8660261034965515,
								0,
								-0.49999886751174927,
								0.8660261034965515,
								0,
								-0.49999886751174927,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								1,
								0,
								0,
								0.8660261034965515,
								0,
								0.49999886751174927,
								0.8660261034965515,
								0,
								0.49999886751174927,
								0.8660261034965515,
								0,
								0.49999886751174927,
								0.8660261034965515,
								0,
								0.49999886751174927
							],
							normalized: false
						}
					},
					index: {
						type: 'Uint16Array',
						array: [
							0,
							1,
							2,
							3,
							0,
							2,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							10,
							12,
							13,
							12,
							14,
							15,
							16,
							12,
							15,
							17,
							18,
							19,
							20,
							17,
							19,
							20,
							19,
							21,
							20,
							21,
							22,
							2,
							23,
							24,
							2,
							24,
							25,
							26,
							27,
							28,
							29,
							26,
							28,
							29,
							28,
							30,
							29,
							30,
							31,
							13,
							12,
							16,
							1,
							13,
							16,
							1,
							16,
							23,
							2,
							1,
							23,
							32,
							33,
							34,
							32,
							34,
							35,
							36,
							37,
							38,
							39,
							40,
							41,
							42,
							43,
							44,
							45,
							42,
							44,
							46,
							47,
							42,
							46,
							42,
							48,
							49,
							50,
							51,
							52,
							50,
							49,
							52,
							49,
							53,
							53,
							54,
							52,
							55,
							56,
							32,
							57,
							55,
							32,
							58,
							59,
							60,
							61,
							59,
							58,
							61,
							58,
							62,
							62,
							63,
							61,
							64,
							65,
							66,
							67,
							64,
							66,
							67,
							66,
							68,
							69,
							67,
							68,
							70,
							71,
							72,
							70,
							72,
							73,
							74,
							75,
							76,
							74,
							76,
							77,
							78,
							79,
							80,
							78,
							80,
							81,
							82,
							83,
							84,
							82,
							84,
							85,
							86,
							87,
							88,
							86,
							88,
							89,
							90,
							91,
							92,
							90,
							92,
							93,
							94,
							95,
							96,
							94,
							96,
							97,
							98,
							99,
							100,
							98,
							100,
							101,
							102,
							103,
							104,
							102,
							104,
							105,
							106,
							107,
							108,
							106,
							108,
							109,
							110,
							111,
							112,
							110,
							112,
							113,
							114,
							115,
							116,
							114,
							116,
							117
						]
					},
					boundingSphere: { center: [0, -0.019999999552965164, 0], radius: 0.6671957895736171 }
				}
			}
		],
		materials: [
			{
				uuid: '469E6708-1F2A-4621-85D8-AE0BD8D69646',
				type: 'MeshStandardMaterial',
				color: 16777215,
				roughness: 1,
				metalness: 1,
				emissive: 0,
				depthFunc: 3,
				depthTest: true,
				depthWrite: true,
				stencilWrite: false,
				stencilWriteMask: 255,
				stencilFunc: 519,
				stencilRef: 0,
				stencilFuncMask: 255,
				stencilFail: 7680,
				stencilZFail: 7680,
				stencilZPass: 7680
			}
		],
		object: {
			uuid: '10421D45-71EB-4406-A004-8D13992569D8',
			type: 'Mesh',
			name: 'honeycomb001',
			userData: { name: 'honeycomb.001' },
			layers: 1,
			matrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
			geometry: 'AEBFB99A-4CC7-487A-AC20-BCB66E062FFB',
			material: '469E6708-1F2A-4621-85D8-AE0BD8D69646'
		}
	}
}
