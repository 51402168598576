import './Main.scss';
import React, { Component } from 'react';
import { isUserLoggedInAction, hideSuccessMessage, fetchTokenInfo } from './actions';
import {
	SpinnerWidget,
	FeedbackWidget,
	Pan,
	getPathValue,
	connect,
	ModalStackWidget,
	LoadingWidget,
	registerMapboxToken,
	registerAGGridLicenseKey,
	hideModal,
	showModal,
	doLogin,
	doLogout,
	ModalWindowWidget
} from 'ui-lib';
import _ from 'lodash';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LicenseManager } from 'ag-grid-enterprise';
import Visibility from '../visibility/Visibility';

class Main extends Component {
	constructor(props) {
		super(props);
		this.onRevertSuccess = this.onRevertSuccess.bind(this);

		let { history } = props;
	}

	state = {
		selectedItem: 'insights',
		hasUserRBA: false,
		forceLogin: false,
		missingTenant: false
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (!prevState.hasUserRBA) {
			let userRba = getPathValue(nextProps, 'loggedInUser.rba');
			if (!Pan.isEmpty(userRba)) {
				return {
					hasUserRBA: true
				};
			}
		}
		return null;
	}

	componentDidUpdate() {
		const {
			isLoggedIn,
			noSession,
			loggedInUser,
			licenseInfo,
			tokenInfo,
			fetchLicenseError,
			fetchTokenError,
			fetchApplicationsError,
			content: { applications, fetchingApplications } = {}
		} = this.props;

		if ((isLoggedIn === false || this.state.forceLogin === true) && noSession !== true) {
			// if noSession is true: it implies that we did not find a tenantId. Not in URL, not in session
			// there is no point doing login - this causes issues when trying to pull a random support Account Id
			// and tenantId from Admin Cluster.
			doLogin();
			return;
		}

		if (isLoggedIn === true && loggedInUser && Pan.isEmpty(licenseInfo) && !fetchLicenseError) {
			// this.props.dispatch(fetchLicenseInfo());
		}

		if (isLoggedIn === true && Pan.isEmpty(tokenInfo) && !fetchTokenError) {
			this.props.dispatch(fetchTokenInfo());
		} else if (isLoggedIn === true && tokenInfo) {
			registerAGGridLicenseKey(tokenInfo.agGrid);
			registerMapboxToken(tokenInfo.mapBox);
			// register ag-grid license key for insights usage since we are using it directly
			LicenseManager.setLicenseKey(tokenInfo.agGrid);
		}
	}

	componentDidMount() {
		if (window.location.search.endsWith('loginFailed=true')) {
			let modal = {
				id: 'session-error',
				title: 'Error',
				type: 'Error',
				size: 'lg',
				toggle: () => {
					hideModal('session-error');
				},
				message: window.location.search.endsWith('invalidSession=true')
					? 'Your session may have expired or you do not have access to this tenant.'
					: 'We are unable to log you in. Your session may have expired or you do not have access to this tenant.',
				actions: [
					{
						text: 'Close',
						action: () => {
							hideModal('session-error');
							doLogout();
						}
					}
				]
			};
			showModal(modal);
		} else {
			console.log('inside componentDidMount of Main.js :: calling isUserLoggedInAction');
			this.props.dispatch(isUserLoggedInAction());
		}
		const onhashchange = () => {
			// @see sessionHelper
			if (window.location.hash === '#trylogin') {
				console.log('Session expired event trigger by session helper!');
				this.setState({ forceLogin: true });
			}
		};
		window.removeEventListener('hashchange', onhashchange);
		window.addEventListener('hashchange', onhashchange);
	}

	onOk() {
		this.props.dispatch(hideSuccessMessage());
	}

	onRevertSuccess() {
		window.location.reload();
	}

	render() {
		let { isLoggedIn, noSession, loggedInUser, tenant, modals, licenseInfo, tokenInfo } = this.props;
		let defaultRoutes = this.state.defaultRoutes;
		return (
			<div>
				{this.props.success && (
					<FeedbackWidget isOpen={this.props.success} message={this.props.successMessage} actions={[{ text: 'Ok', action: this.onOk.bind(this) }]} type="Error" title="Success" />
				)}

				<ModalStackWidget modals={modals} />

				{isLoggedIn === null && <SpinnerWidget />}
				{noSession === true && (
					<ModalWindowWidget
						isOpen={true}
						size="xl"
						fade
						centered
						title={'Error'}
						actions={[
							{
								text: 'Close',
								action: () => {
									doLogout();
								}
							}
						]}
					>
						{'We did not find a tenant id to log you in. Please navigate to this application from Application Hub to continue.'}
					</ModalWindowWidget>
				)}
				{isLoggedIn === true && loggedInUser && licenseInfo && tokenInfo && (
					<div>
						<Visibility />
					</div>
				)}
				<ToastContainer
					enableMultiContainer
					containerId={'mainToastContainer'}
					position="top-center"
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					autoClose={5000}
					pauseOnHover
					style={{
						width: '550px'
					}}
				/>
				<ToastContainer
					enableMultiContainer
					containerId={'pollingToastContainer'}
					position="top-right"
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					autoClose={5000}
					pauseOnHover
					style={{
						width: '550px'
					}}
				/>
				{this.props.loading && this.props.loading.show && <LoadingWidget renderTo={this.props.loading.renderTo} />}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.main ? state.main.loggedInUser : null,
		tenant: state.main ? state.main.tenant : null,
		extensions: state.main ? state.main.extensions : {},
		isLoggedIn: state.main ? state.main.isLoggedIn : null,
		noSession: state.main ? state.main.noSession : null,
		showRevertSuccess: state.main ? state.main.showRevertSuccess : false,
		revertMsg: state.main ? state.main.revertMsg : '',
		commitType: state.main ? state.main.commitType : 'commit',
		error: state.main ? state.main.error : null,
		errorMessage: state.main ? state.main.errorMessage : null,
		extra: state.main ? state.main.extra : null,
		success: state.main ? state.main.success : null,
		successMessage: state.main ? state.main.successMessage : null,
		configLocation: state.main ? state.main.configLocation : null,
		licenseInfo: state.main ? state.main.licenseInfo : null,
		tokenInfo: state.main ? state.main.tokenInfo : null,
		fetchLicenseError: state.main && state.main.fetchLicenseError,
		fetchTokenError: state.main && state.main.fetchTokenError,
		modals: state.main ? state.main.modals : [],
		loading: state.main ? state.main.loading : {},
		content: state.main ? state.main.content : {},
		fetchApplicationsError: state.main && state.main.fetchApplicationsError
	};
};

export default connect(mapStateToProps)(Main);
