import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of, forkJoin, concat } from 'rxjs'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import Util from '../utils/Util'
import Constants from '../utils/Constants.json'
import pageInfo from '../pages/pageUtil'
import visibilityUtil from '../../util/Util'

import * as actionTypes from './actionTypes'
// import * as services from './services'
import * as actions from './actions'

// This is only for preference
import * as visibilityActions from '../../store/actions'
import * as mainActions from '../../../main/actions'

/*--------- Alerts Initialization ----------*/
export const initAlertsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.INIT_ALERTS),
		switchMap(() => {
			// Check if it's back from explore
			if (store.value.alerts.finishedAlertsInitTasks.count === 0) {
				// Dispatch actions in the following order
				return of(actions.initAlertsSchema(), actions.initAlertsTabsState()) // actions.fetchAlertsMetadataRequest(),
			} else {
				return of()
			}
		})
	)
}

export const initAlertsTabsStateEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.INIT_ALERTS_TABS_STATE),
		switchMap(() => {
			let activeAlertsTab, fixed_tabs, custom_tabs
			let updatePref = false
			const userPref = _.get(store.value.main.loggedInUser, 'preference') ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}

			// 1. Check if user pref exists
			if (_.get(userPref, 'alerts')) {
				// 1.1 Restore Tabs State
				activeAlertsTab = _.get(userPref, 'alerts.activeTab')
				fixed_tabs = _.get(userPref, 'alerts.fixed_tabs')
				custom_tabs = _.get(userPref, 'alerts.custom_tabs')

				if (!fixed_tabs) {
					fixed_tabs = [
						{ id: Constants.FIXED_TABS[0], state: pageInfo.AlertsMain.pageDefaultSettings, pageType: pageInfo.AlertsMain.pageType },
						{ id: Constants.FIXED_TABS[1], state: pageInfo.AlertsNotifications.pageDefaultSettings, pageType: pageInfo.AlertsNotifications.pageType }
					]
					updatePref = true
				}

				if (!custom_tabs) {
					custom_tabs = []
					_.set(userPref, 'alerts.custom_tabs', custom_tabs)
					updatePref = true
				}

				if (_.isString(activeAlertsTab)) {
					if (!Constants.FIXED_TABS.includes(activeAlertsTab)) {
						activeAlertsTab = Constants.FIXED_TABS[0]
					}
				} else if (_.isInteger(activeAlertsTab)) {
					if (activeAlertsTab < 0 || activeAlertsTab > custom_tabs.length - 1) {
						activeAlertsTab = Constants.FIXED_TABS[0]
					}
				} else {
					activeAlertsTab = Constants.FIXED_TABS[0]
				}

				if (activeAlertsTab !== _.get(userPref, 'alerts.activeTab')) {
					_.set(userPref, 'alerts.activeTab', activeAlertsTab)
					updatePref = true
				}
			} else {
				// 1.2 Init Tabs State
				updatePref = true
				activeAlertsTab = Constants.FIXED_TABS[0]
				fixed_tabs = [
					{ id: Constants.FIXED_TABS[0], state: pageInfo.AlertsMain.pageDefaultSettings, pageType: pageInfo.AlertsMain.pageType },
					{ id: Constants.FIXED_TABS[1], state: pageInfo.AlertsNotifications.pageDefaultSettings, pageType: pageInfo.AlertsNotifications.pageType }
				]
				custom_tabs = []
			}

			// 2. Check if url carrys tab info
			const tabFromUrl = Util.constructTabFromUrl()
			if (tabFromUrl) {
				updatePref = true
				if (tabFromUrl.pageType === pageInfo.AlertsMain.pageType) {
					activeAlertsTab = Constants.FIXED_TABS[0]
				} else if (tabFromUrl.pageType === pageInfo.AlertsNotifications.pageType) {
					activeAlertsTab = Constants.FIXED_TABS[1]
				} else {
					// Open Tab From URL
					/***
					 * 1. If activeAlertsTab info in pref matches info from URL, restore pref
					 * 2. If not, do a search, switch to the first one found.
					 * 3. If not found, create a new tab.
					 * 4. If tab from url is the sixth tab, still open it but add warning notification.
					 */
					const prefTabDesc = _.get(userPref, `alerts.custom_tabs[${activeAlertsTab}].desc`) || _.get(userPref, `alerts.custom_tabs[${activeAlertsTab}].ids`)
					if (!_.isEqual(tabFromUrl.desc, prefTabDesc)) {
						const tabMatchURL = custom_tabs.findIndex((tab) => _.isEqual(tab.desc, tabFromUrl.desc))
						if (tabMatchURL >= 0) {
							activeAlertsTab = tabMatchURL
						} else {
							activeAlertsTab = custom_tabs.length
							custom_tabs.push(tabFromUrl)
						}
					}
				}
			}

			let actionList = []
			if (updatePref) {
				_.set(userPref, 'alerts.activeTab', activeAlertsTab)
				_.set(userPref, 'alerts.fixed_tabs', fixed_tabs)
				_.set(userPref, 'alerts.custom_tabs', custom_tabs)
				actionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}

			const tabs = { fixed_tabs: _.cloneDeep(fixed_tabs), custom_tabs: _.cloneDeep(custom_tabs) }
			tabs.fixed_tabs.forEach((tab) => {
				tab.lastRefresh = null
			})
			tabs.custom_tabs.forEach((tab) => {
				tab.lastRefresh = null
				tab.id = uuid()
			})

			actionList.push(actions.updateAlertsTabsState(tabs))

			if (custom_tabs.length > 5) {
				actionList.push(
					visibilityActions.addNotification({
						type: 'warning',
						message: 'Maximum tab limit reached (5). Please close unused tabs.'
					})
				)
			}
			// return concat(of(actionList))
			return concat(of(...actionList), of(actions.alertsInitTaskComplete('init_tabs')), of(actions.updateAlertsActiveTab(activeAlertsTab)))
		})
	)
}

/*--------- Tab Operations ----------*/
export const openAlertsTabEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.OPEN_ALERTS_TAB),
		switchMap((action) => {
			let tabs = store.value.alerts.alertsTabs
			if (tabs.custom_tabs.length >= 5) {
				return of(
					visibilityActions.addNotification({
						type: 'warning',
						message: 'Maximum tab limit reached (5). Please close unused tabs.'
					})
				)
			}
			let tab = {
				pageType: pageInfo.AlertDetails.pageType,
				desc: {
					alertname: _.get(action, 'pageDesc.alert_name'),
					hostname: _.get(action, 'pageDesc.hostname'),
					alertid: _.get(action, 'pageDesc.alert_id')
				},
				state: { ..._.cloneDeep(pageInfo.AlertDetails.pageDefaultSettings) },
				id: uuid()
			}

			// 1. Cache current tab
			const currentTabState = Util.getTabState(tabs, store.value.alerts.activeAlertsTab)
			const { storeKey, cacheUtil } = pageInfo[currentTabState.pageType]
			const pageCache = cacheUtil(store.value[storeKey], store.value.alerts.activeAlertsTab)
			tabs = Util.setTabState(tabs, store.value.alerts.activeAlertsTab, pageCache)
			// 2. Change activeAlertsTab to the newly added tab
			const activeAlertsTab = tabs.custom_tabs.length
			// 3. Append a new tab to the end of tabs.custom_tabs
			tabs.custom_tabs.push(tab)
			// 4. update pref object
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const custom_tabs_pref = _.get(userPref, 'alerts.custom_tabs')
			const tabPref = _.omit(_.cloneDeep(tab), 'id')
			tabPref.desc = {
				alertname: _.get(tabPref, 'desc.alertname'),
				hostname: _.get(tabPref, 'desc.hostname'),
				alertid: _.get(tabPref, 'desc.alertid')
			}
			custom_tabs_pref.push(tabPref)
			_.set(userPref, `alerts.custom_tabs`, custom_tabs_pref)

			return concat(
				of(actions.updateAlertsTabsState(tabs), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME)),
				of(actions.updateAlertsActiveTab(activeAlertsTab))
			)
		})
	)
}

export const switchAlertsTabEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.SWITCH_ALERTS_TAB),
		mergeMap((action) => {
			const { from, to } = action
			if (from === to) return of()
			let { alertsTabs } = store.value.alerts
			const fromTabState = Util.getTabState(alertsTabs, from)
			// cache action.from tab
			const { storeKey, cacheUtil } = pageInfo[fromTabState.pageType]
			const pageCache = cacheUtil(store.value[storeKey], from)
			alertsTabs = Util.setTabState(alertsTabs, from, pageCache)
			return concat(of(actions.resetStore(fromTabState.pageType)), of(actions.updateAlertsTabsState(alertsTabs)), of(actions.updateAlertsActiveTab(to)))
		})
	)
}

export const closeAlertsTabEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.CLOSE_ALERTS_TAB),
		mergeMap((action) => {
			// 1. Remove cache
			let { alertsTabs, activeAlertsTab } = store.value.alerts
			alertsTabs = { ...alertsTabs }
			const custom_tabs = [...alertsTabs.custom_tabs]
			const pageType = custom_tabs[action.index].pageType
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			let custom_tabs_pref = _.get(userPref, 'alerts.custom_tabs')
			_.pullAt(custom_tabs, action.index)
			_.pullAt(custom_tabs_pref, action.index)
			_.set(userPref, 'alerts.custom_tabs', custom_tabs_pref)
			alertsTabs.custom_tabs = custom_tabs
			// 2. Calculate next tab, switch to next tab
			let nextTab
			if (Constants.FIXED_TABS.includes(activeAlertsTab)) {
				nextTab = activeAlertsTab
			} else {
				if (custom_tabs.length === 0) {
					// 1. closed tab is the only tab
					nextTab = Constants.FIXED_TABS[0]
				} else if (action.index < activeAlertsTab) {
					// 2. closed tab is prior to active tab
					nextTab = activeAlertsTab - 1
				} else {
					// 3. closed tab is after active tab
					nextTab = activeAlertsTab
					if (custom_tabs.length === action.index && activeAlertsTab === action.index) {
						// 3.1 closed tab is the last tab, and it's the active tab
						nextTab = activeAlertsTab - 1
					}
				}
			}
			// If tab to close is not current tab, do not reset
			if (activeAlertsTab !== action.index) {
				return concat(
					of(actions.updateAlertsTabsState(alertsTabs), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME)),
					of(actions.updateAlertsActiveTab(nextTab))
				)
			} else {
				return concat(
					of(actions.resetStore(pageType)),
					of(actions.updateAlertsTabsState(alertsTabs), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME)),
					of(actions.updateAlertsActiveTab(nextTab))
				)
			}
		})
	)
}

export const cacheAlertsTabEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.CACHE_ALERTS_TAB),
		mergeMap((action) => {
			let { alertsTabs } = store.value.alerts
			const tabState = Util.getTabState(alertsTabs, action.index)
			// cache action.index tab
			const { storeKey, cacheUtil } = pageInfo[tabState.pageType]
			const pageCache = cacheUtil(store.value[storeKey], action.index)
			alertsTabs = Util.setTabState(alertsTabs, action.index, pageCache)
			return of(actions.updateAlertsTabsState(alertsTabs))
		})
	)
}

export const clearAlertsDataCacheEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.CLEAR_ALERTS_DATA_CACHE),
		mergeMap((action) => {
			let tabs = _.cloneDeep(store.value.alerts.alertsTabs)
			const currentTab = store.value.alerts.activeAlertsTab
			const currentTabState = Util.getTabState(tabs, currentTab)
			// cache current tab
			const { storeKey, cacheUtil } = pageInfo[currentTabState.pageType]
			const pageCache = cacheUtil(store.value[storeKey], currentTab)
			tabs = Util.setTabState(tabs, currentTab, pageCache)
			tabs.fixed_tabs.forEach((tab) => {
				tab.lastRefresh = null
				tab.isDataStale = null
				tab.data = null
			})
			tabs.custom_tabs.forEach((tab) => {
				tab.lastRefresh = null
				tab.isDataStale = null
				tab.data = null
			})
			let pageType
			if (store.value.alerts.activeAlertsTab === Constants.FIXED_TABS[0]) {
				pageType = pageInfo.AlertsMain.pageType
			} else if (store.value.alerts.activeAlertsTab === Constants.FIXED_TABS[1]) {
				pageType = pageInfo.AlertsNotifications.pageType
			} else {
				pageType = tabs.custom_tabs[store.value.alerts.activeAlertsTab].pageType
			}
			if (store.value.visibility.activeApp !== 'alerts') {
				return concat(of(actions.updateAlertsTabsState(tabs)), of(actions.resetStore(pageType)))
			}
			return concat(of(actions.updateAlertsTabsState(tabs)), of(actions.resetStore(pageType)), of(actions.refreshAlertsTab(pageType)))
		})
	)
}

export const updateAlertsActiveTabEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_ALERTS_ACTIVE_TAB),
		mergeMap((action) => {
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const activeAlertsTabPref = _.get(userPref, 'alerts.activeTab')
			if (activeAlertsTabPref === action.index) {
				return of()
			} else {
				_.set(userPref, 'alerts.activeTab', action.index)
				return of(mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME))
			}
		})
	)
}

/*--------- Schema Operations ----------*/
export const initAlertsSchemaEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.INIT_ALERTS_SCHEMA),
		switchMap(() => {
			let schema = _.omit(visibilityUtil.loadSchema('alerts'), ['version', 'super'])
			// Merge with Pref
			const schemaPref = _.get(store.value.main.loggedInUser.preference, 'alerts.schema')
			schema = visibilityUtil.mergeSchemaWithPref(schema, schemaPref)
			return concat(of(actions.setAlertsSchema(schema)), of(actions.alertsInitTaskComplete('schema')))
		})
	)
}

export const updateAlertsSchemaEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_ALERTS_SCHEMA),
		mergeMap((action) => {
			const { preference } = action
			let { alertsSchema: schema } = store.value.alerts
			let schemaPref = _.get(store.value.main.loggedInUser.preference, 'alerts.schema')
			let [updatedSchema, updatedSchemaPref] = visibilityUtil.updateSchemaOnChange(schema, schemaPref, preference)
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			_.set(userPref, 'alerts.schema', updatedSchemaPref)
			return of(actions.setAlertsSchema(updatedSchema), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME))
		})
	)
}

export const resetAlertsSchemaEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.RESET_ALERTS_SCHEMA),
		mergeMap((action) => {
			let { alertsSchema: updatedSchema } = store.value.alerts
			let schema = _.omit(visibilityUtil.loadSchema('alerts'), ['version', 'super'])
			// Remove Pref
			_.set(updatedSchema, `alerts.content.${action.schemaType}`, _.get(schema, `alerts.content.${action.schemaType}`))
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			_.set(userPref, `alerts.schema.alerts.${action.schemaType}.fields`, _.get(schema, `alerts.content.${action.schemaType}.fields`))
			return of(actions.setAlertsSchema({ ...updatedSchema }), mainActions.setUserPreference(userPref), mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME))
		})
	)
}
