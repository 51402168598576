export const FETCH_ALERTS_MAIN_PAGE_CONTENT = 'FETCH_ALERTS_MAIN_PAGE_CONTENT'
export const REFRESH_ALERTS_TAB = 'REFRESH_ALERTS_TAB'
export const RESET_STORE = 'RESET_STORE'

export const FETCH_DEVICES_DETAILS_SUCCESS = 'FETCH_DEVICES_DETAILS_SUCCESS'
export const FETCH_DEVICES_DETAILS_REQUEST = 'FETCH_DEVICES_DETAILS_REQUEST'
export const SET_DEVICES_DETAILS_REQUEST_ID = 'SET_DEVICES_DETAILS_REQUEST_ID'
export const FETCH_DEVICES_DETAILS_FAILURE = 'FETCH_DEVICES_DETAILS_FAILURE'
export const RESTORE_DEVICES_DETAILS = 'RESTORE_DEVICES_DETAILS'

export const FETCH_ALERTS_REQUEST = 'FETCH_ALERTS_REQUEST'
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS'
export const FETCH_ALERTS_FAILURE = 'FETCH_ALERTS_FAILURE'
export const UPDATE_ALERT_REQUEST = 'UPDATE_ALERT_REQUEST'
export const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS'
export const UPDATE_ALERT_FAILURE = 'UPDATE_ALERT_FAILURE'
export const RESTORE_ALERTS = 'RESTORE_ALERTS'

export const FETCH_CLOSED_ALERTS_REQUEST = 'FETCH_CLOSED_ALERTS_REQUEST'
export const FETCH_CLOSED_ALERTS_SUCCESS = 'FETCH_CLOSED_ALERTS_SUCCESS'
export const FETCH_CLOSED_ALERTS_FAILURE = 'FETCH_CLOSED_ALERTS_FAILURE'
export const RESTORE_CLOSED_ALERTS = 'RESTORE_CLOSED_ALERTS'

export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST'
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE'

export const FETCH_ACTIVITIES_REQUEST = 'FETCH_ACTIVITIES_REQUEST'
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS'
export const FETCH_ACTIVITIES_FAILURE = 'FETCH_ACTIVITIES_FAILURE'

export const FETCH_COMMENTS_REQUEST = 'FETCH_COMMENTS_REQUEST'
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS'
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE'
export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST'
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS'
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE'
export const UPDATE_COMMENT_REQUEST = 'UPDATE_COMMENT_REQUEST'
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS'
export const UPDATE_COMMENT_FAILURE = 'UPDATE_COMMENT_FAILURE'
export const REMOVE_COMMENT_REQUEST = 'REMOVE_COMMENT_REQUEST'
export const REMOVE_COMMENT_SUCCESS = 'REMOVE_COMMENT_SUCCESS'
export const REMOVE_COMMENT_FAILURE = 'REMOVE_COMMENT_FAILURE'

export const UPDATE_ALERTS_TIME_RANGE = 'UPDATE_ALERTS_TIME_RANGE'
export const UPDATE_ALERTS_TIME_PRESET = 'UPDATE_ALERTS_TIME_PRESET'
export const SET_ALERTS_TIME_RANGE = 'SET_ALERTS_TIME_RANGE'
export const SET_ALERTS_TIME_PRESET = 'SET_ALERTS_TIME_PRESET'

export const POLL_ALERTS_MAIN_PAGE_JOB_REQUEST = 'POLL_ALERTS_MAIN_PAGE_JOB_REQUEST'
export const POLL_ALERTS_MAIN_PAGE_JOB_FAILURE = 'POLL_ALERTS_MAIN_PAGE_JOB_FAILURE'
