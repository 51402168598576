import * as actionTypes from './actionTypes'

export const resetStore = (pageType) => ({
	type: actionTypes.RESET_STORE,
	pageType
})

/*----- Tabs State Actions -----*/
export const initInsightsTabsState = () => ({
	type: actionTypes.INIT_INSIGHTS_TABS_STATE
})

export const updateInsightsTabsState = (insightsTabs, statesToUpdate = {}) => ({
	type: actionTypes.UPDATE_INSIGHTS_TABS_STATE,
	insightsTabs,
	statesToUpdate
})

export const insightsInitTaskComplete = (task) => ({
	type: actionTypes.INSIGHTS_INIT_TASK_COMPLETE,
	task
})

/*----- Tab Actions -----*/
export const openDevicePage = (pageDesc) => ({
	type: actionTypes.OPEN_INSIGHTS_DEVICE_DETAILS,
	pageDesc
})

export const openInsightsTab = (pageDesc, ignoreExistingDetails) => ({
	type: actionTypes.OPEN_INSIGHTS_TAB,
	pageDesc,
	ignoreExistingDetails
})

export const switchInsightsTab = (from, to) => ({
	type: actionTypes.SWITCH_INSIGHTS_TAB,
	from,
	to
})

export const closeInsightsTab = (index) => ({
	type: actionTypes.CLOSE_INSIGHTS_TAB,
	index
})

export const cacheInsightsTab = (index) => ({
	type: actionTypes.CACHE_INSIGHTS_TAB,
	index
})

export const updateInsightsActiveTab = (index) => ({
	type: actionTypes.UPDATE_INSIGHTS_ACTIVE_TAB,
	index
})

export const clearInsightsDataCache = () => ({
	type: actionTypes.CLEAR_INSIGHTS_DATA_CACHE
})

export const refreshInsightsTab = (pageType) => ({
	type: actionTypes.REFRESH_INSIGHTS_TAB,
	pageType
})

/*----- Poll Job Actions -----*/
export const pollInsightsJobRequest = (jobId, jobType, jobPage, jobLimit, params) => ({
	type: actionTypes.POLL_INSIGHTS_JOB_REQUEST,
	jobId,
	jobType,
	jobPage,
	jobLimit,
	params
})

export const pollInsightsJobFailure = (jobId, error) => ({
	type: actionTypes.POLL_INSIGHTS_JOB_FAILURE,
	jobId,
	error
})

/*----- Insights Schema Actions -----*/
export const initInsightsSchema = (schema) => ({
	type: actionTypes.INIT_INSIGHTS_SCHEMA,
	schema
})

export const updateInsightsSchema = (preference) => ({
	type: actionTypes.UPDATE_INSIGHTS_SCHEMA,
	preference
})

export const setInsightsSchema = (schema) => ({
	type: actionTypes.SET_INSIGHTS_SCHEMA,
	schema
})

export const resetInsightsSchema = (schemaType) => ({
	type: actionTypes.RESET_INSIGHTS_SCHEMA,
	schemaType
})

/*----- Autocomplete Actions -----*/
export const fetchAutocompleteRequest = (field) => ({
	type: actionTypes.FETCH_AUTOCOMPLETE_REQUEST,
	field
})

export const fetchAutocompleteSuccess = (suggestions, field, rowsInJob) => ({
	type: actionTypes.FETCH_AUTOCOMPLETE_SUCCESS,
	suggestions,
	field,
	rowsInJob
})

export const setAutocompleteRequestId = (jobId) => ({
	type: actionTypes.SET_AUTOCOMPLETE_REQUEST_ID,
	jobId
})

export const fetchAutocompleteFailure = (error) => ({
	type: actionTypes.FETCH_AUTOCOMPLETE_FAILURE,
	error
})

/*----- Stale Status Actions -----*/
export const updateInsightsLastRefresh = (index) => ({
	type: actionTypes.UPDATE_INSIGHTS_LAST_REFRESH,
	index
})

export const updateInsightsStaleStatus = (index, isDataStale) => ({
	type: actionTypes.UPDATE_INSIGHTS_STALE_STATUS,
	index,
	isDataStale
})

export const setInsightsLastUpdate = (data) => ({
	type: actionTypes.SET_INSIGHTS_LAST_UPDATE,
	data
})
