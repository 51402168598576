import React, { Component } from 'react'
import { connect } from 'ui-lib'
import { LogQueryComponent as FilterBuilder } from './FilterBuilder'
import DeviceMetricsTable from './DeviceMetricsTable/DeviceMetricsTable'
import visibilityUtil from '../../../../../util/Util'
import * as actions from '../../store/actions'

import iconCritical from '../../../../../images/icon-critical.svg'
import iconWarning from '../../../../../images/icon-warning.svg'
import iconHealty from '../../../../../images/icon-healthy.svg'
import iconFilter from '../../../../../images/icon-filter.svg'

import './MetricsTable.scss'

const iconMap = { critical: iconCritical, warning: iconWarning, healthy: iconHealty }

class MetricsTable extends Component {
	state = {
		filterBuilderSchema: visibilityUtil.loadSchema('insights', false)
	}

	render() {
		const { devicePageDesc, deviceMetrics, metricsMisc, valueSuggestions, updateDeviceMetricsQuery, fetchDeviceMetricsRequest } = this.props
		const { filterBuilderSchema } = this.state

		let metricsStat

		if (!_.isEmpty(deviceMetrics)) {
			metricsStat = { critical: 0, warning: 0, healthy: 0 }
			deviceMetrics.forEach((metric) => {
				if (metric.severity === 'critical') metricsStat.critical += 1
				if (metric.severity === 'warning') metricsStat.warning += 1
				if (metric.severity === 'healthy') metricsStat.healthy += 1
			})
		}
		return (
			<div className="metrics-table">
				<div className="summary">
					<span className="item">Metrics</span>
					{!_.isEmpty(metricsStat)
						? _.keys(metricsStat).map((k) => (
								<span className="item" key={k}>
									<img className={`icon-${k}`} src={iconMap[k]} alt={`icon-${k}`} />
									<span className="count">{metricsStat[k]}</span>
									<span className="status">{_.startCase(k)}</span>
								</span>
						  ))
						: null}
				</div>
				<div className="filter-builder">
					<img src={iconFilter} alt="filter-icon" className="filter-icon" />
					<FilterBuilder
						metricsMisc={metricsMisc}
						timeFormat="MM/DD/YYYY HH:mm:ss"
						supportNot={false}
						schema={filterBuilderSchema}
						logType={'insights.metrics'}
						query={''}
						searchButton={true}
						onSearch={() => fetchDeviceMetricsRequest(devicePageDesc.device)}
						onQueryChange={updateDeviceMetricsQuery}
						placeholder="Filter by severity,metric etc."
						hasLogType={true}
						valueSuggestions={valueSuggestions}
					/>
				</div>
				<DeviceMetricsTable />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.insights, ...state.devicePage }
}

const mapDispatchToProps = { ...actions }

export default connect(mapStateToProps, mapDispatchToProps, null)(MetricsTable)
