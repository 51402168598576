import { ajax as Ajax } from 'rxjs/ajax'
import { throwError, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import moment from 'moment'
import { getAuthHeaders, getCustomServiceURL, getLoginServiceURL, MOCK_SERVICES } from 'ui-lib'
import Util from '../utils/Util'
import { getStoreState } from 'ui-lib'
import Constants from '../utils/Constants.json'

import DeviceData from '../pages/DeviceDetails/components/DeviceGraph/denormalized-device-data.json'
import Utils from '../utils/Util'

const relogin = () => {
	let loginUrl = getLoginServiceURL('api/sso/v1/login')['SERVICE_URL']
	//tenant from redux store
	let store = getStoreState()
	let tenantId = _.get(store, 'main.tenant.id', null)
	if (tenantId) {
		loginUrl += `?tenantId=${tenantId}`
	}
	window.location = loginUrl
}

const ajax = (obj) => {
	return Ajax(obj).pipe(
		map((r) => {
			// on redirection response is null & req url != resp url
			if (r.xhr.status === 403) {
				return relogin()
			}
			return r
		}),
		catchError((e) => {
			if (e.xhr.status === 403) {
				return relogin()
			} else {
				return throwError(e)
			}
		})
	)
}

export function pollInsightsJob(store, currentJobId, pageNumber) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/log/polljob')
	SERVICE_URL = `${SERVICE_URL}?jobId=${currentJobId}&pageNumber=${pageNumber}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders()
	})
}

export function getAutocomplete(store) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/autocomplete')

	const currentTime = moment()

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			limit: 10000,
			defaultPageSize: 10000,
			endTime: currentTime.valueOf(),
			startTime: currentTime.subtract(Constants.DEFAULT_TIME_RANGE, 'd').valueOf(),
			logtenant: store.visibility.tenantId,
			timeRangeField: 'timegenerated',
			query: {
				serial: true,
				hostname: true,
				model: true,
				metric: true,
				severity: true,
				sw_version: true
			}
		}
	})
}

export function getHealthStatus(store) {
	let url
	if (store.insights.activeInsightsTab === Constants.FIXED_TABS[0]) {
		url = 'api/visibility/v1/insights/devices/health'
	} else if (store.insights.activeInsightsTab === Constants.FIXED_TABS[1]) {
		url = 'api/visibility/v1/insights/metrics/health'
	}
	let { SERVICE_URL } = getCustomServiceURL(url)

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			limit: Constants.DEFAULT_PAGE_LIMIT,
			endTime: store.insightsMain.insightsEndTime,
			startTime: store.insightsMain.insightsStartTime,
			logtenant: store.visibility.tenantId,
			timeRangeField: 'timegenerated'
		}
	})
}

export function getLogs(store, pageLimit) {
	let url
	if (store.insights.activeInsightsTab === Constants.FIXED_TABS[0]) {
		url = 'api/visibility/v1/insights/devices/aggregated'
	} else if (store.insights.activeInsightsTab === Constants.FIXED_TABS[1]) {
		url = 'api/visibility/v1/insights/metrics/aggregated'
	}

	let { SERVICE_URL } = getCustomServiceURL(url)
	const [vendor, type] = store.insights.activeInsightsTab.split('.')

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			limit: pageLimit ? pageLimit : Constants.DEFAULT_PAGE_LIMIT,
			endTime: store.insightsMain.insightsEndTime,
			startTime: store.insightsMain.insightsStartTime,
			defaultPageSize: store.insightsMain.insightsPageSize,
			sortField: store.insightsMain.insightsSortField,
			sortOrder: store.insightsMain.insightsSortOrder,
			query: Util.convertQueryTimeStamp(store.insightsMain.insightsQuery, store.insights.insightsSchema[vendor].content[type].fields),
			logtenant: store.visibility.tenantId,
			timeRangeField: 'timegenerated'
		}
	})
}

export function getLogsCount(store) {
	let url
	if (store.insights.activeInsightsTab === Constants.FIXED_TABS[0]) {
		url = 'api/visibility/v1/insights/devices/aggregated/count'
	} else if (store.insights.activeInsightsTab === Constants.FIXED_TABS[1]) {
		url = 'api/visibility/v1/insights/metrics/aggregated/count'
	}

	let { SERVICE_URL } = getCustomServiceURL(url)
	const [vendor, type] = store.insights.activeInsightsTab.split('.')

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			endTime: store.insightsMain.insightsEndTime,
			startTime: store.insightsMain.insightsStartTime,
			sortField: '',
			query: Util.convertQueryTimeStamp(store.insightsMain.insightsQuery, store.insights.insightsSchema[vendor].content[type].fields),
			logtenant: store.visibility.tenantId,
			timeRangeField: 'timegenerated'
		}
	})
}

export function getDetailLogs(store, list) {
	let url
	const body = {
		limit: 1000,
		endTime: store.insightsMain.insightsEndTime,
		startTime: store.insightsMain.insightsStartTime,
		defaultPageSize: 1000,
		query: '',
		logtenant: store.visibility.tenantId,
		timeRangeField: 'timegenerated'
	}

	if (store.insights.activeInsightsTab === Constants.FIXED_TABS[0]) {
		url = 'api/visibility/v1/insights/devices/details/aggregated'
		body.devices = list
	} else if (store.insights.activeInsightsTab === Constants.FIXED_TABS[1]) {
		url = 'api/visibility/v1/insights/metrics/details/aggregated'
		body.metrics = list
	}

	let { SERVICE_URL } = getCustomServiceURL(url)

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function getMoreDetails(store, device, metric) {
	const url = 'api/visibility/v1/insights/details'
	let { SERVICE_URL } = getCustomServiceURL(url)

	const body = {
		limit: 10000,
		endTime: store.insightsMain.insightsEndTime,
		startTime: store.insightsMain.insightsStartTime,
		defaultPageSize: 10000,
		sortField: store.insightsMain.insightsSortField,
		query: '',
		logtenant: store.visibility.tenantId,
		timeRangeField: 'timegenerated'
	}

	if (store.insights.activeInsightsTab === Constants.FIXED_TABS[0]) {
		body.device = device
	} else if (store.insights.activeInsightsTab === Constants.FIXED_TABS[1]) {
		body.metrics = [metric]
	}

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function getHexGridLogs(store) {
	let url
	if (store.insights.activeInsightsTab === Constants.FIXED_TABS[0]) {
		url = 'api/visibility/v1/insights/devices/aggregated'
	} else if (store.insights.activeInsightsTab === Constants.FIXED_TABS[1]) {
		url = 'api/visibility/v1/insights/metrics/aggregated'
	}

	let { SERVICE_URL } = getCustomServiceURL(url)

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			limit: 10000,
			endTime: store.insightsMain.insightsEndTime,
			startTime: store.insightsMain.insightsStartTime,
			defaultPageSize: 10000,
			sortField: store.insightsMain.insightsSortField,
			sortOrder: store.insightsMain.insightsSortOrder,
			query: '',
			logtenant: store.visibility.tenantId,
			timeRangeField: 'timegenerated'
		}
	})
}

/*------------ Chart Related Apis -----------------*/

export function getPageDesc(store, device, metric) {
	const url = 'api/visibility/v1/insights/details'
	let { SERVICE_URL } = getCustomServiceURL(url)
	const currentTime = moment()
	const endTime = currentTime.valueOf()
	const startTime = currentTime.subtract(Constants.DEFAULT_TIME_RANGE, 'd').valueOf()
	const body = {
		limit: 1,
		endTime,
		startTime,
		defaultPageSize: 1,
		sortField: store.insightsMain.insightsSortField,
		query: '',
		logtenant: store.visibility.tenantId,
		timeRangeField: 'timegenerated',
		device,
		metrics: [metric]
	}

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function getChartInfoJob(store, jobApi, node) {
	let { SERVICE_URL } = getCustomServiceURL(`api/visibility/v1/insights${jobApi}`)

	const currentTime = moment()
	const body = {
		device: node.serial,
		endTime: currentTime.valueOf(),
		startTime: currentTime.subtract(Constants.DEFAULT_TIME_RANGE, 'd').valueOf(),
		logtenant: store.visibility.tenantId,
		timeRangeField: 'timegenerated'
	}

	if (jobApi === '/cve') {
		body.version = `${node.sw_version.match(/^\d+.\d+.\d+/g)[0]}`
		body.sortField = 'update_date'
		body.sortOrder = 'DESC'
	}

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function getChartInfo(store, api, node) {
	let { SERVICE_URL } = getCustomServiceURL(`api/visibility/v1/insights${api}`)
	const body = { logtenant: store.visibility.tenantId }
	if (api === '/eol') {
		body.product = 'PAN-OS and Panorama'
		body.version = node.sw_version.match(/^\d+.\d+/g)[0]
		body.limit = 100
	}
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function getRelatedMetrics(store, device, metricList) {
	const url = 'api/visibility/v1/insights/details'
	let { SERVICE_URL } = getCustomServiceURL(url)
	const currentTime = moment()
	const body = {
		limit: 10000,
		endTime: currentTime.valueOf(),
		startTime: currentTime.subtract(Constants.DEFAULT_TIME_RANGE, 'd').valueOf(),
		defaultPageSize: 100,
		sortField: store.insightsMain.insightsSortField,
		query: '',
		logtenant: store.visibility.tenantId,
		timeRangeField: 'timegenerated',
		metrics: metricList ? metricList : null,
		device
	}

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function getTimeline(store, device, metric) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/timeline')

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			defaultPageSize: 10000,
			limit: 10000,
			device,
			metric,
			endTime: store.linePage.timelineEndTime,
			startTime: store.linePage.timelineStartTime,
			logtenant: store.visibility.tenantId,
			query: '',
			sortField: 'timegenerated',
			sortOrder: 'ASC'
		}
	})
}

export function getMovingAverage(store, device, metric) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/mavg')

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			avg_window: '1d',
			defaultPageSize: 10000,
			limit: 10000,
			device,
			metric,
			endTime: store.linePage.timelineEndTime,
			startTime: moment(store.linePage.timelineStartTime).subtract(7, 'd').valueOf(),
			logtenant: store.visibility.tenantId,
			query: '',
			sortField: 'timegenerated',
			sortOrder: 'ASC'
		}
	})
}

export function getCommitEvents(store, device) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/commit')

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			defaultPageSize: 10000,
			limit: 10000,
			endTime: store.linePage.timelineEndTime,
			startTime: store.linePage.timelineStartTime,
			logtenant: store.visibility.tenantId,
			device,
			sortField: 'timegenerated',
			sortOrder: 'ASC'
		}
	})
}

export function getForecast(store, device, metric) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/forecast/device/${device}/metric/${metric}/prediction`)
	
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders()
	})
}

export function getCapacity(store, node, metricList) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/capacity')
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			metrics: metricList,
			model: node.capacity_model ? node.capacity_model : node.model,
			version: node.sw_version.match(/^\d+.\d+/g)[0],
			logtenant: store.visibility.tenantId,
			limit: 10000
		}
	})
}

/*--------------- Device Detais Page Related -------------------*/
export function getDeviceMetrics(store, deviceId) {
	const url = 'api/visibility/v1/insights/details'
	let { SERVICE_URL } = getCustomServiceURL(url)

	const body = {
		limit: 10000,
		endTime: moment().valueOf(),
		startTime: moment().subtract(1, 'years').valueOf(),
		defaultPageSize: 10000,
		sortField: 'severity_value',
		query: store.devicePage.deviceMetricsQuery,
		logtenant: store.visibility.tenantId,
		timeRangeField: 'timegenerated',
		device: deviceId
	}
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body
	})
}

export function getDeviceAlerts(store, deviceId, status) {
	//TO DO: Update this service when api is ready
	let { SERVICE_URL } = getCustomServiceURL('api/di/alert/alerts')
	SERVICE_URL = `${SERVICE_URL}?${Util.getTenantInfoParams(store)}&size=10000&sort=Time%3ADESC%7CSeverity%3ADESC&since=${moment(store.devicePage.deviceInfoStartTime).unix()}&until=${moment(
		store.devicePage.deviceInfoEndTime
	).unix()}&include_status=${status}&appliance_id=${deviceId}`
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
	return of({
		status: 200,
		response: [
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'critical',
				metrics: ['license'],
				create_time: 1609355862,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 1',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'warning',
				metrics: ['license'],
				create_time: 1609855862,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 2',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'critical',
				metrics: ['license'],
				create_time: 1609916734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 3',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'warning',
				metrics: ['license'],
				create_time: 1610916734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 4',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'critical',
				metrics: ['license'],
				create_time: 1611016734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 5',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'critical',
				metrics: ['license'],
				create_time: 1609916734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 6',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'warning',
				metrics: ['license'],
				create_time: 1611116734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 2',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'critical',
				metrics: ['license'],
				create_time: 1611216734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 3',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'warning',
				metrics: ['license'],
				create_time: 1610816734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 4',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'critical',
				metrics: ['license'],
				create_time: 1610076734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 5',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'critical',
				metrics: ['license'],
				create_time: 1610516734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 6',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'warning',
				metrics: ['license'],
				create_time: 1608916734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 2',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'critical',
				metrics: ['license'],
				create_time: 1608016734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 3',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'warning',
				metrics: ['license'],
				create_time: 1607916734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 4',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'critical',
				metrics: ['license'],
				create_time: 1611016734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 5',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'critical',
				metrics: ['license'],
				create_time: 1607916734,
				time: 1609916734
			},
			{
				csp_id: '273453',
				tenant_id: '977708452',
				alert_id: '95f3e6a7-4fed-11eb-8e89-325fbc59d2f9',
				alert_name: 'License expiration status 6',
				alert_type_id: 'S6-SUB',
				appliance_id: '015351000054892',
				status: 'open',
				status_hl: 'open',
				assignee: '',
				severity: 'warning',
				metrics: ['license'],
				create_time: 1611936734,
				time: 1609916734
			}
		]
	})
}

export function getDeviceCommitEvents(store, deviceId) {
	let { SERVICE_URL } = getCustomServiceURL('api/visibility/v1/insights/commit')

	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		},
		body: {
			defaultPageSize: 10000,
			limit: 10000,
			endTime: store.devicePage.deviceInfoEndTime,
			startTime: store.devicePage.deviceInfoStartTime,
			logtenant: store.visibility.tenantId,
			device: deviceId,
			sortField: 'timegenerated',
			sortOrder: 'ASC'
		}
	})
}

export function getDeviceDetails(store, deviceId) {
	let { SERVICE_URL } = getCustomServiceURL(`api/di/device/${encodeURIComponent(deviceId)}?${Utils.getTenantInfoParams(store)}`)
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}
