import { extent as d3_extent } from 'd3-array'

export default function calculateZoom3D(elements) {
  const _elementSize = 55
  const visibleElements = elements.filter(o=>{return o.opacity > 0})

  const extentX = d3_extent(visibleElements, d=>{return d.p.x})
  let w = Math.abs(extentX[0]) + Math.abs(extentX[1]) + (4*_elementSize)
  
  const extentY = d3_extent(visibleElements, d=>{return d.p.y})
  let h = Math.abs(extentY[0]) + Math.abs(extentY[1]) + (3*_elementSize)  
  
  const _HEIGHT = this.props.svgHeight - 5

  const aspectRatioWindow = this.props.svgWidth / _HEIGHT
  const aspectRatioElements = w / h  

  let v = -1  
  if(aspectRatioElements > aspectRatioWindow) {
    v = (1 / (w / this.props.svgWidth))    
  } else {    
    v = (1 / (h / _HEIGHT))  
  }
  v *= 1.7 // magic number to get everything to line up
  return v
}
