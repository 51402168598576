import React, { useState, useEffect } from 'react';
import { DefaultFontColor } from '../utils';

let nextFilterId = 0;

/**
 * Why do we inline our SVGs? So that we can change the colors of them later on.
 * If the SVGs are loaded as `image` tags within our `svg` tag, we don't have the ability to manipulate their color.
 */
export function Node({ x, y, show, type, title, titleColor, lines, svg: SvgImage, color, textOnTop, onClick }) {
    if (!show) return null;
    const [hover, setHover] = useState(false);

    const textOffset = textOnTop ? y - 86 - (lines.length - 1) * 14 : y;
    const style = {
        color,
    };
    if (onClick) {
        style.cursor = 'pointer';
    }
    const filterId = `device_graph_node_filter_${nextFilterId++}`;
    return <g
        onClick={onClick}
        style={style}
        onMouseOver={() => onClick && setHover(true)}
        onMouseOut={() => onClick && setHover(false)}
    >
        <g filter={`url(#${filterId})`}>
            <SvgImage x={x} y={y} />
        </g>
        <NodeText {...{ x, y: textOffset, title, titleColor, lines, onClick }} />
        <filter id={filterId}>
            <feGaussianBlur in="SourceAlpha" stdDeviation={hover ? 2 : 1}/>
            <feOffset dy={2} result="blur"/>
            <feFlood floodColor="currentColor" floodOpacity={hover ? .5 : .3}/>
            <feComposite in2="blur" operator="in"/>
            <feMerge>
                <feMergeNode/>
                <feMergeNode in="SourceGraphic"/>
            </feMerge>
        </filter>
    </g>;
}

function NodeText({ x, y, title, lines, titleColor }) {
    const baseY = 36;
    return <>
        {Array.isArray(title) ? (
            <text
                x={x}
                fill={titleColor || DefaultFontColor}
                fontSize={'11px'}
                fontWeight={700}
                alignmentBaseline={'middle'}
                textAnchor={'middle'}
            >
                {title.map((line) => {
                    line = <tspan key={line} x={x} y={y + baseY} children={line}/>;
                    y += 14;
                    return line;
                })}
            </text>
        ) : (
            <text
                x={x}
                y={y + baseY}
                fill={titleColor || DefaultFontColor}
                fontSize={'11px'}
                fontWeight={700}
                alignmentBaseline={'middle'}
                textAnchor={'middle'}
                children={title}
            />
        )}
        <text
            aria-multiline={true}
            x={x}
            y={y + baseY + 5}
            fill={DefaultFontColor}
            fontSize={'11px'}
            fontWeight={400}
            alignmentBaseline={'middle'}
            textAnchor={'middle'}
        >
            {lines.map(line => (
                <tspan key={line} x={x} dy={14} children={line}/>
            ))}
        </text>
    </>;
}

