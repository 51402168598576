import { connect } from 'ui-lib'
import { Select, Radio, Input } from 'antd'
import React from 'react'
import iconChecked from '../../../../../../images/icon-checked.svg'
import iconUnchecked from '../../../../../../images/icon-unchecked.svg'
import iconArrowDown from '../../../../../../images/icon-arrow-down.svg'
import iconAdd from '../../../../../../images/icon-add-group.svg'
import iconModify from '../../../../../../images/icon-modify-dimmed.svg'
import iconRemove from '../../../../../../images/icon-delete-dimmed.svg'
import './EmailConfig.scss'
import 'antd/lib/select/style/css'

import * as actions from '../../../store/actions'

const { Option } = Select

class Config extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			createMode: false,
			isTemplatePickerOpen: false,
			emailGroupName: '',
			emails: [],
			selectValue: null,
			editingTemplate: null,
			inputErrors: [null, null]
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleOutsideClick, false)
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick, false)
	}

	handleOutsideClick = (e) => {
		if (e.target.closest('.email-template-select') === null) {
			this.setState({
				createMode: false,
				isTemplatePickerOpen: false,
				emailGroupName: '',
				emails: [],
				editingTemplate: null,
				inputErrors: [null, null]
			})
		}
	}

	onSelect = (value, option) => {
		if (option.id === 'addEmailGroup') {
			this.templatePicker.blur()
			this.setState({ createMode: true, emailGroupName: '', emails: [], isTemplatePickerOpen: true, selectValue: null })
		} else if (value === 'loading') {
			this.setState({ emailGroupName: '' })
		} else if (option.id === 'editEmailGroup') {
			this.templatePicker.blur()
			return
		} else {
			this.templatePicker.blur()
			this.setState({ selectValue: option.name, createMode: false, isTemplatePickerOpen: false })
			this.props.setRuleState({ emailTemplateId: value })
		}
	}

	onOpenTemplatePicker = () => {
		this.props.fetchEmailTemplatesRequest()
		this.setState({ isTemplatePickerOpen: true, selectValue: null })
	}

	render() {
		const { rules, rulesLoading, ruleState, setRuleState, emailTemplates, emailTemplatesLoading, emailTemplatesError } = this.props
		const selEmail = emailTemplates && emailTemplates.filter((et) => et.template_id === ruleState.emailTemplateId)
		const email = selEmail && selEmail[0] ? selEmail[0].name : this.state.selectValue
		return (
			<div className="email-config">
				<div className="type">
					<img
						className="icon-check"
						onClick={() => {
							setRuleState({ emailChecked: !ruleState.emailChecked })
						}}
						src={!!ruleState.emailChecked ? iconChecked : iconUnchecked}
						alt="icon"
					/>
					<span className="title">Email</span>
				</div>
				{ruleState.emailChecked ? (
					<>
						<div className="template-picker">
							<Select
								ref={(select) => (this.templatePicker = select)}
								value={email}
								suffixIcon={<img src={iconArrowDown} alt="expand-icon" />}
								prefixCls={`${this.state.createMode ? 'create-mode' : ''} email-template-select  ant-select`}
								open={this.state.isTemplatePickerOpen}
								showArrow
								showSearch
								style={{ width: '100%' }}
								placeholder="Select a Email Group"
								onSelect={this.onSelect}
								onFocus={(e) => {
									if (!this.state.isTemplatePickerOpen) this.onOpenTemplatePicker()
								}}
							>
								{this.state.createMode ? (
									<Option value="New Email Group" id="editEmailGroup">
										<div className="create-group">
											<div className="title">{this.state.editingTemplate ? 'Edit Email Group' : 'New Email Group'}</div>
											<div className="input-item">
												<div className="title">Email Group Name</div>
												<div className="input">
													<Input
														ref={(input) => (this.emailGroupNameInput = input)}
														allowClear
														maxLength={256}
														value={this.state.emailGroupName}
														onFocus={(e) => {
															e.stopPropagation()
															this.setState({ isTemplatePickerOpen: true, createMode: true })
														}}
														onChange={(e) => {
															this.setState({ emailGroupName: e.target.value })
														}}
													/>
													{this.state.inputErrors[0] ? <div className="input-error">{`* ${this.state.inputError[0]}`}</div> : null}
												</div>
											</div>
											<div className="input-item">
												<div className="title">Email Addresses</div>
												<div className="input">
													<Select
														mode="tags"
														value={this.state.emails}
														style={{ width: '100%' }}
														prefixCls={`email-template-select ant-select`}
														placeholder=""
														onFocus={(e) => {
															e.stopPropagation()
															this.setState({ isTemplatePickerOpen: true, createMode: true })
														}}
														onChange={(emails) => {
															if (emails.length === 0) {
																this.setState({ emails })
															} else {
																const last = _.last(emails)
																const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
																if (re.test(String(last).toLowerCase())) this.setState({ emails })
															}
														}}
													/>
													{this.state.inputErrors[1] ? <div className="input-error">{`* ${this.state.inputError[1]}`}</div> : null}
												</div>
											</div>
											<div className="btns">
												<span
													className="cancel-btn"
													onClick={() => {
														// this.templatePicker.blur()
														this.setState({
															createMode: false,
															isTemplatePickerOpen: false,
															emailGroupName: '',
															emails: [],
															selectValue: null,
															editingTemplate: null,
															inputErrors: [null, null]
														})
													}}
												>
													Cancel
												</span>
												<span
													className={`next-btn ${_.isEmpty(this.state.emailGroupName) || _.isEmpty(this.state.emails) ? 'disabled' : ''}`}
													onClick={() => {
														if (!_.isEmpty(this.state.emailGroupName) && !_.isEmpty(this.state.emails)) {
															if (this.state.editingTemplate) {
																this.props.updateEmailTemplateRequest(this.state.editingTemplate.template_id, {
																	name: this.state.emailGroupName,
																	to_emails: this.state.emails.map((email) => ({ name: email, email }))
																})
																this.setState({ createMode: false, isTemplatePickerOpen: true })
															} else {
																this.props.addEmailTemplateRequest({
																	name: this.state.emailGroupName,
																	to_emails: this.state.emails.map((email) => ({ name: email, email }))
																})
																this.setState({ createMode: false, isTemplatePickerOpen: true })
															}
														}
													}}
												>
													Next
												</span>
											</div>
										</div>
									</Option>
								) : (
									<>
										<Option value="New Email Group" id="addEmailGroup">
											<img src={iconAdd} alt="" className="icon-add-group" />
											<span className="text">Create a new email group</span>
										</Option>
										{emailTemplatesError ? (
											<Option disabled value="loading">
												Error loading tempaltes
											</Option>
										) : emailTemplatesLoading ? (
											<Option disabled value="loading">
												Loading
											</Option>
										) : _.isEmpty(emailTemplates) ? (
											<Option disabled value="loading">
												No data
											</Option>
										) : (
											emailTemplates.map((template) => {
												const allowRemove = (rules && rules.findIndex((rule) => rule.email_template_id === template.template_id) < 0) || (_.isEmpty(rules) && !rulesLoading)
												return (
													<Option name={template.name} value={template.template_id}>
														{template.name}
														<img
															src={iconModify}
															onClick={(e) => {
																e.stopPropagation()
																this.setState({
																	isTemplatePickerOpen: true,
																	editingTemplate: template,
																	createMode: true,
																	emailGroupName: template.name,
																	emails: template.to_emails.map((email) => email.email)
																})
															}}
															alt=""
															className="icon-modify"
														/>
														{allowRemove ? (
															<img
																src={iconRemove}
																onClick={(e) => {
																	e.stopPropagation()
																	this.props.removeEmailTemplateRequest(template.template_id)
																	this.setState({ isTemplatePickerOpen: true })
																}}
																alt=""
																className="icon-remove"
															/>
														) : null}
													</Option>
												)
											})
										)}
									</>
								)}
							</Select>
						</div>
						<div className="email-freq">
							<Radio.Group
								onChange={(e) => {
									setRuleState({ emailFreq: e.target.value })
								}}
								value={ruleState.emailFreq}
							>
								<Radio value={1}>Send all email notifications for this rule immediately </Radio>
								<Radio value={14400}>Group and send email notifications for this rule every 4 hours</Radio>
								<Radio value={86400}>Group and send email notifications for this rule once a day</Radio>
							</Radio.Group>
						</div>
					</>
				) : null}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alertsNotifications }
}

const mapDispatchToProps = { ...actions }

export default connect(mapStateToProps, mapDispatchToProps, null)(Config)
