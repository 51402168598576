import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of, forkJoin, concat } from 'rxjs'
import { getChartInfo, getChartInfoJob, getPageDesc, pollInsightsJob } from '../../../store/services'
import Constants from '../../../utils/Constants.json'
import * as actionTypes from './actionTypes'
import * as actions from './actions'
import * as insightsActions from '../../../store/actions'

import pageInfo from '../../pageUtil'

export const fetchTablePageContentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_TABLE_PAGE_CONTENT, actionTypes.REFRESH_INSIGHTS_TAB),
		switchMap((action) => {
			if (action.type === actionTypes.REFRESH_INSIGHTS_TAB && action.pageType !== pageInfo.TableMetricDetails.pageType) {
				return of()
			}
			const { insightsTabs, activeInsightsTab } = store.value.insights
			const currentTab = insightsTabs.custom_tabs[activeInsightsTab]
			const { desc, data: cachedData } = currentTab
			// 1. Table Page has no state
			// 2. Restore Data
			const dataActionList = []
			if (cachedData) {
				const { tablePageDesc, tableChartData } = cachedData
				dataActionList.push(actions.restoreTablePageDesc({ tablePageDesc }), actions.restoreTableChart({ tableChartData }))
				return of(...dataActionList)
			} else {
				if (store.value.tablePage.tablePageDesc) {
					// Page Desc is set when open tab
					dataActionList.push(actions.fetchTableChartRequest(store.value.tablePage.tablePageDesc))
				} else {
					// No Page Desc Info
					dataActionList.push(actions.fetchTablePageDescRequest(desc.device, desc.metric, desc.hostname))
				}
				return concat(of(insightsActions.updateInsightsLastRefresh(activeInsightsTab)), of(...dataActionList))
			}
		})
	)
}

export const fetchTablePageDescEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_TABLE_PAGE_DESC_REQUEST),
		switchMap((action) => {
			const tabIndexBeforeRequest = store.value.insights.activeInsightsTab
			return getPageDesc(store.value, action.device, action.metric).pipe(
				mergeMap(({ response }) => {
					const tabIndexAfterRequest = store.value.insights.activeInsightsTab
					if (tabIndexBeforeRequest !== tabIndexAfterRequest) {
						return of(actions.pollTablePageJobFailure('Cancelled'))
					}
					return of(actions.setTablePageDescRequestId(response.jobId), actions.pollTablePageJobRequest(response.jobId, 'table_page_desc', 0, null, { hostname: action.hostname }))
				}),
				catchError((error) => {
					return of(actions.fetchTablePageDescFailure(error))
				})
			)
		})
	)
}

export const fetchTableChartEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_TABLE_CHART_REQUEST),
		mergeMap((action) => {
			const tabIndexBeforeRequest = store.value.insights.activeInsightsTab
			const chartInfoMap = Constants.TABLE_CHART_INFO_MAP
			let { api, jobApi } = chartInfoMap[action.node.metric]
			//check api type, decide following step
			if (api && jobApi) {
				const apiCall = getChartInfo(store.value, api, action.node)
				const jobApiCall = getChartInfoJob(store.value, jobApi, action.node)
				return forkJoin([apiCall, jobApiCall]).pipe(
					mergeMap((result) => {
						const tabIndexAfterRequest = store.value.insights.activeInsightsTab
						if (tabIndexBeforeRequest !== tabIndexAfterRequest) {
							return of(actions.pollTablePageJobFailure('Cancelled'))
						}
						const apiResponse = result[0].response
						const jobApiResponse = result[1].response
						return of(
							actions.setTableChartRequestId(jobApiResponse.jobId),
							actions.pollTablePageJobRequest(jobApiResponse.jobId, 'table_chart', 0, null, { apiResponse, metric: action.node.metric, currentTabJob: jobApiResponse.jobId })
						)
					}),
					catchError((error) => of(actions.fetchTableChartFailure(error)))
				)
			} else if (api) {
				return getChartInfo(store.value, api, action.node).pipe(
					mergeMap(({ response }) => {
						const tabIndexAfterRequest = store.value.insights.activeInsightsTab
						if (tabIndexBeforeRequest !== tabIndexAfterRequest) {
							return of(actions.pollTablePageJobFailure('Cancelled'))
						}
						return of(actions.fetchTableChartSuccess(null, response, action.node.metric, null))
					}),
					catchError((error) => of(actions.fetchTableChartFailure(error)))
				)
			} else {
				return getChartInfoJob(store.value, jobApi, action.node).pipe(
					mergeMap(({ response }) => {
						const tabIndexAfterRequest = store.value.insights.activeInsightsTab
						if (tabIndexBeforeRequest !== tabIndexAfterRequest) {
							return of(actions.pollTablePageJobFailure('Cancelled'))
						}
						return of(
							actions.setTableChartRequestId(response.jobId),
							actions.pollTablePageJobRequest(response.jobId, 'table_chart', 0, null, { metric: action.node.metric, currentTabJob: response.jobId })
						)
					}),
					catchError((error) => of(actions.fetchTableChartFailure(error)))
				)
			}
		})
	)
}

/*--------- Poll Job ----------*/
export const pollTablePageJobEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.POLL_TABLE_PAGE_JOB_REQUEST),
		mergeMap((action) => {
			return pollInsightsJob(store.value.insights, action.jobId, action.jobPage).pipe(
				mergeMap(({ response }) => {
					// For logs job, if job expired or pageNumber expired, quit processing
					// For health job, if job expired, quit processing
					if (
						(action.jobType === 'table_page_desc' && response.jobId !== store.value.tablePage.tablePageDescRequestId) ||
						(action.jobType === 'table_chart' && response.jobId !== store.value.tablePage.tableChartRequestId)
					) {
						return of(actions.pollTablePageJobFailure(response.jobId, action.jobType, 'Cancelled'))
					}
					// If it's still running and job is valid, keep polling
					else if (response.state === 'RUNNING') {
						return of(actions.pollTablePageJobRequest(response.jobId, action.jobType, action.jobPage, action.jobLimit, action.params))
					}
					// If it's done, handle response accroding to action type
					else {
						if (action.jobType === 'table_page_desc') {
							if (_.isArray(response.page.result.data)) {
								if (response.page.result.data.length === 0) {
									throw new Error('Invalid Serial or Metric')
								} else {
									if (_.get(response.page.result.data[0], 'hostname') !== action.params.hostname) {
										throw new Error('Invalid Hostname')
									}
								}
							}
							return concat(of(actions.fetchTablePageDescSuccess(response.page.result.data[0], response.jobId)), of(actions.fetchTableChartRequest(response.page.result.data[0])))
						} else if (action.jobType === 'table_chart') {
							return of(actions.fetchTableChartSuccess(response.page.result.data, action.params.apiResponse, action.params.metric, action.params.currentTabJob))
						}
					}
				}),
				catchError((error) => {
					if (action.jobType === 'table_page_desc') {
						return of(actions.fetchTablePageDescFailure(error))
					} else if (action.jobType === 'table_chart') {
						return of(actions.fetchTableChartFailure(error))
					} else {
						return of(actions.pollTablePageJobFailure(action.jobId, action.jobType, error))
					}
				})
			)
		})
	)
}
