import React, { Component } from 'react'
import iconCritical from '../../../images/icon-critical.svg'
import iconWarning from '../../../images/icon-warning.svg'
import _ from 'lodash'
import moment from 'moment'

export default class InsertedCellRender extends Component {
	render() {
		let { data, field, metric, valueFormatted } = this.props
		const iconMap = { critical: iconCritical, warning: iconWarning }
		let severity = null
		let cellValue = data[field]

		if (metric === 'environmentals.powersupply') {
			if (field === 'inserted' && !cellValue) {
				severity = 'warning'
			}
			if (field === 'alarm' && cellValue) {
				severity = 'critical'
			}
			valueFormatted = _.capitalize(valueFormatted)
		}

		if (metric === 'environmentals.power') {
			if (field === 'volts_in_range' && cellValue.toLowerCase() === 'no') {
				severity = 'warning'
			}
			if (field === 'alarm' && cellValue) {
				severity = 'critical'
			}
			valueFormatted = _.capitalize(valueFormatted)
		}

		if (metric === 'environmentals.fan') {
			if (field === 'rpm_in_range' && cellValue.toLowerCase() === 'no') {
				severity = 'warning'
			}
			if (field === 'inserted' && !cellValue) {
				severity = 'warning'
			}
			if (field === 'alarm' && cellValue) {
				severity = 'critical'
			}
			valueFormatted = _.capitalize(valueFormatted)
		}

		if (metric === 'environmentals.thermal') {
			if (field === 'temperature_in_range' && cellValue.toLowerCase() === 'no') {
				severity = 'warning'
			}
			if (field === 'alarm' && cellValue) {
				severity = 'critical'
			}
			valueFormatted = _.capitalize(valueFormatted)
		}

		if (metric === 'license' || metric === 'eol') {
			if (cellValue !== 'Unlimited') {
				if (cellValue >= 14 && cellValue < 90) {
					severity = 'warning'
				} else if (cellValue < 14) {
					severity = 'critical'
					if (cellValue < 0) {
						valueFormatted = `${-cellValue} days ago`
					}
				}
			}
			valueFormatted = _.capitalize(valueFormatted)
		}

		if (metric === 'security_advisory') {
			if (cellValue.toLowerCase() === 'critical') {
				severity = 'critical'
			}

			if (cellValue.toLowerCase() === 'warning') {
				severity = 'warning'
			}
			valueFormatted = _.capitalize(valueFormatted)
		}

		if (metric === 'threat_update' || metric === 'wildfire_update' || metric === 'av_update') {
			const criticalThreshold = { threat_update: 120, wildfire_update: 24, av_update: 48 }
			const warningThreshold = { threat_update: 24, wildfire_update: 12, av_update: 24 }
			const diff = moment(data['latest_release_date'], ['YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss']).diff(
				moment(data['installed_release_date'], ['YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss']),
				'hours'
			)
			if (diff > criticalThreshold[metric]) {
				severity = 'critical'
			} else if (diff > warningThreshold[metric]) {
				severity = 'warning'
			}
		}

		return (
			<span className="inner-severity-cell">
				{valueFormatted}
				{severity ? <img className="severity-icon" src={iconMap[severity]} alt="severity-icon" /> : null}
			</span>
		)
	}
}
