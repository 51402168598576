export const RESET_STORE = 'RESET_STORE'
export const REFRESH_INSIGHTS_TAB = 'REFRESH_INSIGHTS_TAB'

export const FETCH_INSIGHTS_MAIN_PAGE_CONTENT = 'FETCH_INSIGHTS_MAIN_PAGE_CONTENT'

export const POLL_INSIGHTS_MAIN_PAGE_JOB_REQUEST = 'POLL_INSIGHTS_MAIN_PAGE_JOB_REQUEST'
export const POLL_INSIGHTS_MAIN_PAGE_JOB_FAILURE = 'POLL_INSIGHTS_MAIN_PAGE_JOB_FAILURE'

export const FETCH_HEALTH_STAT_REQUEST = 'FETCH_HEALTH_STAT_REQUEST'
export const FETCH_HEALTH_STAT_SUCCESS = 'FETCH_HEALTH_STAT_SUCCESS'
export const SET_HEALTH_STAT_REQUEST_ID = 'SET_HEALTH_STAT_REQUEST_ID'
export const FETCH_HEALTH_STAT_FAILURE = 'FETCH_HEALTH_STAT_FAILURE'
export const RESTORE_HEALTH_STAT = 'RESTORE_HEALTH_STAT'

export const UPDATE_HEXGRID_SETTINGS = 'UPDATE_HEXGRID_SETTINGS'
export const FETCH_HEX_GRID_REQUEST = 'FETCH_HEX_GRID_REQUEST'
export const FETCH_HEX_GRID_SUCCESS = 'FETCH_HEX_GRID_SUCCESS'
export const SET_HEX_GRID_REQUEST_ID = 'SET_HEX_GRID_REQUEST_ID'
export const FETCH_HEX_GRID_FAILURE = 'FETCH_HEX_GRID_FAILURE'
export const RESTORE_HEX_GRID = 'RESTORE_HEX_GRID'

export const FETCH_INSIGHTS_LOGS_REQUEST = 'FETCH_INSIGHTS_LOGS_REQUEST'
export const FETCH_INSIGHTS_LOGS_SUCCESS = 'FETCH_INSIGHTS_LOGS_SUCCESS'
export const SET_INSIGHTS_LOGS_REQUEST_ID = 'SET_INSIGHTS_LOGS_REQUEST_ID'
export const FETCH_INSIGHTS_LOGS_FAILURE = 'FETCH_INSIGHTS_LOGS_FAILURE'
export const RESTORE_INSIGHTS_LOGS = 'RESTORE_INSIGHTS_LOGS'

export const FETCH_INSIGHTS_LOGS_COUNT_REQUEST = 'FETCH_INSIGHTS_LOGS_COUNT_REQUEST'
export const FETCH_INSIGHTS_LOGS_COUNT_SUCCESS = 'FETCH_INSIGHTS_LOGS_COUNT_SUCCESS'
export const SET_INSIGHTS_LOGS_COUNT_REQUEST_ID = 'SET_INSIGHTS_LOGS_COUNT_REQUEST_ID'
export const FETCH_INSIGHTS_LOGS_COUNT_FAILURE = 'FETCH_INSIGHTS_LOGS_COUNT_FAILURE'
export const RESTORE_INSIGHTS_LOGS_COUNT = 'RESTORE_INSIGHTS_LOGS_COUNT'

export const FETCH_INSIGHTS_DETAIL_LOGS_REQUEST = 'FETCH_INSIGHTS_DETAIL_LOGS_REQUEST'
export const FETCH_INSIGHTS_DETAIL_LOGS_SUCCESS = 'FETCH_INSIGHTS_DETAIL_LOGS_SUCCESS'
export const SET_INSIGHTS_DETAIL_LOGS_REQUEST_ID = 'SET_INSIGHTS_DETAIL_LOGS_REQUEST_ID'
export const FETCH_INSIGHTS_DETAIL_LOGS_FAILURE = 'FETCH_INSIGHTS_DETAIL_LOGS_FAILURE'

export const FETCH_MORE_DETAIL_LOGS_REQUEST = 'FETCH_MORE_DETAIL_LOGS_REQUEST'
export const FETCH_MORE_DETAIL_LOGS_SUCCESS = 'FETCH_MORE_DETAIL_LOGS_SUCCESS'
export const FETCH_MORE_DETAIL_LOGS_FAILURE = 'FETCH_MORE_DETAIL_LOGS_FAILURE'

export const UPDATE_INSIGHTS_PAGE_REQUEST = 'UPDATE_INSIGHTS_PAGE_REQUEST'
export const UPDATE_INSIGHTS_PAGE_NUMBER = 'UPDATE_INSIGHTS_PAGE_NUMBER'
export const UPDATE_INSIGHTS_PAGE_SIZE = 'UPDATE_INSIGHTS_PAGE_SIZE'
export const UPDATE_INSIGHTS_PAGE_LIMIT = 'UPDATE_INSIGHTS_PAGE_LIMIT'
export const RESET_INSIGHTS_PAGE = 'RESET_INSIGHTS_PAGE'
export const RESTORE_INSIGHTS_PAGE = 'RESTORE_INSIGHTS_PAGE'

export const UPDATE_QUERY_PREF = 'UPDATE_QUERY_PREF'
export const UPDATE_INSIGHTS_QUERY = 'UPDATE_INSIGHTS_QUERY'
export const UPDATE_INSIGHTS_TIME_RANGE = 'UPDATE_INSIGHTS_TIME_RANGE'

export const SET_HEXGRID_SETTINGS = 'SET_HEXGRID_SETTINGS'
export const SET_INSIGHTS_QUERY = 'SET_INSIGHTS_QUERY'
export const SET_INSIGHTS_PAGE_SIZE = 'SET_INSIGHTS_PAGE_SIZE'
