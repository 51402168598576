import * as actionTypes from './actionTypes'

const ACTION_HANDLERS = {
	[actionTypes.RESET_STORE]: (state, { pageType }) => {
		if (pageType === 'AlertsNotifications') {
			return {
				..._.cloneDeep(initialState),
				snConfig: state.snConfig,
				snConfigLoading: state.snConfig,
				snConfigError: state.snConfig
			}
		} else {
			return { ...state }
		}
	},
	/*--------------- Notifications ---------------*/
	[actionTypes.FETCH_RULES_REQUEST]: (state, {}) => {
		return {
			...state,
			rules: null,
			rulesLoading: true,
			rulesError: null
		}
	},
	[actionTypes.FETCH_RULES_SUCCESS]: (state, { rules }) => {
		return {
			...state,
			rules,
			rulesLoading: false,
			rulesError: null
		}
	},
	[actionTypes.FETCH_RULES_FAILURE]: (state, { error }) => {
		return {
			...state,
			rules: null,
			rulesLoading: false,
			rulesError: error
		}
	},
	[actionTypes.RESTORE_RULES]: (state, { data }) => {
		return {
			...state,
			rulesLoading: false,
			rulesError: null,
			...data
		}
	},
	[actionTypes.ADD_RULE_REQUEST]: (state, {}) => {
		return {
			...state,
			rules: null,
			rulesLoading: true,
			rulesError: null
		}
	},
	[actionTypes.ADD_RULE_FAILURE]: (state, { error }) => {
		return {
			...state,
			rulesLoading: false,
			rulesError: error
		}
	},
	[actionTypes.UPDATE_RULE_REQUEST]: (state, { ruleId }) => {
		const updatedRules = [...state.rules]
		const ruleIndex = state.rules.findIndex((rule) => rule.rule_id === ruleId)
		updatedRules[ruleIndex].statusUpdating = true
		return {
			...state,
			rules: updatedRules,
			rulesLoading: false,
			rulesError: null
		}
	},
	[actionTypes.UPDATE_RULE_SUCCESS]: (state, { rule }) => {
		const updatedRule = rule
		const updatedRules = [...state.rules]
		const ruleIndex = state.rules.findIndex((rule) => rule.rule_id === updatedRule.rule_id)
		if (ruleIndex >= 0) {
			updatedRules[ruleIndex] = { ...updatedRules[ruleIndex], ...updatedRule }
			updatedRules[ruleIndex].statusUpdating = false
		}
		return {
			...state,
			rules: updatedRules,
			rulesLoading: false,
			rulesError: null
		}
	},
	[actionTypes.UPDATE_RULE_FAILURE]: (state, { error }) => {
		return {
			...state,
			rulesLoading: false,
			rulesError: error
		}
	},
	[actionTypes.REMOVE_RULE_REQUEST]: (state, { ruleId }) => {
		const updatedRules = [...state.rules]
		const ruleIndex = state.rules.findIndex((rule) => rule.rule_id === ruleId)
		updatedRules[ruleIndex].deleting = true
		return {
			...state,
			rules: updatedRules,
			rulesLoading: false,
			rulesError: null
		}
	},
	[actionTypes.REMOVE_RULE_SUCCESS]: (state, { rule }) => {
		const updatedRule = rule
		const updatedRules = [...state.rules]
		const ruleIndex = state.rules.findIndex((rule) => rule.rule_id === updatedRule.rule_id)
		if (ruleIndex >= 0) {
			_.pullAt(updatedRules, ruleIndex)
		}
		return {
			...state,
			rules: updatedRules,
			rulesLoading: false,
			rulesError: null
		}
	},
	[actionTypes.REMOVE_RULE_FAILURE]: (state, { error }) => {
		return {
			...state,
			rulesLoading: false,
			rulesError: error
		}
	},
	/*--------------- Email Templates ---------------*/
	[actionTypes.FETCH_EMAIL_TEMPLATES_REQUEST]: (state, {}) => {
		return {
			...state,
			emailTemplates: null,
			emailTemplatesLoading: true,
			emailTemplatesError: null
		}
	},
	[actionTypes.FETCH_EMAIL_TEMPLATES_SUCCESS]: (state, { templates }) => {
		return {
			...state,
			emailTemplates: templates,
			emailTemplatesLoading: false,
			emailTemplatesError: null
		}
	},
	[actionTypes.FETCH_EMAIL_TEMPLATES_FAILURE]: (state, { error }) => {
		return {
			...state,
			emailTemplates: null,
			emailTemplatesLoading: false,
			emailTemplatesError: error
		}
	},
	[actionTypes.ADD_EMAIL_TEMPLATE_REQUEST]: (state, {}) => {
		return {
			...state,
			emailTemplatesLoading: true,
			emailTemplatesError: null
		}
	},
	[actionTypes.UPDATE_EMAIL_TEMPLATE_REQUEST]: (state, {}) => {
		return {
			...state,
			emailTemplatesLoading: true,
			emailTemplatesError: null
		}
	},
	[actionTypes.REMOVE_EMAIL_TEMPLATE_REQUEST]: (state, {}) => {
		return {
			...state,
			emailTemplatesLoading: true,
			emailTemplatesError: null
		}
	},
	/*--------------- ServiceNow Templates ---------------*/
	[actionTypes.FETCH_SN_TEMPLATES_REQUEST]: (state, {}) => {
		return {
			...state,
			snTemplates: null,
			snTemplatesLoading: true,
			snTemplatesError: null
		}
	},
	[actionTypes.FETCH_SN_TEMPLATES_SUCCESS]: (state, { templates }) => {
		return {
			...state,
			snTemplates: templates,
			snTemplatesLoading: false,
			snTemplatesError: null
		}
	},
	[actionTypes.FETCH_SN_TEMPLATES_FAILURE]: (state, { error }) => {
		return {
			...state,
			snTemplates: null,
			snTemplatesLoading: false,
			snTemplatesError: error
		}
	},
	[actionTypes.ADD_SN_TEMPLATE_REQUEST]: (state, {}) => {
		return {
			...state,
			snTemplatesLoading: true,
			snTemplatesError: null
		}
	},
	[actionTypes.UPDATE_SN_TEMPLATE_REQUEST]: (state, {}) => {
		return {
			...state,
			snTemplatesLoading: true,
			snTemplatesError: null
		}
	},
	[actionTypes.REMOVE_SN_TEMPLATE_REQUEST]: (state, {}) => {
		return {
			...state,
			snTemplatesLoading: true,
			snTemplatesError: null
		}
	},
	/*-------- service now groups ---------*/
	[actionTypes.FETCH_SN_GROUPS_REQUEST]: (state, {}) => {
		return {
			...state,
			snGroups: null,
			snGroupsLoading: true,
			snGroupsError: null
		}
	},
	[actionTypes.FETCH_SN_GROUPS_SUCCESS]: (state, { groups }) => {
		return {
			...state,
			snGroups: groups,
			snGroupsLoading: false,
			snGroupsError: null
		}
	},
	[actionTypes.FETCH_SN_GROUPS_FAILURE]: (state, { error }) => {
		return {
			...state,
			snGroups: null,
			snGroupsLoading: false,
			snGroupsError: error
		}
	},
	/*-------- service now group users ---------*/
	[actionTypes.FETCH_SN_GROUPUSERS_REQUEST]: (state, {}) => {
		return {
			...state,
			snGroupUsers: null,
			snGroupUsersLoading: true,
			snGroupUsersError: null
		}
	},
	[actionTypes.FETCH_SN_GROUPUSERS_SUCCESS]: (state, { groupUsers }) => {
		return {
			...state,
			snGroupUsers: groupUsers,
			snGroupUsersLoading: false,
			snGroupUsersError: null
		}
	},
	[actionTypes.FETCH_SN_GROUPUSERS_FAILURE]: (state, { error }) => {
		return {
			...state,
			snGroupUsers: null,
			snGroupUsersLoading: false,
			snGroupUsersError: error
		}
	},
	/*-------- Config ------------*/
	[actionTypes.FETCH_CONFIG_REQUEST]: (state, {}) => {
		return {
			...state,
			snConfig: null,
			snConfigLoading: true,
			snConfigError: null
		}
	},
	[actionTypes.FETCH_CONFIG_SUCCESS]: (state, { config }) => {
		return {
			...state,
			snConfig: config,
			snConfigLoading: false,
			snConfigError: null
		}
	},
	[actionTypes.FETCH_CONFIG_FAILURE]: (state, { error }) => {
		return {
			...state,
			snConfigLoading: false
			// snConfigError: error
		}
	},
	[actionTypes.ADD_CONFIG_REQUEST]: (state, {}) => {
		return {
			...state,
			snConfig: null,
			snConfigLoading: true,
			snConfigError: null
		}
	},
	[actionTypes.ADD_CONFIG_SUCCESS]: (state, { config }) => {
		return {
			...state,
			snConfig: config,
			snConfigLoading: false,
			snConfigError: null
		}
	},
	[actionTypes.ADD_CONFIG_FAILURE]: (state, { error }) => {
		return {
			...state,
			snConfigLoading: false,
			snConfigError: error
		}
	},
	[actionTypes.REMOVE_CONFIG_ERROR]: (state, {}) => {
		return {
			...state,
			snConfigError: null
		}
	}
}

const initialState = {
	rules: null,
	rulesLoading: true,
	rulesError: null,
	emailTemplates: null,
	emailTemplatesLoading: true,
	emailTempaltesError: false,
	snConfig: null,
	snConfigLoading: false,
	snConfigError: null,
	snTemplates: null,
	snTemplatesLoading: true,
	snTempaltesError: false,
	snGroups: null,
	snGroupsLoading: true,
	snGroupsError: null,
	snGroupUsers: null,
	snGroupUsersLoading: true,
	snGroupUsersError: false
}

export const alertsNotificationsReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
