import _ from 'lodash'
import moment from 'moment'
import Constants from './Constants.json'
import pageInfo from '../pages/pageUtil'

const encode = (s) => {
	try {
		return window.btoa(s)
	} catch (e) {
		return null
	}
}

const decode = (s) => {
	try {
		return window.atob(s)
	} catch (e) {
		return null
	}
}

const Util = {
	getTenantInfoParams(store) {
		return `instance_id=${store.visibility.instanceId}&csp_id=${store.visibility.cspId}&tenant_id=${store.visibility.tenantId}`
	},
	getRelativeTime(time) {
		return moment(time * 1000).fromNow()
	},
	getTransformedTime(time) {
		//default format for panw
		let date = ''
		if (!isNaN(+time)) {
			date = moment(time / 1000).format('DD MMM YYYY [at] HH:mm:ss')
		} else {
			date = moment(time).format('DD MMM YYYY [at] HH:mm:ss')
		}
		return date
	},
	getReadableNumber(num, decimal_places) {
		if (decimal_places === undefined) {
			decimal_places = 0
		}
		const neg = num < 0
		const units = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
		if (neg) {
			num = -num
		}
		if (num < 1) {
			return (neg ? '-' : '') + num
		}
		const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
		// num = Math.round(Number((num / Math.pow(1000, exponent)).toFixed(2)))
		// if (exponent >= 1) {
		// 	decimal_places = 2
		// }
		num = Number((num / Math.pow(1000, exponent)).toFixed(decimal_places))
		const unit = units[exponent]
		return `${(neg ? '-' : '') + num}${unit}`
	},
	getTimeRange(preset) {
		const currentTime = moment()
		const endTime = currentTime.valueOf()
		let startTime = endTime
		const presetInfo = Constants.TIME_RANGE_PRESETS[preset]
		if (presetInfo) startTime = currentTime.subtract(presetInfo.range[0], presetInfo.range[1]).valueOf()
		return [startTime, endTime]
	},
	getGenericMetricName(metric) {
		let result = metric
		Object.keys(Constants.GENERIC_METRIC_PATTERNS).forEach((key) => {
			let re = new RegExp(Constants.GENERIC_METRIC_PATTERNS[key])
			if (re.test(metric)) {
				// console.log(metric, pattern[1])
				result = key
			}
		})
		return result
	},
	getMetricDisplayName(metricsMisc, metric) {
		if (!metric) {
			return ''
		}
		const genericMetricName = Util.getGenericMetricName(metric)
		let displayName = ''
		let suffix = ''
		if (genericMetricName in metricsMisc) {
			if (genericMetricName in Constants.GENERIC_METRIC_PATTERNS) {
				let re = new RegExp(Constants.GENERIC_METRIC_PATTERNS[genericMetricName])
				suffix = metric.match(re)[1]
			}
			displayName = suffix && suffix !== 'unset' ? `${metricsMisc[genericMetricName].title} (${suffix})` : metricsMisc[genericMetricName].title
		} else {
			displayName = 'Metric Not Found'
		}
		return displayName
	},
	// getLastRefreshOfActiveTab(props) {
	// 	let lastRefresh = null
	// 	const { insightsTabs, activeInsightsTab } = props
	// 	if (insightsTabs && activeInsightsTab !== null) {
	// 		if (_.isString(activeInsightsTab)) {
	// 			lastRefresh = insightsTabs.fixed_tabs[Constants.FIXED_TABS.indexOf(activeInsightsTab)].lastRefresh
	// 		} else {
	// 			lastRefresh = insightsTabs.custom_tabs[activeInsightsTab].lastRefresh
	// 		}
	// 	}
	// 	return lastRefresh
	// },
	getTabState(tabs, index) {
		const fixedTabIndex = Constants.FIXED_TABS.indexOf(index)
		if (fixedTabIndex >= 0) {
			return tabs.fixed_tabs[fixedTabIndex]
		} else {
			return tabs.custom_tabs[index]
		}
	},
	setTabState(tabs, index, statesToUpdate) {
		const fixedTabIndex = Constants.FIXED_TABS.indexOf(index)
		const updatedTabs = _.cloneDeep(tabs)

		if (fixedTabIndex >= 0 && statesToUpdate) {
			updatedTabs.fixed_tabs[fixedTabIndex] = { ...updatedTabs.fixed_tabs[fixedTabIndex], ...statesToUpdate }
		} else {
			updatedTabs.custom_tabs[index] = { ...updatedTabs.custom_tabs[index], ...statesToUpdate }
		}

		return updatedTabs
	},
	// getStaleStatusOfActiveTab(props) {
	// 	let staleStatus = false
	// 	const { insightsTabs, activeInsightsTab } = props
	// 	if (insightsTabs && activeInsightsTab !== null) {
	// 		if (_.isString(activeInsightsTab)) {
	// 			staleStatus = insightsTabs.fixed_tabs[Constants.FIXED_TABS.indexOf(activeInsightsTab)].isDataStale
	// 		} else {
	// 			staleStatus = insightsTabs.custom_tabs[activeInsightsTab].isDataStale
	// 		}
	// 	}
	// 	return staleStatus
	// },
	constructTabFromUrl() {
		const url = new URL(window.location.href)
		const urlParams = url.searchParams
		const type = urlParams.get('type')
		const alertname = urlParams.get('alertname')
		const hostname = urlParams.get('hostname')
		const alertid = urlParams.get('alertid')
		let tab = null
		if (alertname && hostname && alertid) {
			const decodedAlertname = decode(alertname)
			const decodedHostname = decode(hostname)
			const decodedAlertid = decode(alertid)

			tab = {
				pageType: pageInfo.AlertDetails.pageType,
				desc: { alertname: decodedAlertname, hostname: decodedHostname, alertid: decodedAlertid },
				state: {
					showCommitEvents: false,
					showRefLines: true,
					timeRange: Constants.TIME_RANGE_PRESETS.DefaultPreset
				}
			}
		}
		if (type === 'main') {
			tab = { id: Constants.FIXED_TABS[0], state: pageInfo.AlertsMain.pageDefaultSettings, pageType: pageInfo.AlertsMain.pageType }
		}
		if (type === 'notifications') {
			tab = { id: Constants.FIXED_TABS[1], state: pageInfo.AlertsNotifications.pageDefaultSettings, pageType: pageInfo.AlertsNotifications.pageType }
		}
		return tab
	},
	encode,
	decode
}

export default Util
