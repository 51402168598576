import * as React from "react";
import Select from "./Select";
import Option from "./Option";
import "./RecycleSelect.scss";

export default class RecycleSelect extends React.Component {
	state = {
		firstVisibleMenuItem: 0,
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.firstVisibleMenuItem !== this.state.firstVisibleMenuItem && this.state.firstVisibleMenuItem === 0) {
			const menu = document.getElementsByClassName(`${this.props.prefixCls ? this.props.prefixCls : ""} pan-recycle-select-dropdown-menu-viewport`)[0];
			menu && menu.scrollTo(0, 0);
		}
		if (prevProps.optionList !== this.props.optionList) {
			this.setState({ firstVisibleMenuItem: 0 });
		}
	}

	saveSelect = (node) => {
		this.rcSelect = node;
	};

	render() {
		const { menuHeight, menuItemHeight, optionList, prefixCls } = this.props;
		return (
			<Select
				onPopupScroll={() => {
					const menuViewport = document.getElementsByClassName(`${this.props.prefixCls ? this.props.prefixCls : ""} pan-recycle-select-dropdown-menu-viewport`)[0];
					const menu = document.getElementsByClassName(`${this.props.prefixCls ? this.props.prefixCls : ""} pan-recycle-select-dropdown-menu`)[0];
					const scrollHeight = menuViewport.getBoundingClientRect().y - menu.getBoundingClientRect().y;
					this.setState({
						firstVisibleMenuItem: Math.floor(scrollHeight / menuItemHeight),
					});
				}}
				menuHeight={menuHeight}
				menuItemHeight={menuItemHeight}
				disabled={false}
				notFoundContent=""
				allowClear
				placeholder="please select"
				value={""}
				combobox
				backfill
				onDropdownVisibleChange={() => {
					this.setState({ firstVisibleMenuItem: 0 });
				}}
				style={{ width: "100%" }}
				{...this.props}
				prefixCls={`${prefixCls ? prefixCls : ""} pan-recycle-select`}
				ref={this.saveSelect}
			>
				{optionList.map((option, index) => {
					if (index < this.state.firstVisibleMenuItem || index > this.state.firstVisibleMenuItem + Math.floor(menuHeight / menuItemHeight)) return null;
					if (_.isString(option)) {
						option = { itemKey: index, itemValue: option, itemContent: option, itemDisabled: false };
					}
					return (
						<Option disabled={option.itemDisabled} key={option.itemKey} value={option.itemValue} data-cursor={option.itemCursor} style={{ top: `${index * menuItemHeight}px` }}>
							{option.itemContent}
						</Option>
					);
				})}
			</Select>
		);
	}
}
