import insightsCons from '../insights/utils/Constants.json'
import alertsCons from '../alerts/utils/Constants.json'

const mapSchemaTypes = (object, timestampColumns) => {
	let uiLogicalType = ''
	let type = object.logicalType ? object.logicalType : _.isArray(object.type) ? _.filter(object.type, (s) => s !== 'null')[0] : object.type
	if (_.has(type, 'symbols')) {
		object.symbols = type.symbols
	}
	type = _.isObject(type) ? (_.isArray(type.type) ? _.filter(type.type, (s) => s !== 'null')[0] : _.isString(type.type) ? type.type : null) : _.isString(type) ? type : null
	switch (type) {
		case 'array':
			if (object.symbols) {
				uiLogicalType = 'enum'
			} else {
				uiLogicalType = 'string'
			}
			break
		case 'record':
			uiLogicalType = 'object'
			break
		case 'string':
			// if field name is in the array of time fields, return uiLogicalType = "timestamp"
			if (timestampColumns.indexOf(object.name) > -1) {
				uiLogicalType = 'timestamp'
			} else {
				uiLogicalType = 'string'
			}
			break
		case 'boolean':
			uiLogicalType = 'boolean'
			break
		case 'int':
		case 'long':
			uiLogicalType = 'int'
			break
		default:
			uiLogicalType = type
	}

	if (_.has(object, 'symbols')) {
		object.enum = []
		_.forEach(object.symbols, (value) => {
			const obj = {}
			obj[value] = { displayName: value }
			object.enum.push(obj)
		})
	}
	return uiLogicalType
}

const getDisplayName = (name) => {
	return name
		.split('_')
		.map((a) => {
			if (a.indexOf('.') !== -1) {
				return a
					.split('.')
					.map((b) => {
						return b
					})
					.join(' ')
			}
			return a
		})
		.join(' ')
}

const normalizeFields = function (fields) {
	fields.forEach((field) => {
		if ((field.pinned !== 'left' && field.pinned !== 'right') || field.hidden === true) {
			field.pinned = null
		}
	})
	const visiblePinnedLeft = fields.filter((field) => field.pinned === 'left' && !field.hidden)
	const visiblePinnedRight = fields.filter((field) => field.pinned === 'right' && !field.hidden)
	const visibleNoPin = fields.filter((field) => field.pinned === null && !field.hidden)
	const hidden = fields.filter((field) => field.hidden).sort((m, n) => (m.displayName.toLowerCase().replace(/\s/g, '') > n.displayName.toLowerCase().replace(/\s/g, '') ? 1 : -1))
	// Normalized fields structure
	return [...visiblePinnedLeft, ...visibleNoPin, ...visiblePinnedRight, ...hidden].filter((field) => !field.internal)
}

export default {
	getLastRefreshOfActiveTab(props) {
		let lastRefresh = null
		let tabs, activeTab, Constants
		if (props.activeApp === 'insights') {
			tabs = props.insightsTabs
			activeTab = props.activeInsightsTab
			Constants = insightsCons
		}
		if (props.activeApp === 'alerts') {
			tabs = props.alertsTabs
			activeTab = props.activeAlertsTab
			Constants = alertsCons
		}
		if (tabs && activeTab !== null) {
			if (_.isString(activeTab)) {
				lastRefresh = tabs.fixed_tabs[Constants.FIXED_TABS.indexOf(activeTab)].lastRefresh
			} else {
				lastRefresh = tabs.custom_tabs[activeTab].lastRefresh
			}
		}
		return lastRefresh
	},
	getStaleStatusOfActiveTab(props) {
		let staleStatus = false
		let tabs, activeTab, Constants
		if (props.activeApp === 'insights') {
			tabs = props.insightsTabs
			activeTab = props.activeInsightsTab
			Constants = insightsCons
		}
		if (props.activeApp === 'alerts') {
			tabs = props.alertsTabs
			activeTab = props.activeAlertsTab
			Constants = alertsCons
		}
		if (tabs && activeTab !== null) {
			if (_.isString(activeTab)) {
				staleStatus = tabs.fixed_tabs[Constants.FIXED_TABS.indexOf(activeTab)].isDataStale
			} else {
				staleStatus = tabs.custom_tabs[activeTab].isDataStale
			}
		}
		return staleStatus
	},
	extractTenantUser(tenant, loggedInUser) {
		let extractedTenantUserObj = {}
		if (tenant.extensions && tenant.extensions.length > 0) {
			const lstenantObj = _.find(tenant.extensions, (extension) => extension.name === 'logging_service' || extension.name === 'Logging Service')
			let lstenantObjInfo = {
				tenant_id: '',
				region: ''
			}

			if (!lstenantObj) {
				console.log('no logging service')
			} else {
				if (lstenantObj.info) {
					lstenantObjInfo = JSON.parse(lstenantObj.info)
				}
				const dstenantObj = _.find(tenant.extensions, (extension) => (extension.name === 'Directory Sync' || extension.name === 'directory_sync') && extension.active === true)
				let dstenantObjInfo = {
					tenant_id: '',
					region: ''
				}
				if (dstenantObj && dstenantObj.info) {
					dstenantObjInfo = JSON.parse(dstenantObj.info)
				}
				extractedTenantUserObj = {
					lstenant: lstenantObjInfo.tenant_id,
					lsregion: lstenantObjInfo.region,
					dstenant: dstenantObjInfo.tenant_id,
					dsregion: dstenantObjInfo.region,
					account: lstenantObj.supportAccountId,
					email: loggedInUser.email
				}
			}
		} else {
			console.log('tenant has no extensions...check entitlements?')
		}
		return extractedTenantUserObj
	},
	normalizeFields,
	loadSchema(type, removeInternalFields = true) {
		const TYPES = {
			explore: ['firewall', 'common'],
			insights: ['insights'],
			alerts: ['alerts', 'notifications']
		}

		const superAllLogTypes = ['traffic', 'threat', 'url', 'file_data']

		const userPreference = {}
		const SCHEMA = require('./schema')
		const PRESENTATION_KEY = '_presentation'
		const KEYS = {}
		const _SCHEMA = _.merge({}, SCHEMA)
		const schemaCopy = { version: _SCHEMA.version }
		if (Object.keys(KEYS).length === 0) {
			let key
			for (key in SCHEMA) {
				if (key !== 'version') {
					for (let content in _.get(SCHEMA, `${key}.content`)) {
						if (content.indexOf(PRESENTATION_KEY) === -1) {
							KEYS[`${key}.content.${content}`] = 1 // e.g. firewall.content.auth
						}
					}
				}
			}
		}
		_.forEach(KEYS, (o, key) => {
			const toplvlkey = key.split('.')[0] // e.g. firewall, common
			if (type && TYPES[type].indexOf(toplvlkey) >= 0) {
				let _toplvlkey = toplvlkey.split('-')
				_toplvlkey = _toplvlkey[_toplvlkey.length - 1]
				_.has(schemaCopy, _toplvlkey) ? '' : _.set(schemaCopy, _toplvlkey, _.get(_SCHEMA, toplvlkey))
				const content = _.get(_SCHEMA, `${key}`)
				if (content) {
					const presentation = _.get(_SCHEMA, `${key}${PRESENTATION_KEY}`) || {}
					content.presentation = _.merge({}, content.presentation, presentation.presentation)
					// content.presentation.fields = presentation.fields;
					let _key = key.split('-') // e.g. metrics-insights, or adelphi-logs-firewall
					_key = _key[_key.length - 1] // insights, or firewall
					_.set(schemaCopy, `${_key}`, content)
					_.unset(schemaCopy, `${_key}${PRESENTATION_KEY}`)
				}
			}
		})

		let timestampColumns = []
		const ALL = {}
		_.forEach(schemaCopy, (ctx, ctxkey) => {
			// ctxkey e.g. firewall or tms
			if (_.isObject(ctx)) {
				_.forEach(ctx.content, (schema, schemakey) => {
					// schemaKey e.g. traffic
					timestampColumns = _.get(schema, 'metadata.timestampColumns') || []
					if (schema && _.isArray(schema.structure)) {
						let STRUCTURE = {}
						const DEFINATIONS = {}
						const Presentation = _.clone(schema.presentation)
						_.forEach(schema.structure, (structure) => {
							if (_.has(structure, 'namespace')) {
								STRUCTURE = _.clone(structure)
							} else {
								/* type definations */
								const structDef = _.clone(structure)
								structDef.type = mapSchemaTypes(structDef, timestampColumns)
								_.forEach(structDef.fields, (f) => {
									f.origType = f.type
									f.type = mapSchemaTypes(f, timestampColumns)
								})
								DEFINATIONS[structDef.name] = structDef
							}
						})
						const pFields = _.get(Presentation, 'fields') || []
						const pDefaults = _.get(Presentation, 'metadata.defaults') || {}
						const _FIELDS = []
						_.forEach(STRUCTURE.fields, (fieldObj) => {
							fieldObj.origType = fieldObj.type
							fieldObj.type = mapSchemaTypes(fieldObj, timestampColumns)
							if (DEFINATIONS[fieldObj.type]) {
								fieldObj.fields = _.cloneDeep(DEFINATIONS[fieldObj.type].fields)
								fieldObj.type = _.clone(DEFINATIONS[fieldObj.type].type)
							}

							if (fieldObj.fields) {
								_.forEach(fieldObj.fields, (field, k) => {
									field.name = `${fieldObj.name}.${field.name}`
									field.origType = field.type
									field.type = mapSchemaTypes(field, timestampColumns)
									const userField = _.has(userPreference, `${ctxkey}.${schemakey}.fields`) ? _.find(userPreference[ctxkey][schemakey].fields, { name: field.name }) : {}
									// sortable field is overriden by schema presentation layer.
									userField ? delete userField.sortable : null
									_.extend(field, pDefaults, _.find(pFields, { name: field.name }), userField)
									if (!field.displayName) {
										field.displayName = getDisplayName(field.name)
									}
									_FIELDS.push(field)
									if (superAllLogTypes.indexOf(schemakey) > -1) {
										ALL[field.name] = field
									}
								})
							} else {
								const userField = _.has(userPreference, `${ctxkey}.${schemakey}.fields`) ? _.find(userPreference[ctxkey][schemakey].fields, { name: fieldObj.name }) : {}
								// sortable field is overriden by schema presentation layer.
								userField ? delete userField.sortable : null
								_.extend(fieldObj, pDefaults, _.find(pFields, { name: fieldObj.name }), userField)
								if (!fieldObj.displayName) {
									fieldObj.displayName = getDisplayName(fieldObj.name)
								}
								_FIELDS.push(fieldObj)
								if (superAllLogTypes.indexOf(schemakey) > -1) {
									ALL[fieldObj.name] = fieldObj
								}
							}
						})
						STRUCTURE.fields = _FIELDS
						// order fields per presentation layer.
						const pLayerFields = []
						_.forEach(pFields, (f) => {
							const field = _.find(STRUCTURE.fields, { name: f.name })
							if (field) {
								_.remove(STRUCTURE.fields, field)
								pLayerFields.push(field)
							}
						})
						// now add rest of the structure fields, if any
						_.forEach(STRUCTURE.fields, (f) => {
							pLayerFields.push(f)
						})

						STRUCTURE.fields = []
						// now sort as per User's order of columns
						if (_.has(userPreference, `${ctxkey}.${schemakey}.fields`)) {
							_.forEach(_.get(userPreference, `${ctxkey}.${schemakey}.fields`), (f) => {
								const _fld = _.remove(pLayerFields, { name: f.name })
								if (_fld && _fld.length) {
									STRUCTURE.fields.push(_fld[0])
								}
							})
							// add back remaining fields - that were not in userPreference.
							_.forEach(pLayerFields, (f) => {
								STRUCTURE.fields.push(f)
							})
						} else {
							STRUCTURE.fields = pLayerFields
						}
						//
						schemaCopy[ctxkey].content[schemakey] = { ...Presentation.metadata, ...STRUCTURE }
						schemaCopy[ctxkey].content[schemakey].defaults = {}
					}
				})
			}
		})

		// Normalize Schema
		_.forOwn(schemaCopy, (categoryContent, category) => {
			if (category !== 'super' && removeInternalFields) {
				_.forOwn(categoryContent.content, (logTypeContent, logType) => {
					schemaCopy[category].content[logType].fields = normalizeFields(logTypeContent.fields)
				})
			}
		})

		return schemaCopy
	},
	mergeSchemaWithPref(schema, schemaPref) {
		if (_.isObject(schemaPref)) {
			Object.keys(schemaPref).forEach((category) => {
				Object.keys(schemaPref[category]).forEach((type) => {
					const originalFields = _.get(schema, `${category}.content.${type}.fields`)
					const prefFields = _.get(schemaPref, `${category}.${type}.fields`)
					if (originalFields && prefFields) {
						const originalExclusive = _.differenceWith(originalFields, prefFields, (a, b) => a.name === b.name)
						const intersection = _.intersectionWith(prefFields, originalFields, (a, b) => a.name === b.name)
						const updatedFields = normalizeFields([...intersection, ...originalExclusive])
						_.set(schema, `${category}.content.${type}.fields`, updatedFields)
					}
				})
			})
		}
		return schema
	},
	updateSchemaOnChange(schema, schemaPref, preference) {
		let key = '',
			contentKey = [],
			toBeMoved,
			fields = preference
		while (fields) {
			key = Object.keys(fields)[0]
			fields = fields[key]
			if (key === 'fields') {
				break
			} else if (key === 'moveAfter' || key === 'moveBefore' || key === 'field') {
				// have to move field;
				toBeMoved = [...contentKey]
				break
			}
			contentKey.push(key)
		}
		contentKey.splice(1, 0, 'content')
		contentKey.push('fields')
		if (toBeMoved) {
			let op = _.get(preference, toBeMoved)
			let tbr = _.remove(_.get(schema, contentKey), { name: op.field.name })
			tbr = tbr[0]
			if (tbr) {
				if (op['moveAfter']) {
					let idx = _.findIndex(_.get(schema, contentKey), { name: op.moveAfter.name })
					_.get(schema, contentKey).splice(idx + 1, 0, tbr)
				} else {
					let idx = _.findIndex(_.get(schema, contentKey), { name: op.moveBefore.name })
					_.get(schema, contentKey).splice(idx, 0, tbr)
				}
			}
		} else {
			fields.forEach((field) => {
				if (field && field.name) {
					// get obj for the field name in schema and update it using _.merge
					let obj = _.find(_.get(schema, contentKey), { name: field.name })
					if (obj) {
						_.merge(obj, field)
					}
				}
			})
		}
		schemaPref = schemaPref ? _.cloneDeep(schemaPref) : {}
		Object.keys(preference).forEach((category) => {
			Object.keys(preference[category]).forEach((type) => {
				const fields = _.get(schema, `${category}.content.${type}.fields`)
				_.set(schema, `${category}.content.${type}.fields`, fields)
				_.set(schemaPref, `${category}.${type}.fields`, fields)
			})
		})
		return [schema, schemaPref]
	}
}
