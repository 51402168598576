export const RESET_STORE = 'RESET_STORE'
export const OPEN_INSIGHTS_DEVICE_DETAILS = 'OPEN_INSIGHTS_DEVICE_DETAILS'

export const FETCH_DEVICE_PAGE_CONTENT = 'FETCH_DEVICE_PAGE_CONTENT'

export const POLL_DEVICE_PAGE_JOB_REQUEST = 'POLL_DEVICE_PAGE_JOB_REQUEST'
export const POLL_DEVICE_PAGE_JOB_FAILURE = 'POLL_DEVICE_PAGE_JOB_FAILURE'

export const FETCH_DEVICE_METRICS_REQUEST = 'FETCH_DEVICE_METRICS_REQUEST'
export const SET_DEVICE_METRICS_REQUEST_ID = 'SET_DEVICE_METRICS_REQUEST_ID'
export const FETCH_DEVICE_METRICS_SUCCESS = 'FETCH_DEVICE_METRICS_SUCCESS'
export const FETCH_DEVICE_METRICS_FAILURE = 'FETCH_DEVICE_METRICS_FAILURE'
export const UPDATE_DEVICE_METRICS_QUERY = 'UPDATE_DEVICE_METRICS_QUERY'
export const SET_DEVICE_PAGE_DESC = 'SET_DEVICE_PAGE_DESC'

export const FETCH_DEVICE_ALERTS_REQUEST = 'FETCH_DEVICE_ALERTS_REQUEST'
export const FETCH_DEVICE_ALERTS_SUCCESS = 'FETCH_DEVICE_ALERTS_SUCCESS'
export const FETCH_DEVICE_ALERTS_FAILURE = 'FETCH_DEVICE_ALERTS_FAILURE'

export const FETCH_CLOSED_DEVICE_ALERTS_REQUEST = 'FETCH_CLOSED_DEVICE_ALERTS_REQUEST'
export const FETCH_CLOSED_DEVICE_ALERTS_SUCCESS = 'FETCH_CLOSED_DEVICE_ALERTS_SUCCESS'
export const FETCH_CLOSED_DEVICE_ALERTS_FAILURE = 'FETCH_CLOSED_DEVICE_ALERTS_FAILURE'

export const UPDATE_DEVICE_ALERT_REQUEST = 'UPDATE_DEVICE_ALERT_REQUEST'
export const UPDATE_DEVICE_ALERT_SUCCESS = 'UPDATE_DEVICE_ALERT_SUCCESS'
export const UPDATE_DEVICE_ALERT_FAILURE = 'UPDATE_DEVICE_ALERT_FAILURE'

export const FETCH_DEVICE_COMMIT_EVENTS_REQUEST = 'FETCH_DEVICE_COMMIT_EVENTS_REQUEST'
export const SET_DEVICE_COMMIT_EVENTS_REQUEST_ID = 'SET_DEVICE_COMMIT_EVENTS_REQUEST_ID'
export const FETCH_DEVICE_COMMIT_EVENTS_SUCCESS = 'FETCH_DEVICE_COMMIT_EVENTS_SUCCESS'
export const FETCH_DEVICE_COMMIT_EVENTS_FAILURE = 'FETCH_DEVICE_COMMIT_EVENTS_FAILURE'

export const UPDATE_DEVICE_INFO_TIME_PRESET = 'UPDATE_DEVICE_INFO_TIME_PRESET'
export const UPDATE_DEVICE_INFO_TIME_RANGE = 'UPDATE_DEVICE_INFO_TIME_RANGE'

export const FETCH_DEVICE_DETAILS_REQUEST = 'FETCH_DEVICE_DETAILS_REQUEST'
export const FETCH_DEVICE_DETAILS_SUCCESS = 'FETCH_DEVICE_DETAILS_SUCCESS'
export const FETCH_DEVICE_DETAILS_FAILURE = 'FETCH_DEVICE_DETAILS_FAILURE'

export const FETCH_DEVICE_ALERT_EVENTS_REQUEST = 'FETCH_DEVICE_ALERT_EVENTS_REQUEST'
export const FETCH_DEVICE_ALERT_EVENTS_SUCCESS = 'FETCH_DEVICE_ALERT_EVENTS_SUCCESS'
export const FETCH_DEVICE_ALERT_EVENTS_FAILURE = 'FETCH_DEVICE_ALERT_EVENTS_FAILURE'

export const FETCH_DEVICE_ALERT_ACTIVITIES_REQUEST = 'FETCH_DEVICE_ALERT_ACTIVITIES_REQUEST'
export const FETCH_DEVICE_ALERT_ACTIVITIES_SUCCESS = 'FETCH_DEVICE_ALERT_ACTIVITIES_SUCCESS'
export const FETCH_DEVICE_ALERT_ACTIVITIES_FAILURE = 'FETCH_DEVICE_ALERT_ACTIVITIES_FAILURE'

export const FETCH_DEVICE_ALERT_COMMENTS_REQUEST = 'FETCH_DEVICE_ALERT_COMMENTS_REQUEST'
export const FETCH_DEVICE_ALERT_COMMENTS_SUCCESS = 'FETCH_DEVICE_ALERT_COMMENTS_SUCCESS'
export const FETCH_DEVICE_ALERT_COMMENTS_FAILURE = 'FETCH_DEVICE_ALERT_COMMENTS_FAILURE'
export const ADD_DEVICE_ALERT_COMMENT_REQUEST = 'ADD_DEVICE_ALERT_COMMENT_REQUEST'
export const ADD_DEVICE_ALERT_COMMENT_SUCCESS = 'ADD_DEVICE_ALERT_COMMENT_SUCCESS'
export const ADD_DEVICE_ALERT_COMMENT_FAILURE = 'ADD_DEVICE_ALERT_COMMENT_FAILURE'
export const UPDATE_DEVICE_ALERT_COMMENT_REQUEST = 'UPDATE_DEVICE_ALERT_COMMENT_REQUEST'
export const UPDATE_DEVICE_ALERT_COMMENT_SUCCESS = 'UPDATE_DEVICE_ALERT_COMMENT_SUCCESS'
export const UPDATE_DEVICE_ALERT_COMMENT_FAILURE = 'UPDATE_DEVICE_ALERT_COMMENT_FAILURE'
export const REMOVE_DEVICE_ALERT_COMMENT_REQUEST = 'REMOVE_DEVICE_ALERT_COMMENT_REQUEST'
export const REMOVE_DEVICE_ALERT_COMMENT_SUCCESS = 'REMOVE_DEVICE_ALERT_COMMENT_SUCCESS'
export const REMOVE_DEVICE_ALERT_COMMENT_FAILURE = 'REMOVE_DEVICE_ALERT_COMMENT_FAILURE'
