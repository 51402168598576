import React from 'react';
import { LogCollectorGroupList } from './LogCollectorGroupList';

export function LoggingDrawerContent({ device, onClickDevice }) {
    const title = `Log Collectors`;
    return <>
        <LogCollectorGroupList
            title={title}
            groups={device.log_collector_groups}
            onClickDevice={onClickDevice}
        />
    </>;
}
