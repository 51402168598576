import React from 'react'
import { connect } from 'ui-lib'
import _ from 'lodash'
import { Dropdown } from 'antd'
import Overlay from './DropdownOverlay'
import { PopoverWidget as Popover } from 'ui-lib'
import Util from '../../../utils/Util'

import * as alertsActions from '../../../store/actions'

import 'antd/lib/dropdown/style/css'
import './CommentCellRenderer.scss'

import iconComment from '../../../../images/icon-comment.svg'

class CommentCellRenderer extends React.Component {
	state = {
		isDropdownVisible: false,
		editingMode: false
	}
	static getDerivedStateFromProps(nextProps) {
		if (_.get(nextProps, 'editingCommentInfo.alert_id') === _.get(nextProps, 'data.alert_id')) {
			return { isDropdownVisible: true, editingMode: true }
		}
	}
	hideDropdown = () => {
		this.props.cancelEditing(this.props.node)
		this.setState({ isDropdownVisible: false })
	}
	onVisibleChange = (visible) => {
		if (!visible) this.hideDropdown()
	}
	render() {
		const { data, editingCommentInfo, popupContainer } = this.props
		const getContainer = popupContainer === 'body' ? () => document.body : (triggerNode) => triggerNode.closest(`[class$=cols-container]`)
		let { value } = this.props
		const lastUpdate = this.props[`${this.props.activeApp}LastUpdate`]
		value = lastUpdate ? Util.getRelativeTime(value) : Util.getTransformedTime(value * 1000 * 1000)
		const reverseValue = lastUpdate ? Util.getTransformedTime(this.props.value * 1000 * 1000) : Util.getRelativeTime(this.props.value)
		const content = (
			<>
				<div>{reverseValue}</div>
			</>
		)
		if (data.row_desc === 'show_closed') return <div />
		return (
			<div className="comment-cell">
				<span className="time">
					<Popover prefixCls="cell-popover" content={content} trigger="hover" placement="bottom">
						<span>{value}</span>
					</Popover>
				</span>
				<Dropdown
					onVisibleChange={this.onVisibleChange}
					prefixCls="comment-dropdown ant-dropdown"
					getPopupContainer={getContainer}
					placement={'bottomRight'}
					overlay={
						<Overlay
							commentId={_.get(editingCommentInfo, 'comment_id')}
							alertId={data.alert_id}
							editingCommentInfo={editingCommentInfo}
							hideDropdown={this.hideDropdown}
							editingMode={this.state.editingMode}
							addComment={this.props.addComment}
							updateComment={this.props.updateComment}
						/>
					}
					align={{ offset: [8, 0] }}
					visible={this.state.isDropdownVisible}
					trigger="click"
					arrow
				>
					<img
						onClick={() => {
							this.setState({ isDropdownVisible: !this.state.isDropdownVisible, editingMode: false })
						}}
						src={iconComment}
						alt="comment-icon"
						className="comment-btn"
					/>
				</Dropdown>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.alerts }
}

const mapDispatchToProps = { ...alertsActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(CommentCellRenderer)
