import React, { Component } from 'react'
import iconDownload from 'ui-lib/src/images/icon-download.svg'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'
import iconInfo from 'ui-lib/src/images/icon-info.svg'
import iconList from 'ui-lib/src/images/icon-list.svg'
import { PopoverWidget as Popover } from 'ui-lib'
import _ from 'lodash'
import './LogExportHistory.scss'

const getIcon = (job, exportFileLoading) => {
	if (job.status === 'DONE') {
		if (exportFileLoading[job.jobId] && exportFileLoading[job.jobId].loading === true) {
			return iconLoading
		} else {
			return iconDownload
		}
	} else if (job.status === 'RUNNING') {
		return iconLoading
	} else {
		return iconInfo
	}
}

const payload = (job) => (
	<table>
		<tbody>
			{Object.keys(job.envVariables).map((key) => (
				<tr key={key}>
					<td>
						<div className="key">{key.toUpperCase()}</div>
					</td>
					<td>
						<div className="value">{job.envVariables[key]}</div>
					</td>
				</tr>
			))}
		</tbody>
	</table>
)
export default class LogExportHistory extends Component {
	render() {
		const { exportHistory, exportHistoryLoading, exportHistoryError, fetchExportFileRequest, exportFileLoading } = this.props
		return (
			<div className="export-history">
				{exportHistoryError ? (
					<div>Error</div>
				) : exportHistoryLoading ? (
					<div>Loading</div>
				) : (
					<table>
						<thead>
							<tr>
								<th>Export Job ID</th>
								<th>File Name</th>
								<th>Status</th>
								<th>Download</th>
							</tr>
						</thead>
						<tbody>
							{exportHistory.map((job, idx) => {
								return (
									<tr key={idx}>
										<td>
											<div className="id">
												<Popover prefixCls="export-popover" content={payload(job)} trigger="click" placement="rightTop">
													<img onClick={_.noop} className="list-btn" src={iconList} alt="List" />
												</Popover>
												<span>{job.jobId}</span>
											</div>
										</td>
										<td>
											<div className="filename">{job.filename}</div>
										</td>
										<td>
											<div className="status">{job.status.toLowerCase()}</div>
										</td>
										<td>
											<div className="download">
												<img
													onClick={job.status === 'DONE' && _.get(exportFileLoading, `${job.jobId}.loading`) !== true ? () => fetchExportFileRequest(job) : _.noop}
													className={
														job.status === 'RUNNING' || (job.status === 'DONE' && _.get(exportFileLoading, `${job.jobId}.loading`) === true) ? 'loading-btn' : 'info-btn'
													}
													src={getIcon(job, exportFileLoading)}
													alt="Status"
												/>
											</div>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				)}
			</div>
		)
	}
}
