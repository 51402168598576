import { ofType } from "redux-observable";
import { mergeMap, catchError } from "rxjs/operators";
import { of, forkJoin, concat } from "rxjs";
import { ajax } from "rxjs/ajax";
import _ from "lodash";
import {
	fetchRemoteNetworksStatusObservable,
	fetchMobileUsersStatusObservable,
	fetchMobileUsersCurrentUsersObservable,
	fetchMobileUsersCurrentUsersDetailObservable,
	fetchMobileUsersTotalUsersObservable,
	fetchMobileUsersTotalUsersDetailObservable,
	fetchServiceConnectionStatusObservable,
	getSecondLevelDetailInfoObservable,
	getSecondLevelTableDetailInfoObservable,
} from "./services";
import { parseError, SERVER_ERROR, startLoading, finishLoading } from "ui-lib";

export const FETCH_REMOTE_NETWORKS_STATUS = "FETCH_REMOTE_NETWORKS_STATUS";
export const FETCH_REMOTE_NETWORKS_STATUS_SUCCESS = "FETCH_REMOTE_NETWORKS_STATUS_SUCCESS";

export const FETCH_MOBILE_USERS_STATUS = "FETCH_MOBILE_USERS_STATUS";
export const FETCH_MOBILE_USERS_STATUS_SUCCESS = "FETCH_MOBILE_USERS_STATUS_SUCCESS";

export const FETCH_SERVICE_CONNECTION_STATUS = "FETCH_SERVICE_CONNECTION_STATUS";
export const FETCH_SERVICE_CONNECTION_STATUS_SUCCESS = "FETCH_SERVICE_CONNECTION_STATUS_SUCCESS";

export const FETCH_MAP_DETAIL_INFO = "FETCH_MAP_DETAIL_INFO";
export const FETCH_MAP_DETAIL_INFO_SUCCESS = "FETCH_MAP_DETAIL_INFO_SUCCESS";

export const FETCH_MAP_DETAIL_INFO_COMBINE = "FETCH_MAP_DETAIL_INFO_COMBINE";
export const FETCH_MAP_DETAIL_INFO_COMBINE_SUCCESS = "FETCH_MAP_DETAIL_INFO_COMBINE_SUCCESS";

export const CLEAR_MAP_DETAIL_INFO = "CLEAR_MAP_DETAIL_INFO";
export const CLEAR_MAP_DETAIL_INFO_SUCCESS = "CLEAR_MAP_DETAIL_INFO_SUCCESS";

export const START_DATA_LOADING = "START_DATA_LOADING";
export const FINISH_DATA_LOADING = "FINISH_DATA_LOADING";

export const FETCH_SECOND_LEVEL_MAP_DETAIL_INFO = "FETCH_SECOND_LEVEL_MAP_DETAIL_INFO";
export const FETCH_SECOND_LEVEL_MAP_DETAIL_INFO_SUCCESS = "FETCH_SECOND_LEVEL_MAP_DETAIL_INFO_SUCCESS";

export const FETCH_SECOND_LEVEL_TABLE_DETAIL_INFO = "FETCH_SECOND_LEVEL_TABLE_DETAIL_INFO";
export const FETCH_SECOND_LEVEL_TABLE_DETAIL_INFO_SUCCESS = "FETCH_SECOND_LEVEL_TABLE_DETAIL_INFO_SUCCESS";

export const FETCH_CURRENT_USERS_DETAILS_INFO = "FETCH_CURRENT_USERS_DETAILS_INFO";
export const FETCH_CURRENT_USERS_DETAILS_INFO_SUCCESS = "FETCH_CURRENT_USERS_DETAILS_INFO_SUCCESS";

export const CLEAR_TABLE_DETAIL_INFO = "CLEAR_TABLE_DETAIL_INFO";

export const fetchRemoteNetworksStatus = () => ({
	type: FETCH_REMOTE_NETWORKS_STATUS,
});

export const fetchMobileUsersStatus = () => ({
	type: FETCH_MOBILE_USERS_STATUS,
});

export const fetchServiceConnectionStatus = () => ({
	type: FETCH_SERVICE_CONNECTION_STATUS,
});

export const fetchMapDetailInfo = (header) => ({
	type: FETCH_MAP_DETAIL_INFO,
	payload: header,
});

export const fetchMapDetailInfoCombine = () => {
	return {
		type: FETCH_MAP_DETAIL_INFO_COMBINE,
	};
};

export const fetchSecondLevelMapDetailInfo = (header, locations) => ({
	type: FETCH_SECOND_LEVEL_MAP_DETAIL_INFO,
	header,
	locations,
});

export const clearSecondLevelTableDetailInfo = () => ({
	type: CLEAR_TABLE_DETAIL_INFO,
});

export const fetchSecondLevelTableDetailInfo = (header, locations) => ({
	type: FETCH_SECOND_LEVEL_TABLE_DETAIL_INFO,
	header,
	locations,
});

export const fetchCurrentUsersDetailsInfo = (queryString) => ({
	type: FETCH_CURRENT_USERS_DETAILS_INFO,
	queryString,
});

export const clearMapDetailInfo = (header) => ({
	type: CLEAR_MAP_DETAIL_INFO,
	payload: header,
});

export const fetchRemoteNetworksStatusSuccess = (resp) => {
	let status = {};

	if (resp["status"] && resp["status"].response && resp["status"].response.msg) {
		status = resp["status"].response.msg;
	}

	if (status && status["@status"] === "success") {
		let obj = {
			status: status.result.entry[0]["Status"].value,
			status_tooltip: status.result.entry[0]["Status"].tooltip,
			set_up_now: status.result.entry[0]["Status"]["set-up-now"],
			config_status: status.result.entry[0]["Config Status"].value,
			config_status_tooltip: status.result.entry[0]["Config Status"].tooltip,
			total_peer: status.result.entry[0]["Total Peers"],
			limit: status.result.entry[0]["Allocated Mbps"].limit,
			value: status.result.entry[0]["Allocated Mbps"].value,
		};
		return {
			type: FETCH_REMOTE_NETWORKS_STATUS_SUCCESS,
			remoteNetwork: obj,
		};
	} else {
		return {
			type: FETCH_REMOTE_NETWORKS_STATUS_SUCCESS,
		};
	}
};

export const fetchMobileUsersStatusSuccess = (resp) => {
	let currentUserInfo,
		usersInfo,
		currentUsersDetailInfo,
		usersDetailInfo,
		status = {};

	if (resp && resp["current_user"] && resp["current_user"].response && resp["current_user"].response.msg) {
		currentUserInfo = resp["current_user"].response.msg.result["@total-count"];
	}
	if (resp && resp["users"] && resp["users"].response && resp["users"].response.msg) {
		usersInfo = resp["users"].response.msg.result;
	}
	if (resp && resp["current_users_details"] && resp["current_users_details"].response && resp["current_users_details"].response.msg) {
		currentUsersDetailInfo = resp["current_users_details"].response.msg.result;
	}
	if (resp && resp["users_details"] && resp["users_details"].response && resp["users_details"].response.msg) {
		usersDetailInfo = resp["users_details"].response.msg.result;
	}
	if (resp && resp["status"] && resp["status"].response && resp["status"].response.msg) {
		status = resp["status"].response.msg;
	}

	if (status && status["@status"] === "success") {
		let obj = {
			current_user: currentUserInfo,
			users: usersInfo,
			status: status.result.entry[0]["Status"].value,
			status_tooltip: status.result.entry[0]["Status"].tooltip,
			set_up_now: status.result.entry[0]["Status"]["set-up-now"],
			config_status: status.result.entry[0]["Config Status"].value,
			config_status_tooltip: status.result.entry[0]["Config Status"].tooltip,
			current_users_info: currentUsersDetailInfo,
			users_info: usersDetailInfo,
		};
		return {
			type: FETCH_MOBILE_USERS_STATUS_SUCCESS,
			mobileUser: obj,
		};
	} else {
		return {
			type: FETCH_MOBILE_USERS_STATUS_SUCCESS,
		};
	}
};

export const fetchServiceConnectionStatusSuccess = (resp) => {
	const xhr = resp && resp.response ? resp.response : null;
	let status = {};

	if (xhr && resp.status === 200) {
		status = xhr.msg;
	}

	if (status && status["@status"] === "success") {
		let obj = {
			status: status.result.entry[0]["Status"].value,
			status_tooltip: status.result.entry[0]["Status"].tooltip,
			set_up_now: status.result.entry[0]["Status"]["set-up-now"],
			config_status: status.result.entry[0]["Config Status"].value,
			config_status_tooltip: status.result.entry[0]["Config Status"].tooltip,
			connection_number: status.result.entry[0]["Service Connections"],
		};
		return {
			type: FETCH_SERVICE_CONNECTION_STATUS_SUCCESS,
			serviceConnection: obj,
		};
	} else {
		return {
			type: FETCH_SERVICE_CONNECTION_STATUS_SUCCESS,
		};
	}
};

export const fetchCurrentUsersDetailsInfoEpicSuccess = (resp) => {
	let currentUsers = {};
	if (resp && resp.response && resp.response.msg) {
		currentUsers = resp.response.msg.result;
	}
	return {
		type: FETCH_CURRENT_USERS_DETAILS_INFO_SUCCESS,
		currentUsers,
	};
};

export const getDetailInfoSuccess = (resp, header) => {
	let regionInfo = {};
	let detailInfo = {};
	let contientRegionMap = {};
	if (resp && resp["regionInfo"] && resp["regionInfo"].response && resp["regionInfo"].response.msg) {
		regionInfo = resp["regionInfo"].response.msg.completions;
	}
	if (resp && resp["detailInfo"] && resp["detailInfo"].response && resp["detailInfo"].response.msg) {
		detailInfo = resp["detailInfo"].response.msg.result.entry;
	}
	if (header === "Remote Networks" || header === "Service Connections") {
		for (let i = 0; i < detailInfo.length; i++) {
			let region = regionInfo.filter((region) => region["@display"] === detailInfo[i]["name"])[0];
			if (!contientRegionMap[region["@continent"]]) {
				contientRegionMap[region["@continent"]] = {};
				contientRegionMap[region["@continent"]]["total"] = 0;
				contientRegionMap[region["@continent"]]["OK"] = 0;
				contientRegionMap[region["@continent"]]["Warning"] = 0;
				contientRegionMap[region["@continent"]]["Error"] = 0;
				contientRegionMap[region["@continent"]]["regions"] = [];
			}
			contientRegionMap[region["@continent"]].total += detailInfo[i]["map-tooltip-object"].total;
			contientRegionMap[region["@continent"]].OK += detailInfo[i]["map-tooltip-object"].OK;
			contientRegionMap[region["@continent"]].Warning += detailInfo[i]["map-tooltip-object"].Warning;
			contientRegionMap[region["@continent"]].Error += detailInfo[i]["map-tooltip-object"].Error;
			contientRegionMap[region["@continent"]].regions.push(detailInfo[i]);
		}

		return {
			type: FETCH_MAP_DETAIL_INFO_SUCCESS,
			mapData: contientRegionMap,
			mapType: header,
		};
	} else if (header === "Mobile Users") {
		for (let i = 0; i < detailInfo.length; i++) {
			let region = regionInfo.filter((region) => region["@display"] === detailInfo[i]["name"])[0];
			if (!contientRegionMap[region["@continent"]]) {
				contientRegionMap[region["@continent"]] = {};
				contientRegionMap[region["@continent"]]["users"] = 0;
				contientRegionMap[region["@continent"]]["total"] = 0;
				contientRegionMap[region["@continent"]]["OK"] = 0;
				contientRegionMap[region["@continent"]]["Warning"] = 0;
				contientRegionMap[region["@continent"]]["Error"] = 0;
				contientRegionMap[region["@continent"]]["regions"] = [];
			}
			contientRegionMap[region["@continent"]].total++;
			if (detailInfo[i]["Status"].value === "OK") {
				contientRegionMap[region["@continent"]]["OK"]++;
			} else if (detailInfo[i]["Status"].value === "Warning") {
				contientRegionMap[region["@continent"]]["Warning"]++;
			} else if (detailInfo[i]["Status"].value === "Error") {
				contientRegionMap[region["@continent"]]["Error"]++;
			}
			contientRegionMap[region["@continent"]]["users"] += detailInfo[i]["Current Users"].value;
			contientRegionMap[region["@continent"]].regions.push(detailInfo[i]);
		}

		return {
			type: FETCH_MAP_DETAIL_INFO_SUCCESS,
			mapData: contientRegionMap,
			mapType: header,
		};
	}
};

export const getDetailInfoCombineSuccess = (resp) => {
	let globalMapRegionInfo = [];
	let regionInfo = {};
	let rnMapInfo = {};
	let muMapInfo = {};
	let scMapInfo = {};
	let rnContientRegionMap = {};
	let muContientRegionMap = {};
	let scContientRegionMap = {};
	let dirSyncInfo = {};
	if (resp && resp["regionInfo"] && resp["regionInfo"].response && resp["regionInfo"].response.msg) {
		regionInfo = resp["regionInfo"].response.msg.completions;
	}
	if (resp && resp["globalMapRegionInfo"] && resp["globalMapRegionInfo"].response && resp["globalMapRegionInfo"].response.msg) {
		globalMapRegionInfo = resp["globalMapRegionInfo"].response.msg.result.entry;
	}
	if (resp && resp["rnMapInfo"] && resp["rnMapInfo"].response && resp["rnMapInfo"].response.msg) {
		rnMapInfo = resp["rnMapInfo"].response.msg.result.entry;
	}
	if (resp && resp["muMapInfo"] && resp["muMapInfo"].response && resp["muMapInfo"].response.msg) {
		muMapInfo = resp["muMapInfo"].response.msg.result.entry;
	}
	if (resp && resp["scMapInfo"] && resp["scMapInfo"].response && resp["scMapInfo"].response.msg) {
		scMapInfo = resp["scMapInfo"].response.msg.result.entry;
	}
	if (resp && resp["dirSyncInfo"] && resp["dirSyncInfo"].response) {
		dirSyncInfo = resp["dirSyncInfo"].response;
	}
	// Remote Networks case
	for (let i = 0; i < rnMapInfo.length; i++) {
		let region = regionInfo.filter((region) => region["@display"] === rnMapInfo[i]["name"])[0];
		if (!rnContientRegionMap[region["@continent"]]) {
			rnContientRegionMap[region["@continent"]] = {
				total: 0,
				regions: [],
				Status: {
					value: "OK",
				},
				messages: [],
			};
		}
		rnContientRegionMap[region["@continent"]].regions.push(rnMapInfo[i]);
		rnContientRegionMap[region["@continent"]].total += rnMapInfo[i]["map-tooltip-object"].total;
		if (rnMapInfo[i]["Status"] && rnMapInfo[i]["Status"].value) {
			switch (rnMapInfo[i]["Status"].value) {
				case "Warning":
					rnContientRegionMap[region["@continent"]].messages.push(rnMapInfo[i]["Status"].tooltip);
					if (rnContientRegionMap[region["@continent"]]["Status"].value !== "Error") {
						rnContientRegionMap[region["@continent"]]["Status"].value = "Warning";
					}
					break;
				case "Error":
					rnContientRegionMap[region["@continent"]]["Status"].value = "Error";
					rnContientRegionMap[region["@continent"]].messages.push(rnMapInfo[i]["Status"].tooltip);
					break;
			}
		}
	}
	// Mobile Users case
	for (let i = 0; i < muMapInfo.length; i++) {
		let region = regionInfo.filter((region) => region["@display"] === muMapInfo[i]["name"])[0];
		if (!muContientRegionMap[region["@continent"]]) {
			muContientRegionMap[region["@continent"]] = {
				total: 0,
				regions: [],
				Status: {
					value: "OK",
				},
				messages: [],
			};
		}
		muContientRegionMap[region["@continent"]].regions.push(muMapInfo[i]);
		muContientRegionMap[region["@continent"]].total++;
		if (muMapInfo[i]["Status"] && muMapInfo[i]["Status"].value) {
			switch (muMapInfo[i]["Status"].value) {
				case "Warning":
					muContientRegionMap[region["@continent"]].messages.push(muMapInfo[i]["Status"].tooltip);
					if (muContientRegionMap[region["@continent"]]["Status"].value !== "Error") {
						muContientRegionMap[region["@continent"]]["Status"].value = "Warning";
					}
					break;
				case "Error":
					muContientRegionMap[region["@continent"]]["Status"].value = "Error";
					muContientRegionMap[region["@continent"]].messages.push(muMapInfo[i]["Status"].tooltip);
					break;
			}
		}
	}
	// Service Connections case
	for (let i = 0; i < scMapInfo.length; i++) {
		let region = regionInfo.filter((region) => region["@display"] === scMapInfo[i]["name"])[0];
		if (!scContientRegionMap[region["@continent"]]) {
			scContientRegionMap[region["@continent"]] = {
				total: 0,
				regions: [],
				Status: {
					value: "OK",
				},
				messages: [],
			};
		}
		scContientRegionMap[region["@continent"]].regions.push(scMapInfo[i]);
		scContientRegionMap[region["@continent"]].total += scMapInfo[i]["map-tooltip-object"].total;
		if (scMapInfo[i]["Status"] && scMapInfo[i]["Status"].value) {
			switch (scMapInfo[i]["Status"].value) {
				case "Warning":
					scContientRegionMap[region["@continent"]].messages.push(scMapInfo[i]["Status"].tooltip);
					if (scContientRegionMap[region["@continent"]]["Status"].value !== "Error") {
						scContientRegionMap[region["@continent"]]["Status"].value = "Warning";
					}
					break;
				case "Error":
					scContientRegionMap[region["@continent"]]["Status"].value = "Error";
					scContientRegionMap[region["@continent"]].messages.push(scMapInfo[i]["Status"].tooltip);
					break;
			}
		}
	}
	return {
		type: FETCH_MAP_DETAIL_INFO_COMBINE_SUCCESS,
		mapData: {
			rn: rnContientRegionMap,
			mu: muContientRegionMap,
			sc: scContientRegionMap,
			globalMapRegionInfo: globalMapRegionInfo,
			dirSyncInfo: dirSyncInfo,
		},
	};
};

export const getSecondLevelDetailInfoSuccess = (resp) => {
	const xhr = resp && resp.response ? resp.response : null;
	let info = {};

	if (xhr && resp.status === 200) {
		info = xhr.msg;
	}

	if (info["@status"] === "success") {
		let dataWithKey = [],
			index = 1;
		info.result.entry.map((data) => {
			dataWithKey.push({ Key: index, ...data });
			index++;
		});
		let obj = {
			tableData: dataWithKey,
			total: info.result["@count"],
		};
		return {
			type: FETCH_SECOND_LEVEL_MAP_DETAIL_INFO_SUCCESS,
			info: obj,
		};
	}
	return {
		type: FETCH_SECOND_LEVEL_MAP_DETAIL_INFO_SUCCESS,
	};
};

export const getSecondLevelTableDetailInfoSuccess = (resp) => {
	const xhr = resp && resp.response ? resp.response : null;
	let info = {};

	if (xhr && resp.status === 200) {
		info = xhr.msg;
	}

	if (info["@status"] === "success") {
		let dataWithKey = [],
			index = 1;
		info.result.entry.map((data) => {
			dataWithKey.push({ Key: index, ...data });
			index++;
		});
		let obj = {
			tableData: dataWithKey,
			total: info.result["@count"],
		};
		return {
			type: FETCH_SECOND_LEVEL_TABLE_DETAIL_INFO_SUCCESS,
			info: obj,
		};
	}
	return {
		type: FETCH_SECOND_LEVEL_TABLE_DETAIL_INFO_SUCCESS,
	};
};

export const clearDetailInfoSuccess = (resp) => ({
	type: CLEAR_MAP_DETAIL_INFO_SUCCESS,
	mapData: "",
});

export const startDataLoading = (location) => ({
	type: START_DATA_LOADING,
	location,
});

export const finishDataLoading = (location) => ({
	type: FINISH_DATA_LOADING,
	location,
});

export const fetchRemoteNetworksStatusEpic = (action$) => {
	return action$.pipe(
		ofType(FETCH_REMOTE_NETWORKS_STATUS),
		mergeMap((action) => {
			return concat(
				of(startLoading()),
				forkJoin({
					status: ajax(fetchRemoteNetworksStatusObservable()),
				}).pipe(
					mergeMap((response) => of(finishLoading(), fetchRemoteNetworksStatusSuccess(response))),
					catchError((error) => {
						return of(
							{
								type: SERVER_ERROR,
								errorMessage: parseError(error),
								showMessage: false,
							},
							finishLoading()
						);
					})
				)
			);
		})
	);
};

export const fetchMobileUsersStatusEpic = (action$) => {
	return action$.pipe(
		ofType(FETCH_MOBILE_USERS_STATUS),
		mergeMap((action) => {
			return concat(
				of(startLoading()),
				forkJoin({
					status: ajax(fetchMobileUsersStatusObservable()),
					current_user: ajax(fetchMobileUsersCurrentUsersObservable()),
					users: ajax(fetchMobileUsersTotalUsersObservable()),
					current_users_details: ajax(fetchMobileUsersCurrentUsersDetailObservable()),
					users_details: ajax(fetchMobileUsersTotalUsersDetailObservable()),
				}).pipe(
					mergeMap((response) => of(finishLoading(), fetchMobileUsersStatusSuccess(response))),
					catchError((error) => {
						return of(
							{
								type: SERVER_ERROR,
								errorMessage: parseError(error),
								showMessage: false,
							},
							finishLoading()
						);
					})
				)
			);
		})
	);
};

export const fetchCurrentUsersDetailsInfoEpic = (action$) => {
	return action$.pipe(
		ofType(FETCH_CURRENT_USERS_DETAILS_INFO),
		mergeMap((action) =>
			ajax(fetchMobileUsersCurrentUsersDetailObservable(action.queryString)).pipe(
				mergeMap((response) => of(fetchCurrentUsersDetailsInfoEpicSuccess(response))),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error),
						showMessage: false,
					});
				})
			)
		)
	);
};

export const fetchServiceConnectionStatusEpic = (action$) => {
	return action$.pipe(
		ofType(FETCH_SERVICE_CONNECTION_STATUS),
		mergeMap((action) => {
			return concat(
				of(startLoading()),
				ajax(fetchServiceConnectionStatusObservable()).pipe(
					mergeMap((response) => of(finishLoading(), fetchServiceConnectionStatusSuccess(response))),
					catchError((error) => {
						return of(
							{
								type: SERVER_ERROR,
								errorMessage: parseError(error),
								showMessage: false,
							},
							finishLoading()
						);
					})
				)
			);
		})
	);
};

export const getSecondLevelDetailInfoEpic = (action$) =>
	action$.pipe(
		ofType(FETCH_SECOND_LEVEL_MAP_DETAIL_INFO),
		mergeMap((action) => {
			return concat(
				of(startDataLoading("map")),
				ajax(getSecondLevelDetailInfoObservable(action.header, action.locations)).pipe(
					mergeMap((response) => of(finishDataLoading("map"), getSecondLevelDetailInfoSuccess(response))),
					catchError((error) => {
						return of(
							{
								type: SERVER_ERROR,
								errorMessage: parseError(error),
								showMessage: false,
							},
							finishDataLoading("map")
						);
					})
				)
			);
		})
	);

export const getSecondLevelTableDetailInfoEpic = (action$) => {
	return action$.pipe(
		ofType(FETCH_SECOND_LEVEL_TABLE_DETAIL_INFO),
		mergeMap((action) =>
			ajax(getSecondLevelTableDetailInfoObservable(action.header, action.locations)).pipe(
				mergeMap((response) => of(getSecondLevelTableDetailInfoSuccess(response))),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error),
						showMessage: false,
					});
				})
			)
		)
	);
};

export const clearDetailInfoEpic = (action$) => {
	return action$.pipe(
		ofType(CLEAR_MAP_DETAIL_INFO),
		mergeMap((response) => of(clearDetailInfoSuccess())),
		catchError((error) => {
			return of({
				type: SERVER_ERROR,
				errorMessage: parseError(error),
				showMessage: false,
			});
		})
	);
};
