import _ from 'lodash'
import moment from 'moment'

import * as actionTypes from './actionTypes'
import Constants from '../../../utils/Constants.json'
import Util from '../../../utils/Util'

const ACTION_HANDLERS = {
	[actionTypes.RESET_STORE]: (state, { pageType }) => {
		if (pageType === 'AlertDetails') {
			return {
				..._.cloneDeep(initialState)
			}
		} else {
			return { ...state }
		}
	},
	/*--------------- Device Details ---------------*/
	[actionTypes.FETCH_ALERT_DEVICE_DETAILS_REQUEST]: (state, {}) => {
		return {
			...state,
			deviceDetails: null,
			deviceDetailsRequestId: null,
			deviceDetailsLoading: true,
			deviceDetailsError: null
		}
	},
	[actionTypes.SET_ALERT_DEVICE_DETAILS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			deviceDetailsRequestId: jobId
		}
	},
	[actionTypes.FETCH_ALERT_DEVICE_DETAILS_SUCCESS]: (state, { deviceDetails }) => {
		const formattedData = {}
		deviceDetails.forEach((device) => {
			formattedData[device.serial] = device
		})
		return {
			...state,
			deviceDetails: formattedData,
			deviceDetailsRequestId: null,
			deviceDetailsLoading: false,
			deviceDetailsError: null
		}
	},
	[actionTypes.FETCH_ALERT_DEVICE_DETAILS_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceDetails: null,
			deviceDetailsRequestId: null,
			deviceDetailsLoading: false,
			deviceDetailsError: error
		}
	},
	[actionTypes.RESTORE_DEVICE_DETAILS]: (state, { data }) => {
		return {
			...state,
			deviceDetailsLoading: false,
			deviceDetailsError: null,
			...data
		}
	},
	/*--------------- Alert ---------------*/
	[actionTypes.FETCH_ALERT_REQUEST]: (state, {}) => {
		return {
			...state,
			alert: null,
			alertLoading: true,
			alertError: null
		}
	},
	[actionTypes.FETCH_ALERT_SUCCESS]: (state, { alert }) => {
		return {
			...state,
			alert,
			alertLoading: false,
			alertError: null
		}
	},
	[actionTypes.FETCH_ALERT_FAILURE]: (state, { error }) => {
		return {
			...state,
			alert: null,
			alertLoading: false,
			alertError: error
		}
	},
	[actionTypes.UPDATE_ALERT_STATUS_SUCCESS]: (state, { alert, alertId }) => {
		const updatedAlerts = [...state.alert]
		const index = updatedAlerts.findIndex((alert) => alert.alert_id === alertId)
		if (index > -1) updatedAlerts[index] = alert
		return {
			...state,
			alert: updatedAlerts,
			alertLoading: false,
			alertError: null
		}
	},
	[actionTypes.RESTORE_ALERT]: (state, { data }) => {
		return {
			...state,
			alertLoading: false,
			alertError: null,
			...data
		}
	},
	/*--------------- Comments ---------------*/
	[actionTypes.FETCH_ALERT_COMMENTS_REQUEST]: (state, { alertId }) => {
		const updatedComments = { ...state.alertComments }
		updatedComments[alertId] = { isLoading: true }
		return {
			...state,
			alertComments: updatedComments,
			alertCommentsLoading: false,
			alertCommentsError: null
		}
	},
	[actionTypes.FETCH_ALERT_COMMENTS_SUCCESS]: (state, { comments, alertId }) => {
		const updatedComments = { ...state.alertComments }
		updatedComments[alertId] = { isLoading: false, data: comments }
		return {
			...state,
			alertComments: updatedComments,
			alertCommentsLoading: false,
			alertCommentsError: null
		}
	},
	[actionTypes.FETCH_ALERT_COMMENTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertComments: null,
			alertCommentsLoading: false,
			alertCommentsError: error
		}
	},
	[actionTypes.ADD_ALERT_COMMENT_REQUEST]: (state, { comment, alertId }) => {
		const updatedComments = { ...state.alertComments }
		if (_.get(updatedComments, alertId)) {
			updatedComments[alertId].isLoading = true
		} else {
			updatedComments[alertId] = { isLoading: true, data: [] }
		}
		return {
			...state,
			alertComments: updatedComments,
			alertCommentsLoading: true,
			alertCommentsError: null
		}
	},
	[actionTypes.ADD_ALERT_COMMENT_SUCCESS]: (state, { comment, alertId }) => {
		const updatedComments = { ...state.alertComments }
		updatedComments[alertId].isLoading = false
		updatedComments[alertId].data.push(comment)
		return {
			...state,
			alertComments: updatedComments,
			alertCommentsLoading: false,
			alertCommentsError: null
		}
	},
	[actionTypes.ADD_ALERT_COMMENT_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertComments: null,
			alertCommentsLoading: false,
			alertCommentsError: error
		}
	},
	[actionTypes.REMOVE_ALERT_COMMENT_REQUEST]: (state, { commentId, alertId }) => {
		const updatedComments = { ...state.alertComments }
		updatedComments[alertId].isLoading = true

		return {
			...state,
			alertComments: updatedComments,
			alertCommentsLoading: true,
			alertCommentsError: null
		}
	},
	[actionTypes.REMOVE_ALERT_COMMENT_SUCCESS]: (state, { commentId, alertId }) => {
		const updatedComments = { ...state.alertComments }
		updatedComments[alertId].isLoading = false
		_.remove(updatedComments[alertId].data, (comment) => comment.comment_id === commentId)
		return {
			...state,
			alertComments: updatedComments,
			alertCommentsLoading: false,
			alertCommentsError: null
		}
	},
	[actionTypes.REMOVE_ALERT_COMMENT_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertComments: null,
			alertCommentsLoading: false,
			alertCommentsError: error
		}
	},
	[actionTypes.UPDATE_ALERT_COMMENT_REQUEST]: (state, { alertId }) => {
		const updatedComments = { ...state.alertComments }
		updatedComments[alertId].isLoading = true

		return {
			...state,
			alertComments: updatedComments,
			alertCommentsLoading: true,
			alertCommentsError: null
		}
	},
	[actionTypes.UPDATE_ALERT_COMMENT_SUCCESS]: (state, { comment, alertId, commentId }) => {
		const updatedComments = { ...state.alertComments }
		updatedComments[alertId].isLoading = false
		_.remove(updatedComments[alertId].data, (comment) => comment.comment_id === commentId)
		updatedComments[alertId].data.push(comment)
		return {
			...state,
			alertComments: updatedComments,
			alertCommentsLoading: false,
			alertCommentsError: null
		}
	},
	[actionTypes.UPDATE_ALERT_COMMENT_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertComments: null,
			alertCommentsLoading: false,
			alertCommentsError: error
		}
	},
	/*--------------- Events ---------------*/
	[actionTypes.FETCH_ALERT_EVENTS_REQUEST]: (state, { alertId }) => {
		const updatedEvents = { ...state.alertEvents }
		updatedEvents[alertId] = { isLoading: true }
		return {
			...state,
			alertEvents: updatedEvents,
			alertEventsLoading: false,
			alertEventsError: null
		}
	},
	[actionTypes.FETCH_ALERT_EVENTS_SUCCESS]: (state, { events, alertId }) => {
		const updatedEvents = { ...state.alertEvents }
		updatedEvents[alertId] = { isLoading: false, data: events }
		const metr = []
		events.map((r) =>
			r.evidences.map((e) => {
				// metr.push({[e.key]: e.value})
				metr.push(e.key)
			})
		)
		state.alert[0]['metrics'] = metr //.flatMap(m => [Object.keys(m)]).join(',')
		// Object.keys(...metr);
		return {
			...state,
			...alert,
			alertEvents: updatedEvents,
			alertEventsLoading: false,
			alertEventsError: null
		}
	},
	[actionTypes.FETCH_ALERT_EVENTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertEvents: null,
			alertEventsLoading: false,
			alertEventsError: error
		}
	},
	/*--------------- Timeline ---------------*/
	[actionTypes.UPDATE_ALERT_TIMELINE_TIME_RANGE]: (state, { preset }) => {
		const timelineTimeRange = Util.getTimeRange(preset)
		return {
			...state,
			alertTimelineStartTime: timelineTimeRange[0],
			alertTimelineEndTime: timelineTimeRange[1]
		}
	},
	[actionTypes.FETCH_ALERT_TIMELINE_REQUEST]: (state, { device, metrics }) => {
		const alertTimeline = {}
		metrics.forEach((metric) => (alertTimeline[metric] = null))
		return {
			...state,
			alertTimeline,
			alertTimelineLoading: true,
			alertTimelineError: false,
			alertTimelineRequestId: []
		}
	},
	[actionTypes.SET_ALERT_TIMELINE_REQUEST_ID]: (state, { ids }) => {
		return {
			...state,
			alertTimelineRequestId: ids
		}
	},
	[actionTypes.FETCH_ALERT_TIMELINE_SUCCESS]: (state, { data, metric, jobId }) => {
		const alertTimeline = { ...state.alertTimeline }
		let alertTimelineRequestId = state.alertTimelineRequestId
		if (!alertTimelineRequestId.includes(jobId)) {
			return { ...state }
		}
		alertTimeline[metric] = data
		let alertTimelineLoading = false
		Object.keys(state.alertTimeline).forEach((metric) => {
			if (!alertTimeline[metric]) alertTimelineLoading = true
		})
		if (!alertTimelineLoading) {
			alertTimelineRequestId = []
		}
		return {
			...state,
			alertTimeline,
			alertTimelineLoading,
			alertTimelineRequestId
		}
	},
	[actionTypes.FETCH_ALERT_TIMELINE_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertTimelineError: error
		}
	},
	[actionTypes.FETCH_ALERT_FORECAST_REQUEST]: (state, { device, metrics }) => {
		const alertForecast = {}
		metrics.forEach((metric) => (alertForecast[metric] = null))
		return {
			...state,
			alertForecast,
			alertForecastLoading: true,
			alertForecastError: false,
			alertForecastRequestId: []
		}
	},
	[actionTypes.FETCH_ALERT_FORECAST_SUCCESS]: (state, { alertForecast, alertTrend }) => {
		let alertForecastLoading = false
		Object.keys(alertForecast).forEach((metric) => {
			if (!alertForecast[metric] || !alertTrend[metric]) alertForecastLoading = true
		})
		return {
			...state,
			alertForecast,
			alertTrend,
			alertForecastLoading,
			alertForecastError: false
		}
	},
	[actionTypes.FETCH_ALERT_FORECAST_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertForecastLoading: false,
			alertForecastError: error
		}
	},
	[actionTypes.RESTORE_ALERT_TIMELINE]: (state, { data }) => {
		return {
			...state,
			alertTimelineLoading: false,
			alertTimelineError: null,
			alertTimelineRequestId: [],
			...data
		}
	},
	/*--------------- Mov Avg ---------------*/
	[actionTypes.FETCH_ALERT_MOV_AVG_REQUEST]: (state, { device, metrics }) => {
		const alertMovingAverage = {}
		metrics.forEach((metric) => (alertMovingAverage[metric] = null))
		return {
			...state,
			alertMovingAverage,
			alertMovingAverageLoading: true,
			alertMovingAverageError: false,
			alertMovingAverageRequestId: []
		}
	},
	[actionTypes.SET_ALERT_MOV_AVG_REQUEST_ID]: (state, { ids }) => {
		return {
			...state,
			alertMovingAverageRequestId: ids
		}
	},
	[actionTypes.FETCH_ALERT_MOV_AVG_SUCCESS]: (state, { data, metric, jobId }) => {
		if (_.isEqual(data, {})) {
			return {
				...state,
				alertMovingAverage: data,
				alertMovingAverageLoading: false,
				alertMovingAverageRequestId: null
			}
		}
		const alertMovingAverage = { ...state.alertMovingAverage }
		let alertMovingAverageRequestId = state.alertMovingAverageRequestId
		if (!alertMovingAverageRequestId.includes(jobId)) {
			return { ...state }
		}
		alertMovingAverage[metric] = data
		let alertMovingAverageLoading = false
		Object.keys(state.alertMovingAverage).forEach((metric) => {
			if (!alertMovingAverage[metric]) alertMovingAverageLoading = true
		})
		if (!alertMovingAverageLoading) {
			alertMovingAverageRequestId = []
		}
		return {
			...state,
			alertMovingAverage,
			alertMovingAverageLoading,
			alertMovingAverageRequestId
		}
	},
	[actionTypes.FETCH_ALERT_MOV_AVG_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertMovingAverageError: error
		}
	},
	[actionTypes.RESTORE_ALERT_MOV_AVG]: (state, { data }) => {
		return {
			...state,
			alertMovingAverageLoading: false,
			alertMovingAverageError: false,
			alertMovingAverageRequestId: [],
			...data
		}
	},
	/*--------------- Capacity ---------------*/
	[actionTypes.FETCH_ALERT_CAPACITY_REQUEST]: (state, { metricList }) => {
		return {
			...state,
			alertCapacity: metricList,
			alertCapacityLoading: true,
			alertCapacityError: false
		}
	},
	[actionTypes.FETCH_ALERT_CAPACITY_SUCCESS]: (state, { data }) => {
		const alertCapacity = {}
		Object.keys(state.alertCapacity).forEach((metric) => {
			alertCapacity[metric] = data.find((item) => item.name === state.alertCapacity[metric])
		})
		return {
			...state,
			alertCapacity,
			alertCapacityLoading: false,
			alertCapacityError: false
		}
	},
	[actionTypes.FETCH_ALERT_CAPACITY_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertCapacity: null,
			alertCapacityLoading: false,
			alertCapacityError: error
		}
	},
	[actionTypes.RESTORE_ALERT_CAPACITY]: (state, { data }) => {
		return {
			...state,
			alertCapacityLoading: false,
			alertCapacityError: false,
			...data
		}
	},
	/*--------------- Commit Events ---------------*/
	[actionTypes.FETCH_ALERT_COMMIT_EVENTS_REQUEST]: (state, {}) => {
		return {
			...state,
			alertCommitEvents: null,
			alertCommitEventsLoading: true,
			alertCommitEventsError: false,
			alertCommitEventsRequestId: null
		}
	},
	[actionTypes.SET_ALERT_COMMIT_EVENTS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			alertCommitEventsRequestId: jobId
		}
	},
	[actionTypes.FETCH_ALERT_COMMIT_EVENTS_SUCCESS]: (state, { data }) => {
		return {
			...state,
			alertCommitEvents: data,
			alertCommitEventsLoading: false,
			alertCommitEventsError: false
		}
	},
	[actionTypes.FETCH_ALERT_COMMIT_EVENTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertCommitEvents: null,
			alertCommitEventsLoading: false,
			alertCommitEventsError: error,
			alertCommitEventsRequestId: null
		}
	},
	[actionTypes.RESTORE_ALERT_COMMIT_EVENTS]: (state, { data }) => {
		return {
			...state,
			alertCommitEventsLoading: false,
			alertCommitEventsError: null,
			alertCommitEventsRequestId: null,
			...data
		}
	},
	/*--------------- Activities ---------------*/
	[actionTypes.FETCH_ALERT_ACTIVITIES_REQUEST]: (state, { alertId }) => {
		const updatedActivities = { ...state.alertActivities }
		updatedActivities[alertId] = { isLoading: true }
		return {
			...state,
			alertActivities: updatedActivities,
			alertActivitiesLoading: false,
			alertActivitiesError: null
		}
	},
	[actionTypes.FETCH_ALERT_ACTIVITIES_SUCCESS]: (state, { activities, alertId }) => {
		const updatedActivities = { ...state.alertActivities }
		updatedActivities[alertId] = { isLoading: false, data: activities }
		return {
			...state,
			alertActivities: updatedActivities,
			alertActivitiesLoading: false,
			alertActivitiesError: null
		}
	},
	[actionTypes.FETCH_ALERT_ACTIVITIES_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertActivities: [],
			alertActivitiesLoading: false,
			alertActivitiesError: error
		}
	},
	/*----- Update Line Chart Settings Actions -----*/
	[actionTypes.SET_SHOW_ALERT_COMMIT_EVENTS]: (state, { showAlertCommitEvents }) => {
		return {
			...state,
			showAlertCommitEvents
		}
	},
	[actionTypes.SET_SHOW_ALERT_REF_LINES]: (state, { showAlertRefLines }) => {
		return {
			...state,
			showAlertRefLines
		}
	},
	[actionTypes.SET_ALERT_TIME_RANGE_PRESET]: (state, { alertTimeRangePreset }) => {
		return {
			...state,
			alertTimeRangePreset
		}
	},
	/*----- Table Chart Reducers -----*/
	[actionTypes.RESET_ALERT_TABLE_CHARTS]: (state) => {
		return {
			...state,
			alertTableChartsData: {},
			alertTableChartsDataLoading: false,
			alertTableChartsDataError: null,
			alertTableChartsRequestId: []
		}
	},
	[actionTypes.FETCH_ALERT_TABLE_CHART_REQUEST]: (state, { deviceInfo }) => {
		return {
			...state,
			alertTableChartsData: { ...state.alertTableChartsData, [deviceInfo.metric]: { isLoading: true } },
			alertTableChartsDataError: null
		}
	},
	[actionTypes.FETCH_ALERT_TABLE_CHART_SUCCESS]: (state, { jobApiResponse, apiResponse, metric, currentTabJob }) => {
		if (currentTabJob && !state.alertTableChartsRequestId.includes(currentTabJob)) {
			return { ...state }
		} else {
			let alertTableChartData
			const expCompare = (a, b) => {
				if (a === 'Unlimited') return 1
				if (b === 'Unlimited') return -1
				if (a > b) return 1
				if (a <= b) return -1
			}
			const envCompare = (a, b) => {
				if (a.timegenerated > b.timegenerated) {
					return -1
				} else if (a.timegenerated < b.timegenerated) {
					return 1
				} else {
					if (a.name > b.name) {
						return 1
					} else {
						return -1
					}
				}
			}
			if (metric === 'license') {
				jobApiResponse.forEach((license) => {
					const expDate = moment(license.expiredate)
					if (expDate.isValid()) {
						const days = expDate.diff(moment(), 'days')
						license.daystoexpire = days
					} else {
						license.daystoexpire = 'Unlimited'
					}
				})
				jobApiResponse.sort((a, b) => expCompare(a.daystoexpire, b.daystoexpire))
			}
			if (metric === 'eol') {
				apiResponse.forEach((eol) => {
					const expDate = moment(eol.eol_date)
					if (expDate.isValid()) {
						const days = expDate.diff(moment(), 'days')
						eol.daystoeol = days
					} else {
						eol.daystoeol = 'Unlimited'
					}
				})
				apiResponse.sort((a, b) => expCompare(a.daystoeol, b.daystoeol))
			}
			if (_.isString(metric) && metric.startsWith('environmentals')) {
				jobApiResponse.forEach((env) => {
					if (metric === 'environmentals.fan') {
						const name = env.description.match(/^(.+)\s*RPM\s*$/)
						env.name = name && name.length === 2 ? `${name[1]}` : env.description
					}
					if (metric === 'environmentals.thermal') {
						const name = env.description.match(/^Temperature:\s*(.+)$/)
						env.name = name && name.length === 2 ? `${name[1]}` : env.description
					}
					if (metric === 'environmentals.power') {
						const name = env.description.match(/^Power\s*Rail:\s*(.+)$/)
						env.name = name && name.length === 2 ? `${name[1]}` : env.description
					}
					if (metric === 'environmentals.powersupply') {
						const name = env.description.match(/^Power\s*Supply\s*(#\d+)\s*\((\w+)\)$/)
						env.name = name && name.length === 3 ? `${name[1]} - ${_.capitalize(name[2])}` : env.description
					}
				})
				jobApiResponse.sort((a, b) => envCompare(a, b))

				if (metric === 'environmentals.fan') {
					const supplementalData = []
					jobApiResponse.forEach((fan) => {
						if (!fan.name.startsWith('Fan Tray')) supplementalData.push(fan)
					})
					_.pullAll(jobApiResponse, supplementalData)
					jobApiResponse = { mainData: jobApiResponse, supplementalData }
				}
			}
			if (jobApiResponse && apiResponse) {
				const chartData = {}
				const installed = jobApiResponse[0]
				let latest = {}
				if (metric === 'av_update') {
					latest = apiResponse.find((i) => i.content_name === 'antivirus')
					chartData.installed_version = installed.av_version
					chartData.installed_release_date = installed.av_release_date
				} else if (metric === 'threat_update') {
					latest = apiResponse.find((i) => i.content_name === 'applications and threats')
					chartData.installed_version = installed.threat_version
					chartData.installed_release_date = installed.threat_release_date
				} else if (metric === 'wildfire_update') {
					latest = apiResponse.find((i) => i.content_name === 'wildfire')
					chartData.installed_version = installed.wildfire_version
					chartData.installed_release_date = installed.wildfire_release_date
				}
				chartData.content_name = latest.content_name
				chartData.latest_version = latest.version
				chartData.latest_release_date = latest.content_release_date
				alertTableChartData = [chartData]
			} else if (jobApiResponse) {
				alertTableChartData = jobApiResponse
			} else {
				alertTableChartData = apiResponse
			}
			if (metric !== 'environmentals.fan') {
				alertTableChartData = { mainData: alertTableChartData }
			}

			return {
				...state,
				alertTableChartsData: { ...state.alertTableChartsData, [metric]: { isLoading: false, data: alertTableChartData } },
				alertTableChartsDataError: null
			}
		}
	},
	[actionTypes.SET_ALERT_TABLE_CHART_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			alertTableChartsRequestId: [...state.alertTableChartsRequestId, jobId]
		}
	},
	[actionTypes.FETCH_ALERT_TABLE_CHART_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertTableChartsDataError: error
		}
	},
	[actionTypes.RESTORE_ALERT_TABLE_CHARTS]: (state, { data }) => {
		return {
			...state,
			alertTableChartsDataLoading: false,
			alertTableChartsDataError: null,
			alertTableChartsRequestId: null,
			...data
		}
	}
}

const initialState = {
	deviceDetails: null,
	deviceDetailsLoading: true,
	deviceDetailsError: null,
	alert: null,
	alertLoading: true,
	alertError: null,
	alertEvents: null,
	alertEventsLoading: true,
	alertEventsError: null,
	alertActivities: null,
	alertActivitiesLoading: false,
	alertActivitiesError: null,
	alertComments: null,
	alertCommentsLoading: true,
	alertCommentsError: null,
	alertTimelineStartTime: null,
	alertTimelineEndTime: null,
	alertTimeline: {},
	alertTimelineLoading: true,
	alertTimelineError: false,
	alertTimelineRequestId: [],
	alertMovingAverage: {},
	alertMovingAverageLoading: false,
	alertMovingAverageError: false,
	alertMovingAverageRequestId: [],
	alertCommitEvents: null,
	alertCommitEventsLoading: true,
	alertCommitEventsError: false,
	alertCommitEventsRequestId: null,
	alertCapacity: null,
	alertCapacityLoading: true,
	alertCapacityError: false,
	alertTableChartsData: {},
	alertTableChartsDataLoading: true,
	alertTableChartsDataError: null,
	alertTableChartsRequestId: [],
	showAlertCommitEvents: false,
	showAlertRefLines: true,
	alertTimeRangePreset: Constants.TIME_RANGE_PRESETS.DefaultPreset
}

export const alertDetailsReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
