import React, { Component } from 'react'
import { DropdownWidget as Dropdown, BaseInput as Input, connect } from 'ui-lib'
import { quotes } from '../FilterBuilder'
import Constants from '../../../../utils/Constants.json'
import _ from 'lodash'

import * as mainActions from '../../../../../../main/actions'
import * as insightsActions from '../../.././../store/actions'
import * as insightsMainActions from '../../store/actions'

import MasterDetailGridWidget from './MasterDetailGridWidget/MasterDetailGridWidget'
import DetailCellRenderer from '../../../../components/CellRenderers/DetailCellRenderer'
import HostnameCellRenderer from '../../../../components/CellRenderers/HostnameCellRenderer'
import MetricCellRenderer from '../../../../components/CellRenderers/MetricCellRenderer'
import LastUpdateHeaderCellRenderer from '../../../../components/CellRenderers/LastUpdatedHeaderCellRenderer/LastUpdateHeaderCellRenderer'
import LastUpdatedCellRenderer from '../../../../components/CellRenderers/LastUpdatedCellRenderer'

import iconLoading from 'ui-lib/src/images/icon-loading.svg'

import './InsightsTable.scss'

class InsightsTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			defaultColDef: { flex: 1 },
			frameworkComponents: {
				detailCellRenderer: DetailCellRenderer,
				hostnameCellRenderer: HostnameCellRenderer,
				metricCellRenderer: MetricCellRenderer,
				LastUpdateHeaderCellRenderer,
				LastUpdatedCellRenderer
			},
			detailCellRenderer: 'detailCellRenderer',
			resetSchema: false,
			gridWidth: 0
		}
	}

	componentDidUpdate(prevProps) {
		if (this.gridApi && prevProps.insightsLogs !== this.props.insightsLogs) {
			this.gridApi.forEachNode((node) => {
				node.setExpanded(true)
				node.detailNode && node.detailNode.setRowHeight(null)
			})
			this.gridApi.resetRowHeights()
			this.gridApi.redrawRows()
			this.props.removeSticky()
		}

		if (this.gridApi && this.gridColumnApi && prevProps.insightsSchema !== this.props.insightsSchema) {
			this.gridColumnApi.resetColumnState()
			this.gridApi.sizeColumnsToFit()
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api
		this.gridColumnApi = params.columnApi
		this.gridApi.forEachNode((node) => {
			node.setExpanded(true)
		})
		this.gridApi.sizeColumnsToFit()
	}

	onModelUpdated = () => {
		if (this.props.offset) {
			const el = document.getElementsByClassName('ag-body-viewport')[0]
			el && el.scrollTo(0, this.props.offset)
		}
	}

	onGridSizeChanged = (e) => {
		if (this.gridApi) {
			if (e.clientWidth !== this.state.gridWidth) {
				this.setState({ gridWidth: e.clientWidth })
				this.gridApi.sizeColumnsToFit()
				this.props.removeSticky()
				this.onScroll()
			}
		}
	}

	getMainMenuItems = (params, activeInsightsTab) => {
		const itemsToExclue = ['autoSizeThis', 'autoSizeAll', 'resetColumns']
		const menu = params.defaultItems.filter((item) => !itemsToExclue.includes(item))
		menu.push({
			name: 'Reset Columns',
			action: () => {
				this.props.resetInsightsSchema(this.props.activeInsightsTab.split('.')[1])
			}
		})
		return menu
	}

	onPrevPage = () => {
		if (this.props.insightsPageNumber + 1 > 1) {
			this.props.removeSticky()
			this.props.clearOffset()
			this.props.updateInsightsPageRequest({ pageNumber: this.props.insightsPageNumber - 1 })
		}
	}

	onNextPage = () => {
		if (this.props.insightsPageNumber + 1 < Math.ceil(this.props.insightsLogsCount / this.props.insightsPageSize)) {
			this.props.removeSticky()
			this.props.clearOffset()
			this.props.updateInsightsPageRequest({ pageNumber: this.props.insightsPageNumber + 1 })
		}
	}

	onPageSizeChange = (value) => {
		this.props.removeSticky()
		this.props.clearOffset()
		this.props.updateInsightsPageRequest({ pageSize: parseInt(value) })
	}

	onScroll = () => {
		const agRoot = document.getElementsByClassName('ag-root')[0]
		const centerRowsContainer = document.getElementsByClassName('ag-center-cols-clipper')[0]
		const centerDetailsContainer = document.getElementsByClassName('ag-full-width-container')[0]
		if (!(agRoot && centerRowsContainer && centerDetailsContainer)) return
		const centerRows = centerRowsContainer.getElementsByClassName('ag-row')
		const centerDetails = centerDetailsContainer.querySelectorAll(`[row-id^='detail_']`)
		const outerGrid = document.getElementsByClassName('outer-grid')[0]
		const grid = document.getElementsByClassName('master-detail-grid')[0]

		if (centerRows.length > centerDetails.length) {
			for (let i = 0; i < centerRows.length; i++) {
				const row = centerRows[i]
				const rowId = parseInt(row.getAttribute('row-id'))

				if (row) {
					const top = row.getBoundingClientRect().top - agRoot.getBoundingClientRect().top - 84
					const bottom = row.getBoundingClientRect().bottom - agRoot.getBoundingClientRect().top - 84

					if (top < 0 && bottom >= 0) {
						let classes, oldHeader, currentHeader
						if (this.props.activeInsightsTab === Constants.FIXED_TABS[0]) {
							classes = 'sticky device-detail-header'
							oldHeader = outerGrid.getElementsByClassName(`ag-header sticky metric-detail-header`)
						} else {
							classes = 'sticky metric-detail-header'
							oldHeader = outerGrid.getElementsByClassName(`ag-header sticky device-detail-header`)
						}
						currentHeader = outerGrid.getElementsByClassName(`ag-header ${classes}`)
						const headerVisible = row.getBoundingClientRect().top - agRoot.getBoundingClientRect().top - 28 > 0
						if (headerVisible) {
							grid && grid.classList.remove('sticky-mode')
							if (currentHeader.length !== 0) {
								outerGrid.removeChild(currentHeader[0])
							}
							this.props.removeSticky()
							return
						}
						if (oldHeader.length !== 0) {
							outerGrid.removeChild(oldHeader[0])
						}
						if (currentHeader.length == 0) {
							const stickyHeader = centerDetailsContainer.querySelector(`[row-id='detail_${rowId}']`).getElementsByClassName('ag-header')[0]
							const copy = stickyHeader && stickyHeader.cloneNode(true)
							copy && copy.classList.add(...classes.split(' '))
							copy && outerGrid.appendChild(copy)
						}
						if (this.props.pinnedTopRow !== rowId) {
							grid && grid.classList.add('sticky-mode')
							this.props.setSticky(rowId)
						}
						break
					}
				}
			}
		} else {
			for (let i = 0; i < centerDetails.length; i++) {
				const detail = centerDetails[i]
				const rowId = parseInt(detail.getAttribute('row-id').match(/^detail_(\d+)$/)[1])
				const row = centerRowsContainer.querySelector(`[row-id='${rowId}']`)

				if (detail) {
					const top = detail.getBoundingClientRect().top - agRoot.getBoundingClientRect().top - 112
					const bottom = detail.getBoundingClientRect().bottom - agRoot.getBoundingClientRect().top - 112

					if (top < 0 && bottom >= 0) {
						let classes, oldHeader, currentHeader
						if (this.props.activeInsightsTab === Constants.FIXED_TABS[0]) {
							classes = 'sticky device-detail-header'
							oldHeader = outerGrid.getElementsByClassName(`ag-header sticky metric-detail-header`)
						} else {
							classes = 'sticky metric-detail-header'
							oldHeader = outerGrid.getElementsByClassName(`ag-header sticky device-detail-header`)
						}
						currentHeader = outerGrid.getElementsByClassName(`ag-header ${classes}`)
						const headerVisible = row && row.getBoundingClientRect().top - agRoot.getBoundingClientRect().top - 28 > 0
						if (headerVisible) {
							grid && grid.classList.remove('sticky-mode')
							if (currentHeader.length !== 0) {
								outerGrid.removeChild(currentHeader[0])
							}
							this.props.removeSticky()
							return
						}
						if (oldHeader.length !== 0) {
							outerGrid.removeChild(oldHeader[0])
						}
						if (currentHeader.length == 0) {
							const stickyHeader = centerDetailsContainer.querySelector(`[row-id='detail_${rowId}']`).getElementsByClassName('ag-header')[0]
							const copy = stickyHeader && stickyHeader.cloneNode(true)
							copy && copy.classList.add(...classes.split(' '))
							copy && outerGrid.appendChild(copy)
						}
						if (this.props.pinnedTopRow !== rowId) {
							grid && grid.classList.add('sticky-mode')
							this.props.setSticky(rowId)
						}
						break
					}
				}
			}
		}
	}

	getRowHeight = (params) => {
		if (params.node && params.node.detail && params.node.data && params.node.data.details_data.length > 0) {
			if (params.node.data.details_nomore) {
				return 28 * (params.node.data.details_data.length + 1) + 3
			}
			return 28 * (params.node.data.details_data.length + 2) + 3
		} else {
			if (params.node.detail) return 31
			return 28
		}
	}

	render() {
		let {
			insightsLogs: logs,
			insightsLogsLoading: logsLoading,
			insightsLogsError: logsError,
			insightsLogsCount,
			insightsLogsCountLoading,
			insightsLogsCountError,
			insightsSchema,
			insightsPageNumber: pageNumber,
			insightsPageSize: pageSize,
			metricsMisc,
			pinnedTopRow,
			activeInsightsTab
		} = this.props

		const gridFooter = (
			<div className="grid-footer">
				{insightsLogsCountError ? (
					<span className="info-area">Error Fetching Logs Count</span>
				) : insightsLogsCountLoading ? (
					<span className="info-area">
						<img className="loading-spinner" src={iconLoading} alt={'loading'} />
						Loading Page Info
					</span>
				) : (
					<>
						<span className="config-page-size">
							Per Page
							<Dropdown
								value={pageSize}
								options={[
									{ value: '10', label: '10' },
									{ value: '20', label: '20' }
								]}
								onChange={this.onPageSizeChange}
							/>
						</span>
						<span className="config-page-number">
							<span>
								Page
								<Input disabled={true} onChange={_.noop} id="page-number" value={pageNumber + 1} />
								of {Math.ceil(insightsLogsCount / pageSize)}
								<span className="prev" onClick={this.onPrevPage}>
									&lt;
								</span>
								<span className="next" onClick={this.onNextPage}>
									&gt;
								</span>
							</span>
						</span>
					</>
				)}
			</div>
		)
		if (logsLoading) {
			return (
				<>
					<div className={'info-area'}>
						<img className="loading-spinner" src={iconLoading} alt={'loading'} />
						Loading Data logs
					</div>
					{gridFooter}
				</>
			)
		}

		if (logsError) {
			return <div className={'info-area'}>Error fetching data</div>
		}

		if (logs.length === 0) {
			return (
				<div className={'info-area'}>
					<h5 className="logviewer-nodata">There are no results that match your query.</h5>
					<h6 className="logviewer-nodatasub">Please check your entry and try again</h6>
				</div>
			)
		}
		const [vendor, type] = activeInsightsTab.split('.')
		const fields = insightsSchema[vendor].content[type].fields.filter((field) => field.name !== 'severity')

		const columns = _.map(fields, (fieldObj, i) => {
			const col = {
				headerName: fieldObj.displayName,
				field: fieldObj.name,
				colId: fieldObj.name,
				pinned: fieldObj.pinned,
				resizable: false,
				hide: fieldObj.hidden,
				filterable: fieldObj.filterable,
				flex: null,
				width: fieldObj.defaultWidth,
				suppressSizeToFit: true,
				valueFormatter: (params) => {
					if (['true', 'false', 'yes', 'no'].includes(params.value)) {
						return _.capitalize(params.value)
					} else {
						return params.value
					}
				}
			}

			if (col.field === 'metric') {
				col.cellRenderer = 'metricCellRenderer'
				col.cellRendererParams = { metricsMisc }
				col.flex = 1
				col.lockPosition = true
				col.lockVisible = true
			}

			if (col.field === 'hostname') {
				col.cellRenderer = 'hostnameCellRenderer'
				col.cellRendererParams = {
					openDevicePage: this.props.openDevicePage,
					cacheInsightsTab: this.props.cacheInsightsTab,
					activeInsightsTab: this.props.activeInsightsTab
				}
				col.flex = 1
				col.lockPosition = true
				col.lockVisible = true
				col.suppressSizeToFit = false
			}

			if (col.field === 'timegenerated' || col.field === 'date' || col.field === 'updated' || col.field === 'update_date') {
				col.lockPosition = true
				col.lockVisible = true
				col.lockPinned = true
				col.cellRenderer = 'LastUpdatedCellRenderer'
				col.headerComponent = 'LastUpdateHeaderCellRenderer'
			}

			if (fieldObj.defaultWidth && fieldObj.defaultWidth > 0) {
				col.width = fieldObj.defaultWidth
			}

			return col
		})

		return (
			<>
				<div className="outer-grid">
					<MasterDetailGridWidget
						scrollbarWidth={0}
						logType={activeInsightsTab}
						enableSorting={false}
						columnDefs={columns}
						defaultColDef={this.state.defaultColDef}
						masterDetail={true}
						getMainMenuItems={this.getMainMenuItems}
						detailCellRendererParams={{
							metricsMisc,
							openInsightsTab: this.props.openInsightsTab,
							fetchMoreDetailLogsRequest: this.props.fetchMoreDetailLogsRequest,
							setOffset: this.props.setOffset,
							schema: this.props.insightsSchema,
							logType: this.props.activeInsightsTab,
							getDetailRowData: (params) => {
								params.successCallback(params.data.details_data)
							}
						}}
						rowData={logs}
						pinnedBottomRowData={pinnedTopRow >= 0 ? [logs[pinnedTopRow]] : []}
						onGridSizeChanged={this.onGridSizeChanged}
						onGridReady={this.onGridReady}
						onModelUpdated={this.onModelUpdated}
						onBodyScroll={this.onScroll}
						detailCellRenderer={this.state.detailCellRenderer}
						frameworkComponents={this.state.frameworkComponents}
						getRowHeight={this.getRowHeight}
						suppressPropertyNamesCheck={true}
					/>
				</div>
				{gridFooter}
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.insights, ...state.insightsMain, ...state.visibility }
}

const mapDispatchToProps = { ...insightsActions, ...insightsMainActions, ...mainActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(InsightsTable)
