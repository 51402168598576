import * as actionTypes from './actionTypes'
import Constants from '../utils/Constants.json'
import moment from 'moment'

const ACTION_HANDLERS = {
	[actionTypes.INIT_ALERTS]: (state) => {
		return {
			...state
		}
	},
	/*----- Tabs State Reducers -----*/
	[actionTypes.UPDATE_ALERTS_TABS_STATE]: (state, { alertsTabs, statesToUpdate }) => {
		return {
			...state,
			alertsTabs,
			...statesToUpdate
		}
	},
	[actionTypes.ALERTS_INIT_TASK_COMPLETE]: (state, { task }) => {
		// if (task === 'autocomplete' && state.valueSuggestionsLoading) {
		// 	return { ...state }
		// }
		return {
			...state,
			finishedAlertsInitTasks: {
				count: state.finishedAlertsInitTasks.count + 1,
				tasks: [...state.finishedAlertsInitTasks.tasks, task]
			}
		}
	},
	/*----- Active Tab Reducers -----*/
	[actionTypes.UPDATE_ALERTS_ACTIVE_TAB]: (state, { index }) => {
		return {
			...state,
			activeAlertsTab: index
		}
	},
	/*----- PollJob Reducers -----*/
	[actionTypes.POLL_ALERTS_JOB_REQUEST]: (state, { jobId }) => {
		return {
			...state,
			alertsJobId: jobId,
			alertsJobError: null
		}
	},
	[actionTypes.POLL_ALERTS_JOB_FAILURE]: (state, { jobId, error }) => {
		return {
			...state,
			alertsJobId: jobId,
			alertsJobError: error
		}
	},
	/*----- Schema Reducers -----*/
	[actionTypes.SET_ALERTS_SCHEMA]: (state, { schema }) => {
		return {
			...state,
			alertsSchema: schema
		}
	},
	/*----- Refresh Reducers -----*/
	[actionTypes.UPDATE_ALERTS_LAST_REFRESH]: (state, { index }) => {
		if (_.isString(index)) {
			const fixed_tabs = [...state.alertsTabs.fixed_tabs]
			let tabIndex = Constants.FIXED_TABS.indexOf(index)
			fixed_tabs[tabIndex] = { ...fixed_tabs[tabIndex] }
			fixed_tabs[tabIndex].lastRefresh = moment()
			fixed_tabs[tabIndex].isDataStale = false
			return {
				...state,
				alertsTabs: { ...state.alertsTabs, fixed_tabs }
			}
		} else {
			const custom_tabs = [...state.alertsTabs.custom_tabs]
			custom_tabs[index] = { ...custom_tabs[index] }
			custom_tabs[index].lastRefresh = moment()
			custom_tabs[index].isDataStale = false
			return {
				...state,
				alertsTabs: { ...state.alertsTabs, custom_tabs }
			}
		}
	},
	[actionTypes.UPDATE_ALERTS_STALE_STATUS]: (state, { index, isDataStale }) => {
		if (_.isString(index)) {
			const fixed_tabs = [...state.alertsTabs.fixed_tabs]
			let tabIndex = Constants.FIXED_TABS.indexOf(index)
			fixed_tabs[tabIndex] = { ...fixed_tabs[tabIndex] }
			fixed_tabs[tabIndex].isDataStale = isDataStale
			return {
				...state,
				alertsTabs: { ...state.alertsTabs, fixed_tabs }
			}
		} else {
			const custom_tabs = [...state.alertsTabs.custom_tabs]
			custom_tabs[index] = { ...custom_tabs[index] }
			custom_tabs[index].isDataStale = isDataStale
			return {
				...state,
				alertsTabs: { ...state.alertsTabs, custom_tabs }
			}
		}
	},
	[actionTypes.SET_ALERTS_LAST_UPDATE]: (state, { data }) => {
		return {
			...state,
			alertsLastUpdate: data
		}
	}
}

const initialState = {
	activeAlertsTab: null,
	alertsTabs: null,
	finishedAlertsInitTasks: { count: 0, tasks: [] },
	alertsSchema: null,
	alertsJobId: null,
	alertsJobError: null,
	alertsLastUpdate: true
}

export const alertsReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
