import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'ui-lib'
import Util from '../../../../utils/Util'
import Constants from '../../../../utils/Constants.json'
import HighlightedCellRenderer from '../../../../components/CellRenderers/HighlightedCellRenderer'
import ArrayCellRenderer from '../../../../components/CellRenderers/ArrayCellRenderer'
import LastUpdateHeaderCellRenderer from '../../../../../insights/components/CellRenderers/LastUpdatedHeaderCellRenderer/LastUpdateHeaderCellRenderer'
import LastUpdatedCellRenderer from '../../../../../insights/components/CellRenderers/LastUpdatedCellRenderer'
import LinkCellRenderer from '../../../../components/CellRenderers/LinkCellRenderer'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'

class Table extends Component {
	render() {
		const { metric, metricsMisc, chartInfo } = this.props
		const data = _.get(chartInfo, 'data')
		const isLoading = _.get(chartInfo, 'isLoading')
		if (isLoading || !data) {
			return (
				<div className="info-area">
					<img className="loading-spinner" src={iconLoading} alt={'loading'} /> Loading Chart Data
				</div>
			)
		}

		const chartInfoMap = Constants.TABLE_CHART_INFO_MAP
		const misc = metricsMisc[Util.getGenericMetricName(metric)] ? metricsMisc[Util.getGenericMetricName(metric)] : {}
		const colDefConverter = (field) => {
			const col = {
				headerName: field.displayName,
				field: field.name,
				colId: field.name,
				flex: field.flex ? field.flex : null,
				width: field.defaultWidth,
				minWidth: field.minWidth ? field.minWidth : 100,
				suppressMenu: true,
				resizable: true,
				valueFormatter: (params) => {
					if (['true', 'false', 'yes', 'no'].includes(params.value)) {
						return _.capitalize(params.value)
					} else {
						return params.value
					}
				}
			}

			if (col.field === 'id') {
				col.cellRenderer = 'linkCellRenderer'
				col.cellRendererParams = { metric: metric }
			}

			if (field.highlight) {
				col.cellRenderer = 'highlightedCellRenderer'
				col.cellRendererParams = { field: field.name, metric: metric }
			}

			// Value Formatters
			if (col.field === 'timegenerated' || col.field === 'date' || col.field === 'updated' || col.field === 'time') {
				col.cellRenderer = 'LastUpdatedCellRenderer'
				col.headerComponent = 'LastUpdateHeaderCellRenderer'
			}

			if (col.field === 'content_name') {
				col.valueFormatter = () => {
					return misc.title
				}
			}

			if (col.field === 'update_date' || col.field === 'installed_release_date' || col.field === 'latest_release_date') {
				col.valueFormatter = (params) => {
					return moment.utc(params.value, ['YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss']).local().format('DD MMM YYYY [at] HH:mm:ss')
				}
			}

			return col
		}
		const chartInfoCols = chartInfoMap[metric].colDefs.map((field) => colDefConverter(field))
		let supplementalChartInfoCols = null
		if (_.has(chartInfoMap[metric], 'supplementalColDefs')) {
			supplementalChartInfoCols = chartInfoMap[metric].supplementalColDefs.map((field) => colDefConverter(field))
		}
		return (
			<>
				<div className="chart-title">{`${Util.getMetricDisplayName(metricsMisc, metric)} Details`}</div>
				{(metric.startsWith('environmentals') && !('timegenerated' in _.get(data, 'mainData[0]', {}))) || (data.mainData && data.mainData.length === 0) ? null : (
					<div className={'master-detail-grid ag-theme-balham main'} style={{ height: (data.mainData.length + 1) * 28 }}>
						<AgGridReact
							frameworkComponents={{
								highlightedCellRenderer: HighlightedCellRenderer,
								arrayCellRenderer: ArrayCellRenderer,
								linkCellRenderer: LinkCellRenderer,
								LastUpdateHeaderCellRenderer,
								LastUpdatedCellRenderer
							}}
							columnDefs={chartInfoCols}
							rowData={data.mainData}
							suppressCsvExport={true}
							suppressExcelExport={true}
							suppressDragLeaveHidesColumns={true}
							suppressPropertyNamesCheck={true}
						/>
					</div>
				)}

				{data.supplementalData && data.supplementalData.length > 0 ? (
					<div className={'master-detail-grid ag-theme-balham supplemental'} style={{ height: (data.supplementalData.length + 1) * 28 }}>
						<AgGridReact
							frameworkComponents={{ highlightedCellRenderer: HighlightedCellRenderer, arrayCellRenderer: ArrayCellRenderer, LastUpdateHeaderCellRenderer, LastUpdatedCellRenderer }}
							columnDefs={supplementalChartInfoCols}
							rowData={data.supplementalData}
							suppressCsvExport={true}
							suppressExcelExport={true}
							suppressDragLeaveHidesColumns={true}
							suppressPropertyNamesCheck={true}
						/>
					</div>
				) : null}
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.insightsMain, ...state.alertDetails, ...state.alerts }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps, null)(Table)
