import moment from 'moment'
import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of, concat } from 'rxjs'

import * as services from '../../../store/services'
import * as alertsServices from '../../../../alerts/store/services'
import * as actionTypes from './actionTypes'
import * as actions from './actions'

export const fetchDeviceDetailsPageContentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_DEVICE_PAGE_CONTENT),
		switchMap((action) => {
			const { insightsTabs } = store.value.insights
			const currentTab = _.get(insightsTabs, 'fixed_tabs[2]', null)

			const { deviceInfoStartTime, deviceInfoEndTime, deviceInfoTimePreset } = store.value.devicePage
			if (deviceInfoStartTime === null || deviceInfoEndTime === null) {
				const presetDaysMap = { last_7_days: 7, last_30_days: 30, last_90_days: 90 }
				const currentTime = moment()
				const endTime = currentTime.valueOf()
				const startTime = currentTime - presetDaysMap[deviceInfoTimePreset] * 86400000
				return concat(
					of(actions.setDevicePageDesc(currentTab.desc)),
					of(actions.updateDeviceInfoTimeRange(startTime, endTime)),
					of(
						actions.fetchDeviceMetricsRequest(currentTab.desc.device),
						actions.fetchDeviceAlertsRequest(currentTab.desc.device),
						actions.fetchClosedDeviceAlertsRequest(currentTab.desc.device),
						actions.fetchDeviceCommitEventsRequest(currentTab.desc.device),
						actions.fetchDeviceDetailsRequest(currentTab.desc.device)
					)
				)
			} else {
				return concat(
					of(actions.setDevicePageDesc(currentTab.desc)),
					of(
						actions.fetchDeviceMetricsRequest(currentTab.desc.device),
						actions.fetchDeviceAlertsRequest(currentTab.desc.device),
						actions.fetchClosedDeviceAlertsRequest(currentTab.desc.device),
						actions.fetchDeviceCommitEventsRequest(currentTab.desc.device),
						actions.fetchDeviceDetailsRequest(currentTab.desc.device)
					)
				)
			}
		})
	)
}

export const fetchDeviceDetailsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_DEVICE_DETAILS_REQUEST),
		switchMap((action) => {
			return services.getDeviceDetails(store.value, action.deviceId).pipe(
				mergeMap(({ response }) => {
					return of(actions.fetchDeviceDetailsSuccess(response))
				}),
				catchError((error) => of(actions.fetchDeviceDetailsFailure(error)))
			)
		})
	)
}

export const fetchDeviceMetricsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_DEVICE_METRICS_REQUEST),
		switchMap((action) => {
			return services.getDeviceMetrics(store.value, action.deviceId).pipe(
				mergeMap(({ response }) => {
					return of(actions.setDeviceMetricsRequestId(response.jobId), actions.pollDevicePageJobRequest(response.jobId, 'device_metrics', 0))
				}),
				catchError((error) => of(actions.fetchDeviceMetricsFailure(error)))
			)
		})
	)
}

export const fetchDeviceAlertsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_DEVICE_ALERTS_REQUEST),
		switchMap((action) => {
			return services.getDeviceAlerts(store.value, action.deviceId, 'open').pipe(
				mergeMap(({ response }) => {
					return of(actions.fetchDeviceAlertsSuccess(response))
				}),
				catchError((error) => of(actions.fetchDeviceAlertsFailure(error)))
			)
		})
	)
}

export const fetchDeviceCommitEventsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_DEVICE_COMMIT_EVENTS_REQUEST),
		switchMap((action) => {
			return services.getDeviceCommitEvents(store.value, action.deviceId).pipe(
				mergeMap(({ response }) => {
					return of(actions.setDeviceCommitEventsRequestId(response.jobId), actions.pollDevicePageJobRequest(response.jobId, 'commit_events', 0))
				}),
				catchError((error) => of(actions.fetchDeviceCommitEventsFailure(error)))
			)
		})
	)
}

export const updateDeviceInfoTimePresetEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_DEVICE_INFO_TIME_PRESET),
		switchMap((action) => {
			const presetDaysMap = { last_7_days: 7, last_30_days: 30, last_90_days: 90 }
			const currentTime = moment()
			const endTime = currentTime.valueOf()
			const startTime = currentTime - presetDaysMap[action.deviceInfoTimePreset] * 86400000

			return concat(
				of(actions.updateDeviceInfoTimeRange(startTime, endTime)),
				of(
					actions.fetchDeviceAlertsRequest(store.value.devicePage.devicePageDesc.device),
					actions.fetchClosedDeviceAlertsRequest(store.value.devicePage.devicePageDesc.device),
					actions.fetchDeviceCommitEventsRequest(store.value.devicePage.devicePageDesc.device)
				)
			)
		})
	)
}

/*--------- Poll Job ----------*/
export const pollDevicePageJobEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.POLL_DEVICE_PAGE_JOB_REQUEST),
		mergeMap((action) => {
			const pageNumber = action.jobPage
			return services.pollInsightsJob(store.value, action.jobId, pageNumber).pipe(
				mergeMap(({ response }) => {
					if (
						(action.jobType === 'device_metrics' && response.jobId !== store.value.devicePage.deviceMetricsRequestId) ||
						(action.jobType === 'commit_events' && response.jobId !== store.value.devicePage.deviceCommitEventsRequestId)
					) {
						return of(actions.pollDevicePageJobFailure(response.jobId, action.jobType, 'Cancelled'))
					}
					// If it's still running and job is valid, keep polling
					else if (response.state === 'RUNNING') {
						return of(actions.pollDevicePageJobRequest(response.jobId, action.jobType, action.jobPage, action.jobLimit, action.params))
					}
					// If it's done, handle response accroding to action type
					else {
						if (action.jobType === 'device_metrics') {
							return of(actions.fetchDeviceMetricsSuccess(response.page.result.data))
						} else if (action.jobType === 'commit_events') {
							return of(actions.fetchDeviceCommitEventsSuccess(response.page.result.data))
						}
					}
				}),
				catchError((error) => {
					if (action.jobType === 'device_metrics') {
						return of(actions.fetchDeviceMetricsFailure(error))
					} else if (action.jobType === 'commit_events') {
						return of(actions.fetchDeviceCommitEventsFailure(error))
					}
				})
			)
		})
	)
}

/*--------- Device Alerts Related -----------*/
export const fetchClosedDeviceAlertsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_CLOSED_DEVICE_ALERTS_REQUEST),
		switchMap((action) => {
			return services.getDeviceAlerts(store.value, action.deviceId, 'closed').pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response)) {
							// 1. Response is empty
							return of(actions.fetchClosedDeviceAlertsSuccess([]))
						} else {
							// 2. Response is not empty
							return of(actions.fetchClosedDeviceAlertsSuccess(response.response))
						}
					} else {
						throw 'Error Fetching Alerts'
					}
				}),
				catchError((error) => {
					return of(actions.fetchClosedDeviceAlertsFailure(error))
				})
			)
		})
	)
}

export const updateDeviceAlertEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_DEVICE_ALERT_REQUEST),
		switchMap((action) => {
			return alertsServices.updateAlert(store.value, action.alertId, action.status, action.assignee).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							throw 'Error Updating Alert'
						} else {
							// 2. Response is not empty}
							return concat(of(actions.updateDeviceAlertSuccess(response.response, action.alertId)), of(actions.fetchDeviceAlertActivitiesRequest('', action.alertId, action.alertId)))
						}
					} else {
						throw 'Error Updating Alert'
					}
				}),
				catchError((error) => {
					return of(actions.updateDeviceAlertFailure(error))
				})
			)
		})
	)
}

export const fetchDeviceAlertCommentsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_DEVICE_ALERT_COMMENTS_REQUEST),
		switchMap((action) => {
			return alertsServices.getComments(store.value, action.alertId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							return of(actions.fetchDeviceAlertCommentsSuccess([], action.alertId))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchDeviceAlertCommentsSuccess(response.response, action.alertId))
						}
					} else {
						throw 'Error Fetching Comments'
					}
				}),
				catchError((error) => {
					return of(actions.fetchDeviceAlertCommentsFailure(error))
				})
			)
		})
	)
}

export const addDeviceAlertCommentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.ADD_DEVICE_ALERT_COMMENT_REQUEST),
		switchMap((action) => {
			return alertsServices.addComment(store.value, action.alertId, action.comment).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							return of(actions.addDeviceAlertCommentFailure('Error Saving Comment'))
						} else {
							// 2. Response is not empty}
							return concat(
								of(actions.addDeviceAlertCommentSuccess(response.response, action.alertId)),
								of(actions.fetchDeviceAlertActivitiesRequest('', action.alertId, action.alertId))
							)
						}
					} else {
						throw 'Error Saving Comments'
					}
				}),
				catchError((error) => {
					return of(actions.addDeviceAlertCommentSuccess(error))
				})
			)
		})
	)
}

export const updateDeviceAlertCommentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_DEVICE_ALERT_COMMENT_REQUEST),
		switchMap((action) => {
			return alertsServices.updateComment(store.value, action.alertId, action.commentId, action.comment).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							throw 'Error Updating Comments'
						} else {
							// 2. Response is not empty}
							return concat(
								of(actions.updateDeviceAlertCommentSuccess(response.response, action.alertId, action.commentId)),
								of(actions.fetchDeviceAlertActivitiesRequest('', action.alertId, action.alertId))
							)
						}
					} else {
						throw 'Error Updating Comments'
					}
				}),
				catchError((error) => {
					return of(actions.updateDeviceAlertCommentFailure(error))
				})
			)
		})
	)
}

export const removeDeviceAlertCommentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.REMOVE_DEVICE_ALERT_COMMENT_REQUEST),
		switchMap((action) => {
			return alertsServices.removeComment(store.value, action.alertId, action.commentId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return concat(of(actions.removeDeviceAlertCommentSuccess(action.commentId, action.alertId)), of(actions.fetchDeviceAlertActivitiesRequest('', action.alertId, action.alertId)))
					} else {
						throw 'Error Fetching Comments'
					}
				}),
				catchError((error) => {
					return of(actions.removeDeviceAlertCommentFailure(error))
				})
			)
		})
	)
}

export const fetchDeviceAlertEventsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_DEVICE_ALERT_EVENTS_REQUEST),
		switchMap((action) => {
			return alertsServices.getEvents(store.value, action.alertId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response)) {
							// 1. Response is empty
							return of(actions.fetchDeviceAlertEventsSuccess([], action.alertId))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchDeviceAlertEventsSuccess(response.response, action.alertId))
						}
					} else {
						throw 'Error Fetching Events'
					}
				}),
				catchError((error) => {
					return of(actions.fetchDeviceAlertEventsFailure(error))
				})
			)
		})
	)
}

export const fetchDeviceAlertActivitiesEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_DEVICE_ALERT_ACTIVITIES_REQUEST),
		switchMap((action) => {
			return alertsServices.getActivities(store.value, action.subject, action.object_id).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response)) {
							// 1. Response is empty
							return of(actions.fetchDeviceAlertActivitiesSuccess([], action.alertId))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchDeviceAlertActivitiesSuccess(response.response, action.alertId))
						}
					} else {
						throw 'Error Fetching Activities'
					}
				}),
				catchError((error) => {
					return of(actions.fetchDeviceAlertActivitiesFailure(error))
				})
			)
		})
	)
}
