import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of, forkJoin, concat } from 'rxjs'
import * as actionTypes from './actionTypes'
import * as services from './services'
import * as actions from './actions'
import * as mainActions from '../../main/actions'

import Constants from '../util/Constants.json'
import Util from '../util/Util'

export const initAppEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.INIT_APP),
		switchMap(() => {
			const { tenant, loggedInUser } = store.value.main
			const activeAppPref = _.get(store.value.main, 'loggedInUser.preference.activeApp')
			const tenantId = Util.extractTenantUser(tenant, loggedInUser).lstenant
			const cspId = Util.extractTenantUser(tenant, loggedInUser).account
			const instanceId = _.get(loggedInUser, 'currentInstanceId', null)
			const visibilityInitTasks = [actions.fetchMetricsMiscRequest(), actions.fetchAlertsMiscRequest()]
			if (Constants.SUB_APPS.includes(activeAppPref)) {
				visibilityInitTasks.push(actions.updateActiveApp(activeAppPref))
			} else {
				const userPref = _.get(store.value.main.loggedInUser, 'preference') ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
				_.set(userPref, 'activeApp', 'insights')
				visibilityInitTasks.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}
			return concat(
				of(actions.setTenantId(tenantId), actions.setCSPId(cspId), actions.setInstanceId(instanceId)),
				of(...visibilityInitTasks),
				// Initialize sub apps
				of(actions.initInsights(), actions.initAlerts())
			)
		})
	)
}

/*--------- Update Active App ----------*/
export const updateActiveAppEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_ACTIVE_APP),
		mergeMap((action) => {
			const userPref = _.get(store.value.main.loggedInUser, 'preference') ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const activeAppPref = _.get(store.value.main, 'loggedInUser.preference.activeApp')
			if (activeAppPref !== action.activeApp) {
				_.set(userPref, 'activeApp', action.activeApp)
				return of(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			} else {
				return of()
			}
		})
	)
}

/*--------- Fetch Metric Misc ----------*/
export const fetchMetricsMiscEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_METRICS_MISC_REQUEST),
		switchMap((action) => {
			return services.getMetricsMisc(store.value.visibility).pipe(
				mergeMap(({ response }) => {
					return of(actions.fetchMetricsMiscSuccess(response))
				}),
				catchError((error) => {
					return of(actions.fetchMetricsMiscFailure(error))
				})
			)
		})
	)
}

/*--------- Fetch Alert Misc ----------*/
export const fetchAlertsMiscEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_METRICS_MISC_REQUEST),
		switchMap((action) => {
			return services.getAlertsMisc(store.value.visibility).pipe(
				mergeMap(({ response }) => {
					return of(actions.fetchAlertsMiscSuccess(response))
				}),
				catchError((error) => {
					return of(actions.fetchAlertsMiscFailure(error))
				})
			)
		})
	)
}
