import React, { Component } from 'react'
import { connect } from 'ui-lib'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import moment from 'moment-timezone'

import './Timeline.scss'

class Timeline extends Component {
	shouldComponentUpdate(nextProps) {
		if (
			nextProps.deviceCommitEvents !== this.props.deviceCommitEvents ||
			nextProps.deviceAlerts !== this.props.deviceAlerts ||
			nextProps.deviceInfoStartTime !== this.props.deviceInfoStartTime ||
			nextProps.deviceInfoEndTime != this.props.deviceInfoEndTime ||
			nextProps.isNavOpen !== this.props.isNavOpen
		) {
			return true
		} else {
			return false
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.isNavOpen !== this.props.isNavOpen) {
			setTimeout(() => {
				// To trigger resize of charts after container size change, 300 is the animation delay
				window.dispatchEvent(new Event('resize'))
			}, 500)
		}
	}
	render() {
		const { deviceInfoTimePreset, deviceAlerts, deviceInfoStartTime, deviceInfoEndTime, deviceCommitEvents, deviceCommitEventsLoading, deviceCommitEventsError } = this.props
		const presetMap = { last_7_days: 7, last_30_days: 30, last_90_days: 90 }
		const criticalCountByDay = Array(presetMap[deviceInfoTimePreset]).fill(0)
		const criticalAlertsByDay = Array(presetMap[deviceInfoTimePreset]).fill(null)
		const warningCountByDay = Array(presetMap[deviceInfoTimePreset]).fill(0)
		const warningAlertsByDay = Array(presetMap[deviceInfoTimePreset]).fill(null)
		const commitEventsCountByDay = Array(presetMap[deviceInfoTimePreset]).fill(0)
		const commitEventsByDay = Array(presetMap[deviceInfoTimePreset]).fill(null)
		if (!_.isEmpty(deviceAlerts)) {
			deviceAlerts.forEach((alert) => {
				if (alert.create_time * 1000 >= deviceInfoStartTime && alert.create_time * 1000 <= deviceInfoEndTime) {
					const index = Math.floor((alert.create_time * 1000 - deviceInfoStartTime) / 86400000)
					if (alert.severity === 'critical') {
						criticalCountByDay[index] += 1
						if (criticalAlertsByDay[index] === null) {
							criticalAlertsByDay[index] = [alert]
						} else {
							criticalAlertsByDay[index].push(alert)
						}
					}
					if (alert.severity === 'warning') {
						warningCountByDay[index] += 1
						if (warningAlertsByDay[index] === null) {
							warningAlertsByDay[index] = [alert]
						} else {
							warningAlertsByDay[index].push(alert)
						}
					}
				}
			})
		}

		if (!_.isEmpty(deviceCommitEvents)) {
			deviceCommitEvents.forEach((commitEvent) => {
				if (commitEvent.timegenerated >= deviceInfoStartTime * 1000 && commitEvent.timegenerated <= deviceInfoEndTime * 1000) {
					const index = Math.floor((commitEvent.timegenerated - deviceInfoStartTime * 1000) / 86400000000)
					commitEventsCountByDay[index] += 1
					if (commitEventsByDay[index] === null) {
						commitEventsByDay[index] = [commitEvent]
					} else {
						commitEventsByDay[index].push(commitEvent)
					}
				}
			})
		}
		const options = {
			title: { text: null },
			chart: {
				type: 'column',
				height: 220,
				zIndex: 2,
				backgroundColor: 'transparent',
				marginTop: 50,
				marginLeft: 50
			},
			credits: {
				enabled: false
			},
			colors: ['#D13C3C', '#FFD745'],
			xAxis: {
				labels: { enabled: false },
				tickInterval: 1,
				tickLength: 4,
				tickAmount: presetMap[deviceInfoTimePreset],
				min: 0,
				max: presetMap[deviceInfoTimePreset] - 1
			},
			yAxis: {
				lineWidth: 0,
				lineColor: 'transparent',
				title: { text: null },
				gridLineDashStyle: 'longdash',
				labels: {
					enabled: true
				},
				min: 0,
				labels: {
					x: -10,
					style: {
						fontSize: 10,
						fontWeight: 400
					}
				}
			},
			legend: {
				useHTML: true,
				width: 200,
				align: 'right',
				verticalAlign: 'middle',
				backgroundColor: 'transparent',
				layout: 'vertical',
				symbolHeight: 0,
				symbolWidth: 0,
				symbolRadius: 0,
				squareSymbol: false,
				itemMarginBottom: 15,
				itemStyle: {
					textOverflow: 'clip',
					fontSize: 12,
					fontWeight: 400
				},
				itemHoverStyle: {
					cursor: 'default'
				},
				labelFormatter: function () {
					let color
					let text
					if (this.name === 'Helper') return null
					if (this.name === 'Commit Events') {
						return `
						<div class='legend-item'>
						<svg width="14" height="14" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="5.5" cy="5.5" r="4.5" fill="transparent" stroke="#38A5FF" stroke-width="2"/>
						</svg>
						<span class='legend-text'>Commit Events</span>
						</div>
						`
					}
					if (this.name === 'Critical Alerts') {
						color = '#D13C3C'
						text = 'Critical Alerts'
					}
					if (this.name === 'Warning Alerts') {
						color = '#FFD745'
						text = 'Warning Alerts'
					}

					const legendText = `<span class='legend-text'>${text}</span>`

					return `
					<div class='legend-item'>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="${color}" xmlns="http://www.w3.org/2000/svg">
						<circle cx="7" cy="7" r="7" fill="${color}"/>
					</svg>
					${legendText}
					</div>
					`
				}
			},
			tooltip: {
				shared: true,
				formatter: function () {
					if (_.get(this, 'series.name') === 'Commit Events') {
						if (commitEventsCountByDay[this.x] > 0) {
							const content = (commitEventsByDay[this.x] || [])
								.map(
									(commitEvent) =>
										`<div class='commit'><div class='command'>${_.startCase(commitEvent.commit_command)}<span class='user'> | Committed by ${_.startCase(
											commitEvent.commit_user
										)}</span></div><div class='time'>${moment(commitEvent.timegenerated / 1000)
											.tz(moment.tz.guess())
											.format('MMM Do, YYYY | HH:mm A z')}</div></div>`
								)
								.join('')
							return `<div class='commits-tooltip'>${content}</div>`
						} else {
							return null
						}
					} else {
						if (criticalCountByDay[this.x] > 0 || warningCountByDay[this.x] > 0) {
							const content = [...(criticalAlertsByDay[this.x] || []), ...(warningAlertsByDay[this.x] || [])]
								.map(
									(alert) =>
										`<div class='alert'><div class='${`icon-${alert.severity}`}'></div><div class='data'><div class='name'>${alert.alert_name}</div><div class='time'>${moment(
											alert.time * 1000
										)
											.tz(moment.tz.guess())
											.format('MMM Do, YYYY | HH:mm A z')}</div></div></div>`
								)
								.join('')
							return `<div class='alerts-tooltip'>${content}</div>`
						} else {
							return null
						}
					}
				},
				useHTML: true,
				borderWidth: 0,
				backgroundColor: 'none',
				shadow: false,
				style: {
					fontWeight: 300,
					maxHeight: 100
				}
			},
			plotOptions: {
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: false
					},
					states: {
						inactive: {
							enabled: false
						}
					}
				},
				series: {
					stickyTracking: true,
					events: {
						legendItemClick: function () {
							return false
						}
					}
				}
			},
			series: [
				{
					name: 'Critical Alerts',
					data: criticalCountByDay.map((count, index) => ({ x: index, y: count })).filter((data) => data.y > 0),
					point: {
						events: {
							mouseOver: function (e) {}
						}
					}
				},
				{
					name: 'Warning Alerts',
					data: warningCountByDay.map((count, index) => ({ x: index, y: count })).filter((data) => data.y > 0),
					point: {
						events: {
							mouseOver: function (e) {}
						}
					}
				},
				{
					data: commitEventsCountByDay
						.map((data, index) => [index, data])
						.filter((data) => data[1] > 0)
						.map((data) => [data[0], 0]),
					name: 'Commit Events',
					type: 'scatter',
					lineColor: 'transparent',
					fillOpacity: 1,
					lineWidth: 0,
					marker: {
						enabled: true,
						radius: 6,
						symbol: 'circle',
						fillColor: 'transparent',
						lineWidth: 2,
						lineColor: '#38A5FF',
						states: {
							hover: {
								enabled: false
							}
						}
					},
					states: {
						inactive: {
							enabled: false
						},
						hover: {
							lineWidthPlus: 0
						}
					}
				}
			]
		}
		return (
			<div className="timeline">
				<HighchartsReact highcharts={Highcharts} options={options} />
				<div className="legend-bg" />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.devicePage }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps, null)(Timeline)
