import React from 'react'
import _ from 'lodash'

export default class CellRenderer extends React.Component {
	render() {
		const { data } = this.props
		let disabledContent = null
		if (data.pause_start_time > 0 || data.pause_end_time > 0) {
			disabledContent = <span style={{ fontWeight: 'bold' }}>{`(Disabled) `}</span>
		}
		return (
			<div className="rule-name-cell">
				{disabledContent}
				<span
					className={`link rule-name ${disabledContent ? ' link-disabled' : ' '}`}
					onClick={() => {
						this.props.setEditingRule(data)
					}}
				>
					{_.startCase(data.name)}
				</span>
			</div>
		)
	}
}
