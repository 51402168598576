import * as actionTypes from './actionTypes'

const ACTION_HANDLERS = {
	[actionTypes.UPDATE_NAV_OPEN_STATE]: (state, { isNavOpen }) => {
		return {
			...state,
			isNavOpen
		}
	},
	[actionTypes.UPDATE_ACTIVE_APP]: (state, { activeApp }) => {
		if (_.isEqual(activeApp, state.activeApp)) return { ...state }
		return {
			...state,
			activeApp
		}
	},
	/*----- Tenant Info Reducers -----*/
	[actionTypes.SET_TENANT_ID]: (state, { tenantId }) => {
		return {
			...state,
			tenantId
		}
	},
	[actionTypes.SET_CSP_ID]: (state, { cspId }) => {
		return {
			...state,
			cspId
		}
	},
	[actionTypes.SET_INSTANCE_ID]: (state, { instanceId }) => {
		return {
			...state,
			instanceId
		}
	},
	/*----- Notification Reducers -----*/
	[actionTypes.ADD_NOTIFICATION]: (state, { notification }) => {
		return {
			...state,
			notifications: [...state.notifications, notification]
		}
	},
	[actionTypes.REMOVE_NOTIFICATION]: (state) => {
		const updatedNotifications = [...state.notifications]
		updatedNotifications.pop()
		return {
			...state,
			notifications: updatedNotifications
		}
	},
	/*----- Metrics Misc Reducers -----*/
	[actionTypes.FETCH_METRICS_MISC_REQUEST]: (state) => {
		return {
			...state,
			metricsMisc: null,
			metricsMiscLoading: true,
			metricsMiscError: null
		}
	},
	[actionTypes.FETCH_METRICS_MISC_SUCCESS]: (state, { metricsMisc }) => {
		let misc = {}
		metricsMisc.forEach((metric) => {
			if (!(metric.metric_name in misc)) {
				misc[metric.metric_name] = metric
			}
		})
		return {
			...state,
			metricsMisc: misc,
			metricsMiscLoading: false,
			metricsMiscError: null
		}
	},
	[actionTypes.FETCH_METRICS_MISC_FAILURE]: (state, { error }) => {
		return {
			...state,
			metricsMisc: null,
			metricsMiscLoading: false,
			metricsMiscError: error
		}
	},
	/*----- Alerts Misc Reducers -----*/
	[actionTypes.FETCH_ALERTS_MISC_REQUEST]: (state) => {
		return {
			...state,
			alertsMisc: null,
			alertsMiscLoading: true,
			alertsMiscError: null
		}
	},
	[actionTypes.FETCH_ALERTS_MISC_SUCCESS]: (state, { alertsMisc }) => {
		let misc = {}
		alertsMisc.forEach((alert) => {
			if (!(alert.alert_type_id in misc)) {
				misc[alert.alert_type_id] = alert
			}
		})
		return {
			...state,
			alertsMisc: misc,
			alertsMiscLoading: false,
			alertsMiscError: null
		}
	},
	[actionTypes.FETCH_ALERTS_MISC_FAILURE]: (state, { error }) => {
		return {
			...state,
			alertsMisc: null,
			alertsMiscLoading: false,
			alertsMiscError: error
		}
	}
}

const initialState = {
	activeApp: 'insights',
	notifications: [],
	isNavOpen: true,
	tenantId: null,
	cspId: null,
	instanceId: null,
	metricsMisc: null,
	metricsMiscLoading: true,
	metricsMiscError: null,
	alertsMisc: null,
	alertsMiscLoading: true,
	alertsMiscError: null
}

export const visibilityReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
