import React, { Component } from 'react'
import _ from 'lodash'
import './AlertsNav.scss'

export default class AlertsNav extends Component {
	state = {
		activeItem: 0
	}

	static getDerivedStateFromProps(nextProps) {
		const index = _.findIndex(nextProps.items, (item) => item.index === nextProps.activeAlertsTab)
		if (index >= 0) {
			return { activeItem: index }
		} else {
			return {}
		}
	}

	render() {
		const { items } = this.props
		return (
			<div className="alerts-nav">
				{items.map((item, index) => (
					<div
						key={index}
						className={`alerts-nav-item ${this.state.activeItem === index ? 'active' : ''}`}
						onClick={() => {
							this.setState({ activeItem: index })
							item.onItemClicked()
						}}
					>
						{item.name}
					</div>
				))}
				<div className="alerts-nav-filler"></div>
			</div>
		)
	}
}
