import React from 'react'
import 'antd/lib/select/style/css'
import Condition from './Condition'
import iconAdd from '../../../../../../images/icon-add.svg'

export default class ConditionBuilder extends React.Component {
	mergeUpdate = (index, update) => {
		const updatedConditions = [...this.props.ruleState.conditions]
		updatedConditions[index] = { ...updatedConditions[index], ...update }
		this.props.setRuleState({ conditions: updatedConditions })
	}

	render() {
		return (
			<div className="condition-builder">
				{this.props.ruleState.conditions.map((condition, index) => (
					<>
						<Condition
							onChange={(update) => {
								this.mergeUpdate(index, update)
							}}
							onRemove={() => {
								const updatedConditions = [...this.props.ruleState.conditions]
								_.pullAt(updatedConditions, index)
								this.props.setRuleState({ conditions: updatedConditions })
							}}
							k={condition.key}
							op={condition.op}
							v={condition.value}
							isLast={index === this.props.ruleState.conditions.length - 1}
						/>
					</>
				))}
				<div className="add-condition">
					<img
						src={iconAdd}
						onClick={() => {
							this.props.setRuleState({ conditions: [...this.props.ruleState.conditions, { key: 'severity', op: '==', value: 'critical' }] })
						}}
						alt="add icon"
						className="add-icon"
					/>
					<span className="text">Add New Condition</span>
				</div>
			</div>
		)
	}
}
