import React from 'react'

export default class DefaultDefs extends React.Component {
	render() {
		return (
			<React.Fragment>
				<g id="expanded-hexagon-red" filter="url(#expanded-hexagon-drop-shadow)">
					<path d="M4 79L46.5846 4H134.415L177 79L134.415 154H46.5846L4 79Z" fill="#ffffff" />
					<path d="M4 79L15.113652 56H163.22667L177 79L134.415 154H46.5846L4 79Z" fill="#fbf1f2" />
					<path d="M47.1668 153L5.14995 79L47.1668 5H133.833L175.85 79L133.833 153H47.1668Z" fill="none" stroke="#C83A4B" strokeWidth="3" />
				</g>
				<g id="expanded-hexagon-yellow" filter="url(#expanded-hexagon-drop-shadow)">
					<path d="M4 79L46.5846 4H134.415L177 79L134.415 154H46.5846L4 79Z" fill="#FFFFFF" />
					<path d="M4 79L15.113652 56H163.22667L177 79L134.415 154H46.5846L4 79Z" fill="#fff6df" />
					<path d="M47.1668 153L5.14995 79L47.1668 5H133.833L175.85 79L133.833 153H47.1668Z" fill="none" stroke="#FFD745" strokeWidth="3" />
				</g>
				<g id="expanded-hexagon-blue" filter="url(#expanded-hexagon-drop-shadow)">
					<path d="M4 79L46.5846 4H134.415L177 79L134.415 154H46.5846L4 79Z" fill="#FFFFFF" />
					<path d="M4 79L15.113652 56H163.22667L177 79L134.415 154H46.5846L4 79Z" fill="#ecf6fe" />
					<path d="M47.1668 153L5.14995 79L47.1668 5H133.833L175.85 79L133.833 153H47.1668Z" fill="none" stroke="#42A5ED" strokeWidth="3" />
				</g>
				<g id="expanded-hexagon-green" filter="url(#expanded-hexagon-drop-shadow)">
					<path d="M4 79L46.5846 4H134.415L177 79L134.415 154H46.5846L4 79Z" fill="#FFFFFF" />
					<path d="M4 79L15.113652 56H163.22667L177 79L134.415 154H46.5846L4 79Z" fill="#eef7ee" />
					<path d="M47.1668 153L5.14995 79L47.1668 5H133.833L175.85 79L133.833 153H47.1668Z" fill="none" stroke="#1DB846" strokeWidth="3" />
				</g>
				<filter id="expanded-hexagon-drop-shadow" x="0" y="0" width="200" height="200" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="0" />
					<feGaussianBlur stdDeviation="2" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
			</React.Fragment>
		)
	}
}
