import React, { Component } from 'react'
import _ from 'lodash'
import './InsightsNav.scss'

export default class InsightsNav extends Component {
	state = {
		activeItem: 0
	}

	static getDerivedStateFromProps(nextProps) {
		const index = _.findIndex(nextProps.items, (item) => item.index === nextProps.activeInsightsTab)
		if (index >= 0) {
			return { activeItem: index }
		}
	}

	render() {
		const { items } = this.props
		return (
			<div className="insights-nav">
				{items.map((item, index) => (
					<div
						key={index}
						className={`insights-nav-item ${this.state.activeItem === index ? 'active' : ''}`}
						onClick={() => {
							this.setState({ activeItem: index })
							item.onItemClicked()
						}}
					>
						{item.name}
					</div>
				))}
				<div className="insights-nav-filler"></div>
			</div>
		)
	}
}
