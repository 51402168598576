import React from 'react';
import { PanoramaSelectedSvg } from './svg-components/PanoramaSelectedSvg';
import { PanoramaSvg } from './svg-components/PanoramaSvg';
import { LogCollectorSvg } from './svg-components/LogCollectorSvg';
import { FirewallSelectedSvg } from './svg-components/FirewallSelectedSvg';
import { FirewallSvg } from './svg-components/FirewallSvg';
import { ServicesSvg } from './svg-components/ServicesSvg';
import { LoggingSvg } from './svg-components/LoggingSvg';

const svgMap = {
    PANORAMA: { selected: PanoramaSelectedSvg, base: PanoramaSvg },
    LC: { selected: LogCollectorSvg, base: LogCollectorSvg },
    NGFW: { selected: FirewallSelectedSvg, base: FirewallSvg },
    default: { selected: FirewallSelectedSvg, base: FirewallSvg }
};

const types = {
    'PANORAMA': 'Panorama',
    'LC': 'Log Collector',
    'NGFW': 'Firewall',
};

export const DefaultFontColor = '#333333';
export const HealthyColor = '#1DB846';
export const UnhealthyColor = '#D13C3C';
export const NeutralColor = '#5D6A7A';
export const LinkColor = '#006FCC';

const offsetFromTop = 84;
const nodeDistance = 200;

window.device_graph = {
    showLogging: false,
    showManagedLogCollectors: false,
};

const validateDevice = device => device && device.hostname && device.device_id

export const getDevice = device => {
    const svgs = svgMap[device.device_type] || svgMap.default;
    return {
        x: 0, y: 0,
        type: 'device',
        show: true,
        selected: true,
        title: device.hostname,
        color: NeutralColor,
        lines: [
            types[device.device_type],
        ],
        svg: svgs.selected,
        textOnTop: true,
    };
};

export const getServices = (device, onClick) => {
    const lines = [
        device.threat_version ? 'Telemetry' : undefined,
        device.wildfire_version ? 'WildFire' : undefined,
        device.app_version ? 'Apps and Threats' : undefined,
        device.av_version ? 'Antivirus' : undefined,
    ].filter(s => s);
    return {
        x: 0, y: 0,
        type: 'services',
        show: lines.length > 0,
        title: 'SERVICES',
        titleColor: LinkColor,
        color: NeutralColor,
        lines,
        svg: ServicesSvg,
        onClick: () => onClick(device),
    };
};

export const getLogging = (device, onClick) => {
    const { log_collector_groups } = device;
    const logCollectorCount = log_collector_groups?.reduce((sum, lcg) => sum + lcg.lc_records?.length, 0) || 0;
    return {
        x: 0, y: 0,
        type: 'logging',
        show: window.device_graph?.showLogging && log_collector_groups?.length > 0,
        title: `LOGGING SERVICE`,
        titleColor: LinkColor,
        color: NeutralColor,
        lines: [
            <tspan fontWeight={'bold'}>On-Premise Logging</tspan>,
            `Log Collectors (${logCollectorCount})`,
        ],
        svg: LoggingSvg,
        onClick: () => onClick(device),
    };
};

export const getManagedFirewalls = (device, onClick) => {
    return {
        x: 0, y: 0,
        type: 'firewalls',
        show: device.managed_firewalls?.length > 0,
        title: `MANAGED FIREWALLS (${device.managed_firewalls?.length})`,
        titleColor: LinkColor,
        color: NeutralColor,
        lines: [],
        svg: FirewallSvg,
        onClick: () => onClick(device),
    };
};

/**
 * We may show this in the future, depending on PM.
 */
export const getManagedLogConnectors = (device, onClick) => {
    return {
        x: 0, y: 0,
        type: 'log_collector',
        show: window.device_graph?.showManagedLogCollectors && device.managed_log_collectors?.length > 0,
        title: [
            'MANAGED',
            `LOG CONNECTORS (${device.managed_log_collectors?.length})`,
        ],
        titleColor: LinkColor,
        color: NeutralColor,
        lines: [],
        svg: FirewallSvg,
        onClick: () => onClick(device),
    };
};

export const getConnectedFirewalls = (device, onClick) => {
    const titlePrefix = device.device_type === 'LC'
        ? 'LOGGING FIREWALLS' : 'CONNECTED FIREWALLS';
    return {
        x: 0, y: 0,
        type: 'firewalls',
        show: device.connected_firewalls?.length > 0,
        title: `${titlePrefix} (${device.connected_firewalls?.length})`,
        titleColor: LinkColor,
        color: NeutralColor,
        lines: [],
        svg: FirewallSvg,
        onClick: () => onClick(device),
    };
};

export const getManagingPanorama = (device, onClick) => {
    return {
        x: 0, y: 0,
        type: 'panorama',
        show: validateDevice(device.managing_panorama) && device.managing_panorama.device_id !== device.device_id,
        title: device.managing_panorama?.hostname,
        titleColor: LinkColor,
        color: NeutralColor,
        lines: ['(Managing Panorama)'],
        svg: PanoramaSvg,
        onClick: () => onClick(device.managing_panorama),
    };
};

export const calculatePositions = (center, nodes) => {
    nodes = nodes.filter(node => node?.show);
    const upperTypes = ['device', 'logging', 'services'];
    const lowers = nodes.filter(node => !upperTypes.includes(node.type));
    const lowersOffset = nodeDistance * ((lowers.length - 1) / 2);
    for (let i = 0; i < lowers.length; i++) {
        lowers[i].x = -lowersOffset + nodeDistance * i;
        lowers[i].y = offsetFromTop;
    }
    const loggingNode = nodes.find(node => node.type === 'logging');
    if (loggingNode) {
        loggingNode.x = -(lowersOffset + nodeDistance);
    }
    const servicesNode = nodes.find(node => node.type === 'services');
    if (servicesNode) {
        servicesNode.x = +(lowersOffset + nodeDistance);
    }

    let min = 0;
    let max = 0;
    for (const node of nodes) {
        min = Math.min(node.x, min);
        max = Math.max(node.x, max);
    }
    // Apply Default Offset
    for (const node of nodes) {
        node.x += center.x - (min + max) / 2;
        node.y += offsetFromTop;
    }
};

export const getEdges = (from, toList) => {
    return toList
        .filter(to => to?.show)
        .map((to, key) => (
            <line key={key}
                  x1={from.x} y1={from.y}
                  x2={to.x} y2={to.y}
                  strokeWidth={2}
                  opacity={.5}
                  stroke={to.color}/>
        ));
};