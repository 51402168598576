import React, { Component } from 'react'
import { connect } from 'ui-lib'
import Table from './Table'
import './TableChart.scss'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'

class TableChart extends Component {
	render() {
		const { alertTableChartsDataLoading, alertTableChartsData, alert } = this.props
		if (alertTableChartsDataLoading) {
			return (
				<div className="table-chart">
					<div className="info-area">
						<img className="loading-spinner" src={iconLoading} alt={'loading'} /> Loading Charts
					</div>
				</div>
			)
		}
		const charts = alert[0].metrics.map((metric, index) => {
			return (
				<div className="chart" key={index}>
					<Table chartInfo={alertTableChartsData[metric]} metric={metric} />
				</div>
			)
		})
		return <div className="table-chart">{charts}</div>
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.alertDetails, ...state.alerts }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps, null)(TableChart)
