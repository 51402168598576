import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of, concat, forkJoin } from 'rxjs'
import { TimeRangeSelectComponent } from 'ui-lib'
import _ from 'lodash'

import Constants from '../../../utils/Constants.json'
import Util from '../../../utils/Util'

import * as services from '../../../store/services'
import * as actionTypes from './actionTypes'
import * as actions from './actions'
import * as alertsActions from '../../../store/actions'
import * as mainActions from '../../../../../main/actions'

import pageInfo from '../../pageUtil'

const getRange = TimeRangeSelectComponent.getRange

export const fetchAlertsMainPageContentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERTS_MAIN_PAGE_CONTENT, actionTypes.REFRESH_ALERTS_TAB),
		switchMap((action) => {
			if (action.type === actionTypes.REFRESH_ALERTS_TAB && action.pageType !== pageInfo.AlertsMain.pageType) {
				return of()
			} else if (action.type === actionTypes.REFRESH_ALERTS_TAB && action.pageType === pageInfo.AlertsMain.pageType) {
				const stateActionList = []
				const { activeAlertsTab } = store.value.alerts
				const alertsTimePreset = store.value.alertsMain.alertsTimePreset
				if (alertsTimePreset !== 'custom') stateActionList.push(actions.updateAlertsTimeRange(getRange(alertsTimePreset).timeval()))
				const dataActionList = []
				dataActionList.push(actions.fetchAlertsRequest())
				return concat(of(alertsActions.resetStore('AlertsMain')), of(alertsActions.updateAlertsLastRefresh(activeAlertsTab)), of(...stateActionList), of(...dataActionList))
			}

			const { alertsTabs, activeAlertsTab } = store.value.alerts
			const currentTab = alertsTabs.fixed_tabs[Constants.FIXED_TABS.indexOf(activeAlertsTab)]
			const { state: cachedState, data: cachedData } = currentTab
			// 1. Restore State
			const stateActionList = []
			if (!_.isEmpty(cachedState)) {
				const { alertsTimePreset, alertsTimeRange } = cachedState
				if (alertsTimePreset && alertsTimeRange) {
					if (alertsTimePreset !== 'custom') {
						stateActionList.push(actions.setAlertsTimePreset(alertsTimePreset), actions.setAlertsTimeRange(getRange(alertsTimePreset).timeval()))
					} else {
						stateActionList.push(actions.setAlertsTimePreset(alertsTimePreset), actions.setAlertsTimeRange(alertsTimeRange))
					}
				}
			} else {
				stateActionList.push(actions.updateAlertsTimePreset(Constants.DEFAULT_ALERTS_TIME_PRESET), actions.updateAlertsTimeRange(getRange(Constants.DEFAULT_ALERTS_TIME_PRESET).timeval()))
			}

			// 2. Restore Data
			const dataActionList = []
			if (!_.isEmpty(cachedData)) {
				const { alerts, closedAlerts, devicesDetails } = cachedData
				dataActionList.push(actions.restoreAlerts({ alerts }), actions.restoreDevicesDetails({ devicesDetails }), actions.restoreClosedAlerts({ closedAlerts }))
				return concat(of(alertsActions.resetStore('AlertsMain')), of(...stateActionList), of(...dataActionList))
			} else {
				dataActionList.push(actions.fetchAlertsRequest())
				return concat(of(alertsActions.resetStore('AlertsMain')), of(alertsActions.updateAlertsLastRefresh(activeAlertsTab)), of(...stateActionList), of(...dataActionList))
			}
		})
	)
}

export const fetchAlertsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERTS_REQUEST),
		switchMap((action) => {
			return services.getAlerts(store.value, 'open').pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response)) {
							// 1. Response is empty
							return concat(of(actions.fetchAlertsSuccess([])), of(actions.fetchDevicesDetailsSuccess([], 'open')))
						} else {
							// 2. Response is not empty}
							const devices = response.response.map((alert) => alert.appliance_id)
							return concat(of(actions.fetchAlertsSuccess(response.response)), of(actions.fetchDevicesDetailsRequest(devices, 'open')))
						}
					} else {
						throw 'Error Fetching Alerts'
					}
				}),
				catchError((error) => {
					return of(actions.fetchAlertsFailure(error))
				})
			)
		})
	)
}

export const fetchClosedAlertsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_CLOSED_ALERTS_REQUEST),
		switchMap((action) => {
			return services.getAlerts(store.value, 'closed').pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response)) {
							// 1. Response is empty
							return concat(of(actions.fetchClosedAlertsSuccess([])), of(actions.fetchDevicesDetailsSuccess([], 'closed')))
						} else {
							// 2. Response is not empty
							const devices = response.response.map((alert) => alert.appliance_id)
							return concat(of(actions.fetchClosedAlertsSuccess(response.response)), of(actions.fetchDevicesDetailsRequest(devices, 'closed')))
						}
					} else {
						throw 'Error Fetching Alerts'
					}
				}),
				catchError((error) => {
					return of(actions.fetchClosedAlertsFailure(error))
				})
			)
		})
	)
}

export const fetchDevicesDetailsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_DEVICES_DETAILS_REQUEST),
		switchMap((action) => {
			if (action.devices.length === 0) {
				return of(actions.fetchDevicesDetailsSuccess([], action.alertsStatus))
			}
			return services.getDevicesDetails(store.value, action.devices).pipe(
				mergeMap(({ response }) => {
					return of(
						actions.setDevicesDetailsRequestId(response.jobId),
						actions.pollAlertsMainPageJobRequest(response.jobId, 'devices_details', 0, undefined, { alertsStatus: action.alertsStatus })
					)
				}),
				catchError((error) => {
					return of(actions.fetchDevicesDetailsFailure(error, action.alertsStatus))
				})
			)
		})
	)
}

export const updateAlertEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_ALERT_REQUEST),
		switchMap((action) => {
			return services.updateAlert(store.value, action.alertId, action.status, action.assignee).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							throw 'Error Updating Alert'
						} else {
							// 2. Response is not empty}
							return concat(of(actions.updateAlertSuccess(response.response, action.alertId)), of(actions.fetchActivitiesRequest('', action.alertId, action.alertId)))
						}
					} else {
						throw 'Error Updating Alert'
					}
				}),
				catchError((error) => {
					return of(actions.updateAlertFailure(error))
				})
			)
		})
	)
}

export const fetchCommentsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_COMMENTS_REQUEST),
		switchMap((action) => {
			return services.getComments(store.value, action.alertId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							return of(actions.fetchCommentsSuccess([], action.alertId))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchCommentsSuccess(response.response, action.alertId))
						}
					} else {
						throw 'Error Fetching Comments'
					}
				}),
				catchError((error) => {
					return of(actions.fetchCommentsFailure(error))
				})
			)
		})
	)
}

export const addCommentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.ADD_COMMENT_REQUEST),
		switchMap((action) => {
			return services.addComment(store.value, action.alertId, action.comment).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							return of(actions.addCommentFailure('Error Saving Comment'))
						} else {
							// 2. Response is not empty}
							return concat(of(actions.addCommentSuccess(response.response, action.alertId)), of(actions.fetchActivitiesRequest('', action.alertId, action.alertId)))
						}
					} else {
						throw 'Error Saving Comments'
					}
				}),
				catchError((error) => {
					return of(actions.addCommentFailure(error))
				})
			)
		})
	)
}

export const updateCommentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_COMMENT_REQUEST),
		switchMap((action) => {
			return services.updateComment(store.value, action.alertId, action.commentId, action.comment).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							throw 'Error Updating Comments'
						} else {
							// 2. Response is not empty}
							return concat(of(actions.updateCommentSuccess(response.response, action.alertId, action.commentId)), of(actions.fetchActivitiesRequest('', action.alertId, action.alertId)))
						}
					} else {
						throw 'Error Updating Comments'
					}
				}),
				catchError((error) => {
					return of(actions.updateCommentFailure(error))
				})
			)
		})
	)
}

export const removeCommentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.REMOVE_COMMENT_REQUEST),
		switchMap((action) => {
			return services.removeComment(store.value, action.alertId, action.commentId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return concat(of(actions.removeCommentSuccess(action.commentId, action.alertId)), of(actions.fetchActivitiesRequest('', action.alertId, action.alertId)))
					} else {
						throw 'Error Fetching Comments'
					}
				}),
				catchError((error) => {
					return of(actions.removeCommentFailure(error))
				})
			)
		})
	)
}

export const fetchEventsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_EVENTS_REQUEST),
		switchMap((action) => {
			return services.getEvents(store.value, action.alertId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response)) {
							// 1. Response is empty
							return of(actions.fetchEventsSuccess([], action.alertId))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchEventsSuccess(response.response, action.alertId))
						}
					} else {
						throw 'Error Fetching Events'
					}
				}),
				catchError((error) => {
					return of(actions.fetchEventsFailure(error))
				})
			)
		})
	)
}

export const fetchActivitiesEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ACTIVITIES_REQUEST),
		switchMap((action) => {
			return services.getActivities(store.value, action.subject, action.object_id).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response) || !_.isArray(response.response)) {
							// 1. Response is empty
							return of(actions.fetchActivitiesSuccess([], action.alertId))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchActivitiesSuccess(response.response, action.alertId))
						}
					} else {
						throw 'Error Fetching Activities'
					}
				}),
				catchError((error) => {
					return of(actions.fetchActivitiesFailure(error))
				})
			)
		})
	)
}

export const updateAlertsTimePresetEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_ALERTS_TIME_PRESET),
		switchMap((action) => {
			const prefActionList = []
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const timePresetPref = _.get(userPref, `alerts.fixed_tabs[0].state.alertsTimePreset`)
			if (!_.isEqual(timePresetPref, action.alertsTimePreset)) {
				_.set(userPref, `alerts.fixed_tabs[0].state.alertsTimePreset`, action.alertsTimePreset)
				prefActionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}
			return concat(of(actions.setAlertsTimePreset(action.alertsTimePreset)), of(...prefActionList))
		})
	)
}

export const updateAlertsTimeRangeEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_ALERTS_TIME_RANGE),
		switchMap((action) => {
			const prefActionList = []
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const timeRangePref = _.get(userPref, `alerts.fixed_tabs[0].state.alertsTimeRange`)
			if (!_.isEqual(timeRangePref, action.alertsTimeRange)) {
				_.set(userPref, `alerts.fixed_tabs[0].state.alertsTimeRange`, action.alertsTimeRange)
				prefActionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}
			return concat(of(actions.setAlertsTimeRange(action.alertsTimeRange)), of(...prefActionList))
		})
	)
}

/*--------- Poll Job ----------*/
export const pollAlertsMainPageJobEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.POLL_ALERTS_MAIN_PAGE_JOB_REQUEST),
		mergeMap((action) => {
			return services.pollAlertsJob(action.jobId, action.jobPage).pipe(
				mergeMap(({ response }) => {
					// For logs job, if job expired or pageNumber expired, quit processing
					// For health job, if job expired, quit processing
					if (action.jobType === 'devices_details' && response.jobId !== store.value.alertsMain.devicesDetailsRequestId) {
						return of(actions.pollAlertsMainPageJobFailure(response.jobId, action.jobType, 'Cancelled'))
					}
					// If it's still running and job is valid, keep polling
					else if (response.state === 'RUNNING') {
						return of(actions.pollAlertsMainPageJobRequest(response.jobId, action.jobType, action.jobPage, action.jobLimit, action.params))
					}
					// If it's done, handle response accroding to action type
					else {
						if (action.jobType === 'devices_details') {
							return of(actions.fetchDevicesDetailsSuccess(response.page.result.data, action.params.alertsStatus))
						}
					}
				}),
				catchError((error) => {
					return of(actions.fetchDevicesDetailsFailure(error, action.params.alertsStatus))
				})
			)
		})
	)
}
