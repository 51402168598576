import React, { Component } from 'react'
import './LogViewerHeader.scss'
import { LogQueryComponent as LogQuery } from './LogQuery'
import Constants from '../utils/Constants'
import util from '../utils/Util'
import moment from 'moment'
import { TimeRangeSelectComponent } from 'ui-lib'
import { ButtonWidget as Button, ProgressWidget as Progress } from 'ui-lib'
import iconHistory from 'ui-lib/src/images/icon-history.svg'
import iconPrev from 'ui-lib/src/images/page-prev.svg'
import iconNext from 'ui-lib/src/images/page-next.svg'
import { Badge } from 'reactstrap'
const getRange = TimeRangeSelectComponent.getRange

class LogViewerHeader extends Component {
	onQueryChange = (input) => {
		if (!input) {
			this.props.updateQuery('')
		}
		// this.props.updateQuery(input)
	}

	onRangeChange = (timeRange, timePreset) => {
		this.props.updateTimePreset(timePreset)
		this.props.updateTimeRange(timeRange)
	}

	onSearch = (query, logType, timeRange, timePreset, isDSTenant = false) => {
		this.props.onCloseLogDetailPanel()
		if (!isDSTenant) {
			this.props.updateQuery(query)
			this.props.updateLogType(logType)
			this.props.updateTimePreset(timePreset)
			this.props.updateTimeRange(timeRange)
			this.props.updatePageNumber(0)
			this.props.fetchLogsRequest()
			this.props.fetchLogsCountRequest()
		}
	}
	onPrevPage = () => {
		this.props.onCloseLogDetailPanel()
		const { timePreset, timeRange, pageNumber } = this.props
		const currentTimeRange = timePreset === 'custom' ? timeRange : getRange(timePreset).timeval()
		this.props.updateTimeRange(currentTimeRange)
		this.props.updatePageRequest({ pageNumber: pageNumber - 1 })
	}
	onNextPage = () => {
		this.props.onCloseLogDetailPanel()
		const { timePreset, timeRange, pageNumber } = this.props
		const currentTimeRange = timePreset === 'custom' ? timeRange : getRange(timePreset).timeval()
		this.props.updateTimeRange(currentTimeRange)
		this.props.updatePageRequest({ pageNumber: pageNumber + 1 })
	}

	render() {
		const {
			schemaForQuery,
			logsCount,
			logsCountLoading,
			logsCountError,
			query,
			logType,
			timePreset,
			timeRange,
			pageNumber,
			exportJobId,
			exportProgress,
			initExportError,
			exportHistoryLoading,
			cancelExportRequest,
			cancelExportPending,
			exportFileLoading,
			onOpenExportConfig,
			onOpenExportHistory
		} = this.props

		// Set default logType if it is from acc or it is undefined
		let typeOfLogs = logType ? logType : util.getFirstAvailableLogTypeFromSchema(schemaForQuery)

		// Get fields from schema
		const [vendor, type] = typeOfLogs.split('.')
		const fields = schemaForQuery[vendor].content[type].fields

		// If logsCount is ready calculate pagination info
		let pageCount
		if (!logsCountLoading && !logsCountError) {
			pageCount = Math.ceil(logsCount / Constants.DEFAULT_PAGE_SIZE)
		}

		// Exoport Feature Content
		let exportFeatureContent
		if (this.props.extractTenantUserObj && this.props.extractTenantUserObj.lsregion === 'americas') {
			// console.log(exportFileLoading, exportJobId);
			// console.log('export status', exportJobId, exportHistoryLoading)
			if (exportHistoryLoading && exportJobId === -1) {
				exportFeatureContent = <>Loading...</>
			} else if (initExportError) {
				exportFeatureContent = <>Export Currently Unavailable</>
			} else if (exportJobId && exportJobId !== -1) {
				const isDownloading = exportFileLoading[exportJobId] && exportFileLoading[exportJobId].loading === true
				if (isDownloading) {
					exportFeatureContent = (
						<>
							<Badge className="download" color="primary">
								Downloading ...
							</Badge>
							<img onClick={onOpenExportHistory} className="history-btn" src={iconHistory} alt="History" />
						</>
					)
				} else {
					exportFeatureContent = (
						<>
							<div className={exportProgress === 0 && !isDownloading ? 'hide-text' : 'show-text'}>
								<Progress value={(exportProgress === 0 ? 100 : exportProgress).toString()} />
							</div>
							<Button className="cancel-btn" disabled={cancelExportPending} text="Cancel" onClick={() => cancelExportRequest(exportJobId)} />
							<img onClick={onOpenExportHistory} className="history-btn" src={iconHistory} alt="History" />
						</>
					)
				}
			} else {
				exportFeatureContent = (
					<>
						<Button className="export-btn" text="Export" onClick={onOpenExportConfig} />
						<img onClick={onOpenExportHistory} className="history-btn" src={iconHistory} alt="History" />
					</>
				)
			}
		}

		return (
			<>
				<LogQuery
					timeFormat="MM/DD/YYYY hh:mm:ss A"
					supportParen={false}
					supportNot={false}
					schema={schemaForQuery}
					fields={fields}
					logType={typeOfLogs}
					query={query}
					timeRange={timeRange}
					timePreset={timePreset}
					searchButton={true}
					disabledDate={(date) => (date > moment() ? true : false)}
					onSearch={(query, { logType, timeRange, timePreset }) => {
						this.onSearch(query, logType, timeRange, timePreset)
					}}
					onQueryChange={this.onQueryChange}
					onRangeChange={this.onRangeChange}
					placeholder="Please enter log query"
					hasLogType={true}
				/>
				<div className="second-search-bar">
					<div className="time-n-results">
						<div className="logs-count-results">{`${logsCountLoading ? '...' : logsCountError ? 'N/A' : logsCount} results`}</div>
						<div className="current-date-range" data-test={'current-data-range'}>
							{`${timeRange[0].utc().local().format('MM-DD-YYYY hh:mm:ss a')}  to  ${timeRange[1].utc().local().format('MM-DD-YYYY hh:mm:ss a')}`}
						</div>
					</div>
					<div className="pagination">
						<div className="log-export">{exportFeatureContent}</div>
						<div className="pagination-prev">
							<button className="pagination-btn" disabled={logsCountLoading || logsCountError || pageNumber === 0 ? 'disabled' : ''} onClick={this.onPrevPage}>
								<img src={iconPrev} alt="Previous Page"></img>
							</button>
						</div>
						<div className="pagination-page_count">{logsCountLoading ? '...' : logsCountError ? 'N/A' : `Page ${pageCount > 0 ? pageNumber + 1 : 0} of ${pageCount}`}</div>
						<div className="pagination-next">
							<button className="pagination-btn" disabled={logsCountLoading || logsCountError || pageNumber + 1 === pageCount ? 'disabled' : ''} onClick={this.onNextPage}>
								<img src={iconNext} alt="Next Page"></img>
							</button>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default LogViewerHeader
