import Insights from './insights/Insights'
import Alerts from './alerts/Alerts'
import LogViewer from './explore/logViewer_v2/LogViewer'
import AccViewer from './explore/accViewer/AccViewer'

export default [
	{ path: '/insights', component: Insights },
	{ path: '/alerts', component: Alerts },
	{ path: '/logs', component: LogViewer },
	{ path: '/visibility', component: AccViewer }
]
