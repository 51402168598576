import React from 'react'
import { Drawer, Input, Radio } from 'antd'
import './ExportDrawer.scss'
import 'antd/lib/drawer/style/css'

export default class ExportDrawer extends React.Component {
	state = {
		fileType: 'csv',
		fileName: '',
		inputError: null
	}
	onClose = () => {
		this.setState({ fileType: 'csv', fileName: '', inputError: null })
		this.props.closeDrawer()
	}
	onExport = () => {
		if (!/^[\w_@!]+$/.test(this.state.fileName) || this.state.fileName.length > 256 || this.state.fileName.length === 0) {
			this.setState({ inputError: 'Filename must be a non-empty string with maximum 256 characters. No special characters allowed expect !, @, _' })
		} else {
			this.setState({ inputError: null })
			if (this.props.onExportClick) this.props.onExportClick(this.state.fileType, this.state.fileName)
		}
	}
	render() {
		return (
			<Drawer
				prefixCls="alerts-drawer ant-drawer"
				getContainer={() => document.getElementById('alerts-app')}
				title="Export"
				placement="right"
				closable={false}
				width={500}
				onClose={this.onClose}
				visible={this.props.exportDrawerVisible}
			>
				<div className="input-item">
					<div className="title">Name</div>
					<div className="input">
						<Input
							allowClear
							value={this.state.fileName}
							onChange={(e) => {
								this.setState({ fileName: e.target.value })
							}}
						/>
						{this.state.inputError ? <div className="input-error">{`* ${this.state.inputError}`}</div> : null}
					</div>
				</div>
				<div className="input-item">
					<div className="title">File Type</div>
					<div className="input">
						<Radio.Group
							onChange={(e) => {
								this.setState({ fileType: e.target.value })
							}}
							value={this.state.fileType}
						>
							<Radio value={'csv'}>CSV</Radio>
							<Radio value={'xml'}>XML</Radio>
							{/* <Radio value={'json'}>JSON</Radio> */}
						</Radio.Group>
					</div>
				</div>
				<div className="count">{`Number of results: ${this.props.numberOfResults}`}</div>
				<div className="btns">
					<span className="cancel-btn" onClick={this.onClose}>
						Cancel
					</span>
					<span className="export-btn" onClick={this.onExport}>
						Export
					</span>
				</div>
			</Drawer>
		)
	}
}
