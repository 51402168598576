import { ajax } from 'rxjs/ajax';
import { getCustomServiceURL, MOCK_SERVICES } from 'ui-lib';
import { loginSuccess, loginFail } from './actions';
import { getAuthHeaders, getParam, saveSession, getSession, jwt_decode } from 'ui-lib';
import { throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getValidUserPreference } from '../../utils/utils';

/*
	return minimalistic tenant information and then get user info from API server
	after token verification
*/
export function isUserLoggedIn() {
	const jwToken = window.location.hash.split('#_rjwt=')[1];
	if (jwToken) {
		// it was a redirect
		const { sub, exp, iat, redirectURL, supportAccountId } = jwt_decode(jwToken);

		const expValid = exp && Date.now() < exp * 1000;
		const iatValid = iat && Date.now() > iat * 1000;
		const subValid = sub === getParam('tenantId');
		if (expValid && iatValid && subValid) {
			// token is not expired, issued earlier & match the tenant
			window.location.hash = ''; // reset hash
			saveSession({ sub, token: jwToken, exp });
			console.log('Sending the token.');
			return of({
				response: {
					ok: true,
					isLoggedIn: true,
					tenant: {
						supportAccountId,
						cluster: {
							redirectURL
						}
					}
				}
			});
		}
	} else {
		// got here after a refresh of the page or first time by navigating or copy-pasting url.
		// tenantId from url or sessionId.
		const tenantId = getParam('tenantId');
		// see if we have token or not - with tenantId from url or from session storage
		const token = getSession(tenantId);
		if (token) {
			const { sub, exp, iat, redirectURL, supportAccountId } = jwt_decode(token);
			if (exp && Date.now() < exp * 1000 && iat && Date.now() > iat * 1000) {
				console.log('Sending the token, with T=', sub);
				saveSession({ sub, exp, token });
				return of({
					response: {
						ok: true,
						isLoggedIn: true,
						tenant: {
							supportAccountId,
							cluster: {
								redirectURL
							}
						}
					}
				});
			}
		}
		// no redirected token or token expired or invalid.
		console.log('noSession since No TOKEN NO TENANT-ID.');
		return of({
			response: {
				ok: true,
				isLoggedIn: false,
				noSession: !tenantId && !token
			}
		});
	}
	throw new Error('Invalid Token');
}

export function getLoggedInUser() {
	console.log('GET USer');
	let { SERVICE_URL } = getCustomServiceURL('api/sso/v1/user');
	if (!SERVICE_URL) {
		// SERVICE address is not available yet.
		console.log('NOT READY');
		return throwError(new Error('NOT_READY'));
	}
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders()
	}).pipe(
		map((r) => {
			return r;
		}),
		catchError((e) => {
			return throwError(e);
		})
	);
}

export function postLoginProcess(resp) {
	if (resp && resp.response && resp.response.isLoggedIn) {
		return loginSuccess(resp.response.tenant);
	} else {
		//navigate to login page
		return loginFail();
	}
}

export function commitActionObservable(scope) {
	let { SERVICE_URL } = getCustomServiceURL('api/config/v9.0/Operations/commitScope/' + scope);
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders()
	});
}

export function doCommitActionObservable(params) {
	let { SERVICE_URL } = getCustomServiceURL('api/config/v9.0/commit');
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(params),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders()
		}
	});
}

export function doCommitAndPushActionObservable(params) {
	let { SERVICE_URL } = getCustomServiceURL('api/config/v9.0/commitAndPush');
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(params),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders()
		}
	});
}

export function doPushActionObservable(params) {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/pushToCloud`);
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(params),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders()
		}
	});
}

export function doRevertActionObservable(params) {
	let { SERVICE_URL } = getCustomServiceURL('api/config/v9.0/revert');
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders()
	});
}

export function directoryDomainsObservable() {
	let { SERVICE_URL } = getCustomServiceURL('api/directory/v1/domains');
	return ajax({
		withCredentials: true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: {},
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders()
		}
	});
}

export function saveUserPreferenceObservable(params) {
	let { SERVICE_URL } = getCustomServiceURL('api/preference');
	const userPreference = getValidUserPreference(params);
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: { params: userPreference },
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders()
		}
	});
}
