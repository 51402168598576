import React, { Component } from 'react'
import { connect, TimeRangeSelectComponent as TimePicker } from 'ui-lib'
import _ from 'lodash'
import moment from 'moment'
import visibilityUtil from '../../../util/Util'

import ExportDrawer from './components/ExportDrawer/ExportDrawer'
import AlertsTable from './components/AlertsTable/AlertsTable'

import * as actions from './store/actions'
import * as alertsActions from '../../store/actions'

import exportIcon from '../../../images/icon-export.svg'

import './AlertsMain.scss'

class AlertsMain extends Component {
	constructor(props) {
		super(props)
		this.onExportClick = null
	}
	state = {
		filterBuilderSchema: visibilityUtil.loadSchema('alerts', false),
		exportDrawerVisible: false,
		currentTime: moment()
	}
	componentDidMount() {
		this.props.fetchAlertsMainPageContent()
	}
	componentDidUpdate(prevProps) {
		if (prevProps.activeAlertsTab !== this.props.activeAlertsTab) {
			//Scroll to Top
			//Fetch content
			this.props.fetchAlertsMainPageContent()
		}
	}
	onRangeChange = (timeRange) => {
		this.props.updateAlertsTimeRange(timeRange)
		//Trigger Refresh After Time Range Change
		this.props.refreshAlertsTab('AlertsMain')
	}
	onPresetChange = (timePreset, callback) => {
		this.props.updateAlertsTimePreset(timePreset)
		callback()
	}
	render() {
		const { openAlertsTab, switchAlertsTab, activeAlertsTab, alertsTimePreset, alertsTimeRange, alerts, closedAlerts, alertsLoading, alertsError } = this.props
		let activeAlertsCount = 0
		let openAlertsCount = 0
		if (alerts) {
			alerts.forEach((alert) => {
				if (alert.status !== 'closed') activeAlertsCount += 1
				if (alert.status === 'open') openAlertsCount += 1
			})
		}

		return (
			<div className="alerts-main">
				<div className="alerts-summary">
					{alerts ? (
						<div className="alerts-stat">
							<div className="active-count">{`${activeAlertsCount} Active Alerts`}</div>
							<div className="open-count">{`${openAlertsCount} Open`}</div>
						</div>
					) : null}

					<div
						className="export-btn"
						onClick={() => {
							this.setState({ exportDrawerVisible: true })
						}}
					>
						<img src={exportIcon} alt="export icon" className="export-icon" />
						<span className="text">Export</span>
					</div>
					<TimePicker
						value={alertsTimeRange}
						defaultValue={alertsTimeRange}
						preset={alertsTimePreset}
						onChange={this.onRangeChange}
						onPresetChange={this.onPresetChange}
						format={'YYYY/MM/DD HH:mm:ss'}
						disabled={false}
						disabledDate={(date) => (date > moment() ? true : false)}
					/>
					<ExportDrawer
						numberOfResults={_.get(alerts, 'length', 0) + _.get(closedAlerts, 'length', 0)}
						onExportClick={this.onExportClick}
						exportDrawerVisible={this.state.exportDrawerVisible}
						closeDrawer={() => this.setState({ exportDrawerVisible: false })}
					/>
				</div>
				<div className="alerts-table">
					<AlertsTable setExportClick={(fn) => (this.onExportClick = fn)} />
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alerts, ...state.alertsMain }
}

const mapDispatchToProps = { ...actions, ...alertsActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(AlertsMain)
