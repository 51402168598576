import React, { Component } from 'react'
import { connect, PopoverWidget as Popover, DropdownWidget as Dropdown } from 'ui-lib'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'
import iconSetting from '../../../../../images/icon-gear.svg'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import _ from 'lodash'
import Setting from './Setting'
import Constants from '../../../../utils/Constants.json'
import Util from '../../../../utils/Util'

import iconCheckboxTrue from 'ui-lib/src/images/icon-checkbox-true.svg'
import iconCheckboxFalse from 'ui-lib/src/images/icon-checkbox-false.svg'
import metricGraph from 'ui-lib/src/images/metric-graph-active.svg'
import thresholdActive from 'ui-lib/src/images/threshold-active.svg'
import maActive from 'ui-lib/src/images/moving-avg-active.svg'
import inActive from 'ui-lib/src/images/inactive.svg'

import './SyncChart.scss'

import * as actions from '../../store/actions'

class SyncChart extends Component {
	constructor (props) {
		super(props)
		this.state = {
			hoverPointSeriesName: '',
			chartIndex: -1,
			timeRangeMin: null,
			timeRangeMax: null,
			ce:
				props.alert && props.alert[0] && props.alert[0].metrics
					? props.alert[0].metrics.map((m, i) => {
							return { checked: true }
					  })
					: [{ checked: false }],
			rl:
				props.alert && props.alert[0] && props.alert[0].metrics
					? props.alert[0].metrics.map((m, i) => {
							return { checked: true }
					  })
					: [{ checked: false }],
			ma:
				props.alert && props.alert[0] && props.alert[0].metrics
					? props.alert[0].metrics.map((m, i) => {
							return { checked: true }
					  })
					: [{ checked: false }]
		}
	}

	createMiscHTML = (snippet) => ({ __html: snippet })

	shouldComponentUpdate (prevProps) {
		if (prevProps.alertTimeRangePreset !== this.props.alertTimeRangePreset) {
			this.setState({ timeRangeMin: null, timeRangeMax: null })
		}
		if (
			prevProps.isNavOpen !== this.props.isNavOpen ||
			prevProps.alertTimelineError !== this.props.alertTimelineError ||
			prevProps.alertTimelineLoading !== this.props.alertTimelineLoading ||
			prevProps.alertTimeline !== this.props.alertTimeline ||
			prevProps.alertCommitEventsError !== this.props.alertCommitEventsError ||
			prevProps.alertCommitEventsLoading !== this.props.alertCommitEventsLoading ||
			prevProps.alertCommitEvents !== this.props.alertCommitEvents ||
			prevProps.alertCapacityError !== this.props.alertCapacityError ||
			prevProps.alertCapacityLoading !== this.props.alertCapacityLoading ||
			prevProps.alertCapacity !== this.props.alertCapacity ||
			prevProps.alertMovingAverageError !== this.props.alertMovingAverageError ||
			prevProps.alertMovingAverageLoading !== this.props.alertMovingAverageLoading ||
			prevProps.alertMovingAverage !== this.props.alertMovingAverage ||
			prevProps.showAlertCommitEvents !== this.props.showAlertCommitEvents ||
			prevProps.showAlertRefLines !== this.props.showAlertRefLines ||
			prevProps.alertForecastError !== this.props.alertForecastError ||
			prevProps.alertForecastLoading !== this.props.alertForecastLoading ||
			prevProps.alertForecast !== this.props.alertForecast
		) {
			return true
		} else {
			return false
		}
	}

	componentDidUpdate (prevProps) {
		if (prevProps.isNavOpen !== this.props.isNavOpen) {
			setTimeout(() => {
				// To trigger resize of charts after container size change, 300 is the animation delay
				window.dispatchEvent(new Event('resize'))
			}, 500)
		}
	}

	/**
	 *
	 * reference from https://www.highcharts.com/demo/synchronized-charts
	 */
	synchronizedCharts = (e) => {
		let chart, point, i, event
		let { hoverPointSeriesName, chartIndex } = this.state

		for (i = 0; i < Highcharts.charts.length; i = i + 1) {
			chart = Highcharts.charts[i]
			if (!chart) {
				continue
			}
			// clear hover state
			chart.series.map((series) => {
				series.points.map((point) => {
					if (point) point.setState(undefined)
				})
			})
			// hide tooltip
			chart.tooltip.hide()
			chart.pointer.chartPosition = null
			// Find coordinates within the chart
			event = chart.pointer.normalize(e)
			/**
			 * to find the hover active series by state
			 */
			let currentSeries = chart.series[0]
			if (hoverPointSeriesName === 'Moving Average' || hoverPointSeriesName === 'Commit Events') {
				currentSeries = this.findMatchedSeries(chart.series, hoverPointSeriesName)
			}
			// Get the hovered point
			if (currentSeries) {
				point = currentSeries.searchPoint(event, hoverPointSeriesName === 'Commit Events' ? false : true)
			}
			if (point) {
				point.setState('hover') // set the marker status to hover
				point.series.chart.tooltip.refresh(point, event) // show the tooltip
				point.series.chart.xAxis[0].drawCrosshair(event, point) // show the crosshair
			}

			if (chart.index !== chartIndex && hoverPointSeriesName === 'Commit Events') {
				chart.tooltip.hide()
			}
		}
	}

	removeCrosshairs = () => {
		for (let i = 0; i < Highcharts.charts.length; i = i + 1) {
			let chart = Highcharts.charts[i]
			if (!chart) {
				continue
			}
			// clear hover state
			chart.series.map((series) => {
				series.points.map((point) => {
					if (point) point.setState(undefined)
				})
			})
			chart.tooltip.hide()
			chart.xAxis[0].hideCrosshair()
			chart.pointer.chartPosition = null
		}
	}

	findMatchedSeries (series, seriesName) {
		for (let i = 0; i < series.length; i++) {
			if (series[i].name === seriesName) {
				return series[i]
			}
		}
		return false
	}

	/**
	 * Synchronize zooming through the setExtremes event handler.
	 */
	syncExtremes = (e) => {
		let thisChart = this.chart
		if (e.trigger !== 'syncExtremes') {
			// Prevent feedback loop
			for (let i = 0; i < Highcharts.charts.length; i++) {
				let chart = Highcharts.charts[i]
				if (!chart) {
					continue
				}

				if (e.trigger === 'zoom') {
					if (e.min === undefined && e.max === undefined) {
						if (Highcharts.zoomMode) {
							Highcharts.zoomMode = false
						}
						if (_.get(chart, 'resetZoomButton.added')) {
							chart.resetZoomButton.hide()
						}
					} else {
						if (!Highcharts.zoomMode) {
							chart.showResetZoom()
							if (i + 1 === Highcharts.charts.length) {
								Highcharts.zoomMode = true
							}
						}
					}
				}

				if (chart !== thisChart) {
					for (let i = 0; i < chart.xAxis.length; i++) {
						// also need to syncing the x-axis for reference line and commit events
						let chartXaxis = chart.xAxis[i]
						if (chartXaxis.setExtremes) {
							// It is null while updating
							chartXaxis.setExtremes(e.min, e.max, undefined, false, { trigger: 'syncExtremes' })
						}
					}
				}
			}
		}
	}

	render () {
		let {
			alert,
			alertLoading,
			alertError,
			alertCapacityLoading,
			alertCapacity,
			alertTimelineError,
			alertTimeline,
			alertCommitEventsLoading,
			alertCommitEventsError,
			alertCommitEvents,
			alertMovingAverageLoading,
			alertMovingAverageError,
			alertMovingAverage,
			metricsMisc,
			alertTimeRangePreset,
			alertTimelineStartTime,
			alertTimelineEndTime,
			showAlertCommitEvents,
			showAlertRefLines,
			deviceDetails,
			updateAlertTimeRangePreset,
			updateShowAlertCommitEvents,
			updateShowAlertRefLines,
			alertForecastLoading,
			alertForecastError,
			alertForecast = [],
			alertTrend = []
			// alertMetrics
		} = this.props

		let deviceInfo = deviceDetails[alert[0].appliance_id]
		let self = this

		let timePresetInfo = Constants.TIME_RANGE_PRESETS[alertTimeRangePreset]
		if (this.state.timeRangeMin && this.state.timeRangeMax) {
			let interval = this.state.timeRangeMax - this.state.timeRangeMin
			for (let intervalPreset of Constants.TICK_INFO['Intervals']) {
				if (interval < Constants.TICK_INFO[intervalPreset].range) {
					timePresetInfo = Constants.TICK_INFO[intervalPreset]
					break
				}
			}
		}
		// 0 ~ 2 main chart, 3 Moving AVG, 4 Ref Line, 5 Commit Events
		const lineColors = ['#8F00FF', '#25969C', '#0BA4E8', '#EF9700', '#AC1818', '#38A5FF', '#D13C3C']
		const fillColors = ['#3ED6BA', '#3ED6BA', '#3ED6BA'].map((color) => ({
			linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
			stops: [
				[
					0,
					Highcharts.color(color)
						.setOpacity(0.5)
						.get('rgba')
				],
				[
					1,
					Highcharts.color(color)
						.setOpacity(0.2)
						.get('rgba')
				]
			]
		}))

		if (alertTimelineError && alertTimelineError !== 'Cancelled') {
			return (
				<div className='sync-chart'>
					<div className='info-area'>Error Fetching Timeline Data</div>
				</div>
			)
		}

		if (alertCommitEventsLoading || alertCapacityLoading || alertMovingAverageLoading) {
			return (
				<div className='sync-chart'>
					<div className='info-area'>
						<img className='loading-spinner' src={iconLoading} alt={'loading'} /> Loading Chart Data
					</div>
				</div>
			)
		}

		// Replace with the list from alert
		const metricList = alert[0].metrics || []

		const optionsList = []

		metricList.forEach((metric, i) => {
			if (!alertTimeline[metric]) {
				optionsList.push(null)
				return
			}
			const dataset = { type: 'area', maxY: 0 }
			const alertMovingAverageDataset = { type: 'line', maxY: 0 }

			const genericMetricName = Util.getGenericMetricName(metric)
			const graphInfo = Constants.LINE_CHART_INFO_MAP[genericMetricName]
			const percentage_graph = graphInfo.yaxis_type === 'percentage'
			const misc = metricsMisc[genericMetricName]
			let displayName = Util.getMetricDisplayName(metricsMisc, metric)

			dataset.name = displayName
			const alertTimelineData = Highcharts.map(alertTimeline[metric], (point) => {
				if (!percentage_graph && _.toNumber(point.metric_value_raw) > dataset.maxY) {
					dataset.maxY = _.toNumber(point.metric_value_raw)
				}
				return [parseInt(point.timegenerated), percentage_graph ? _.toNumber(point.metric_value_pct) : _.toNumber(point.metric_value_raw)]
			})

			const currentAlertTimeline = alertTimeline[metric]
			const lastCurrentAlertTimeline = currentAlertTimeline[currentAlertTimeline.length - 1]
			const currentAlertFC = alertForecast[metric] || []
			const filterFCData = currentAlertFC.filter((a) => {
				if (a.timestamp * 1000 * 1000 >= lastCurrentAlertTimeline.timegenerated) return a
			})

			const alertForecastData = Highcharts.map(filterFCData || [], (fc) => {
				if (!percentage_graph && _.toNumber(fc.timestamp) > dataset.maxY) {
					dataset.maxY = _.toNumber(fc.timestamp * 1000 * 1000)
				}
				return [fc.timestamp * 1000 * 1000, fc.value]
			})
			const alertForecastDataX = Highcharts.map(filterFCData || [], (fc) => {
				return fc.timestamp * 1000 * 1000
			})
			dataset.data = [...alertTimelineData, ...alertForecastData]
			if (dataset.maxY === 0) dataset.maxY = 100
			// For Ref Lines
			let refLines = null
			if (alertCapacity[genericMetricName]) {
				if (percentage_graph) {
					refLines = [
						{
							color: lineColors[4],
							width: 2,
							value: 100,
							label: {
								text: `<div class='ref-line'>Max. Capacity: ${Util.getReadableNumber(alertCapacity[genericMetricName].value, 2)} ${graphInfo.unit}</div>`,
								align: 'left',
								verticalAlign: 'bottom',
								useHTML: true,
								x: 15,
								y: 0
							},
							dashStyle: 'dash'
						}
					]
				} else {
					refLines = [
						{
							color: lineColors[4],
							width: 2,
							value: _.toNumber(alertCapacity[genericMetricName].value),
							label: {
								text: `<div class='ref-line'>Max. Capacity:  ${Util.getReadableNumber(alertCapacity[genericMetricName].value, 2)} ${graphInfo.unit}</div>`,
								align: 'left',
								verticalAlign: 'bottom',
								useHTML: true,
								x: 15,
								y: 0
							},
							dashStyle: 'dash'
						}
					]
				}
			}

			// For moving average
			if (!alertMovingAverageError && alertMovingAverage[metric] && Constants.TIME_RANGE_PRESETS[alertTimeRangePreset].hasMovingAverage) {
				const alertMovingAverageData = Highcharts.map(alertMovingAverage[metric], (point) => {
					if (!percentage_graph && _.toNumber(point.mov_avg_7d) > alertMovingAverageDataset.maxY) {
						alertMovingAverageDataset.maxY = _.toNumber(point.mov_avg_7d)
					}
					return [parseInt(point.timegenerated), _.toNumber(point.mov_avg_7d)]
				})
				const currentAlertMA = alertMovingAverage[metric]
				const currentAlertTrend = alertTrend[metric] || []
				const filterTrendData = currentAlertTrend.filter((a) => {
					if (a.timestamp * 1000 * 1000 >= currentAlertMA[currentAlertMA.length - 1].timegenerated) return a
				})

				const alertTrendData = Highcharts.map(filterTrendData || [], (point) => {
					if (!percentage_graph && _.toNumber(point.value) > alertMovingAverageDataset.maxY) {
						alertMovingAverageDataset.maxY = _.toNumber(point.value)
					}
					return [parseInt(point.timestamp * 1000 * 1000), _.toNumber(point.value)]
				})
				alertMovingAverageDataset.data = [...alertMovingAverageData, ...alertTrendData]
				alertMovingAverageDataset.name = 'Moving Average'
			}

			optionsList.push({
				chart: {
					backgroundColor: 'transparent',
					zIndex: 2,
					marginLeft: 50, // Keep all charts left aligned
					spacingTop: 20,
					spacingBottom: 20,
					height: 240,
					zoomType: 'x',
					events: {
						selection: function (event) {
							for (let i = 0; i < Highcharts.charts.length; i++) {
								let chart = Highcharts.charts[i]
								if (!chart) {
									continue
								}
								if (event.resetSelection) {
									self.setState({ timeRangeMin: null, timeRangeMax: null })
									// when user click the reset zoom button
									// update chart xAxis label
									chart.xAxis[0].update({
										tickInterval: timePresetInfo.tickInterval,
										labels: {
											formatter: function () {
												return moment(this.value / 1000).format(timePresetInfo.format)
											}
										}
									})
								} else {
									// when user zoom in
									// update chart xAxis label
									let interval = event.xAxis[0].max - event.xAxis[0].min
									self.setState({ timeRangeMin: event.xAxis[0].min, timeRangeMax: event.xAxis[0].max })
									let tickInterval, format
									for (let intervalPreset of Constants.TICK_INFO['Intervals']) {
										if (interval < Constants.TICK_INFO[intervalPreset].range) {
											tickInterval = Constants.TICK_INFO[intervalPreset].tickInterval
											format = Constants.TICK_INFO[intervalPreset].format
											break
										}
									}
									chart.xAxis[0].update({
										tickInterval: tickInterval,
										labels: {
											formatter: function () {
												return moment(this.value / 1000).format(format)
											}
										}
									})
								}
							}
						}
					}
				},
				plotOptions: {
					series: {
						boostThreshold: 5000,
						turboThreshold: 1000,
						lineWidth: 1.5,
						lineColor: lineColors[i],
						marker: { lineWidth: 1, lineColor: lineColors[i], fillColor: 'white' },
						states: {
							inactive: {
								opacity: 1
							}
						},
						events: {
							legendItemClick: function () {
								if (this.name === 'Moving Average') {
									self.setState(
										{
											ma: {
												...self.state.ma,
												[i]: { checked: !self.state.ma[i].checked }
											}
										},
										() => {
											updateShowAlertRefLines(!showAlertRefLines)
										}
									)
								} else if (this.name === 'Commit Events') {
									self.setState(
										{
											ce: {
												...self.state.ce,
												[i]: { checked: !self.state.ce[i].checked }
											}
										},
										() => {
											updateShowAlertCommitEvents(!showAlertCommitEvents)
										}
									)
								} else if (this.name === 'Metric Graph') {
									return false
								} else if (this.name === 'Threshold') {
									self.setState(
										{
											rl: {
												...self.state.rl,
												[i]: { checked: !self.state.rl[i].checked }
											}
										},
										() => {
											updateShowAlertRefLines(!showAlertRefLines)
										}
									)
								} else return false
							}
						},
						point: {
							events: {
								mouseOver: function () {
									// update the hover point series name
									let chart = this.series.chart
									self.setState({
										hoverPointSeriesName: _.get(chart, 'hoverSeries.name') || '',
										chartIndex: chart.index
									})
								},
								mouseOut: function () {
									// update the hover point series name
									self.setState({
										hoverPointSeriesName: ''
									})
								}
							}
						}
					}
				},
				titleInfo: { name: dataset.name, desc: misc ? misc.description : 'Not Available' },
				title: {
					text: null
				},
				credits: {
					enabled: false
				},
				legend: {
					margin: 50,
					layout: 'vertical',
					align: 'right',
					verticalAlign: 'middle',
					useHTML: true,
					symbolHeight: 0,
					symbolWidth: 0,
					symbolRadius: 0,
					squareSymbol: false,
					itemMarginBottom: 15,
					itemStyle: {
						textOverflow: 'clip',
						fontSize: 10,
						fontWeight: 400
					},
					itemHoverStyle: {
						cursor: 'default'
					},
					labelFormatter: function () {
						let color = lineColors[i]
						let text = `${deviceInfo.hostname}:${this.name}`
						self.index = i
						let checkBox = `<img class='checkbox-icon' data-index=${i} key=${this.name.replace(' ', '')} src=${iconCheckboxFalse} />`
						if (this.name === 'Commit Events') {
							color = lineColors[5]
							text = 'Commit Events'
							checkBox = `<img class='checkbox-icon' data-index=${i} key=${this.name.replace(' ', '')} src=${self.state.ce[i].checked ? iconCheckboxTrue : inActive} alt='checkbox' />`
						}
						if (this.name === 'Moving Average') {
							color = lineColors[3]
							text = 'Moving Average'
							checkBox = `<img class='checkbox-icon' data-index=${i} key=${this.name.replace(' ', '')} src=${self.state.ma[i].checked ? maActive : inActive} alt='checkbox' />`
						}
						if (this.name === 'Metric Graph' || this.name === dataset.name) {
							checkBox = `<img class='checkbox-icon' data-index=${i} key=${this.name.replace(' ', '')} src=${metricGraph} alt='checkbox' />`
							text = 'Metric Graph'
						}
						if (this.name === 'Threshold') {
							color = lineColors[3]
							text = 'Threshold'
							checkBox = `<img class='checkbox-icon' data-index=${i} key=${this.name.replace(' ', '')} src=${self.state.rl[i].checked ? thresholdActive : inActive} alt='checkbox' />`
						}

						const legendText = `<div class='legend-text'>${text}</div>`

						return `
						<div class='legend-item'>
						${checkBox}
						${legendText}
						</div>
						`
					}
				},
				xAxis: [
					{
						min: alertTimelineStartTime * 1000,
						max: alertForecastDataX ? alertForecastDataX[alertForecastDataX.length - 1] : alertTimelineEndTime * 1000,
						tickInterval: timePresetInfo.tickInterval,
						crosshair: true,
						events: {
							setExtremes: this.syncExtremes
						},
						labels: {
							formatter: function () {
								const value = moment(this.value / 1000).format(timePresetInfo.format)
								if (alertForecastDataX.includes(this.value)) {
									return `<div class='alert-forecast-label'>${value}</div>`
								}
								return value
							},
							style: {
								fontSize: 10,
								fontWeight: 400
							}
						},
						plotLines: [{
							color: '#DADBDB', // Color value
							dashStyle: 'solid', // Style of the plot line. Default to solid
							value: alertForecastDataX[0], // Value of where the line will appear
							width: 2 // Width of the line    
						}],
						style: {
							lineColor: 'black',
							lineWidth: 2
						}
					},
					{
						min: alertTimelineStartTime * 1000,
						max: alertTimelineEndTime * 1000,
						tickInterval: timePresetInfo.tickInterval,
						crosshair: true,
						visible: false,
						style: {
							lineColor: 'black',
							lineWidth: 2
						}
					}
				],
				yAxis: {
					gridLineDashStyle: 'longdash',
					tickInterval: percentage_graph ? 20 : undefined,
					// minorTickInterval: percentage_graph ? 10 : undefined,
					// minorTicks: true,
					min: 0,
					max: percentage_graph
						? 100
						: alertCapacity[genericMetricName]
						? Math.max(parseInt(alertCapacity[genericMetricName].value), dataset.maxY, alertMovingAverageDataset.maxY)
						: Math.max(dataset.maxY, alertMovingAverageDataset.maxY),
					title: {
						enabled: true,
						text: 'y_axis_title'
					},
					plotLines: self.state.rl[i].checked ? refLines : [],
					labels: {
						x: -10,
						formatter: function () {
							if (percentage_graph) {
								return `${Util.getReadableNumber(this.value)}%`
							} else {
								return `${Util.getReadableNumber(this.value)}`
							}
						},
						style: {
							fontSize: 10,
							fontWeight: 400
						}
					}
				},
				tooltip: {
					hideDelay: 0,
					positioner: function (labelWidth, labelHeight, point) {
						const label_width = 200
						if (this.chart.hoverPoint && this.chart.hoverPoint.series.name === 'Commit Events') {
							let x = point.plotX - 68
							if (point.plotX + label_width + 76 > this.chart.chartWidth) {
								x = point.plotX - label_width - 48
							}
							return {
								// right aligned
								x,
								y: -45 // align to title
							}
						}
						return {
							// right aligned
							x: this.chart.chartWidth - this.label.width - 185,
							y: -38 // align to title
						}
					},
					formatter: function () {
						if (this.point.series.name === 'Commit Events') {
							const label_width = 200
							let arrow_right = false
							// tooltip width 300, margin
							if (this.point.plotX + label_width + 76 > this.series.chart.chartWidth) {
								arrow_right = true
							}
							return `<div class='commits-tooltip${arrow_right ? ' arrow-right' : ' arrow-left'}'><div class='tooltip-title'>${moment(this.point.x / 1000).format(
								'YYYY/MM/DD - HH:mm:ss'
							)}</div><div class='tooltip-content'><p>Command: ${
								this.point.data.commit_command ? this.point.data.commit_command : 'Not Applicable'
							}</p></div><div class='tooltip-footer'>Committed By: ${this.point.data.commit_user}</div></div>`
						} else {
							if (percentage_graph) {
								return (
									"<div class='timeline-tooltip'><span class='tooltip-timestamp'>" +
									moment(this.point.x / 1000).format('YYYY/MM/DD HH:mm:ss') +
									"</span> - <span class='tooltip-value'>" +
									Util.getReadableNumber(this.point.y, 2) +
									'%</span></div>'
								)
							} else {
								return (
									"<div class='timeline-tooltip'><span class='tooltip-timestamp'>" +
									moment(this.point.x / 1000).format('YYYY/MM/DD HH:mm:ss') +
									"</span> - <span class='tooltip-value'>" +
									Util.getReadableNumber(this.point.y, 2) +
									' ' +
									graphInfo.unit +
									'</span></div>'
								)
							}
						}
					},
					useHTML: true,
					borderWidth: 0,
					backgroundColor: 'none',
					pointFormat: '{point.y}',
					headerFormat: '',
					shadow: false,
					animation: false,
					valueDecimals: dataset.valueDecimals,
					style: {
						fontWeight: 500
					}
				},
				series: [
					{
						boostThreshold: 500,
						xAxis: 0,
						legendIndex: 0,
						data: dataset.data,
						name: dataset.name,
						type: dataset.type,
						zoneAxis: 'x',
						zones: [
							{
								value: alertForecastDataX[0]
							},
							{
								dashStyle: 'dot'
							}
						],
						color: fillColors[i],
						fillOpacity: 0.3,
						states: {
							hover: {
								lineWidthPlus: 0
							}
						}
					}
				]
			})

			// For Threshold/Reference Lines
			optionsList[i].series.push({
				name: 'Threshold',
				lineColor: lineColors[3],
				marker: {
					enabled: true,
					radius: 1,
					symbol: 'circle',
					fillColor: lineColors[6],
					lineWidth: 2,
					lineColor: lineColors[6],
					states: {
						hover: {
							lineWidthPlus: 1,
							radiusPlus: 3
						}
					}
				}
			})

			// For moving average
			if (!alertMovingAverageError && alertMovingAverage[metric] && Constants.TIME_RANGE_PRESETS[alertTimeRangePreset].hasMovingAverage) {
				optionsList[i].series.push({
					stickyTracking: false,
					xAxis: 1,
					legendIndex: 1,
					data: alertMovingAverageDataset.data,
					name: alertMovingAverageDataset.name,
					type: alertMovingAverageDataset.type,
					lineColor: lineColors[3],
					fillOpacity: 1,
					lineWidth: 2,
					marker: {
						enabled: true,
						radius: 1,
						symbol: 'circle',
						fillColor: lineColors[3],
						lineWidth: 2,
						lineColor: lineColors[3],
						states: {
							hover: {
								lineWidthPlus: 1,
								radiusPlus: 3
							}
						}
					},
					states: {
						hover: {
							lineWidthPlus: 0
						}
					}
				})
			}

			// For commit events
			if (!alertCommitEventsError) {
				const alertCommitEventsDataset = {}
				alertCommitEventsDataset.data = Highcharts.map(alertCommitEvents, (point) => {
					return { x: parseInt(point.timegenerated), y: 0, data: point }
				})
				alertCommitEventsDataset.name = 'Commit Events'
				alertCommitEventsDataset.type = 'scatter'

				optionsList[i].series.push({
					stickyTracking: false,
					xAxis: 1,
					legendIndex: 2,
					data: alertCommitEventsDataset.data,
					name: alertCommitEventsDataset.name,
					type: alertCommitEventsDataset.type,
					lineColor: lineColors[5],
					fillOpacity: 1,
					lineWidth: 2,
					marker: {
						enabled: true,
						radius: 6,
						symbol: 'circle',
						fillColor: 'white',
						lineWidth: 2,
						lineColor: lineColors[5],
						states: {
							hover: {
								lineWidthPlus: 1,
								radiusPlus: 1
							}
						}
					},
					states: {
						hover: {
							lineWidthPlus: 0
						}
					}
				})
			}
		})

		return (
			<div className='sync-chart'>
				{optionsList.map((options, i) => {
					if (!options) {
						return (
							<div className='chart' key={i + 'loading'}>
								<div className='info-area'>
									<img className='loading-spinner' src={iconLoading} alt={'loading'} /> Loading Chart Data
								</div>
							</div>
						)
					}
					return (
						<div className='line-chart'>
							<div className='chart-title'>
								<span class='icon'></span>
								<span class='text'>{options.titleInfo.name}</span>
								<span class='desc' dangerouslySetInnerHTML={this.createMiscHTML(options.titleInfo.desc)} />
							</div>
							{i === 0 ? (
								<div className='chart-config' onMouseMove={(e) => e.stopPropagation()} onMouseLeave={(e) => e.stopPropagation()}>
									<div className='time-range-picker'>
										<Dropdown
											value={alertTimeRangePreset}
											options={Constants.TIME_RANGE_PRESETS.Order.map((preset) => ({ value: preset, label: preset }))}
											onChange={(value) => updateAlertTimeRangePreset(value)}
										/>
									</div>
								</div>
							) : null}
							<div className='chart' key={i} onMouseMove={this.synchronizedCharts} onMouseLeave={this.removeCrosshairs}>
								<HighchartsReact highcharts={Highcharts} options={options} />
								<div className='legend-bg' />
							</div>
						</div>
					)
				})}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.alertDetails, ...state.alerts }
}

const mapDispatchToProps = { ...actions }

export default connect(mapStateToProps, mapDispatchToProps, null)(SyncChart)
