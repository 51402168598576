import React, { Component } from 'react'
import { GridWidget, connect } from 'ui-lib'
import { updateExploreSchema } from '../../store/actions'

class ExploreGridWidget extends Component {

	constructor(props){
		super(props);
		this.state = {
			movingColumn: null
		}
	}

	setPreference = (presentation) => {
		// update the local copy as well.
		this.props.dispatch(updateExploreSchema(presentation));
	}

	onColumnResized = (params) => {
		if (_.get(params, 'column.colDef.field') === 'details') return
		const { column, columns, finished } = params
		if (column && finished) {
			const presentation = {}
			_.set(presentation, `${this.props.agGridProps.logType}.fields[0].name`, column.colDef.field)
			_.set(presentation, `${this.props.agGridProps.logType}.fields[0].width`, column.actualWidth)
			this.setPreference(presentation)
		} else if (columns && columns.length && finished) {
			const presentation = {}
			let idx = 0
			columns.map((col) => {
				if (col.colDef.resizable) {
					_.set(presentation, `${this.props.agGridProps.logType}.fields[${idx}].name`, col.colDef.field)
					_.set(presentation, `${this.props.agGridProps.logType}.fields[${idx}].width`, col.actualWidth)
					idx++
				}
			})
			this.setPreference(presentation)
		}
	}

	onColumnPinned = (params) => {
		const { column, pinned } = params

		const presentation = {}
		_.set(presentation, `${this.props.agGridProps.logType}.fields[0].name`, column.colDef.field)
		_.set(presentation, `${this.props.agGridProps.logType}.fields[0].pinned`, pinned)

		this.setPreference(presentation)
	}

	onDragStarted = (params) => {
		const columns = params.api.columnController.gridColumns
		const movingColumn = columns.find((col) => col.moving)
		if (movingColumn) {
			this.setState({ movingColumn })
		}
	}

	onDragStopped = (params) => {
		if (this.state.movingColumn) {
			const columns = params.api.columnController.gridColumns
			const movColStopIndex = columns.findIndex((col) => col.colDef.field === this.state.movingColumn.colDef.field)

			const presentation = {}
			_.set(presentation, `${this.props.agGridProps.logType}.field.name`, this.state.movingColumn.colDef.field)
			// If column is moved to the beginning of all, use moveBefore, otherwise use moveAfter
			if (movColStopIndex === 1) {
				_.set(presentation, `${this.props.agGridProps.logType}.moveBefore.name`, columns[movColStopIndex + 1].colDef.field)
			} else {
				_.set(presentation, `${this.props.agGridProps.logType}.moveAfter.name`, columns[movColStopIndex - 1].colDef.field)
			}
			this.setPreference(presentation)

			this.setState({ movingColumn: null })
		}
	}

	onColumnVisible = (params) => {
		const { visible, column } = params

		const presentation = {}
		_.set(presentation, `${this.props.agGridProps.logType}.fields[0].name`, column.colDef.field)
		_.set(presentation, `${this.props.agGridProps.logType}.fields[0].hidden`, !visible)

		this.setPreference(presentation)
	}

	render() {
		return (
			<GridWidget
				{...this.props}
				agGridProps={{
					onColumnResized: this.onColumnResized,
					onColumnPinned: this.onColumnPinned,
					onDragStarted: this.onDragStarted,
					onDragStopped: this.onDragStopped,
					onColumnVisible: this.onColumnVisible,
					...this.props.agGridProps
				}}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(mapStateToProps)(ExploreGridWidget)