import React, { Component } from 'react'
import HelpIcon from '../../images/icon-help-tab.svg'

import './HelpTab.scss'

export default class HelpTab extends Component {
	constructor(props) {
		super(props)
		this.node = React.createRef()
		this.state = {
			isMenuVisible: false
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleOutsideClick, false)
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick, false)
	}

	handleOutsideClick = (e) => {
		if (this.node && !this.node.current.contains(e.target) && e.target.closest('[id=pendo-resource-center-container]') === null) {
			this.setState({ isMenuVisible: false })
		}
	}
	render() {
		const { isNavOpen } = this.props
		return (
			<div className="help-tab" ref={this.node}>
				<div className={`tab-btn ${this.state.isMenuVisible ? 'menu-visible' : ''}`} onClick={() => this.setState({ isMenuVisible: !this.state.isMenuVisible })}>
					<img alt="help-icon" className="icon" src={HelpIcon} />
					{isNavOpen ? <span className="text">Help</span> : null}
				</div>
				<div className={`tab-menu ${this.state.isMenuVisible ? 'menu-visible' : ''}`}>
					<div className="title">HELP</div>
					<div className="item">
						<a href=" https://docs.paloaltonetworks.com/strata/device-insights/device-insights-getting-started.html" target="_blank">
							Tech Docs
						</a>
					</div>
					<div className="item pendo-announcements">Announcements</div>
					{/* <div className="item pendo-walkthroughs">Walkthroughs</div> */}
				</div>
			</div>
		)
	}
}
