import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'ui-lib'
import { AgGridReact } from 'ag-grid-react'

import * as alertsAction from '../../../../../alerts/store/actions'
import * as visibilityActions from '../../../../../store/actions'

import LastUpdatedCellRenderer from '../../../../components/CellRenderers/LastUpdatedCellRenderer'
import AlertNameCellRenderer from '../../../../components/CellRenderers/AlertNameCellRenderer'

import iconLoading from 'ui-lib/src/images/icon-loading.svg'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import './TopAlertTypes.scss'
import { openAlertsTab } from '../../../../../alerts/store/actions'

class TopAlertTypes extends Component {
	constructor(props) {
		super(props)
		this.state = {
			frameworkComponents: { LastUpdatedCellRenderer, AlertNameCellRenderer },
			colDefs: [
				{
					headerName: 'Hits',
					field: 'hits',
					width: 50,
					suppressSizeToFit: true,
					cellRenderer: (params) => `<span class="hits">${params.value}</span>`
				},
				{
					headerName: 'Name',
					field: 'name',
					flex: 1,
					cellRenderer: 'AlertNameCellRenderer',
					cellRendererParams: {
						hostname: props.devicePageDesc.hostname,
						openAlertsTab: (pageDesc) => {
							this.props.updateActiveApp('alerts')
							this.props.openAlertsTab(pageDesc)
							this.props.history.push('/')
						}
					}
				},
				{
					headerName: 'Last Hit',
					field: 'last_hit_time',
					width: 168,
					suppressSizeToFit: true,
					cellRenderer: 'LastUpdatedCellRenderer'
				}
			],
			defaultColDef: {
				resizable: false,
				suppressMenu: true
			}
		}
	}
	render() {
		const { deviceInfoTimePreset, deviceAlerts, deviceAlertsLoading, deviceAlertsError, closedDeviceAlerts, closedDeviceAlertsLoading, closedDeviceAlertsError } = this.props
		const presetMap = { last_7_days: 'Last 7 days', last_30_days: 'Last 30 days', last_90_days: 'Last 90 days' }

		let content
		if (deviceAlertsError || closedDeviceAlertsError) {
			content = <div className="info-area">Error Loading Data</div>
		} else if (deviceAlertsLoading || closedDeviceAlertsLoading) {
			content = (
				<div className="info-area">
					<img className="loading-spinner" src={iconLoading} alt={'loading'} />
					Loading Data
				</div>
			)
		} else if (_.isEmpty(deviceAlerts) && _.isEmpty(closedDeviceAlerts)) {
			content = <div className="info-area">No Data</div>
		} else {
			const alertsStat = {}
			const groupedAlertTypes = {}
			const alerts = [...(deviceAlerts || []), ...(closedDeviceAlerts || [])]
			alerts.forEach((alert) => {
				if (alert.alert_name in alertsStat) {
					alertsStat[alert.alert_name] += 1
				} else {
					alertsStat[alert.alert_name] = 1
				}
				if (alert.alert_name in groupedAlertTypes) {
					groupedAlertTypes[alert.alert_name].push(alert)
				} else {
					groupedAlertTypes[alert.alert_name] = [alert]
				}
			})
			let sortedAlertTypes = _.toPairs(alertsStat)
			sortedAlertTypes.sort((a, b) => b[1] - a[1])
			sortedAlertTypes.slice(0, 5).forEach((type) => groupedAlertTypes[type[0]].sort((a, b) => b.time - a.time))

			const rowData = sortedAlertTypes
				.slice(0, 5)
				.map((type, index) => ({ hits: type[1], name: type[0], last_hit_time: groupedAlertTypes[type[0]][0].time * 1000 * 1000, alerts: groupedAlertTypes[type[0]] }))

			content = (
				<div className="top-list">
					<div className={'ag-theme-balham'} style={{ height: '100%' }}>
						<AgGridReact
							scrollbarWidth={0}
							suppressMaxRenderedRowRestriction={true}
							frameworkComponents={this.state.frameworkComponents}
							columnDefs={this.state.colDefs}
							defaultColDef={this.state.defaultColDef}
							rowData={rowData}
						/>
					</div>
				</div>
			)
		}

		return (
			<div className="top-alert-types">
				<div className="title">
					Top Alert Types for this Device <span className="time-range">{presetMap[deviceInfoTimePreset]}</span>
				</div>
				<div className="content">{content}</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.visibility, ...state.devicePage }
}

const mapDispatchToProps = { ...visibilityActions, ...alertsAction }

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null)(TopAlertTypes))
