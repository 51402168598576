import * as actionTypes from './actionTypes'

export const resetStore = (pageType) => ({
	type: actionTypes.RESET_STORE,
	pageType
})

/*----- Tabs State Actions -----*/
export const initAlertsTabsState = () => ({
	type: actionTypes.INIT_ALERTS_TABS_STATE
})

export const updateAlertsTabsState = (alertsTabs, statesToUpdate = {}) => ({
	type: actionTypes.UPDATE_ALERTS_TABS_STATE,
	alertsTabs,
	statesToUpdate
})

export const alertsInitTaskComplete = (task) => ({
	type: actionTypes.ALERTS_INIT_TASK_COMPLETE,
	task
})

/*----- Tab Actions -----*/
export const openAlertsTab = (pageDesc) => ({
	type: actionTypes.OPEN_ALERTS_TAB,
	pageDesc
})

export const switchAlertsTab = (from, to) => ({
	type: actionTypes.SWITCH_ALERTS_TAB,
	from,
	to
})

export const closeAlertsTab = (index) => ({
	type: actionTypes.CLOSE_ALERTS_TAB,
	index
})

export const cacheAlertsTab = (index) => ({
	type: actionTypes.CACHE_ALERTS_TAB,
	index
})

export const updateAlertsActiveTab = (index) => ({
	type: actionTypes.UPDATE_ALERTS_ACTIVE_TAB,
	index
})

export const clearAlertsDataCache = () => ({
	type: actionTypes.CLEAR_ALERTS_DATA_CACHE
})

export const refreshAlertsTab = (pageType) => ({
	type: actionTypes.REFRESH_ALERTS_TAB,
	pageType
})

/*----- Poll Job Actions -----*/
export const pollAlertsJobRequest = (jobId, jobType, jobPage, jobLimit, params) => ({
	type: actionTypes.POLL_ALERTS_JOB_REQUEST,
	jobId,
	jobType,
	jobPage,
	jobLimit,
	params
})

export const pollAlertsJobFailure = (jobId, error) => ({
	type: actionTypes.POLL_ALERTS_JOB_FAILURE,
	jobId,
	error
})

/*----- Alerts Schema Actions -----*/
export const initAlertsSchema = (schema) => ({
	type: actionTypes.INIT_ALERTS_SCHEMA,
	schema
})

export const updateAlertsSchema = (preference) => ({
	type: actionTypes.UPDATE_ALERTS_SCHEMA,
	preference
})

export const setAlertsSchema = (schema) => ({
	type: actionTypes.SET_ALERTS_SCHEMA,
	schema
})

export const resetAlertsSchema = (schemaType) => ({
	type: actionTypes.RESET_ALERTS_SCHEMA,
	schemaType
})

/*----- Stale Status Actions -----*/
export const updateAlertsLastRefresh = (index) => ({
	type: actionTypes.UPDATE_ALERTS_LAST_REFRESH,
	index
})

export const updateAlertsStaleStatus = (index, isDataStale) => ({
	type: actionTypes.UPDATE_ALERTS_STALE_STATUS,
	index,
	isDataStale
})

export const setAlertsLastUpdate = (data) => ({
	type: actionTypes.SET_ALERTS_LAST_UPDATE,
	data
})