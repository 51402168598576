import React, { Component } from 'react'
import Card from './ExpandableCard'
import _ from 'lodash'

export default class Anchor extends Component {
	state = { selected: 0 }

	componentDidUpdate(prevProps) {
		if (this.props.logIndex !== prevProps.logIndex) {
			document.getElementsByClassName('anchor-cards')[0].scrollTo(0, 0)
			this.setState({ selected: 0 })
		}
	}

	render() {
		const { groupsInfo, fieldsInfo, logData, logIndex, isUser, userCtxLoading, userCtxError } = this.props
		const fieldsDisplayNameMap = {}
		const userData = {}

		// Internal fields will not have corresponding fields in fieldsInfo
		fieldsInfo.forEach((field) => (fieldsDisplayNameMap[field.name] = field.displayName))
		const groupNames = Object.keys(groupsInfo)
		const groupNameDataMap = {}
		groupNames.forEach((name) => {
			const groupData = {}
			groupsInfo[name].forEach((field) => {
				// Fields that are not in fieldsDisplayNameMap are internal
				if (!_.isUndefined(_.get(logData, field)) && fieldsDisplayNameMap[field]) {
					let data = _.get(logData, field)
					if (_.find(fieldsInfo, { name: field }).type === "timestamp") {
						data = new Date(data / 1000).toLocaleString()
					}
					groupData[fieldsDisplayNameMap[field]] = _.isObject(data) ? !_.isUndefined(data['value']) ? data['value'] : '' : _.toString(data)
				}
			})
			groupNameDataMap[name] = groupData
		})

		const cards = []
		groupNames.forEach((name) => {
			if (Object.keys(groupNameDataMap[name]).length !== 0) {
				if (isUser && !userCtxError && logData) {
					userData['title'] = logData['title']
					userData['displayName'] = logData['displayName']
					userData['initials'] = logData['initials']
				}
				cards.push(
					<Card
						isUser={isUser}
						userData={userData}
						userCtxLoading={userCtxLoading}
						userCtxError={userCtxError}
						title={name}
						key={name}
						id={name}
						groupData={groupNameDataMap[name]}
						logIndex={logIndex}
					/>
				)
			}
		})

		return (
			<>
				<div className="anchor-anchors">
					{groupNames.map((name, index) => {
						if (Object.keys(groupNameDataMap[name]).length !== 0) {
							return (
								<a
									onClick={() => this.setState({ selected: index })}
									className={`anchor-anchor ${index === this.state.selected ? 'anchor-highlight' : ''}`}
									href={`#${name}`}
									key={`anchor-${name}`}
								>
									{name}
								</a>
							)
						}
					})}
				</div>
				<div className="anchor-cards">
					{cards.length > 0 ? cards : 'Not Available'}
					<div className="anchor-white-space" />
				</div>
			</>
		)
	}
}
