import { Card } from 'antd';
import React from 'react';
import { startCase } from 'lodash';
import moment from 'moment';

import './ServicesDrawerContent.scss';
import 'antd/lib/card/style/css';

const formatSeconds = (seconds) => moment(seconds * 1000).format('DD MMM YYYY');
const formatSecondsWithTime = (seconds) => formatSeconds(seconds) + ' at ' + moment(seconds * 1000).format('HH:MM:SS');

export function ServicesDrawerContent({ device }) {
    const services = [
        ['Telemetry', device.threat_version, device.threat_release_date],
        ['WildFire', device.wildfire_version, device.wildfire_release_date],
        ['Threats', device.app_version, device.app_release_date],
        ['Antivirus', device.av_version, device.av_release_date],
    ].filter(s => s[1]);
    return (<div className={'services-drawer-content'}>
        <Card size="small" title={'Telemetry'}>
            <table>
                <tbody>
                <tr>
                    <th>Last Data Processed</th>
                    <td>{formatSecondsWithTime(device.observed_at)}</td>
                </tr>
                <tr>
                    <th>CDL Region</th>
                    <td>{startCase(device.region)}</td>
                </tr>
                <tr>
                    <th>Tenant ID</th>
                    <td>{device.tenant_id}</td>
                </tr>
                </tbody>
            </table>
        </Card>
        {services.length > 0 && (
            <Card size="small" title={'Security Content'} style={{ marginTop: '1em' }}>
                <table>
                    <tbody>
                    {services.map((service, index) => (
                        <tr key={index}>
                            <th>{service[0]}</th>
                            <td>Version {service[1]}</td>
                            <td>{typeof service[2] === 'number' ? `released ${formatSeconds(service[2])}` : 'release date N/A'}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
        )}

    </div>);
}