import React, { Component } from 'react'
import { Popover } from 'antd'
import moment from 'moment-timezone'

export default class AlertNameCellRenderer extends Component {
	render() {
		const { data } = this.props
		return (
			<span className="alert-name">
				<Popover
					prefixCls="alert-name-popover pan-popover"
					arrowPointAtCenter={true}
					placement="rightTop"
					content={
						<>
							{data.alerts.map((alert, index) => {
								return (
									<div class="alert" key={index}>
										<div class={`icon-${alert.severity}`}></div>
										<div className="data">
											<div
												class="name"
												onClick={() => {
													this.props.openAlertsTab({ alert_name: alert.alert_name, alert_id: alert.alert_id, hostname: this.props.hostname })
												}}
											>
												{alert.alert_name}
											</div>
											<div class="time">
												{moment(alert.time * 1000)
													.tz(moment.tz.guess())
													.format('MMM Do, YYYY | HH:mm A z')}
											</div>
										</div>
									</div>
								)
							})}
						</>
					}
					trigger="hover"
					// align={{ offset: [-2, 0] }}
				>
					{this.props.value}
				</Popover>
			</span>
		)
	}
}
