import { ajax as Ajax } from 'rxjs/ajax'
import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { getAuthHeaders, getCustomServiceURL, getLoginServiceURL, MOCK_SERVICES } from 'ui-lib'
import { getStoreState } from 'ui-lib'

const relogin = () => {
	let loginUrl = getLoginServiceURL('api/sso/v1/login')['SERVICE_URL']
	//tenant from redux store
	let store = getStoreState()
	let tenantId = _.get(store, 'main.tenant.id', null)
	if (tenantId) {
		loginUrl += `?tenantId=${tenantId}`
	}
	window.location = loginUrl
}

const ajax = (obj) => {
	return Ajax(obj).pipe(
		map((r) => {
			// on redirection response is null & req url != resp url
			if (r.xhr.status === 403) {
				return relogin()
			}
			return r
		}),
		catchError((e) => {
			if (e.xhr.status === 403) {
				return relogin()
			} else {
				return throwError(e)
			}
		})
	)
}

export function getMetricsMisc() {
	let { SERVICE_URL } = getCustomServiceURL('api/di/metadata/metric')
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}

export function getAlertsMisc() {
	let { SERVICE_URL } = getCustomServiceURL('api/di/metadata/alert')
	return ajax({
		withCredentials: MOCK_SERVICES ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}`,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...getAuthHeaders()
		}
	})
}
