export const INIT_APP = 'INIT_APP'
export const INIT_INSIGHTS = 'INIT_INSIGHTS'
export const INIT_ALERTS = 'INIT_ALERTS'

export const SET_TENANT_ID = 'SET_TENANT_ID'
export const SET_CSP_ID = 'SET_CSP_ID'
export const SET_INSTANCE_ID = 'SET_INSTANCE_ID'

export const UPDATE_NAV_OPEN_STATE = 'UPDATE_NAV_OPEN_STATE'

export const UPDATE_ACTIVE_APP = 'UPDATE_ACTIVE_APP'

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export const FETCH_METRICS_MISC_REQUEST = 'FETCH_METRICS_MISC_REQUEST'
export const FETCH_METRICS_MISC_SUCCESS = 'FETCH_METRICS_MISC_SUCCESS'
export const FETCH_METRICS_MISC_FAILURE = 'FETCH_METRICS_MISC_FAILURE'

export const FETCH_ALERTS_MISC_REQUEST = 'FETCH_ALERTS_MISC_REQUEST'
export const FETCH_ALERTS_MISC_SUCCESS = 'FETCH_ALERTS_MISC_SUCCESS'
export const FETCH_ALERTS_MISC_FAILURE = 'FETCH_ALERTS_MISC_FAILURE'
