import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of, forkJoin, concat } from 'rxjs'
import { getRelatedMetrics, getTimeline, getMovingAverage, getCommitEvents, getCapacity, getPageDesc, pollInsightsJob, getForecast } from '../../../store/services'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import * as insightsActions from '../../../store/actions'
import * as mainActions from '../../../../../main/actions'

import Util from '../../../utils/Util'
import Constants from '../../../utils/Constants.json'
import pageInfo from '../../pageUtil'

export const fetchLinePageContentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_LINE_PAGE_CONTENT, actionTypes.REFRESH_INSIGHTS_TAB),
		switchMap((action) => {
			if (action.type === actionTypes.REFRESH_INSIGHTS_TAB && action.pageType !== pageInfo.LineMetricDetails.pageType) {
				return of()
			}
			const { insightsTabs, activeInsightsTab } = store.value.insights
			const currentTab = insightsTabs.custom_tabs[activeInsightsTab]
			const { desc, state: cachedState, data: cachedData } = currentTab
			// 1. Restore State
			const stateActionList = []
			const { checkState, showCommitEvents, showRefLines, timeRange } = cachedState
			stateActionList.push(
				actions.setCheckedRelatedMetrics(checkState),
				actions.setShowCommitEvents(showCommitEvents),
				actions.setShowRefLines(showRefLines),
				actions.setTimeRangePreset(timeRange)
			)
			// 2. Restore Data
			const dataActionList = []
			if (cachedData) {
				const { linePageDesc, relatedMetrics, commitEvents, capacity, movingAverage, timeline, forecast } = cachedData
				dataActionList.push(
					actions.restoreLinePageDesc({ linePageDesc }),
					actions.restoreTimeline({ timeline }),
					actions.restoreCapacity({ capacity }),
					actions.restoreMovingAverage({ movingAverage }),
					actions.restoreCommitEvents({ commitEvents }),
					actions.restoreRelatedMetrics({ relatedMetrics }),
					actions.restoreForecast({ forecast })
				)
				return concat(of(...stateActionList), of(actions.updateTimelineTimeRange(timeRange)), of(...dataActionList))
			} else {
				if (store.value.linePage.linePageDesc) {
					// Page Desc is set when open tab
					const linePageDesc = store.value.linePage.linePageDesc
					const metrics = [linePageDesc.metric, ...Object.keys(checkState).filter((metric) => checkState[metric])]
					let capacityList = {}
					const movingAverageList = []
					metrics.forEach((metric) => {
						const lineChartInfo = Constants.LINE_CHART_INFO_MAP[Util.getGenericMetricName(metric)]
						if (!(Util.getGenericMetricName(metric) in capacityList)) {
							if (lineChartInfo && lineChartInfo.capacity_name !== 'N/A') {
								capacityList[Util.getGenericMetricName(metric)] = lineChartInfo.capacity_name
							}
						}
						if (lineChartInfo && lineChartInfo.hasMovingAverage && Constants.TIME_RANGE_PRESETS[timeRange].hasMovingAverage) {
							movingAverageList.push(metric)
						}
					})
					dataActionList.push(
						actions.fetchRelatedMetricsRequest(linePageDesc),
						actions.fetchTimelineRequest(linePageDesc.serial, metrics),
						actions.fetchCapacityRequest(linePageDesc, capacityList),
						actions.fetchCommitEventsRequest(linePageDesc.serial),
						actions.fetchMovingAverageRequest(linePageDesc.serial, movingAverageList),
						actions.fetchForecastRequest(linePageDesc.serial, metrics)
						//TODO: add a new fetchmethod for forecast here
					)
				} else {
					// No Page Desc Info
					dataActionList.push(actions.fetchLinePageDescRequest(desc.device, desc.metric, desc.hostname))
				}
				return concat(of(...stateActionList), of(actions.updateTimelineTimeRange(timeRange)), of(insightsActions.updateInsightsLastRefresh(activeInsightsTab)), of(...dataActionList))
			}
		})
	)
}

export const fetchLinePageDescEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_LINE_PAGE_DESC_REQUEST),
		switchMap((action) => {
			const tabIndexBeforeRequest = store.value.insights.activeInsightsTab
			return getPageDesc(store.value, action.device, action.metric).pipe(
				mergeMap(({ response }) => {
					const tabIndexAfterRequest = store.value.insights.activeInsightsTab
					if (tabIndexBeforeRequest !== tabIndexAfterRequest) {
						return of(actions.pollLinePageJobFailure('Cancelled'))
					}
					return of(actions.setLinePageDescRequestId(response.jobId), actions.pollLinePageJobRequest(response.jobId, 'line_page_desc', 0, null, { hostname: action.hostname }))
				}),
				catchError((error) => {
					return of(actions.fetchLinePageDescFailure(error))
				})
			)
		})
	)
}

export const fetchRelatedMetricsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_RELATED_METRICS_REQUEST),
		switchMap((action) => {
			const tabIndexBeforeRequest = store.value.insights.activeInsightsTab
			const misc = store.value.visibility.metricsMisc[Util.getGenericMetricName(action.node.metric)]
			const metricList = misc
				? misc.related_metrics
						.split(',')
						.map((metric) => metric.trim())
						.filter((metric) => metric !== '')
				: []
			const device = action.node.serial
			if (metricList.length === 0) {
				return of(actions.fetchRelatedMetricsSuccess([], store.value.insights.relatedMetricsRequestId))
			}
			return getRelatedMetrics(store.value, device, metricList).pipe(
				mergeMap(({ response }) => {
					const tabIndexAfterRequest = store.value.insights.activeInsightsTab
					if (tabIndexBeforeRequest !== tabIndexAfterRequest) {
						return of(actions.pollLinePageJobFailure('Cancelled'))
					}
					return of(
						actions.setRelatedMetricsRequestId(response.jobId),
						actions.pollLinePageJobRequest(response.jobId, 'related_metrics', 0, null, { metric: action.node.metric, currentTabJob: response.jobId })
					)
				}),
				catchError((error) => of(actions.fetchRelatedMetricsFailure(error)))
			)
		})
	)
}

export const fetchForecastEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_FORECAST_REQUEST),
		switchMap((action) => {
			const tabIndexBeforeRequest = store.value.insights.activeInsightsTab
			return forkJoin(action.metrics.map((metric) => getForecast(store.value, action.device, metric))).pipe(
				mergeMap((response) => {
					const forecast = {}
					const trend = {}
					action.metrics.forEach((metric, index) => {
						forecast[metric] = response[index].response.forecast
						trend[metric] = response[index].response.trend
					})
					return of(actions.fetchForecastSuccess(forecast, trend, null, null))
				}),
				catchError((error) => {
					return of(actions.fetchForecastFailure(error))
				})
			)
		})
	)
}

export const fetchTimelineEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_TIMELINE_REQUEST),
		switchMap((action) => {
			const tabIndexBeforeRequest = store.value.insights.activeInsightsTab
			return forkJoin(action.metrics.map((metric) => getTimeline(store.value, action.device, metric))).pipe(
				mergeMap((response) => {
					const tabIndexAfterRequest = store.value.insights.activeInsightsTab
					if (tabIndexBeforeRequest !== tabIndexAfterRequest) {
						return of(actions.pollLinePageJobFailure('Cancelled'))
					}
					const requestIds = []
					const timelineJobs = action.metrics.map((metric, index) => {
						const jobId = response[index].response.jobId
						requestIds.push(jobId)
						return actions.pollLinePageJobRequest(jobId, 'timeline', 0, null, { metric })
					})
					return [actions.setTimelineRequestId(requestIds), ...timelineJobs]
				}),
				catchError((error) => {
					return of(actions.fetchTimelineFailure(error))
				})
			)
		})
	)
}

export const fetchMovingAverageEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_MOV_AVG_REQUEST),
		switchMap((action) => {
			if (action.metrics.length === 0) {
				return of(actions.fetchMovingAverageSuccess({}, null, null))
			}
			const tabIndexBeforeRequest = store.value.insights.activeInsightsTab
			return forkJoin(action.metrics.map((metric) => getMovingAverage(store.value, action.device, metric))).pipe(
				mergeMap((response) => {
					const tabIndexAfterRequest = store.value.insights.activeInsightsTab
					if (tabIndexBeforeRequest !== tabIndexAfterRequest) {
						return of(actions.pollLinePageJobFailure('Cancelled'))
					}
					const requestIds = []
					const movingAverageJobs = action.metrics.map((metric, index) => {
						const jobId = response[index].response.jobId
						requestIds.push(jobId)
						return actions.pollLinePageJobRequest(jobId, 'moving_average', 0, null, { metric })
					})
					return [actions.setMovingAverageRequestId(requestIds), ...movingAverageJobs]
				}),
				catchError((error) => {
					return of(actions.fetchMovingAverageFailure(error))
				})
			)
		})
	)
}

export const fetchCommitEventsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_COMMIT_EVENTS_REQUEST),
		switchMap((action) => {
			const tabIndexBeforeRequest = store.value.insights.activeInsightsTab
			return getCommitEvents(store.value, action.device).pipe(
				mergeMap(({ response }) => {
					const tabIndexAfterRequest = store.value.insights.activeInsightsTab
					if (tabIndexBeforeRequest !== tabIndexAfterRequest) {
						return of(actions.pollLinePageJobFailure('Cancelled'))
					}
					return of(actions.setCommitEventsRequestId(response.jobId), actions.pollLinePageJobRequest(response.jobId, 'commit_events', 0))
				}),
				catchError((error) => of(actions.fetchCommitEventsFailure(error)))
			)
		})
	)
}

export const fetchCapacityEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_CAPACITY_REQUEST),
		switchMap((action) => {
			const capacityList = Object.values(action.metricList)
			if (capacityList.length === 0) {
				return of(actions.fetchCapacitySuccess([]))
			}
			return getCapacity(store.value, action.node, capacityList).pipe(
				mergeMap(({ response }) => {
					return of(actions.fetchCapacitySuccess(response))
				}),
				catchError((error) => of(actions.fetchCapacityFailure(error)))
			)
		})
	)
}

export const updateCheckedRelatedMetricsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_CHECKED_RELATED_METRICS),
		switchMap((action) => {
			const { linePageDesc, timeRangePreset } = store.value.linePage
			const metrics = [linePageDesc.metric, ...Object.keys(action.checkedRelatedMetrics).filter((metric) => action.checkedRelatedMetrics[metric])]
			let capacityList = {}
			const movingAverageList = []
			metrics.forEach((metric) => {
				const lineChartInfo = Constants.LINE_CHART_INFO_MAP[Util.getGenericMetricName(metric)]
				if (!(Util.getGenericMetricName(metric) in capacityList)) {
					if (lineChartInfo && lineChartInfo.capacity_name !== 'N/A') {
						capacityList[Util.getGenericMetricName(metric)] = lineChartInfo.capacity_name
					}
				}
				if (lineChartInfo && lineChartInfo.hasMovingAverage && Constants.TIME_RANGE_PRESETS[store.value.linePage.timeRangePreset].hasMovingAverage) {
					movingAverageList.push(metric)
				}
			})
			const prefActionList = []
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const checkedMetricsPref = _.get(userPref, `insights.custom_tabs[${store.value.insights.activeInsightsTab}].state.checkState`)
			if (!_.isEqual(checkedMetricsPref, action.checkedRelatedMetrics)) {
				_.set(userPref, `insights.custom_tabs[${store.value.insights.activeInsightsTab}].state.checkState`, action.checkedRelatedMetrics)
				prefActionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}
			return concat(
				of(actions.setCheckedRelatedMetrics(action.checkedRelatedMetrics)),
				of(...prefActionList),
				of(actions.updateTimelineTimeRange(timeRangePreset)),
				of(
					actions.fetchTimelineRequest(linePageDesc.serial, metrics),
					actions.fetchCapacityRequest(linePageDesc, capacityList),
					actions.fetchCommitEventsRequest(linePageDesc.serial),
					actions.fetchMovingAverageRequest(linePageDesc.serial, movingAverageList),
					actions.fetchForecastRequest(linePageDesc.serial, metrics)
				)
			)
		})
	)
}

export const updateShowCommitEventsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_SHOW_COMMIT_EVENTS),
		switchMap((action) => {
			const prefActionList = []
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const showCommitEventsPref = _.get(userPref, `insights.custom_tabs[${store.value.insights.activeInsightsTab}].state.showCommitEvents`)
			if (!_.isEqual(showCommitEventsPref, action.showCommitEvents)) {
				_.set(userPref, `insights.custom_tabs[${store.value.insights.activeInsightsTab}].state.showCommitEvents`, action.showCommitEvents)
				prefActionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}
			return concat(of(actions.setShowCommitEvents(action.showCommitEvents)), of(...prefActionList))
		})
	)
}

export const updateShowRefLinesEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_SHOW_REF_LINES),
		switchMap((action) => {
			const prefActionList = []
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const showRefLinesPref = _.get(userPref, `insights.custom_tabs[${store.value.insights.activeInsightsTab}].state.showRefLines`)
			if (!_.isEqual(showRefLinesPref, action.showRefLines)) {
				_.set(userPref, `insights.custom_tabs[${store.value.insights.activeInsightsTab}].state.showRefLines`, action.showRefLines)
				prefActionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}
			return concat(of(actions.setShowRefLines(action.showRefLines)), of(...prefActionList))
		})
	)
}

export const updateTimeRangePresetEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_TIME_RANGE_PRESET),
		switchMap((action) => {
			const { linePageDesc, checkedRelatedMetrics } = store.value.linePage
			const metrics = [linePageDesc.metric, ...Object.keys(checkedRelatedMetrics).filter((metric) => checkedRelatedMetrics[metric])]
			let capacityList = {}
			const movingAverageList = []
			metrics.forEach((metric) => {
				const lineChartInfo = Constants.LINE_CHART_INFO_MAP[Util.getGenericMetricName(metric)]
				if (!(Util.getGenericMetricName(metric) in capacityList)) {
					if (lineChartInfo && lineChartInfo.capacity_name !== 'N/A') {
						capacityList[Util.getGenericMetricName(metric)] = lineChartInfo.capacity_name
					}
				}
				if (lineChartInfo && lineChartInfo.hasMovingAverage && Constants.TIME_RANGE_PRESETS[store.value.linePage.timeRangePreset].hasMovingAverage) {
					movingAverageList.push(metric)
				}
			})

			const prefActionList = []
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const timeRangePref = _.get(userPref, `insights.custom_tabs[${store.value.insights.activeInsightsTab}].state.timeRange`)
			if (!_.isEqual(timeRangePref, action.timeRangePreset)) {
				_.set(userPref, `insights.custom_tabs[${store.value.insights.activeInsightsTab}].state.timeRange`, action.timeRangePreset)
				prefActionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}
			return concat(
				of(actions.setTimeRangePreset(action.timeRangePreset)),
				of(...prefActionList),
				of(actions.updateTimelineTimeRange(action.timeRangePreset)),
				of(
					actions.fetchTimelineRequest(linePageDesc.serial, metrics),
					actions.fetchCapacityRequest(linePageDesc, capacityList),
					actions.fetchCommitEventsRequest(linePageDesc.serial),
					actions.fetchMovingAverageRequest(linePageDesc.serial, movingAverageList),
					actions.fetchForecastRequest(linePageDesc.serial, metrics)
				)
			)
		})
	)
}

/*--------- Poll Job ----------*/
export const pollLinePageJobEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.POLL_LINE_PAGE_JOB_REQUEST),
		mergeMap((action) => {
			return pollInsightsJob(store.value.insights, action.jobId, action.jobPage).pipe(
				mergeMap(({ response }) => {
					// For logs job, if job expired or pageNumber expired, quit processing
					// For health job, if job expired, quit processing
					if (
						(action.jobType === 'line_page_desc' && response.jobId !== store.value.linePage.linePageDescRequestId) ||
						(action.jobType === 'related_metrics' && response.jobId !== store.value.linePage.relatedMetricsRequestId) ||
						(action.jobType === 'timeline' && !store.value.linePage.timelineRequestId.includes(response.jobId)) ||
						(action.jobType === 'moving_average' && !store.value.linePage.movingAverageRequestId.includes(response.jobId)) ||
						(action.jobType === 'commit_events' && response.jobId !== store.value.linePage.commitEventsRequestId)
					) {
						return of(actions.pollLinePageJobFailure(response.jobId, action.jobType, 'Cancelled'))
					}
					// If it's still running and job is valid, keep polling
					else if (response.state === 'RUNNING') {
						return of(actions.pollLinePageJobRequest(response.jobId, action.jobType, action.jobPage, action.jobLimit, action.params))
					}
					// If it's done, handle response accroding to action type
					else {
						if (action.jobType === 'line_page_desc') {
							if (_.isArray(response.page.result.data)) {
								if (response.page.result.data.length === 0) {
									throw new Error('Invalid Serial or Metric')
								} else {
									if (_.get(response.page.result.data[0], 'hostname') !== action.params.hostname) {
										throw new Error('Invalid Hostname')
									}
								}
							}
							return concat(of(actions.fetchLinePageDescSuccess(response.page.result.data[0], response.jobId)), of(actions.fetchLineMetricDetailsPageContent()))
						} else if (action.jobType === 'related_metrics') {
							return of(actions.fetchRelatedMetricsSuccess(response.page.result.data, action.params.currentTabJob))
						} else if (action.jobType === 'timeline') {
							return of(actions.fetchTimelineSuccess(response.page.result.data, action.params.metric, response.jobId))
						} else if (action.jobType === 'moving_average') {
							return of(actions.fetchMovingAverageSuccess(response.page.result.data, action.params.metric, response.jobId))
						} else if (action.jobType === 'commit_events') {
							return of(actions.fetchCommitEventsSuccess(response.page.result.data))
						}	else if (action.jobType === 'forcast') {
							return of(actions.fetchForecastSuccess(response.page.result.data))
						}
					}
				}),
				catchError((error) => {
					if (action.jobType === 'line_page_desc') {
						return of(actions.fetchLinePageDescFailure(error))
					} else if (action.jobType === 'related_metrics') {
						return of(actions.fetchRelatedMetricsFailure(error))
					} else if (action.jobType === 'timeline') {
						return of(actions.fetchTimelineFailure(error))
					} else if (action.jobType === 'moving_average') {
						return of(actions.fetchMovingAverageFailure(error))
					} else if (action.jobType === 'commit_events') {
						return of(actions.fetchCommitEventsFailure(error))
					} else if (action.jobType === 'forcast') {
						return of(actions.fetchForecastFailure(error))
					} else {
						return of(actions.pollLinePageJobFailure(action.jobId, action.jobType, error))
					}
				})
			)
		})
	)
}
