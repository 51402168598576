import * as actionTypes from './actionTypes'

export const initInsights = () => ({
	type: actionTypes.INIT_INSIGHTS
})

export const initAlerts = () => ({
	type: actionTypes.INIT_ALERTS
})

export const initApp = () => ({
	type: actionTypes.INIT_APP
})

export const updateNavOpenState = (isNavOpen) => ({
	type: actionTypes.UPDATE_NAV_OPEN_STATE,
	isNavOpen
})

export const updateActiveApp = (activeApp) => ({
	type: actionTypes.UPDATE_ACTIVE_APP,
	activeApp
})

/*----- Tenant Info Actions -----*/
export const setTenantId = (tenantId) => ({
	type: actionTypes.SET_TENANT_ID,
	tenantId
})

export const setCSPId = (cspId) => ({
	type: actionTypes.SET_CSP_ID,
	cspId
})

export const setInstanceId = (instanceId) => ({
	type: actionTypes.SET_INSTANCE_ID,
	instanceId
})

/*----- Notification Actions -----*/
export const addNotification = (notification) => ({
	type: actionTypes.ADD_NOTIFICATION,
	notification
})

export const removeNotification = () => ({
	type: actionTypes.REMOVE_NOTIFICATION
})

/*----- Metrics Misc Actions -----*/
export const fetchMetricsMiscRequest = () => ({
	type: actionTypes.FETCH_METRICS_MISC_REQUEST
})

export const fetchMetricsMiscSuccess = (metricsMisc) => ({
	type: actionTypes.FETCH_METRICS_MISC_SUCCESS,
	metricsMisc
})

export const fetchMetricsMiscFailure = (error) => ({
	type: actionTypes.FETCH_METRICS_MISC_FAILURE,
	error
})

/*----- Alerts Misc Actions -----*/
export const fetchAlertsMiscRequest = () => ({
	type: actionTypes.FETCH_ALERTS_MISC_REQUEST
})

export const fetchAlertsMiscSuccess = (alertsMisc) => ({
	type: actionTypes.FETCH_ALERTS_MISC_SUCCESS,
	alertsMisc
})

export const fetchAlertsMiscFailure = (error) => ({
	type: actionTypes.FETCH_ALERTS_MISC_FAILURE,
	error
})
