import {
	isUserLoggedIn,
	getLoggedInUser,
	commitActionObservable,
	doCommitActionObservable,
	doCommitAndPushActionObservable,
	doPushActionObservable,
	doRevertActionObservable,
	directoryDomainsObservable,
	saveUserPreferenceObservable
} from './services';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounce, concatMap } from 'rxjs/operators';
import { of, from, interval } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { getLicenseInfoObservable, getTokenInfoObservable, getApplicationsInfoObservable } from '../dashboard/services';
import { initAuth, getPathValue, parseError, SERVER_ERROR, SHOW_MODAL, UPDATE_MODAL, HIDE_MODAL } from 'ui-lib';

export const REGISTER_EXTENSION = 'REGISTER_EXTENSION';
export const IS_USER_LOGGEDIN = 'IS_USER_LOGGEDIN';
export const GET_LOGGEDIN_USER = 'GET_LOGGEDIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const START_MAIN_LOADING = 'START_MAIN_LOADING';
export const FINISH_MAIN_LOADING = 'FINISH_MAIN_LOADING';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const HIDE_SUCCESS_MESSAGE = 'HIDE_SUCCESS_MESSAGE';
export const CLEAR_NEW_ERRORS = 'CLEAR_NEW_ERRORS';
export const CHANGE_CONFIG_LOCATION = 'CHANGE_CONFIG_LOCATION';
export const COMMIT_SUCCESS = 'COMMIT_SUCCESS';
export const CONFIG_PUSH = 'CONFIG_PUSH';
export const DO_COMMIT = 'DO_COMMIT';
export const DO_COMMIT_AND_PUSH = 'DO_COMMIT_AND_PUSH';
export const DO_PUSH = 'DO_PUSH';
export const DO_REVERT = 'DO_REVERT';
export const DO_COMMIT_SUCCESS = 'DO_COMMIT_SUCCESS';
export const LOAD_COMMIT_DATA = 'LOAD_COMMIT_DATA';
export const FETCH_COMMIT_DATA = 'FETCH_COMMIT_DATA';
export const FETCH_LICENSE_INFO = 'FETCH_LICENSE_INFO';
export const FETCH_TOKEN_INFO = 'FETCH_TOKEN_INFO';
export const SET_LICENSE_INFO = 'SET_LICENSE_INFO';
export const SET_TOKEN_INFO = 'SET_TOKEN_INFO';
export const FETCH_LICENSE_ERROR = 'FETCH_LICENSE_ERROR';
export const FETCH_TOKEN_ERROR = 'FETCH_TOKEN_ERROR';
export const DO_REVERT_SUCCESS = 'DO_REVERT_SUCCESS';
export const DIRECTORY_DOMAINS = 'DIRECTORY_DOMAINS';
export const DIRECTORY_DOMAINS_SUCCESS = 'DIRECTORY_DOMAINS_SUCCESS';

export const FETCH_APPLICATIONS_INFO = 'FETCH_APPLICATIONS_INFO';
export const SET_APPLICATIONS_INFO = 'SET_APPLICATIONS_INFO';
export const FETCH_APPLICATIONS_ERROR = 'FETCH_APPLICATIONS_ERROR';

export const SET_USER_PREFERENCE = 'SET_USER_PREFERENCE';
export const SAVE_USER_PREFERENCE = 'SAVE_USER_PREFERENCE';
export const SAVE_USER_PREFERENCE_SUCCESS = 'SAVE_USER_PREFERENCE_SUCCESS';

const _T = (str) => str;

const emptyAction = { type: '' };

export const isUserLoggedInAction = () => ({
	type: IS_USER_LOGGEDIN
});

export const getLoggedInUserAction = () => ({
	type: GET_LOGGEDIN_USER
});

export const getLoggedInUserSuccess = (user) => ({
	type: GET_USER_SUCCESS,
	user: user.response.user,
	preference: user.response.preference,
	tenant: user.response.tenant
});

export const getLoggedInUserFail = () => ({
	type: GET_USER_FAIL
});

export const loginSuccess = (tenant) => ({
	type: LOGIN_SUCCESS,
	tenant
});

export const clearNewErrors = () => ({
	type: CLEAR_NEW_ERRORS
});

export const pushAction = () => ({
	type: CONFIG_PUSH
});

export const loginFail = ({ noSession }) => ({
	type: LOGIN_FAIL,
	noSession
});

export const startMainLoading = () => ({
	type: START_MAIN_LOADING
});

export const finishMainLoading = () => ({
	type: FINISH_MAIN_LOADING
});

export const showSuccessMessage = (successMessage) => ({
	type: SHOW_SUCCESS_MESSAGE,
	successMessage
});

export const hideSuccessMessage = (message) => ({
	type: HIDE_SUCCESS_MESSAGE
});

export const changeConfigLocation = (locationValue) => ({
	type: CHANGE_CONFIG_LOCATION,
	locationValue
});

export const commitSuccess = () => ({
	type: COMMIT_SUCCESS
});

export const loadCommitDataInReduxJob = (commit) => ({
	type: LOAD_COMMIT_DATA,
	commit
});

export const fetchCommitData = (payload) => ({
	type: FETCH_COMMIT_DATA,
	payload
});

export const doCommit = (payload) => ({
	type: DO_COMMIT,
	payload
});

export const doCommitAndPush = (payload) => ({
	type: DO_COMMIT_AND_PUSH,
	payload
});

export const doPush = (payload) => ({
	type: DO_PUSH,
	payload
});

export const doRevert = (params) => ({
	type: DO_REVERT,
	params
});

export const doCommitSuccess = () => ({
	type: DO_COMMIT_SUCCESS
});

export const fetchLicenseInfo = () => ({
	type: FETCH_LICENSE_INFO
});

export const fetchTokenInfo = () => ({
	type: FETCH_TOKEN_INFO
});

export const setLicenseInfo = (licenseInfo) => ({
	type: SET_LICENSE_INFO,
	licenseInfo
});

export const setTokenInfo = (tokenInfo) => ({
	type: SET_TOKEN_INFO,
	tokenInfo
});

export const fetchApplicationsInfo = () => {
	return {
		type: FETCH_APPLICATIONS_INFO
	};
};

export const setApplicationsInfo = (applicationsInfo) => ({
	type: SET_APPLICATIONS_INFO,
	applicationsInfo
});

export const doRevertSuccess = (revertMsg) => ({
	type: DO_REVERT_SUCCESS,
	revertMsg
});

export const fetchDirectoryDomains = () => ({
	type: DIRECTORY_DOMAINS
});

export const setUserPreference = (userPreference) => ({
	type: SET_USER_PREFERENCE,
	userPreference
});

/**
 * 
 * @param {*} userPreference :the entire preference object to save on main store
 * @param {*} debounceTime :debounce time unit is milliseconds, if you want to fire immediately, can skip pass in this param
 */
export const saveUserPreference = (userPreference, debounceTime = 0) => ({
	type: SAVE_USER_PREFERENCE,
	userPreference,
	debounceTime
});

export const saveUserPreferenceSuccess = (userPreference) => ({
	type: SAVE_USER_PREFERENCE_SUCCESS
	// userPreference
});

export const fetchDirectoryDomainsSuccess = (response) => {
	const directoryDomains = response && response.response && response.response.result ? response.response.result : [];
	return {
		type: DIRECTORY_DOMAINS_SUCCESS,
		directoryDomains
	};
};

export const fetchDirectoryDomainsEpic = (action$) => {
	return action$.pipe(
		ofType(DIRECTORY_DOMAINS),
		mergeMap((action) =>
			directoryDomainsObservable().pipe(
				mergeMap((response) => of(fetchDirectoryDomainsSuccess(response))),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error)
					});
				})
			)
		)
	);
};

export const postProcess = (response, payload) => {
	if (!payload || !payload.type) return emptyAction;
	let { type, postAction } = payload;
	let result = postAction && postAction(response);
	if (type === SHOW_MODAL) {
		let { modal } = result;
		if (modal) {
			return {
				type: SHOW_MODAL,
				modal
			};
		}
	}
	if (type === UPDATE_MODAL) {
		let { id, props } = result;
		if (id && props) {
			return {
				type: UPDATE_MODAL,
				id,
				props
			};
		}
	}
	return emptyAction;
};

export const isUserLoggedInEpic = action$ =>
	action$.pipe(
		ofType(IS_USER_LOGGEDIN),
		mergeMap(action =>
			isUserLoggedIn().pipe(
				concatMap(response => {
					const resp = response.response;
					if(resp && resp.isLoggedIn && resp.tenant) {
						return of(loginSuccess(resp.tenant), getLoggedInUserAction())
					} else {
						return of(loginFail(resp))
					}
				}),
				catchError(error => {
					return of({
						type: LOGIN_FAIL
					});
				})
			)
		)
	);

export const getLoggedInUserEpic = (action$) =>
	action$.pipe(
		ofType(GET_LOGGEDIN_USER),
		mergeMap((action) =>
			getLoggedInUser().pipe(
				mergeMap((response) => {
					initAuth(response);
					return of(getLoggedInUserSuccess(response));
				}),
				catchError((error) => {
					return of({
						type: GET_USER_FAIL
					});
				})
			)
		)
	);

export const fetchCommitEpic = (action$) => {
	return action$.pipe(
		ofType(FETCH_COMMIT_DATA),
		mergeMap((action) =>
			commitActionObservable(action.payload.scope).pipe(
				mergeMap((response) => of(loadCommitDataInReduxJob(response), postProcess(response, action.payload))),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error)
					});
				})
			)
		)
	);
};

export const doCommitEpic = (action$) => {
	return action$.pipe(
		ofType(DO_COMMIT),
		mergeMap((action) =>
			doCommitActionObservable(action.payload.params).pipe(
				mergeMap((response) =>
					of(
						doCommitSuccess(),
						{
							type: HIDE_MODAL,
							id: action.payload.modalId
						},
						postProcess(response, action.payload)
					)
				),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error)
					});
				})
			)
		)
	);
};

export const doCommitAndPushEpic = (action$) => {
	return action$.pipe(
		ofType(DO_COMMIT_AND_PUSH),
		mergeMap((action) =>
			doCommitAndPushActionObservable(action.payload.params).pipe(
				mergeMap((response) =>
					of(
						doCommitSuccess(),
						{
							type: HIDE_MODAL,
							id: action.payload.modalId
						},
						postProcess(response, action.payload)
					)
				),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error)
					});
				})
			)
		)
	);
};

export const doPushEpic = (action$) => {
	return action$.pipe(
		ofType(DO_PUSH),
		mergeMap((action) =>
			from(action.payload.params).pipe(
				mergeMap((id) =>
					doPushActionObservable(id).pipe(
						mergeMap((response) =>
							of(
								doCommitSuccess(),
								{
									type: HIDE_MODAL,
									id: action.payload.modalId
								},
								postProcess(response, action.payload)
							)
						),
						catchError((error) => {
							return of({
								type: SERVER_ERROR,
								errorMessage: parseError(error)
							});
						})
					)
				)
			)
		)
	);
};

export const doRevertEpic = (action$) => {
	return action$.pipe(
		ofType(DO_REVERT),
		mergeMap((action) =>
			doRevertActionObservable(action.params).pipe(
				mergeMap((response) =>
					of({
						type: SHOW_MODAL,
						modal: {
							isOpen: true,
							title: _T('Success'),
							type: 'Info',
							actions: [
								{
									text: _T('Close'),
									action: () => {
										window.location.reload();
									}
								}
							],
							message: getPathValue(response, 'response.result.result')
						}
					})
				),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error)
					});
				})
			)
		)
	);
};

export const fetchLicenseInfoEpic = (action$) => {
	return action$.pipe(
		ofType(FETCH_LICENSE_INFO),
		mergeMap((action) =>
			ajax(getLicenseInfoObservable()).pipe(
				mergeMap((response) => of(setLicenseInfo(getPathValue(response, 'response.msg')))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false
						},
						{
							type: FETCH_LICENSE_ERROR
						}
					);
				})
			)
		)
	);
};

export const fetchTokenInfoEpic = (action$) => {
	return action$.pipe(
		ofType(FETCH_TOKEN_INFO),
		mergeMap((action) =>
			ajax(getTokenInfoObservable()).pipe(
				mergeMap((resp) => of(setTokenInfo(resp.response))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false
						},
						{
							type: FETCH_TOKEN_ERROR
						}
					);
				})
			)
		)
	);
};

export const fetchAplicationsInfoEpic = (action$) => {
	return action$.pipe(
		ofType(FETCH_APPLICATIONS_INFO),
		mergeMap((action) =>
			ajax(getApplicationsInfoObservable()).pipe(
				mergeMap((response) => {
					return of(setApplicationsInfo(getPathValue(response, 'response.data')));
				}),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false
						},
						{
							type: FETCH_APPLICATIONS_ERROR
						}
					);
				})
			)
		)
	);
};

export const saveUserPreferenceEpic = (action$) => {
	return action$.pipe(
		ofType(SAVE_USER_PREFERENCE),
		debounce((action) => action.debounceTime ? interval(action.debounceTime) : interval(0)),
		mergeMap((action) =>
			// POST request to save pref to DB
			saveUserPreferenceObservable(action.userPreference).pipe(
				mergeMap((resp) =>
					of(
						// currently we have separate action for update main store,
						// this is a placeholder if we want to do something after success call in the future
						saveUserPreferenceSuccess(action.userPreference)
					)
				),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error)
					});
				})
			)
		)
	);
};
