import React from 'react';
import { DeviceList } from './DeviceList';

export function DevicesDrawerContent({ titlePrefix, device, devices, onClickDevice }) {
    const title = `${titlePrefix}${device.hostname} (${devices.length})`;
    return <>
        <DeviceList
            title={title}
            devices={devices}
            onClickDevice={onClickDevice}
        />
    </>;
}
