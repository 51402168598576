import React from "react";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";

// store info
import { adelphiStoreInfo } from "./containers/main/storeInfo";
import { registerStore } from "ui-lib";

const epicMiddleware = createEpicMiddleware();

const middleware = [applyMiddleware(epicMiddleware)];

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
	middleware.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const initialState = {};

const reducers = {
	...adelphiStoreInfo.reducers,
};

const epics = combineEpics(...adelphiStoreInfo.epics);

/* eslint-disable no-underscore-dangle */
export const store = createStore(combineReducers(reducers), initialState, compose(...middleware));
/* eslint-enable */

epicMiddleware.run(epics);

registerStore(store);
