import * as actionTypes from './actionTypes'

export const fetchAlertDetailsPageContent = () => ({
	type: actionTypes.FETCH_ALERT_DETAILS_PAGE_CONTENT
})

export const fetchCharts = () => ({
	type: actionTypes.FETCH_ALERT_CHARTS
})

/*----- Device Details Actions -----*/
export const fetchDeviceDetailsRequest = (devices) => ({
	type: actionTypes.FETCH_ALERT_DEVICE_DETAILS_REQUEST,
	devices
})

export const fetchDeviceDetailsSuccess = (deviceDetails) => ({
	type: actionTypes.FETCH_ALERT_DEVICE_DETAILS_SUCCESS,
	deviceDetails
})

export const setDeviceDetailsRequestId = (jobId) => ({
	type: actionTypes.SET_ALERT_DEVICE_DETAILS_REQUEST_ID,
	jobId
})

export const fetchDeviceDetailsFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_DEVICE_DETAILS_FAILURE,
	error
})

export const restoreDeviceDetails = (data) => ({
	type: actionTypes.RESTORE_DEVICE_DETAILS,
	data
})

/*----- Alert Actions -----*/
export const fetchAlertRequest = (alertId) => ({
	type: actionTypes.FETCH_ALERT_REQUEST,
	alertId
})

export const fetchAlertSuccess = (alert) => ({
	type: actionTypes.FETCH_ALERT_SUCCESS,
	alert
})

export const fetchAlertFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_FAILURE,
	error
})

export const updateAlertStatusRequest = (alertId, status, assignee) => ({
	type: actionTypes.UPDATE_ALERT_STATUS_REQUEST,
	alertId,
	status,
	assignee
})

export const updateAlertStatusSuccess = (alert, alertId) => ({
	type: actionTypes.UPDATE_ALERT_STATUS_SUCCESS,
	alert,
	alertId
})

export const updateAlertStatusFailure = (error) => ({
	type: actionTypes.UPDATE_ALERT_STATUS_FAILURE,
	error
})

export const restoreAlert = (data) => ({
	type: actionTypes.RESTORE_ALERT,
	data
})

/*----- AlertEvents Actions -----*/
export const fetchAlertEventsRequest = (alertId) => ({
	type: actionTypes.FETCH_ALERT_EVENTS_REQUEST,
	alertId
})

export const fetchAlertEventsSuccess = (events, alertId) => ({
	type: actionTypes.FETCH_ALERT_EVENTS_SUCCESS,
	events,
	alertId
})

export const fetchAlertEventsFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_EVENTS_FAILURE,
	error
})

/*----- Activities Actions -----*/
export const fetchAlertActivitiesRequest = (subject, object_id, alertId) => ({
	type: actionTypes.FETCH_ALERT_ACTIVITIES_REQUEST,
	subject,
	object_id,
	alertId
})

export const fetchAlertActivitiesSuccess = (activities, alertId) => ({
	type: actionTypes.FETCH_ALERT_ACTIVITIES_SUCCESS,
	activities,
	alertId
})

export const fetchAlertActivitiesFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_ACTIVITIES_FAILURE,
	error
})

/*----- AlertComments Actions -----*/
export const fetchAlertCommentsRequest = (alertId) => ({
	type: actionTypes.FETCH_ALERT_COMMENTS_REQUEST,
	alertId
})

export const fetchAlertCommentsSuccess = (comments, alertId) => ({
	type: actionTypes.FETCH_ALERT_COMMENTS_SUCCESS,
	comments,
	alertId
})

export const fetchAlertCommentsFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_COMMENTS_FAILURE,
	error
})

export const addAlertCommentRequest = (comment, alertId) => ({
	type: actionTypes.ADD_ALERT_COMMENT_REQUEST,
	comment,
	alertId
})

export const addAlertCommentSuccess = (comment, alertId) => ({
	type: actionTypes.ADD_ALERT_COMMENT_SUCCESS,
	comment,
	alertId
})

export const addAlertCommentFailure = (error) => ({
	type: actionTypes.ADD_ALERT_COMMENT_FAILURE,
	error
})

export const updateAlertCommentRequest = (comment, alertId, commentId) => ({
	type: actionTypes.UPDATE_ALERT_COMMENT_REQUEST,
	comment,
	alertId,
	commentId
})

export const updateAlertCommentSuccess = (comment, alertId, commentId) => ({
	type: actionTypes.UPDATE_ALERT_COMMENT_SUCCESS,
	comment,
	alertId,
	commentId
})

export const updateAlertCommentFailure = (error) => ({
	type: actionTypes.UPDATE_ALERT_COMMENT_FAILURE,
	error
})

export const removeAlertCommentRequest = (commentId, alertId) => ({
	type: actionTypes.REMOVE_ALERT_COMMENT_REQUEST,
	commentId,
	alertId
})

export const removeAlertCommentSuccess = (commentId, alertId) => ({
	type: actionTypes.REMOVE_ALERT_COMMENT_SUCCESS,
	commentId,
	alertId
})

export const removeAlertCommentFailure = (error) => ({
	type: actionTypes.REMOVE_ALERT_COMMENT_FAILURE,
	error
})

/*----- Capacity Actions -----*/
export const fetchAlertCapacityRequest = (deviceInfo, metricList, alertId) => ({
	type: actionTypes.FETCH_ALERT_CAPACITY_REQUEST,
	deviceInfo,
	metricList,
	alertId
})

export const fetchAlertCapacitySuccess = (data) => ({
	type: actionTypes.FETCH_ALERT_CAPACITY_SUCCESS,
	data
})

export const fetchAlertCapacityFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_CAPACITY_FAILURE,
	error
})

export const restoreAlertCapacity = (data) => ({
	type: actionTypes.RESTORE_ALERT_CAPACITY,
	data
})

/*----- Mov Avg Actions -----*/
export const fetchAlertMovingAverageRequest = (device, metrics, alertId) => ({
	type: actionTypes.FETCH_ALERT_MOV_AVG_REQUEST,
	device,
	metrics,
	alertId
})

export const fetchAlertMovingAverageSuccess = (data, metric, jobId) => ({
	type: actionTypes.FETCH_ALERT_MOV_AVG_SUCCESS,
	data,
	metric,
	jobId
})

export const setAlertMovingAverageRequestId = (ids) => ({
	type: actionTypes.SET_ALERT_MOV_AVG_REQUEST_ID,
	ids
})

export const fetchAlertMovingAverageFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_MOV_AVG_FAILURE,
	error
})

export const restoreAlertMovingAverage = (data) => ({
	type: actionTypes.RESTORE_ALERT_MOV_AVG,
	data
})

/*----- Timeline Actions -----*/
export const updateAlertTimeRangePreset = (alertTimeRangePreset) => ({
	type: actionTypes.UPDATE_ALERT_TIME_RANGE_PRESET,
	alertTimeRangePreset
})

export const setAlertTimeRangePreset = (alertTimeRangePreset) => ({
	type: actionTypes.SET_ALERT_TIME_RANGE_PRESET,
	alertTimeRangePreset
})

export const updateAlertTimelineTimeRange = (preset) => ({
	type: actionTypes.UPDATE_ALERT_TIMELINE_TIME_RANGE,
	preset
})

export const fetchAlertTimelineRequest = (device, metrics, alertId) => ({
	type: actionTypes.FETCH_ALERT_TIMELINE_REQUEST,
	device,
	metrics,
	alertId
})

export const fetchAlertTimelineSuccess = (data, metric, jobId) => ({
	type: actionTypes.FETCH_ALERT_TIMELINE_SUCCESS,
	data,
	metric,
	jobId
})

export const setAlertTimelineRequestId = (ids) => ({
	type: actionTypes.SET_ALERT_TIMELINE_REQUEST_ID,
	ids
})

export const fetchAlertTimelineFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_TIMELINE_FAILURE,
	error
})

export const restoreAlertTimeline = (data) => ({
	type: actionTypes.RESTORE_ALERT_TIMELINE,
	data
})

/*----- Alert Forecast Actions -----*/
export const fetchAlertForecastRequest = (device, metrics) => ({
	type: actionTypes.FETCH_ALERT_FORECAST_REQUEST,
	device,
	metrics
})

export const fetchAlertForecastSuccess = (alertForecast, alertTrend, device, metric) => ({
	type: actionTypes.FETCH_ALERT_FORECAST_SUCCESS,
	alertForecast,
	alertTrend,
	device,
	metric
})

export const fetchAlertForecastFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_FORECAST_FAILURE,
	error
})

export const restoreAlertForecastEvents = (data) => ({
	type: actionTypes.RESTORE_ALERT_FORECAST,
	data
})

/*----- Alert Commit Events Actions -----*/
export const fetchAlertCommitEventsRequest = (device, alertId) => ({
	type: actionTypes.FETCH_ALERT_COMMIT_EVENTS_REQUEST,
	device,
	alertId
})

export const fetchAlertCommitEventsSuccess = (data) => ({
	type: actionTypes.FETCH_ALERT_COMMIT_EVENTS_SUCCESS,
	data
})

export const setAlertCommitEventsRequestId = (jobId) => ({
	type: actionTypes.SET_ALERT_COMMIT_EVENTS_REQUEST_ID,
	jobId
})

export const fetchAlertCommitEventsFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_COMMIT_EVENTS_FAILURE,
	error
})

export const restoreAlertCommitEvents = (data) => ({
	type: actionTypes.RESTORE_ALERT_COMMIT_EVENTS,
	data
})

/*----- Poll Job Actions -----*/
export const pollAlertDetailsPageJobRequest = (jobId, jobType, jobPage, jobLimit, params) => ({
	type: actionTypes.POLL_ALERT_DETAILS_PAGE_JOB_REQUEST,
	jobId,
	jobType,
	jobPage,
	jobLimit,
	params
})

export const pollAlertDetailsPageJobFailure = (jobId, error) => ({
	type: actionTypes.POLL_ALERT_DETAILS_PAGE_JOB_FAILURE,
	jobId,
	error
})

/*----- Table Chart Actions -----*/
export const fetchAlertTableChartRequest = (deviceInfo, alertId) => ({
	type: actionTypes.FETCH_ALERT_TABLE_CHART_REQUEST,
	alertId,
	deviceInfo
})

export const fetchAlertTableChartSuccess = (jobApiResponse, apiResponse, metric, currentTabJob) => ({
	type: actionTypes.FETCH_ALERT_TABLE_CHART_SUCCESS,
	jobApiResponse,
	apiResponse,
	metric,
	currentTabJob
})

export const setAlertTableChartRequestId = (jobId) => ({
	type: actionTypes.SET_ALERT_TABLE_CHART_REQUEST_ID,
	jobId
})

export const fetchAlertTableChartFailure = (error) => ({
	type: actionTypes.FETCH_ALERT_TABLE_CHART_FAILURE,
	error
})

export const restoreAlertTableCharts = (data) => ({
	type: actionTypes.RESTORE_ALERT_TABLE_CHARTS,
	data
})

export const resetAlertTableCharts = () => ({
	type: actionTypes.RESET_ALERT_TABLE_CHARTS
})

/*----------- Charts Settings -------------*/
export const updateShowAlertCommitEvents = (showAlertCommitEvents) => ({
	type: actionTypes.UPDATE_SHOW_ALERT_COMMIT_EVENTS,
	showAlertCommitEvents
})

export const updateShowAlertRefLines = (showAlertRefLines) => ({
	type: actionTypes.UPDATE_SHOW_ALERT_REF_LINES,
	showAlertRefLines
})

export const setShowAlertCommitEvents = (showAlertCommitEvents) => ({
	type: actionTypes.SET_SHOW_ALERT_COMMIT_EVENTS,
	showAlertCommitEvents
})

export const setShowAlertRefLines = (showAlertRefLines) => ({
	type: actionTypes.SET_SHOW_ALERT_REF_LINES,
	showAlertRefLines
})
