export default {
	extractTenantUser(tenant, loggedInUser) {
		let extractedTenantUserObj = {}
		if (tenant.extensions && tenant.extensions.length > 0) {
			const lstenantObj = _.find(tenant.extensions, (extension) => extension.name === 'logging_service' || extension.name === 'Logging Service')
			let lstenantObjInfo = {
				tenant_id: '',
				region: ''
			}

			if (!lstenantObj) {
			} else {
				if (lstenantObj.info) {
					lstenantObjInfo = JSON.parse(lstenantObj.info)
				}
				const dstenantObj = _.find(tenant.extensions, (extension) => (extension.name === 'Directory Sync' || extension.name === 'directory_sync') && extension.active === true)
				let dstenantObjInfo = {
					tenant_id: '',
					region: ''
				}
				if (dstenantObj && dstenantObj.info) {
					dstenantObjInfo = JSON.parse(dstenantObj.info)
				}
				extractedTenantUserObj = {
					lstenant: lstenantObjInfo.tenant_id,
					lsregion: lstenantObjInfo.region,
					dstenant: dstenantObjInfo.tenant_id,
					dsregion: dstenantObjInfo.region,
					account: lstenantObj.supportAccountId,
					email: loggedInUser.email
				}
			}
		} else {
			console.log('tenant has no extensions...check entitlements?')
		}
		return extractedTenantUserObj
	}
}
