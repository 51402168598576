import React from 'react'
export const FirewallSelectedSvg = ({ x, y }) => (
  <svg x={x - 44 / 2} y={y - 44 / 2} width="44" height="44" viewBox="0 -2 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" width="40" height="40" rx="10" fill="white"/>
    <rect x="3" y="1" width="38" height="38" rx="9" stroke="currentColor" strokeWidth="2"/>
    <rect x="7.5" y="5.5" width="29" height="29" rx="5.5" stroke="currentColor"/>
    <path
      d="M30.6 21.5V24.5C30.6 25.3284 29.9434 26 29.1333 26H14.4667C13.6566 26 13 25.3284 13 24.5V21.5C13 20.6716 13.6566 20 14.4667 20H29.1333C29.9434 20 30.6 20.6716 30.6 21.5ZM29.1333 19C29.4562 18.9997 29.7759 19.0652 30.0737 19.1927L27.1243 14.6679C26.9904 14.4625 26.8089 14.294 26.596 14.1775C26.3831 14.061 26.1454 14 25.9039 14H17.6961C17.4546 14 17.2169 14.061 17.004 14.1775C16.7911 14.294 16.6097 14.4625 16.4757 14.6679L13.5263 19.1927C13.8241 19.0652 14.1438 18.9997 14.4667 19H29.1333ZM27.6667 22C27.1267 22 26.6889 22.4477 26.6889 23C26.6889 23.5523 27.1267 24 27.6667 24C28.2067 24 28.6444 23.5523 28.6444 23C28.6444 22.4477 28.2067 22 27.6667 22ZM24.7333 22C24.1933 22 23.7556 22.4477 23.7556 23C23.7556 23.5523 24.1933 24 24.7333 24C25.2733 24 25.7111 23.5523 25.7111 23C25.7111 22.4477 25.2733 22 24.7333 22Z"
      fill="currentColor"/>
  </svg>
);