export const OPEN_INSIGHTS_TAB = 'OPEN_INSIGHTS_TAB'
export const REFRESH_INSIGHTS_TAB = 'REFRESH_INSIGHTS_TAB'

export const RESET_STORE = 'RESET_STORE'

export const RESET_TABLE_PAGE_CONTENT = 'RESET_TABLE_PAGE_CONTENT'
export const FETCH_TABLE_PAGE_CONTENT = 'FETCH_TABLE_PAGE_CONTENT'

export const POLL_TABLE_PAGE_JOB_REQUEST = 'POLL_TABLE_PAGE_JOB_REQUEST'
export const POLL_TABLE_PAGE_JOB_FAILURE = 'POLL_TABLE_PAGE_JOB_FAILURE'

export const FETCH_TABLE_PAGE_DESC_REQUEST = 'FETCH_TABLE_PAGE_DESC_REQUEST'
export const FETCH_TABLE_PAGE_DESC_SUCCESS = 'FETCH_TABLE_PAGE_DESC_SUCCESS'
export const SET_TABLE_PAGE_DESC_REQUEST_ID = 'SET_TABLE_PAGE_DESC_REQUEST_ID'
export const FETCH_TABLE_PAGE_DESC_FAILURE = 'FETCH_TABLE_PAGE_DESC_FAILURE'
export const RESTORE_TABLE_PAGE_DESC = 'RESTORE_TABLE_PAGE_DESC'

export const FETCH_TABLE_CHART_REQUEST = 'FETCH_TABLE_CHART_REQUEST'
export const FETCH_TABLE_CHART_SUCCESS = 'FETCH_TABLE_CHART_SUCCESS'
export const SET_TABLE_CHART_REQUEST_ID = 'SET_TABLE_CHART_REQUEST_ID'
export const FETCH_TABLE_CHART_FAILURE = 'FETCH_TABLE_CHART_FAILURE'
export const RESTORE_TABLE_CHART = 'RESTORE_TABLE_CHART'
