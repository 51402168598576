import * as React from 'react';
import * as PropTypes from 'prop-types';
import RcSelect from 'rc-select';
import classNames from 'classnames';
import _ from 'lodash';
import warning from 'warning';
import closeCircle from '../../../../../images/icon-close-circle.svg';
import checkCircle from '../../../../../images/icon-check-circle.svg';
import down from '../../../../../../src/images/new-caret-down.png';
import close from '../../../../../images/icon-close.svg'
import '../style/Select.scss'


const SelectPropTypes = {
    prefixCls: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    notFoundContent: PropTypes.any,
    showSearch: PropTypes.bool,
    optionLabelProp: PropTypes.string,
    transitionName: PropTypes.string,
    choiceTransitionName: PropTypes.string,
    id: PropTypes.string,
};

export default class Select extends React.Component {

    static defaultProps = {
        showSearch: false,
        transitionName: 'slide-up',
        choiceTransitionName: 'zoom',
    };


    constructor(props) {
        super(props);

        warning(
            props.mode !== 'combobox',
            'Select',
            'The combobox mode is deprecated, ' +
            'it will be removed in next major version, ' +
            'please use AutoComplete instead',
        );
    }

    getNotFoundContent(renderEmpty) {
        const { notFoundContent } = this.props;
        if (notFoundContent !== undefined) {
            return notFoundContent;
        }

        if (this.isCombobox()) {
            return null;
        }

        return renderEmpty('Select');
    }

    saveSelect = (node) => {
        this.rcSelect = node;
    };

    focus() {
        this.rcSelect.focus();
    }

    blur() {
        this.rcSelect.blur();
    }

    isCombobox() {
        const { mode } = this.props;
        return mode === 'combobox' || mode === 'SECRET_COMBOBOX_MODE_DO_NOT_USE';
    }

    renderSuffixIcon(prefixCls) {
        const { loading, suffixIcon } = this.props;
        if (suffixIcon) {
            return React.isValidElement(suffixIcon)
                ? React.cloneElement(suffixIcon, {
                    className: classNames(suffixIcon.props.className, `${prefixCls}-arrow-icon`),
                })
                : suffixIcon;
        }
        return <i className={`${prefixCls}-arrow-icon`}><img width={'8px'} height={'5px'} alt="down" src={down}  /></i>;
    }

    render() {
        const {
            className = '',
            size,
            mode,
            getPopupContainer,
            removeIcon,
            clearIcon,
            menuItemSelectedIcon,
            notFoundContent,
            showArrow,
            ...restProps
        } = this.props;
        const rest = _.omit(restProps, ['inputIcon']);

        const prefixCls = 'pan-select';
        const getContextPopupContainer = triggerNode => triggerNode
        const cls = classNames(
            {
                [`${prefixCls}-lg`]: size === 'large',
                [`${prefixCls}-sm`]: size === 'small',
                [`${prefixCls}-show-arrow`]: showArrow,
            },
            className,
        );

        let { optionLabelProp } = this.props;
        if (this.isCombobox()) {
            // children 带 dom 结构时，无法填入输入框
            optionLabelProp = optionLabelProp || 'value';
        }

        const modeConfig = {
            multiple: mode === 'multiple',
            tags: mode === 'tags',
            combobox: this.isCombobox(),
        };

        const finalRemoveIcon = (removeIcon &&
            (React.isValidElement(removeIcon)
                ? React.cloneElement(removeIcon, {
                    className: classNames(removeIcon.props.className, `${prefixCls}-remove-icon`),
                })
                : removeIcon)) || <i className={`${prefixCls}-remove-icon`}><img width={'12px'} height={'12px'} alt="close" src={close}  /></i>;

        const finalClearIcon = (clearIcon &&
            (React.isValidElement(clearIcon)
                ? React.cloneElement(clearIcon, {
                    className: classNames(clearIcon.props.className, `${prefixCls}-clear-icon`),
                })
                : clearIcon)) || (
            <i className={`${prefixCls}-clear-icon`}><img width={'12px'} height={'12px'} alt="close-circle" src={closeCircle}/></i>
        );

        const finalMenuItemSelectedIcon = (menuItemSelectedIcon &&
            (React.isValidElement(menuItemSelectedIcon)
                ? React.cloneElement(menuItemSelectedIcon, {
                    className: classNames(
                        menuItemSelectedIcon.props.className,
                        `${prefixCls}-selected-icon`,
                    ),
                })
                : menuItemSelectedIcon)) || <i className={`${prefixCls}-check-icon`}><img width={'12px'} height={'12px'} alt="check-circle" src={checkCircle}/></i>;

        return (
            <RcSelect
                inputIcon={this.renderSuffixIcon(prefixCls)}
                removeIcon={finalRemoveIcon}
                clearIcon={finalClearIcon}
                menuItemSelectedIcon={finalMenuItemSelectedIcon}
                showArrow={showArrow}
                {...rest}
                {...modeConfig}
                prefixCls={prefixCls}
                className={cls}
                optionLabelProp={optionLabelProp || 'children'}
                notFoundContent={notFoundContent}
                getPopupContainer={getPopupContainer || getContextPopupContainer}
                ref={this.saveSelect}
            />
        );
    }
}
