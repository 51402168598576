import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import Anchor from './NavigatableViewer'
import classnames from 'classnames'

export default class DetailsDrawer extends Component {
	render() {
		const { schema, selectedRow, logType } = this.props
		const [vendor, type] = logType.split('.')
		let logIndex = selectedRow.__table_row_index
		delete selectedRow.__table_row_index

		let selectedLogData
		let selectedLogType
		selectedLogType = selectedRow.log_type.value.toLowerCase()
		selectedLogData = selectedRow

		let groupsInfo = schema[vendor].content[selectedLogType].groupFieldsOrder
		let fieldsInfo = schema[vendor].content[selectedLogType].fields
		return (
			<div className="log-detail-tabs">
				<Nav tabs>
					<NavItem>
						<NavLink className={classnames({ active: true })}>Details</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab="1">
					<TabPane tabId="1">
						<Anchor groupsInfo={groupsInfo} fieldsInfo={fieldsInfo} logData={selectedLogData} logIndex={logIndex} />
					</TabPane>
				</TabContent>
			</div>
		)
	}
}
