import { Card, Input } from 'antd';
import svgVSys from '../../../../../../images/icon-vsys.svg';
import React, { useState } from 'react';

import 'antd/lib/card/style/css';
import 'antd/lib/input/style/css';

import './DeviceList.scss'

export function DeviceList({title, devices, onClickDevice}) {
    if (!devices) return null;
    let [list, setList] = useState(devices);
    const onChange = (event) => {
        const search = event.currentTarget.value;
        if (search === '') {
            setList(devices);
        } else {
            setList(devices.filter(f => f.hostname.toLowerCase().includes(search.toLowerCase())));
        }
    };
    return (<div className={'device-list'}>
        <Input allowClear onChange={onChange} placeholder={'Search'}/>
        <Card size="small" title={title} className={'card'}>
            <ul>
                {list.map((device, index) => (
                    <li key={index}
                        onClick={() => onClickDevice(device)}>
                        <img src={svgVSys} alt={'vsys'}/>
                        {device.hostname}
                    </li>
                ))}
            </ul>
        </Card>
    </div>);
}