import React, { Component } from 'react'

export default class HostnameCellRender extends Component {
	render() {
		const { data, devicesDetails, updateActiveApp, redirectToAppRoot } = this.props
		let hostname = 'N/A'
		if (data.row_desc === 'show_closed') {
			return null
		} else {
			hostname = _.get(devicesDetails, `${data.appliance_id}.hostname`, 'N/A')
		}
		return (
			<span className="hostname-cell">
				<span
					className="hostname"
					onClick={() => {
						updateActiveApp('insights')
						this.props.openDevicePage({ hostname, serial: data.appliance_id })
						redirectToAppRoot()
					}}
				>
					{hostname}
				</span>
			</span>
		)
	}
}
