import * as actionTypes from './actionTypes'
import moment from 'moment'

const ACTION_HANDLERS = {
	[actionTypes.SET_DEVICE_PAGE_DESC]: (state, { pageDesc }) => {
		return {
			...state,
			devicePageDesc: pageDesc
		}
	},
	/*----- Reset Device Page Reducers -----*/
	[actionTypes.RESET_STORE]: (state, { pageType }) => {
		if (pageType === 'DeviceDetails') {
			return {
				..._.cloneDeep(initialState),
				devicePageDesc: state.devicePageDesc
			}
		} else {
			return { ...state }
		}
	},
	/*----- Open Device Page Reducers -----*/
	[actionTypes.OPEN_INSIGHTS_DEVICE_DETAILS]: (state, { pageDesc }) => {
		return {
			...state,
			devicePageDesc: { device: pageDesc.serial, hostname: pageDesc.hostname }
		}
	},
	/*----------- Device Metrics Reducer ---------------*/
	[actionTypes.FETCH_DEVICE_METRICS_REQUEST]: (state, {}) => {
		return {
			...state,
			deviceMetrics: null,
			deviceMetricsRequestId: null,
			deviceMetricsLoading: true,
			deviceMetricsError: null
		}
	},
	[actionTypes.SET_DEVICE_METRICS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			deviceMetricsRequestId: jobId
		}
	},
	[actionTypes.FETCH_DEVICE_METRICS_SUCCESS]: (state, { deviceMetrics }) => {
		return {
			...state,
			deviceMetrics,
			deviceMetricsRequestId: null,
			deviceMetricsLoading: false,
			deviceMetricsError: null
		}
	},
	[actionTypes.FETCH_DEVICE_METRICS_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceMetrics: null,
			deviceMetricsRequestId: null,
			deviceMetricsLoading: false,
			deviceMetricsError: error
		}
	},
	[actionTypes.UPDATE_DEVICE_METRICS_QUERY]: (state, { query }) => {
		return {
			...state,
			deviceMetricsQuery: query
		}
	},
	/*----------- Device Alerts Reducer ---------------*/
	[actionTypes.FETCH_DEVICE_ALERTS_REQUEST]: (state, {}) => {
		return {
			...state,
			deviceAlerts: null,
			deviceAlertsLoading: true,
			deviceAlertsError: null
		}
	},
	[actionTypes.FETCH_DEVICE_ALERTS_SUCCESS]: (state, { deviceAlerts }) => {
		return {
			...state,
			deviceAlerts,
			deviceAlertsLoading: false,
			deviceAlertsError: null
		}
	},
	[actionTypes.FETCH_DEVICE_ALERTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceAlerts: null,
			deviceAlertsLoading: false,
			deviceAlertsError: error
		}
	},
	[actionTypes.UPDATE_DEVICE_ALERT_SUCCESS]: (state, { alert, alertId }) => {
		const updatedAlerts = [...state.deviceAlerts]
		const index = updatedAlerts.findIndex((alert) => alert.alert_id === alertId)
		if (index > -1) updatedAlerts[index] = alert
		return {
			...state,
			deviceAlerts: updatedAlerts,
			deviceAlertsLoading: false,
			deviceAlertsError: null
		}
	},
	[actionTypes.FETCH_CLOSED_DEVICE_ALERTS_REQUEST]: (state, {}) => {
		return {
			...state,
			closedDeviceAlerts: null,
			closedDeviceAlertsLoading: true,
			closedDeviceAlertsError: null
		}
	},
	[actionTypes.FETCH_CLOSED_DEVICE_ALERTS_SUCCESS]: (state, { alerts }) => {
		return {
			...state,
			closedDeviceAlerts: alerts,
			closedDeviceAlertsLoading: false,
			closedDeviceAlertsError: null
		}
	},
	[actionTypes.FETCH_CLOSED_DEVICE_ALERTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			closedDeviceAlertsLoading: false,
			closedDeviceAlertsError: error
		}
	},
	/*----------- Device Commit Events Reducer ---------------*/
	[actionTypes.FETCH_DEVICE_COMMIT_EVENTS_REQUEST]: (state, {}) => {
		return {
			...state,
			deviceCommitEvents: null,
			deviceCommitEventsRequestId: null,
			deviceCommitEventsLoading: true,
			deviceCommitEventsError: null
		}
	},
	[actionTypes.SET_DEVICE_COMMIT_EVENTS_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			deviceCommitEventsRequestId: jobId
		}
	},
	[actionTypes.FETCH_DEVICE_COMMIT_EVENTS_SUCCESS]: (state, { deviceCommitEvents }) => {
		return {
			...state,
			deviceCommitEvents,
			deviceCommitEventsRequestId: null,
			deviceCommitEventsLoading: false,
			deviceCommitEventsError: null
		}
	},
	[actionTypes.FETCH_DEVICE_COMMIT_EVENTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceCommitEvents: null,
			deviceCommitEventsRequestId: null,
			deviceCommitEventsLoading: false,
			deviceCommitEventsError: error
		}
	},
	/*------------ Time Settings Reducer ---------------*/
	[actionTypes.UPDATE_DEVICE_INFO_TIME_PRESET]: (state, { deviceInfoTimePreset }) => {
		return {
			...state,
			deviceInfoTimePreset
		}
	},
	[actionTypes.UPDATE_DEVICE_INFO_TIME_RANGE]: (state, { deviceInfoStartTime, deviceInfoEndTime }) => {
		return {
			...state,
			deviceInfoStartTime,
			deviceInfoEndTime
		}
	},
	[actionTypes.FETCH_DEVICE_DETAILS_REQUEST]: (state, {}) => {
		return {
			...state,
			deviceDetails: null,
			deviceDetailsLoading: true,
			deviceDetailsError: null
		}
	},
	[actionTypes.FETCH_DEVICE_DETAILS_SUCCESS]: (state, { deviceDetails }) => {
		return {
			...state,
			deviceDetails,
			deviceDetailsLoading: false,
			deviceDetailsError: null
		}
	},
	[actionTypes.FETCH_DEVICE_DETAILS_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceDetails: null,
			deviceDetailsLoading: false,
			deviceDetailsError: error
		}
	},
	/*--------------- Comments ---------------*/
	[actionTypes.FETCH_DEVICE_ALERT_COMMENTS_REQUEST]: (state, { alertId }) => {
		const updatedComments = { ...state.deviceAlertsComments }
		updatedComments[alertId] = { isLoading: true }
		return {
			...state,
			deviceAlertsComments: updatedComments,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: null
		}
	},
	[actionTypes.FETCH_DEVICE_ALERT_COMMENTS_SUCCESS]: (state, { comments, alertId }) => {
		const updatedComments = { ...state.deviceAlertsComments }
		updatedComments[alertId] = { isLoading: false, data: comments }
		return {
			...state,
			deviceAlertsComments: updatedComments,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: null
		}
	},
	[actionTypes.FETCH_DEVICE_ALERT_COMMENTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: error
		}
	},
	[actionTypes.ADD_DEVICE_ALERT_COMMENT_REQUEST]: (state, { comment, alertId }) => {
		const updatedComments = { ...state.deviceAlertsComments }
		if (_.get(updatedComments, alertId)) {
			updatedComments[alertId].isLoading = true
		} else {
			updatedComments[alertId] = { isLoading: true, data: [] }
		}
		return {
			...state,
			deviceAlertsComments: updatedComments,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: null
		}
	},
	[actionTypes.ADD_DEVICE_ALERT_COMMENT_SUCCESS]: (state, { comment, alertId }) => {
		const updatedComments = { ...state.deviceAlertsComments }
		updatedComments[alertId].isLoading = false
		updatedComments[alertId].data.push(comment)
		return {
			...state,
			deviceAlertsComments: updatedComments,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: null
		}
	},
	[actionTypes.ADD_DEVICE_ALERT_COMMENT_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: error
		}
	},
	[actionTypes.REMOVE_DEVICE_ALERT_COMMENT_REQUEST]: (state, { commentId, alertId }) => {
		const updatedComments = { ...state.deviceAlertsComments }
		updatedComments[alertId].isLoading = true

		return {
			...state,
			deviceAlertsComments: updatedComments,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: null
		}
	},
	[actionTypes.REMOVE_DEVICE_ALERT_COMMENT_SUCCESS]: (state, { commentId, alertId }) => {
		const updatedComments = { ...state.deviceAlertsComments }
		updatedComments[alertId].isLoading = false
		_.remove(updatedComments[alertId].data, (comment) => comment.comment_id === commentId)
		return {
			...state,
			deviceAlertsComments: updatedComments,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: null
		}
	},
	[actionTypes.REMOVE_DEVICE_ALERT_COMMENT_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: error
		}
	},
	[actionTypes.UPDATE_DEVICE_ALERT_COMMENT_REQUEST]: (state, { alertId }) => {
		const updatedComments = { ...state.deviceAlertsComments }
		updatedComments[alertId].isLoading = true

		return {
			...state,
			deviceAlertsComments: updatedComments,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: null
		}
	},
	[actionTypes.UPDATE_DEVICE_ALERT_COMMENT_SUCCESS]: (state, { comment, alertId, commentId }) => {
		const updatedComments = { ...state.deviceAlertsComments }
		updatedComments[alertId].isLoading = false
		_.remove(updatedComments[alertId].data, (comment) => comment.comment_id === commentId)
		updatedComments[alertId].data.push(comment)
		return {
			...state,
			deviceAlertsComments: updatedComments,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: null
		}
	},
	[actionTypes.UPDATE_DEVICE_ALERT_COMMENT_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceAlertsCommentsLoading: false,
			deviceAlertsCommentsError: error
		}
	},
	/*--------------- Events ---------------*/
	[actionTypes.FETCH_DEVICE_ALERT_EVENTS_REQUEST]: (state, { alertId }) => {
		const updatedEvents = { ...state.deviceAlertsEvents }
		updatedEvents[alertId] = { isLoading: true }
		return {
			...state,
			deviceAlertsEvents: updatedEvents,
			deviceAlertsEventsLoading: false,
			deviceAlertsEventsError: null
		}
	},
	[actionTypes.FETCH_DEVICE_ALERT_EVENTS_SUCCESS]: (state, { events, alertId }) => {
		const updatedEvents = { ...state.deviceAlertsEvents }
		updatedEvents[alertId] = { isLoading: false, data: events }
		return {
			...state,
			deviceAlertsEvents: updatedEvents,
			deviceAlertsEventsLoading: false,
			deviceAlertsEventsError: null
		}
	},
	[actionTypes.FETCH_DEVICE_ALERT_EVENTS_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceAlertsEventsLoading: false,
			deviceAlertsEventsError: error
		}
	},
	/*--------------- Activities ---------------*/
	[actionTypes.FETCH_DEVICE_ALERT_ACTIVITIES_REQUEST]: (state, { alertId }) => {
		const updatedActivities = { ...state.deviceAlertsActivities }
		updatedActivities[alertId] = { isLoading: true }
		return {
			...state,
			deviceAlertsActivities: updatedActivities,
			deviceAlertsActivitiesLoading: false,
			deviceAlertsActivitiesError: null
		}
	},
	[actionTypes.FETCH_DEVICE_ALERT_ACTIVITIES_SUCCESS]: (state, { activities, alertId }) => {
		const updatedActivities = { ...state.deviceAlertsActivities }
		updatedActivities[alertId] = { isLoading: false, data: activities }
		return {
			...state,
			deviceAlertsActivities: updatedActivities,
			deviceAlertsActivitiesLoading: false,
			deviceAlertsActivitiesError: null
		}
	},
	[actionTypes.FETCH_DEVICE_ALERT_ACTIVITIES_FAILURE]: (state, { error }) => {
		return {
			...state,
			deviceAlertsActivities: [],
			deviceAlertsActivitiesLoading: false,
			deviceAlertsActivitiesError: error
		}
	}
}

const initialState = {
	devicePageDesc: null,
	deviceDetails: null,
	deviceDetailsLoading: false,
	deviceDetailsError: null,
	deviceMetrics: null,
	deviceMetricsRequestId: null,
	deviceMetricsLoading: true,
	deviceMetricsError: null,
	deviceMetricsQuery: '',
	deviceAlerts: null,
	deviceAlertsLoading: true,
	deviceAlertsError: null,
	deviceCommitEvents: null,
	deviceCommitEventsRequestId: null,
	deviceCommitEventsLoading: true,
	deviceCommitEventsError: null,
	deviceInfoTimePreset: 'last_30_days',
	deviceInfoStartTime: null,
	deviceInfoEndTime: null,
	closedDeviceAlerts: null,
	closedDeviceAlertsLoading: false,
	closedDeviceAlertsError: null,
	deviceAlertsEvents: null,
	deviceAlertsEventsLoading: false,
	deviceAlertsEventsError: null,
	deviceAlertsActivities: null,
	deviceAlertsActivitiesLoading: true,
	deviceAlertsActivitiesError: null,
	deviceAlertsComments: null,
	deviceAlertsCommentsLoading: false,
	deviceAlertsCommentsError: null
}

export const devicePageReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
