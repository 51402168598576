import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of, concat, forkJoin } from 'rxjs'
import _ from 'lodash'

import Constants from '../../../utils/Constants.json'
import Util from '../../../utils/Util'

import * as services from '../../../store/services'
import * as actionTypes from './actionTypes'
import * as actions from './actions'
import * as alertsActions from '../../../store/actions'
import * as mainActions from '../../../../../main/actions'

import pageInfo from '../../pageUtil'

export const fetchAlertDetailPageContentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_DETAILS_PAGE_CONTENT, actionTypes.REFRESH_ALERTS_TAB),
		switchMap((action) => {
			if (action.type === actionTypes.REFRESH_ALERTS_TAB && action.pageType !== pageInfo.AlertDetails.pageType) {
				return of()
			}
			const { alertsTabs, activeAlertsTab } = store.value.alerts
			const currentTab = alertsTabs.custom_tabs[activeAlertsTab]
			const { state: cachedState, data: cachedData } = currentTab
			// 1. Restore State
			const stateActionList = []
			const { showCommitEvents, showRefLines, timeRange } = cachedState
			stateActionList.push(actions.setShowAlertCommitEvents(showCommitEvents), actions.setShowAlertRefLines(showRefLines), actions.setAlertTimeRangePreset(timeRange))
			// 2. Restore Data
			const dataActionList = []
			if (!_.isEmpty(cachedData)) {
				const { alert, deviceDetails, alertCommitEvents, alertForecast, alertCapacity, alertMovingAverage, alertTimeline, alertTableChartsData } = cachedData
				dataActionList.push(actions.restoreAlert({ alert }), actions.restoreDeviceDetails({ deviceDetails }))
				const metric = _.get(alert[0], 'metrics[0]')
				if (metric in Constants.TABLE_CHART_INFO_MAP) {
					dataActionList.push(actions.restoreAlertTableCharts({ alertTableChartsData }))
					return concat(of(...stateActionList), of(...dataActionList))
				} else {
					dataActionList.push(
						actions.restoreAlertTimeline({ alertTimeline }),
						actions.restoreAlertCapacity({ alertCapacity }),
						actions.restoreAlertMovingAverage({ alertMovingAverage }),
						actions.restoreAlertCommitEvents({ alertCommitEvents }),
						actions.restoreAlertForecastEvents({ alertForecast })
					)
					return concat(of(...stateActionList), of(actions.updateAlertTimelineTimeRange(timeRange)), of(...dataActionList))
				}
			} else {
				dataActionList.push(actions.fetchAlertRequest(currentTab.desc.alertid))
				return concat(of(alertsActions.resetStore('AlertDetails')), of(alertsActions.updateAlertsLastRefresh(activeAlertsTab)), of(...stateActionList), of(...dataActionList))
			}
		})
	)
}

/*------------ Alert --------------*/
export const fetchAlertEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_REQUEST),
		switchMap((action) => {
			return services.getAlert(store.value, action.alertId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							return concat(of(actions.fetchAlertSuccess([])), of(actions.fetchDeviceDetailsSuccess([])))
						} else {
							// 2. Response is not empty}
							const devices = [response.response].map((alert) => alert.appliance_id)
							return concat(of(actions.fetchAlertEventsRequest(action.alertId)), of(actions.fetchAlertSuccess([response.response])), of(actions.fetchDeviceDetailsRequest(devices)))
						}
					} else {
						throw 'Error Fetching Alert'
					}
				}),
				catchError((error) => {
					return of(actions.fetchAlertFailure(error))
				})
			)
		})
	)
}

/*------------ Device Details --------------*/
export const fetchAlertDeviceDetailsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_DEVICE_DETAILS_REQUEST),
		switchMap((action) => {
			if (action.devices.length === 0) {
				return of(actions.fetchDeviceDetailsSuccess([]))
			}
			return services.getDevicesDetails(store.value, action.devices).pipe(
				mergeMap(({ response }) => {
					return of(actions.setDeviceDetailsRequestId(response.jobId), actions.pollAlertDetailsPageJobRequest(response.jobId, 'device_details', 0))
				}),
				catchError((error) => {
					return of(actions.fetchDeviceDetailsFailure(error))
				})
			)
		})
	)
}

/*------------ Status --------------*/
export const updateAlertStatusEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_ALERT_STATUS_REQUEST),
		switchMap((action) => {
			return services.updateAlert(store.value, action.alertId, action.status, action.assignee).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							throw 'Error Updating Alert'
						} else {
							// 2. Response is not empty}
							return concat(of(actions.updateAlertStatusSuccess(response.response, action.alertId)), of(actions.fetchAlertActivitiesRequest('', action.alertId, action.alertId)))
						}
					} else {
						throw 'Error Updating Alert'
					}
				}),
				catchError((error) => {
					return of(actions.updateAlertStatusFailure(error))
				})
			)
		})
	)
}

/*------------ Comments --------------*/
export const fetchAlertCommentsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_COMMENTS_REQUEST),
		switchMap((action) => {
			return services.getComments(store.value, action.alertId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							return of(actions.fetchAlertCommentsSuccess([], action.alertId))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchAlertCommentsSuccess(response.response, action.alertId))
						}
					} else {
						throw 'Error Fetching Comments'
					}
				}),
				catchError((error) => {
					return of(actions.fetchAlertCommentsFailure(error))
				})
			)
		})
	)
}

export const addAlertCommentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.ADD_ALERT_COMMENT_REQUEST),
		switchMap((action) => {
			return services.addComment(store.value, action.alertId, action.comment).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							throw 'Error Saving Comments'
						} else {
							// 2. Response is not empty}
							return concat(of(actions.addAlertCommentSuccess(response.response, action.alertId)), of(actions.fetchAlertActivitiesRequest('', action.alertId, action.alertId)))
						}
					} else {
						throw 'Error Saving Comments'
					}
				}),
				catchError((error) => {
					return of(actions.addAlertCommentFailure(error))
				})
			)
		})
	)
}

export const updateAlertCommentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_ALERT_COMMENT_REQUEST),
		switchMap((action) => {
			return services.updateComment(store.value, action.alertId, action.commentId, action.comment).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							throw 'Error Updating Comments'
						} else {
							// 2. Response is not empty}
							return concat(
								of(actions.updateAlertCommentSuccess(response.response, action.alertId, action.commentId)),
								of(actions.fetchAlertActivitiesRequest('', action.alertId, action.alertId))
							)
						}
					} else {
						throw 'Error Updating Comments'
					}
				}),
				catchError((error) => {
					return of(actions.updateAlertCommentFailure(error))
				})
			)
		})
	)
}

export const removeAlertCommentEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.REMOVE_ALERT_COMMENT_REQUEST),
		switchMap((action) => {
			return services.removeComment(store.value, action.alertId, action.commentId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						return concat(of(actions.removeAlertCommentSuccess(action.commentId, action.alertId)), of(actions.fetchAlertActivitiesRequest('', action.alertId, action.alertId)))
					} else {
						throw 'Error Fetching Comments'
					}
				}),
				catchError((error) => {
					return of(actions.removeAlertCommentFailure(error))
				})
			)
		})
	)
}

/*------------ Events --------------*/
export const fetchAlertEventsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_EVENTS_REQUEST),
		switchMap((action) => {
			return services.getEvents(store.value, action.alertId).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							return of(actions.fetchAlertEventsSuccess([], action.alertId))
						} else {
							return of(actions.fetchAlertEventsSuccess(response.response, action.alertId))
						}
					} else {
						throw 'Error Fetching Events'
					}
				}),
				catchError((error) => {
					return of(actions.fetchAlertEventsFailure(error))
				})
			)
		})
	)
}

/*------------ Activities --------------*/
export const fetchAlertActivitiesEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_ACTIVITIES_REQUEST),
		switchMap((action) => {
			return services.getActivities(store.value, action.subject, action.object_id).pipe(
				mergeMap((response) => {
					if (response.status === 200) {
						if (_.isEmpty(response.response)) {
							// 1. Response is empty
							return of(actions.fetchAlertActivitiesSuccess([], action.alertId))
						} else {
							// 2. Response is not empty}
							return of(actions.fetchAlertActivitiesSuccess(response.response, action.alertId))
						}
					} else {
						throw 'Error Fetching Activities'
					}
				}),
				catchError((error) => {
					return of(actions.fetchAlertActivitiesFailure(error))
				})
			)
		})
	)
}

/*------------ Charts --------------*/
export const fetchChartsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_CHARTS),
		switchMap((action) => {
			const alertInfo = store.value.alertDetails.alert && store.value.alertDetails.alert[0]
			const deviceInfo = store.value.alertDetails.deviceDetails[alertInfo.appliance_id]
			if (!alertInfo || !deviceInfo) {
				return of()
			}
			const metrics = alertInfo.metrics || []
			if (metrics[0] in Constants.TABLE_CHART_INFO_MAP) {
				const dataActionList = []
				metrics.forEach((metric) => {
					dataActionList.push(actions.fetchAlertTableChartRequest({ ...deviceInfo, metric }, alertInfo.alert_id))
				})
				return concat(of(actions.resetAlertTableCharts()), of(...dataActionList))
			} else {
				const timeRangePreset = store.value.alertDetails.alertTimeRangePreset
				const dataActionList = []
				let capacityList = {}
				const movingAverageList = []
				metrics.forEach((metric) => {
					const lineChartInfo = Constants.LINE_CHART_INFO_MAP[Util.getGenericMetricName(metric)]
					if (!(Util.getGenericMetricName(metric) in capacityList)) {
						if (lineChartInfo && lineChartInfo.capacity_name !== 'N/A') {
							capacityList[Util.getGenericMetricName(metric)] = lineChartInfo.capacity_name
						}
					}
					if (lineChartInfo && lineChartInfo.hasMovingAverage && Constants.TIME_RANGE_PRESETS[timeRangePreset].hasMovingAverage) {
						movingAverageList.push(metric)
					}
				})
				dataActionList.push(
					// actions.fetchAlertEventsRequest(alertInfo.alert_id),
					actions.fetchAlertTimelineRequest(deviceInfo.serial, metrics, alertInfo.alert_id),
					actions.fetchAlertCapacityRequest(deviceInfo, capacityList, alertInfo.alert_id),
					actions.fetchAlertCommitEventsRequest(deviceInfo.serial, alertInfo.alert_id),
					actions.fetchAlertMovingAverageRequest(deviceInfo.serial, movingAverageList, alertInfo.alert_id),
					actions.fetchAlertForecastRequest(deviceInfo.serial, metrics)
				)
				return concat(of(actions.updateAlertTimelineTimeRange(timeRangePreset)), of(...dataActionList))
			}
		})
	)
}

export const updateAlertTimeRangePresetEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_ALERT_TIME_RANGE_PRESET),
		switchMap((action) => {
			const prefActionList = []
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const timeRangePref = _.get(userPref, `alerts.custom_tabs[${store.value.alerts.activeAlertsTab}].state.timeRange`)
			if (!_.isEqual(timeRangePref, action.alertTimeRangePreset)) {
				_.set(userPref, `alerts.custom_tabs[${store.value.alerts.activeAlertsTab}].state.timeRange`, action.alertTimeRangePreset)
				prefActionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}
			return concat(of(actions.setAlertTimeRangePreset(action.alertTimeRangePreset)), of(...prefActionList), of(actions.fetchCharts()))
		})
	)
}

export const updateShowAlertCommitEventsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_SHOW_ALERT_COMMIT_EVENTS),
		switchMap((action) => {
			const prefActionList = []
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const showCommitEventsPref = _.get(userPref, `alerts.custom_tabs[${store.value.alerts.activeAlertsTab}].state.showAlertCommitEvents`)
			if (!_.isEqual(showCommitEventsPref, action.showAlertCommitEvents)) {
				_.set(userPref, `alerts.custom_tabs[${store.value.alerts.activeAlertsTab}].state.showCommitEvents`, action.showAlertCommitEvents)
				prefActionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}
			return concat(of(actions.setShowAlertCommitEvents(action.showAlertCommitEvents)), of(...prefActionList))
		})
	)
}

export const updateShowAlertRefLinesEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.UPDATE_SHOW_ALERT_REF_LINES),
		switchMap((action) => {
			const prefActionList = []
			const userPref = store.value.main.loggedInUser.preference ? _.cloneDeep(store.value.main.loggedInUser.preference) : {}
			const showRefLinesPref = _.get(userPref, `alerts.custom_tabs[${store.value.alerts.activeAlertsTab}].state.showRefLines`)
			if (!_.isEqual(showRefLinesPref, action.showAlertRefLines)) {
				_.set(userPref, `alerts.custom_tabs[${store.value.alerts.activeAlertsTab}].state.showRefLines`, action.showAlertRefLines)
				prefActionList.push(mainActions.saveUserPreference(userPref, Constants.DEFAULT_DEBOUNCE_TIME), mainActions.setUserPreference(userPref))
			}
			return concat(of(actions.setShowAlertRefLines(action.showAlertRefLines)), of(...prefActionList))
		})
	)
}

export const fetchAlertTimelineEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_TIMELINE_REQUEST),
		switchMap((action) => {
			return forkJoin(action.metrics.map((metric) => services.getTimeline(store.value, action.device, metric))).pipe(
				mergeMap((response) => {
					if (action.alertId !== _.get(store.value.alertDetails, 'alert[0].alert_id')) {
						return of(actions.pollAlertDetailsPageJobFailure('Cancelled'))
					}
					const requestIds = []
					const timelineJobs = action.metrics.map((metric, index) => {
						const jobId = response[index].response.jobId
						requestIds.push(jobId)
						return actions.pollAlertDetailsPageJobRequest(jobId, 'timeline', 0, null, { metric })
					})
					return concat(of(actions.setAlertTimelineRequestId(requestIds)), of(...timelineJobs))
				}),
				catchError((error) => {
					return of(actions.fetchAlertTimelineFailure(error))
				})
			)
		})
	)
}

export const fetchAlertMovingAverageEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_MOV_AVG_REQUEST),
		switchMap((action) => {
			if (action.metrics.length === 0) {
				return of(actions.fetchAlertMovingAverageSuccess({}, null, null))
			}

			return forkJoin(action.metrics.map((metric) => services.getMovingAverage(store.value, action.device, metric))).pipe(
				mergeMap((response) => {
					if (action.alertId !== _.get(store.value.alertDetails, 'alert[0].alert_id')) {
						return of(actions.pollAlertDetailsPageJobFailure('Cancelled'))
					}
					const requestIds = []
					const movingAverageJobs = action.metrics.map((metric, index) => {
						const jobId = response[index].response.jobId
						requestIds.push(jobId)
						return actions.pollAlertDetailsPageJobRequest(jobId, 'moving_average', 0, null, { metric })
					})
					return [actions.setAlertMovingAverageRequestId(requestIds), ...movingAverageJobs]
				}),
				catchError((error) => {
					return of(actions.fetchAlertMovingAverageFailure(error))
				})
			)
		})
	)
}

export const fetchAlertCommitEventsEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_COMMIT_EVENTS_REQUEST),
		switchMap((action) => {
			return services.getCommitEvents(store.value, action.device).pipe(
				mergeMap(({ response }) => {
					if (action.alertId !== _.get(store.value.alertDetails, 'alert[0].alert_id')) {
						return of(actions.pollAlertDetailsPageJobFailure('Cancelled'))
					}
					return of(actions.setAlertCommitEventsRequestId(response.jobId), actions.pollAlertDetailsPageJobRequest(response.jobId, 'commit_events', 0))
				}),
				catchError((error) => of(actions.fetchAlertCommitEventsFailure(error)))
			)
		})
	)
}

export const fetchAlertForecastEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_FORECAST_REQUEST),
		switchMap((action) => {
			return forkJoin(action.metrics.map((metric) => services.getForecast(store.value, action.device, metric))).pipe(
				mergeMap((response, metric) => {
					const alertForecast = {}
					const alertTrend = {}
					action.metrics.forEach((metric, index) => {
						alertForecast[metric] = response[index].response.forecast
						alertTrend[metric] = response[index].response.trend
					})
					return of(actions.fetchAlertForecastSuccess(alertForecast, alertTrend, action.device))
				}),
				catchError((error) => {
					return of(actions.fetchAlertForecastFailure(error))
				})
			)
		})
	)
}

export const fetchAlertCapacityEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_CAPACITY_REQUEST),
		switchMap((action) => {
			const capacityList = Object.values(action.metricList)
			if (capacityList.length === 0) {
				return of(actions.fetchAlertCapacitySuccess([]))
			}
			return services.getCapacity(store.value, action.deviceInfo, capacityList).pipe(
				mergeMap(({ response }) => {
					return of(actions.fetchAlertCapacitySuccess(response))
				}),
				catchError((error) => of(actions.fetchAlertCapacityFailure(error)))
			)
		})
	)
}

export const fetchAlertTableChartEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.FETCH_ALERT_TABLE_CHART_REQUEST),
		mergeMap((action) => {
			const chartInfoMap = Constants.TABLE_CHART_INFO_MAP
			let { api, jobApi } = chartInfoMap[action.deviceInfo.metric]
			//check api type, decide following step
			if (api && jobApi) {
				const apiCall = services.getChartInfo(store.value, api, action.deviceInfo)
				const jobApiCall = services.getChartInfoJob(store.value, jobApi, action.deviceInfo)
				return forkJoin([apiCall, jobApiCall]).pipe(
					mergeMap((result) => {
						if (action.alertId !== _.get(store.value.alertDetails, 'alert[0].alert_id')) {
							return of(actions.pollAlertDetailsPageJobFailure('Cancelled'))
						}
						const apiResponse = result[0].response
						const jobApiResponse = result[1].response
						return of(
							actions.setAlertTableChartRequestId(jobApiResponse.jobId),
							actions.pollAlertDetailsPageJobRequest(jobApiResponse.jobId, 'table_chart', 0, null, { apiResponse, metric: action.deviceInfo.metric, currentTabJob: jobApiResponse.jobId })
						)
					}),
					catchError((error) => of(actions.fetchAlertTableChartFailure(error)))
				)
			} else if (api) {
				return services.getChartInfo(store.value, api, action.deviceInfo).pipe(
					mergeMap(({ response }) => {
						if (action.alertId !== _.get(store.value.alertDetails, 'alert[0].alert_id')) {
							return of(actions.pollAlertDetailsPageJobFailure('Cancelled'))
						}
						return of(actions.fetchAlertTableChartSuccess(null, response, action.deviceInfo.metric, null))
					}),
					catchError((error) => of(actions.fetchAlertTableChartFailure(error)))
				)
			} else {
				return services.getChartInfoJob(store.value, jobApi, action.deviceInfo).pipe(
					mergeMap(({ response }) => {
						if (action.alertId !== _.get(store.value.alertDetails, 'alert[0].alert_id')) {
							return of(actions.pollAlertDetailsPageJobFailure('Cancelled'))
						}
						return of(
							actions.setAlertTableChartRequestId(response.jobId),
							actions.pollAlertDetailsPageJobRequest(response.jobId, 'table_chart', 0, null, { metric: action.deviceInfo.metric, currentTabJob: response.jobId })
						)
					}),
					catchError((error) => of(actions.fetchAlertTableChartFailure(error)))
				)
			}
		})
	)
}

/*--------- Poll Job ----------*/
export const pollAlertDetailsPageJobEpic = (action$, store) => {
	return action$.pipe(
		ofType(actionTypes.POLL_ALERT_DETAILS_PAGE_JOB_REQUEST),
		mergeMap((action) => {
			return services.pollAlertsJob(action.jobId, action.jobPage).pipe(
				mergeMap(({ response }) => {
					// For logs job, if job expired or pageNumber expired, quit processing
					// For health job, if job expired, quit processing
					if (
						(action.jobType === 'device_details' && response.jobId !== store.value.alertDetails.deviceDetailsRequestId) ||
						(action.jobType === 'timeline' && !store.value.alertDetails.alertTimelineRequestId.includes(response.jobId)) ||
						(action.jobType === 'moving_average' && !store.value.alertDetails.alertMovingAverageRequestId.includes(response.jobId)) ||
						(action.jobType === 'commit_events' && response.jobId !== store.value.alertDetails.alertCommitEventsRequestId) ||
						(action.jobType === 'table_chart' && !store.value.alertDetails.alertTableChartsRequestId.includes(response.jobId))
					) {
						return of(actions.pollAlertDetailsPageJobFailure(response.jobId, action.jobType, 'Cancelled'))
					}
					// If it's still running and job is valid, keep polling
					else if (response.state === 'RUNNING') {
						return of(actions.pollAlertDetailsPageJobRequest(response.jobId, action.jobType, action.jobPage, action.jobLimit, action.params))
					}
					// If it's done, handle response accroding to action type
					else {
						if (action.jobType === 'device_details') {
							return concat(of(actions.fetchDeviceDetailsSuccess(response.page.result.data)), of(actions.fetchCharts()))
						} else if (action.jobType === 'timeline') {
							return of(actions.fetchAlertTimelineSuccess(response.page.result.data, action.params.metric, response.jobId))
						} else if (action.jobType === 'moving_average') {
							return of(actions.fetchAlertMovingAverageSuccess(response.page.result.data, action.params.metric, response.jobId))
						} else if (action.jobType === 'commit_events') {
							return of(actions.fetchAlertCommitEventsSuccess(response.page.result.data))
						} else if (action.jobType === 'table_chart') {
							return of(actions.fetchAlertTableChartSuccess(response.page.result.data, action.params.apiResponse, action.params.metric, action.params.currentTabJob))
						}
					}
				}),
				catchError((error) => {
					if (action.jobType === 'device_details') {
						return of(actions.fetchDeviceDetailsFailure(error))
					} else if (action.jobType === 'timeline') {
						return of(actions.fetchAlertTimelineFailure(error))
					} else if (action.jobType === 'moving_average') {
						return of(actions.fetchAlertMovingAverageFailure(error))
					} else if (action.jobType === 'commit_events') {
						return of(actions.fetchAlertCommitEventsFailure(error))
					} else if (action.jobType === 'table_chart') {
						return of(actions.fetchAlertTableChartFailure(error))
					} else {
						return of(actions.pollAlertDetailsPageJobFailure(action.jobId, action.jobType, error))
					}
				})
			)
		})
	)
}
