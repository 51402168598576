import React, { Component } from 'react'
import { ClearableInputWidget as Input, RadioWidget, ButtonWidget as Button } from 'ui-lib'
import './LogExportConfig.scss'

export default class LogExportConfig extends Component {
	state = {
		filetype: 'csv',
		limit: '66000',
		filename: 'export_logs',
		configErrors: [],
	}

	onExport = () => {
		const { limit, filename } = this.state
		// Check input validity
		const configErrors = []
		if (!/^\d+$/.test(limit) || parseInt(limit) <= 0 || parseInt(limit) > 1000000) {
			configErrors.push('Number of Results must be a positive interger between 1 and 1,000,000')
		}
		if (!/^[\w_@!]+$/.test(filename) || filename.length > 256) {
			configErrors.push('Filename must be a non-empty string with maximum 256 characters. No special characters allowed expect !, @, _')
		}
		if (configErrors.length === 0) {
			this.props.initExportRequest(this.state)
			this.props.onCloseExportConfig()
		}
		this.setState({ configErrors })
	}

	render() {
		return (
			<div className="export-config">
				<div>
					<label htmlFor="limit">Number of Results</label>
					<Input
						value={this.state.limit}
						id="limit"
						allowClear={true}
						onChange={(e) => {
							this.setState({ limit: e.target.value })
						}}
					/>
				</div>
				<div>
					<label htmlFor="filetype">Filetype</label>
					<RadioWidget
						onChange={(filetype) => this.setState({ filetype })}
						id="filetype"
						value={this.state.filetype}
						options={{
							enumOptions: [
								{ value: 'csv', label: 'csv' },
								{ value: 'xml', label: 'xml' },
								{ value: 'json', label: 'json' },
							],
							inline: true,
						}}
					/>
				</div>
				<div>
					<label htmlFor="filename">Filename</label>
					<Input
						value={this.state.filename}
						id="filename"
						allowClear={true}
						onChange={(e) => {
							this.setState({ filename: e.target.value })
						}}
					/>
				</div>
				<div>
					<label htmlFor="errors"></label>
					<div className="error-list">
						{this.state.configErrors.map((error) => (
							<div>* {error}</div>
						))}
					</div>
				</div>
				<div className="export-btns">
					<Button className="export-btn" text="Export" onClick={this.onExport} />
				</div>
			</div>
		)
	}
}
