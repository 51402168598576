import * as actionTypes from './actionTypes'
import moment from 'moment'
import Constants from '../../../utils/Constants.json'
import _ from 'lodash'
import pageInfo from '../../pageUtil'

const ACTION_HANDLERS = {
	[actionTypes.OPEN_INSIGHTS_TAB]: (state, { pageDesc, ignoreExistingDetails }) => {
		if (pageDesc.metric in Constants.TABLE_CHART_INFO_MAP && !ignoreExistingDetails) {
			return {
				..._.cloneDeep(initialState),
				tablePageDesc: pageDesc,
				tablePageDescRequestId: null,
				tablePageDescLoading: false,
				tablePageDescError: null
			}
		} else {
			return { ...state }
		}
	},
	[actionTypes.RESET_STORE]: (state, { pageType }) => {
		if (pageType === pageInfo.TableMetricDetails.pageType) {
			return {
				..._.cloneDeep(initialState)
			}
		} else {
			return { ...state }
		}
	},
	/*----- PollJob Reducers -----*/
	[actionTypes.POLL_TABLE_PAGE_JOB_REQUEST]: (state, { jobId }) => {
		return {
			...state,
			tablePageJobId: jobId,
			tablePageJobError: null
		}
	},
	[actionTypes.POLL_TABLE_PAGE_JOB_FAILURE]: (state, { jobId, error }) => {
		return {
			...state,
			tablePageJobId: jobId,
			tablePageJobError: error
		}
	},
	/*----- Table Page Desc Reducers -----*/
	[actionTypes.FETCH_TABLE_PAGE_DESC_REQUEST]: (state) => {
		return {
			...state,
			tablePageDesc: null,
			tablePageDescLoading: true,
			tablePageDescError: null,
			tablePageDescRequestId: null
		}
	},
	[actionTypes.FETCH_TABLE_PAGE_DESC_SUCCESS]: (state, { pageDesc }) => {
		return {
			...state,
			tablePageDesc: pageDesc,
			tablePageDescLoading: false,
			tablePageDescError: null,
			tablePageDescRequestId: null
		}
	},
	[actionTypes.SET_TABLE_PAGE_DESC_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			tablePageDescRequestId: jobId
		}
	},
	[actionTypes.FETCH_TABLE_PAGE_DESC_FAILURE]: (state, { error }) => {
		return {
			...state,
			tablePageDesc: null,
			tablePageDescLoading: false,
			tablePageDescError: error,
			tablePageDescRequestId: null
		}
	},
	[actionTypes.RESTORE_TABLE_PAGE_DESC]: (state, { data }) => {
		return {
			...state,
			tablePageDescLoading: false,
			tablePageDescError: null,
			tablePageDescRequestId: null,
			...data
		}
	},
	/*----- Table Chart Reducers -----*/
	[actionTypes.FETCH_TABLE_CHART_REQUEST]: (state) => {
		return {
			...state,
			tableChartData: null,
			tableChartDataLoading: true,
			tableChartDataError: null,
			tableChartRequestId: null,
			insightsLogsRequestId: null,
			insightsDetailLogsRequestId: null,
			insightsLogsCountRequestId: null
		}
	},
	[actionTypes.FETCH_TABLE_CHART_SUCCESS]: (state, { jobApiResponse, apiResponse, metric, currentTabJob }) => {
		if (currentTabJob && currentTabJob !== state.tableChartRequestId) {
			return { ...state }
		} else {
			let tableChartData
			const expCompare = (a, b) => {
				if (a === 'Unlimited') return 1
				if (b === 'Unlimited') return -1
				if (a > b) return 1
				if (a <= b) return -1
			}
			const envCompare = (a, b) => {
				if (a.timegenerated > b.timegenerated) {
					return -1
				} else if (a.timegenerated < b.timegenerated) {
					return 1
				} else {
					if (a.name > b.name) {
						return 1
					} else {
						return -1
					}
				}
			}
			if (metric === 'license') {
				jobApiResponse.forEach((license) => {
					const expDate = moment(license.expiredate)
					if (expDate.isValid()) {
						const days = expDate.diff(moment(), 'days')
						license.daystoexpire = days
					} else {
						license.daystoexpire = 'Unlimited'
					}
				})
				jobApiResponse.sort((a, b) => expCompare(a.daystoexpire, b.daystoexpire))
			}
			if (metric === 'eol') {
				apiResponse.forEach((eol) => {
					const expDate = moment(eol.eol_date)
					if (expDate.isValid()) {
						const days = expDate.diff(moment(), 'days')
						eol.daystoeol = days
					} else {
						eol.daystoeol = 'Unlimited'
					}
				})
				apiResponse.sort((a, b) => expCompare(a.daystoeol, b.daystoeol))
			}
			if (_.isString(metric) && metric.startsWith('environmentals')) {
				jobApiResponse.forEach((env) => {
					if (metric === 'environmentals.fan') {
						const name = env.description.match(/^(.+)\s*RPM\s*$/)
						env.name = name && name.length === 2 ? `${name[1]}` : env.description
					}
					if (metric === 'environmentals.thermal') {
						const name = env.description.match(/^Temperature:\s*(.+)$/)
						env.name = name && name.length === 2 ? `${name[1]}` : env.description
					}
					if (metric === 'environmentals.power') {
						const name = env.description.match(/^Power\s*Rail:\s*(.+)$/)
						env.name = name && name.length === 2 ? `${name[1]}` : env.description
					}
					if (metric === 'environmentals.powersupply') {
						const name = env.description.match(/^Power\s*Supply\s*(#\d+)\s*\((\w+)\)$/)
						env.name = name && name.length === 3 ? `${name[1]} - ${_.capitalize(name[2])}` : env.description
					}
				})
				jobApiResponse.sort((a, b) => envCompare(a, b))

				if (metric === 'environmentals.fan') {
					const supplementalData = []
					jobApiResponse.forEach((fan) => {
						if (!fan.name.startsWith('Fan Tray')) supplementalData.push(fan)
					})
					_.pullAll(jobApiResponse, supplementalData)
					jobApiResponse = { mainData: jobApiResponse, supplementalData }
				}
			}

			if (jobApiResponse && apiResponse) {
				const chartData = {}
				const installed = jobApiResponse[0]
				let latest = {}
				if (metric === 'av_update') {
					latest = apiResponse.find((i) => i.content_name === 'antivirus')
					chartData.installed_version = installed.av_version
					chartData.installed_release_date = installed.av_release_date
				} else if (metric === 'threat_update') {
					latest = apiResponse.find((i) => i.content_name === 'applications and threats')
					chartData.installed_version = installed.threat_version
					chartData.installed_release_date = installed.threat_release_date
				} else if (metric === 'wildfire_update') {
					latest = apiResponse.find((i) => i.content_name === 'wildfire')
					chartData.installed_version = installed.wildfire_version
					chartData.installed_release_date = installed.wildfire_release_date
				}
				chartData.content_name = latest.content_name
				chartData.latest_version = latest.version
				chartData.latest_release_date = latest.content_release_date
				tableChartData = [chartData]
			} else if (jobApiResponse) {
				tableChartData = jobApiResponse
			} else {
				tableChartData = apiResponse
			}

			if (metric !== 'environmentals.fan') {
				tableChartData = { mainData: tableChartData }
			}

			return {
				...state,
				tableChartData,
				tableChartDataLoading: false,
				tableChartDataError: null,
				tableChartRequestId: null
			}
		}
	},
	[actionTypes.SET_TABLE_CHART_REQUEST_ID]: (state, { jobId }) => {
		return {
			...state,
			tableChartRequestId: jobId
		}
	},
	[actionTypes.FETCH_TABLE_CHART_FAILURE]: (state, { error }) => {
		return {
			...state,
			tableChartData: null,
			tableChartDataLoading: false,
			tableChartDataError: error,
			tableChartRequestId: null
		}
	},
	[actionTypes.RESTORE_TABLE_CHART]: (state, { data }) => {
		return {
			...state,
			tableChartDataLoading: false,
			tableChartDataError: null,
			tableChartRequestId: null,
			...data
		}
	}
}

const initialState = {
	tablePageId: null,
	tablePageDesc: null,
	tablePageDescRequestId: null,
	tablePageDescLoading: true,
	tablePageDescError: null,
	tablePageJobId: null,
	tablePageJobError: null,
	tableChartData: null,
	tableChartDataLoading: true,
	tableChartDataError: null,
	tableChartRequestId: null
}

export const tablePageReducer = (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
