import React, { Component } from 'react'
import iconCritical from '../../../images/icon-critical.svg'
import iconWarning from '../../../images/icon-warning.svg'
import iconHealty from '../../../images/icon-healthy.svg'
import Constants from '../../utils/Constants.json'
import Util from '../../utils/Util'

const iconMap = { critical: iconCritical, warning: iconWarning, healthy: iconHealty }

export default class SeverityCellRender extends Component {
	render() {
		const data = this.props.data
		return (
			<span className="inner-severity-cell">
				<img className="severity-icon" src={iconMap[data.severity]} alt={data.severity} />
				{data.metric in Constants.TABLE_CHART_INFO_MAP
					? ''
					: data.metric_value_pct === null || isNaN(data.metric_value_pct)
					? data.metric_value_raw === null || isNaN(data.metric_value_raw)
						? ''
						: Util.getReadableNumber(data.metric_value_raw, 0)
					: `${Math.round(data.metric_value_pct)}%`}
			</span>
		)
	}
}
