export const OPEN_ALERTS_TAB = 'OPEN_ALERTS_TAB'
export const REFRESH_ALERTS_TAB = 'REFRESH_ALERTS_TAB'
export const FETCH_ALERT_CHARTS = 'FETCH_ALERT_CHARTS'

export const RESET_STORE = 'RESET_STORE'

export const FETCH_ALERT_DETAILS_PAGE_CONTENT = 'FETCH_ALERT_DETAILS_PAGE_CONTENT'

export const FETCH_ALERT_DEVICE_DETAILS_SUCCESS = 'FETCH_ALERT_DEVICE_DETAILS_SUCCESS'
export const FETCH_ALERT_DEVICE_DETAILS_REQUEST = 'FETCH_ALERT_DEVICE_DETAILS_DEVICE_REQUEST'
export const SET_ALERT_DEVICE_DETAILS_REQUEST_ID = 'SET_ALERT_DEVICE_DETAILS_REQUEST_ID'
export const FETCH_ALERT_DEVICE_DETAILS_FAILURE = 'FETCH_ALERT_DEVICE_DETAILS_FAILURE'
export const RESTORE_DEVICE_DETAILS = 'RESTORE_DEVICE_DETAILS'

export const FETCH_ALERT_REQUEST = 'FETCH_ALERT_REQUEST'
export const FETCH_ALERT_SUCCESS = 'FETCH_ALERT_SUCCESS'
export const FETCH_ALERT_FAILURE = 'FETCH_ALERT_FAILURE'
export const UPDATE_ALERT_STATUS_REQUEST = 'UPDATE_ALERT_STATUS_REQUEST'
export const UPDATE_ALERT_STATUS_SUCCESS = 'UPDATE_ALERT_STATUS_SUCCESS'
export const UPDATE_ALERT_STATUS_FAILURE = 'UPDATE_ALERT_STATUS_FAILURE'
export const RESTORE_ALERT = 'RESTORE_ALERT'

export const FETCH_ALERT_EVENTS_REQUEST = 'FETCH_ALERT_EVENTS_REQUEST'
export const FETCH_ALERT_EVENTS_SUCCESS = 'FETCH_ALERT_EVENTS_SUCCESS'
export const FETCH_ALERT_EVENTS_FAILURE = 'FETCH_ALERT_EVENTS_FAILURE'

export const FETCH_ALERT_ACTIVITIES_REQUEST = 'FETCH_ALERT_ACTIVITIES_REQUEST'
export const FETCH_ALERT_ACTIVITIES_SUCCESS = 'FETCH_ALERT_ACTIVITIES_SUCCESS'
export const FETCH_ALERT_ACTIVITIES_FAILURE = 'FETCH_ALERT_ACTIVITIES_FAILURE'

export const FETCH_ALERT_COMMENTS_REQUEST = 'FETCH_ALERT_COMMENTS_REQUEST'
export const FETCH_ALERT_COMMENTS_SUCCESS = 'FETCH_ALERT_COMMENTS_SUCCESS'
export const FETCH_ALERT_COMMENTS_FAILURE = 'FETCH_ALERT_COMMENTS_FAILURE'
export const ADD_ALERT_COMMENT_REQUEST = 'ADD_ALERT_COMMENT_REQUEST'
export const ADD_ALERT_COMMENT_SUCCESS = 'ADD_ALERT_COMMENT_SUCCESS'
export const ADD_ALERT_COMMENT_FAILURE = 'ADD_ALERT_COMMENT_FAILURE'
export const UPDATE_ALERT_COMMENT_REQUEST = 'UPDATE_ALERT_COMMENT_REQUEST'
export const UPDATE_ALERT_COMMENT_SUCCESS = 'UPDATE_ALERT_COMMENT_SUCCESS'
export const UPDATE_ALERT_COMMENT_FAILURE = 'UPDATE_ALERT_COMMENT_FAILURE'
export const REMOVE_ALERT_COMMENT_REQUEST = 'REMOVE_ALERT_COMMENT_REQUEST'
export const REMOVE_ALERT_COMMENT_SUCCESS = 'REMOVE_ALERT_COMMENT_SUCCESS'
export const REMOVE_ALERT_COMMENT_FAILURE = 'REMOVE_ALERT_COMMENT_FAILURE'

/*--------- Charts ------------*/
export const POLL_ALERT_DETAILS_PAGE_JOB_REQUEST = 'POLL_ALERT_DETAILS_PAGE_JOB_REQUEST'
export const POLL_ALERT_DETAILS_PAGE_JOB_FAILURE = 'POLL_ALERT_DETAILS_PAGE_JOB_FAILURE'

export const FETCH_ALERT_CAPACITY_REQUEST = 'FETCH_ALERT_CAPACITY_REQUEST'
export const FETCH_ALERT_CAPACITY_SUCCESS = 'FETCH_ALERT_CAPACITY_SUCCESS'
export const FETCH_ALERT_CAPACITY_FAILURE = 'FETCH_ALERT_CAPACITY_FAILURE'
export const RESTORE_ALERT_CAPACITY = 'RESTORE_ALERT_CAPACITY'

export const FETCH_ALERT_MOV_AVG_REQUEST = 'FETCH_ALERT_MOV_AVG_REQUEST'
export const FETCH_ALERT_MOV_AVG_SUCCESS = 'FETCH_ALERT_MOV_AVG_SUCCESS'
export const SET_ALERT_MOV_AVG_REQUEST_ID = 'SET_ALERT_MOV_AVG_REQUEST_ID'
export const FETCH_ALERT_MOV_AVG_FAILURE = 'FETCH_ALERT_MOV_AVG_FAILURE'
export const RESTORE_ALERT_MOV_AVG = 'RESTORE_ALERT_MOV_AVG'

export const FETCH_ALERT_TIMELINE_REQUEST = 'FETCH_ALERT_TIMELINE_REQUEST'
export const FETCH_ALERT_TIMELINE_SUCCESS = 'FETCH_ALERT_TIMELINE_SUCCESS'
export const SET_ALERT_TIMELINE_REQUEST_ID = 'SET_ALERT_TIMELINE_REQUEST_ID'
export const FETCH_ALERT_TIMELINE_FAILURE = 'FETCH_ALERT_TIMELINE_FAILURE'
export const RESTORE_ALERT_TIMELINE = 'RESTORE_ALERT_TIMELINE'

export const FETCH_ALERT_FORECAST_REQUEST = 'FETCH_ALERT_FORECAST_REQUEST'
export const FETCH_ALERT_FORECAST_FAILURE = 'FETCH_ALERT_FORECAST_FAILURE'
export const FETCH_ALERT_FORECAST_SUCCESS = 'FETCH_ALERT_FORECAST_SUCCESS'
export const RESTORE_ALERT_FORECAST = 'RESTORE_ALERT_FORECAST'

export const FETCH_ALERT_COMMIT_EVENTS_REQUEST = 'FETCH_ALERT_COMMIT_EVENTS_REQUEST'
export const FETCH_ALERT_COMMIT_EVENTS_SUCCESS = 'FETCH_ALERT_COMMIT_EVENTS_SUCCESS'
export const SET_ALERT_COMMIT_EVENTS_REQUEST_ID = 'SET_ALERT_COMMIT_EVENTS_REQUEST_ID'
export const FETCH_ALERT_COMMIT_EVENTS_FAILURE = 'FETCH_ALERT_COMMIT_EVENTS_FAILURE'
export const RESTORE_ALERT_COMMIT_EVENTS = 'RESTORE_ALERT_COMMIT_EVENTS'

export const UPDATE_ALERT_TIMELINE_TIME_RANGE = 'UPDATE_ALERT_TIMELINE_TIME_RANGE'
export const UPDATE_ALERT_TIME_RANGE_PRESET = 'UPDATE_ALERT_TIME_RANGE_PRESET'
export const SET_ALERT_TIME_RANGE_PRESET = 'SET_ALERT_TIME_RANGE_PRESET'
export const SET_SHOW_ALERT_COMMIT_EVENTS = 'SET_SHOW_ALERT_COMMIT_EVENTS'
export const SET_SHOW_ALERT_REF_LINES = 'SET_SHOW_ALERT_REF_LINES'
export const UPDATE_SHOW_ALERT_COMMIT_EVENTS = 'UPDATE_SHOW_ALERT_COMMIT_EVENTS'
export const UPDATE_SHOW_ALERT_REF_LINES = 'UPDATE_SHOW_ALERT_REF_LINES'

export const FETCH_ALERT_TABLE_CHART_REQUEST = 'FETCH_ALERT_TABLE_CHART_REQUEST'
export const FETCH_ALERT_TABLE_CHART_SUCCESS = 'FETCH_ALERT_TABLE_CHART_SUCCESS'
export const SET_ALERT_TABLE_CHART_REQUEST_ID = 'SET_ALERT_TABLE_CHART_REQUEST_ID'
export const FETCH_ALERT_TABLE_CHART_FAILURE = 'FETCH_ALERT_TABLE_CHART_FAILURE'
export const RESTORE_ALERT_TABLE_CHARTS = 'RESTORE_ALERT_TABLE_CHARTS'
export const RESET_ALERT_TABLE_CHARTS = 'RESET_ALERT_TABLE_CHARTS'
