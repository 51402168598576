import React, { Component } from 'react'
import moment from 'moment'
import { Popover } from 'antd'
import RefreshIcon from '../../images/icon-refresh.svg'
import ActiveRefreshIcon from '../../images/icon-refresh-active.svg'
import './RefreshTab.scss'

export default class RefreshTab extends Component {
	render() {
		const { activeApp, activeInsightsTab, isNavOpen, onRefresh, staleStatus, lastRefresh } = this.props
		if (activeApp === 'insights' && activeInsightsTab === 'insights.devicedetails') return null
		return ['insights', 'alerts'].includes(activeApp) ? (
			isNavOpen ? (
				<div className="refresh-tab" onClick={onRefresh}>
					<div className="refresh-btn">
						<img alt="refresh-icon" className="icon" src={staleStatus && lastRefresh ? ActiveRefreshIcon : RefreshIcon} />
						<div className="text">Refresh</div>
					</div>
					<div className={`refresh-info ${staleStatus && lastRefresh ? 'stale' : ''}`}>
						<div className="desc">Last Update</div>
						<div className="timestamp">{lastRefresh && moment(lastRefresh).clone().format('HH:mm:ss')}</div>
					</div>
				</div>
			) : (
				<Popover
					prefixCls="nav-popover pan-popover"
					arrowPointAtCenter={true}
					placement="right"
					content={`Last Update: ${lastRefresh && moment(lastRefresh).clone().format('HH:mm:ss')}`}
					trigger="hover"
					align={{ offset: [-2, 0] }}
				>
					<div className="refresh-tab" onClick={onRefresh}>
						<div className={`refresh-btn ${staleStatus && lastRefresh ? 'stale' : ''}`}>
							<img alt="refresh-icon" className="icon" src={staleStatus && lastRefresh ? ActiveRefreshIcon : RefreshIcon} />
							<div className="text">Refresh</div>
						</div>
					</div>
				</Popover>
			)
		) : null
	}
}
