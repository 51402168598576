import React, { Component } from 'react'
import { connect } from 'ui-lib'
import AlertTable from './components/AlertTable/AlertTable'
import SyncChart from './components/SyncChart/SyncChart'
import TableChart from './components/TableChart/TableChart'
import DetailsPageHeader from '../../../components/DetailsPageHeader/DetailsPageHeader'

import Constants from '../../utils/Constants.json'

import * as actions from './store/actions'
import * as alertsActions from '../../store/actions'

import './AlertDetails.scss'
import iconService from '../../../images/icon-service.svg'
import iconHelp from '../../../images/icon-help.svg'
import iconLoading from 'ui-lib/src/images/icon-loading.svg'

class AlertDetails extends Component {
	state = {
		tableHeight: 57
	}

	createMiscHTML = (snippet) => ({ __html: snippet })

	componentDidMount() {
		this.props.fetchAlertDetailsPageContent()
	}
	componentDidUpdate(prevProps) {
		if (_.get(prevProps, `alertsTabs.custom_tabs[${prevProps.activeAlertsTab}].id`) !== _.get(this.props, `alertsTabs.custom_tabs[${this.props.activeAlertsTab}].id`)) {
			//Scroll to Top
			//Fetch content
			this.props.fetchAlertDetailsPageContent()
		}
	}
	render() {
		const { switchAlertsTab, activeAlertsTab, alertsMisc, alert, alertLoading, alertError, deviceDetails, deviceDetailsLoading, deviceDetailsError } = this.props

		if (alertError || deviceDetailsError) {
			return <div className="info-area">Error Fetching Alert</div>
		}

		if (alertLoading || deviceDetailsLoading) {
			return (
				<div className="info-area">
					<img className="loading-spinner" src={iconLoading} alt={'loading'} />
					Loading Data
				</div>
			)
		}

		if (!alert || !deviceDetails || _.get(alert, 'length') !== 1 || _.get(Object.keys(deviceDetails), 'length') !== 1) {
			return <div className="info-area">Error Loading Page</div>
		}

		const deviceInfo = deviceDetails[alert[0].appliance_id]
		const alertMeta = alertsMisc[alert[0].alert_type_id]
		return (
			<div className="alert-details">
				<DetailsPageHeader
					pageDesc={{
						'Serial Number:': deviceInfo.serial,
						'Model:': deviceInfo.model,
						'SW Version:': deviceInfo.sw_version,
						'IP Address:':
							!_.isString(deviceInfo.ip_address) && !_.isString(deviceInfo.ipv6_address) ? 'N/A' : deviceInfo.ip_address !== 'unknown' ? deviceInfo.ip_address : deviceInfo.ipv6_address
					}}
					pageTitle={`${alert[0].alert_name} - ${deviceInfo.hostname}`}
					parentPageName={'Alerts'}
					currentPageName={'Alert Details'}
					onParentLinkClicked={() => switchAlertsTab(activeAlertsTab, 'alerts.main')}
				/>
				<AlertTable logs={alert} setTableHeight={(tableHeight) => this.setState({ tableHeight })} />
				<div className="chart-info" style={{ height: `calc(100vh - ${115 + this.state.tableHeight}px)` }}>
					<div className="chart-graph">
						<div className="alert-charts">{_.get(alert[0], 'metrics[0]') in Constants.TABLE_CHART_INFO_MAP ? <TableChart /> : <SyncChart />}</div>
					</div>
					<div className="chart-misc">
						<div className="item">
							<div className="title">
								<img className="misc-icon" src={iconService} alt="misc" />
								Recommendations
							</div>
							<div className="content" dangerouslySetInnerHTML={this.createMiscHTML(alertMeta.recommendations || 'N/A')} />
						</div>
						{/* <div className="item">
							<div className="title">
								<img className="misc-icon" src={iconHelp} alt="misc" />
								Related Help Articles
							</div>
							<div className="content" dangerouslySetInnerHTML={this.createMiscHTML(alertMeta.related_help || 'N/A')} />
						</div> */}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.alerts, ...state.alertDetails, ...state.visibility }
}

const mapDispatchToProps = { ...actions, ...alertsActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(AlertDetails)
