import * as actionTypes from './actionTypes'

/*----- User Context Actions -----*/
export const fetchUserContext = (username) => ({
	type: actionTypes.FETCH_USRCTX_REQUEST,
	username
})

export const fetchUserContextSuccess = (userContext) => ({
	type: actionTypes.FETCH_USRCTX_SUCCESS,
	userContext
})

export const fetchUserContextFailure = () => ({
	type: actionTypes.FETCH_USRCTX_FAILURE
})

/*----- Schema Actions -----*/
export const fetchSchemaRequest = () => ({
	type: actionTypes.FETCH_SCHEMA_REQUEST
})

export const fetchSchemaSuccess = (schema) => ({
	type: actionTypes.FETCH_SCHEMA_SUCCESS,
	schema
})

export const updateExploreSchema = (preference) => ({
	type: actionTypes.UPDATE_EXPLORE_SCHEMA,
	preference
})

export const fetchSchemaFailure = (error) => ({
	type: actionTypes.FETCH_SCHEMA_FAILURE,
	error
})

/*----- Restore Actions -----*/
export const restoreLogviewer = () => ({
	type: actionTypes.RESTORE_LOGVIEWER
})

export const restoreComplete = () => ({
	type: actionTypes.RESTORE_COMPLETE
})

/*----- Logs Actions -----*/
export const fetchLogsRequest = (params) => ({
	type: actionTypes.FETCH_LOGS_REQUEST,
	params: params || {}
})

export const setLogsRequestId = (jobId) => ({
	type: actionTypes.SET_LOGS_REQUEST_ID,
	jobId
})

export const fetchLogsSuccess = (logs, jobId) => ({
	type: actionTypes.FETCH_LOGS_SUCCESS,
	logs,
	jobId
})

export const fetchLogsFailure = (error) => ({
	type: actionTypes.FETCH_LOGS_FAILURE,
	error
})

/*----- LogsCount Actions -----*/
export const fetchLogsCountRequest = (params) => ({
	type: actionTypes.FETCH_LOGS_COUNT_REQUEST,
	params: params || {}
})

export const setLogsCountRequestId = (jobId) => ({
	type: actionTypes.SET_LOGS_COUNT_REQUEST_ID,
	jobId
})

export const fetchLogsCountSuccess = (logsCount, jobId) => ({
	type: actionTypes.FETCH_LOGS_COUNT_SUCCESS,
	logsCount,
	jobId
})

export const fetchLogsCountFailure = (error) => ({
	type: actionTypes.FETCH_LOGS_COUNT_FAILURE,
	error
})

/*----- RelatedLogs Actions -----*/
export const fetchRelatedLogsRequest = (params) => ({
	type: actionTypes.FETCH_RELATED_LOGS_REQUEST,
	params: params || {}
})

export const setRelatedLogsRequestId = (jobId) => ({
	type: actionTypes.SET_RELATED_LOGS_REQUEST_ID,
	jobId
})

export const fetchCombinedRelatedLogs = (results) => {
	let allLogs = []
	for (let result of results) {
		allLogs.push(...result.response.response.page.result.data)
	}
	allLogs.sort((a, b) => (a.time_generated >= b.time_generated ? -1 : 1))
	return {
		type: actionTypes.FETCH_RELATED_LOGS_SUCCESS,
		relatedLogs: allLogs,
		jobId: 123
	}
}
export const fetchRelatedLogsSuccess = (relatedLogs, jobId) => ({
	type: actionTypes.FETCH_RELATED_LOGS_SUCCESS,
	relatedLogs,
	jobId
})

export const fetchRelatedLogsFailure = (error) => ({
	type: actionTypes.FETCH_RELATED_LOGS_FAILURE,
	error
})

/*----- Init Export Actions -----*/
export const initExportRequest = (params) => ({
	type: actionTypes.INIT_EXPORT_REQUEST,
	params: params || {}
})

export const setExportRequestId = (exportJobId) => ({
	type: actionTypes.SET_EXPORT_REQUEST_ID,
	exportJobId
})

export const initExportSuccess = (exportJobId) => ({
	type: actionTypes.INIT_EXPORT_SUCCESS,
	exportJobId
})

export const initExportFailure = (error) => ({
	type: actionTypes.INIT_EXPORT_FAILURE,
	error
})

/*----- Cancel Export Actions -----*/
export const cancelExportRequest = (exportJobId) => ({
	type: actionTypes.CANCEL_EXPORT_REQUEST,
	exportJobId
})

export const cancelExportSuccess = () => ({
	type: actionTypes.CANCEL_EXPORT_SUCCESS
})

export const removeCurrentJobId = () => ({
	type: actionTypes.REMOVE_CURRENT_EXPORT_JOBID
})

export const cancelExportFailure = (error) => ({
	type: actionTypes.CANCEL_EXPORT_FAILURE,
	error
})

/*----- Export Status Actions -----*/
export const fetchExportStatusRequest = (exportJobId) => ({
	type: actionTypes.FETCH_EXPORT_STATUS_REQUEST,
	exportJobId
})

export const fetchExportStatusSuccess = (exportStatus) => ({
	type: actionTypes.FETCH_EXPORT_STATUS_SUCCESS,
	exportStatus
})

export const fetchExportStatusFailure = (error) => ({
	type: actionTypes.FETCH_EXPORT_STATUS_FAILURE,
	error
})

/*----- Export File Actions -----*/
export const fetchExportFileRequest = (params) => ({
	type: actionTypes.FETCH_EXPORT_FILE_REQUEST,
	params: params || {}
})

export const fetchExportFileSuccess = (params) => ({
	type: actionTypes.FETCH_EXPORT_FILE_SUCCESS,
	params
})

export const fetchExportFileFailure = (error, params) => ({
	type: actionTypes.FETCH_EXPORT_FILE_FAILURE,
	error,
	params
})

/*----- Export History Actions -----*/
export const fetchExportHistoryRequest = () => ({
	type: actionTypes.FETCH_EXPORT_HISTORY_REQUEST
})

export const fetchExportHistorySuccess = (exportHistory) => ({
	type: actionTypes.FETCH_EXPORT_HISTORY_SUCCESS,
	exportHistory
})

export const fetchExportHistoryFailure = (error) => ({
	type: actionTypes.FETCH_EXPORT_HISTORY_FAILURE,
	error
})

/*----- PollJob Actions -----*/
export const pollJobRequest = (params) => ({
	type: actionTypes.POLL_JOB_REQUEST,
	params: params || {}
})

export const pollJobFailure = (params) => ({
	type: actionTypes.POLL_JOB_FAILURE,
	params: params || {}
})

/*----- Page Actions -----*/
export const updatePageRequest = (params) => ({
	type: actionTypes.UPDATE_PAGE_REQUEST,
	params: params || {}
})

export const updatePageNumber = (pageNumber) => ({
	type: actionTypes.UPDATE_PAGE_NUMBER,
	pageNumber
})

export const updatePageLimit = (pageLimit) => ({
	type: actionTypes.UPDATE_PAGE_LIMIT,
	pageLimit
})

/*----- Query Related Actions -----*/
export const updateQuery = (query) => ({
	type: actionTypes.UPDATE_QUERY,
	query
})

export const updateLogType = (logType) => ({
	type: actionTypes.UPDATE_LOG_TYPE,
	logType
})

export const updateTenantId = (tenantId) => ({
	type: actionTypes.UPDATE_TENANT_ID,
	tenantId
})

export const updateTimeRange = (timeRange) => ({
	type: actionTypes.UPDATE_TIME_RANGE,
	timeRange
})

export const updateTimePreset = (timePreset) => ({
	type: actionTypes.UPDATE_TIME_PRESET,
	timePreset
})

export const updateSortOrder = (sortOrder) => ({
	type: actionTypes.UPDATE_SORT_ORDER,
	sortOrder
})
