import React from "react";
import { Provider, create } from "mini-store";
import SubPopupMenu, { getActiveKey } from "./SubPopupMenu";
import { noop } from "./util";
import { getMotion } from "./utils/legacyUtil";

class Menu extends React.Component {
	static defaultProps = {
		selectable: true,
		onClick: noop,
		onSelect: noop,
		onOpenChange: noop,
		onDeselect: noop,
		defaultSelectedKeys: [],
		defaultOpenKeys: [],
		subMenuOpenDelay: 0.1,
		subMenuCloseDelay: 0.1,
		triggerSubMenuAction: "hover",
		prefixCls: "rc-menu",
		className: "",
		mode: "vertical",
		style: {},
		builtinPlacements: {},
		overflowedIndicator: <span>···</span>,
	};

	constructor(props) {
		super(props);

		this.isRootMenu = true;

		let selectedKeys = props.defaultSelectedKeys;
		let openKeys = props.defaultOpenKeys;
		if ("selectedKeys" in props) {
			selectedKeys = props.selectedKeys || [];
		}
		if ("openKeys" in props) {
			openKeys = props.openKeys || [];
		}

		this.store = create({
			selectedKeys,
			openKeys,
			activeKey: { "0-menu-": getActiveKey(props, props.activeKey) },
		});
	}

	isRootMenu;

	store;

	innerMenu;

	componentDidMount() {
		this.updateMiniStore();
	}

	componentDidUpdate() {
		this.updateMiniStore();
	}

	onSelect = (selectInfo) => {
		const { props } = this;
		if (props.selectable) {
			// root menu
			let { selectedKeys } = this.store.getState();
			const selectedKey = selectInfo.key;
			if (props.multiple) {
				selectedKeys = selectedKeys.concat([selectedKey]);
			} else {
				selectedKeys = [selectedKey];
			}
			if (!("selectedKeys" in props)) {
				this.store.setState({
					selectedKeys,
				});
			}
			props.onSelect({
				...selectInfo,
				selectedKeys,
			});
		}
	};

	onClick = (e) => {
		this.props.onClick(e);
	};

	// onKeyDown needs to be exposed as a instance method
	// e.g., in pan-recycle-select, we need to navigate menu item while
	// current active item is pan-recycle-select input box rather than the menu itself
	onKeyDown = (e, callback) => {
		this.innerMenu.getWrappedInstance().onKeyDown(e, callback);
	};

	onOpenChange = (event) => {
		const { props } = this;
		const openKeys = this.store.getState().openKeys.concat();
		let changed = false;
		const processSingle = (e) => {
			let oneChanged = false;
			if (e.open) {
				oneChanged = openKeys.indexOf(e.key) === -1;
				if (oneChanged) {
					openKeys.push(e.key);
				}
			} else {
				const index = openKeys.indexOf(e.key);
				oneChanged = index !== -1;
				if (oneChanged) {
					openKeys.splice(index, 1);
				}
			}
			changed = changed || oneChanged;
		};
		if (Array.isArray(event)) {
			// batch change call
			event.forEach(processSingle);
		} else {
			processSingle(event);
		}
		if (changed) {
			if (!("openKeys" in this.props)) {
				this.store.setState({ openKeys });
			}
			props.onOpenChange(openKeys);
		}
	};

	onDeselect = (selectInfo) => {
		const { props } = this;
		if (props.selectable) {
			const selectedKeys = this.store.getState().selectedKeys.concat();
			const selectedKey = selectInfo.key;
			const index = selectedKeys.indexOf(selectedKey);
			if (index !== -1) {
				selectedKeys.splice(index, 1);
			}
			if (!("selectedKeys" in props)) {
				this.store.setState({
					selectedKeys,
				});
			}
			props.onDeselect({
				...selectInfo,
				selectedKeys,
			});
		}
	};

	getOpenTransitionName = () => {
		const { props } = this;
		let transitionName = props.openTransitionName;
		const animationName = props.openAnimation;
		if (!transitionName && typeof animationName === "string") {
			transitionName = `${props.prefixCls}-open-${animationName}`;
		}
		return transitionName;
	};

	setInnerMenu = (node) => {
		this.innerMenu = node;
	};

	updateMiniStore() {
		if ("selectedKeys" in this.props) {
			this.store.setState({
				selectedKeys: this.props.selectedKeys || [],
			});
		}
		if ("openKeys" in this.props) {
			this.store.setState({
				openKeys: this.props.openKeys || [],
			});
		}
	}

	render() {
		let props = { ...this.props };
		props.className += ` ${props.prefixCls}-root`;
		props = {
			...props,
			onClick: this.onClick,
			onOpenChange: this.onOpenChange,
			onDeselect: this.onDeselect,
			onSelect: this.onSelect,
			parentMenu: this,
			motion: getMotion(this.props),
		};

		delete props.openAnimation;
		delete props.openTransitionName;

		return (
			<Provider store={this.store}>
				<SubPopupMenu {...props} ref={this.setInnerMenu}>
					{this.props.children}
				</SubPopupMenu>
			</Provider>
		);
	}
}

export default Menu;
