import React, { Component } from 'react'
import { connect } from 'ui-lib'

import * as insightsActions from '../../store/actions'

import * as alertsActions from '../../../alerts/store/actions'
import Util from '../../utils/Util'
import { Popover } from 'antd'

class LastUpdatedCellRenderer extends Component {
	render () {
		let { value } = this.props
		const lastUpdate = this.props[`${this.props.activeApp}LastUpdate`]
		value = lastUpdate ? Util.getRelativeTime(value) : Util.getTransformedTime(value)
		const reverseValue = lastUpdate ? Util.getTransformedTime(this.props.value) : Util.getRelativeTime(this.props.value)
		const content = (
			<>
				<div>{reverseValue}</div>
			</>
		)
		return (
			<Popover prefixCls='nav-popover pan-popover' content={content} trigger='hover' placement='top'>
				<span>{value}</span>
			</Popover>
		)
	}
}

const mapStateToProps = (state) => {
	return { ...state.linePage, ...state.visibility, ...state.insights, ...state.alerts }
}

const mapDispatchToProps = { ...insightsActions, ...alertsActions }

export default connect(mapStateToProps, mapDispatchToProps, null)(LastUpdatedCellRenderer)
